import { makeAutoObservable } from 'mobx';
import { ManagementStore, SignalRStore } from '.';
import { AuthorizationStore } from './authorization/AuthorizationStore';
import {LocalizationStore} from "./localization/LocalizationStore";
import {ThemeColorStore} from "./themeColor/ThemeColorStore";
import {ExpandSidebarStore} from "./expandSidebar/ExpandSidebarStore";

export class RootStore {
    readonly signalRStore: SignalRStore;
    readonly managementStore: ManagementStore;
    readonly authorizationStore: AuthorizationStore;
    readonly localizationStore: LocalizationStore;
    readonly themeColorStore: ThemeColorStore;
    readonly expandSidebarStore: ExpandSidebarStore;

    constructor() {
        this.authorizationStore = new AuthorizationStore(this);
        this.signalRStore = new SignalRStore(this);
        this.localizationStore = new LocalizationStore(this);
        this.themeColorStore = new ThemeColorStore(this);
        this.expandSidebarStore = new ExpandSidebarStore(this);
        this.managementStore = new ManagementStore(this);
        makeAutoObservable(this);
    }
}
