import {
    Box,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Dot as DotIcon } from '../../icons/dot';
import { useAuthorizationStore } from '../../stores';

interface JurisdictionsPopoverProps {
    anchorEl: null | Element;
    onClose?: () => void;
    open?: boolean;
}

export const JurisdictionPopover: FC<JurisdictionsPopoverProps> = (props) => {
    const { anchorEl, onClose, open, ...other } = props;
    const { t } = useTranslation();
    const authorizationStore = useAuthorizationStore();

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
            }}
            keepMounted
            onClose={onClose}
            open={!!open}
            PaperProps={{ sx: { width: 240 } }}
            transitionDuration={0}
            {...other}
        >
            {authorizationStore.jurisdictions.items.map((jurisdiction) => {
                const isActive =
                    jurisdiction.jurisdictionId === authorizationStore.jurisdictions.value?.jurisdictionId;
                return (
                    <MenuItem
                        onClick={() => {
                            onClose?.();
                            authorizationStore.jurisdictions.value = jurisdiction;
                            toast.success(t(`jurisdictions.changed`).replace("{name}", `"${jurisdiction.jurisdiction.name!}"`));
                        }}
                        key={jurisdiction.jurisdictionId}
                    >
                        <ListItemIcon>
                            <Box
                                sx={{
                                    display: 'flex',
                                    height: 20,
                                    width: 20,
                                    '& img': {
                                        width: '100%',
                                    },
                                }}
                            >
                                <DotIcon
                                    fontSize="small"
                                    color={isActive ? 'primary' : 'disabled'}
                                />
                            </Box>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="subtitle2">
                                    {jurisdiction.jurisdiction.name}
                                </Typography>
                            }
                        />
                    </MenuItem>
                );
            })}
        </Popover>
    );
};

JurisdictionPopover.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    open: PropTypes.bool,
};
