/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OrgNameDto,
    OrgNameDtoFromJSON,
    OrgNameDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetOrgNameDto,
    SetOrgNameDtoFromJSON,
    SetOrgNameDtoToJSON,
} from '../models';

export interface CreateOrgNameRequest {
    setOrgNameDto: SetOrgNameDto;
}

export interface DeleteOrgNameRequest {
    id: string;
}

export interface GetOrgNameByIdRequest {
    id: string;
}

export interface GetOrgNamesRequest {
    search?: string;
    organizationId?: string;
    typeNameId?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetOrgNamesCountRequest {
    search?: string;
    typeNameId?: string;
    organizationId?: string;
}

export interface UpdateOrgNameRequest {
    id: string;
    setOrgNameDto: SetOrgNameDto;
}

/**
 * 
 */
export class OrgNamesApi extends runtime.BaseAPI {

    /**
     */
    async createOrgNameRaw(requestParameters: CreateOrgNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrgNameDto>> {
        if (requestParameters.setOrgNameDto === null || requestParameters.setOrgNameDto === undefined) {
            throw new runtime.RequiredError('setOrgNameDto','Required parameter requestParameters.setOrgNameDto was null or undefined when calling createOrgName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/names`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetOrgNameDtoToJSON(requestParameters.setOrgNameDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async createOrgName(requestParameters: CreateOrgNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrgNameDto> {
        const response = await this.createOrgNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteOrgNameRaw(requestParameters: DeleteOrgNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrgNameDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOrgName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/names/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteOrgName(requestParameters: DeleteOrgNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrgNameDto> {
        const response = await this.deleteOrgNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrgNameByIdRaw(requestParameters: GetOrgNameByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrgNameDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrgNameById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/names/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrgNameById(requestParameters: GetOrgNameByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrgNameDto> {
        const response = await this.getOrgNameByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrgNamesRaw(requestParameters: GetOrgNamesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrgNameDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.typeNameId !== undefined) {
            queryParameters['typeNameId'] = requestParameters.typeNameId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/names`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrgNameDtoFromJSON));
    }

    /**
     */
    async getOrgNames(requestParameters: GetOrgNamesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrgNameDto>> {
        const response = await this.getOrgNamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrgNamesCountRaw(requestParameters: GetOrgNamesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.typeNameId !== undefined) {
            queryParameters['typeNameId'] = requestParameters.typeNameId;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/names/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrgNamesCount(requestParameters: GetOrgNamesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getOrgNamesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrgNameRaw(requestParameters: UpdateOrgNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrgNameDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrgName.');
        }

        if (requestParameters.setOrgNameDto === null || requestParameters.setOrgNameDto === undefined) {
            throw new runtime.RequiredError('setOrgNameDto','Required parameter requestParameters.setOrgNameDto was null or undefined when calling updateOrgName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/names/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetOrgNameDtoToJSON(requestParameters.setOrgNameDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateOrgName(requestParameters: UpdateOrgNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrgNameDto> {
        const response = await this.updateOrgNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
