/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    RoleDto,
    RoleDtoFromJSON,
    RoleDtoToJSON,
    SetRoleDto,
    SetRoleDtoFromJSON,
    SetRoleDtoToJSON,
} from '../models';

export interface CreateRoleRequest {
    setRoleDto: SetRoleDto;
}

export interface DeleteRoleRequest {
    id: string;
}

export interface GetRoleByIdRequest {
    id: string;
}

export interface GetRoleByNameRequest {
    name: string;
}

export interface GetRolesRequest {
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    permissions?: Array<string>;
    users?: Array<string>;
    page?: number;
    size?: number;
}

export interface GetRolesCountRequest {
    search?: string;
    permissions?: Array<string>;
    users?: Array<string>;
}

export interface UpdateRoleRequest {
    id: string;
    setRoleDto: SetRoleDto;
}

/**
 * 
 */
export class RolesApi extends runtime.BaseAPI {

    /**
     */
    async createRoleRaw(requestParameters: CreateRoleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RoleDto>> {
        if (requestParameters.setRoleDto === null || requestParameters.setRoleDto === undefined) {
            throw new runtime.RequiredError('setRoleDto','Required parameter requestParameters.setRoleDto was null or undefined when calling createRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/v1/roles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetRoleDtoToJSON(requestParameters.setRoleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDtoFromJSON(jsonValue));
    }

    /**
     */
    async createRole(requestParameters: CreateRoleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RoleDto> {
        const response = await this.createRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteRoleRaw(requestParameters: DeleteRoleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RoleDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteRole(requestParameters: DeleteRoleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RoleDto> {
        const response = await this.deleteRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRoleByIdRaw(requestParameters: GetRoleByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RoleDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRoleById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDtoFromJSON(jsonValue));
    }

    /**
     */
    async getRoleById(requestParameters: GetRoleByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RoleDto> {
        const response = await this.getRoleByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRoleByNameRaw(requestParameters: GetRoleByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RoleDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getRoleByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/roles/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDtoFromJSON(jsonValue));
    }

    /**
     */
    async getRoleByName(requestParameters: GetRoleByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RoleDto> {
        const response = await this.getRoleByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRolesRaw(requestParameters: GetRolesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<RoleDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.permissions) {
            queryParameters['permissions'] = requestParameters.permissions;
        }

        if (requestParameters.users) {
            queryParameters['users'] = requestParameters.users;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleDtoFromJSON));
    }

    /**
     */
    async getRoles(requestParameters: GetRolesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<RoleDto>> {
        const response = await this.getRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRolesCountRaw(requestParameters: GetRolesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.permissions) {
            queryParameters['permissions'] = requestParameters.permissions;
        }

        if (requestParameters.users) {
            queryParameters['users'] = requestParameters.users;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/roles/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getRolesCount(requestParameters: GetRolesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getRolesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateRoleRaw(requestParameters: UpdateRoleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RoleDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateRole.');
        }

        if (requestParameters.setRoleDto === null || requestParameters.setRoleDto === undefined) {
            throw new runtime.RequiredError('setRoleDto','Required parameter requestParameters.setRoleDto was null or undefined when calling updateRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/v1/roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetRoleDtoToJSON(requestParameters.setRoleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateRole(requestParameters: UpdateRoleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RoleDto> {
        const response = await this.updateRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
