import {action, makeObservable, observable} from "mobx";
import {getApiConfiguration, getObject, setObject} from "../../../../core";
import {
    ExportImportType,
    ImportFilesApi,
    ImportManagementApi,
    ImportPersonsToCheckRequest
} from "../../../../services/import";
import {PagedItems, TableLocalFilters, TableStore} from "../../../utils";
import {ImportTabsStore} from "./ImportTabsStore";
import {ImportItemStore} from "./ImportItemStore";
import {downloadFile, getBlobRequestSettings} from "../../../../core/blob";
import {DefaultFormModalStore} from "../../../../components/shared/DefaultFormModalStore";
import {DefaultValueStore} from "../../../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from "../../../../components/shared/DefaultSelectStore";
import {ClientFragment, ClientsQuery, ClientsQueryVariables, SortEnumType} from "../../../../gql/graphql";
import {getAppoloClient, GRAPHQL_URLS} from "../../../../graphql";
import {CLIENTS_QUERY} from "../../../../graphql/queries.graphql";

export type ImportType = 'Organizations' | 'Persons';

export class ImportTableStore extends TableStore<ImportItemStore> {
    readonly store: ImportTabsStore;
    creator: DefaultFormModalStore<{
        fileObjects: DefaultValueStore<File[]>,
        clients: DefaultSelectStore<ClientFragment>
    }> = new DefaultFormModalStore({
        clients: new DefaultSelectStore(null,
            async (x: any) => {
                const {data} = await getAppoloClient(GRAPHQL_URLS.CLIENTS).query<ClientsQuery, ClientsQueryVariables>(
                    {
                        query: CLIENTS_QUERY, variables: {
                            search: x.search ?? "",
                            skip: 0,
                            take: 1000,
                            sort: {
                                name: SortEnumType.Asc
                            }
                        },
                    }
                )
                return data.clients?.items ?? []
            }, null),
        fileObjects: new DefaultValueStore([] as File[])
    }, async (e) => await this.create())

    current: ImportItemStore | undefined | null;
    importKey: ImportType;
    type: ExportImportType;
    deleteDialogState: boolean = false;

    constructor(key: ImportType, store: ImportTabsStore) {
        super();
        this.store = store;
        this.importKey = key;
        this.type = key == "Persons" ? ExportImportType.NUMBER_1 : ExportImportType.NUMBER_2;

        const filters = getObject<TableLocalFilters<ImportItemStore>>(`Import${key}Table`);
        if (filters) {
            this._page = filters.page ?? 0;
            this._size = filters.size;
        }
        this.creator.afterSubmit = async () => {
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.updateState()
        }
        this.creator.fields.clients.defaultSort = true;
        this.creator.fields.clients.nameExp = 'name'

        makeObservable(this, {
            pullFormat: action,
            current: observable,
            delete: action
        });
    }

    async request(): Promise<PagedItems<ImportItemStore>> {
        const filters = {
            importType: this.type,
            page: this.page + 1,
            size: this.size,
        };

        const api: ImportManagementApi = new ImportManagementApi(getApiConfiguration() as any);
        const newList: ImportItemStore[] = [];
        let count = 0;

        const result = await api.getImportEntries(filters);
        count = (await api.getImportEntriesCount(filters));

        for (const item of result) {
            const existsItem = this.items.find(t => t.id === item.id);
            if (existsItem) {
                existsItem.update(item);
                newList.push(existsItem);
                continue;
            }
            newList.push(new ImportItemStore(this.importKey, this, item))
        }

        return new PagedItems<ImportItemStore>(newList, count)
    };

    async pullFormat(): Promise<void> {
        await new ImportManagementApi(getApiConfiguration()).getDocumentTemplate({importType: this.type})
            .then(async x => {
                await downloadFile(await x.blob(),
                    x.headers?.get('Content-Disposition')?.split('filename=')[1]?.split(';')[0] ??
                    `Import${this.importKey}Template_${Date.now()}.xlsx`)
            });
    };

    async create(): Promise<void> {
        const params: ImportPersonsToCheckRequest = {
            boundId: this.creator.model.clients?.id,
            formFile: this.creator.model.fileObjects[0]
        };
        const over = getBlobRequestSettings()

        this.importKey == "Persons" ? await new ImportFilesApi(getApiConfiguration()).importPersonsToCheck(params, over) :
            await new ImportFilesApi(getApiConfiguration()).importOrganizationsToCheck(params, over);
    };

    async delete(): Promise<void> {
        await new ImportManagementApi(getApiConfiguration()).deleteImportEntry({fileId: this.current?.id});
    };

    onFiltersSave() {
        setObject<TableLocalFilters<ImportItemStore>>(`Import${this.importKey}Table`, {
            search: this.search.value ?? '',
            order: this.order,
            page: this.page,
            size: this.size,
        });
    };
}
