import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { AuthorizePermission, Page, PageTab, PageTabs } from '../../components';
import { TabTitle } from '../../components/TabTitle';
import { AllPermissions } from '../../core/const';
import { useManagementStore } from '../../stores';
// import { Claims } from './tabs/claims';
import { GroupTableTab } from './tabs/groups/GroupTableTab';
import { PermissionTableTab } from './tabs/permissions/PermissionTableTab';
import { RoleTableTab } from './tabs/roles/RoleTableTab';
import { UserTableTab } from './tabs/users/UserTableTab';

export const UsersPage = observer(() => {
    const { usersPageStore } = useManagementStore();
    const { t } = useTranslation();

    const renderTabs = (index: number) => {
        switch (index) {
            case 0:
                return (
                    <AuthorizePermission
                        permissions={AllPermissions.user.read}
                        showWarning
                    >
                        <UserTableTab store={usersPageStore.userTableStore} />
                    </AuthorizePermission>
                );
            case 1:
                return (
                    <AuthorizePermission
                        permissions={AllPermissions.user.read}
                        showWarning
                    >
                        <PermissionTableTab
                            store={usersPageStore.permissionTableStore}
                        />
                    </AuthorizePermission>
                );
            case 2:
                return (
                    <AuthorizePermission
                        permissions={AllPermissions.role.read}
                        showWarning
                    >
                        <RoleTableTab store={usersPageStore.roleTableStore} />
                    </AuthorizePermission>
                );
            case 3:
                return (
                    <GroupTableTab store={usersPageStore.dcrGroupTableStore} />
                );
            // case 4:
            //     return <Claims claimStore={usersPageStore.claimTableStore} />;
        }
    };
    return (
        <Box>
            <AuthorizePermission
                permissions={AllPermissions.user.read}
                showWarning
            >
                <TabTitle title={t('pageNames.users')} />

                <PageTabs
                    index={usersPageStore.tabIndex}
                    onChange={(t) => {
                        usersPageStore.tabIndex = t;
                    }}
                >
                    <PageTab
                        label={t('usersPage.userTabs.users')}
                        testid="usersTab-users-tabs"
                    />

                    <PageTab
                        label={t('usersPage.userTabs.permissions')}
                        testid="usersTab-claims-tabs"
                    />
                    <PageTab
                        label={t('usersPage.userTabs.roles')}
                        testid="usersTab-roles-tabs"
                    />
                    <PageTab
                        label={t('usersPage.userTabs.dcrGroups')}
                        testid="usersTab-dcrGroups-tabs"
                    />
                    {/* <PageTab
                        label={t('usersPage.tabs.claims')}
                        testid="usersTab-claims-tabs"
                    /> */}
                </PageTabs>
                <Page style={{ flex: 1, width: '100%', height: '100%' }}>
                    {renderTabs(usersPageStore.tabIndex)}
                </Page>
            </AuthorizePermission>
        </Box>
    );
});
