/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {NameDto, NameDtoFromJSON, NameDtoToJSON,} from './NameDto';
import {PersonDto, PersonDtoFromJSON, PersonDtoToJSON,} from './PersonDto';
import {PhoneDto, PhoneDtoFromJSON, PhoneDtoToJSON,} from './PhoneDto';

/**
 * 
 * @export
 * @interface PersonPhoneDto
 */
export interface PersonPhoneDto {
    /**
     * 
     * @type {string}
     * @memberof PersonPhoneDto
     */
    externalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonPhoneDto
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonPhoneDto
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PersonPhoneDto
     */
    ticks?: number;
    /**
     * 
     * @type {string}
     * @memberof PersonPhoneDto
     */
    id: string;
    /**
     * 
     * @type {PhoneDto}
     * @memberof PersonPhoneDto
     */
    phone: PhoneDto;
    /**
     * 
     * @type {PersonDto}
     * @memberof PersonPhoneDto
     */
    person: PersonDto;
    /**
     * 
     * @type {NameDto}
     * @memberof PersonPhoneDto
     */
    phoneStatus: NameDto;
    /**
     * 
     * @type {NameDto}
     * @memberof PersonPhoneDto
     */
    phoneCategory: NameDto;
    /**
     * 
     * @type {NameDto}
     * @memberof PersonPhoneDto
     */
    phoneType: NameDto;
    /**
     * 
     * @type {string}
     * @memberof PersonPhoneDto
     */
    additionalNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonPhoneDto
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PersonPhoneDto
     */
    createdAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof PersonPhoneDto
     */
    modifiedAt?: Date | null;
}

export function PersonPhoneDtoFromJSON(json: any): PersonPhoneDto {
    return PersonPhoneDtoFromJSONTyped(json, false);
}

export function PersonPhoneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonPhoneDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'ticks': !exists(json, 'ticks') ? undefined : json['ticks'],
        'id': json['id'],
        'phone': PhoneDtoFromJSON(json['phone']),
        'person': PersonDtoFromJSON(json['person']),
        'phoneStatus': NameDtoFromJSON(json['phoneStatus']),
        'phoneCategory': NameDtoFromJSON(json['phoneCategory']),
        'phoneType': NameDtoFromJSON(json['phoneType']),
        'additionalNumber': !exists(json, 'additionalNumber') ? undefined : json['additionalNumber'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (json['modifiedAt'] === null ? null : new Date(json['modifiedAt'])),
    };
}

export function PersonPhoneDtoToJSON(value?: PersonPhoneDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'externalId': value.externalId,
        'createdBy': value.createdBy,
        'modifiedBy': value.modifiedBy,
        'ticks': value.ticks,
        'id': value.id,
        'phone': PhoneDtoToJSON(value.phone),
        'person': PersonDtoToJSON(value.person),
        'phoneStatus': NameDtoToJSON(value.phoneStatus),
        'phoneCategory': NameDtoToJSON(value.phoneCategory),
        'phoneType': NameDtoToJSON(value.phoneType),
        'additionalNumber': value.additionalNumber,
        'description': value.description,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt === null ? null : value.createdAt.toISOString()),
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt === null ? null : value.modifiedAt.toISOString()),
    };
}

