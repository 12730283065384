import { observer } from 'mobx-react';
import { useManagementStore } from '../stores';

interface Props {
    tab: number    
    children?: React.ReactNode
}

export const Wrapper = observer(({ tab, children }: Props) => {
    const store = useManagementStore();
    store.tabIndex = tab;
    return (
        <>
            {children}
        </>
    );
});