import {observer} from "mobx-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {Page, PageTab, PageTabs} from "../../components";
import {DetailsTab} from "./tabs/DetailsTab";
import {ClientItemStore} from "../../stores/management/clients/ClientItemStore";
import {JurisdictionsTab} from "./tabs/JurisdictionsTab";
import {PersonParams} from "../../icons/personParams";
import {OfficeBuilding} from "../../icons/office-building";
import {DcrSettingsTab} from "./tabs/DcrSettingsTab";
import {CrmSettingsTab} from "./tabs/CrmSettingsTab";
import {Dcr} from "../../icons/dcr";
import {Data} from "../../icons/data";

interface Props {
    store: ClientItemStore;
    style?: React.CSSProperties;
}

export const ClientDetails = observer(({store, style}: Props) => {
    const {t} = useTranslation();


    const renderTabs = (index: number) => {
        switch (index) {
            case 0:
                return <DetailsTab store={store.clientStore.detailsStore} style={{flex: 1}}/>;
            case 1:
                return <DcrSettingsTab store={store.clientStore.dcrSettingsStore} style={{flex: 1}}/>;
            case 2:
                return <CrmSettingsTab store={store.clientStore.crmSettingsStore} style={{flex: 1}}/>;
            case 5:
                return <JurisdictionsTab store={store.clientStore.jurisdictionsStore} style={{flex: 1}}/>;
            default:
                return null;
        }
    };


    return (
        <Page
            style={style}
        >
            <PageTabs
                index={store.tabIndex}
                onChange={t => store.tabIndex = t}>
                <PageTab icon={<PersonParams/>} title="ПАРАМЕТРЫ"/>
                <PageTab icon={<Data/>} title="НАСТРОЙКИ ДСР"/>
                <PageTab icon={<Dcr/>} title="НАСТРОЙКИ ОБМЕНА"/>
                <PageTab icon={<OfficeBuilding/>} title="ЮРИСДИКЦИИ"/>
            </PageTabs>
            <div style={{marginTop: 4, marginBottom: 4, padding: 20}}>
                {renderTabs(store.tabIndex)}
            </div>
        </Page>
    );
});
