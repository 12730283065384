import {Box, Card, Table, TableBody, TableHead, TableRow, Typography,} from '@mui/material';
import {observer} from 'mobx-react';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {
    AddButton,
    AuthorizePermission,
    Cell,
    ConfirmationForm,
    EmptyBox,
    ExpandButton,
    ExpandRow,
    ExpertDialog,
    HCell,
    Pagination,
    Progress,
    Search,
} from '../../components';
import {IDictionaryOperations, toDateTimeString} from '../../core';
import {callTheProcessingFunc} from '../../stores';
import {DictionaryTableStore} from '../../stores/management/dictionaries/DictionaryTableStore';
import {DictionaryCreator} from './DictionaryCreator';
import {DictionaryItemDetails} from './DictionaryItemDetails';

interface DetailsProps {
    store: DictionaryTableStore;
    sectionType: any;
    dictionaryType: IDictionaryOperations;
    dictionaryName: string;
}

export const DictionaryTable = observer(
    ({ store, dictionaryName, sectionType, dictionaryType }: DetailsProps) => {
        const { t } = useTranslation();
        return (
            <Box>
                <ExpertDialog
                    title="Удаление"
                    open={store.deleteDialogState}
                    onClose={() => (store.deleteDialogState = false)}
                >
                    <ConfirmationForm
                        disabled={store.processing}
                        onSubmit={async () =>
                            await callTheProcessingFunc(
                                store,
                                store.delete.bind(store),
                                store.pull.bind(store)
                            )()
                        }
                        onClose={() => (store.deleteDialogState = false)}
                    >
                        {t('common.deleteConfirm')} "
                        {store.current?.dto.nameRus}"?
                    </ConfirmationForm>
                </ExpertDialog>
                <Box
                    sx={{
                        flexGrow: 1,
                        py: 0,
                        backgroundColor: 'background.default',
                    }}
                >
                    <ExpertDialog
                        title={t(`create.${dictionaryName}`)}
                        open={store.creator.Open}
                        onClose={() => (store.creator.Open = false)}
                    >
                        <DictionaryCreator
                            store={store.creator}
                            placeholder={t(`table.${dictionaryName}`)}
                        />
                    </ExpertDialog>

                    <Box sx={{ mb: 2 }}>
                        <Box
                            sx={{
                                mt: 3,
                                mb: 3,
                            }}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Search
                                value={store.search.value}
                                onChange={(t) => (store.search.value = t)}
                                style={{ width: '87%', marginRight: 8 }}
                                testid={`personDictionaryTable-${dictionaryName}-search`}
                            />
                            <AuthorizePermission
                                permissions={dictionaryType.create}
                            >
                                <AddButton
                                    onClick={() => (store.creator.Open = true)}
                                    testid="personDictionaryTable-create-btn"
                                />
                            </AuthorizePermission>
                        </Box>
                        <Card sx={{ mb: 8, overflow: 'unset' }}>
                            <Table sx={{ minWidth: 1250 }}>
                                <TableHead
                                    style={{
                                        position: 'sticky',
                                        top: 63,
                                        zIndex: 999,
                                    }}
                                >
                                    <TableRow>
                                        <HCell
                                            width="5%"
                                            testid="organizationDictionaryTable-expand"
                                        />
                                        <HCell
                                            orderBy="nameRus"
                                            store={store}
                                            testid="organizationDictionaryTable-nameRus"
                                        >
                                            {t('table.name')} (Rus)
                                        </HCell>
                                        <HCell
                                            orderBy="nameEng"
                                            store={store}
                                            testid="organizationDictionaryTable-nameEng"
                                        >
                                            {t('table.name')} (Eng)
                                        </HCell>
                                        <HCell
                                            orderBy="modifiedAt"
                                            width="15%"
                                            testid="organizationDictionaryTable-modifiedAt"
                                            store={store}
                                        >
                                            {t('table.date')}
                                        </HCell>
                                        {
                                            store.isActiveStateUsed &&
                                            <AuthorizePermission permissions={dictionaryType.inactiveRead}>
                                                <HCell
                                                    width="15%"
                                                    store={store}
                                                >
                                                    Активный
                                                </HCell>
                                            </AuthorizePermission>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {store.items.length > 0 &&
                                        store.items.map((item) => {
                                            return (
                                                <Fragment key={item.dto.id}>
                                                    <TableRow
                                                        hover
                                                        onClick={(x) =>
                                                            (store.current =
                                                                item)
                                                        }
                                                        key={item.dto.id}
                                                    >
                                                        <ExpandButton
                                                            item={item}
                                                            isOpen={item.expand}
                                                            testid="organizationDictionaryTable"
                                                        />

                                                        <Cell testid="organizationDictionaryTable-nameRus">
                                                            <Typography
                                                                variant="subtitle2"
                                                                sx={{
                                                                    overflow:
                                                                        'hidden',
                                                                    textOverflow:
                                                                        'ellipsis',
                                                                }}
                                                            >
                                                                {
                                                                    item.dto
                                                                        .nameRus
                                                                }
                                                            </Typography>
                                                        </Cell>
                                                        <Cell testid="organizationDictionaryTable-nameEng">
                                                            <Typography
                                                                variant="subtitle2"
                                                                sx={{
                                                                    overflow:
                                                                        'hidden',
                                                                    textOverflow:
                                                                        'ellipsis',
                                                                }}
                                                            >
                                                                {
                                                                    item.dto
                                                                        .nameEng
                                                                }
                                                            </Typography>
                                                        </Cell>
                                                        <Cell testid="organizationDictionaryTable-modifiedAt">
                                                            <Typography
                                                                variant="subtitle2"
                                                                color="textSecondary"
                                                            >
                                                                {toDateTimeString(
                                                                    item.dto
                                                                        .modifiedAt
                                                                )}
                                                            </Typography>
                                                        </Cell>
                                                        {
                                                            store.isActiveStateUsed &&
                                                            <AuthorizePermission
                                                                permissions={dictionaryType.inactiveRead}>
                                                                <Cell>
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        color="textSecondary"
                                                                    >
                                                                        {item.dto.isInactive ? t("common.no") : t("common.yes")}
                                                                    </Typography>
                                                                </Cell>
                                                            </AuthorizePermission>
                                                        }
                                                    </TableRow>
                                                    {item.expand && (
                                                        <ExpandRow
                                                            key={`${item.dto.id}_`}
                                                            item={item}
                                                        >
                                                            <DictionaryItemDetails
                                                                dictionaryType={dictionaryType}
                                                                store={item}
                                                                placeholder={t(
                                                                    `table.${dictionaryName}`
                                                                )}
                                                                sectionType={
                                                                    sectionType
                                                                }
                                                            />
                                                        </ExpandRow>
                                                    )}
                                                </Fragment>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                            {!store.items.length && <EmptyBox />}
                            {store.items.length > 0 && (
                                <Pagination store={store} />
                            )}

                            {store.loading && (
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        py: 3,
                                    }}
                                >
                                    <Progress store={store} />
                                </Box>
                            )}
                        </Card>
                    </Box>
                </Box>
            </Box>
        );
    }
);
