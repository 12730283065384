import React from "react";
import {EmptyBlock2} from "./table";

export interface Props {
    children: any;
    isEmpty: boolean;
    position?: 'absolute' | 'relative',
    size?: number
}

export const MbEmptyComponent = ({children, isEmpty, position, size}: Props) => {

    return (
        <>
            {
                isEmpty ? <EmptyBlock2 position={position} size={size}/> : children
            }
        </>
    );
};
