import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Box, Card, Switch, Table, TableBody, TableHead, TableRow, Tooltip, Typography} from "@mui/material";
import {observer} from "mobx-react";
import React, {Fragment, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    Cell,
    ConfirmationForm,
    EmptyBox,
    ExpandButton,
    ExpandRow,
    ExpertDialog,
    HCell,
    Progress
} from '../../../components';
import {CopyToClipboard} from "../../../components/CopyToClipboard";
import {MenuComponent} from "../../../components/MenuComponent";
import {MenuItemComponent} from "../../../components/MenuItemComponent";
import {NavLink} from "../../../components/NavLink";
import {SeverityPill, SeverityPillColor} from "../../../components/severity-pill";
import {DefaultSelect} from "../../../components/shared/components/DefaultSelect";
import {DeleteIcon} from "../../../icons/deleteIcon";
import {Layers as LayersIcon} from '../../../icons/layers';
import {Scrollbar} from "../../../scrollbar";
import {PersonDto} from "../../../services/management";
import {useRootStore} from "../../../stores";
import {DoubleTableStore} from "../../../stores/management/persons/tabs/doubles/DoubleTableStore";
import {getPersonFullNameHandler} from "../../../stores/utils";
import {personStatusHandler} from "../Persons";
import {DoubleDetails} from "./DoubleDetails";

interface Props {
    store: DoubleTableStore;
    style: React.CSSProperties;
}

export const DoubleTable = observer(({store, style}: Props) => {
    const {t} = useTranslation();
    const {themeColorStore} = useRootStore();
    const currentColor = themeColorStore.themeSettings === 'dark' ? '#7582EB' : '#5048E5'
    const [openDetails, setOpenDetails] = useState<string | null>(null);

    const handleOpenDetails = (productId: string): void => {
        setOpenDetails((prevValue) => (prevValue === productId ? null : productId));
    };

    return (
        <>
            {
                store.current
                    ? (
                        <ExpertDialog
                            title={t('common.delete')}
                            open={store.current.deleteDialogState}
                            onClose={() => store.current ? store.current.deleteDialogState = false : false}
                        >
                            <ConfirmationForm
                                disabled={store.current.processing}
                                onSubmit={async () => {
                                    if (!!store?.current?._dto?.doubleId) {
                                        await store.current?.removeDoble();
                                    } else {
                                        store.items = store.items.filter(x => x.id !== store?.current?.id);
                                    }
                                }}
                                onClose={() => store.current ? store.current.deleteDialogState = false : false}
                            >
                                {t('common.deleteConfirm')} "{store.current._dto.firstName.nameRus} {store.current._dto.middleName?.nameRus} {store.current._dto.surname.nameRus} "?
                            </ConfirmationForm>
                        </ExpertDialog>
                    )
                    : ''
            }
            {
                store.current
                    ? (
                        <ExpertDialog
                            title={t('common.add')}
                            open={store.current.addDialogState}
                            onClose={() => store.current ? store.current.addDialogState = false : false}
                        >
                            <ConfirmationForm
                                disabled={store.current.processing}
                                submitButton={t('common.add')}
                                onSubmit={async () => {
                                    await store.current?.submitAntiDoble();
                                }}
                                onClose={() => store.current ? store.current.addDialogState = false : false}
                            >
                                {t('common.addConfirm')} "{store.current._dto.firstName.nameRus} {store.current._dto.middleName?.nameRus} {store.current._dto.surname.nameRus} "?
                            </ConfirmationForm>
                        </ExpertDialog>
                    )
                    : ''
            }
            <Box sx={{mb: 3}}>
                <Box sx={{display: 'flex'}}>
                    <Typography variant="h6" sx={{my: 'auto'}}>{t('common.antiDoble')}</Typography>
                    <Switch checked={store.antiDobleCheck}
                            disabled={store.store.isDouble || store.store.isDead}
                            data-testid="parameters-toggle"
                            onClick={() => {
                            }}
                    />
                    <Tooltip title={
                        <Box>
                            <p>{`1. ${t('helpText.personDouble.personFirst')}`}</p>
                            <p>{`2. ${t('helpText.personDouble.personSecond')}`}</p>
                        </Box>
                    }>
                        <InfoOutlinedIcon fontSize='small' sx={{my: 'auto'}}/>
                    </Tooltip>
                </Box>
                <DefaultSelect store={store.selectPerson}
                               disabled={store.store.isDouble || store.store.isDead}
                               testid="DoubleTable-selectPerson"
                               title={t('common.searchDoubleById')}
                               style={{width: '45%'}}
                               onValue={async () => {
                                   await store.addDoubleToTable();
                               }}
                               nameExp={(item: PersonDto) => getPersonFullNameHandler(item)}
                               hasLabel/>
            </Box>
            <Box>
                <Box sx={{mb: 1}}>
                    <Box sx={{display: 'flex'}}>
                        <Typography variant="h6"
                                    sx={{color: currentColor, my: 'auto'}}>{t('common.doubles')}</Typography>
                    </Box>
                    <Typography variant="caption">{t('common.doubleHelpText')}</Typography>
                    <Box sx={{alignItems: 'center', display: 'flex'}}>
                        <Box><LayersIcon sx={{height: 20, width: 20, pt: 0.5}}/></Box>
                        <Typography color="textSecondary" variant="overline">{store.count}</Typography>
                        <Tooltip title={'Указано кол-во добавленных Дублей'}>
                            <InfoOutlinedIcon fontSize='small' sx={{my: 'auto', ml: 1}}/>
                        </Tooltip>
                    </Box>
                </Box>
                <Card>
                    <Scrollbar>
                        <Table sx={{minWidth: 1100}}>
                            <TableHead>
                                <TableRow>
                                    <HCell style={{minWidth: 10}} testid="persons-expand"></HCell>
                                    <HCell orderBy='id' store={store} style={{width: '13%'}}
                                           testid="doubles-id">Id</HCell>
                                    <HCell orderBy='surname' store={store}
                                           testid="doubles-fullname">{t('table.surname')}</HCell>
                                    <HCell orderBy='name' store={store} testid="doubles-name">{t('table.name')}</HCell>
                                    <HCell orderBy='middleName' store={store}
                                           testid="doubles-middleName">{t('table.middleName')}</HCell>
                                    <HCell orderBy='personStatus' store={store}
                                           testid="doubles-status">{t('table.status')}</HCell>
                                    <HCell orderBy='speciality' store={store}
                                           testid="doubles-speciality">{t('table.speciality')}</HCell>
                                    <HCell orderBy='createdAt' store={store}
                                           testid="doubles-dateCreationlink">{t('table.dateCreationlink')}</HCell>
                                    <HCell store={store} testid="doubles-stateDouble">{t('table.state')}</HCell>
                                    {(!!store.items.find(x => x._edit) || store.isAdmin) &&
                                        <HCell store={store} style={{width: 100}}
                                               testid="doubles-action">{t('table.action')}</HCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    store.items.length > 0
                                        ? store.items.map(item => {
                                            const open = item.id === openDetails;
                                            return (
                                                <Fragment key={item.id}>
                                                    <TableRow
                                                        hover
                                                        key={item.id}
                                                        onClick={() => store.current = item}
                                                    >
                                                        <ExpandButton item={item} isOpen={open} testid="doubles"
                                                                      style={!item._dto.doubleId ? {} : {visibility: 'hidden'}}
                                                                      onClick={() => {
                                                                          if (!store.store.isDouble || store.store.isDead) {
                                                                              store.current = item;
                                                                              handleOpenDetails(item.id);
                                                                              item.openDetails();
                                                                          }
                                                                      }}
                                                        />
                                                        <Cell style={{padding: '18px 10px 18px 0', marginRight: 10}}
                                                              testid="doubles-id">
                                                            <Typography variant="subtitle2"
                                                                        style={{display: "flex", alignItems: 'center'}}>
                                                                {`${item.id.substring(0, 11)}...`}
                                                                <CopyToClipboard copyText={item.id}/>
                                                            </Typography>
                                                        </Cell>
                                                        <Cell style={{maxWidth: 150}} testid="doubles-surname">
                                                            <NavLink to={`/persons/${item.id}`} target="_blank">
                                                                <Typography variant="subtitle2" sx={{
                                                                    maxWidth: 150,
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}>
                                                                    {item._dto.surname.nameRus}
                                                                </Typography>
                                                            </NavLink>
                                                        </Cell>
                                                        <Cell
                                                            style={{maxWidth: 150}}
                                                            testid="doubles-name"
                                                        >
                                                            <NavLink to={`/persons/${item.id}`} target="_blank">
                                                                <Typography variant="subtitle2" sx={{
                                                                    maxWidth: 150,
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}
                                                                >
                                                                    {item._dto?.firstName?.nameRus}
                                                                </Typography>
                                                            </NavLink>
                                                        </Cell>
                                                        <Cell
                                                            style={{maxWidth: 150}}
                                                            testid="doubles-middleName"
                                                        >
                                                            <NavLink to={`/persons/${item.id}`} target="_blank">
                                                                <Typography variant="subtitle2" sx={{
                                                                    maxWidth: 150,
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}>
                                                                    {item._dto?.middleName?.nameRus}
                                                                </Typography>
                                                            </NavLink>
                                                        </Cell>
                                                        <Cell style={{width: 100}} testid="doubles-status">
                                                            <SeverityPill
                                                                color={personStatusHandler(item._dto.personStatus?.nameRus || '') as SeverityPillColor}>
                                                                <Tooltip
                                                                    title={item._dto.personStatus?.nameRus ? item._dto.personStatus?.nameRus : 'Работает'}>
                                                                    <Typography variant="caption" align={'left'} sx={{
                                                                        maxWidth: 100,
                                                                        textOverflow: 'ellipsis',
                                                                        overflow: 'hidden',
                                                                    }}>
                                                                        {item._dto.personStatus?.nameRus ? item._dto.personStatus?.nameRus : 'Работает'}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </SeverityPill>
                                                        </Cell>
                                                        <Cell testid="doubles-speciality" style={{width: 150}}>
                                                            <Typography variant="subtitle2" color="textSecondary">
                                                                {item._dto?.primarySpeciality?.nameRus}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell testid="doubles-createdAt" style={{width: 150}}>
                                                            <Typography variant="subtitle2" color="textSecondary">
                                                                {item.createdDate}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell testid="doubles-stateDouble" style={{width: 120}}>
                                                            <Typography variant="subtitle2" color={item.stateDouble.color}>
                                                                {item.stateDouble.title}
                                                            </Typography>
                                                        </Cell>
                                                        {(item._edit || store.isAdmin) &&
                                                            <Cell align={'center'}>
                                                                {
                                                                    item._edit &&
                                                                    <MenuComponent>
                                                                        <MenuItemComponent text={t('common.add')}
                                                                                           icon={<AddBoxOutlinedIcon
                                                                                               fontSize="small"
                                                                                               color="success"/>}
                                                                                           onClick={() => item.addDialogState = true}
                                                                                           style={{color: '#10B981'}}/>

                                                                    </MenuComponent>
                                                                }
                                                                {
                                                                    (store.isAdmin || item._edit) &&
                                                                    <MenuItemComponent text={t('common.delete')}
                                                                                       icon={<DeleteIcon
                                                                                           fontSize="small"/>}
                                                                                       onClick={() => item.deleteDialogState = true}
                                                                                       style={{color: '#D14343'}}/>
                                                                }
                                                            </Cell>
                                                        }
                                                    </TableRow>
                                                    {
                                                        open && (
                                                            <ExpandRow key={`${item.id}_`} item={item}
                                                                       style={{paddingRight: 0}}>
                                                                {!item._dto.doubleId &&
                                                                    <DoubleDetails prop={item}/>
                                                                }
                                                            </ExpandRow>)
                                                    }
                                                </Fragment>
                                            )
                                        })
                                        :
                                        <Cell colSpan={10}>
                                            <EmptyBox/>
                                        </Cell>
                                }
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </Card>
            </Box>
            {/* {store.items.length > 1 &&
                <Box sx={{ mt: 1 }}>
                    <NavLink to={`/persons/${store?.store?.id}`}>
                        <SimpleButton
                            title="посмотреть полный список"
                            variant='outlined'
                            onClick={() => {}}
                            sx={{ width: '100%' }}
                        />
                    </NavLink>
                    <Tooltip title={'Для просмотра полного списка нажмите на кнопку'}>
                        <InfoOutlinedIcon fontSize='small' sx={{ mb: '-6px' }} />
                    </Tooltip>
                </Box>
            } */
            }
            <Progress
                store={{processing: store.childProcessing || store.loading || store.processing}}/>
        </>
    )
        ;
});
