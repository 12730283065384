import {getApiConfiguration} from '../../../../../core';
import {ClinicCategoriesApi} from "../../../../../services/management";
import {DictionaryCreateEntity, DictionaryEntity, DictionaryTableStore} from "../../DictionaryTableStore";
import {DictionaryOrganizationsStore} from "../DictionaryOrganizationsStore";

export class ClinicCategoryTableStore extends DictionaryTableStore {
    isActiveStateUsed = true;

    constructor(store: DictionaryOrganizationsStore) {
        super(store, 'clinic-category-store');
    }

    async Create(model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new ClinicCategoriesApi(getApiConfiguration()).createClinicCategory({setClinicCategoryDto: model});
    }

    async Delete(id: string): Promise<DictionaryEntity> {
        return await new ClinicCategoriesApi(getApiConfiguration()).deleteClinicCategory({id: id});
    }


    async GetCount(search: string): Promise<{ count: number }> {
        return await new ClinicCategoriesApi(getApiConfiguration()).getClinicCategoriesCount({search: search});
    }

    async GetList(filters: any): Promise<DictionaryEntity[]> {
        return await new ClinicCategoriesApi(getApiConfiguration()).getClinicCategories(filters)
    }

    async Update(id: string, model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new ClinicCategoriesApi(getApiConfiguration()).updateClinicCategory({id: id, setClinicCategoryDto: model});
    }
}
