import type {FC, MutableRefObject} from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';

import {Drawer, Theme, useMediaQuery} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Scrollbar} from "../../scrollbar";

interface DcrDrawerProps {
    containerRef?: MutableRefObject<HTMLDivElement | null>;
    open?: boolean;
    onClose?: () => void;
    children?: React.ReactNode
}

const OrderDrawerDesktop = styled(Drawer)(({theme}) => ({
    marginTop: 65,
    boxShadow: 'none',
    '& .MuiDrawer-paper': {
        // position: 'relative',
        marginTop: 63,
        width: '70%',
    }
}));

const OrderDrawerMobile = styled(Drawer)({
    flexShrink: 0,
    maxWidth: '100%',
    height: 'calc(100% - 64px)',
    width: 500,
    marginTop: 16,
    '& .MuiDrawer-paper': {
        height: 'calc(100% - 64px)',
        maxWidth: '100%',
        top: 64,
        width: 500
    }
});

export const DcrDrawer: FC<DcrDrawerProps> = (props) => {
    const {containerRef, onClose, open, children, ...other} = props;
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    if (lgUp) {
        return (
            <OrderDrawerDesktop
                anchor="right"
                open={open}
                SlideProps={{container: containerRef?.current}}
                variant="persistent"
                // variant="temporary"
                {...other}
                // transitionDuration={100}
                PaperProps={{
                    sx: {
                        borderBottomColor: 'divider',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: 1,
                    }
                }}
            >
                {children}
            </OrderDrawerDesktop>
        );
    }

    return (
        <OrderDrawerMobile
            anchor="right"
            ModalProps={{container: containerRef?.current}}
            onClose={onClose}
            open={open}
            SlideProps={{container: containerRef?.current}}
            variant="temporary"
            {...other}
        >
            {children}
        </OrderDrawerMobile>
    );
};

DcrDrawer.propTypes = {
    containerRef: PropTypes.any,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    // @ts-ignore
    order: PropTypes.object
};
