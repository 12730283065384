import type {ApexOptions} from 'apexcharts';
import Box from '@mui/material/Box';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {Chart} from "../chart";
import {useTheme} from '@mui/material/styles';
import {Theme} from "@mui/material";
import {alpha} from "@mui/system/colorManipulator";

const useChartOptions = (categories: string[], values: any[]): ApexOptions => {
    const theme = useTheme();

    const generateColor = () => {
        return '#' + Math.random().toString(16).substr(-6);
    }

    const colors = values.map(x => generateColor())

    return {
        tooltip: {
            enabled: true,
            intersect: false,
            shared: true,
            cssClass: 'tooltip-bar-chart',
            x: {
                show: false
            },

        },
        legend: {
            horizontalAlign: 'center',
            labels: {
                colors: theme.palette.text.secondary
            },
            position: 'bottom',
            show: true,
            formatter(legendName: string, opts?: any): string {
                const data = opts.w.globals.initialSeries[opts.seriesIndex];
                return data.name + " (" + (data.data.length > 0 ? data.data.reduce((x: number,y: number) => x + y) : 0 )+ ")"
            },
        },
        chart: {
            background: 'transparent',
            stacked: true,
            toolbar: {
                show: false
            }
        },
        colors: colors,
        dataLabels: {
            enabled: false
        },
        grid: {
            borderColor: theme.palette.divider,
            strokeDashArray: 2
        },
        plotOptions: {
            bar: {
                columnWidth: '32px'
            }
        },
        theme: {
            mode: theme.palette.mode
        },
        xaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            categories: categories,
            min: 1,
            max: categories.length,
            labels: {
                style: {
                    colors: theme.palette.text.secondary
                }
            }
        },
        yaxis: {
            labels: {
                show: true
            }
        }
    };
};


const createChartOptions = (theme: Theme, color: string): ApexOptions => {
    return {
        chart: {
            background: 'transparent'
        },
        colors: [color],
        labels: ['Health'],
        plotOptions: {
            radialBar: {
                dataLabels: {
                    name: {
                        show: true,
                        color: theme.palette.text.secondary,
                        fontSize: '12px',
                        fontWeight: 400,
                        offsetY: 20
                    },
                    value: {
                        color: theme.palette.text.primary,
                        fontSize: '18px',
                        fontWeight: 600,
                        offsetY: -20
                    }
                },
                hollow: {
                    size: '50%'
                },
                track: {
                    background: alpha(color, 0.12)
                }
            }
        },
        states: {
            active: {
                filter: {
                    type: 'none'
                }
            },
            hover: {
                filter: {
                    type: 'none'
                }
            }
        },
        theme: {
            mode: theme.palette.mode
        }
    };
};

export interface BarChartData {
    name: string,
    data: number[]
}

export interface BarChartProps {
    data: BarChartData[];
    settings?: ApexOptions;
    periods: string[]
}

export const BarChart = observer((props: BarChartProps) => {
    const {t} = useTranslation();
    const chartOptions = {...useChartOptions(props.periods, props.data), ...props.settings};

    return (
        <Box>
            <Chart
                height={350}
                options={chartOptions}
                series={props.data}
                type="bar"
            />
        </Box>
    );
});