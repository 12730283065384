import {makeAutoObservable} from "mobx";
import {getObject, IExpanded, IProcessing, setObject} from "../../../../core";
import {ResponseDto} from "../../../../services/dcr";
import {ResponseTableStore} from "./ResponseTableStore";
import {ResponseDetailsStore} from "./ResponseDetailsStore";
import {
    mappingDcrStatusToString,
    mappingRequestStatusToString,
    mappingRequestTypeToString,
    mappingResponseStatusToString
} from "../services";
import {DCRStatusEnum} from "../types";

export class ResponseItemStore implements IExpanded, IProcessing {
    readonly _store: ResponseTableStore;
    dto: ResponseDto;
    details: ResponseDetailsStore;
    processing: boolean = false;

    private _expand: boolean = false;

    constructor(store: ResponseTableStore, dto: ResponseDto) {
        this._store = store;
        this.dto = dto;
        this.details = new ResponseDetailsStore(this, dto);
        const filters = getObject<IExpanded>(`responseItem-tore-${this.dto.requestId}`);
        if (filters) {
            this._expand = filters.expand;
        }

        if (this._expand) {
            this.details.pull();
        }

        makeAutoObservable(this);
    }

    get expand() {
        return this._expand;
    }

    set expand(value: boolean) {
        this._expand = value;
        this.saveFilters();
        if (this.expand) {
            this.details.pull();
        }
    }

    saveFilters() {
        setObject<IExpanded>(`responseItem-tore-${this.dto.requestId}`, {
            expand: this._expand
        });
    }

    get requestStatusName(): string {
        return mappingRequestStatusToString(this.dto.requestStatus)
    }

    get requestTypeName(): string {
        return mappingRequestTypeToString(this.dto.requestType)
    }

    get responseStatusName(): string {
        return mappingResponseStatusToString(this.dto.status)
    }

    get dcrStatusName(): string {
        return mappingDcrStatusToString(this.dto.dcrStatus as DCRStatusEnum)
    }
}
