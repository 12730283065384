import {Box} from "@mui/material";
import {observer} from "mobx-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {ParamsButtonGroup} from "../../../../components";
import {DefaultSelect} from "../../../../components/shared/components/DefaultSelect";
import {
    OrganizationEmployeesItemStore
} from "../../../../stores/management/organizations/details/employees/OrganizationEmployeesItemStore";

interface Props {
    store: OrganizationEmployeesItemStore;
    style?: React.CSSProperties;
}

export const OrganizationEmployeeDetails = observer(({store, style}: Props) => {
    const {t} = useTranslation();

    return (
        <Box style={style} sx={{pl: 2, pt: 2, pr: 0}}>

            <Box sx={{display: "flex", justifyContent: "space-between", mb: 2}}>
                <DefaultSelect store={store.status}
                               testid="organizationEmployeeDetails-status"
                               disabled={store._store.isDouble}
                               title={t('common.status')}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <ParamsButtonGroup
                    testid="organizationEmployeeDetails"
                    onCancel={() => store.reset()}
                    onSave={async () => {
                        if ('organizationDep' in store.dto) {
                            await store.saveDep()
                        } else {
                            await store.save()
                        }
                    }}
                    isCancelBtnDisabled={store.processing || store.equals}
                    isSaveBtnDisabled={store.processing || store.equals || !store.valid || store._store.isDouble}
                />
            </Box>
        </Box>
    );
});
