import { Box, Breadcrumbs, Card, Container, Typography } from "@mui/material";
import { observer } from 'mobx-react';
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { PageTab, PageTabs } from '../../../../components';
import { PersonJob } from "../../../../icons/personJob";
import { PersonParams } from "../../../../icons/personParams";
import { useManagementStore } from "../../../../stores";
import { RoleItemStore } from "../../../../stores/management/users/tabs/Roles/RoleItemStore";
import { ParametersTab } from "./tabs/ParametersTab";
import { UsersTab } from "./tabs/UsersTab";
import { PermissionTableTab } from "./tabs/permissions/PermissionTableTab";

export const Role = observer(() => {
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams<{ id: string }>();
    const { roleTableStore } = useManagementStore().usersPageStore;
    const [role, setRole] = useState<RoleItemStore | undefined | null>();
    const { t } = useTranslation();

    useEffect(() => {
        get();
    }, [id]);

    const get = async () => {
        try {
            if (id) {
                const f = await roleTableStore.getRole(id);
                setRole(f);
            }
        } catch (e) {
            enqueueSnackbar(`Ошибка при загрузке юзера`);
        }
    };

    const renderTabs = (index: number) => {
        if (role) {
            switch (index) {
                case 0:
                    return <ParametersTab store={role.parameter} style={{ flex: 1 }} />;
                case 1:
                    return <UsersTab store={role.roleUsersTabStore} style={{ flex: 1 }} />;
                case 2:
                    return <PermissionTableTab store={role.permissionsTabStore} style={{ flex: 1 }} />;
                default:
                    return null;

            }
        }
    };

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 3,
                backgroundColor: 'background.default',
            }}
        >
            <Container maxWidth="xl">
                <Box sx={{
                    mb: 3
                }}>
                    {
                        role
                            ? (
                                <Breadcrumbs
                                    separator="/"
                                    sx={{ mt: 1 }}
                                >

                                    <Link to="/users" style={{ textDecoration: 'none' }}>
                                        <Typography color="primary"
                                            variant="h6">{t('rolesPage.rolesTabs.roles')}</Typography>
                                    </Link>
                                    <Typography color="textSecondary"
                                        variant="h6">
                                        {role.dto.name}
                                    </Typography>
                                </Breadcrumbs>
                            )
                            : '...'
                    }

                </Box>
                <Card
                    style={{ display: 'flex', flexDirection: 'column' }}
                    sx={{
                        px: 2,
                        mt: 2
                    }}
                >
                    {
                        role
                            ? (
                                <>
                                    <PageTabs
                                        index={role.tabIndex}
                                        onChange={t => role.tabIndex = t} style={{ width: '100%' }}>
                                        <PageTab icon={<PersonParams />} label={t('usersPage.roles.tabs.parameters')}
                                            style={{ width: '31%' }} />
                                        <PageTab icon={<PersonJob />} label={t('usersPage.roles.tabs.users')}
                                            style={{ width: '31%' }} />
                                        <PageTab icon={<PersonJob />} label={t('usersPage.roles.tabs.permissions')}
                                            style={{ width: '31%' }} />
                                    </PageTabs>

                                    <Box sx={{ mt: 2, mb: 2 }}>
                                        {renderTabs(role.tabIndex)}
                                    </Box>
                                </>
                            )
                            : '...'
                    }
                </Card>
            </Container>
        </Box>
    );
});
