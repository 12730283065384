/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface SetRolePermissionDto
 */
export interface SetRolePermissionDto {
    /**
     * 
     * @type {string}
     * @memberof SetRolePermissionDto
     */
    roleId: string;
    /**
     * 
     * @type {string}
     * @memberof SetRolePermissionDto
     */
    permissionId: string;
}

export function SetRolePermissionDtoFromJSON(json: any): SetRolePermissionDto {
    return SetRolePermissionDtoFromJSONTyped(json, false);
}

export function SetRolePermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetRolePermissionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roleId': json['roleId'],
        'permissionId': json['permissionId'],
    };
}

export function SetRolePermissionDtoToJSON(value?: SetRolePermissionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roleId': value.roleId,
        'permissionId': value.permissionId,
    };
}

