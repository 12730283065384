/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Exchange.Analytics.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientsAggregation
 */
export interface ClientsAggregation {
    /**
     * 
     * @type {string}
     * @memberof ClientsAggregation
     */
    clientId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientsAggregation
     */
    clientName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClientsAggregation
     */
    count?: number;
}

export function ClientsAggregationFromJSON(json: any): ClientsAggregation {
    return ClientsAggregationFromJSONTyped(json, false);
}

export function ClientsAggregationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientsAggregation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'clientName': !exists(json, 'clientName') ? undefined : json['clientName'],
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function ClientsAggregationToJSON(value?: ClientsAggregation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientId': value.clientId,
        'clientName': value.clientName,
        'count': value.count,
    };
}

