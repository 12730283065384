import { makeAutoObservable } from "mobx";
// import { PersonDto } from "../../../../services/management";
import {PersonItemStore} from "../../PersonItemStore";
import {JobArchiveTableStore} from "./jobArchive/JobArchiveTableStore";
import {JobDoubleTableStore} from "./jobDouble/JobDoubleTableStore";
import {JobsTableStore} from "./JobsTableStore";
// import { OrganizationDto } from "../../../../../services/management";
// import { OrganizationItemStore } from "../../OrganizationItemStore";
// import { OrganizationChildsTableStore } from "./OrganizationChildsTableStore";
// import { OrganizationParentStore } from "./OrganizationParentStore";

export class JobsTabsStore {
    readonly store: PersonItemStore;
    // dto: PersonDto;
    private _tabIndex = 0;
    private _expand: boolean = false;
    JobsStore: JobsTableStore;
    JobDoubleStore: JobDoubleTableStore;
    JobArchiveStore: JobArchiveTableStore;
    // ParentStore: OrganizationParentStore;
    // ChildStore: OrganizationChildsTableStore;

    constructor(store: PersonItemStore) {
    // constructor(store: PersonItemStore, dto: OrganizationDto) {
        this.store = store;
        // this.dto = dto;

        // this.ParentStore = new OrganizationParentStore(this, this.dto);
        // this.ChildStore = new OrganizationChildsTableStore(this, this.dto);
        this.JobsStore = new JobsTableStore(store);
        this.JobDoubleStore = new JobDoubleTableStore(store);
        this.JobArchiveStore = new JobArchiveTableStore(store);

        makeAutoObservable(this);
    }

    get expand() {
        return this._expand;
    }

    async setExpand(value: boolean) {
        this._expand = value;
        if (this.expand) {
            await this.handleTabChange();
        }
    }

    get tabIndex() {
        return this._tabIndex;
    }

    async setTabIndex(value: number) {
        this._tabIndex = value;
        await this.handleTabChange();
    }

    // update(dto: OrganizationDto) {
    //     this.ParentStore.update(dto)
    // }

    async handleTabChange() {
        if(this._tabIndex == 0){
            await this.JobsStore.pull()
        }
        if (this._tabIndex === 1) {
            await this.JobDoubleStore.pull()
        }
        if (this._tabIndex === 2) {
            await this.JobArchiveStore.pull()
        }
    }
}
