import {Box} from "@mui/material";
import {observer} from "mobx-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {AuthorizePermission, DeleteButton, FormRoot} from "../../components";
import {DefaultValue} from "../../components/shared/components/DefaultValue";
import {JurisdictionItemStore} from "../../stores/management/jurisdictions/JurisdictionItemStore";
import {AllPermissions} from "../../core/const";

interface Props {
    store: JurisdictionItemStore;
    style?: React.CSSProperties;
}

export const JurisdictionDetails = observer(({store, style}: Props) => {
    const {t} = useTranslation();

    return (
        <FormRoot style={style}>
            <DefaultValue title={t('jurisdictions.nameRus')}
                          disabled={true}
                          store={store.details.fields.nameRus}/>
            <DefaultValue title={t('jurisdictions.nameEng')}
                          disabled={true}
                          store={store.details.fields.nameEng}/>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',

                }}
            >
                <AuthorizePermission permissions={AllPermissions.jurisdiction.delete} showWarning>
                    <DeleteButton onClick={() => {
                        store.store.current = store
                        store.store.deleteDialogState = true
                    }} disabled={store.details.processing}/>
                </AuthorizePermission>

            </Box>
        </FormRoot>
    );
});
