/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OCMObjectState,
    OCMObjectStateFromJSON,
    OCMObjectStateToJSON,
    PhoneTypeDto,
    PhoneTypeDtoFromJSON,
    PhoneTypeDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetPhoneTypeDto,
    SetPhoneTypeDtoFromJSON,
    SetPhoneTypeDtoToJSON,
} from '../models';

export interface CreatePersonPhoneTypeRequest {
    setPhoneTypeDto: SetPhoneTypeDto;
}

export interface DeletePersonPhoneTypeRequest {
    id: string;
}

export interface GetPersonPhoneTypeByIdRequest {
    id: string;
}

export interface GetPersonPhoneTypeByNameRequest {
    name: string;
}

export interface GetPersonPhoneTypesRequest {
    search?: string;
    orderBy?: string;
    searchByExternalId?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface GetPersonPhoneTypesCountRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface UpdatePersonPhoneTypeRequest {
    id: string;
    setPhoneTypeDto: SetPhoneTypeDto;
}

/**
 * 
 */
export class PersonPhoneTypesApi extends runtime.BaseAPI {

    /**
     */
    async createPersonPhoneTypeRaw(requestParameters: CreatePersonPhoneTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneTypeDto>> {
        if (requestParameters.setPhoneTypeDto === null || requestParameters.setPhoneTypeDto === undefined) {
            throw new runtime.RequiredError('setPhoneTypeDto','Required parameter requestParameters.setPhoneTypeDto was null or undefined when calling createPersonPhoneType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/phones/types/person`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPhoneTypeDtoToJSON(requestParameters.setPhoneTypeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async createPersonPhoneType(requestParameters: CreatePersonPhoneTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneTypeDto> {
        const response = await this.createPersonPhoneTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePersonPhoneTypeRaw(requestParameters: DeletePersonPhoneTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePersonPhoneType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/types/person/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async deletePersonPhoneType(requestParameters: DeletePersonPhoneTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneTypeDto> {
        const response = await this.deletePersonPhoneTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonPhoneTypeByIdRaw(requestParameters: GetPersonPhoneTypeByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPersonPhoneTypeById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/types/person/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonPhoneTypeById(requestParameters: GetPersonPhoneTypeByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneTypeDto> {
        const response = await this.getPersonPhoneTypeByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonPhoneTypeByNameRaw(requestParameters: GetPersonPhoneTypeByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneTypeDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getPersonPhoneTypeByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/types/person/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonPhoneTypeByName(requestParameters: GetPersonPhoneTypeByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneTypeDto> {
        const response = await this.getPersonPhoneTypeByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonPhoneTypesRaw(requestParameters: GetPersonPhoneTypesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PhoneTypeDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/types/person`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PhoneTypeDtoFromJSON));
    }

    /**
     */
    async getPersonPhoneTypes(requestParameters: GetPersonPhoneTypesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PhoneTypeDto>> {
        const response = await this.getPersonPhoneTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonPhoneTypesCountRaw(requestParameters: GetPersonPhoneTypesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/types/person/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonPhoneTypesCount(requestParameters: GetPersonPhoneTypesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPersonPhoneTypesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePersonPhoneTypeRaw(requestParameters: UpdatePersonPhoneTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePersonPhoneType.');
        }

        if (requestParameters.setPhoneTypeDto === null || requestParameters.setPhoneTypeDto === undefined) {
            throw new runtime.RequiredError('setPhoneTypeDto','Required parameter requestParameters.setPhoneTypeDto was null or undefined when calling updatePersonPhoneType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/phones/types/person/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetPhoneTypeDtoToJSON(requestParameters.setPhoneTypeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async updatePersonPhoneType(requestParameters: UpdatePersonPhoneTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneTypeDto> {
        const response = await this.updatePersonPhoneTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
