/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CategoryLocalityDto,
    CategoryLocalityDtoFromJSON,
    CategoryLocalityDtoToJSON,
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetCategoryLocalityDto,
    SetCategoryLocalityDtoFromJSON,
    SetCategoryLocalityDtoToJSON,
} from '../models';

export interface CreateCategoryLocalityRequest {
    setCategoryLocalityDto: SetCategoryLocalityDto;
}

export interface DeleteCategoryLocalityRequest {
    id: string;
}

export interface GetCategoryLocalitiesRequest {
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetCategoryLocalitiesCountRequest {
    search?: string;
}

export interface GetCategoryLocalityByIdRequest {
    id: string;
}

export interface GetCategoryLocalityByNameRequest {
    name: string;
}

export interface UpdateCategoryLocalityRequest {
    id: string;
    setCategoryLocalityDto: SetCategoryLocalityDto;
}

/**
 * 
 */
export class CategoryLocalitiesApi extends runtime.BaseAPI {

    /**
     */
    async createCategoryLocalityRaw(requestParameters: CreateCategoryLocalityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CategoryLocalityDto>> {
        if (requestParameters.setCategoryLocalityDto === null || requestParameters.setCategoryLocalityDto === undefined) {
            throw new runtime.RequiredError('setCategoryLocalityDto','Required parameter requestParameters.setCategoryLocalityDto was null or undefined when calling createCategoryLocality.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/addresses/locality/categories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetCategoryLocalityDtoToJSON(requestParameters.setCategoryLocalityDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryLocalityDtoFromJSON(jsonValue));
    }

    /**
     */
    async createCategoryLocality(requestParameters: CreateCategoryLocalityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CategoryLocalityDto> {
        const response = await this.createCategoryLocalityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteCategoryLocalityRaw(requestParameters: DeleteCategoryLocalityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CategoryLocalityDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCategoryLocality.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/locality/categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryLocalityDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteCategoryLocality(requestParameters: DeleteCategoryLocalityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CategoryLocalityDto> {
        const response = await this.deleteCategoryLocalityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCategoryLocalitiesRaw(requestParameters: GetCategoryLocalitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<CategoryLocalityDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/locality/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryLocalityDtoFromJSON));
    }

    /**
     */
    async getCategoryLocalities(requestParameters: GetCategoryLocalitiesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<CategoryLocalityDto>> {
        const response = await this.getCategoryLocalitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCategoryLocalitiesCountRaw(requestParameters: GetCategoryLocalitiesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/locality/categories/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getCategoryLocalitiesCount(requestParameters: GetCategoryLocalitiesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getCategoryLocalitiesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCategoryLocalityByIdRaw(requestParameters: GetCategoryLocalityByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CategoryLocalityDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCategoryLocalityById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/locality/categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryLocalityDtoFromJSON(jsonValue));
    }

    /**
     */
    async getCategoryLocalityById(requestParameters: GetCategoryLocalityByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CategoryLocalityDto> {
        const response = await this.getCategoryLocalityByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCategoryLocalityByNameRaw(requestParameters: GetCategoryLocalityByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CategoryLocalityDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getCategoryLocalityByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/locality/categories/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryLocalityDtoFromJSON(jsonValue));
    }

    /**
     */
    async getCategoryLocalityByName(requestParameters: GetCategoryLocalityByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CategoryLocalityDto> {
        const response = await this.getCategoryLocalityByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateCategoryLocalityRaw(requestParameters: UpdateCategoryLocalityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CategoryLocalityDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCategoryLocality.');
        }

        if (requestParameters.setCategoryLocalityDto === null || requestParameters.setCategoryLocalityDto === undefined) {
            throw new runtime.RequiredError('setCategoryLocalityDto','Required parameter requestParameters.setCategoryLocalityDto was null or undefined when calling updateCategoryLocality.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/addresses/locality/categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetCategoryLocalityDtoToJSON(requestParameters.setCategoryLocalityDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryLocalityDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateCategoryLocality(requestParameters: UpdateCategoryLocalityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CategoryLocalityDto> {
        const response = await this.updateCategoryLocalityRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
