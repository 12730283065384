/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    PersonJobDto,
    PersonJobDtoFromJSON,
    PersonJobDtoFromJSONTyped,
    PersonJobDtoToJSON,
} from './PersonJobDto';
import {
    TagDto,
    TagDtoFromJSON,
    TagDtoFromJSONTyped,
    TagDtoToJSON,
} from './TagDto';

/**
 * 
 * @export
 * @interface AssignedPersonJobTagDto
 */
export interface AssignedPersonJobTagDto {
    /**
     * 
     * @type {string}
     * @memberof AssignedPersonJobTagDto
     */
    id?: string | null;
    /**
     * 
     * @type {PersonJobDto}
     * @memberof AssignedPersonJobTagDto
     */
    personJob?: PersonJobDto;
    /**
     * 
     * @type {TagDto}
     * @memberof AssignedPersonJobTagDto
     */
    jobTag?: TagDto;
}

export function AssignedPersonJobTagDtoFromJSON(json: any): AssignedPersonJobTagDto {
    return AssignedPersonJobTagDtoFromJSONTyped(json, false);
}

export function AssignedPersonJobTagDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignedPersonJobTagDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'personJob': !exists(json, 'personJob') ? undefined : PersonJobDtoFromJSON(json['personJob']),
        'jobTag': !exists(json, 'jobTag') ? undefined : TagDtoFromJSON(json['jobTag']),
    };
}

export function AssignedPersonJobTagDtoToJSON(value?: AssignedPersonJobTagDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'personJob': PersonJobDtoToJSON(value.personJob),
        'jobTag': TagDtoToJSON(value.jobTag),
    };
}

