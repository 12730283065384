import {Box, Card, Table, TableBody, TableHead, TableRow, Tooltip, Typography} from "@mui/material";
import {observer} from "mobx-react";
import {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import {Cell, EmptyBox, ExpandButton, ExpandRow, HCell, Progress, SimpleButton} from "../../../components";
import {SeverityPill, SeverityPillColor} from "../../../components/severity-pill";
import {Scrollbar} from "../../../scrollbar";
import {ExportStatus} from "../../../services/export";
import {ExportTableStore} from "../../../stores/management/data/export/ExportTableStore";
import {ExportDetails} from "./ExportDetails";
import {callTheProcessingFunc} from "../../../stores";

interface Props {
    store: ExportTableStore;
    tabsName?: string;
    children?: React.ReactNode;
}

export const ExportTable = observer(({ store, tabsName, children }: Props) => {
    const { t } = useTranslation();
    const [openDetails, setOpenDetails] = useState<string | null>(null);

    const colorStatusHandler = (status: ExportStatus) => {
        if (status === 0) {
            return 'error'
        } else if (status === 3) {
            return 'success'
        } else {
            return 'warning'
        }
    }

    const exportStatus = (status: ExportStatus) => {
        switch (status) {
            case 0:
                return t('dataPage.exportStatus.error')
            case 1:
                return t('dataPage.exportStatus.pending')
            case 2:
                return t('dataPage.exportStatus.inProcess')
        }
        return t('dataPage.exportStatus.success')
    }

    const handleOpenDetails = (productId: string): void => {
        setOpenDetails((prevValue) => (prevValue === productId ? null : productId));
    };

    return (
        <>
            {children}
            <Box
                sx={{
                    flexGrow: 1,
                    py: 1,
                    backgroundColor: 'background.default',
                }}
            >
                {/* <Container maxWidth="xl"> */}
                <Card sx={{ mb: 8 }}>
                    <Scrollbar>
                        <Table sx={{ minWidth: 850 }}>
                            <TableHead>
                                <TableRow>
                                    <HCell width='5%'></HCell>
                                    <HCell store={store} width='30%'>{t('dataPage.table.name')}</HCell>
                                    <HCell store={store} width='15%'>{t('dataPage.table.modifiedDate')}</HCell>
                                    <HCell store={store} width='15%'>{t('dataPage.table.status')}</HCell>
                                    <HCell store={store} width='20%' align={'center'}>{t('dataPage.table.action')}</HCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {store.items.length > 0 && store.items.map((item) => {
                                    const open = item.id === openDetails;
                                    return (
                                        <Fragment key={item.id}>
                                            <TableRow
                                                hover
                                                key={item.id}
                                            >
                                                <ExpandButton item={item} isOpen={open}
                                                    onClick={() => handleOpenDetails(item?.id ?? '')} />
                                                <Cell>
                                                    <Typography variant="subtitle2" color="textSecondary">
                                                        {item.name.value}
                                                    </Typography>
                                                </Cell>
                                                <Cell>
                                                    <Typography variant="subtitle2" color="textSecondary">
                                                        {item.modifiedAt}
                                                    </Typography>
                                                </Cell>
                                                <Cell>
                                                    <SeverityPill
                                                        color={colorStatusHandler(item.status || 0) as SeverityPillColor}>
                                                        <Tooltip
                                                            title={item.status}>
                                                            <Typography variant="caption" align={'left'} sx={{
                                                                maxWidth: 160,
                                                                textOverflow: 'ellipsis',
                                                                overflow: 'hidden',
                                                            }}>
                                                                {exportStatus(item.status)}
                                                            </Typography>
                                                        </Tooltip>
                                                    </SeverityPill>
                                                    {/* <Typography variant="subtitle2" color="textSecondary">
                                                            {item.name.value}
                                                        </Typography> */}
                                                </Cell>
                                                <Cell>
                                                    <Box style={{ display: 'flex', justifyContent: 'center' }}>

                                                        {/* <AuthorizePermission permissions=""> */}
                                                        <>
                                                            <SimpleButton
                                                                onClick={async () => await callTheProcessingFunc(item, item.exportFile.bind(this))()}
                                                                title={t('dataPage.dataTabs.export')}
                                                                style={{ width: 165 }}
                                                                disabled={item.status !== 3 || item.processing}
                                                            />
                                                        </>
                                                        {/* </AuthorizePermission> */}
                                                    </Box>
                                                </Cell>
                                            </TableRow>
                                            {open && (
                                                <ExpandRow key={`${item.id}_`} item={item}>
                                                    <ExportDetails
                                                        store={item}
                                                        style={{
                                                            margin: '0 40px'
                                                        }}
                                                    />
                                                </ExpandRow>)}
                                        </Fragment>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {!store.items.length && <EmptyBox />}
                    </Scrollbar>

                    {/* {(organizationTableStore.items.length > 0 || !!organizationTableStore.count) && <Pagination store={organizationTableStore}/> } */}
                    {store.loading && <Box
                        sx={{
                            flexGrow: 1,
                            py: 3,
                        }}
                    >
                        <Progress store={store} />
                    </Box>
                    }
                </Card>
                {/* </Container> */}
            </Box>
        </>
    );
});
