/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AzRequestsExportCommand,
    AzRequestsExportCommandToJSON,
    DcrStatus,
    DeleteRequestsDto,
    DeleteRequestsDtoFromJSON,
    FetchCrmMissedRequestsCommand,
    FetchCrmMissedRequestsCommandToJSON,
    FieldsToUpdateDto,
    FieldsToUpdateDtoFromJSON,
    ListSortDirection,
    MissedRequestCommand,
    MissedRequestCommandToJSON,
    RefreshHashesByClientCommand,
    RefreshHashesByClientCommandToJSON,
    Request,
    RequestDto,
    RequestDtoFromJSON,
    RequestDtoPagedResponse,
    RequestDtoPagedResponseFromJSON,
    RequestFromJSON,
    RequestRecordType,
    RequestStatus,
    ResOutputDto,
    ResOutputDtoFromJSON,
} from '../models';

export interface ApiDcrV1AdminApprovedDcrGetRequest {
    page?: number;
    take?: number;
    dcrStatus?: Array<DcrStatus>;
    requestRecordType?: Array<RequestRecordType>;
    requestStatus?: Array<RequestStatus>;
    client?: Array<string>;
    orderField?: string;
    order?: ListSortDirection;
    startDate?: Date;
    endDate?: Date;
    jurisdictionId?: string;
}

export interface ApiDcrV1AdminAzExportPostRequest {
    azRequestsExportCommand?: AzRequestsExportCommand;
}

export interface ApiDcrV1AdminCheckRequestsPostRequest {
    body?: object;
}

export interface ApiDcrV1AdminFetchMissedDcrRequestsPostRequest {
    fetchCrmMissedRequestsCommand?: FetchCrmMissedRequestsCommand;
}

export interface ApiDcrV1AdminMissedDcrPostRequest {
    missedRequestCommand?: MissedRequestCommand;
}

export interface ApiDcrV1AdminRefreshHashByClientGetRequest {
    refreshHashesByClientCommand?: RefreshHashesByClientCommand;
}

/**
 * 
 */
export class AdminApi extends runtime.BaseAPI {

    /**
     */
    async apiDcrV1AdminApprovedDcrGetRaw(requestParameters: ApiDcrV1AdminApprovedDcrGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RequestDtoPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.take !== undefined) {
            queryParameters['Take'] = requestParameters.take;
        }

        if (requestParameters.dcrStatus) {
            queryParameters['DcrStatus'] = requestParameters.dcrStatus;
        }

        if (requestParameters.requestRecordType) {
            queryParameters['RequestRecordType'] = requestParameters.requestRecordType;
        }

        if (requestParameters.requestStatus) {
            queryParameters['RequestStatus'] = requestParameters.requestStatus;
        }

        if (requestParameters.client) {
            queryParameters['Client'] = requestParameters.client;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['OrderField'] = requestParameters.orderField;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['Order'] = requestParameters.order;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.jurisdictionId !== undefined) {
            queryParameters['JurisdictionId'] = requestParameters.jurisdictionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/admin/approved-dcr`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestDtoPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDcrV1AdminApprovedDcrGet(requestParameters: ApiDcrV1AdminApprovedDcrGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RequestDtoPagedResponse> {
        const response = await this.apiDcrV1AdminApprovedDcrGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminAzExportPostRaw(requestParameters: ApiDcrV1AdminAzExportPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/dcr/v1/admin/az-export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AzRequestsExportCommandToJSON(requestParameters.azRequestsExportCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiDcrV1AdminAzExportPost(requestParameters: ApiDcrV1AdminAzExportPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.apiDcrV1AdminAzExportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminCheckFieldsGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FieldsToUpdateDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/admin/check-fields`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FieldsToUpdateDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDcrV1AdminCheckFieldsGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FieldsToUpdateDto> {
        const response = await this.apiDcrV1AdminCheckFieldsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminCheckRequestsPostRaw(requestParameters: ApiDcrV1AdminCheckRequestsPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/dcr/v1/admin/check-requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiDcrV1AdminCheckRequestsPost(requestParameters: ApiDcrV1AdminCheckRequestsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.apiDcrV1AdminCheckRequestsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminCleanUpDcrRequestsDeleteRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ResOutputDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/admin/clean-up-dcr-requests`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResOutputDtoFromJSON));
    }

    /**
     */
    async apiDcrV1AdminCleanUpDcrRequestsDelete(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ResOutputDto>> {
        const response = await this.apiDcrV1AdminCleanUpDcrRequestsDeleteRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminCleanUpMissedDcrRequestsDeleteRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<Request>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/admin/clean-up-missed-dcr-requests`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RequestFromJSON));
    }

    /**
     */
    async apiDcrV1AdminCleanUpMissedDcrRequestsDelete(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<Request>> {
        const response = await this.apiDcrV1AdminCleanUpMissedDcrRequestsDeleteRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminDeleteDcrRequests2DeleteRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/admin/delete-dcr-requests2`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiDcrV1AdminDeleteDcrRequests2Delete(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.apiDcrV1AdminDeleteDcrRequests2DeleteRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminDeleteDeclinedRequestsDeleteRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DeleteRequestsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/admin/delete-declined-requests`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteRequestsDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDcrV1AdminDeleteDeclinedRequestsDelete(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DeleteRequestsDto> {
        const response = await this.apiDcrV1AdminDeleteDeclinedRequestsDeleteRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminDeleteImportedBfgRequestsDeleteRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<Request>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/admin/delete-imported-bfg-requests`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RequestFromJSON));
    }

    /**
     */
    async apiDcrV1AdminDeleteImportedBfgRequestsDelete(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<Request>> {
        const response = await this.apiDcrV1AdminDeleteImportedBfgRequestsDeleteRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminDeleteRepeatsDeleteRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<Request>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/admin/delete-repeats`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RequestFromJSON));
    }

    /**
     */
    async apiDcrV1AdminDeleteRepeatsDelete(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<Request>> {
        const response = await this.apiDcrV1AdminDeleteRepeatsDeleteRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminDeleteUselessResponsesDcrGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/admin/delete-useless-responses-dcr`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiDcrV1AdminDeleteUselessResponsesDcrGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.apiDcrV1AdminDeleteUselessResponsesDcrGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminFetchMissedDcrRequestsPostRaw(requestParameters: ApiDcrV1AdminFetchMissedDcrRequestsPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RequestDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/dcr/v1/admin/fetch-missed-dcr-requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchCrmMissedRequestsCommandToJSON(requestParameters.fetchCrmMissedRequestsCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDcrV1AdminFetchMissedDcrRequestsPost(requestParameters: ApiDcrV1AdminFetchMissedDcrRequestsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RequestDto> {
        const response = await this.apiDcrV1AdminFetchMissedDcrRequestsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminMissedDcrPostRaw(requestParameters: ApiDcrV1AdminMissedDcrPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RequestDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/dcr/v1/admin/missed-dcr`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MissedRequestCommandToJSON(requestParameters.missedRequestCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDcrV1AdminMissedDcrPost(requestParameters: ApiDcrV1AdminMissedDcrPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RequestDto> {
        const response = await this.apiDcrV1AdminMissedDcrPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminRecalcCreationdatetimePostRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/admin/recalc-creationdatetime`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiDcrV1AdminRecalcCreationdatetimePost(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<string>> {
        const response = await this.apiDcrV1AdminRecalcCreationdatetimePostRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminRefreshChangedatetimeDcrGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/admin/refresh-changedatetime-dcr`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiDcrV1AdminRefreshChangedatetimeDcrGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.apiDcrV1AdminRefreshChangedatetimeDcrGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminRefreshHashByClientGetRaw(requestParameters: ApiDcrV1AdminRefreshHashByClientGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RequestDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/dcr/v1/admin/refresh-hash-by-client`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshHashesByClientCommandToJSON(requestParameters.refreshHashesByClientCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDcrV1AdminRefreshHashByClientGet(requestParameters: ApiDcrV1AdminRefreshHashByClientGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RequestDto> {
        const response = await this.apiDcrV1AdminRefreshHashByClientGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminRefreshHashGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/admin/refresh-hash`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiDcrV1AdminRefreshHashGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.apiDcrV1AdminRefreshHashGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminResearchData360idGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/admin/research-data360id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiDcrV1AdminResearchData360idGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.apiDcrV1AdminResearchData360idGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminSetCountPostRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/admin/set-count`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiDcrV1AdminSetCountPost(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.apiDcrV1AdminSetCountPostRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminSetJurisdictionPostRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/admin/set-jurisdiction`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiDcrV1AdminSetJurisdictionPost(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.apiDcrV1AdminSetJurisdictionPostRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminSetMappingsGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/admin/set-mappings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiDcrV1AdminSetMappingsGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.apiDcrV1AdminSetMappingsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1AdminSetStatusNamePostRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/admin/set-status-name`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiDcrV1AdminSetStatusNamePost(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<string>> {
        const response = await this.apiDcrV1AdminSetStatusNamePostRaw(initOverrides);
        return await response.value();
    }

}
