import {makeAutoObservable} from "mobx";
import {OrganizationDto} from "../../../../../services/management";
import {OrganizationItemStore} from "../../OrganizationItemStore";
import {OrganizationChildsTableStore} from "./OrganizationChildsTableStore";
import {OrganizationParentStore} from "./OrganizationParentStore";

export class ParentChildTabsStore {
    readonly store: OrganizationItemStore;
    dto: OrganizationDto;
    private _tabIndex = 0;
    private _expand: boolean = false;
    ParentStore: OrganizationParentStore;
    ChildStore: OrganizationChildsTableStore;

    constructor(store: OrganizationItemStore, dto: OrganizationDto) {
        this.store = store;
        this.dto = dto;

        this.ParentStore = new OrganizationParentStore(this, this.dto);
        this.ChildStore = new OrganizationChildsTableStore(this, this.dto);

        makeAutoObservable(this);
    }

    get expand() {
        return this._expand;
    }

    async setExpand(value: boolean) {
        this._expand = value;
        if (this.expand) {
            await this.handleTabChange();
        }
    }

    get tabIndex() {
        return this._tabIndex;
    }

    get isDouble(): boolean {
        return this.store.isDouble;
    }

    async setTabIndex(value: number) {
        this._tabIndex = value;
        await this.handleTabChange();
    }

    update(dto: OrganizationDto) {
        this.ParentStore.update(dto)
    }

    async handleTabChange() {
        if(this._tabIndex == 0){
            await this.ParentStore.pull()
        }
        if (this._tabIndex === 1) {
            await this.ChildStore.pull()
        }
    }
}
