/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Api.Import
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    NameDto,
    NameDtoFromJSON,
    NameDtoFromJSONTyped,
    NameDtoToJSON,
} from './NameDto';
import {
    OrganizationDto,
    OrganizationDtoFromJSON,
    OrganizationDtoFromJSONTyped,
    OrganizationDtoToJSON,
} from './OrganizationDto';
import {
    WebsiteDto,
    WebsiteDtoFromJSON,
    WebsiteDtoFromJSONTyped,
    WebsiteDtoToJSON,
} from './WebsiteDto';

/**
 *
 * @export
 * @interface OrganizationWebsiteDto
 */
export interface OrganizationWebsiteDto {
    /**
     *
     * @type {string}
     * @memberof OrganizationWebsiteDto
     */
    id: string;
    /**
     *
     * @type {WebsiteDto}
     * @memberof OrganizationWebsiteDto
     */
    website: WebsiteDto;
    /**
     *
     * @type {OrganizationDto}
     * @memberof OrganizationWebsiteDto
     */
    organization: OrganizationDto;
    /**
     *
     * @type {NameDto}
     * @memberof OrganizationWebsiteDto
     */
    websiteStatus: NameDto;
}

export function OrganizationWebsiteDtoFromJSON(json: any): OrganizationWebsiteDto {
    return OrganizationWebsiteDtoFromJSONTyped(json, false);
}

export function OrganizationWebsiteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationWebsiteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'id': json['id'],
        'website': WebsiteDtoFromJSON(json['website']),
        'organization': OrganizationDtoFromJSON(json['organization']),
        'websiteStatus': NameDtoFromJSON(json['websiteStatus']),
    };
}

export function OrganizationWebsiteDtoToJSON(value?: OrganizationWebsiteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'id': value.id,
        'website': WebsiteDtoToJSON(value.website),
        'organization': OrganizationDtoToJSON(value.organization),
        'websiteStatus': NameDtoToJSON(value.websiteStatus),
    };
}

