import { Box, SxProps } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { IPage } from "../../../core";
import { PaginationLeft } from "./PaginationLeft";
import { PaginationRight } from "./PaginationRigth";
import { useRootStore } from "../../../stores";
import {useSettings} from "../../../core/hooks/use-settings";

type Props = {
    store: IPage;
    style?: React.CSSProperties;
    sx?: SxProps;
    pageOptions?: number[];
};


const WidePadding = '0 10px 0 280px';
const CompactPadding = '0 100px 0 375px';

export const Pagination = observer(({ store, style, sx, pageOptions }: Props) => {
    return (
        <Box
            sx={{ backgroundColor: 'background.default' }}
            style={{
                display: "flex",
                position: 'sticky',
                bottom: 0,
                zIndex: 900
            }}
        >
            <Box
                style={{ flex: 'auto' }}>
                <PaginationLeft store={store} style={style} sx={sx} />
            </Box>
            <Box>
                <PaginationRight store={store} style={style} sx={sx} pageOptions={pageOptions} />
            </Box>
        </Box>
    )
})

export const PaginationLocal = observer(({ store, style, sx, pageOptions }: Props) => {
    return (
        <Box
            sx={{ backgroundColor: 'background.default' }}
            style={{
                display: "flex",
            }}
        >
            <Box
                style={{ flex: 'auto' }}>
                <PaginationLeft store={store} style={style} sx={sx} />
            </Box>
            <Box>
                <PaginationRight store={store} style={style} sx={sx} pageOptions={pageOptions} />
            </Box>
        </Box>
    )
})