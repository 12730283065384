/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    ListSortDirection,
    OCMObjectState,
    PostDto,
    PostDtoFromJSON,
    SetPostDto,
    SetPostDtoToJSON,
} from '../models';

export interface CreatePostRequest {
    setPostDto: SetPostDto;
}

export interface DeletePostRequest {
    id: string;
}

export interface GetPostByIdRequest {
    id: string;
}

export interface GetPostByNameRequest {
    name: string;
}

export interface GetPostsRequest {
    search?: string;
    searchByExternalId?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    showOnlyActive?: boolean;
}

export interface GetPostsCountRequest {
    searchByExternalId?: string;
    search?: string;
    showOnlyActive?: boolean;
}

export interface GetPostsCountFallbackRequest {
    searchByExternalId?: string;
    search?: string;
    states?: Array<OCMObjectState>;
    showOnlyActive?: boolean;
}

export interface GetPostsFallbackRequest {
    search?: string;
    searchByExternalId?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
    showOnlyActive?: boolean;
}

export interface UpdatePostRequest {
    id: string;
    setPostDto: SetPostDto;
}

/**
 * 
 */
export class PostsApi extends runtime.BaseAPI {

    /**
     */
    async createPostRaw(requestParameters: CreatePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PostDto>> {
        if (requestParameters.setPostDto === null || requestParameters.setPostDto === undefined) {
            throw new runtime.RequiredError('setPostDto','Required parameter requestParameters.setPostDto was null or undefined when calling createPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/jobs/posts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPostDtoToJSON(requestParameters.setPostDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostDtoFromJSON(jsonValue));
    }

    /**
     */
    async createPost(requestParameters: CreatePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PostDto> {
        const response = await this.createPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePostRaw(requestParameters: DeletePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PostDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jobs/posts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostDtoFromJSON(jsonValue));
    }

    /**
     */
    async deletePost(requestParameters: DeletePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PostDto> {
        const response = await this.deletePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPostByIdRaw(requestParameters: GetPostByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PostDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPostById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jobs/posts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPostById(requestParameters: GetPostByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PostDto> {
        const response = await this.getPostByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPostByNameRaw(requestParameters: GetPostByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PostDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getPostByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jobs/posts/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPostByName(requestParameters: GetPostByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PostDto> {
        const response = await this.getPostByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPostsRaw(requestParameters: GetPostsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PostDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.showOnlyActive !== undefined) {
            queryParameters['showOnlyActive'] = requestParameters.showOnlyActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jobs/posts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PostDtoFromJSON));
    }

    /**
     */
    async getPosts(requestParameters: GetPostsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PostDto>> {
        const response = await this.getPostsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPostsCountRaw(requestParameters: GetPostsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.showOnlyActive !== undefined) {
            queryParameters['showOnlyActive'] = requestParameters.showOnlyActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jobs/posts/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPostsCount(requestParameters: GetPostsCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPostsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPostsCountFallbackRaw(requestParameters: GetPostsCountFallbackRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        if (requestParameters.showOnlyActive !== undefined) {
            queryParameters['showOnlyActive'] = requestParameters.showOnlyActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jobs/posts/count/fallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPostsCountFallback(requestParameters: GetPostsCountFallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPostsCountFallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPostsFallbackRaw(requestParameters: GetPostsFallbackRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PostDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        if (requestParameters.showOnlyActive !== undefined) {
            queryParameters['showOnlyActive'] = requestParameters.showOnlyActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jobs/posts/fallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PostDtoFromJSON));
    }

    /**
     */
    async getPostsFallback(requestParameters: GetPostsFallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PostDto>> {
        const response = await this.getPostsFallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePostRaw(requestParameters: UpdatePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PostDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePost.');
        }

        if (requestParameters.setPostDto === null || requestParameters.setPostDto === undefined) {
            throw new runtime.RequiredError('setPostDto','Required parameter requestParameters.setPostDto was null or undefined when calling updatePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/jobs/posts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetPostDtoToJSON(requestParameters.setPostDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostDtoFromJSON(jsonValue));
    }

    /**
     */
    async updatePost(requestParameters: UpdatePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PostDto> {
        const response = await this.updatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
