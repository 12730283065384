import {action, computed, makeObservable, observable} from "mobx";
import {getApiConfiguration, getObject, setObject} from "../../../../core";
import {PagedItems, TableLocalFilters, TableStore} from "../../../utils";
import {ManagementStore} from "../../ManagementStore";
import {ClientApi, ClientDto, ResponseApi} from "../../../../services/dcr";
import {ResponseItemStore} from "./ResponseItemStore";
import {DefaultMultiSelectStore} from "../../../../components/shared/DefaultMultiSelectStore";
import {getEnumValues} from "../../../../core/getEnumValues";
import {
    mappingDcrStatusToString,
    mappingRequestStatusToString,
    mappingRequestTypeToString,
    mappingResponseStatusToString,
} from "../services";
import {DCRStatusEnum, RequestStatusEnum, RequestTypeEnum, ResponseStatusEnum} from "../types";

interface ResponseTableStoreLocalFilters extends TableLocalFilters<ResponseItemStore> {
    isOpenInDrawer: boolean
}

export class ResponseTableStore extends TableStore<ResponseItemStore> {
    readonly store: ManagementStore;

    readonly dcrStatus: DefaultMultiSelectStore<{ value: string, label: string }>;
    readonly requestRecordType: DefaultMultiSelectStore<{ value: string, label: string }>;
    readonly requestStatus: DefaultMultiSelectStore<{ value: string, label: string }>;
    readonly responseStatus: DefaultMultiSelectStore<{ value: string, label: string }>;
    readonly client: DefaultMultiSelectStore<ClientDto>;
    _isOpenInDrawer: boolean;

    constructor(store: ManagementStore) {
        super();
        this._isOpenInDrawer = true;
        this.client = new DefaultMultiSelectStore<ClientDto>([],
            (e) => new ClientApi().apiDcrV1ClientsGet(e),
            async (e) => await this.updateState(),
            null,
            "name",
            "name")
        this.dcrStatus = new DefaultMultiSelectStore<{ value: string; label: string }>([],
            (e) => Promise.resolve(getEnumValues(DCRStatusEnum)),
            async (e) => await this.updateState(),
            null,
            "value",
            (e) => mappingDcrStatusToString(parseInt(e.value)))
        this.requestRecordType = new DefaultMultiSelectStore<{ value: string; label: string }>([],
            (e) => Promise.resolve(getEnumValues(RequestTypeEnum)),
            async (e) => await this.updateState(),
            null,
            "value",
            (e) => mappingRequestTypeToString(parseInt(e.value)))
        this.requestStatus = new DefaultMultiSelectStore<{ value: string; label: string }>([],
            (e) => Promise.resolve(getEnumValues(RequestStatusEnum)),
            async (e) => await this.updateState(),
            null,
            "value",
            (e) => mappingRequestStatusToString(parseInt(e.value)))
        this.responseStatus = new DefaultMultiSelectStore<{ value: string; label: string }>([],
            (e) => Promise.resolve(getEnumValues(ResponseStatusEnum)),
            async (e) => await this.updateState(),
            null,
            "value",
            (e) => mappingResponseStatusToString(parseInt(e.value)))
        this.store = store;
        const filters = getObject<ResponseTableStoreLocalFilters>('ResponseTable-store');
        if (filters) {
            this._page = filters.page || 0;
            this._size = filters.size;
            this.search.setValueWithoutEffects(filters.search);;
            this._order = filters.order;
            this._orderBy = filters.orderBy;
            this._isOpenInDrawer = filters.isOpenInDrawer;
        }
        makeObservable(this, {
            _isOpenInDrawer: observable,
        });
    }

    set isOpenInDrawer(value: boolean) {
        this._isOpenInDrawer = value;
        this.onFiltersSave();
    }

    get isOpenInDrawer() {
        return this._isOpenInDrawer;
    }

    async request(): Promise<PagedItems<ResponseItemStore>> {
        let result = await new ResponseApi(getApiConfiguration() as any).apiDcrV1ResponsesGet({
            page: this.page + 1,
            take: this.size,
            dcrStatus: this.dcrStatus.value?.length ? this.dcrStatus.value.map(x => parseInt(x.value) as any) : undefined,
            requestStatus: this.requestStatus.value?.length ? this.requestStatus.value.map(x => parseInt(x.value) as any) : undefined,
            requestRecordType: this.requestRecordType.value?.length ? this.requestRecordType.value.map(x => parseInt(x.value) as any) : undefined,
            responseStatus: this.responseStatus.value?.length ? this.responseStatus.value.map(x => parseInt(x.value) as any) : undefined,
            client: this.client.value?.length ? this.client.value.map(x => x.name!) : undefined,
            orderField: this.orderBy,
            order: this.order === "ascending" ? 1 : 0
        });
        let newItem = []

        for (const element of (result.items ?? [])) {
            let item = this.items.filter(x => x.dto.requestId === element.requestId)[0]
            newItem.push(!!item ? item : new ResponseItemStore(this, element))
        }
        return new PagedItems<ResponseItemStore>(newItem, result.total)
    };


    onFiltersSave() {
        setObject<ResponseTableStoreLocalFilters>('ResponseTable-store', {
            search: this.search.value ?? '',
            order: this.order,
            page: this.page,
            size: this.size,
            isOpenInDrawer: this.isOpenInDrawer
        });
    }
}
