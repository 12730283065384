/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetMappingDto
 */
export interface SetMappingDto {
    /**
     * 
     * @type {string}
     * @memberof SetMappingDto
     */
    entityId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetMappingDto
     */
    clientValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetMappingDto
     */
    alternativeClientValue?: string | null;
}

export function SetMappingDtoFromJSON(json: any): SetMappingDto {
    return SetMappingDtoFromJSONTyped(json, false);
}

export function SetMappingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetMappingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityId': !exists(json, 'entityId') ? undefined : json['entityId'],
        'clientValue': !exists(json, 'clientValue') ? undefined : json['clientValue'],
        'alternativeClientValue': !exists(json, 'alternativeClientValue') ? undefined : json['alternativeClientValue'],
    };
}

export function SetMappingDtoToJSON(value?: SetMappingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityId': value.entityId,
        'clientValue': value.clientValue,
        'alternativeClientValue': value.alternativeClientValue,
    };
}

