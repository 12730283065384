import {getApiConfiguration} from '../../../../../core';
import {PostsApi} from "../../../../../services/management";
import {DictionaryCreateEntity, DictionaryEntity, DictionaryTableStore} from "../../DictionaryTableStore";
import {DictionaryPersonStore} from "../DictionaryPersonStore";

export class PositionTableStore extends DictionaryTableStore {
    isActiveStateUsed = true;

    constructor(store: DictionaryPersonStore) {
        super(store, 'position-store');
    }

    async Create(model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new PostsApi(getApiConfiguration()).createPost({setPostDto: model});
    }

    async Delete(id: string): Promise<DictionaryEntity> {
        return await new PostsApi(getApiConfiguration()).deletePost({id: id});
    }

    async GetCount(search: string): Promise<{ count: number }> {
        return await new PostsApi(getApiConfiguration()).getPostsCount({search: search});
    }

    async GetList(filters: any): Promise<DictionaryEntity[]> {
        return await new PostsApi(getApiConfiguration()).getPosts(filters)
    }

    async Update(id: string, model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new PostsApi(getApiConfiguration()).updatePost({id: id, setPostDto: model});
    }
}
