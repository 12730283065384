import {getApiConfiguration} from '../../../../../core';
import {MiddleNamesApi} from '../../../../../services/management';
import {DictionaryCreateEntity, DictionaryEntity, DictionaryTableStore} from "../../DictionaryTableStore";
import {DictionaryPersonStore} from "../DictionaryPersonStore";

export class MiddleNameTableStore extends DictionaryTableStore {
    constructor(store: DictionaryPersonStore) {
        super(store, 'middleName-store');
    }

    async Create(model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new MiddleNamesApi(getApiConfiguration()).createMiddleName({setMiddleNameDto: model});
    }

    async Delete(id: string): Promise<DictionaryEntity> {
        return await new MiddleNamesApi(getApiConfiguration()).deleteMiddleName({id: id});
    }

    async GetCount(search: string): Promise<{ count: number }> {
        return await new MiddleNamesApi(getApiConfiguration()).getMiddleNamesCount({search: search});
    }

    async GetList(filters: any): Promise<DictionaryEntity[]> {
        return await new MiddleNamesApi(getApiConfiguration()).getMiddleNames(filters)
    }

    async Update(id: string, model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new MiddleNamesApi(getApiConfiguration()).updateMiddleName({id: id, setMiddleNameDto: model});
    }
}
