import {makeObservable, observable} from "mobx";
import {getApiConfiguration} from "../../../../../../../core";
import {compareArraysIds} from "../../../../../../../core/compareArray";
import {GetSubjectsRequest, RolePermissionsApi, SubjectsApi} from "../../../../../../../services/auth";
import {PagedItems, TableStore} from "../../../../../../utils";
import {RoleItemStore} from "../../RoleItemStore";
import {PermissionItem, PermissionItemStore} from "./PermissionItemStore";

export class PermissionTableStore extends TableStore<PermissionItemStore> {
    current: PermissionItemStore | undefined | null;
    store: RoleItemStore;
    permissions: string[] = []

    constructor(store: RoleItemStore) {
        super();
        this.store = store;
        makeObservable(this, {
            current: observable,
        });
    }

    async request(): Promise<PagedItems<PermissionItemStore>> {
        const filters = {
            page: this.page + 1,
            size: 1000,
            search: this.search.value ?? '',
            order: this.order === "ascending" ? 1 : 0,
            orderBy: this.orderBy
        } as GetSubjectsRequest;
        const api: SubjectsApi = new SubjectsApi(getApiConfiguration());
        const result = await api.getSubjects(filters);

        const res: PermissionItem[] = []

        result.map(x => x.permissions ?? []).reduce((x, y) => x.concat(y), []).forEach(x => {
            const split = x.key!.split(".")
            split.pop();

            if (split.length) {
                let spKey = '';
                split.forEach((s, i) => {
                    const parentKey = i == 0 ? 'main' : spKey
                    spKey = spKey != '' ? spKey + '.' + s : s;
                    const allReadyElement1 = res.find(rr => rr.key == spKey);
                    const parentItem = res.find(rr => rr.key == parentKey)?.name ?? ""
                    if (!allReadyElement1)
                        res.push({
                            key: spKey,
                            name: s.toUpperCase().replace(parentItem, ""),
                            parent: parentKey,
                            selected: false
                        })
                })
            }

            const parent = split.join(".") == x.key! ? 'main' : split.join(".");

            res.push({
                key: x.key!,
                parent: parent,
                name: x.key!,
                idPermission: x.id,
                selected: !!this.permissions?.find(t => t == x.id)
            })
        })

        const nest = (items: PermissionItem[], id: string | null = null, link = 'parent'): PermissionItemStore[] =>{
            return items
                .filter((item: any) => item[link] === id)
                .map(item => new PermissionItemStore(this, item, nest(items.filter(x => x.key != item.key), item.key)));
        }

        const items = nest(res.sort((b, bb) => (b.idPermission ?? "") > (bb.idPermission ?? "") ? -1 : 1), 'main');

        return new PagedItems<PermissionItemStore>(items,items.length)
    };

    get selectItems(): string[] {
        return this.items.map(x => x.permissionIds).reduce((x,y)=> x.concat(y))
    }

    get allItems(): PermissionItemStore[] {
        return this.items.map(x => x.allItems).flatMap(x => x)
    }


    get equals (){
        if(!this.permissions.length) return false;
        return compareArraysIds(this.permissions, this.selectItems)
    }

    async beforeLoad(): Promise<void> {
        this.permissions = (await new RolePermissionsApi(getApiConfiguration()).getRolePermissions({
            roleId: this.store.dto.id,
            size: 1000,
            page: 1
        })).map(x => x.permissionId)
    }

    async save(): Promise<void>{
        const rolePermissionIds = this.permissions;
        const currentIds = this.selectItems;

        const deletesIds = rolePermissionIds.filter(x => !currentIds.includes(x));
        const insetIds = currentIds.filter(x => !rolePermissionIds.includes(x));

        await this.deleteRolePermission(deletesIds)
        await this.createRolePermission(insetIds)
        this.permissions = this.selectItems;
    }

    async createRolePermission(ids: string[]): Promise<void>{
        await new RolePermissionsApi(getApiConfiguration()).createRolePermissions({
            roleId: this.store.dto?.id!,
            setRolePermissionDto: ids.map(x => ({
                roleId: this.store.dto.id,
                permissionId: x
            }))
        });
    }

    async deleteRolePermission(ids: string[]): Promise<void>{
        await new RolePermissionsApi(getApiConfiguration({successTooltip: false})).removeRolePermissions({
            roleId: this.store.dto.id,
            setRolePermissionDto: ids.map(x => ({
                roleId: this.store.dto.id,
                permissionId: x
            }))
        });
    }
}
