export const sort = (a: any, b: any, property?: string): 0 | 1 | -1 => {
    const valueA = property ? a[property] : a;
    const valueB = property ? b[property] : b;


    if (valueA < valueB) {
        return -1;
    }
    if (valueA > valueB) {
        return 1;
    }
    return 0;
};
