import {Box, Button} from "@mui/material";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {ResetFiltersButton} from '../../../../components';
import {DefaultMultiSelect} from "../../../../components/shared/components/DefaultMultiSelect";
import {ExportDictionaryStore} from "../../../../stores/management/data/export/tabs/ExportDictionaryStore";
import {DefaultDate} from "../../../../components/shared/DefaultDate";

interface Props {
    exportDictionaryCreateStore: ExportDictionaryStore;
}

export const ExportDictionaryCreate = observer(({ exportDictionaryCreateStore }: Props) => {
    const { t } = useTranslation();

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                backgroundColor: 'background.default',
            }}
        >
            <Box sx={{ mt: 1 }}
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <DefaultMultiSelect
                    autoFocus
                    store={exportDictionaryCreateStore.guidesPersonTypes}
                    title={t('dataPage.guidesPerson')}
                    nameExp={exportDictionaryCreateStore.guidesPersonTypes.nameExp}
                    valueExp={null}
                    style={{ width: '50%' }}
                />
                <DefaultMultiSelect store={exportDictionaryCreateStore.guidesOrganizationTypes}
                    title={t('dataPage.guidesOrganization')}
                    nameExp={exportDictionaryCreateStore.guidesOrganizationTypes.nameExp}
                    valueExp={null}
                    style={{ marginLeft: 16, width: '50%' }}
                />
            </Box>
            <Box
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <DefaultDate store={exportDictionaryCreateStore.createDate}
                             style={{marginTop: 16, width: '33%'}}
                             title={t('dataPage.table.createDate')}/>

                <DefaultDate store={exportDictionaryCreateStore.updateDate}
                             style={{marginTop: 16, marginLeft: 16, width: '33%'}}
                             title={t('dataPage.table.modifiedDate')}/>

                <DefaultMultiSelect store={exportDictionaryCreateStore.exportTypes}
                    title={t('dataPage.exportType')}
                    nameExp={exportDictionaryCreateStore.exportTypes.nameExp}
                    valueExp={null}
                    style={{ marginLeft: 16, width: '33%' }}
                />
            </Box>
            <Box style={{ display: 'flex', height: 37, justifyContent: 'end' }} sx={{ my: 2 }}>
                <ResetFiltersButton onClick={() => exportDictionaryCreateStore.resetFilters()} />
                {/* <AuthorizePermission permissions=""> */}
                <Button variant='contained' color="success" sx={{ ml: 2 }} onClick={() => exportDictionaryCreateStore.create()} disabled={exportDictionaryCreateStore.loading || exportDictionaryCreateStore.valid}>
                    {t('buttons.create')}
                </Button>
                {/* </AuthorizePermission> */}
            </Box>
        </Box >
    )
});
