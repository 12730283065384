/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RequestFieldType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;
export type RequestFieldType = typeof RequestFieldType[keyof typeof RequestFieldType];


export function RequestFieldTypeFromJSON(json: any): RequestFieldType {
    return RequestFieldTypeFromJSONTyped(json, false);
}

export function RequestFieldTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestFieldType {
    return json as RequestFieldType;
}

export function RequestFieldTypeToJSON(value?: RequestFieldType | null): any {
    return value as any;
}

