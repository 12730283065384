/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CreatePersonDto,
    CreatePersonDtoToJSON,
    ListSortDirection,
    PersonDto,
    PersonDtoFromJSON,
    SetPersonDto,
    SetPersonDtoToJSON,
} from '../models';

export interface CreatePersonRequest {
    createPersonDto: CreatePersonDto;
}

export interface DeletePersonRequest {
    id: string;
}

export interface GetPersonByIdRequest {
    id: string;
}

export interface GetPersonsRequest {
    search?: string;
    searchByName?: string;
    searchBySurname?: string;
    searchBySecondname?: string;
    genderId?: string;
    orderBy?: string;
    searchById?: string;
    searchByExternalId?: string;
    searchByAntidoubleId?: string;
    searchByHouse?: string;
    specialities?: Array<string>;
    localities?: Array<string>;
    posts?: Array<string>;
    statuses?: Array<string>;
    tags?: Array<string>;
    jobStatusId?: number;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    searchByMaidenname?: string;
}

export interface GetPersonsCountRequest {
    searchByName?: string;
    search?: string;
    searchBySurname?: string;
    searchBySecondname?: string;
    searchByAntidoubleId?: string;
    specialities?: Array<string>;
    localities?: Array<string>;
    posts?: Array<string>;
    statuses?: Array<string>;
    tags?: Array<string>;
    genderId?: string;
    jobStatusId?: number;
    searchByHouse?: string;
    searchByMaidenname?: string;
}

export interface TickPersonRequest {
    id: string;
}

export interface UpdatePersonRequest {
    id: string;
    setPersonDto: SetPersonDto;
}

/**
 * 
 */
export class PersonsApi extends runtime.BaseAPI {

    /**
     */
    async createPersonRaw(requestParameters: CreatePersonRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonDto>> {
        if (requestParameters.createPersonDto === null || requestParameters.createPersonDto === undefined) {
            throw new runtime.RequiredError('createPersonDto','Required parameter requestParameters.createPersonDto was null or undefined when calling createPerson.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/persons`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePersonDtoToJSON(requestParameters.createPersonDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDtoFromJSON(jsonValue));
    }

    /**
     */
    async createPerson(requestParameters: CreatePersonRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonDto> {
        const response = await this.createPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePersonRaw(requestParameters: DeletePersonRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePerson.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDtoFromJSON(jsonValue));
    }

    /**
     */
    async deletePerson(requestParameters: DeletePersonRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonDto> {
        const response = await this.deletePersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonByIdRaw(requestParameters: GetPersonByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPersonById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonById(requestParameters: GetPersonByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonDto> {
        const response = await this.getPersonByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonsRaw(requestParameters: GetPersonsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByName !== undefined) {
            queryParameters['searchByName'] = requestParameters.searchByName;
        }

        if (requestParameters.searchBySurname !== undefined) {
            queryParameters['searchBySurname'] = requestParameters.searchBySurname;
        }

        if (requestParameters.searchBySecondname !== undefined) {
            queryParameters['searchBySecondname'] = requestParameters.searchBySecondname;
        }

        if (requestParameters.genderId !== undefined) {
            queryParameters['genderId'] = requestParameters.genderId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.searchById !== undefined) {
            queryParameters['searchById'] = requestParameters.searchById;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.searchByAntidoubleId !== undefined) {
            queryParameters['searchByAntidoubleId'] = requestParameters.searchByAntidoubleId;
        }

        if (requestParameters.searchByHouse !== undefined) {
            queryParameters['searchByHouse'] = requestParameters.searchByHouse;
        }

        if (requestParameters.specialities) {
            queryParameters['specialities'] = requestParameters.specialities;
        }

        if (requestParameters.localities) {
            queryParameters['localities'] = requestParameters.localities;
        }

        if (requestParameters.posts) {
            queryParameters['posts'] = requestParameters.posts;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.jobStatusId !== undefined) {
            queryParameters['jobStatusId'] = requestParameters.jobStatusId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.searchByMaidenname !== undefined) {
            queryParameters['searchByMaidenname'] = requestParameters.searchByMaidenname;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonDtoFromJSON));
    }

    /**
     */
    async getPersons(requestParameters: GetPersonsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonDto>> {
        const response = await this.getPersonsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonsCountRaw(requestParameters: GetPersonsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.searchByName !== undefined) {
            queryParameters['searchByName'] = requestParameters.searchByName;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchBySurname !== undefined) {
            queryParameters['searchBySurname'] = requestParameters.searchBySurname;
        }

        if (requestParameters.searchBySecondname !== undefined) {
            queryParameters['searchBySecondname'] = requestParameters.searchBySecondname;
        }

        if (requestParameters.searchByAntidoubleId !== undefined) {
            queryParameters['searchByAntidoubleId'] = requestParameters.searchByAntidoubleId;
        }

        if (requestParameters.specialities) {
            queryParameters['specialities'] = requestParameters.specialities;
        }

        if (requestParameters.localities) {
            queryParameters['localities'] = requestParameters.localities;
        }

        if (requestParameters.posts) {
            queryParameters['posts'] = requestParameters.posts;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.genderId !== undefined) {
            queryParameters['genderId'] = requestParameters.genderId;
        }

        if (requestParameters.jobStatusId !== undefined) {
            queryParameters['jobStatusId'] = requestParameters.jobStatusId;
        }

        if (requestParameters.searchByHouse !== undefined) {
            queryParameters['searchByHouse'] = requestParameters.searchByHouse;
        }

        if (requestParameters.searchByMaidenname !== undefined) {
            queryParameters['searchByMaidenname'] = requestParameters.searchByMaidenname;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonsCount(requestParameters: GetPersonsCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPersonsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tickPersonRaw(requestParameters: TickPersonRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tickPerson.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/{id}/tick`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDtoFromJSON(jsonValue));
    }

    /**
     */
    async tickPerson(requestParameters: TickPersonRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonDto> {
        const response = await this.tickPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePersonRaw(requestParameters: UpdatePersonRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePerson.');
        }

        if (requestParameters.setPersonDto === null || requestParameters.setPersonDto === undefined) {
            throw new runtime.RequiredError('setPersonDto','Required parameter requestParameters.setPersonDto was null or undefined when calling updatePerson.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/persons/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetPersonDtoToJSON(requestParameters.setPersonDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDtoFromJSON(jsonValue));
    }

    /**
     */
    async updatePerson(requestParameters: UpdatePersonRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonDto> {
        const response = await this.updatePersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
