import React, {Suspense} from 'react';
import type {RouteObject} from 'react-router';
import {Outlet} from 'react-router-dom';
import {Authentication, Wrapper} from '../components';
import {Layout} from '../components/dashboard';
import {PageLayout} from '../components/layouts/page-layout/PageLayout';
import {Login, PostLoginCallback} from './Login';
import {AdminPage} from './admin/AdminPage';
import {DcrDashboards} from './analytics/pages/dcr/DcrDashboards';
import {DcrReport} from './analytics/pages/dcr/DcrReport';
import {ExchangeDashboards} from './analytics/pages/exchange/ExchangeDashboards';
import {ExchangeReport} from './analytics/pages/exchange/ExchangeReport';
import {ClientTable} from './clients/ClientTable';
import {Client} from './clients/client/Client';
import {ImportTabs} from './data/import/ImportTabs';
import {DcrRequest} from './dcr/requests/DcrRequest';
import {DcrResponse} from './dcr/responses/DcrResponse';
import {DictionariesOrganizations} from './dictionaries/organizations/DictionariesOrganizations';
import {DictionariesPersons} from './dictionaries/persone/DictionariesPersons';
import {OrganizationTable} from './organizations';
import {Organization} from './organizations/Organization';
import {Person, Persons} from './persons';
import {UsersPage} from './users/UsersPage';
import {Role} from './users/tabs/roles/Role';
import {User} from './users/tabs/users/User';
import {NotFound} from "./NotFound";
import {JurisdictionTable} from "./jurisdictions/JurisdictionTable";
import {ExportTabs} from "./data/export/ExportTabs";

// const PersonsPage = lazy(() => import('../components/Page'));
//
// // Academy
// const AcademyDashboardPage = lazy(() => import('src/pages/dashboard(old)/academy/dashboard(old)'));
// const AcademyCoursePage = lazy(() => import('src/pages/dashboard(old)/academy/course'));
//
// // Blog
// const BlogPostListPage = lazy(() => import('src/pages/dashboard(old)/blog/list'));
// const BlogPostDetailPage = lazy(() => import('src/pages/dashboard(old)/blog/detail'));
// const BlogPostCreatePage = lazy(() => import('src/pages/dashboard(old)/blog/create'));
//
// // Customer
// const CustomerListPage = lazy(() => import('src/pages/dashboard(old)/customers/list'));
// const CustomerDetailPage = lazy(() => import('src/pages/dashboard(old)/customers/detail'));
// const CustomerEditPage = lazy(() => import('src/pages/dashboard(old)/customers/edit'));
//
// // Invoice
// const InvoiceListPage = lazy(() => import('src/pages/dashboard(old)/invoices/list'));
// const InvoiceDetailPage = lazy(() => import('src/pages/dashboard(old)/invoices/detail'));
//
// // Job
// const JobBrowsePage = lazy(() => import('src/pages/dashboard(old)/jobs/browse'));
// const JobCreatePage = lazy(() => import('src/pages/dashboard(old)/jobs/create'));
// const CompanyDetailPage = lazy(() => import('src/pages/dashboard(old)/jobs/companies/detail'));
//
// // Logistics
// const LogisticsDashboardPage = lazy(() => import('src/pages/dashboard(old)/logistics/dashboard(old)'));
// const LogisticsFleetPage = lazy(() => import('src/pages/dashboard(old)/logistics/fleet'));
//
// // Order
// const OrderListPage = lazy(() => import('src/pages/dashboard(old)/orders/list'));
// const OrderDetailPage = lazy(() => import('src/pages/dashboard(old)/orders/detail'));
//
// // Product
// const ProductListPage = lazy(() => import('src/pages/dashboard(old)/products/list'));
// const ProductCreatePage = lazy(() => import('src/pages/dashboard(old)/products/create'));
//
// // Social
// const SocialFeedPage = lazy(() => import('src/pages/dashboard(old)/social/feed'));
// const SocialProfilePage = lazy(() => import('src/pages/dashboard(old)/social/profile'));
//
// // Other
// const AccountPage = lazy(() => import('src/pages/dashboard(old)/account'));
// const AnalyticsPage = lazy(() => import('src/pages/dashboard(old)/analytics'));
// const BlankPage = lazy(() => import('src/pages/dashboard(old)/blank'));

export const routes: RouteObject[] = [
    { path: 'cb', element: <PostLoginCallback></PostLoginCallback> },
    { path: 'login', element: <Login></Login> },
    {
        path: '',
        element: (
            <Authentication>
                <Wrapper tab={0}>
                    <Layout>
                        <Suspense>
                            <PageLayout>
                                <Outlet />
                            </PageLayout>
                        </Suspense>
                    </Layout>
                </Wrapper>
            </Authentication>
        ),
        children: [
            {path: 'not-found', element: <NotFound/>},
            {
                path: 'persons',
                children: [
                    {
                        index: true,
                        element: (
                            <Wrapper tab={1}>
                                <Persons/>
                            </Wrapper>
                        ),
                    },
                    {
                        path: ':id',
                        element: (
                            <Wrapper tab={1}>
                                <Person/>
                            </Wrapper>
                        ),
                    },
                ],
            },
            {
                path: 'organizations',
                children: [
                    {
                        index: true,
                        element: (
                            <Wrapper tab={2}>
                                <OrganizationTable/>
                            </Wrapper>
                        ),
                    },
                    {
                        path: ':id',
                        element: (
                            <Wrapper tab={2}>
                                <Organization/>
                            </Wrapper>
                        ),
                    },
                ],
            },
            {
                path: 'dictionaries/persons',
                children: [
                    {
                        index: true,
                        element: (
                            <Wrapper tab={3}>
                                <DictionariesPersons/>
                            </Wrapper>
                        ),
                    },
                ],
            },
            {
                path: 'dictionaries/organizations',
                children: [
                    {
                        index: true,
                        element: (
                            <Wrapper tab={4}>
                                <DictionariesOrganizations/>
                            </Wrapper>
                        ),
                    },
                ],
            },
            {
                path: 'users',
                children: [
                    {
                        index: true,
                        element: (
                            <Wrapper tab={5}>
                                <UsersPage/>
                            </Wrapper>
                        ),
                    },
                    {
                        path: ':id',
                        element: (
                            <Wrapper tab={5}>
                                <User/>
                            </Wrapper>
                        ),
                    },
                ],
            },
            {
                path: 'roles',
                children: [
                    {
                        path: ':id',
                        element: (
                            <Wrapper tab={5}>
                                <Role/>
                            </Wrapper>
                        ),
                    },
                ],
            },
            {
                path: 'clients',
                children: [
                    {
                        index: true,
                        element: (
                            <Wrapper tab={6}>
                                <ClientTable/>
                            </Wrapper>
                        ),
                    },
                    {
                        path: ':id',
                        element: (
                            <Wrapper tab={6}>
                                <Client/>
                            </Wrapper>
                        ),
                    },
                ],
            },
            {
                path: 'dcr',
                children: [
                    {
                        index: true,
                        element: (
                            <Wrapper tab={7}>
                                <DcrRequest/>
                            </Wrapper>
                        ),
                    },
                    {
                        path: 'responses',
                        element: (
                            <Wrapper tab={8}>
                                <DcrResponse/>
                            </Wrapper>
                        ),
                    },
                ],
            },
            {
                path: 'data',
                element: (
                    <Wrapper tab={9}>
                        <ImportTabs/>
                    </Wrapper>
                ),
            },
            {
                path: 'export',
                element: (
                    <Wrapper tab={10}>
                        <ExportTabs/>
                    </Wrapper>
                ),
            },
            {
                path: 'admin',
                element: (
                    <Wrapper tab={11}>
                        <AdminPage/>
                    </Wrapper>
                ),
            },
            {
                path: 'analytics',
                children: [
                    {
                        path: 'exchange/dashboard',
                        element: (
                            <Wrapper tab={12}>
                                <ExchangeDashboards/>
                            </Wrapper>
                        ),
                    },
                    {
                        path: 'exchange/report',
                        element: (
                            <Wrapper tab={13}>
                                <ExchangeReport/>
                            </Wrapper>
                        ),
                    },
                    {
                        path: 'dcr/dashboard',
                        element: (
                            <Wrapper tab={14}>
                                <DcrDashboards/>
                            </Wrapper>
                        ),
                    },
                    {
                        path: 'dcr/report',
                        element: (
                            <Wrapper tab={15}>
                                <DcrReport/>
                            </Wrapper>
                        ),
                    },
                ],
            },
            {
                path: 'jurisdictions',
                element: (
                    <Wrapper tab={16}>
                        <JurisdictionTable/>
                    </Wrapper>
                ),
            },
        ],
    },
];
