import React, {FC, useState} from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {AccordionTitle} from "../../pages/clients/tabs/bounds/Bounds";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import {t} from "i18next";


export type FiltersComponentProps = {
    children?: React.ReactNode
    title?: string
}


export const FiltersComponent: FC<FiltersComponentProps> = (props) => {
    const { children, title = t('common.filters') } = props;

    const [open,setOpen] = useState<boolean>(true);

    return <Accordion expanded={open!}
                      style={{margin: '15px 0', borderRadius: 8}}
                      onChange={(e, isExpanded) => setOpen(isExpanded)}>
        <AccordionSummary
            style={{margin: 'unset', height: 'max-content', minHeight: 'unset'}}
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"

        >
            <AccordionTitle
                style={{
                    margin: 'unset',
                    height: 'max-content',
                    minHeight: 'unset',
                    color: 'rgb(117, 130, 235)'
                }}
                isExpand={open}
                title={title!}/>
        </AccordionSummary>
        <AccordionDetails style={{padding: '0 16px'}}>
            {children}
        </AccordionDetails>
    </Accordion>
};
