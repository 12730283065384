import {t} from "i18next";
import {getApiConfiguration} from "../../../core";
import {OrganizationDoblesApi, OrganizationsApi, PersonDoblesApi, PersonsApi} from "../../../services/management";
import {
    DcrOrganizationBlockInfo,
    DcrPersonBlockInfo,
    DCRStatusEnum,
    OrganizationField,
    OrganizationFieldCustom,
    PersonField,
    PersonFieldCustom,
    RequestCorrect,
    RequestStatusEnum,
    RequestTypeEnum,
    ResponseStatusEnum
} from "./types";
import {RequestFieldType} from "../../../services/dcr";

export const AddressFields: string[] = [
    "region",
    "city",
    "streettype",
    "street",
    "building",
]

export const AdditionalAddressFields: string[] = [
    "okrug",
    "district",
    "gps_lo",
    "gps_la",
    "postcode",
]

export const FullAddressFields: string[] = [...AddressFields,...AdditionalAddressFields];
export const PersonJobFields: string[] = ["company", "companyId", "position", "isPrimary", "primaryJob", "archivereason"];


export const PersonBlock: { [key in DcrPersonBlockInfo]: (PersonField | PersonFieldCustom)[]; } = {
    main: ["lastname", "firstname", "middlename", 'withoutMiddlename', "sex", "maidenname"],
    specialization: ["specialization", "isPrimarySpeciality"],
    job: ["job"],
    double: ["isDouble", "originalId"]
}

export const OrganizationBlock: { [key in DcrOrganizationBlockInfo]: (OrganizationField | OrganizationFieldCustom)[]; } = {
    main: ["name", "companytype", "parent", "cliniccategory", "archivereason", "code"],
    address: ["address"],
    phone: ["phone1", "phonecategory", "phonestatus", "phonetype", "additionalNumber"],
    double: ["isDouble", "originalId"],
}

export function mappingRequestStatusToString(status: RequestStatusEnum): string {
    switch (status) {
        case RequestStatusEnum.WaitingVerification:
            return t("dcrPage.statuses.waitingVerification")
        case RequestStatusEnum.OnVerification:
            return t("dcrPage.statuses.onVerification")
        case RequestStatusEnum.Verified:
            return t("dcrPage.statuses.verified")
        case RequestStatusEnum.Decline:
            return t("dcrPage.statuses.decline")
        case RequestStatusEnum.Partialy:
            return t("dcrPage.statuses.partialy")
        case RequestStatusEnum.Double:
            return t("dcrPage.statuses.isDouble")
    }
    return ""
}

export function mappingResponseStatusToString(status: ResponseStatusEnum): string {
    switch (status) {
        case ResponseStatusEnum.New:
            return t("dcrPage.responseStatus.new")
        case ResponseStatusEnum.Sended:
            return t("dcrPage.responseStatus.sender")
        case ResponseStatusEnum.Error:
            return t("dcrPage.responseStatus.error")
    }
    return ""
}


export function mappingRequestTypeToString(type: RequestTypeEnum | RequestFieldType): string {
    switch (type) {
        case RequestTypeEnum.Organization:
            return t("dcrPage.type.organization")
        case RequestTypeEnum.Person:
            return t("dcrPage.type.person")
    }
    return ""
}

export function mappingDcrStatusToString(type: DCRStatusEnum): string {
    switch (type) {
        case DCRStatusEnum.Create:
            return t("dcrPage.create")
        case DCRStatusEnum.Update:
            return t("dcrPage.update")
    }
    return ""
}

export function mappingRequestCorrectToString(type: RequestCorrect): string {
    switch (type) {
        case RequestCorrect.All:
            return t("common.all")
        case RequestCorrect.No:
            return t("common.noCorrect")
        case RequestCorrect.Yes:
            return t("common.correct")
    }
    return ""
}

export async function doubleValidate(type: RequestTypeEnum, id: string): Promise<boolean> {
    if (!id) return true
    if (type === RequestTypeEnum.Person) {
        const api_: PersonDoblesApi = new PersonDoblesApi(getApiConfiguration());
        const result = await api_.getPersonDobles({searchType: 'doble', searchId: id});
        if (!!result.length) return false;
        const api: PersonsApi = new PersonsApi(getApiConfiguration());
        try {
            await api.getPersonById({id: id});
            return true;
        } catch {
            return false;
        }
    } else {
        const api_: OrganizationDoblesApi = new OrganizationDoblesApi(getApiConfiguration());
        const result = await api_.getOrganizationDobles({searchType: 'doble', searchId: id});
        if (!!result.length) return false;
        const api: OrganizationsApi = new OrganizationsApi(getApiConfiguration());
        try {
            await api.getOrganizationById({id: id});
            return true;
        } catch {
            return false;
        }
    }
}

export const OrganizationFieldPriority: { [key in OrganizationField]: number; } = {
    name: 1,
    //companyId: 1.4,
    companytype: 2,
    parent: 2.1,
    cliniccategory: 2.2,
    code: 2.3,
    region: 3,
    city: 4,
    okrug: 4.5,
    district: 4.6,
    street: 5,
    streettype: 5.2,
    building: 6,
    postcode: 7,
    gps_la: 8,
    gps_lo: 9,
    phone1: 11,
    phonecategory: 12,
    phonestatus: 13,
    phonetype: 14,
    additionalNumber: 14.1,
    //brand: 15,
    archivereason: 16,
    // companyMorionId: 17,
    // reregistrations: 18,
    // tradeformat: 19,
    // main: 20,
    // movement: 21,
    originalId: 23,
    isDouble: 22,
}

export const PersonFieldPriority: { [key in PersonField]: number; } = {
    lastname: 1,
    firstname: 2,
    middlename: 3,
    withoutMiddlename: 3.1,
    sex: 4,
    maidenname: 4.5,
    specialization: 5,
    isPrimarySpeciality: 5.2,
    company: 6,
    companyId: 6.4,
    position: 7,
    isPrimary: 7.2,
    //region: 8,
    //street: 9,
    //city: 10,
    //building: 11,
    //postcode: 12,
    //gps_la: 13,
    //gps_lo: 14,
    // companyMorionId: 15,
    // phone1: 16,
    // phoneType: 17,
    // phoneStatus: 18,
    // phoneCategory: 19,
    archivereason: 20,
    primaryJob: 21,
    // owner: 22,
    originalId: 24,
    isDouble: 23
}


export async function getById<T, D>(params: {
    id: D | null,
    external: boolean,
    getInternal: (id: D) => Promise<T>,
    getExternal?: (id: D) => Promise<T[]>
}): Promise<T | null> {
    if (!params.id) return Promise.resolve(null);
    try {
        return params.external ? (await params.getExternal!(params.id))[0] : await params.getInternal(params.id);
    } catch (e) {
        return null;
    }
}
