import {getObject, setObject} from '../../core';
import {makeAutoObservable} from 'mobx';
import {RootStore} from "../RootStore";

interface LocalizationState {
    locale: string
}

export class LocalizationStore {
    readonly store: RootStore;
    private _locale: string = 'ru';

    constructor(store: RootStore) {
        this.store = store;
        const st = getObject<LocalizationState>('localization-store');
        if (st) {
            this._locale = st.locale;
        }
        makeAutoObservable(this);
    }

    get locale() {
        return this._locale;
    }

    set locale(value: string) {
        this._locale = value;
        this.saveState();
    }

    saveState() {
        setObject<LocalizationState>('localization-store', {
            locale: this.locale,
        });
    }
}
