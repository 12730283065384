import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Page, PageTab, PageTabs } from "../../../../components";
import { ParentChildTabsStore } from "../../../../stores/management/organizations/details/parentChild/ParentChildTabsStore";
import { OrganizationChildsTable } from "./OrganizationChildsTable";
import { OrganizationParent } from "./OrganizationParent";

interface DetailsProps {
    store: ParentChildTabsStore;
    style?: React.CSSProperties;
}

export const ParentChildTabs = observer(({ store, style }: DetailsProps) => {
    const { t } = useTranslation();

    const renderTabs = (index: number) => {
        switch (index) {
            case 0:
                return <OrganizationParent store={store.ParentStore} />;
            case 1:
                return <OrganizationChildsTable store={store.ChildStore} />;
            default:
                return null;
        }
    };

    return (
        <Page
            style={style}
        >
            <PageTabs
                style={{ marginTop: -4 }}
                index={store.tabIndex}
                onChange={async (t) => await store.setTabIndex(t)}>
                <PageTab label={t('common.orgParent').toUpperCase()} testid="ParentChildTabs-parent-tabs" />
                <PageTab label={t('common.orgChild').toUpperCase()} testid="ParentChildTabs-child-tabs" />
            </PageTabs>
            <div style={{ marginTop: 4, marginBottom: 4 }}>
                {renderTabs(store.tabIndex)}
            </div>
        </Page>
    );
});
