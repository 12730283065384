/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ListSortDirection = {
    Ascending: 'ascending',
    Descending: 'descending'
} as const;
export type ListSortDirection = typeof ListSortDirection[keyof typeof ListSortDirection];


export function ListSortDirectionFromJSON(json: any): ListSortDirection {
    return ListSortDirectionFromJSONTyped(json, false);
}

export function ListSortDirectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListSortDirection {
    return json as ListSortDirection;
}

export function ListSortDirectionToJSON(value?: ListSortDirection | null): any {
    return value as any;
}

