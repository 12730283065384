import {getApiConfiguration, IProcessing} from "../../../../../../core";
import {RoleItemStore} from "../RoleItemStore";
import {DefaultMultiSelectStore} from "../../../../../../components/shared/DefaultMultiSelectStore";
import {RoleUsersApi, UserDto, UsersApi} from "../../../../../../services/auth";
import {LoadStore} from "../../../../../utils";

export class UsersTabStore extends LoadStore implements IProcessing {
    processing: boolean = false;
    store: RoleItemStore;
    users: DefaultMultiSelectStore<UserDto> = new DefaultMultiSelectStore<UserDto>([], (filter) => new UsersApi(getApiConfiguration()).getUsers(filter))

    constructor(store: RoleItemStore) {
        super()
        this.store = store
    }

    async save(): Promise<void>{
        const roleUserIds = this.users.firstValue.map(x => x.id);
        const currentIds = this.users.value.map(x => x.id);

        const deletesIds = roleUserIds.filter(x => !currentIds.includes(x));
        const insetIds = currentIds.filter(x => !roleUserIds.includes(x));

        await this.deleteRoleUser(deletesIds)
        await this.createRoleUser(insetIds)
        await this.users.update(this.users.value)
    }

    async createRoleUser(ids: string[]): Promise<void>{
        await new RoleUsersApi(getApiConfiguration()).createRoleUsers({
            roleId: this.store.dto?.id!,
            setUserRoleDto: ids.map(x => ({
                roleId: this.store.dto.id,
                userId: x
            }))
        });
    }

    async deleteRoleUser(ids: string[]): Promise<void>{
        await new RoleUsersApi(getApiConfiguration({successTooltip: false})).removeRoleUsers({
            roleId: this.store.dto.id,
            setUserRoleDto: ids.map(x => ({
                roleId: this.store.dto.id,
                userId: x
            }))
        });
    }

    async request(): Promise<void> {
        const api: RoleUsersApi=   new RoleUsersApi(getApiConfiguration());
        const count = await api.getRoleUsersCount({roleId: this.store.dto.id});
        const users = await api.getRoleUsers({roleId: this.store.dto.id, size: count.count })

        this.users.update(users.map(x => x.user))
    }
}