import type {FC, ReactElement} from 'react';
import PropTypes from 'prop-types';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import {PaletteMode} from '../../../theme';
import {useTranslation} from "react-i18next";


interface Option {
    label: string;
    value: PaletteMode;
    icon: ReactElement;
}

const options: Option[] = [
    {
        label: 'Light',
        value: 'light',
        icon: (
            <SvgIcon fontSize="small">
                <WbSunnyOutlinedIcon/>
            </SvgIcon>
        )
    },
    {
        label: 'Dark',
        value: 'dark',
        icon: (
            <SvgIcon fontSize="small">
                <DarkModeOutlinedIcon/>
            </SvgIcon>
        )
    }
];

interface OptionsColorSchemeProps {
    onChange?: (value: PaletteMode) => void;
    value?: PaletteMode;
}

export const OptionsColorScheme: FC<OptionsColorSchemeProps> = (props) => {
    const {onChange, value} = props;
    const {t} = useTranslation()

    return (
        <Stack spacing={1}>
            <Typography
                color="text.secondary"
                variant="overline"
            >
                {t("ui.theme")}
            </Typography>
            <Stack
                alignItems="center"
                direction="row"
                flexWrap="wrap"
                gap={2}
            >
                {options.map((option) => (
                    <Chip
                        icon={option.icon}
                        key={option.value}
                        label={t("ui." + option.label.toLowerCase())}
                        onClick={() => onChange?.(option.value)}
                        sx={{
                            borderColor: 'transparent',
                            borderRadius: 1.5,
                            borderStyle: 'solid',
                            borderWidth: 2,
                            ...(option.value === value && {
                                borderColor: 'primary.main'
                            })
                        }}
                    />
                ))}
            </Stack>
        </Stack>
    );
};

OptionsColorScheme.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.oneOf(['light', 'dark'])
};
