/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OCMObjectState = {
    Created: 'created',
    Updated: 'updated',
    Deleted: 'deleted'
} as const;
export type OCMObjectState = typeof OCMObjectState[keyof typeof OCMObjectState];


export function OCMObjectStateFromJSON(json: any): OCMObjectState {
    return OCMObjectStateFromJSONTyped(json, false);
}

export function OCMObjectStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OCMObjectState {
    return json as OCMObjectState;
}

export function OCMObjectStateToJSON(value?: OCMObjectState | null): any {
    return value as any;
}

