import { makeAutoObservable } from 'mobx';
import { getApiConfiguration, IExpanded, IForm, IProcessing } from '../../../../../core';
import { OrgNameDto, OrgNamesApi, TypeNameDto, TypeNamesApi } from '../../../../../services/management';
import { OrganizationNameTableStore } from "./OrganizationNameTableStore";
import { DefaultSelectStore } from "../../../../../components/shared/DefaultSelectStore";
import i18n from "i18next";
import { DefaultValueStore } from '../../../../../components/shared/DefaultValueStore';


export class OrganizationNameItemStore implements IProcessing, IExpanded, IForm {
    readonly _store: OrganizationNameTableStore;
    dto: OrgNameDto;
    processing: boolean = false;
    deleteDialogState: boolean = false;
    expand: boolean = false;
    name: DefaultValueStore<string>;
    type: DefaultSelectStore<TypeNameDto>;

    constructor(store: OrganizationNameTableStore, dto: OrgNameDto) {
        this._store = store;
        this.dto = dto;
        this.name = new DefaultValueStore<string>(dto.name, (value) => !value ? i18n.t('errors.empty') : "");
        this.type = new DefaultSelectStore<TypeNameDto>(
            dto.typeName, (x) => new TypeNamesApi(getApiConfiguration()).getTypeNames(x),
            (value) => !value ? i18n.t('errors.empty') : "")
        makeAutoObservable(this);
    }

    async delete(): Promise<void> {
        if (this._store.items.length == 1)
            return;
        this.processing = true;
        const api: OrgNamesApi = new OrgNamesApi(getApiConfiguration());
        try {
            const result = await api.deleteOrgName({ id: this.dto.id });
            this._store.items = this._store.items.filter(t => t.dto.id !== result.id);
        }
        catch (e) {

        }
        this._store.current = null;
        this.processing = false;
    };

    get equals(): boolean {
        return this.dto.name === this.name.value && this.dto?.typeName?.id === this.type.value?.id
    }

    get valid(): boolean {
        return this.name.valid && this.type.valid;
    }

    validate() {
        this.name.validate();
        this.type.validate();
    }

    update(dto: OrgNameDto) {
        this.dto = dto;
        this.name.update(dto.name);
        this.type.update(dto.typeName);
    }

    reset(): void {
        this.name.reset();
        this.type.reset();
        this.validate();
    }

    async submit() {
        this.validate()
        if (!this.valid)
            return
        this.processing = true;
        const api: OrgNamesApi = new OrgNamesApi(getApiConfiguration());

        try {
            let item = await api.updateOrgName({
                id: this.dto.id,
                setOrgNameDto: {
                    name: this.name?.value!,
                    typeNameId: this.type.value?.id!,
                    organizationId: this._store._dto?.id!
                }
            })
            this.update(item);
            await this._store.pull();
            this._store._orgStore.propertiesStore.submit()
        }
        catch (e) {

        }
        this.processing = false;
    }
}
