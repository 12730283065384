import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, {FC, ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {CardContent} from "@mui/material";

interface Overview2Props {
    title: string;
    count: string | number;
    description?: string;
    icon?: ReactNode;
    sx?: React.CSSProperties;
    cardSx?: React.CSSProperties;
}

export const Overview2: FC<Overview2Props> = (props) => {
    const {t} = useTranslation();

    return (
        <Card sx={props.sx}>
            <CardContent sx={props.cardSx}>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                >
                    <div>
                        {props.icon}
                    </div>
                    <div>
                        <Typography
                            color="text.secondary"
                            variant="body2"
                        >
                            {props.title}
                        </Typography>
                        <Typography variant="h5">
                            {props.count}
                        </Typography>
                        <Typography
                            color="text.secondary"
                            variant="body2"
                        >
                            {props.description}
                        </Typography>
                    </div>
                </Stack>
            </CardContent>
        </Card>
    );
}
