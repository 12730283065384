import {observer} from "mobx-react";
import {CreateButtonsGroup, FormRoot, FormRow} from "../../../../components";
import {DefaultSelect} from "../../../../components/shared/components/DefaultSelect";
import React from "react";
import {TypeNameDto,} from "../../../../services/management";
import {useTranslation} from "react-i18next";
import {DefaultValue} from "../../../../components/shared/components/DefaultValue";
import {
    OrganizationNameCreatorStore
} from "../../../../stores/management/organizations/details/names/OrganizationNameCreatorStore";

interface Props {
    store: OrganizationNameCreatorStore
}

export const OrganizationNameCreator = observer(({ store }: Props) => {
    const { t } = useTranslation();

    return (
        <FormRoot sx={{ p: 2 }}>
            <FormRow>
                <DefaultValue
                    autoFocus
                    style={{ width: '100%' }}
                    store={store.name}
                    title={t('common.name')}
                    testid="organizationNameCreator-name"
                    disabled={store.processing}
                />
            </FormRow>
            <FormRow>
                <DefaultSelect
                    store={store.type}
                    title={t('common.type')}
                    testid="organizationNameCreator-type"
                    nameExp={(x: TypeNameDto) => store._store.listNames.includes(x.nameRus) ? '' : x.nameRus}
                />
            </FormRow>
            <CreateButtonsGroup
                onCancel={() => store.Open = false}
                onSave={async () => await store.create()}
                isCancelBtnDisabled={store.processing}
                isSaveBtnDisabled={store.processing || !store.valid}
                testid="organizationNameCreator"
            />
        </FormRoot>
    );
});
