import {getApiConfiguration, getObject, setObject} from '../../../../../core';
import {PersonWebsiteStatusesApi} from "../../../../../services/management";
import {DictionaryCreateEntity, DictionaryEntity, DictionaryTableStore} from "../../DictionaryTableStore";
import {DictionaryPersonStore} from "../DictionaryPersonStore";

export class PersonWebsiteStatusTableStore extends DictionaryTableStore {
    constructor(store: DictionaryPersonStore) {
        super(store, 'website-status-store');
    }

    async Create(model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new PersonWebsiteStatusesApi(getApiConfiguration()).createPersonWebsiteStatus({setWebsiteStatusDto: model});
    }

    async Delete(id: string): Promise<DictionaryEntity> {
        return await new PersonWebsiteStatusesApi(getApiConfiguration()).deletePersonWebsiteStatus({id: id});
    }

    async GetById(id: string): Promise<DictionaryEntity> {
        return await new PersonWebsiteStatusesApi(getApiConfiguration()).getPersonWebsiteStatusById({id: id});
    }

    async GetCount(search: string): Promise<{ count: number }> {
        return await new PersonWebsiteStatusesApi(getApiConfiguration()).getPersonWebsiteStatusesCount({search: search});
    }

    async GetList(filters: any): Promise<DictionaryEntity[]> {
        return await new PersonWebsiteStatusesApi(getApiConfiguration()).getPersonWebsiteStatuses(filters)
    }

    async Update(id: string, model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new PersonWebsiteStatusesApi(getApiConfiguration()).updatePersonWebsiteStatus({id: id, setWebsiteStatusDto: model});
    }
}
