import {observer} from "mobx-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {FormRoot} from "../../../../components";
import {Box, Grid, List} from "@mui/material";
import {CopyToClipboard} from "../../../../components/CopyToClipboard";
import {toDateTimeString} from "../../../../core";
import {emptyFieldHandler, getStatusNameHandler} from "../../../../stores";
import {DcrListItem} from "../../DcrListItem";
import {NavLink} from "../../../../components/NavLink";
import { PersonStore } from "../../../../stores/management/dcr/requests/specifications/entity/PersonStore";

interface Props {
    store: PersonStore;
}

export const PersonExternalFirstValues = observer(({store}: Props) => {
    const {t} = useTranslation();
    return (
        <FormRoot>
            {
                !store?.loading ?
                    (
                        <Grid container spacing={2} >
                            <Grid container item xs={6} direction="column" >
                                <DcrListItem
                                  name={t('dcrPage.fields.firstname')}
                                  value={store._store.getFirstValue("firstname")}>
                                </DcrListItem>
                                <DcrListItem
                                  name={t('dcrPage.fields.lastname')}
                                  value={store._store.getFirstValue("lastname")}>
                                </DcrListItem>
                                <DcrListItem
                                  name={t('dcrPage.fields.middlename')}
                                  value={store._store.getFirstValue("middlename")}>
                                </DcrListItem>
                                <DcrListItem
                                  name={t('dcrPage.fields.sex')}
                                  value={store._store.getFirstValue("sex")}>
                                </DcrListItem>
                                <DcrListItem
                                  name={t('dcrPage.fields.status')}
                                  value={store._store.getFirstValue("status")}>
                                </DcrListItem>
                            </Grid>
                            <Grid container item xs={6} direction="column" >
                                <DcrListItem 
                                    name={t('dcrPage.fields.company')}
                                    value={store._store.getFirstValue("company")}>
                                </DcrListItem>
                                <DcrListItem 
                                    name={t('dcrPage.fields.position')}
                                    value={store._store.getFirstValue("position")}>
                                </DcrListItem>
                                <DcrListItem 
                                    name={t('dcrPage.fields.specialization')}
                                    value={store._store.getFirstValue("specialization")}>
                                </DcrListItem>
                            </Grid>
                        </Grid>
                    )
                    : <Box sx={{pt: 2}}>{t("common.download")}...</Box>
            }
        </FormRoot>
    );
});