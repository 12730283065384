/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BrickDto,
    BrickDtoFromJSON,
    BrickDtoToJSON,
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetBrickDto,
    SetBrickDtoFromJSON,
    SetBrickDtoToJSON,
} from '../models';

export interface CreateBrickRequest {
    setBrickDto: SetBrickDto;
}

export interface DeleteBrickRequest {
    id: string;
}

export interface GetBrickByIdRequest {
    id: string;
}

export interface GetBrickByNameRequest {
    name: string;
}

export interface GetBricksRequest {
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetBricksCountRequest {
    search?: string;
}

export interface UpdateBrickRequest {
    id: string;
    setBrickDto: SetBrickDto;
}

/**
 * 
 */
export class BricksApi extends runtime.BaseAPI {

    /**
     */
    async createBrickRaw(requestParameters: CreateBrickRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<BrickDto>> {
        if (requestParameters.setBrickDto === null || requestParameters.setBrickDto === undefined) {
            throw new runtime.RequiredError('setBrickDto','Required parameter requestParameters.setBrickDto was null or undefined when calling createBrick.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/addresses/bricks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetBrickDtoToJSON(requestParameters.setBrickDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrickDtoFromJSON(jsonValue));
    }

    /**
     */
    async createBrick(requestParameters: CreateBrickRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<BrickDto> {
        const response = await this.createBrickRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteBrickRaw(requestParameters: DeleteBrickRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<BrickDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteBrick.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/bricks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrickDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteBrick(requestParameters: DeleteBrickRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<BrickDto> {
        const response = await this.deleteBrickRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBrickByIdRaw(requestParameters: GetBrickByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<BrickDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBrickById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/bricks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrickDtoFromJSON(jsonValue));
    }

    /**
     */
    async getBrickById(requestParameters: GetBrickByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<BrickDto> {
        const response = await this.getBrickByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBrickByNameRaw(requestParameters: GetBrickByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<BrickDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getBrickByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/bricks/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrickDtoFromJSON(jsonValue));
    }

    /**
     */
    async getBrickByName(requestParameters: GetBrickByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<BrickDto> {
        const response = await this.getBrickByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBricksRaw(requestParameters: GetBricksRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<BrickDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/bricks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BrickDtoFromJSON));
    }

    /**
     */
    async getBricks(requestParameters: GetBricksRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<BrickDto>> {
        const response = await this.getBricksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBricksCountRaw(requestParameters: GetBricksCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/bricks/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getBricksCount(requestParameters: GetBricksCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getBricksCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateBrickRaw(requestParameters: UpdateBrickRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<BrickDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBrick.');
        }

        if (requestParameters.setBrickDto === null || requestParameters.setBrickDto === undefined) {
            throw new runtime.RequiredError('setBrickDto','Required parameter requestParameters.setBrickDto was null or undefined when calling updateBrick.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/addresses/bricks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetBrickDtoToJSON(requestParameters.setBrickDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrickDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateBrick(requestParameters: UpdateBrickRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<BrickDto> {
        const response = await this.updateBrickRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
