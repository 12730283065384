/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    DobleSearchType,
    DobleSearchTypeFromJSON,
    DobleSearchTypeToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    PersonDobleDto,
    PersonDobleDtoFromJSON,
    PersonDobleDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    QueryType,
    QueryTypeFromJSON,
    QueryTypeToJSON,
    SetPersonDobleDto,
    SetPersonDobleDtoFromJSON,
    SetPersonDobleDtoToJSON,
} from '../models';

export interface CreatePersonDobleRequest {
    setPersonDobleDto: SetPersonDobleDto;
}

export interface DeletePersonDobleRequest {
    id: string;
}

export interface GetPersonDobleByIdRequest {
    id: string;
}

export interface GetPersonDoblesRequest {
    searchType?: DobleSearchType;
    searchId?: string;
    orderBy?: string;
    queryType?: QueryType;
    parentId?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetPersonDoblesCountRequest {
    search?: string;
    searchByExternalId?: string;
    parentId?: string;
    clinicCategoryId?: string;
    queryType?: QueryType;
}

/**
 * 
 */
export class PersonDoblesApi extends runtime.BaseAPI {

    /**
     */
    async createPersonDobleRaw(requestParameters: CreatePersonDobleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonDobleDto>> {
        if (requestParameters.setPersonDobleDto === null || requestParameters.setPersonDobleDto === undefined) {
            throw new runtime.RequiredError('setPersonDobleDto','Required parameter requestParameters.setPersonDobleDto was null or undefined when calling createPersonDoble.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/persons/dobles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPersonDobleDtoToJSON(requestParameters.setPersonDobleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDobleDtoFromJSON(jsonValue));
    }

    /**
     */
    async createPersonDoble(requestParameters: CreatePersonDobleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonDobleDto> {
        const response = await this.createPersonDobleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePersonDobleRaw(requestParameters: DeletePersonDobleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonDobleDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePersonDoble.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/dobles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDobleDtoFromJSON(jsonValue));
    }

    /**
     */
    async deletePersonDoble(requestParameters: DeletePersonDobleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonDobleDto> {
        const response = await this.deletePersonDobleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonDobleByIdRaw(requestParameters: GetPersonDobleByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonDobleDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPersonDobleById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/dobles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDobleDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonDobleById(requestParameters: GetPersonDobleByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonDobleDto> {
        const response = await this.getPersonDobleByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonDoblesRaw(requestParameters: GetPersonDoblesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonDobleDto>>> {
        const queryParameters: any = {};

        if (requestParameters.searchType !== undefined) {
            queryParameters['searchType'] = requestParameters.searchType;
        }

        if (requestParameters.searchId !== undefined) {
            queryParameters['searchId'] = requestParameters.searchId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.queryType !== undefined) {
            queryParameters['queryType'] = requestParameters.queryType;
        }

        if (requestParameters.parentId !== undefined) {
            queryParameters['parentId'] = requestParameters.parentId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/dobles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonDobleDtoFromJSON));
    }

    /**
     */
    async getPersonDobles(requestParameters: GetPersonDoblesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonDobleDto>> {
        const response = await this.getPersonDoblesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonDoblesCountRaw(requestParameters: GetPersonDoblesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.parentId !== undefined) {
            queryParameters['parentId'] = requestParameters.parentId;
        }

        if (requestParameters.clinicCategoryId !== undefined) {
            queryParameters['clinicCategoryId'] = requestParameters.clinicCategoryId;
        }

        if (requestParameters.queryType !== undefined) {
            queryParameters['queryType'] = requestParameters.queryType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/dobles/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonDoblesCount(requestParameters: GetPersonDoblesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPersonDoblesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
