/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    CreateJobTagDto,
    CreateJobTagDtoFromJSON,
    CreateJobTagDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    TagDto,
    TagDtoFromJSON,
    TagDtoToJSON,
    UpdateJobTagDto,
    UpdateJobTagDtoFromJSON,
    UpdateJobTagDtoToJSON,
} from '../models';

export interface CreateJobTagRequest {
    createJobTagDto?: CreateJobTagDto;
}

export interface DeleteJobTagRequest {
    id: string;
}

export interface GetJobTagsRequest {
    name?: string;
    page?: number;
    size?: number;
}

export interface GetJobTagsCountRequest {
    name?: string;
}

export interface UpdateJobTagRequest {
    id: string;
    updateJobTagDto?: UpdateJobTagDto;
}

/**
 * 
 */
export class JobTagsApi extends runtime.BaseAPI {

    /**
     */
    async createJobTagRaw(requestParameters: CreateJobTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TagDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/jobs/tags`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateJobTagDtoToJSON(requestParameters.createJobTagDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagDtoFromJSON(jsonValue));
    }

    /**
     */
    async createJobTag(requestParameters: CreateJobTagRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TagDto> {
        const response = await this.createJobTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteJobTagRaw(requestParameters: DeleteJobTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TagDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteJobTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jobs/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteJobTag(requestParameters: DeleteJobTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TagDto> {
        const response = await this.deleteJobTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getJobTagsRaw(requestParameters: GetJobTagsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<TagDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jobs/tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TagDtoFromJSON));
    }

    /**
     */
    async getJobTags(requestParameters: GetJobTagsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<TagDto>> {
        const response = await this.getJobTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getJobTagsCountRaw(requestParameters: GetJobTagsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jobs/tags/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getJobTagsCount(requestParameters: GetJobTagsCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getJobTagsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateJobTagRaw(requestParameters: UpdateJobTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TagDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateJobTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/jobs/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateJobTagDtoToJSON(requestParameters.updateJobTagDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateJobTag(requestParameters: UpdateJobTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TagDto> {
        const response = await this.updateJobTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
