/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    PersonJobDto,
    PersonJobDtoFromJSON,
    PersonJobDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetPersonJobDto,
    SetPersonJobDtoFromJSON,
    SetPersonJobDtoToJSON,
    UpdatePersonJobDto,
    UpdatePersonJobDtoFromJSON,
    UpdatePersonJobDtoToJSON,
} from '../models';

export interface CreatePersonJobRequest {
    setPersonJobDto?: SetPersonJobDto;
}

export interface DeletePersonJobRequest {
    id: string;
}

export interface GetAllPersonJobsRequest {
    personId: string;
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    postIds?: Array<string>;
    statusIds?: Array<number>;
    organizationIds?: Array<string>;
    states?: Array<number>;
    fullEquals?: boolean;
    tags?: Array<string>;
}

export interface GetAllPersonJobsCountRequest {
    personId: string;
    search?: string;
    postIds?: Array<string>;
    statusIds?: Array<number>;
    organizationIds?: Array<string>;
    states?: Array<number>;
    fullEquals?: boolean;
    tags?: Array<string>;
}

export interface GetPersonJobsRequest {
    personId: string;
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    postIds?: Array<string>;
    statusIds?: Array<string>;
    organizationIds?: Array<string>;
    fullEquals?: boolean;
}

export interface GetPersonJobsCountRequest {
    personId: string;
    search?: string;
    postIds?: Array<string>;
    statusIds?: Array<string>;
    organizationIds?: Array<string>;
}

export interface RemovePersonJobsRequest {
    personId: string;
    setPersonJobDto: Array<SetPersonJobDto>;
}

export interface SetPersonJobsRequest {
    personId: string;
    setPersonJobDto: Array<SetPersonJobDto>;
}

export interface TickJobRequest {
    id: string;
}

export interface UpdatePersonJobRequest {
    id: string;
    updatePersonJobDto: UpdatePersonJobDto;
}

/**
 * 
 */
export class JobsApi extends runtime.BaseAPI {

    /**
     */
    async createPersonJobRaw(requestParameters: CreatePersonJobRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonJobDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/jobs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPersonJobDtoToJSON(requestParameters.setPersonJobDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonJobDtoFromJSON(jsonValue));
    }

    /**
     */
    async createPersonJob(requestParameters: CreatePersonJobRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonJobDto> {
        const response = await this.createPersonJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePersonJobRaw(requestParameters: DeletePersonJobRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonJobDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePersonJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jobs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonJobDtoFromJSON(jsonValue));
    }

    /**
     */
    async deletePersonJob(requestParameters: DeletePersonJobRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonJobDto> {
        const response = await this.deletePersonJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllPersonJobsRaw(requestParameters: GetAllPersonJobsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonJobDto>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getAllPersonJobs.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.postIds) {
            queryParameters['postIds'] = requestParameters.postIds;
        }

        if (requestParameters.statusIds) {
            queryParameters['statusIds'] = requestParameters.statusIds;
        }

        if (requestParameters.organizationIds) {
            queryParameters['organizationIds'] = requestParameters.organizationIds;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        if (requestParameters.fullEquals !== undefined) {
            queryParameters['fullEquals'] = requestParameters.fullEquals;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jobs/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonJobDtoFromJSON));
    }

    /**
     */
    async getAllPersonJobs(requestParameters: GetAllPersonJobsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonJobDto>> {
        const response = await this.getAllPersonJobsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllPersonJobsCountRaw(requestParameters: GetAllPersonJobsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getAllPersonJobsCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.postIds) {
            queryParameters['postIds'] = requestParameters.postIds;
        }

        if (requestParameters.statusIds) {
            queryParameters['statusIds'] = requestParameters.statusIds;
        }

        if (requestParameters.organizationIds) {
            queryParameters['organizationIds'] = requestParameters.organizationIds;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        if (requestParameters.fullEquals !== undefined) {
            queryParameters['fullEquals'] = requestParameters.fullEquals;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jobs/all/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getAllPersonJobsCount(requestParameters: GetAllPersonJobsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getAllPersonJobsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonJobsRaw(requestParameters: GetPersonJobsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonJobDto>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getPersonJobs.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.postIds) {
            queryParameters['postIds'] = requestParameters.postIds;
        }

        if (requestParameters.statusIds) {
            queryParameters['statusIds'] = requestParameters.statusIds;
        }

        if (requestParameters.organizationIds) {
            queryParameters['organizationIds'] = requestParameters.organizationIds;
        }

        if (requestParameters.fullEquals !== undefined) {
            queryParameters['fullEquals'] = requestParameters.fullEquals;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonJobDtoFromJSON));
    }

    /**
     */
    async getPersonJobs(requestParameters: GetPersonJobsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonJobDto>> {
        const response = await this.getPersonJobsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonJobsCountRaw(requestParameters: GetPersonJobsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getPersonJobsCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.postIds) {
            queryParameters['postIds'] = requestParameters.postIds;
        }

        if (requestParameters.statusIds) {
            queryParameters['statusIds'] = requestParameters.statusIds;
        }

        if (requestParameters.organizationIds) {
            queryParameters['organizationIds'] = requestParameters.organizationIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jobs/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonJobsCount(requestParameters: GetPersonJobsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPersonJobsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removePersonJobsRaw(requestParameters: RemovePersonJobsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonJobDto>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling removePersonJobs.');
        }

        if (requestParameters.setPersonJobDto === null || requestParameters.setPersonJobDto === undefined) {
            throw new runtime.RequiredError('setPersonJobDto','Required parameter requestParameters.setPersonJobDto was null or undefined when calling removePersonJobs.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/jobs/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setPersonJobDto.map(SetPersonJobDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonJobDtoFromJSON));
    }

    /**
     */
    async removePersonJobs(requestParameters: RemovePersonJobsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonJobDto>> {
        const response = await this.removePersonJobsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setPersonJobsRaw(requestParameters: SetPersonJobsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonJobDto>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling setPersonJobs.');
        }

        if (requestParameters.setPersonJobDto === null || requestParameters.setPersonJobDto === undefined) {
            throw new runtime.RequiredError('setPersonJobDto','Required parameter requestParameters.setPersonJobDto was null or undefined when calling setPersonJobs.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/jobs/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setPersonJobDto.map(SetPersonJobDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonJobDtoFromJSON));
    }

    /**
     */
    async setPersonJobs(requestParameters: SetPersonJobsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonJobDto>> {
        const response = await this.setPersonJobsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tickJobRaw(requestParameters: TickJobRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonJobDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tickJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jobs/{id}/tick`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonJobDtoFromJSON(jsonValue));
    }

    /**
     */
    async tickJob(requestParameters: TickJobRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonJobDto> {
        const response = await this.tickJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePersonJobRaw(requestParameters: UpdatePersonJobRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonJobDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePersonJob.');
        }

        if (requestParameters.updatePersonJobDto === null || requestParameters.updatePersonJobDto === undefined) {
            throw new runtime.RequiredError('updatePersonJobDto','Required parameter requestParameters.updatePersonJobDto was null or undefined when calling updatePersonJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/jobs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePersonJobDtoToJSON(requestParameters.updatePersonJobDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonJobDtoFromJSON(jsonValue));
    }

    /**
     */
    async updatePersonJob(requestParameters: UpdatePersonJobRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonJobDto> {
        const response = await this.updatePersonJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
