/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    PersonDepJobDto,
    PersonDepJobDtoFromJSON,
    PersonDepJobDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface GetDepEmployeesCountRequest {
    organizationDepId: string;
    search?: string;
    firstName?: string;
    surname?: string;
    middleName?: string;
    postIds?: Array<string>;
    statusIds?: Array<string>;
}

export interface GetPersonDepEmployeesRequest {
    organizationDepId: string;
    search?: string;
    firstName?: string;
    surname?: string;
    middleName?: string;
    postIds?: Array<string>;
    statusIds?: Array<string>;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

/**
 * 
 */
export class DepEmployeesApi extends runtime.BaseAPI {

    /**
     */
    async getDepEmployeesCountRaw(requestParameters: GetDepEmployeesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        if (requestParameters.organizationDepId === null || requestParameters.organizationDepId === undefined) {
            throw new runtime.RequiredError('organizationDepId','Required parameter requestParameters.organizationDepId was null or undefined when calling getDepEmployeesCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationDepId !== undefined) {
            queryParameters['organizationDepId'] = requestParameters.organizationDepId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.firstName !== undefined) {
            queryParameters['firstName'] = requestParameters.firstName;
        }

        if (requestParameters.surname !== undefined) {
            queryParameters['surname'] = requestParameters.surname;
        }

        if (requestParameters.middleName !== undefined) {
            queryParameters['middleName'] = requestParameters.middleName;
        }

        if (requestParameters.postIds) {
            queryParameters['postIds'] = requestParameters.postIds;
        }

        if (requestParameters.statusIds) {
            queryParameters['statusIds'] = requestParameters.statusIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/depjobs/employees/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getDepEmployeesCount(requestParameters: GetDepEmployeesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getDepEmployeesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonDepEmployeesRaw(requestParameters: GetPersonDepEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonDepJobDto>>> {
        if (requestParameters.organizationDepId === null || requestParameters.organizationDepId === undefined) {
            throw new runtime.RequiredError('organizationDepId','Required parameter requestParameters.organizationDepId was null or undefined when calling getPersonDepEmployees.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationDepId !== undefined) {
            queryParameters['organizationDepId'] = requestParameters.organizationDepId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.firstName !== undefined) {
            queryParameters['firstName'] = requestParameters.firstName;
        }

        if (requestParameters.surname !== undefined) {
            queryParameters['surname'] = requestParameters.surname;
        }

        if (requestParameters.middleName !== undefined) {
            queryParameters['middleName'] = requestParameters.middleName;
        }

        if (requestParameters.postIds) {
            queryParameters['postIds'] = requestParameters.postIds;
        }

        if (requestParameters.statusIds) {
            queryParameters['statusIds'] = requestParameters.statusIds;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/depjobs/employees`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonDepJobDtoFromJSON));
    }

    /**
     */
    async getPersonDepEmployees(requestParameters: GetPersonDepEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonDepJobDto>> {
        const response = await this.getPersonDepEmployeesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
