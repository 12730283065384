import {Box} from "@mui/material";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {AuthorizePermission, DeleteButton, FormRoot, FormRow, ParamsButtonGroup} from "../../../../../components";
import {DefaultMultiSelect} from "../../../../../components/shared/components/DefaultMultiSelect";
import {DefaultSelect} from "../../../../../components/shared/components/DefaultSelect";
import {DefaultValue} from "../../../../../components/shared/components/DefaultValue";
import {AllPermissions} from "../../../../../core/const";
import {StoreProps, StyleProps} from "../../../../../stores";
import {ParametersTabStore} from "../../../../../stores/management/users/tabs/Groups/tabs/ParametersTabStore";


export const ParametersTab = observer(({ store, style }: StoreProps<ParametersTabStore> & StyleProps) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ py: 2 }}>

            <FormRoot
                style={style}
            >
                <FormRow>
                    <DefaultValue
                        autoFocus
                        style={{ width: '100%' }}
                        testid="ParametersTab-name-input"
                        store={store.form.fields.name}
                        disabled={store.store.store.processing}
                        title={t('usersPage.groupName')} />
                </FormRow>
                <FormRow>
                    <DefaultSelect title={t('usersPage.owner')} nameExp='username'
                        store={store.form.fields.owner} testid="ParametersTab-owner" />
                </FormRow>
                <DefaultMultiSelect title={t('usersPage.participants')}
                    nameExp='username'
                    store={store.form.fields.participants}
                    style={{ width: '100%', marginBottom: '10px' }}
                    testid="ParametersTab-participants" />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >

                    <AuthorizePermission permissions={AllPermissions.user.update}>
                        <ParamsButtonGroup
                            testid="ParametersTab"
                            onCancel={() => store.store.restore()}
                            onSave={async () => await store.form.submit()}
                            isCancelBtnDisabled={store.store.store.processing || store.form.equals}
                            isSaveBtnDisabled={store.store.store.processing || store.form.equals || !store.form.valid}
                        />
                    </AuthorizePermission>

                    <AuthorizePermission permissions={AllPermissions.user.delete}>
                        <DeleteButton onClick={() => {
                            store.store.store.current = store.store;
                            store.store.store.deleteDialogState = true
                        }}
                            disabled={store.store.store.processing} />
                    </AuthorizePermission>
                </Box>
            </FormRoot>
        </Box>
    );
});
