import {makeAutoObservable} from 'mobx';
import {LocalityValueStore} from "./LocalityValueStore";
import {IModal} from "../../../core";
import {LocalityDto} from "../../../services/management";
import { DefaultValueStore } from '../../shared/DefaultValueStore';

export class LocalityFilterStore implements IModal {
    items: { id: string, name: string, dto: LocalityDto, house?: string }[] = [];
    locality: LocalityValueStore;
    house: DefaultValueStore<string>;
    loading: boolean = false;
    Open: boolean = false;
    readonly _setValue: ((value: LocalityDto[]) => void) | null;

    constructor(setValue: ((value: LocalityDto[]) => void) | null = null) {
        this.locality = new LocalityValueStore()
        this.house = new DefaultValueStore<string>("")
        this._setValue = setValue;

        makeAutoObservable(this);
    }

    open(){
        this.Open = true;
        this.locality = new LocalityValueStore();
    }

    deleteFilter(index: number) {
        this.items = this.items.filter((item, i) => i != index);
        if(this._setValue)
            this._setValue(this.items.map(x => x.dto))
    }

    resetItems (){
        this.items = []
    }

    resetHouse (){
        this.house = new DefaultValueStore<string>("")
    }

    save() {
        let locality = this.locality.currentLocality;
        if (!!locality && this.items.map(x => x.id).indexOf(locality.value?.id!) == -1){
            this.items.push({
                id: locality.id,
                name: this.locality.fullName,
                dto: this.locality.value!,
                house: this.house ? this.house.value! : ''
            })
            if(this._setValue)
                this._setValue(this.items.map(x => x.dto))
        }

        this.Open = false;
    }

}
