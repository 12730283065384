import {observer} from 'mobx-react';
import {Box, Button, Step, StepLabel, Stepper, Typography} from '@mui/material';
import React from "react";
import {PropertyCreator} from "./steps/PropertyCreator";
import {useTranslation} from "react-i18next";
import {PersonCreatorStore} from "../../../stores/management/persons/PersonCreatorStore";
import {SpecialityCreator} from "./steps/SpecialityCreator";
import {JobCreator} from "./steps/JobCreator";
import {ProgressCircleCenter} from "../../../components";

interface Props {
    store: PersonCreatorStore;
}

export const PersonCreator = observer(({store}: Props) => {
    const {t} = useTranslation();

    return (
        <Box sx={{width: '100%', marginTop: 1, pointerEvents: store.processing ? "none" : 'auto'}}>
            <ProgressCircleCenter store={store}/>
            <Stepper activeStep={store.step}>
                {store.steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps} sx={{mx: 1}}>{t(`common.${label}`)}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <React.Fragment>
                <Typography sx={{mt: 3}}>
                    {
                        store.step === 0 &&
                        <PropertyCreator store={store.property}/>
                    }
                    {
                        store.step === 1 &&
                        <SpecialityCreator store={store.speciality}/>
                    }
                    {
                        store.step === 2 &&
                        <JobCreator store={store.job}/>
                    }

                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 1}}>
                    <Button
                        color="inherit"
                        disabled={store.step == 0 || store.processing}
                        onClick={() => store.back()}
                        sx={{mr: 1}}
                    >
                        {t('buttons.back')}
                    </Button>
                    <Box sx={{flex: '1 1 auto'}}/>
                    <Button onClick={async () => {
                        await store.next()
                    }}
                            disabled={store.processing || !store.validStep}>
                        {store.step === store.steps.length - 1 ? t('buttons.finish') : t('buttons.next')}
                    </Button>
                </Box>
            </React.Fragment>
        </Box>
    );
});
