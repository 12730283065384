/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetUserRoleDto,
    SetUserRoleDtoFromJSON,
    SetUserRoleDtoToJSON,
    UserRoleDto,
    UserRoleDtoFromJSON,
    UserRoleDtoToJSON,
} from '../models';

export interface CreateRoleUsersRequest {
    roleId: string;
    setUserRoleDto: Array<SetUserRoleDto>;
}

export interface GetRoleUsersRequest {
    roleId: string;
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetRoleUsersCountRequest {
    roleId: string;
    search?: string;
}

export interface RemoveRoleUsersRequest {
    roleId: string;
    setUserRoleDto: Array<SetUserRoleDto>;
}

/**
 * 
 */
export class RoleUsersApi extends runtime.BaseAPI {

    /**
     */
    async createRoleUsersRaw(requestParameters: CreateRoleUsersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UserRoleDto>>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling createRoleUsers.');
        }

        if (requestParameters.setUserRoleDto === null || requestParameters.setUserRoleDto === undefined) {
            throw new runtime.RequiredError('setUserRoleDto','Required parameter requestParameters.setUserRoleDto was null or undefined when calling createRoleUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.roleId !== undefined) {
            queryParameters['roleId'] = requestParameters.roleId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/v1/roles/users/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setUserRoleDto.map(SetUserRoleDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserRoleDtoFromJSON));
    }

    /**
     */
    async createRoleUsers(requestParameters: CreateRoleUsersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserRoleDto>> {
        const response = await this.createRoleUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRoleUsersRaw(requestParameters: GetRoleUsersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UserRoleDto>>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling getRoleUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.roleId !== undefined) {
            queryParameters['roleId'] = requestParameters.roleId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/roles/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserRoleDtoFromJSON));
    }

    /**
     */
    async getRoleUsers(requestParameters: GetRoleUsersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserRoleDto>> {
        const response = await this.getRoleUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRoleUsersCountRaw(requestParameters: GetRoleUsersCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling getRoleUsersCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.roleId !== undefined) {
            queryParameters['roleId'] = requestParameters.roleId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/roles/users/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getRoleUsersCount(requestParameters: GetRoleUsersCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getRoleUsersCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeRoleUsersRaw(requestParameters: RemoveRoleUsersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UserRoleDto>>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling removeRoleUsers.');
        }

        if (requestParameters.setUserRoleDto === null || requestParameters.setUserRoleDto === undefined) {
            throw new runtime.RequiredError('setUserRoleDto','Required parameter requestParameters.setUserRoleDto was null or undefined when calling removeRoleUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.roleId !== undefined) {
            queryParameters['roleId'] = requestParameters.roleId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/v1/roles/users/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setUserRoleDto.map(SetUserRoleDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserRoleDtoFromJSON));
    }

    /**
     */
    async removeRoleUsers(requestParameters: RemoveRoleUsersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserRoleDto>> {
        const response = await this.removeRoleUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
