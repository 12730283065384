import {makeAutoObservable} from "mobx";
import {DefaultMultiSelectStore} from "../../../../../components/shared/DefaultMultiSelectStore";
import {
    ExportFormat,
    mappingExportFormatToString,
    mappingGuidesOrganizationToString,
    mappingGuidesPersonToString,
    OrganizationGuidesEnum,
    PersonGuidesEnum
} from "../services";
import {ExportTableStore} from "../ExportTableStore";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";

export class ExportDictionaryStore {
    readonly store: ExportTableStore;
    public createDate: DefaultValueStore<Date> = new DefaultValueStore<Date>(new Date())
    public updateDate: DefaultValueStore<Date> = new DefaultValueStore<Date>(null)
    readonly guidesPersonTypes: DefaultMultiSelectStore<PersonGuidesEnum>
        = new DefaultMultiSelectStore([] as PersonGuidesEnum[], Object.values(PersonGuidesEnum) as PersonGuidesEnum[])
    readonly guidesOrganizationTypes: DefaultMultiSelectStore<OrganizationGuidesEnum>
        = new DefaultMultiSelectStore([] as OrganizationGuidesEnum[], Object.values(OrganizationGuidesEnum) as OrganizationGuidesEnum[])
    readonly exportTypes: DefaultMultiSelectStore<ExportFormat>
        = new DefaultMultiSelectStore([] as ExportFormat[], Object.values(ExportFormat) as ExportFormat[])

    loading: boolean = false;
    error: boolean = false;
    downloaded: boolean = false;

    constructor(store: ExportTableStore) {
        this.store = store;
        this.guidesPersonTypes.valueExp = undefined;
        this.guidesOrganizationTypes.valueExp = undefined;
        this.exportTypes.valueExp = undefined;

        this.guidesPersonTypes.nameExp = (e) => mappingGuidesPersonToString(e)
        this.guidesOrganizationTypes.nameExp = (e) => mappingGuidesOrganizationToString(e)
        this.exportTypes.nameExp = (e) => mappingExportFormatToString(e)

        this.guidesPersonTypes.required = true;
        this.guidesOrganizationTypes.required = true;
        this.exportTypes.required = true;

        makeAutoObservable(this);
    }

    get valid(): boolean {
        return this.guidesPersonTypes.valid && this.guidesOrganizationTypes.valid && this.exportTypes.valid
    }

    get guidePerson() {
        return this.guidesPersonTypes.value.reduce((sum, current) => sum + current, 0)
    }

    get guideOrganization() {
        return this.guidesOrganizationTypes.value.reduce((sum, current) => sum + current, 0)
    }

    get dictionaryTypes() {
        if (this.guidePerson && this.guideOrganization)
            return this.guidePerson + this.guideOrganization
        if (this.guidePerson && !this.guideOrganization)
            return this.guidePerson
        return this.guideOrganization
    }

    resetFilters() {
        this.createDate.reset();
        this.updateDate.reset();
        this.guidesPersonTypes.reset();
        this.guidesOrganizationTypes.reset();
        this.exportTypes.reset();
    }

    async create(): Promise<void> {
        this.downloaded = false;
        this.loading = true;
        const filters = {
            exportTypes: this.exportTypes.value?.length ? this.exportTypes.value.reduce((sum, current) => sum + current, 0) : undefined,
            createDate: this.createDate.value,
            updateDate: this.updateDate.value,
            dictionaryTypes: this.dictionaryTypes ? this.dictionaryTypes : undefined,
        }
        // const api: ExportFilesApi = new ExportFilesApi(getApiConfiguration() as any);
        try {
            //const result = await api.getAnyDictionariesFile(filters)
            // this._blob = await result.blob;
            // this._filename = result.filename;
        } catch (e) {
        }
        await this.store.pull()
        this.loading = false;
    };
}
