import React from "react";
import {observer} from "mobx-react";
import {DefaultValueStore} from "../DefaultValueStore";
import {Checkbox, FormControlLabel, Switch, TextField} from "@mui/material";
import {InputProps as StandardInputProps} from "@mui/material/Input/Input";


interface Props {
    store: DefaultValueStore<boolean>;
    style?: React.CSSProperties;
    disabled?: boolean;
    title?: string;
    multiline?: boolean;
    rows?: number;
    InputProps?: Partial<StandardInputProps>;
    testid?: string;
    type?: 'checkbox' | 'switcher'
    indeterminate?: boolean
}

export const DefaultCheckbox = observer(({
                                             store,
                                             style,
                                             disabled,
                                             title,
                                             rows,
                                             indeterminate = false,
                                             multiline = false,
                                             testid,
                                             InputProps = {
                                                 sx: {
                                                     fieldset: {
                                                         height: 48,
                                                     },
                                                 }
                                             },
                                             type = 'switcher'
                                         }: Props) => {
    return (
        <>
            {
                type == 'checkbox' ?
                    <FormControlLabel
                        disabled={disabled}
                        data-testid={`${testid}-FormControlLabel`}
                        labelPlacement="start"
                        label={title}
                        style={{...style, pointerEvents: "none", marginLeft: 0}}
                        control={
                            <Checkbox data-testid={`${testid}-checkbox`} style={{pointerEvents: "auto"}}
                                      checked={!!store.value}
                                      indeterminate={indeterminate}
                                      onClick={(t: any) => store.value = !store.value}/>
                        }
                    /> :
                    <FormControlLabel
                        disabled={disabled}
                        labelPlacement="end"
                        label={title}
                        style={{...style, pointerEvents: "none"}}
                        control={
                            <Switch
                                style={{pointerEvents: "auto"}}
                                checked={!!store.value}
                                onClick={() => {
                                    store.value = !store.value
                                }}
                            />
                        }/>
            }
        </>
    );
});
