import {DefaultValueStore} from "../../../components/shared/DefaultValueStore";
import moment from "moment/moment";
import {DefaultSelectStore} from "../../../components/shared/DefaultSelectStore";
import {t} from "i18next";
import {makeAutoObservable} from "mobx";
import {CountsHistogram} from "../../../services/analytics/exchange";


type Period = 'd' | 'm' | 'y' | 'w' | 'c'
type Interval = '1h' | '1d' | '1w' | '1M'

export class AnalyticsDateFiltersStore {
    minDate = new Date(2020, 1, 1);

    readonly startDate: DefaultValueStore<Date> = new DefaultValueStore<Date>(moment(new Date()).startOf('month').toDate());
    readonly endDate: DefaultValueStore<Date> = new DefaultValueStore<Date>(this.maxDate, null);
    readonly periods: DefaultSelectStore<{ value: Period, label: string }>;

    periodsConst: any[];

    get maxDate(): Date {
        return moment(new Date()).toDate();
    }
    
    store: {updateState(): Promise<void>};

    constructor(store: {updateState(): Promise<void>}) {
        this.store = store;
        this.periodsConst = [
            {value: 'd', label: "usersPage.day"},
            {value: 'w', label: "usersPage.week"},
            {value: 'm', label: "usersPage.month"},
            {value: 'с', label: "common.custom"},
        ]
        this.periods = new DefaultSelectStore<{ value: Period; label: string }>(this.periodsConst[2],
            (e) => Promise.resolve(this.periodsConst), null,
            'value',
            (e) => t(e.label))
        this.periods.onChanged.push((e) => this.setPeriod(e?.value!))
        this.periods.onChanged.push(async (e) => await this.store.updateState())

        this.startDate.onChanged.push(async (e) => {
            this.periods.setValueWithoutEffects(this.periodsConst[3])
            await this.endDate.validate()
        })
        this.startDate.onChanged.push(async (e) => await this.store.updateState())

        this.endDate.onChanged.push(async (e) => {
            this.periods.setValueWithoutEffects(this.periodsConst[3])
            await this.startDate.validate()
        })
        this.endDate.onChanged.push(async (e) => await this.store.updateState())

        this.endDate.required = true;
        this.startDate.required = true;

        this.endDate.formattingValue = (e) => {
            if (e)
                return moment(e).endOf('date').toDate()

            else return e
        }

        this.startDate.setValidator((e) => {
            if (!moment(e).isValid())
                return t("common.wrongValue")
            if (e && this.endDate.value && e > this.endDate.value)
                return t("analytics.exchange.errors.startDate")
            if (e && e < this.minDate)
                return t("analytics.exchange.errors.min")
            return ""
        })
        this.endDate.setValidator((e) => {
            if (!moment(e).isValid())
                return t("common.wrongValue")
            if (e && this.startDate.value && e < this.startDate.value)
                return t("analytics.exchange.errors.endDate")
            if (e && e < this.minDate)
                return t("analytics.exchange.errors.min")
            return ""
        })

        makeAutoObservable(this);
    }


    setPeriod(period: Period) {
        if (period == "d") {
            this.startDate.setValueWithoutEffects(moment(new Date()).startOf('day').toDate())
        } else if (period == 'w') {
            this.startDate.setValueWithoutEffects(moment(new Date()).weekday(0).startOf('day').toDate())
        } else if (period == 'm') {
            this.startDate.setValueWithoutEffects(moment(new Date()).startOf('month').startOf('day').toDate())
        } else if (period == 'y') {
            this.startDate.setValueWithoutEffects(moment(new Date()).startOf('year').startOf('day').toDate())
        }
        if(period != "c")
            this.endDate.setValueWithoutEffects(this.maxDate)
        this.startDate.validate()
        this.endDate.validate()
    }


    get interval(): Interval {
        const different = moment(this.endDate.value).diff(moment(this.startDate.value), 'days')

        let interval: Interval;

        if (different == 0) {
            interval = "1h"
        } else if (different < 7) {
            interval = "1d"
        } else if (different < 30) {
            interval = "1d"
        } else if (different < 45) {
            interval = "1w"
        } else {
            interval = "1M"
        }
        return interval;
    }

    formattingData = (data: {key: Date, count: number}[]): number[] => {
        return this.categoriesValue.map(x => {
            const range = data.filter(p => moment(p.key) >= moment(x.startDate) && moment(p.key) < moment(x.endDate))
            return range?.length == 0 ? 0 : range.map(f => f.count ?? 0).reduce((f, ff) => f + ff)
        })
    }

    getPeriodsLabels(periodValues: { startDate: Date, endDate: Date }[]) {
        let values: string[] = [];
        if (this.interval == '1h') {
            values = periodValues.map(x => `${moment(x.startDate).format('HH:mm')}-${moment(x.endDate).format('HH:mm')}`)
        } else if (this.interval == '1d') {
            values = periodValues.map(x => moment(x.startDate).format('DD MMMM'))
        } else if (this.interval == '1w') {
            values = periodValues.map(x => `${moment(x.startDate).format('DD MMMM')}-${moment(x.endDate).format('DD MMMM')}`)
        } else if (this.interval == '1M') {
            values = periodValues.map(x => moment(x.startDate).format('MMMM'))
        }
        return values;
    }


    get categoriesLabel(): string[] {
        return this.getPeriodsLabels(this.categoriesValue)
    }

    get categoriesValue(): { startDate: Date, endDate: Date }[] {
        return this.getPeriodValues()
    }

    get valid(): boolean {
        return this.startDate.valid && this.endDate.valid;
    }

    getPeriodValues(): { startDate: Date, endDate: Date }[] {
        if (!this.valid)
            return [];

        const startDate = moment(this.startDate.value);
        const endDate = moment(this.endDate.value);

        let uot: 'hour' | 'month' | 'day' | 'week' = 'day';

        if (this.interval == '1h') {
            uot = 'hour';
        } else if (this.interval == "1d") {
            uot = 'day';
        } else if (this.interval == '1w') {
            uot = 'week'
        } else if (this.interval == '1M') {
            uot = 'month'
        }

        const periods: { startDate: Date, endDate: Date }[] = [];

        if (startDate < endDate) {
            let date = startDate;
            while (date < endDate) {
                const sDate = date.toDate();
                date.add(1, uot);
                if (uot == "month") {
                    date.set("date", 1)
                }
                const eDate = date > endDate ? endDate : date;

                periods.push({startDate: sDate, endDate: eDate.toDate()});
            }
        }

        return periods
    }
}
