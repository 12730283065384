/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    EntryTypeDto,
    EntryTypeDtoFromJSON,
    EntryTypeDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetEntryTypeDto,
    SetEntryTypeDtoFromJSON,
    SetEntryTypeDtoToJSON,
} from '../models';

export interface CreateEntryTypeRequest {
    setEntryTypeDto: SetEntryTypeDto;
}

export interface DeleteEntryTypeRequest {
    id: string;
}

export interface GetEntryTypeByIdRequest {
    id: string;
}

export interface GetEntryTypeByNameRequest {
    name: string;
}

export interface GetEntryTypesRequest {
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetEntryTypesCountRequest {
    search?: string;
}

export interface UpdateEntryTypeRequest {
    id: string;
    setEntryTypeDto: SetEntryTypeDto;
}

/**
 * 
 */
export class EntryTypesApi extends runtime.BaseAPI {

    /**
     */
    async createEntryTypeRaw(requestParameters: CreateEntryTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EntryTypeDto>> {
        if (requestParameters.setEntryTypeDto === null || requestParameters.setEntryTypeDto === undefined) {
            throw new runtime.RequiredError('setEntryTypeDto','Required parameter requestParameters.setEntryTypeDto was null or undefined when calling createEntryType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/addresses/entryTypes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetEntryTypeDtoToJSON(requestParameters.setEntryTypeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntryTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async createEntryType(requestParameters: CreateEntryTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EntryTypeDto> {
        const response = await this.createEntryTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteEntryTypeRaw(requestParameters: DeleteEntryTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EntryTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteEntryType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/entryTypes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntryTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteEntryType(requestParameters: DeleteEntryTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EntryTypeDto> {
        const response = await this.deleteEntryTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEntryTypeByIdRaw(requestParameters: GetEntryTypeByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EntryTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getEntryTypeById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/entryTypes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntryTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async getEntryTypeById(requestParameters: GetEntryTypeByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EntryTypeDto> {
        const response = await this.getEntryTypeByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEntryTypeByNameRaw(requestParameters: GetEntryTypeByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EntryTypeDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getEntryTypeByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/entryTypes/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntryTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async getEntryTypeByName(requestParameters: GetEntryTypeByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EntryTypeDto> {
        const response = await this.getEntryTypeByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEntryTypesRaw(requestParameters: GetEntryTypesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<EntryTypeDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/entryTypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EntryTypeDtoFromJSON));
    }

    /**
     */
    async getEntryTypes(requestParameters: GetEntryTypesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<EntryTypeDto>> {
        const response = await this.getEntryTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEntryTypesCountRaw(requestParameters: GetEntryTypesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/entryTypes/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getEntryTypesCount(requestParameters: GetEntryTypesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getEntryTypesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateEntryTypeRaw(requestParameters: UpdateEntryTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EntryTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateEntryType.');
        }

        if (requestParameters.setEntryTypeDto === null || requestParameters.setEntryTypeDto === undefined) {
            throw new runtime.RequiredError('setEntryTypeDto','Required parameter requestParameters.setEntryTypeDto was null or undefined when calling updateEntryType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/addresses/entryTypes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetEntryTypeDtoToJSON(requestParameters.setEntryTypeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntryTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateEntryType(requestParameters: UpdateEntryTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EntryTypeDto> {
        const response = await this.updateEntryTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
