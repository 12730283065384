import 'simplebar-react/dist/simplebar.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {StoreProvider} from './stores';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import {SnackbarUtilsConfigurator} from "./components/snackbar/SnackbarUtils";
import { SnackbarCloseButton } from './components/snackbar/SnackbarCloseButton';
import 'moment/locale/ru';
import {HelmetProvider} from "react-helmet-async";
var moment = require('moment');
moment.locale('ru');

// const {themeColorStore} = useRootStore();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <HelmetProvider>
        <SnackbarProvider maxSnack={3} anchorOrigin={{horizontal: 'right', vertical: 'top'}} autoHideDuration={6000} action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />} hideIconVariant>
            <SnackbarUtilsConfigurator/>
            <React.StrictMode>
                <BrowserRouter>
                    <StoreProvider>
                        <App/>
                    </StoreProvider>
                </BrowserRouter>
            </React.StrictMode>
        </SnackbarProvider>
    </HelmetProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
