import {makeAutoObservable} from 'mobx';
import {getApiConfiguration, IForm} from '../../../../core';
import {
    FormPropertiesApi,
    FormPropertyDto,
    OrganizationDto,
    OrganizationPhoneCategoriesApi,
    OrganizationPhoneStatusesApi,
    OrganizationPhoneTypesApi,
    OrganizationsApi,
    OrganizationStatusDto,
    OrganizationStatusesApi,
    PhoneCategoryDto,
    PhoneStatusDto,
    TypeOrgDto,
    TypeOrgsApi,
} from '../../../../services/management';
import {DefaultValueStore} from "../../../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from "../../../../components/shared/DefaultSelectStore";
import {OrganizationCreatorStore} from "./OrganizationCreatorStore";
import i18n from "i18next";
import {searchStatusWorkOrgHandler} from '../../../utils';
import {JurisdictionLocalSetting} from "../../../authorization/AuthorizationStore";
import {DefaultFormStore} from "../../../../components/shared/DefaultFormStore";

export class FormPropertyCreatorStore implements IForm {
    readonly _store: OrganizationCreatorStore;

    form: DefaultFormStore<{
        isParent: DefaultValueStore<boolean>,
        name: DefaultValueStore<string>,
        itn: DefaultValueStore<string>,
        type: DefaultSelectStore<TypeOrgDto>,
        status: DefaultSelectStore<OrganizationStatusDto>,
        formProperty: DefaultSelectStore<FormPropertyDto>
        phoneNumber: DefaultValueStore<string>,
        phoneType: DefaultSelectStore<PhoneStatusDto>,
        phoneStatus: DefaultSelectStore<PhoneStatusDto>,
        phoneCategory: DefaultSelectStore<PhoneCategoryDto>,
        additionalNumber: DefaultValueStore<string>,
        parentOrganization: DefaultSelectStore<OrganizationDto>
    }> = new DefaultFormStore({
        isParent: new DefaultValueStore<boolean>(false),
        name: new DefaultValueStore<string>(""),
        itn: new DefaultValueStore<string>("", (value) =>
            new RegExp(this.jurisdictionLocalSetting?.itnRegex!).test(value ?? "") ? "" :
                i18n.t('errors.itn').replace("{length}", this.jurisdictionLocalSetting?.intLength!)),
        type: new DefaultSelectStore<TypeOrgDto>(null,
            filters => new TypeOrgsApi(getApiConfiguration()).getTypeOrgs({
                ...filters, ...(this.form.fields.parentOrganization.value?.id ? {
                    parentId: this.form.fields.parentOrganization.value?.typeOrgId
                } : {}),
                showOnlyActive: true
            })),
        status: new DefaultSelectStore<OrganizationStatusDto>(null,
            async (filters) => {
                let statuses = await new OrganizationStatusesApi(getApiConfiguration()).getOrganizationStatuses({
                    ...filters,
                    orderBy: 'name_rus',
                    order: 'ascending'
                })
                return searchStatusWorkOrgHandler(statuses, filters)
            }),
        formProperty: new DefaultSelectStore<FormPropertyDto>(null,
            (x) => new FormPropertiesApi(getApiConfiguration()).getFormProperties(x)),
        phoneNumber: new DefaultValueStore<string>(""),
        phoneType: new DefaultSelectStore<PhoneStatusDto>(
            null, (x) => new OrganizationPhoneTypesApi(getApiConfiguration()).getOrganizationPhoneTypes(x)),
        phoneStatus: new DefaultSelectStore<PhoneStatusDto>(
            null, (x) => new OrganizationPhoneStatusesApi(getApiConfiguration()).getOrganizationPhoneStatuses(x)),
        phoneCategory: new DefaultSelectStore<PhoneCategoryDto>(
            null, (x) => new OrganizationPhoneCategoriesApi(getApiConfiguration()).getOrganizationPhoneCategories(x)),
        additionalNumber: new DefaultValueStore<string>("", (value) =>
            value?.length! > 50 ? i18n.t('errors.lengthMax').replace("lengthMax", "50") : ""),
        parentOrganization: new DefaultSelectStore(null,
            (filters) => new OrganizationsApi(getApiConfiguration()).getOrganizations(filters))
    })

    constructor(store: OrganizationCreatorStore) {
        this._store = store;

        this.form.fields.name.required = true;
        this.form.fields.type.required = true;
        this.form.fields.formProperty.required = true;
        this.form.fields.parentOrganization.isSearchById = true;
        this.form.fields.parentOrganization.required = true;
        this.form.fields.isParent.onChanged.push((e) => {
            this.form.fields.type.value = null;
            this.form.fields.parentOrganization.value = null;
            this.form.fields.parentOrganization.required = !e;
        })

        this.form.fields.phoneNumber = new DefaultValueStore<string>("", (value) => {
            if (!value)
                return "";
            return new RegExp(this.jurisdictionLocalSetting?.phoneNumberRegex!)
                .test(value ?? "") ? "" : i18n.t('dcrPage.errors.phoneFormat').replace("{length}", this.jurisdictionLocalSetting?.numberLength!)
        })

        this.form.fields.phoneNumber.onChanged.push((e) => {
            this.form.fields.phoneType.required = !!e;
            this.form.fields.phoneStatus.required = !!e;
            this.form.fields.phoneCategory.required = !!e;
            if (!e) {
                this.form.fields.phoneStatus.reset()
                this.form.fields.phoneCategory.reset()
                this.form.fields.phoneType.reset()
                this.form.fields.additionalNumber.reset()
                this.form.fields.phoneStatus.validate()
                this.form.fields.phoneCategory.validate()
                this.form.fields.phoneType.validate()
                this.form.fields.additionalNumber.validate()
            }
        })

        makeAutoObservable(this);
    }

    get valid(): boolean {
        return this.form.valid;
    }

    reset(): void {
        this.form.reset();
    }

    validate(): void {
        this.form.validate()
    }

    get jurisdictionLocalSetting(): JurisdictionLocalSetting | undefined {
        return this._store._store.store.store.authorizationStore?.jurisdictionsSettings
    }

    async submit(): Promise<void> {

    }
}
