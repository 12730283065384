/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddressUniqueDto,
    AddressUniqueDtoFromJSON,
    AddressUniqueDtoToJSON,
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetAddressUniqueDto,
    SetAddressUniqueDtoFromJSON,
    SetAddressUniqueDtoToJSON,
} from '../models';

export interface CreateAddressUniqueRequest {
    setAddressUniqueDto: SetAddressUniqueDto;
    isCustomCoordinates?: boolean;
}

export interface DeleteAddressUniqueRequest {
    id: string;
}

export interface GetAddressUniqueByIdRequest {
    id: string;
}

export interface GetAddressUniquesRequest {
    search?: string;
    localityId?: string;
    zipId?: string;
    okrugId?: string;
    districtId?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetAddressUniquesCountRequest {
    search?: string;
    localityId?: string;
    zipId?: string;
    okrugId?: string;
    districtId?: string;
}

export interface SetAddressUniqueRequest {
    setAddressUniqueDto: SetAddressUniqueDto;
    isCustomCoordinates?: boolean;
}

export interface UpdateAddressUniqueRequest {
    id: string;
    setAddressUniqueDto: SetAddressUniqueDto;
    isCustomCoordinates?: boolean;
}

/**
 * 
 */
export class AddressUniquesApi extends runtime.BaseAPI {

    /**
     */
    async createAddressUniqueRaw(requestParameters: CreateAddressUniqueRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AddressUniqueDto>> {
        if (requestParameters.setAddressUniqueDto === null || requestParameters.setAddressUniqueDto === undefined) {
            throw new runtime.RequiredError('setAddressUniqueDto','Required parameter requestParameters.setAddressUniqueDto was null or undefined when calling createAddressUnique.');
        }

        const queryParameters: any = {};

        if (requestParameters.isCustomCoordinates !== undefined) {
            queryParameters['isCustomCoordinates'] = requestParameters.isCustomCoordinates;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/addresses/uniques`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetAddressUniqueDtoToJSON(requestParameters.setAddressUniqueDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressUniqueDtoFromJSON(jsonValue));
    }

    /**
     */
    async createAddressUnique(requestParameters: CreateAddressUniqueRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AddressUniqueDto> {
        const response = await this.createAddressUniqueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAddressUniqueRaw(requestParameters: DeleteAddressUniqueRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AddressUniqueDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAddressUnique.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/uniques/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressUniqueDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteAddressUnique(requestParameters: DeleteAddressUniqueRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AddressUniqueDto> {
        const response = await this.deleteAddressUniqueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAddressUniqueByIdRaw(requestParameters: GetAddressUniqueByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AddressUniqueDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAddressUniqueById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/uniques/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressUniqueDtoFromJSON(jsonValue));
    }

    /**
     */
    async getAddressUniqueById(requestParameters: GetAddressUniqueByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AddressUniqueDto> {
        const response = await this.getAddressUniqueByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAddressUniquesRaw(requestParameters: GetAddressUniquesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<AddressUniqueDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.localityId !== undefined) {
            queryParameters['LocalityId'] = requestParameters.localityId;
        }

        if (requestParameters.zipId !== undefined) {
            queryParameters['ZipId'] = requestParameters.zipId;
        }

        if (requestParameters.okrugId !== undefined) {
            queryParameters['OkrugId'] = requestParameters.okrugId;
        }

        if (requestParameters.districtId !== undefined) {
            queryParameters['DistrictId'] = requestParameters.districtId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/uniques`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AddressUniqueDtoFromJSON));
    }

    /**
     */
    async getAddressUniques(requestParameters: GetAddressUniquesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<AddressUniqueDto>> {
        const response = await this.getAddressUniquesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAddressUniquesCountRaw(requestParameters: GetAddressUniquesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.localityId !== undefined) {
            queryParameters['LocalityId'] = requestParameters.localityId;
        }

        if (requestParameters.zipId !== undefined) {
            queryParameters['ZipId'] = requestParameters.zipId;
        }

        if (requestParameters.okrugId !== undefined) {
            queryParameters['OkrugId'] = requestParameters.okrugId;
        }

        if (requestParameters.districtId !== undefined) {
            queryParameters['DistrictId'] = requestParameters.districtId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/uniques/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getAddressUniquesCount(requestParameters: GetAddressUniquesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getAddressUniquesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setAddressUniqueRaw(requestParameters: SetAddressUniqueRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<AddressUniqueDto>>> {
        if (requestParameters.setAddressUniqueDto === null || requestParameters.setAddressUniqueDto === undefined) {
            throw new runtime.RequiredError('setAddressUniqueDto','Required parameter requestParameters.setAddressUniqueDto was null or undefined when calling setAddressUnique.');
        }

        const queryParameters: any = {};

        if (requestParameters.isCustomCoordinates !== undefined) {
            queryParameters['isCustomCoordinates'] = requestParameters.isCustomCoordinates;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/addresses/uniques/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetAddressUniqueDtoToJSON(requestParameters.setAddressUniqueDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AddressUniqueDtoFromJSON));
    }

    /**
     */
    async setAddressUnique(requestParameters: SetAddressUniqueRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<AddressUniqueDto>> {
        const response = await this.setAddressUniqueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateAddressUniqueRaw(requestParameters: UpdateAddressUniqueRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AddressUniqueDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAddressUnique.');
        }

        if (requestParameters.setAddressUniqueDto === null || requestParameters.setAddressUniqueDto === undefined) {
            throw new runtime.RequiredError('setAddressUniqueDto','Required parameter requestParameters.setAddressUniqueDto was null or undefined when calling updateAddressUnique.');
        }

        const queryParameters: any = {};

        if (requestParameters.isCustomCoordinates !== undefined) {
            queryParameters['isCustomCoordinates'] = requestParameters.isCustomCoordinates;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/addresses/uniques/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetAddressUniqueDtoToJSON(requestParameters.setAddressUniqueDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressUniqueDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateAddressUnique(requestParameters: UpdateAddressUniqueRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AddressUniqueDto> {
        const response = await this.updateAddressUniqueRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
