/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetOrganizationPhoneDto
 */
export interface SetOrganizationPhoneDto {
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationPhoneDto
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationPhoneDto
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationPhoneDto
     */
    phoneStatusId: string;
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationPhoneDto
     */
    phoneCategoryId: string;
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationPhoneDto
     */
    phoneTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationPhoneDto
     */
    additionalNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationPhoneDto
     */
    description?: string | null;
}

export function SetOrganizationPhoneDtoFromJSON(json: any): SetOrganizationPhoneDto {
    return SetOrganizationPhoneDtoFromJSONTyped(json, false);
}

export function SetOrganizationPhoneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetOrganizationPhoneDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phoneNumber': json['phoneNumber'],
        'organizationId': json['organizationId'],
        'phoneStatusId': json['phoneStatusId'],
        'phoneCategoryId': json['phoneCategoryId'],
        'phoneTypeId': json['phoneTypeId'],
        'additionalNumber': !exists(json, 'additionalNumber') ? undefined : json['additionalNumber'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function SetOrganizationPhoneDtoToJSON(value?: SetOrganizationPhoneDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phoneNumber': value.phoneNumber,
        'organizationId': value.organizationId,
        'phoneStatusId': value.phoneStatusId,
        'phoneCategoryId': value.phoneCategoryId,
        'phoneTypeId': value.phoneTypeId,
        'additionalNumber': value.additionalNumber,
        'description': value.description,
    };
}

