/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NameDto,
    NameDtoFromJSON,
    NameDtoFromJSONTyped,
    NameDtoToJSON,
} from './NameDto';
import {
    PersonDto,
    PersonDtoFromJSON,
    PersonDtoFromJSONTyped,
    PersonDtoToJSON,
} from './PersonDto';

/**
 * 
 * @export
 * @interface PersonSpecialityDto
 */
export interface PersonSpecialityDto {
    /**
     * 
     * @type {string}
     * @memberof PersonSpecialityDto
     */
    id: string;
    /**
     * 
     * @type {PersonDto}
     * @memberof PersonSpecialityDto
     */
    person: PersonDto;
    /**
     * 
     * @type {NameDto}
     * @memberof PersonSpecialityDto
     */
    speciality: NameDto;
}

export function PersonSpecialityDtoFromJSON(json: any): PersonSpecialityDto {
    return PersonSpecialityDtoFromJSONTyped(json, false);
}

export function PersonSpecialityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonSpecialityDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'person': PersonDtoFromJSON(json['person']),
        'speciality': NameDtoFromJSON(json['speciality']),
    };
}

export function PersonSpecialityDtoToJSON(value?: PersonSpecialityDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'person': PersonDtoToJSON(value.person),
        'speciality': NameDtoToJSON(value.speciality),
    };
}

