/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OCMObjectState,
    OCMObjectStateFromJSON,
    OCMObjectStateToJSON,
    PhoneCategoryDto,
    PhoneCategoryDtoFromJSON,
    PhoneCategoryDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetPhoneCategoryDto,
    SetPhoneCategoryDtoFromJSON,
    SetPhoneCategoryDtoToJSON,
} from '../models';

export interface CreatePersonPhoneCategoryRequest {
    setPhoneCategoryDto: SetPhoneCategoryDto;
}

export interface DeletePersonPhoneCategoryRequest {
    id: string;
}

export interface GetPersonPhoneCategoriesRequest {
    search?: string;
    orderBy?: string;
    searchByExternalId?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface GetPersonPhoneCategoriesCountRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface GetPersonPhoneCategoryByIdRequest {
    id: string;
}

export interface GetPersonPhoneCategoryByNameRequest {
    name: string;
}

export interface UpdatePersonPhoneCategoryRequest {
    id: string;
    setPhoneCategoryDto: SetPhoneCategoryDto;
}

/**
 * 
 */
export class PersonPhoneCategoriesApi extends runtime.BaseAPI {

    /**
     */
    async createPersonPhoneCategoryRaw(requestParameters: CreatePersonPhoneCategoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneCategoryDto>> {
        if (requestParameters.setPhoneCategoryDto === null || requestParameters.setPhoneCategoryDto === undefined) {
            throw new runtime.RequiredError('setPhoneCategoryDto','Required parameter requestParameters.setPhoneCategoryDto was null or undefined when calling createPersonPhoneCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/phones/categories/person`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPhoneCategoryDtoToJSON(requestParameters.setPhoneCategoryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async createPersonPhoneCategory(requestParameters: CreatePersonPhoneCategoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneCategoryDto> {
        const response = await this.createPersonPhoneCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePersonPhoneCategoryRaw(requestParameters: DeletePersonPhoneCategoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneCategoryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePersonPhoneCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/categories/person/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async deletePersonPhoneCategory(requestParameters: DeletePersonPhoneCategoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneCategoryDto> {
        const response = await this.deletePersonPhoneCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonPhoneCategoriesRaw(requestParameters: GetPersonPhoneCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PhoneCategoryDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/categories/person`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PhoneCategoryDtoFromJSON));
    }

    /**
     */
    async getPersonPhoneCategories(requestParameters: GetPersonPhoneCategoriesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PhoneCategoryDto>> {
        const response = await this.getPersonPhoneCategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonPhoneCategoriesCountRaw(requestParameters: GetPersonPhoneCategoriesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/categories/person/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonPhoneCategoriesCount(requestParameters: GetPersonPhoneCategoriesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPersonPhoneCategoriesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonPhoneCategoryByIdRaw(requestParameters: GetPersonPhoneCategoryByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneCategoryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPersonPhoneCategoryById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/categories/person/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonPhoneCategoryById(requestParameters: GetPersonPhoneCategoryByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneCategoryDto> {
        const response = await this.getPersonPhoneCategoryByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonPhoneCategoryByNameRaw(requestParameters: GetPersonPhoneCategoryByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneCategoryDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getPersonPhoneCategoryByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/categories/person/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonPhoneCategoryByName(requestParameters: GetPersonPhoneCategoryByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneCategoryDto> {
        const response = await this.getPersonPhoneCategoryByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePersonPhoneCategoryRaw(requestParameters: UpdatePersonPhoneCategoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneCategoryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePersonPhoneCategory.');
        }

        if (requestParameters.setPhoneCategoryDto === null || requestParameters.setPhoneCategoryDto === undefined) {
            throw new runtime.RequiredError('setPhoneCategoryDto','Required parameter requestParameters.setPhoneCategoryDto was null or undefined when calling updatePersonPhoneCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/phones/categories/person/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetPhoneCategoryDtoToJSON(requestParameters.setPhoneCategoryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async updatePersonPhoneCategory(requestParameters: UpdatePersonPhoneCategoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneCategoryDto> {
        const response = await this.updatePersonPhoneCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
