import {getObject, setObject} from "../../../../core";
import {ManagementStore} from "../../ManagementStore";
import {ExportTableStore} from "./ExportTableStore";
import {DynamicalComponentStore} from "../../../utils";
import {ExportAddressesStore} from "./tabs/ExportAddressesStore";

interface ExportStoreLocalFilters {
    tabIndex: number;
}

export class ExportTabsStore extends DynamicalComponentStore {
    readonly store: ManagementStore;
    readonly exportPersonTableStore = new ExportTableStore(this, "Persons");
    readonly exportOrganizationTableStore = new ExportTableStore(this, "Organizations");
    readonly exportDictionaryTableStore = new ExportTableStore(this, "Dictionaries");
    readonly exportAddressesStore = new ExportAddressesStore(this);

    constructor(store: ManagementStore) {
        super()
        this.store = store;
        const filters = getObject<ExportStoreLocalFilters>('export-store');
        if (filters) {
            this._tabIndex = filters.tabIndex;
        }
    }

    async updateState() {
        this.onFiltersSave();
        if (this._tabIndex === 0) {
            await this.exportPersonTableStore.pull()
        } else if (this._tabIndex === 1) {
            await this.exportOrganizationTableStore.pull()
        } else if (this._tabIndex === 2) {
            await this.exportDictionaryTableStore.pull()
        }
    }

    onFiltersSave() {
        setObject<ExportStoreLocalFilters>('export-store', {
            tabIndex: this._tabIndex,
        })
    }
}
