import { Box, Card } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { AuthorizePermission, FormRoot, ParamsButtonGroup } from "../../../../../../components";
import { DefaultMultiSelect } from "../../../../../../components/shared/components/DefaultMultiSelect";
import { AllPermissions } from "../../../../../../core/const";
import { StoreProps, StyleProps } from "../../../../../../stores";
import { PeopleItemStore } from "../../../../../../stores/management/users/tabs/Groups/tabs/PeopleTableTab/PeopleItemStore";

export const PeopleDetails = observer(({ store, style }: StoreProps<PeopleItemStore> & StyleProps) => {
    const { t } = useTranslation();

    return (
        <Card sx={{ backgroundColor: 'background.default', m: 2, ml: 3, px: 2, py: 1, mr: 0 }}>
            <FormRoot style={style}>

                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <DefaultMultiSelect store={store.form.fields.clients}
                        title={t('usersPage.dcrGroup.clients')}
                        nameExp={'name'}
                        style={{ width: '32%' }}
                        testid="peopleDetails-clients"
                    />
                    <DefaultMultiSelect store={store.form.fields.entityTypes}
                        title={t('usersPage.dcrGroup.entities')}
                        nameExp={store.form.fields.entityTypes.nameExp}
                        valueExp={null}
                        style={{ width: '32%' }}
                        testid="peopleDetails-entityTypes"
                    />

                    <DefaultMultiSelect store={store.form.fields.dcrStatuses}
                        title={t('usersPage.dcrGroup.typeRequest')}
                        nameExp={store.form.fields.dcrStatuses.nameExp}
                        valueExp={null}
                        style={{ width: '32%' }}
                        testid="peopleDetails-dcrStatuses"
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <AuthorizePermission permissions={AllPermissions.user.update}>
                        <ParamsButtonGroup
                            testid="peopleDetails"
                            onCancel={() => store.form.reset()}
                            onSave={async () => store.form.submit()}
                            isCancelBtnDisabled={false}
                            isSaveBtnDisabled={store.form.equals}
                        />
                    </AuthorizePermission>
                </Box>

            </FormRoot>
        </Card>
    )
});
