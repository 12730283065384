/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {EmailDto, EmailDtoFromJSON, EmailDtoToJSON,} from './EmailDto';
import {NameDto, NameDtoFromJSON, NameDtoToJSON,} from './NameDto';
import {OrganizationDto, OrganizationDtoFromJSON, OrganizationDtoToJSON,} from './OrganizationDto';

/**
 * 
 * @export
 * @interface OrganizationEmailDto
 */
export interface OrganizationEmailDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationEmailDto
     */
    id: string;
    /**
     * 
     * @type {EmailDto}
     * @memberof OrganizationEmailDto
     */
    email: EmailDto;
    /**
     * 
     * @type {OrganizationDto}
     * @memberof OrganizationEmailDto
     */
    organization: OrganizationDto;
    /**
     * 
     * @type {NameDto}
     * @memberof OrganizationEmailDto
     */
    emailStatus: NameDto;
    /**
     * 
     * @type {NameDto}
     * @memberof OrganizationEmailDto
     */
    emailType: NameDto;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationEmailDto
     */
    createdAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationEmailDto
     */
    modifiedAt?: Date | null;
    /**
     *
     * @type {string}
     * @memberof OrganizationEmailDto
     */
    description?: string | null;
}

export function OrganizationEmailDtoFromJSON(json: any): OrganizationEmailDto {
    return OrganizationEmailDtoFromJSONTyped(json, false);
}

export function OrganizationEmailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationEmailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': EmailDtoFromJSON(json['email']),
        'organization': OrganizationDtoFromJSON(json['organization']),
        'emailStatus': NameDtoFromJSON(json['emailStatus']),
        'emailType': NameDtoFromJSON(json['emailType']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (json['modifiedAt'] === null ? null : new Date(json['modifiedAt'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function OrganizationEmailDtoToJSON(value?: OrganizationEmailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': EmailDtoToJSON(value.email),
        'organization': OrganizationDtoToJSON(value.organization),
        'emailStatus': NameDtoToJSON(value.emailStatus),
        'emailType': NameDtoToJSON(value.emailType),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt === null ? null : value.createdAt.toISOString()),
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt === null ? null : value.modifiedAt.toISOString()),
        'description': value.description,
    };
}

