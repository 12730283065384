/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    EmailStatusDto,
    EmailStatusDtoFromJSON,
    EmailStatusDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OCMObjectState,
    OCMObjectStateFromJSON,
    OCMObjectStateToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetEmailStatusDto,
    SetEmailStatusDtoFromJSON,
    SetEmailStatusDtoToJSON,
} from '../models';

export interface CreatePersonEmailStatusRequest {
    setEmailStatusDto: SetEmailStatusDto;
}

export interface DeletePersonEmailStatusRequest {
    id: string;
}

export interface GetPersonEmailStatusByIdRequest {
    id: string;
}

export interface GetPersonEmailStatusByNameRequest {
    name: string;
}

export interface GetPersonEmailStatusesRequest {
    search?: string;
    orderBy?: string;
    searchByExternalId?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface GetPersonEmailStatusesCountRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface UpdatePersonEmailStatusRequest {
    id: string;
    setEmailStatusDto: SetEmailStatusDto;
}

/**
 * 
 */
export class PersonEmailStatusesApi extends runtime.BaseAPI {

    /**
     */
    async createPersonEmailStatusRaw(requestParameters: CreatePersonEmailStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailStatusDto>> {
        if (requestParameters.setEmailStatusDto === null || requestParameters.setEmailStatusDto === undefined) {
            throw new runtime.RequiredError('setEmailStatusDto','Required parameter requestParameters.setEmailStatusDto was null or undefined when calling createPersonEmailStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/emails/statuses/person`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetEmailStatusDtoToJSON(requestParameters.setEmailStatusDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async createPersonEmailStatus(requestParameters: CreatePersonEmailStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailStatusDto> {
        const response = await this.createPersonEmailStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePersonEmailStatusRaw(requestParameters: DeletePersonEmailStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePersonEmailStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/statuses/person/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async deletePersonEmailStatus(requestParameters: DeletePersonEmailStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailStatusDto> {
        const response = await this.deletePersonEmailStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonEmailStatusByIdRaw(requestParameters: GetPersonEmailStatusByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPersonEmailStatusById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/statuses/person/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonEmailStatusById(requestParameters: GetPersonEmailStatusByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailStatusDto> {
        const response = await this.getPersonEmailStatusByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonEmailStatusByNameRaw(requestParameters: GetPersonEmailStatusByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailStatusDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getPersonEmailStatusByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/statuses/person/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonEmailStatusByName(requestParameters: GetPersonEmailStatusByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailStatusDto> {
        const response = await this.getPersonEmailStatusByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonEmailStatusesRaw(requestParameters: GetPersonEmailStatusesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<EmailStatusDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/statuses/person`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmailStatusDtoFromJSON));
    }

    /**
     */
    async getPersonEmailStatuses(requestParameters: GetPersonEmailStatusesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<EmailStatusDto>> {
        const response = await this.getPersonEmailStatusesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonEmailStatusesCountRaw(requestParameters: GetPersonEmailStatusesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/statuses/person/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonEmailStatusesCount(requestParameters: GetPersonEmailStatusesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPersonEmailStatusesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePersonEmailStatusRaw(requestParameters: UpdatePersonEmailStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePersonEmailStatus.');
        }

        if (requestParameters.setEmailStatusDto === null || requestParameters.setEmailStatusDto === undefined) {
            throw new runtime.RequiredError('setEmailStatusDto','Required parameter requestParameters.setEmailStatusDto was null or undefined when calling updatePersonEmailStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/emails/statuses/person/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetEmailStatusDtoToJSON(requestParameters.setEmailStatusDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async updatePersonEmailStatus(requestParameters: UpdatePersonEmailStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailStatusDto> {
        const response = await this.updatePersonEmailStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
