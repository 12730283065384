/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ClientDto,
    ClientDtoFromJSON,
    ClientDtoToJSON,
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetClientDto,
    SetClientDtoFromJSON,
    SetClientDtoToJSON,
} from '../models';

export interface CreateClientRequest {
    setClientDto: SetClientDto;
}

export interface DeleteClientRequest {
    id: string;
}

export interface GetClientByIdRequest {
    id: string;
}

export interface GetClientByNameRequest {
    name: string;
}

export interface GetClientsRequest {
    byDate?: Date;
    startDate?: Date;
    endDate?: Date;
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetClientsCountRequest {
    byDate?: Date;
    startDate?: Date;
    endDate?: Date;
    search?: string;
}

export interface UpdateClientRequest {
    id: string;
    setClientDto: SetClientDto;
}

/**
 * 
 */
export class ApplicationClientsApi extends runtime.BaseAPI {

    /**
     */
    async createClientRaw(requestParameters: CreateClientRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ClientDto>> {
        if (requestParameters.setClientDto === null || requestParameters.setClientDto === undefined) {
            throw new runtime.RequiredError('setClientDto','Required parameter requestParameters.setClientDto was null or undefined when calling createClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/application/clients`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetClientDtoToJSON(requestParameters.setClientDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientDtoFromJSON(jsonValue));
    }

    /**
     */
    async createClient(requestParameters: CreateClientRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ClientDto> {
        const response = await this.createClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteClientRaw(requestParameters: DeleteClientRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ClientDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/application/clients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteClient(requestParameters: DeleteClientRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ClientDto> {
        const response = await this.deleteClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClientByIdRaw(requestParameters: GetClientByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ClientDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getClientById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/application/clients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientDtoFromJSON(jsonValue));
    }

    /**
     */
    async getClientById(requestParameters: GetClientByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ClientDto> {
        const response = await this.getClientByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClientByNameRaw(requestParameters: GetClientByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ClientDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getClientByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/application/clients/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientDtoFromJSON(jsonValue));
    }

    /**
     */
    async getClientByName(requestParameters: GetClientByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ClientDto> {
        const response = await this.getClientByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClientsRaw(requestParameters: GetClientsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ClientDto>>> {
        const queryParameters: any = {};

        if (requestParameters.byDate !== undefined) {
            queryParameters['byDate'] = (requestParameters.byDate as any).toISOString();
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/application/clients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientDtoFromJSON));
    }

    /**
     */
    async getClients(requestParameters: GetClientsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ClientDto>> {
        const response = await this.getClientsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClientsCountRaw(requestParameters: GetClientsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.byDate !== undefined) {
            queryParameters['byDate'] = (requestParameters.byDate as any).toISOString();
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/application/clients/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getClientsCount(requestParameters: GetClientsCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getClientsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateClientRaw(requestParameters: UpdateClientRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ClientDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateClient.');
        }

        if (requestParameters.setClientDto === null || requestParameters.setClientDto === undefined) {
            throw new runtime.RequiredError('setClientDto','Required parameter requestParameters.setClientDto was null or undefined when calling updateClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/application/clients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetClientDtoToJSON(requestParameters.setClientDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateClient(requestParameters: UpdateClientRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ClientDto> {
        const response = await this.updateClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
