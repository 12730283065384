import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { ChangeData as ChangeDataIcon } from '../icons/change-data'
import { List as ListIcon } from '../icons/list'
import { CloudUpload as CloudUploadIcon } from '../icons/cloud-upload';
import { DeleteIcon } from "../icons/deleteIcon";
import React from "react";
import { useTranslation } from "react-i18next";

export interface IconButtonProps {
    onClick: () => void,
    testid: string;
    open?: boolean,
    style?: React.CSSProperties,
}

export const EditIconButton = ({ onClick, testid, open, style }: IconButtonProps) => {
    const { t } = useTranslation();
    return (
        <IconButton
            onClick={onClick}
            style={{ marginTop: 4, paddingRight: 2, ...style }}
            data-testid={`${testid}-editIconButton`}
        >
            <Tooltip title={t('common.edit')}>
                <ChangeDataIcon sx={{ "& path": { stroke: !!open ? '#6366F1' : null }, "&:hover path": { stroke: '#6366F1' } }} />
            </Tooltip>
        </IconButton>
    );
};

export const DeleteIconButton = ({ onClick, testid, style }: IconButtonProps) => {
    const { t } = useTranslation();
    return (
        <IconButton
            onClick={onClick}
            style={{ ...style }}
            data-testid={`${testid}-deleteIconButton`}
        >
            <Tooltip title={t('common.delete')}>
                <DeleteIcon sx={{ "&:hover path": { stroke: '#F04438' } }} />
            </Tooltip>
        </IconButton>
    );
};

export const UpdateTickIconButton = ({ onClick, testid, style }: IconButtonProps) => {
    const { t } = useTranslation();
    return (
        <IconButton
            onClick={onClick}
            style={{ ...style }}
            data-testid={`${testid}-updateTickIconButton`}
        >
            <Tooltip title={t('table.updateTick')}>
                <CloudUploadIcon sx={{ "&:hover path": { stroke: '#10B981' } }} />
            </Tooltip>
        </IconButton>
    );
};

export interface IconTextProps {
    value: number | undefined;
    testid: string;
    style?: React.CSSProperties,
}

export const CountIconText = ({ value, testid, style }: IconTextProps) => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex'
            }}
        >
            <Tooltip title={t('common.totalNumberElements')}>
                <ListIcon />
            </Tooltip>
            <Typography
                color="textSecondary"
                noWrap
                variant="overline"
                data-testid={`${testid}-countIconText`}
            >
                {value}
            </Typography>
        </Box>
    );
};
