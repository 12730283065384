/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Api.Export
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ExportImportType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;
export type ExportImportType = typeof ExportImportType[keyof typeof ExportImportType];


export function ExportImportTypeFromJSON(json: any): ExportImportType {
    return ExportImportTypeFromJSONTyped(json, false);
}

export function ExportImportTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportImportType {
    return json as ExportImportType;
}

export function ExportImportTypeToJSON(value?: ExportImportType | null): any {
    return value as any;
}

