import {makeAutoObservable} from "mobx";
import {getApiConfiguration} from "../../../../../core";
import {ExportTabsStore} from "../ExportTabsStore";
import {InitOverideFunction} from "../../../../../services/export";
import {downloadFile} from "../../../../../core/blob";
import {ImportExportLocalitiesApi} from "../../../../../services/management/apis/ImportExportLocalitiesApi";

export type ExportAddressType = 'CityLocalities' | 'TypeLocalities';

export class ExportAddressesStore {
    readonly store: ExportTabsStore;
    typeLoad: {
        [key in ExportAddressType]: {
            request: (initOverrides?: RequestInit | InitOverideFunction) => Promise<any>,
            loading: boolean
        };
    } = {
        TypeLocalities: {
            request: (e) => {
                return new ImportExportLocalitiesApi(getApiConfiguration()).getExportTypeLocalities()
            },
            loading: false
        },
        CityLocalities: {
            request: (e) => {
                return new ImportExportLocalitiesApi(getApiConfiguration()).getCityLocalities()
            },
            loading: false
        }
    }

    constructor(store: ExportTabsStore) {
        this.store = store;

        makeAutoObservable(this);
    }

    async uploadFile(typeLoad: ExportAddressType): Promise<void> {
        const res = this.typeLoad[typeLoad as ExportAddressType]
        res.loading = true;
        try {
            await res.request().then(async x => {
                await downloadFile(await x.blob(),
                    x.headers?.get('Content-Disposition')?.split('filename=')[1]?.split(';')[0] ??
                    `Import${typeLoad}_${Date.now()}.csv`)
            });
        } catch (e) {

        }
        res.loading = false;
    }

    async pull(): Promise<void> {

    }
}
