import {Box, Button} from "@mui/material";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {AddLocalityButton, ResetFiltersButton} from "../../../../components";
import {LocalityFilter} from "../../../../components/select/Locality/LocalityFilter";
import {DefaultMultiSelect} from "../../../../components/shared/components/DefaultMultiSelect";
import {ExportPersonStore} from "../../../../stores/management/data/export/tabs/ExportPersonStore";
import {DefaultDate} from "../../../../components/shared/DefaultDate";

interface Props {
    exportPersonCreateStore: ExportPersonStore;
}

export const ExportPersonCreate = observer(({ exportPersonCreateStore }: Props) => {
    const { t } = useTranslation();

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                backgroundColor: 'background.default',
            }}
        >
            <Box sx={{ mt: 1 }}
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <DefaultMultiSelect
                    autoFocus
                    store={exportPersonCreateStore.statusesFilter}
                    title={t('common.status')}
                    style={{ width: '24%' }}
                />
                <DefaultMultiSelect store={exportPersonCreateStore.postsFilter}
                    title={t('common.post')}
                    style={{ width: '24%' }}
                />
                <DefaultMultiSelect store={exportPersonCreateStore.specialityFilter}
                    title={t('common.speciality')}
                    style={{ width: '24%' }}
                />
                <DefaultMultiSelect store={exportPersonCreateStore.genderFilter}
                    title={t('common.gender')}
                    style={{ width: '24%' }}
                />
            </Box>
            <Box
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <DefaultDate store={exportPersonCreateStore.createDate}
                             style={{marginTop: 16, width: '33%'}}
                             title={t('dataPage.table.createDate')}/>

                <DefaultDate store={exportPersonCreateStore.updateDate}
                             style={{marginTop: 16, marginLeft: 16, width: '33%'}}
                             title={t('dataPage.table.modifiedDate')}/>

                <DefaultMultiSelect store={exportPersonCreateStore.exportTypes}
                    title={t('dataPage.exportType')}
                    nameExp={exportPersonCreateStore.exportTypes.nameExp}
                    valueExp={null}
                    style={{ marginLeft: 16, width: '33%' }}
                />
            </Box>
            <Box style={{ display: 'flex', height: 37 }} sx={{ my: 2 }}>
                <AddLocalityButton onClick={() => exportPersonCreateStore.localitiesFilter.open()} />
                <LocalityFilter store={exportPersonCreateStore.localitiesFilter} style={{ flex: 1, marginLeft: 40 }} />
                <ResetFiltersButton onClick={() => exportPersonCreateStore.resetFilters()} />
                {/* <AuthorizePermission permissions=""> */}
                <Button variant='contained' color="success" sx={{ml: 2}}
                        onClick={() => exportPersonCreateStore.create()}
                        disabled={exportPersonCreateStore.loading || exportPersonCreateStore.exportTypes.valid}>
                    {t('buttons.create')}
                </Button>
                {/* </AuthorizePermission> */}
            </Box>
        </Box >
    )
});
