/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    externalId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RoleDto
     */
    createdAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RoleDto
     */
    modifiedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RoleDto
     */
    ticks?: number;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RoleDto
     */
    permissionsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof RoleDto
     */
    userCount?: number;
}

export function RoleDtoFromJSON(json: any): RoleDto {
    return RoleDtoFromJSONTyped(json, false);
}

export function RoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (json['modifiedAt'] === null ? null : new Date(json['modifiedAt'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'ticks': !exists(json, 'ticks') ? undefined : json['ticks'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'permissionsCount': !exists(json, 'permissionsCount') ? undefined : json['permissionsCount'],
        'userCount': !exists(json, 'userCount') ? undefined : json['userCount'],
    };
}

export function RoleDtoToJSON(value?: RoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalId': value.externalId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt === null ? null : value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt === null ? null : value.modifiedAt.toISOString()),
        'modifiedBy': value.modifiedBy,
        'ticks': value.ticks,
        'name': value.name,
        'description': value.description,
        'permissionsCount': value.permissionsCount,
        'userCount': value.userCount,
    };
}

