import { Divider } from '@mui/material';

const v = {
    height: 'auto',
    padding: '4px 0px',
    margin: '0px 8px',
};

const h = {
    margin: '0px 0px'
};

export interface ToolDividerProps {
    vertical?: boolean,
    style?: React.CSSProperties
}

export const ToolDivider = ({ vertical, style }: ToolDividerProps) => {
    return (
        <Divider
            orientation={vertical ? 'vertical' : 'horizontal'}
            sx={vertical ? v : h}
            style={style}
        />
    );
};