/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetPersonDto
 */
export interface SetPersonDto {
    /**
     * 
     * @type {string}
     * @memberof SetPersonDto
     */
    firstNameId: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonDto
     */
    middleNameId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetPersonDto
     */
    maidenNameId?: string | null;
    /**
     *
     * @type {string}
     * @memberof SetPersonDto
     */
    surnameId: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonDto
     */
    genderId: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonDto
     */
    primarySpecialityId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetPersonDto
     */
    primaryJobId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SetPersonDto
     */
    personStatusId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SetPersonDto
     */
    hasNoMiddleName?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SetPersonDto
     */
    tags?: Array<string> | null;
}

export function SetPersonDtoFromJSON(json: any): SetPersonDto {
    return SetPersonDtoFromJSONTyped(json, false);
}

export function SetPersonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetPersonDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstNameId': json['firstNameId'],
        'middleNameId': !exists(json, 'middleNameId') ? undefined : json['middleNameId'],
        'maidenNameId': !exists(json, 'maidenNameId') ? undefined : json['maidenNameId'],
        'surnameId': json['surnameId'],
        'genderId': json['genderId'],
        'primarySpecialityId': !exists(json, 'primarySpecialityId') ? undefined : json['primarySpecialityId'],
        'primaryJobId': !exists(json, 'primaryJobId') ? undefined : json['primaryJobId'],
        'personStatusId': !exists(json, 'personStatusId') ? undefined : json['personStatusId'],
        'hasNoMiddleName': !exists(json, 'hasNoMiddleName') ? undefined : json['hasNoMiddleName'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
    };
}

export function SetPersonDtoToJSON(value?: SetPersonDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstNameId': value.firstNameId,
        'middleNameId': value.middleNameId,
        'maidenNameId': value.maidenNameId,
        'surnameId': value.surnameId,
        'genderId': value.genderId,
        'primarySpecialityId': value.primarySpecialityId,
        'primaryJobId': value.primaryJobId,
        'personStatusId': value.personStatusId,
        'hasNoMiddleName': value.hasNoMiddleName,
        'tags': value.tags,
    };
}

