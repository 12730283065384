import {Box, Breadcrumbs, Card, Container, Typography} from "@mui/material";
import {observer} from 'mobx-react';
import {useSnackbar} from "notistack";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import {AuthorizePermission, PageTab, PageTabs} from '../../../../components';
import {AllPermissions} from '../../../../core/const';
import {useManagementStore, useRootStore} from "../../../../stores";
import {UserItemStore} from "../../../../stores/management/users/tabs/Users/UserItemStore";
import {ParametersTab} from "./tabs/ParametersTab";
import {RolesTab} from "./tabs/RolesTab";
import {JurisdictionsTab} from "./tabs/JurisdictionsTab";

export const User = observer(() => {
    const {enqueueSnackbar} = useSnackbar();
    const {id} = useParams<{ id: string }>();
    const {userTableStore} = useManagementStore().usersPageStore;
    const [user, setUser] = useState<UserItemStore | undefined | null>();
    const {t} = useTranslation();
    const {authorizationStore} = useRootStore();
    const hasAdmin = authorizationStore.hasRole("Admin");

    useEffect(() => {
        get();
    }, [id]);

    const get = async () => {
        try {
            if (id) {
                const f = await userTableStore.getUser(id);
                setUser(f);
            }
        } catch (e) {
            enqueueSnackbar(`Ошибка при загрузке юзера`);
        }
    };

    const renderTabs = (index: number) => {
        if (user) {
            switch (index) {
                case 0:
                    return <ParametersTab store={user} style={{ flex: 1 }} />;
                case 1:
                    return <RolesTab store={user.roles} style={{ flex: 1 }} />;
                case 2:
                    return <JurisdictionsTab store={user.jurisdictions} style={{flex: 1}}/>;
                default:
                    return null;

            }
        }
    };

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 3,
                backgroundColor: 'background.default',
            }}
        >
            <Container maxWidth="xl">
                <Box sx={{
                    mb: 3
                }}>
                    {
                        user
                            ? (
                                <Breadcrumbs
                                    separator="/"
                                    sx={{ mt: 1 }}
                                >

                                    <Link to="/users" style={{ textDecoration: 'none' }}>
                                        <Typography color="primary"
                                            variant="h6">{t('pageNames.users')}</Typography>
                                    </Link>
                                    <Typography color="textSecondary"
                                        variant="h6">
                                        {user.dto.username}
                                    </Typography>
                                </Breadcrumbs>
                            )
                            : '...'
                    }

                </Box>
                <AuthorizePermission permissions={AllPermissions.user.read} showWarning>
                    <Card
                        style={{ display: 'flex', flexDirection: 'column' }}
                        sx={{
                            px: 2,
                            mt: 2
                        }}
                    >
                        {
                            user
                                ? (
                                    <>
                                        <PageTabs
                                            index={user.tabIndex}
                                            onChange={t => user.tabIndex = t} style={{width: '100%'}}>
                                            <PageTab label={t('usersPage.userTabs.properties')}
                                                     testid="user-properties-tabs"/>
                                            <PageTab label={t('usersPage.userTabs.roles')} testid="user-roles-tabs"/>
                                            {/* <PageTab label={t('usersPage.tabs.claims')} testid="user-roles-tabs" style={{ width: '33%' }} /> */}
                                            {
                                                hasAdmin && <PageTab label={t('usersPage.tabs.jurisdictions')}
                                                                     testid="user-roles-tabs"/>
                                            }
                                        </PageTabs>

                                        <Box sx={{ mt: 2, mb: 2 }}>
                                            {renderTabs(user.tabIndex)}
                                        </Box>
                                    </>
                                )
                                : '...'
                        }
                    </Card>
                </AuthorizePermission>
            </Container>
        </Box>
    );
});
