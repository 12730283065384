import {Box} from "@mui/material";
import {observer} from "mobx-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {AuthorizePermission, FormRoot, ParamsButtonGroup} from "../../../components";
import {DefaultValue} from "../../../components/shared/components/DefaultValue";
import {AllPermissions} from "../../../core/const";
import {StoreProps, StyleProps} from "../../../stores";
import {DefaultCheckbox} from "../../../components/shared/components/DefaultCheckbox";
import {ClientCrmSettingsStore} from "../../../stores/management/clients/tabs/ClientCrmSettingsStore";

export const CrmSettingsTab = observer((props: StoreProps<ClientCrmSettingsStore> & StyleProps) => {
    const {t} = useTranslation();


    return (
        <FormRoot style={props.style}>
            <DefaultCheckbox
                style={{marginTop: 10, marginBottom: 10}}
                store={props.store.details.fields.disabled}
                title="Отключен"
            />
            <DefaultValue
                disabled={props.store.details.fields.disabled.value!}
                style={{marginTop: 10, marginBottom: 10}}
                store={props.store.details.fields.endpoint}
                title="Ссылка"
            />
            <DefaultValue
                disabled={props.store.details.fields.disabled.value!}
                style={{marginTop: 10, marginBottom: 10}}
                store={props.store.details.fields.username}
                title="Логин"
            />
            <DefaultValue
                disabled={props.store.details.fields.disabled.value!}
                style={{marginTop: 10, marginBottom: 10}}
                store={props.store.details.fields.password}
                title="Пароль"
                type={"password"}
            />

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',

                }}
            >

                <AuthorizePermission permissions={AllPermissions.client.update}>
                    <ParamsButtonGroup
                        testid="clientDetails"
                        onCancel={() => props.store.details.reset()}
                        onSave={async () => await props.store.details.submit()}
                        isCancelBtnDisabled={props.store.details.processing || props.store.details.equals}
                        isSaveBtnDisabled={props.store.details.processing || props.store.details.equals || !props.store.details.valid}
                    />
                </AuthorizePermission>
            </Box>
        </FormRoot>
    );
});
