/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetSpecialityDto
 */
export interface SetSpecialityDto {
    /**
     * 
     * @type {string}
     * @memberof SetSpecialityDto
     */
    nameRus: string;
    /**
     * 
     * @type {string}
     * @memberof SetSpecialityDto
     */
    nameEng?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SetSpecialityDto
     */
    isInactive?: boolean;
}

export function SetSpecialityDtoFromJSON(json: any): SetSpecialityDto {
    return SetSpecialityDtoFromJSONTyped(json, false);
}

export function SetSpecialityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetSpecialityDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nameRus': json['nameRus'],
        'nameEng': !exists(json, 'nameEng') ? undefined : json['nameEng'],
        'isInactive': !exists(json, 'isInactive') ? undefined : json['isInactive'],
    };
}

export function SetSpecialityDtoToJSON(value?: SetSpecialityDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nameRus': value.nameRus,
        'nameEng': value.nameEng,
        'isInactive': value.isInactive,
    };
}

