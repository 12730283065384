import {PagedItems, TableStore} from "../../../../../../utils";
import {DcrGroupListDto, GroupApi,} from "../../../../../../../services/dcr";
import {GroupItemStore} from "../../GroupItemStore";
import {PeopleItemStore} from "./PeopleItemStore";
import {getApiConfiguration} from "../../../../../../../core";

export class PeopleTableTabStore extends TableStore<PeopleItemStore> {
    readonly store: GroupItemStore;
    public _dto: DcrGroupListDto;

    constructor(store: GroupItemStore, dto: DcrGroupListDto) {
        super();
        this.store = store;
        this._dto = dto;
    }

    async request(): Promise<PagedItems<PeopleItemStore>> {
        const filters = {
            page: this.page + 1,
            size: this.size
        };
        const api: GroupApi = new GroupApi(getApiConfiguration());
        const { items, total } = await api.getPermissions({ groupId: this._dto.id!, ...filters });
        const newList: PeopleItemStore[] = [];
        for (const item of items!) {
            const existsItem = this.items.find(t => t._dto.userId === item.userId);
            if (existsItem) {
                existsItem.update(item);
                newList.push(existsItem);
                continue;
            }
            newList.push(new PeopleItemStore(this, item))
        }

        return new PagedItems<PeopleItemStore>(newList,total)
    };
}

