import { styled } from "@mui/material";

export const FormRoot = styled("div")({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',

});

export const FormRow = styled("div")({
    display: 'flex',
    flexWrap: 'nowrap',
    height: 70
});
