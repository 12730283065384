import {observer} from 'mobx-react';
import {Autocomplete, Button, Popper, TextField} from '@mui/material';
import {useEffect, useState} from 'react';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {PanelButton} from "../../Buttons";
import {LocalitySelectStore} from "./LocalitySelectStore";
import {useTranslation} from "react-i18next";

interface Props {
    locality: LocalitySelectStore;
    style?: React.CSSProperties;
    zIndex?: number;
    stepLocality?: () => void;
    step?: number;
    localityFn?: (arg: any) => void;
    typeLocaly?: boolean;
    noStreetFn?: (x: boolean) => void;
    canBeEndpointFn?: (x: boolean) => void;
    testid?: string;
    readonly?: boolean
}

export const LocalitySelect = observer(({
                                            locality,
                                            style,
                                            zIndex = 1300,
                                            stepLocality,
                                            step = 1,
                                            localityFn,
                                            typeLocaly = false,
                                            noStreetFn,
                                            canBeEndpointFn,
                                            testid,
                                            readonly = false
                                        }: Props) => {
    const [text, setText] = useState(locality._search);
    const [open, setOpen] = useState(false);
    const {t} = useTranslation();
    if (!!locality.typeAddress) {
        step = 3
    }

    useEffect(() => {
        if(!locality._value?.locality){
            const fetchData = async () => {
                const data = await locality.checkEndpointFn(locality.id);
            }
            fetchData()
        }
    }, []);

    useEffect(() => {

        const timeOutId = setTimeout(async () => {
            if (text === locality._search) {
                return;
            }
            await locality.setSearch(text);
        }, 500);

        setTimeout(() => {
            noOptions()
        }, 500)

        return () => clearTimeout(timeOutId);
    }, [text]);

    const noOptions = () => {
        if (!text && !locality._search) return (
            <>
                {t('localities.noResults')}
                <Button variant='contained' style={{marginLeft: 10}} onClick={() => {
                    noStreetFn && noStreetFn(true);
                    setOpen(false);
                }}>
                    {t('localities.noResultsButton')}
                </Button>
            </>
        )
        return (<>{t('localities.noResults')}</>)
    }


    return (
        <>
            <div style={{display: 'flex', marginBottom: 25}} className='autocompleteLocality'>
                <Autocomplete
                    data-testid={`${testid}-localitySelect`}
                    options={locality.items ?? []}
                    getOptionLabel={t => t.id === t.parentId ? `${t.nameRus}` : !typeLocaly ? `${t.nameRus} (${t.typeLocality.nameRus})` : `${t.nameRus} (${t?.parent?.nameRus}) (${t?.parent?.parent?.nameRus})`}
                    PopperComponent={({style, ...props}) => (
                        <Popper
                            {...props}
                            placement="bottom"
                            style={{...style, zIndex: zIndex, height: 0}}
                        />
                    )}
                    ListboxProps={{style: {minHeight: '10vh', maxHeight: '25vh'}}}
                    open={open}
                    clearOnBlur
                    autoComplete
                    fullWidth
                    readOnly={readonly}
                    loading={locality.loading}
                    filterOptions={t => t}
                    value={locality.value}
                    onFocusCapture={() => {
                        setText("");
                        if (!stepLocality) return;
                        setTimeout(() => stepLocality(), 0)
                    }}
                    onChange={async (e, t) => {
                        if (!stepLocality) return;
                        if (t === null) {
                            stepLocality()
                        }
                        locality.value = t
                    }}
                    noOptionsText={noOptions()}
                    onOpen={async () => {
                        setOpen(true);
                        await locality.pull();
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    onInputChange={(e, t) => {
                        if (e?.type === 'change')
                            setText(t)
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            disabled={readonly}
                            label={locality.id ? locality.value?.typeLocality.nameRus : (step === 1 || step === 0) ? t('localities.country') : step === 2 ? t('localities.subject') : t('localities.addressSelection')}
                            margin='dense'
                            placeholder={locality.id ? locality.value?.typeLocality.nameRus : (step === 1 || step === 0) ? t('localities.country') : step === 2 ? t('localities.subject') : t('localities.addressSelection')}
                            variant="standard"
                            autoComplete='off'
                            autoFocus
                        />
                    }
                />
                <div style={{width: "auto", marginTop: "auto"}}>
                    {locality._store?.id && !readonly &&<PanelButton onClick={() => {
                        locality.delete();
                        if (!!noStreetFn) noStreetFn(false);
                        if (!stepLocality) return;
                        setTimeout(() => stepLocality(), 200)
                    }}>
                        <DeleteOutlineOutlinedIcon color='primary'/>
                    </PanelButton>}
                </div>
            </div>
            {locality.value && !locality.locality && !locality.isStreet && !readonly &&
                <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row'}}>
                    <PanelButton
                        style={{
                            height: 'max-content',
                            marginTop: 'auto',
                            marginBottom: '25px'
                        }}
                        onClick={() => {
                            locality.add();
                            stepLocality && stepLocality();
                            localityFn && localityFn(locality);
                        }}
                    >
                        <AddBoxOutlinedIcon color='primary'/>
                    </PanelButton>
                </div>}
            {locality.locality && <LocalitySelect locality={locality.locality} stepLocality={stepLocality} step={step}
                                                  readonly={readonly}
                                                  localityFn={localityFn} noStreetFn={noStreetFn}
                                                  canBeEndpointFn={canBeEndpointFn}/>}
        </>
    );
});
