import {makeAutoObservable} from 'mobx';
import {DefaultValueStore} from "../../../../components/shared/DefaultValueStore";
import {IProcessing} from "../../../../core";
import {
    ClientFragment,
    SetCrmSettingsClientMutation,
    SetCrmSettingsClientMutationVariables,
} from "../../../../gql/graphql";
import {getAppoloClient, GRAPHQL_URLS} from "../../../../graphql";
import {SET_CRM_SETTINGS_CLIENT_MUTATION,} from "../../../../graphql/mutations.graphql";
import {DefaultFormModalStore} from "../../../../components/shared/DefaultFormModalStore";
import {ClientStore} from "../client/ClientStore";

export class ClientCrmSettingsStore implements IProcessing {
    readonly _store: ClientStore;
    private _dto: ClientFragment;
    processing: boolean = false;

    details: DefaultFormModalStore<{
        disabled: DefaultValueStore<boolean>,
        endpoint: DefaultValueStore<string>,
        username: DefaultValueStore<string>
        password: DefaultValueStore<string>,
    }>

    constructor(store: ClientStore, dto: ClientFragment) {
        this._store = store;
        this._dto = dto;

        this.details = new DefaultFormModalStore({
            disabled: new DefaultValueStore<boolean>(!!dto.settings?.crm360Settings?.disabled, null, x => {
                this.details.fields.endpoint.required = !x;
                this.details.fields.username.required = !x;
                this.details.validate()
            }),
            endpoint: new DefaultValueStore<string>(dto.settings?.crm360Settings?.endpoint ?? ""),
            username: new DefaultValueStore<string>(dto.settings?.crm360Settings?.username ?? ""),
            password: new DefaultValueStore<string>(dto.settings?.crm360Settings?.password ?? ""),
        }, async (e) => {
            await this.submit()
        })

        this.details.fields.endpoint.required = !dto.settings?.crm360Settings?.disabled;
        this.details.fields.username.required = !dto.settings?.crm360Settings?.disabled;

        makeAutoObservable(this);
    }


    update(dto: ClientFragment) {
        this._dto = dto;
        this.details.fields.disabled.update(!!dto.settings?.crm360Settings?.disabled)
        this.details.fields.endpoint.update(dto.settings?.crm360Settings?.endpoint)
        this.details.fields.username.update(dto.settings?.crm360Settings?.username)
    }

    async submit(): Promise<void> {
        this.details.validate()

        if (this.details.valid) {
            await getAppoloClient(GRAPHQL_URLS.CLIENTS).mutate<SetCrmSettingsClientMutation, SetCrmSettingsClientMutationVariables>({
                mutation: SET_CRM_SETTINGS_CLIENT_MUTATION, variables: {
                    clientId: this._dto.id,
                    disabled: !!this.details.fields.disabled.value,
                    endpoint: this.details.fields.endpoint.value!,
                    username: this.details.fields.username.value!,
                    password: this.details.fields.password.value ?? this._dto.settings?.crm360Settings?.password ?? "",
                }
            })
            await this._store._store._store.getClient(this._dto.id)
        }
    };
}
