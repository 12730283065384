/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface UpdatePersonPhoneDto
 */
export interface UpdatePersonPhoneDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonPhoneDto
     */
    personPhoneStatusId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonPhoneDto
     */
    personPhoneTypeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonPhoneDto
     */
    personPhoneCategoryId?: string | null;
    /**
     *
     * @type {string}
     * @memberof UpdatePersonPhoneDto
     */
    description?: string | null;
    /**
     *
     * @type {string}
     * @memberof UpdatePersonPhoneDto
     */
    personPhone?: string | null;
}

export function UpdatePersonPhoneDtoFromJSON(json: any): UpdatePersonPhoneDto {
    return UpdatePersonPhoneDtoFromJSONTyped(json, false);
}

export function UpdatePersonPhoneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePersonPhoneDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personPhoneStatusId': !exists(json, 'personPhoneStatusId') ? undefined : json['personPhoneStatusId'],
        'personPhoneTypeId': !exists(json, 'personPhoneTypeId') ? undefined : json['personPhoneTypeId'],
        'personPhoneCategoryId': !exists(json, 'personPhoneCategoryId') ? undefined : json['personPhoneCategoryId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'personPhone': !exists(json, 'personPhone') ? undefined : json['personPhone'],
    };
}

export function UpdatePersonPhoneDtoToJSON(value?: UpdatePersonPhoneDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personPhoneStatusId': value.personPhoneStatusId,
        'personPhoneTypeId': value.personPhoneTypeId,
        'personPhoneCategoryId': value.personPhoneCategoryId,
        'description': value.description,
        'personPhone': value.personPhone,
    };
}

