/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BoundLocalityDto,
    BoundLocalityDtoFromJSON,
    BoundLocalityDtoToJSON,
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface GetBoundLocalitiesRequest {
    boundId: string;
    search?: string;
    jurisdictionId?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetBoundLocalitiesCountRequest {
    boundId: string;
    search?: string;
    jurisdictionId?: string;
}

export interface RemoveBoundLocalitiesRequest {
    boundId: string;
    requestBody: Array<string>;
}

export interface SetBoundLocalitiesRequest {
    boundId: string;
    requestBody: Array<string>;
}

/**
 * 
 */
export class ClientLocalitiesApi extends runtime.BaseAPI {

    /**
     */
    async getBoundLocalitiesRaw(requestParameters: GetBoundLocalitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<BoundLocalityDto>>> {
        if (requestParameters.boundId === null || requestParameters.boundId === undefined) {
            throw new runtime.RequiredError('boundId','Required parameter requestParameters.boundId was null or undefined when calling getBoundLocalities.');
        }

        const queryParameters: any = {};

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.jurisdictionId !== undefined) {
            queryParameters['jurisdictionId'] = requestParameters.jurisdictionId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/clients/localities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BoundLocalityDtoFromJSON));
    }

    /**
     */
    async getBoundLocalities(requestParameters: GetBoundLocalitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<BoundLocalityDto>> {
        const response = await this.getBoundLocalitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBoundLocalitiesCountRaw(requestParameters: GetBoundLocalitiesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        if (requestParameters.boundId === null || requestParameters.boundId === undefined) {
            throw new runtime.RequiredError('boundId','Required parameter requestParameters.boundId was null or undefined when calling getBoundLocalitiesCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.jurisdictionId !== undefined) {
            queryParameters['jurisdictionId'] = requestParameters.jurisdictionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/clients/localities/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getBoundLocalitiesCount(requestParameters: GetBoundLocalitiesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getBoundLocalitiesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeBoundLocalitiesRaw(requestParameters: RemoveBoundLocalitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<BoundLocalityDto>>> {
        if (requestParameters.boundId === null || requestParameters.boundId === undefined) {
            throw new runtime.RequiredError('boundId','Required parameter requestParameters.boundId was null or undefined when calling removeBoundLocalities.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling removeBoundLocalities.');
        }

        const queryParameters: any = {};

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/clients/localities/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BoundLocalityDtoFromJSON));
    }

    /**
     */
    async removeBoundLocalities(requestParameters: RemoveBoundLocalitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<BoundLocalityDto>> {
        const response = await this.removeBoundLocalitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setBoundLocalitiesRaw(requestParameters: SetBoundLocalitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<BoundLocalityDto>>> {
        if (requestParameters.boundId === null || requestParameters.boundId === undefined) {
            throw new runtime.RequiredError('boundId','Required parameter requestParameters.boundId was null or undefined when calling setBoundLocalities.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling setBoundLocalities.');
        }

        const queryParameters: any = {};

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/clients/localities/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BoundLocalityDtoFromJSON));
    }

    /**
     */
    async setBoundLocalities(requestParameters: SetBoundLocalitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<BoundLocalityDto>> {
        const response = await this.setBoundLocalitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
