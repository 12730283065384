import type {FC, ReactNode} from 'react';
import type {Theme} from '@mui/material';
import {styled} from '@mui/material/styles';
import type {SxProps} from '@mui/system';
import {Link} from "react-router-dom";

interface NavLinkProps {
    to: string;
    children?: ReactNode;
    style?: {};
    sx?: SxProps<Theme>;
    target?: string
}

const NavLinkRoot = styled(Link)(
    ({theme}) => {
        return {
            alignItems: 'center',
            textDecoration: 'none',
            cursor: 'pointer',
            display: 'inline-flex',
            flexGrow: 0,
            flexShrink: 0,
            color: 'inherit',
            justifyContent: 'center',
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        };
    }
);

export const NavLink: FC<NavLinkProps> = (props) => {
    const {children, ...other} = props;


    return (
        <NavLinkRoot
            {...other}
            sx={{
                pl: 0,
                '&:hover': {
                    color: '#5048E5'
                }
            }}
        >
            {children}
        </NavLinkRoot>
    );
};
