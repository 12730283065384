/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BoundSpecialityDto,
    BoundSpecialityDtoFromJSON,
    BoundSpecialityDtoToJSON,
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface GetBoundSpecialitiesRequest {
    boundId: string;
    search?: string;
    jurisdictionId?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetBoundSpecialitiesCountRequest {
    boundId: string;
    search?: string;
    jurisdictionId?: string;
}

export interface RemoveBoundSpecialitiesRequest {
    boundId: string;
    requestBody: Array<string>;
}

export interface SetBoundSpecialitiesRequest {
    boundId: string;
    requestBody: Array<string>;
}

/**
 * 
 */
export class ClientSpecialitiesApi extends runtime.BaseAPI {

    /**
     */
    async getBoundSpecialitiesRaw(requestParameters: GetBoundSpecialitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<BoundSpecialityDto>>> {
        if (requestParameters.boundId === null || requestParameters.boundId === undefined) {
            throw new runtime.RequiredError('boundId','Required parameter requestParameters.boundId was null or undefined when calling getBoundSpecialities.');
        }

        const queryParameters: any = {};

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.jurisdictionId !== undefined) {
            queryParameters['jurisdictionId'] = requestParameters.jurisdictionId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/clients/specialities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BoundSpecialityDtoFromJSON));
    }

    /**
     */
    async getBoundSpecialities(requestParameters: GetBoundSpecialitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<BoundSpecialityDto>> {
        const response = await this.getBoundSpecialitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBoundSpecialitiesCountRaw(requestParameters: GetBoundSpecialitiesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        if (requestParameters.boundId === null || requestParameters.boundId === undefined) {
            throw new runtime.RequiredError('boundId','Required parameter requestParameters.boundId was null or undefined when calling getBoundSpecialitiesCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.jurisdictionId !== undefined) {
            queryParameters['jurisdictionId'] = requestParameters.jurisdictionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/clients/specialities/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getBoundSpecialitiesCount(requestParameters: GetBoundSpecialitiesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getBoundSpecialitiesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeBoundSpecialitiesRaw(requestParameters: RemoveBoundSpecialitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<BoundSpecialityDto>>> {
        if (requestParameters.boundId === null || requestParameters.boundId === undefined) {
            throw new runtime.RequiredError('boundId','Required parameter requestParameters.boundId was null or undefined when calling removeBoundSpecialities.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling removeBoundSpecialities.');
        }

        const queryParameters: any = {};

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/clients/specialities/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BoundSpecialityDtoFromJSON));
    }

    /**
     */
    async removeBoundSpecialities(requestParameters: RemoveBoundSpecialitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<BoundSpecialityDto>> {
        const response = await this.removeBoundSpecialitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setBoundSpecialitiesRaw(requestParameters: SetBoundSpecialitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<BoundSpecialityDto>>> {
        if (requestParameters.boundId === null || requestParameters.boundId === undefined) {
            throw new runtime.RequiredError('boundId','Required parameter requestParameters.boundId was null or undefined when calling setBoundSpecialities.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling setBoundSpecialities.');
        }

        const queryParameters: any = {};

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/clients/specialities/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BoundSpecialityDtoFromJSON));
    }

    /**
     */
    async setBoundSpecialities(requestParameters: SetBoundSpecialitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<BoundSpecialityDto>> {
        const response = await this.setBoundSpecialitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
