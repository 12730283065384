import {DictionaryOrganizationsStore} from "../DictionaryOrganizationsStore";
import {getApiConfiguration} from '../../../../../core';
import {OrganizationPhoneTypesApi} from "../../../../../services/management";
import {DictionaryCreateEntity, DictionaryEntity, DictionaryTableStore} from "../../DictionaryTableStore";

export class PhoneTypeTableStore extends DictionaryTableStore {
    constructor(store: DictionaryOrganizationsStore) {
        super(store, 'phone-status-store');
    }

    async Create(model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new OrganizationPhoneTypesApi(getApiConfiguration()).createOrganizationPhoneType({setPhoneTypeDto: model});
    }

    async Delete(id: string): Promise<DictionaryEntity> {
        return await new OrganizationPhoneTypesApi(getApiConfiguration()).deleteOrganizationPhoneType({id: id});
    }

    async GetCount(search: string): Promise<{ count: number }> {
        return await new OrganizationPhoneTypesApi(getApiConfiguration()).getOrganizationPhoneTypesCount({search: search});
    }

    async GetList(filters: any): Promise<DictionaryEntity[]> {
        return await new OrganizationPhoneTypesApi(getApiConfiguration()).getOrganizationPhoneTypes(filters);
    }

    async Update(id: string, model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new OrganizationPhoneTypesApi(getApiConfiguration()).updateOrganizationPhoneType({id: id, setPhoneTypeDto: model});
    }
}
