import {BaseSpecification} from "./BaseSpecification";
import {RequestDetailsStore} from "../../RequestDetailsStore";
import {mappingDcrStatusToString} from "../../../services";
import {t} from "i18next";
import {action, computed, makeObservable} from "mobx";
import {RequestStatus} from "../../../../../../services/dcr";
import {createElement} from "react";
import {CrmDetailsButtonsComponent} from "../../../../../../pages/dcr/requests/specifications/CrmSpecification";
import {DCRStatusEnum, RequestField, RequestStatusEnum} from "../../../types";

export type DcrInfoField = {
    name: string
    value?: string | null
}

export class CrmSpecification extends BaseSpecification {
    constructor(store: RequestDetailsStore) {
        super(store);
        this.DetailsButtonsComponent = createElement(CrmDetailsButtonsComponent, {store: this}, null)

        makeObservable(this, {
            DcrInfoFields: computed,
            DcrStatusName: computed,
            SpecValid: computed,
            valid: computed,
            changeAfterInitFieldsSpec: action,
        });
    }

    get DcrInfoFields(): DcrInfoField[] {
        return [
            {name: t('dcrPage.status'), value: this.store._store.statusName},
            {name: t('dcrPage.dcrStatus'), value: this.DcrStatusName},
            {name: t('dcrPage.createDate'), value: this.store._store.createdDate},
            {name: t('dcrPage.modifiedDate'), value: this.store._store.modifiedDate},
            {name: t('Crm Id'), value: this.store.dto.crmId}
        ];
    }

    async changeStatus(status: RequestStatus): Promise<void> {
        if (status === RequestStatusEnum.Decline) {
            this.store.declineOpen = true;
        } else {
            await super.changeStatus(status);
        }
    }

    get DcrStatusName(): string {
        return mappingDcrStatusToString(this.store.dto.dcrStatus as DCRStatusEnum);
    }

    get SpecValid(): boolean {
        return true;
    }

    async changeAfterInitFieldsSpec(fields: RequestField[]) {
    }
    
    createIfExist(fields: DcrInfoField[], additionalKey: string){
        if (this.store.dto.additionalFields?.[additionalKey])
            fields.push({
                name: t('common.' + additionalKey),
                value: this.store.dto.additionalFields?.[additionalKey] ?? t("dcrPage.empty")
            })
    }
}
