/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Api.Export
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ExportStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;
export type ExportStatus = typeof ExportStatus[keyof typeof ExportStatus];


export function ExportStatusFromJSON(json: any): ExportStatus {
    return ExportStatusFromJSONTyped(json, false);
}

export function ExportStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportStatus {
    return json as ExportStatus;
}

export function ExportStatusToJSON(value?: ExportStatus | null): any {
    return value as any;
}

