import {getApiConfiguration, IExpanded} from '../../../../../core';
import {GetSubjectsRequest, PermissionDto, SubjectsApi} from "../../../../../services/auth";
import {PagedItems, TableStore} from '../../../../utils';

export type PermissionItem =
    { parent?: string, key: string, name: string, permissions: PermissionDto[], items: PermissionItem[] }
    & IExpanded;

export class PermissionTableStore extends TableStore<PermissionItem> {
    constructor() {
        super();
    }

    async request(): Promise<PagedItems<PermissionItem>> {
        const filters = {
            page: this.page + 1,
            size: 1000,
            search: this.search.value ?? '',
            order: this.order === "ascending" ? 1 : 0,
            orderBy: this.orderBy
        } as GetSubjectsRequest;
        const api: SubjectsApi = new SubjectsApi(getApiConfiguration());
        const result = await api.getSubjects(filters);

        const nest = (items: PermissionItem[], id: string | null = null, link = 'parent'): PermissionItem[] => {
            return items
                .filter((item: any) => item[link] === id)
                .map(item => ({...item, items: nest(items.filter(x => x.key != item.key), item.key)}));
        }

        const res: PermissionItem[] = []

        result.map(x => x.permissions ?? []).reduce((x, y) => x.concat(y), []).forEach(x => {
            const split = x.key!.split(".")
            const action = split.pop();
            const key = x.key!.replace('.' + action!, '');

            if (split.length > 1) {
                let spKey = '';
                split.forEach((s, i) => {
                    const parentKey = i == 0 ? 'main' : spKey
                    spKey = spKey != '' ? spKey + '.' + s : s;
                    const allReadyElement1 = res.find(rr => rr.key == spKey);
                    const parentItem = res.find(rr => rr.key == parentKey)?.name ?? ""
                    if (!allReadyElement1)
                        res.push({
                            key: spKey,
                            permissions: [],
                            items: [],
                            expand: false,
                            name: s.toUpperCase().replace(parentItem, ""),
                            parent: parentKey
                        })
                })
            }

            const parent = split.join(".") == key ? 'main' : split.join(".");

            const allReadyElement = res.find(r => r.key == key)
            if (allReadyElement) {
                allReadyElement.permissions.push(x)
                return;
            }
            res.push({
                key: key,
                parent: parent,
                name: key.replace(parent + '.', '')!.toUpperCase(),
                permissions: [x],
                expand: false,
                items: []
            })
        })

        const items = nest(res, 'main');

        return new PagedItems<PermissionItem>(items, items.length)
    };
}
