/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PersonDto,
    PersonDtoFromJSON,
    PersonDtoFromJSONTyped,
    PersonDtoToJSON,
} from './PersonDto';

/**
 * 
 * @export
 * @interface PersonDobleDto
 */
export interface PersonDobleDto {
    /**
     * 
     * @type {string}
     * @memberof PersonDobleDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PersonDobleDto
     */
    externalId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PersonDobleDto
     */
    createdAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PersonDobleDto
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PersonDobleDto
     */
    modifiedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PersonDobleDto
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PersonDobleDto
     */
    ticks?: number;
    /**
     * 
     * @type {string}
     * @memberof PersonDobleDto
     */
    personId: string;
    /**
     * 
     * @type {PersonDto}
     * @memberof PersonDobleDto
     */
    person: PersonDto;
    /**
     * 
     * @type {string}
     * @memberof PersonDobleDto
     */
    dobleId: string;
    /**
     * 
     * @type {PersonDto}
     * @memberof PersonDobleDto
     */
    doble: PersonDto;
}

export function PersonDobleDtoFromJSON(json: any): PersonDobleDto {
    return PersonDobleDtoFromJSONTyped(json, false);
}

export function PersonDobleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonDobleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (json['modifiedAt'] === null ? null : new Date(json['modifiedAt'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'ticks': !exists(json, 'ticks') ? undefined : json['ticks'],
        'personId': json['personId'],
        'person': PersonDtoFromJSON(json['person']),
        'dobleId': json['dobleId'],
        'doble': PersonDtoFromJSON(json['doble']),
    };
}

export function PersonDobleDtoToJSON(value?: PersonDobleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalId': value.externalId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt === null ? null : value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt === null ? null : value.modifiedAt.toISOString()),
        'modifiedBy': value.modifiedBy,
        'ticks': value.ticks,
        'personId': value.personId,
        'person': PersonDtoToJSON(value.person),
        'dobleId': value.dobleId,
        'doble': PersonDtoToJSON(value.doble),
    };
}

