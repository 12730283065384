export const CategoryLocality = {
    PopulatedLocalitiesName: 'Населенные пункты',
    RegionName: 'Области',
};

export const TypeOrgs = {
    DepsName: 'Структурные подразделения',
    SurgeryName: 'Кабинет',
};

export const TypeLocality = {
    CityName: 'Город',
};

export const MdmRegion = {
    DistrictId: '773ffca0.6595c6a3-4888-4a4b-bea8-9b25c5a2be19',
    Okrug: '773ffca0.984ec795-17ca-448d-979a-bc8ab03d9f52',
    Okrug2: '773ffca0.d4d1a533-5884-4cfa-85cf-52a2811b312e',
};

export const OrganizationStatuses = {
    Work: 'fdbd9888.f884e85c-f43d-4bdf-aac2-8876783f262b',
    Double: 'fdbd9888.1410e728-cf2e-4474-bd13-a04bf53ef81f',
};

export const PersonStatusesNew = {
    Created: 1,
    Work: 2,
    NoActiveJobs: 3,
    Double: 4,
    Dead: 5,
};

export const JobStatusesNew = {
    Work: 1,
    NotWork: 2,
    Dekret: 3,
};


export const SectionTypeDictionary = {
    typename: 'typename',
    clinicCategory: 'clinicCategory',
    phonestatus: 'phonestatus',
    phonetype: 'phonetype',
    phonecategory: 'phonecategory',
    emailstatus: 'emailstatus',
    emailtype: 'emailtype',
    websitestatus: 'websitestatus',
    firstname: 'firstname',
    middlename: 'middlename',
    surname: 'surname',
    gender: 'gender',
    speciality: 'speciality',
    post: 'post',
    typeorg: 'typeorg',
};

export const AllPermissions = {
    user: {
        create: 'user.create',
        read: 'user.read',
        update: 'user.update',
        delete: 'user.delete',
        userroles: {
            create: 'user.userroles.create',
            read: 'user.userroles.read',
            delete: 'user.userroles.delete',
        },
    },
    role: {
        delete: 'role.delete',
        create: 'role.create',
        read: 'role.read',
        update: 'role.update',
        rolepermissions: {
            create: 'role.rolepermissions.create',
            delete: 'role.rolepermissions.delete',
            read: 'role.rolepermissions.read',
        },
    },
    client: {
        delete: 'client.delete',
        create: 'client.create',
        read: 'client.read',
        update: 'client.update',
    },
    dictionary: {
        person: {
            emailtype: {
                create: 'dictionary.person.emailtype.create',
                read: 'dictionary.person.emailtype.read',
                update: 'dictionary.person.emailtype.update',
                delete: 'dictionary.person.emailtype.delete',
            },
            emailstatus: {
                create: 'dictionary.person.emailstatus.create',
                read: 'dictionary.person.emailstatus.read',
                update: 'dictionary.person.emailstatus.update',
                delete: 'dictionary.person.emailstatus.delete',
            },
            phonetype: {
                create: 'dictionary.person.phonetype.create',
                read: 'dictionary.person.phonetype.read',
                update: 'dictionary.person.phonetype.update',
                delete: 'dictionary.person.phonetype.delete',
            },
            phonestatus: {
                create: 'dictionary.person.phonestatus.create',
                read: 'dictionary.person.phonestatus.read',
                update: 'dictionary.person.phonestatus.update',
                delete: 'dictionary.person.phonestatus.delete',
            },
            phonecategory: {
                create: 'dictionary.person.phonecategory.create',
                read: 'dictionary.person.phonecategory.read',
                update: 'dictionary.person.phonecategory.update',
                delete: 'dictionary.person.phonecategory.delete',
            },
            post: {
                create: 'dictionary.person.post.create',
                read: 'dictionary.person.post.read',
                update: 'dictionary.person.post.update',
                delete: 'dictionary.person.post.delete',
                inactiveRead: 'dictionary.person.post.inactive.read',
                inactiveUpdate: 'dictionary.person.post.inactive.update',
            },
            surname: {
                create: 'dictionary.person.surname.create',
                read: 'dictionary.person.surname.read',
                update: 'dictionary.person.surname.update',
                delete: 'dictionary.person.surname.delete',
            },
            speciality: {
                create: 'dictionary.person.speciality.create',
                read: 'dictionary.person.speciality.read',
                update: 'dictionary.person.speciality.update',
                delete: 'dictionary.person.speciality.delete',
                inactiveRead: 'dictionary.person.speciality.inactive.read',
                inactiveUpdate: 'dictionary.person.speciality.inactive.update',
            },
            middlename: {
                create: 'dictionary.person.middlename.create',
                read: 'dictionary.person.middlename.read',
                update: 'dictionary.person.middlename.update',
                delete: 'dictionary.person.middlename.delete',
            },
            gender: {
                create: 'dictionary.person.gender.create',
                read: 'dictionary.person.gender.read',
                update: 'dictionary.person.gender.update',
                delete: 'dictionary.person.gender.delete',
            },
            firstname: {
                create: 'dictionary.person.firstname.create',
                read: 'dictionary.person.firstname.read',
                update: 'dictionary.person.firstname.update',
                delete: 'dictionary.person.firstname.delete',
            },
            websitestatus: {
                create: 'dictionary.person.websitestatus.create',
                read: 'dictionary.person.websitestatus.read',
                update: 'dictionary.person.websitestatus.update',
                delete: 'dictionary.person.websitestatus.delete',
            },
        },
        organization: {
            emailtype: {
                create: 'dictionary.organization.emailtype.create',
                read: 'dictionary.organization.emailtype.read',
                update: 'dictionary.organization.emailtype.update',
                delete: 'dictionary.organization.emailtype.delete',
            },
            emailstatus: {
                create: 'dictionary.organization.emailstatus.create',
                read: 'dictionary.organization.emailstatus.read',
                update: 'dictionary.organization.emailstatus.update',
                delete: 'dictionary.organization.emailstatus.delete',
            },
            phonetype: {
                create: 'dictionary.organization.phonetype.create',
                read: 'dictionary.organization.phonetype.read',
                update: 'dictionary.organization.phonetype.update',
                delete: 'dictionary.organization.phonetype.delete',
            },
            phonestatus: {
                create: 'dictionary.organization.phonestatus.create',
                read: 'dictionary.organization.phonestatus.read',
                update: 'dictionary.organization.phonestatus.update',
                delete: 'dictionary.organization.phonestatus.delete',
            },
            phonecategory: {
                create: 'dictionary.organization.phonecategory.create',
                read: 'dictionary.organization.phonecategory.read',
                update: 'dictionary.organization.phonecategory.update',
                delete: 'dictionary.organization.phonecategory.delete',
            },
            typeorg: {
                create: 'dictionary.organization.typeorg.create',
                read: 'dictionary.organization.typeorg.read',
                update: 'dictionary.organization.typeorg.update',
                delete: 'dictionary.organization.typeorg.delete',
                inactiveRead: 'dictionary.organization.typeorg.inactive.read',
                inactiveUpdate: 'dictionary.organization.typeorg.inactive.update',
            },
            typename: {
                create: 'dictionary.organization.typename.create',
                read: 'dictionary.organization.typename.read',
                update: 'dictionary.organization.typename.update',
                delete: 'dictionary.organization.typename.delete',
            },
            cliniccategory: {
                create: 'dictionary.organization.cliniccategory.create',
                read: 'dictionary.organization.cliniccategory.read',
                update: 'dictionary.organization.cliniccategory.update',
                delete: 'dictionary.organization.cliniccategory.delete',
                inactiveRead: 'dictionary.organization.cliniccategory.inactive.read',
                inactiveUpdate: 'dictionary.organization.cliniccategory.inactive.update',
            },
            websitestatus: {
                create: 'dictionary.organization.websitestatus.create',
                read: 'dictionary.organization.websitestatus.read',
                update: 'dictionary.organization.websitestatus.update',
                delete: 'dictionary.organization.websitestatus.delete',
            },
        },
    },
    organization: {
        create: 'organization.create',
        read: 'organization.read',
        update: 'organization.update',
        delete: 'organization.delete',
        addressunique: {
            read: 'organization.addressunique.read',
            update: 'organization.addressunique.update',
        },
        parent: {
            read: 'organization.parent.read',
            update: 'organization.parent.update',
        },
        names: {
            create: 'organization.names.create',
            read: 'organization.names.read',
            update: 'organization.names.update',
            delete: 'organization.names.delete',
        },
        phones: {
            create: 'organization.phones.create',
            read: 'organization.phones.read',
            update: 'organization.phones.update',
            delete: 'organization.phones.delete',
        },
        websites: {
            create: 'organization.websites.create',
            read: 'organization.websites.read',
            update: 'organization.websites.update',
            delete: 'organization.websites.delete',
        },
        emails: {
            create: 'organization.emails.create',
            read: 'organization.emails.read',
            update: 'organization.emails.update',
            delete: 'organization.emails.delete',
        },
    },
    person: {
        create: 'person.create',
        read: 'person.read',
        update: 'person.update',
        delete: 'person.delete',
        personjobs: {
            create: 'person.personjobs.create',
            read: 'person.personjobs.read',
            update: 'person.personjobs.update',
            delete: 'person.personjobs.delete',
        },
        personspecialities: {
            delete: 'person.personspecialities.delete',
            update: 'person.personspecialities.update',
            read: 'person.personspecialities.read',
            create: 'person.personspecialities.create',
        },
        phones: {
            create: 'person.phones.create',
            read: 'person.phones.read',
            update: 'person.phones.update',
            delete: 'person.phones.delete',
        },
        emails: {
            create: 'person.emails.create',
            read: 'person.emails.read',
            update: 'person.emails.update',
            delete: 'person.emails.delete',
        },
        websites: {
            create: 'person.websites.create',
            read: 'person.websites.read',
            update: 'person.websites.update',
            delete: 'person.websites.delete',
        },
        tags: {
            create: 'person.tags.create',
            read: 'person.tags.read',
            update: 'person.tags.update',
            delete: 'person.tags.delete',
        },
        notes: {
            create: 'person.personnote.create',
            read: 'person.personnote.read',
            update: 'person.personnote.update',
            delete: 'person.personnote.delete'
        }
    },
    import: {
        person: {
            create: 'import.person.create',
            read: 'import.person.read'
        },
        organization: {
            create: 'import.organization.create',
            read: 'import.organization.read'
        },
        address: {
            create: 'import.address.create'
        }
    },
    export: {
        address: {
            citylocalities: {
                read: 'export.address.citylocalities.read'
            },
            typelocality: {
                read: 'export.address.typelocalities.read'
            }
        }
    },
    jurisdiction: {
        create: 'jurisdiction.create',
        read: 'jurisdiction.read',
        update: 'jurisdiction.update',
        delete: 'jurisdiction.delete',
    }
} as const;


export const PersonDictionary = [
    AllPermissions.dictionary.person.emailstatus.read,
    AllPermissions.dictionary.person.emailtype.read,
    AllPermissions.dictionary.person.firstname.read,
    AllPermissions.dictionary.person.gender.read,
    AllPermissions.dictionary.person.post.read,
    AllPermissions.dictionary.person.middlename.read,
    AllPermissions.dictionary.person.phonecategory.read,
    AllPermissions.dictionary.person.phonestatus.read,
    AllPermissions.dictionary.person.phonetype.read,
    AllPermissions.dictionary.person.speciality.read,
    AllPermissions.dictionary.person.surname.read,
    AllPermissions.dictionary.person.websitestatus.read,
]

export const OrganizationDictionary = [
    AllPermissions.dictionary.organization.emailstatus.read,
    AllPermissions.dictionary.organization.emailtype.read,
    AllPermissions.dictionary.organization.websitestatus.read,
    AllPermissions.dictionary.organization.typeorg.read,
    AllPermissions.dictionary.organization.typename.read,
    AllPermissions.dictionary.organization.cliniccategory.read,
    AllPermissions.dictionary.organization.phonecategory.read,
    AllPermissions.dictionary.organization.phonestatus.read,
    AllPermissions.dictionary.organization.phonetype.read,
]
