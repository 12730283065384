import {HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState,} from "@microsoft/signalr";
import {RootStore} from '..';
import {accessToken} from "../authorization/AuthorizationStore";

export type Connection = "dcr" | "import"

export const ConnectionUri: { [key in Connection]: string; } = {
    dcr: "signalr/dcr",
    import: "signalr/import"
}

export class SignalRStore {
    readonly store: RootStore;
    private _connections: {
        [key in Connection]: {
            connection: HubConnection | null,
            subs: string[],
            isStopFlag: boolean //TODO HUCK
        };
    } = {
        dcr: {
            connection: null,
            subs: [],
            isStopFlag: false
        },
        import: {
            connection: null,
            subs: [],
            isStopFlag: false
        }
    }

    constructor(store: RootStore) {
        this.store = store;
        for (let con of Object.keys(ConnectionUri)) {
            this._connections[con as Connection].connection = this.getConnection(con as Connection);
        }
    }

    private getConnection(connection: Connection) {

        let conn = this._connections[connection]?.connection;
        if (!conn) {
            conn = this.createConnection(connection);
            this._connections[connection].connection = conn;
        }

        return conn;
    }

    private createConnection(connection: Connection): HubConnection {

        return new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_API}/${ConnectionUri[connection]}`, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                }
            })
            .build();
    }

    stopConnection = async (connectionString: Connection) => {
        const connection = this._connections[connectionString]?.connection;

        if(!this._connections[connectionString].isStopFlag){
            this._connections[connectionString].isStopFlag = true;
            return
        }
        else {
            this._connections[connectionString].isStopFlag = false;
        }

        console.log(`Closing connection - ${connectionString}`)

        if (connection && connection.state == HubConnectionState.Connected) {
            await connection?.stop();
        }

        console.log(`Close connection - ${connectionString}`)
    }

    async startConnection(connectionString: Connection) {

        const connection = this.getConnection(connectionString)

        if(connection.state == HubConnectionState.Connecting){
            return;
        }
        console.log(`Starting connection - ${connectionString}`)

        if (connection.state !== HubConnectionState.Connected){
            await connection.start()
        }

        console.log(`Start connection - ${connectionString}`)
    }

    public registerSubs(connectionString: Connection, subs: { [key: string]: (...args: any[]) => void } ): void {
        const connection = this.getConnection(connectionString)

        for (let sub of Object.keys(subs)) {
            connection.on(sub, subs[sub]);
        }
    }
}
