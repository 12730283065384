/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetDcrGroupDto
 */
export interface SetDcrGroupDto {
    /**
     * 
     * @type {string}
     * @memberof SetDcrGroupDto
     */
    ownerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetDcrGroupDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SetDcrGroupDto
     */
    users?: Array<string> | null;
}

export function SetDcrGroupDtoFromJSON(json: any): SetDcrGroupDto {
    return SetDcrGroupDtoFromJSONTyped(json, false);
}

export function SetDcrGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetDcrGroupDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'users': !exists(json, 'users') ? undefined : json['users'],
    };
}

export function SetDcrGroupDtoToJSON(value?: SetDcrGroupDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ownerId': value.ownerId,
        'name': value.name,
        'users': value.users,
    };
}

