import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
    AuthorizePermission,
    Page,
    PageTab,
    PageTabs,
} from '../../../../components';
import { AllPermissions } from '../../../../core/const';
import { PersonJob } from '../../../../icons/personJob';
import { PersonParams } from '../../../../icons/personParams';
import { StoreProps, StyleProps } from '../../../../stores';
import { RoleItemStore } from '../../../../stores/management/users/tabs/Roles/RoleItemStore';
import { ParametersTab } from './tabs/ParametersTab';
import { UsersTab } from './tabs/UsersTab';
import { PermissionTableTab } from './tabs/permissions/PermissionTableTab';

export const RoleDetails = observer(
    ({ store, style }: StoreProps<RoleItemStore> & StyleProps) => {
        const { t } = useTranslation();

        const renderTabs = (index: number) => {
            switch (index) {
                case 0:
                    return (
                        <ParametersTab
                            store={store.parameter}
                            style={{ flex: 1 }}
                        />
                    );
                case 1:
                    return (
                        <UsersTab
                            store={store.roleUsersTabStore}
                            style={{ flex: 1 }}
                        />
                    );
                case 2:
                    return (
                        <AuthorizePermission permissions={AllPermissions.role.rolepermissions.read}>
                        <PermissionTableTab
                            store={store.permissionsTabStore}
                            style={{ flex: 1 }}
                        />
                        </AuthorizePermission>
                    );
                default:
                    return null;
            }
        };

        return (
            <Page style={style}>
                <AuthorizePermission permissions={AllPermissions.role.read}>
                    <PageTabs
                        index={store.tabIndex}
                        onChange={(t) => (store.tabIndex = t)}
                    >
                        <PageTab
                            icon={<PersonParams />}
                            title={t('usersPage.roles.tabs.parameters')}
                            style={{ width: '31%' }}
                        />
                        <PageTab
                            icon={<PersonJob />}
                            title={t('usersPage.roles.tabs.users')}
                            style={{ width: '31%' }}
                        />
                        <PageTab
                            icon={<PersonJob />}
                            title={t('usersPage.roles.tabs.permissions')}
                            style={{ width: '31%' }}
                        />
                    </PageTabs>
                    <div style={{ marginTop: 4, marginBottom: 4, padding: 20 }}>
                        {renderTabs(store.tabIndex)}
                    </div>
                </AuthorizePermission>
            </Page>
        );
    }
);
