type Json = any;
type HTTPQuery = { [key: string]: string | number | null | boolean | Array<string | number | null | boolean> | Set<string | number | null | boolean> | HTTPQuery };

// тут тоже должен теперь быть Issuer. всё что касается авторизации, теперь будет ходить через внешний endpoint
const baseUrl = process.env.REACT_APP_ISSUER!
const client_id = process.env.REACT_APP_CLIENT_ID!
const client_secret = process.env.REACT_APP_CLIENT_SECRET!
const grant_type = process.env.REACT_APP_GRANT_TYPE!
const grant_type_refresh = process.env.REACT_APP_GRANT_TYPE_REFRESH!
interface RequestOpts {
    path: string;
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'HEAD';
    body?: Json | FormData | URLSearchParams;
    query?: HTTPQuery;
    headers?: { [key: string]: string }
}

interface LoginDto {
    username: string;
    password: string;
}

interface LoginRequest {
    loginDto: LoginDto;
}

interface RefreshRequest {
    refreshToken: string;
}

export interface TokenDto {
    access_token: string;
    refresh_token: string;
}

class BaseAPI {
    protected async request(context: RequestOpts): Promise<Response> {
        const response = await (fetch)(baseUrl + context.path, {
            method: context.method,
            body: context.body,
        });
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw new ResponseError(response, 'Response returned an error code');
    }
}

class ResponseError extends Error {
    name: "ResponseError" = "ResponseError";

    constructor(public response: Response, msg?: string) {
        super(msg);
    }
}

class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";

    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

export class AuthApi extends BaseAPI {
    async token(code: string) : Promise<TokenDto>{
        if (!code || code === ''){
            throw new RequiredError("auth token is required")
        }
        const redirectUri = window.location.origin+"/cb";

        const urlencoded = new URLSearchParams();
        urlencoded.append("client_id", client_id);
        urlencoded.append("client_secret", client_secret);
        urlencoded.append("grant_type", grant_type);
        urlencoded.append("code", code);
        urlencoded.append("redirect_uri", redirectUri);
        const response = await this.request({
            path: `/connect/token`,
            method: 'POST',
            body: urlencoded,
            headers: {
                'Content-Type' : 'application/x-www-form-urlencoded'
            }
        });
        let value = await response.json();
        return !value ? value : {'access_token': value['access_token'], 'refresh_token': value['refresh_token']}
    }
    
    async login(requestParameters: LoginRequest): Promise<TokenDto> {

        if (requestParameters.loginDto === null || requestParameters.loginDto === undefined) {
            throw new RequiredError('loginDto', 'Required parameter requestParameters.loginDto was null or undefined when calling login.');
        }

        const urlencoded = new URLSearchParams();
        urlencoded.append("client_id", client_id);
        urlencoded.append("client_secret", client_secret);
        urlencoded.append("grant_type", grant_type);
        urlencoded.append("username", requestParameters.loginDto['username']);
        urlencoded.append("password", requestParameters.loginDto['password']);
        const response = await this.request({
            path: `/connect/token`,
            method: 'POST',
            body: urlencoded,
            headers: {
                'Content-Type' : 'application/x-www-form-urlencoded'
            }
        });

        let value = await response.json();

        return !value ? value : {'access_token': value['access_token'], 'refresh_token': value['refresh_token']}
    }

    async refresh(requestParameters: RefreshRequest): Promise<TokenDto> {
        if (requestParameters.refreshToken === null || requestParameters.refreshToken === undefined) {
            throw new RequiredError('refreshToken', 'Required parameter requestParameters.refreshToken was null or undefined when calling refresh.');
        }

        const urlencoded = new URLSearchParams();
        urlencoded.append("client_id", client_id);
        urlencoded.append("client_secret", client_secret);
        urlencoded.append("grant_type", grant_type_refresh);
        urlencoded.append("refresh_token", requestParameters.refreshToken);

        const response = await this.request({
            path: `/connect/token`,
            method: 'POST',
            body: urlencoded,
            headers: {
                'Content-Type' : 'application/x-www-form-urlencoded'
            }
        });

        let value = await response.json();

        return !value ? value : {'access_token': value['access_token'], 'refresh_token': value['refresh_token']}
    }
}
