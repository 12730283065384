import {Box, Table, TableBody, TableHead, TableRow, Typography} from "@mui/material";
import {observer} from "mobx-react";
import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import {
    AddButton,
    AuthorizePermission,
    Cell,
    ConfirmationForm,
    EmptyBlock4,
    ExpandButton,
    ExpandRow,
    ExpertDialog,
    HCell,
    Progress
} from "../../../../../components";
import {DeleteIconButton} from "../../../../../components/IconButtons";
import {AllPermissions} from "../../../../../core/const";
import {Scrollbar} from "../../../../../scrollbar";
import {
    OrganizationEmailItemStore
} from "../../../../../stores/management/organizations/details/others/emails/OrganizationEmailItemStore";
import {
    OrganizationEmailTableStore
} from "../../../../../stores/management/organizations/details/others/emails/OrganizationEmailTableStore";
import {OrganizationEmailCreator} from "./OrganizationEmailCreator";
import {toDateTimeString} from "../../../../../core";
import {OrganizationEmailDetails} from "./OrganizationEmailDetails";
import {DefaultMultiSelect} from "../../../../../components/shared/components/DefaultMultiSelect";
import {DefaultValue} from "../../../../../components/shared/components/DefaultValue";

interface Props {
    store: OrganizationEmailTableStore;
    style?: React.CSSProperties
}

export const OrganizationEmailTable = observer(({store, style}: Props) => {
    const {t} = useTranslation();

    return (
        <>
            <ExpertDialog
                title={t('create.orgEmail')}
                open={store.creator.Open}
                onClose={() => store.creator.Open = false}
            >
                <OrganizationEmailCreator store={store.creator}/>
            </ExpertDialog>
            {
                store.current
                    ? (
                        <ExpertDialog
                            title={t('common.delete')}
                            open={store.current.deleteDialogState}
                            onClose={() => store.current ? store.current.deleteDialogState = false : false}
                        >
                            <ConfirmationForm
                                disabled={store.current.processing}
                                onSubmit={async () => await store.current?.delete()}
                                onClose={() => store.current ? store.current.deleteDialogState = false : false}
                            >
                                {t('common.deleteConfirm')} "{store.current.dto.email.address}"?
                            </ConfirmationForm>
                        </ExpertDialog>
                    )
                    : ''
            }
            <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <>
                    <Box sx={{ml: 'auto', mb: '10px'}}>
                        <AuthorizePermission permissions={AllPermissions.organization.emails.create}>
                            <AddButton onClick={() => store.creator.Open = true} disabled={store.isDouble}/>
                        </AuthorizePermission>
                    </Box>
                    <Box
                        sx={{mb: 1}}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: 0,
                        }}
                    >

                        <DefaultMultiSelect
                            store={store.statusFilter}
                            title={t('common.status')}
                            nameExp={store.statusFilter.nameExp}
                            valueExp={store.statusFilter.valueExp}
                            style={{width: '33%'}}
                        />
                        <DefaultMultiSelect
                            store={store.typeFilter}
                            title={t('common.type')}
                            nameExp={store.statusFilter.nameExp}
                            valueExp={store.statusFilter.valueExp}
                            style={{width: '33%'}}
                        />
                        <DefaultValue
                            store={store.description}
                            title={t('common.note')}
                            style={{width: '33%', marginTop: '16px'}}
                        />
                    </Box>
                </>
                {
                    store.items.length > 0 ?
                        <Scrollbar>
                            <Table sx={{minWidth: 800, mt: 3}}>
                                <TableHead>
                                    <TableRow>
                                        <HCell width='5%'></HCell>
                                        <HCell store={store} orderBy="emailAddress">{t('common.email')}</HCell>
                                        <HCell store={store} orderBy="status">{t('common.status')}</HCell>
                                        <HCell store={store} orderBy="type">{t('common.type')}</HCell>
                                        <HCell store={store} orderBy="description">{t('common.note')}</HCell>
                                        <HCell store={store} orderBy="createdAt">{t('common.createDate')}</HCell>
                                        <HCell store={store} orderBy="modifiedAt">{t('common.modifiedDate')}</HCell>
                                        {
                                            !store.isDouble && <HCell width='5%'></HCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <>
                                        {store.items.map((t: OrganizationEmailItemStore) => {
                                            return (
                                                <Fragment key={t.dto.id}>
                                                    <TableRow
                                                        hover
                                                        key={t.dto.id}
                                                        style={{width: '100%'}}
                                                        onClick={() => store.current = t}
                                                    >
                                                        <ExpandButton item={t} isOpen={t.expand}/>
                                                        <Cell testid="organizationEmailsTable-emailNumber">
                                                            {t.dto.email.address}
                                                        </Cell>
                                                        <Cell testid="organizationEmailsTable-emailStatus">
                                                            {t.dto.emailStatus.nameRus}
                                                        </Cell>
                                                        <Cell testid="organizationEmailsTable-emailType">
                                                            {t.dto.emailType.nameRus}
                                                        </Cell>
                                                        <Cell>
                                                            <Typography variant="subtitle2" color="textSecondary">
                                                                {t.dto.description}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell>
                                                            <Typography variant="subtitle2" color="textSecondary">
                                                                {toDateTimeString(t.dto.createdAt)}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell>
                                                            <Typography variant="subtitle2" color="textSecondary">
                                                                {toDateTimeString(t.dto.modifiedAt)}
                                                            </Typography>
                                                        </Cell>
                                                        {
                                                            !store.isDouble &&
                                                            <Cell align={'right'} style={{height: 30}}>
                                                                <AuthorizePermission
                                                                    permissions={AllPermissions.organization.emails.delete}>
                                                                    <DeleteIconButton
                                                                        onClick={() => t.deleteDialogState = true}
                                                                        testid='organizationEmailsTable'/>
                                                                </AuthorizePermission>
                                                            </Cell>
                                                        }

                                                    </TableRow>
                                                    {t.expand && (
                                                        <ExpandRow key={`${t.dto.id}_`} item={t}>
                                                            <OrganizationEmailDetails
                                                                store={t}
                                                            />
                                                        </ExpandRow>)}
                                                </Fragment>
                                            )
                                        })}
                                    </>
                                </TableBody>
                            </Table>
                        </Scrollbar>
                        : <EmptyBlock4></EmptyBlock4>
                }

            </Box>
            <Progress store={store}/>
        </>
    );
});

