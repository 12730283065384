import {makeAutoObservable} from 'mobx';
import {OrganizationItemStore} from "../OrganizationItemStore";
import {
    ClinicCategoriesApi,
    FormPropertiesApi,
    FormPropertyDto,
    OrganizationDobleDto,
    OrganizationDoblesApi,
    OrganizationDto,
    OrganizationsApi,
    OrganizationStatusDto,
    OrganizationStatusesApi,
    TypeOrgDto,
    TypeOrgsApi,
    UpdateOrganizationDto
} from "../../../../services/management";
import {getApiConfiguration, IForm, IProcessing} from "../../../../core";
import {DefaultSelectStore} from "../../../../components/shared/DefaultSelectStore";
import {DefaultValueStore} from "../../../../components/shared/DefaultValueStore";
import i18n from "i18next";
import {OrganizationStatuses} from '../../../../core/const';
import {searchStatusWorkOrgHandler} from '../../../utils';
import {JurisdictionLocalSetting} from "../../../authorization/AuthorizationStore";

export class OrganizationPropertiesStore implements IProcessing, IForm {
    dto: OrganizationDto;
    store: OrganizationItemStore;

    organizationFormProperties: DefaultSelectStore<FormPropertyDto>;
    organizationStatus: DefaultSelectStore<OrganizationStatusDto>;
    parent: DefaultSelectStore<OrganizationDto>;
    organizationType: DefaultSelectStore<TypeOrgDto>;
    itn: DefaultValueStore<string>;
    clinicCategory: DefaultValueStore<string>;
    processing: boolean = false;

    doble: DefaultValueStore<string>;
    defaultDobleId: string = '';
    dobleId: string = '';
    // disabledDoble: boolean = true;
    // disabledIconDoble: boolean = false;
    antiDobleCheck: boolean = false;

    antiDobleId: string = '';
    antiDobleArr: DefaultValueStore<OrganizationDobleDto>[] = [];
    antiDoble: DefaultValueStore<string>;
    defaultAntiDobleId: string = '';
    antiDobleAddCheck: boolean = true;

    constructor(store: OrganizationItemStore, dto: OrganizationDto) {
        this.dto = dto;
        this.store = store;

        this.organizationFormProperties = new DefaultSelectStore(
            dto.formProperty,
            (x) => new FormPropertiesApi(getApiConfiguration()).getFormProperties(x),
            (value) => !value ? i18n.t('errors.empty') : "")

            this.organizationStatus = new DefaultSelectStore(dto.status as OrganizationStatusDto,
                async (filters) => {
                    const statuses = await new OrganizationStatusesApi(getApiConfiguration()).getOrganizationStatuses({
                        ...filters,
                        orderBy: 'name_rus',
                        order: 'ascending'
                    })
                    return searchStatusWorkOrgHandler(statuses, filters)
                },
            (value) => !value ? i18n.t('errors.empty') : (value.id === OrganizationStatuses.Double) ? i18n.t('errors.dobleStatus').replace("dobleStatus", 'Организация') : "", 'id')

        this.parent = new DefaultSelectStore(
            null,
            (filters) => new OrganizationsApi(getApiConfiguration()).getOrganizations(filters),
            (value) => !value ? i18n.t('errors.empty') : "")

        this.organizationType = new DefaultSelectStore(
            dto.typeOrg, (x) => new TypeOrgsApi(getApiConfiguration()).getTypeOrgs({...x, showOnlyActive: true}),
            (value) => !value ? i18n.t('errors.empty') : "")

        this.itn = new DefaultValueStore<string>(dto?.itn?.itn,
            (value) => new RegExp(this.jurisdictionLocalSetting?.itnRegex!).test(value ?? "") ? "" :
                i18n.t('errors.itn').replace("{length}",this.jurisdictionLocalSetting?.intLength!))

        this.clinicCategory = new DefaultValueStore<string>("")

        this.doble = new DefaultValueStore<string>(this.defaultDobleId,
            (value) => !value ? i18n.t('errors.empty') : (value === this.dto.id) ? i18n.t('errors.equalId') : new RegExp(/^\w{8}.\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/).test(value) ? "" : i18n.t('errors.wrongId').replace("wrongId", value));
        this.doble.validatorFuncAsync = this.validFn;

        this.antiDoble = new DefaultValueStore<string>(this.defaultAntiDobleId,
            (value) => !value ? i18n.t('errors.empty') : (value === this.dto.id) ? i18n.t('errors.equalId') : new RegExp(/^\w{8}.\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/).test(value) ? "" : i18n.t('errors.wrongId').replace("wrongId", value));
        this.antiDoble.validatorFuncAsync = this.validFn;

        this.parent.isSearchById = true

        makeAutoObservable(this);
    }

    get equals(): boolean {
        return this.organizationFormProperties.equals &&
            this.organizationStatus.equals &&
            this.organizationType.equals &&
            this.itn.equals &&
            this.doble.equals &&
            this.antiDoble.equals &&
            this.parent.equals
    }

    get jurisdictionLocalSetting(): JurisdictionLocalSetting | undefined {
        return this.store._store.store.store.authorizationStore?.jurisdictionsSettings
    }

    get valid(): boolean {
        return this.organizationFormProperties.valid &&
            this.organizationStatus.valid &&
            this.organizationType.valid &&
            this.itn.valid &&
            // (this.organizationStatus.value?.nameRus.toLowerCase() !== 'дубль' ? this.organizationStatus.valid : this.doble.valid && !!this.doble.value) &&
            (this.organizationStatus.value?.nameRus.toLowerCase() !== 'дубль' ? this.organizationStatus.valid : false) &&
            this.doble.value !== this.dto.id &&
            this.antiDoble.value !== this.dto.id
    }

    get isDouble(): boolean {
        return this.store.isDouble;
    }

    validate() {
        this.organizationFormProperties.validate();
        this.organizationStatus.validate();
        this.organizationType.validate();
        this.doble.validate();
        this.antiDoble.validate();
    }

    update(dto: OrganizationDto) {
        this.dto = dto;
        this.itn.update(dto?.itn?.itn);
        this.organizationFormProperties.update(dto.formProperty);
        this.organizationStatus.update(dto.status);
        this.organizationType.update(dto.typeOrg);
    }

    reset(): void {
        this.organizationFormProperties.reset();
        this.organizationStatus.reset();
        this.organizationType.reset();
        this.itn.reset();
        this.doble.reset();
        this.antiDoble.reset();
        this.parent.reset();
        this.validate();
    }

    async validFn(value: string | null | undefined): Promise<string> {
        if (!value) return "";
        const api: OrganizationsApi = new OrganizationsApi(getApiConfiguration());
        const api_2: OrganizationDoblesApi = new OrganizationDoblesApi(getApiConfiguration());
        try {
            await api.getOrganizationById({ id: value });
            const result = await api_2.getOrganizationDobles({ searchType: 'doble', searchId: value });
            if (!!result.length) {
                console.log('!!result.length')
                return i18n.t('errors.hasDobleId').replace("hasDobleId", value);
            }
            return "";
        } catch (e) {
            return i18n.t('errors.wrongId').replace("wrongId", value);
        }
    }

    onAntiDobleCheck() {
        this.antiDobleArr.length ? this.antiDobleCheck = true : this.antiDobleCheck = !this.antiDobleCheck;
    }

    // onEditDoble() {
    //     this.disabledDoble = !this.disabledDoble;
    //     this.disabledIconDoble = !this.disabledIconDoble;
    // }

    onAntiDobleAddCheck() {
        this.antiDobleAddCheck = !this.antiDobleAddCheck;
    }

    onDefaultAntiDobleId() {
        this.antiDoble = new DefaultValueStore<string>('',
            (value) => !value ? i18n.t('errors.empty') : (value === this.dto.id) ? i18n.t('errors.equalId') : new RegExp(/^\w{8}.\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/).test(value) ? "" : i18n.t('errors.wrongId').replace("wrongId", value));
        this.antiDoble.validatorFuncAsync = this.validFn;
    }

    get changedWithoutDobles(): boolean {
        return this.dto.formPropertyId === this.organizationFormProperties.value?.id &&
            this.dto.typeOrgId === this.organizationType.value?.id &&
            this.itn.value === this.dto.itn.itn
    }

    async pullClinicCategory() {
        const api: ClinicCategoriesApi = new ClinicCategoriesApi(getApiConfiguration());
        const result = await api.getClinicCategoryById({ id: this.dto.typeOrg.clinicCategoryId });
        this.clinicCategory = new DefaultValueStore<string>(result?.nameRus)
    }

    async pullDoble(): Promise<void> {
        const api: OrganizationDoblesApi = new OrganizationDoblesApi(getApiConfiguration());
        const result = await api.getOrganizationDobles({ searchType: 'doble', searchId: this.dto?.id });
        this.defaultDobleId = result[0]?.dobleId ?? '';
        this.dobleId = result[0]?.id ?? '';
        this.doble = new DefaultValueStore<string>(this.defaultDobleId,
            (value) => !value ? i18n.t('errors.empty') : (value === this.dto.id) ? i18n.t('errors.equalId') : new RegExp(/^\w{8}.\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/).test(value) ? "" : i18n.t('errors.wrongId').replace("wrongId", value));
        this.doble.validatorFuncAsync = this.validFn;
        // if (!result.length) {
        //     this.disabledDoble = false
        //     this.disabledIconDoble = true
        // }
    };

    async pullAntiDoble(): Promise<void> {
        const api: OrganizationDoblesApi = new OrganizationDoblesApi(getApiConfiguration());
        const result = await api.getOrganizationDobles({ searchType: 'antiDoble', searchId: this.dto?.id });
        this.antiDobleCheck = !result.length ? false : true;
        this.antiDobleId = result[0]?.id ?? ''
        const newList: DefaultValueStore<OrganizationDobleDto>[] = [];
        for (const item of result) {
            newList.push(new DefaultValueStore<OrganizationDobleDto>(item))
        }
        this.antiDobleArr = [...newList]
        if (this.antiDobleArr.length) this.antiDobleAddCheck = false;
    };

    async submit(): Promise<void> {
        this.validate()
        this.processing = true;
        if (!this.valid) {
            this.processing = false;
            return;
        }
        const api: OrganizationsApi = new OrganizationsApi(getApiConfiguration());
        try {
            const res = await api.updateOrganization({
                id: this.dto.id,
                updateOrganizationDto: {
                    itn: this.itn.value!,
                    parentId: this.dto.parentId,
                    typeOrgId: this.organizationType.value?.id!,
                    formPropertyId: this.organizationFormProperties.value?.id!,
                    statusId: this.organizationStatus.value?.id!,
                    addressUniqueId: this.store.addressesStore.dto.id ?? this.dto?.addressUniqueId
                }
            })
            this.store.update(res)
        }
        catch (e) {

        }
        this.processing = false;
    }

    async updateTick(): Promise<void> {
        this.processing = true;
        const api: OrganizationsApi = new OrganizationsApi(getApiConfiguration());
        try {
            await api.tickOrganization({
                id: this.dto.id,
            })
            await this.store._store.updateState()
        }
        catch (e) {

        }
        this.processing = false;
    }

    async submitDoble(): Promise<void> {
        this.validate()
        if (!this.valid) return
        this.processing = true;
        const api: OrganizationDoblesApi = new OrganizationDoblesApi(getApiConfiguration());
        try {
            const updateOrg = await this.store._store.getOrganizationHuck(this.doble.value!);
            updateOrg?.propertiesStore.pullAntiDoble();
            await api.createOrganizationDoble({
                setOrganizationDobleDto: {
                    organizationId: this.dto.id,
                    dobleId: this.doble.value!,
                }
            })
            await this.submit();
            if (updateOrg?.propertiesStore.organizationStatus.value?.nameRus.toLowerCase() === 'дубль') { this.changeStatus(updateOrg?.propertiesStore.dto.id, 'требует дополнительной валидации') }
            // this.disabledDoble = true;
            // this.disabledIconDoble = false;
            this.pullDoble();
        }
        catch (e) {
            this.store._store.updateState();
        }
        this.processing = false;
    }

    async updateDoble(): Promise<void> {
        this.validate()
        if (!this.valid) return
        this.processing = true;
        const api: OrganizationDoblesApi = new OrganizationDoblesApi(getApiConfiguration());
        try {
            await this.submit();
            await api.updateOrganizationDoble({
                id: this.dobleId,
                setOrganizationDobleDto: {
                    organizationId: this.dto.id,
                    dobleId: this.doble.value!,
                }
            })
            const updateOrg = await this.store._store.getOrganizationHuck(this.doble.value!);
            updateOrg?.propertiesStore.pullAntiDoble();
            if (updateOrg?.propertiesStore.organizationStatus.value?.nameRus.toLowerCase() === 'дубль') this.changeStatus(updateOrg?.propertiesStore.dto.id, 'требует дополнительной валидации')
            // this.disabledDoble = true;
            // this.disabledIconDoble = false;
            this.pullDoble();
        }
        catch (e) {
            this.store._store.updateState();
        }
        this.processing = false;
    }

    async submitAntiDoble(): Promise<void> {
        this.validate()
        if (!this.valid) return
        this.processing = true;
        const api: OrganizationDoblesApi = new OrganizationDoblesApi(getApiConfiguration());
        try {
            await api.createOrganizationDoble({
                setOrganizationDobleDto: {
                    organizationId: this.antiDoble.value!,
                    dobleId: this.dto.id,
                }
            })
            if (!!this.antiDoble.value) { await this.changeStatus(this.antiDoble.value, 'дубль') }
            const updateOrg = await this.store._store.getOrganizationHuck(this.antiDoble.value!);
            updateOrg?.propertiesStore.pullDoble()
            if (!!updateOrg?.propertiesStore.antiDobleArr.length) updateOrg?.propertiesStore.antiDobleArr.forEach((item) => { this.removeDoble(item.value?.id!, item.value?.organizationId!); this.changeStatus(item.value?.organizationId!, 'требует дополнительной валидации') })
            this.onDefaultAntiDobleId()
        }
        catch (e) {
        }
        this.pullAntiDoble()
        this.processing = false;
    }

    async changeStatus(id: string, status: ('дубль' | 'требует дополнительной валидации')) {
        const apiStatus: OrganizationStatusesApi = new OrganizationStatusesApi(getApiConfiguration())
        const allStatus = await apiStatus.getOrganizationStatuses({ size: 100 })
        const dobleStatusId = allStatus.find(i => i?.nameRus.toLowerCase() === status)?.id
        const api: OrganizationsApi = new OrganizationsApi(getApiConfiguration())
        const org = await api.getOrganizationById({ id: id })
        const newOrg: UpdateOrganizationDto = {
            statusId: dobleStatusId ?? org.statusId,
            formPropertyId: org.formPropertyId,
            typeOrgId: org.typeOrgId,
            addressUniqueId: org?.addressUniqueId,
            parentId: org.parentId,
            itn: org.itn.itn,
        }
        await api.updateOrganization({ id: id, updateOrganizationDto: newOrg })
        const updateOrg = await this.store._store.getOrganizationHuck(id);
        updateOrg?._store.updateState();
        // if (!!updateOrg && status === 'дубль') {
        //     updateOrg.propertiesStore.disabledDoble = true;
        //     updateOrg.propertiesStore.disabledIconDoble = false;
        // }
    }

    async handlerSubmit() {
        if (this.organizationStatus.value?.nameRus.toLowerCase() === 'дубль' && !!this.doble.value && !!this.dobleId && this.doble.value !== this.defaultDobleId) { await this.updateDoble(); return; };
        if (this.organizationStatus.value?.nameRus.toLowerCase() === 'дубль' && !!this.doble.value && !this.dobleId && !this.antiDobleArr.length) { await this.submitDoble(); return; };
        if (this.organizationStatus.value?.nameRus.toLowerCase() === 'дубль' && !!this.antiDobleArr.length) {
            this.antiDobleArr.forEach(async (item) => {
                await this.removeDoble(item.value?.id!, item.value?.organizationId!)
                await this.changeStatus(item.value?.organizationId!, 'требует дополнительной валидации')
            })
            await this.submitDoble();
            return;
        }
        if (this.organizationStatus.value?.nameRus.toLowerCase() !== 'дубль' && !!this.dobleId) { await this.removeDoble(this.dobleId, this.defaultDobleId, true); return; };
        if (this.antiDoble.value) {
            await this.submitAntiDoble();
            this.antiDobleAddCheck = false;
            return;
        }
        if (!this.changedWithoutDobles || (this.organizationStatus.value?.nameRus.toLowerCase() !== 'дубль')) { await this.submit(); return; };
    }

    async removeDoble(itemId: string, item: string, doble?: boolean) {
        const api: OrganizationDoblesApi = new OrganizationDoblesApi(getApiConfiguration());
        await api.deleteOrganizationDoble({
            id: itemId
        });
        const updateOrg = await this.store._store.getOrganizationHuck(item);
        if (!!doble) updateOrg?.propertiesStore.pullAntiDoble(); this.pullDoble()
        if (!doble) updateOrg?.propertiesStore.pullDoble(); this.pullAntiDoble()
    }
}
