/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetUserJurisdictionDto,
    SetUserJurisdictionDtoFromJSON,
    SetUserJurisdictionDtoToJSON,
    UserJurisdictionDto,
    UserJurisdictionDtoFromJSON,
    UserJurisdictionDtoToJSON,
} from '../models';

export interface CreateUserJurisdictionsRequest {
    userId: string;
    setUserJurisdictionDto: Array<SetUserJurisdictionDto>;
}

export interface GetUserJurisdictionsRequest {
    userId: string;
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetUserJurisdictionsCountRequest {
    userId: string;
    search?: string;
}

export interface RemoveUserJurisdictionsRequest {
    userId: string;
    setUserJurisdictionDto: Array<SetUserJurisdictionDto>;
}

/**
 * 
 */
export class UserJurisdictionsApi extends runtime.BaseAPI {

    /**
     */
    async createUserJurisdictionsRaw(requestParameters: CreateUserJurisdictionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UserJurisdictionDto>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createUserJurisdictions.');
        }

        if (requestParameters.setUserJurisdictionDto === null || requestParameters.setUserJurisdictionDto === undefined) {
            throw new runtime.RequiredError('setUserJurisdictionDto','Required parameter requestParameters.setUserJurisdictionDto was null or undefined when calling createUserJurisdictions.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/v1/users/jurisdictions/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setUserJurisdictionDto.map(SetUserJurisdictionDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserJurisdictionDtoFromJSON));
    }

    /**
     */
    async createUserJurisdictions(requestParameters: CreateUserJurisdictionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserJurisdictionDto>> {
        const response = await this.createUserJurisdictionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserJurisdictionsRaw(requestParameters: GetUserJurisdictionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UserJurisdictionDto>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserJurisdictions.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/users/jurisdictions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserJurisdictionDtoFromJSON));
    }

    /**
     */
    async getUserJurisdictions(requestParameters: GetUserJurisdictionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserJurisdictionDto>> {
        const response = await this.getUserJurisdictionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserJurisdictionsCountRaw(requestParameters: GetUserJurisdictionsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserJurisdictionsCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/users/jurisdictions/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getUserJurisdictionsCount(requestParameters: GetUserJurisdictionsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getUserJurisdictionsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeUserJurisdictionsRaw(requestParameters: RemoveUserJurisdictionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UserJurisdictionDto>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling removeUserJurisdictions.');
        }

        if (requestParameters.setUserJurisdictionDto === null || requestParameters.setUserJurisdictionDto === undefined) {
            throw new runtime.RequiredError('setUserJurisdictionDto','Required parameter requestParameters.setUserJurisdictionDto was null or undefined when calling removeUserJurisdictions.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/v1/users/jurisdictions/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setUserJurisdictionDto.map(SetUserJurisdictionDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserJurisdictionDtoFromJSON));
    }

    /**
     */
    async removeUserJurisdictions(requestParameters: RemoveUserJurisdictionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserJurisdictionDto>> {
        const response = await this.removeUserJurisdictionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
