/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    AddOrgNameDto,
    AddOrgNameDtoFromJSON,
    AddOrgNameDtoFromJSONTyped,
    AddOrgNameDtoToJSON,
} from './AddOrgNameDto';
import {
    AddOrganizationPhoneDto,
    AddOrganizationPhoneDtoFromJSON,
    AddOrganizationPhoneDtoFromJSONTyped,
    AddOrganizationPhoneDtoToJSON,
} from './AddOrganizationPhoneDto';
import {
    SetAddressUniqueDto,
    SetAddressUniqueDtoFromJSON,
    SetAddressUniqueDtoFromJSONTyped,
    SetAddressUniqueDtoToJSON,
} from './SetAddressUniqueDto';

/**
 * 
 * @export
 * @interface AddOrganizationDto
 */
export interface AddOrganizationDto {
    /**
     * 
     * @type {string}
     * @memberof AddOrganizationDto
     */
    statusId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrganizationDto
     */
    formPropertyId: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrganizationDto
     */
    typeOrgId: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrganizationDto
     */
    addressUniqueId?: string | null;
    /**
     * 
     * @type {SetAddressUniqueDto}
     * @memberof AddOrganizationDto
     */
    address: SetAddressUniqueDto;
    /**
     * 
     * @type {Array<AddOrgNameDto>}
     * @memberof AddOrganizationDto
     */
    names: Array<AddOrgNameDto>;
    /**
     * 
     * @type {string}
     * @memberof AddOrganizationDto
     */
    parentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrganizationDto
     */
    itn?: string | null;
    /**
     * 
     * @type {Array<AddOrganizationPhoneDto>}
     * @memberof AddOrganizationDto
     */
    phones?: Array<AddOrganizationPhoneDto> | null;
}

export function AddOrganizationDtoFromJSON(json: any): AddOrganizationDto {
    return AddOrganizationDtoFromJSONTyped(json, false);
}

export function AddOrganizationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddOrganizationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusId': !exists(json, 'statusId') ? undefined : json['statusId'],
        'formPropertyId': json['formPropertyId'],
        'typeOrgId': json['typeOrgId'],
        'addressUniqueId': !exists(json, 'addressUniqueId') ? undefined : json['addressUniqueId'],
        'address': SetAddressUniqueDtoFromJSON(json['address']),
        'names': ((json['names'] as Array<any>).map(AddOrgNameDtoFromJSON)),
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'itn': !exists(json, 'itn') ? undefined : json['itn'],
        'phones': !exists(json, 'phones') ? undefined : (json['phones'] === null ? null : (json['phones'] as Array<any>).map(AddOrganizationPhoneDtoFromJSON)),
    };
}

export function AddOrganizationDtoToJSON(value?: AddOrganizationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'statusId': value.statusId,
        'formPropertyId': value.formPropertyId,
        'typeOrgId': value.typeOrgId,
        'addressUniqueId': value.addressUniqueId,
        'address': SetAddressUniqueDtoToJSON(value.address),
        'names': ((value.names as Array<any>).map(AddOrgNameDtoToJSON)),
        'parentId': value.parentId,
        'itn': value.itn,
        'phones': value.phones === undefined ? undefined : (value.phones === null ? null : (value.phones as Array<any>).map(AddOrganizationPhoneDtoToJSON)),
    };
}

