import {useManagementStore} from "../../../stores";
import {observer} from 'mobx-react';
import {useTranslation} from "react-i18next";
import {Box, Breadcrumbs, Container, Typography} from "@mui/material";
import {RequestTable} from "./RequestTable";

export const DcrRequest = observer(() => {
    const {t} = useTranslation();
    const {dcrRequestStore} = useManagementStore();
    return (
        <Box>
                <Box
                    sx={{
                        mb: 3
                    }}
                >
                    <Breadcrumbs
                        separator="/"
                    >
                        <Typography color="primary"
                                    variant="h6">{t('pageNames.dcr')}</Typography>

                        <Typography color="textSecondary"
                                    variant="h6">
                            {t('dcrPage.dcrTabs.requests')}
                        </Typography>
                    </Breadcrumbs>
                    <RequestTable requestTableStore={dcrRequestStore}/>
                </Box>
        </Box>
    );
});
