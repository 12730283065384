/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetUserRoleDto,
    SetUserRoleDtoFromJSON,
    SetUserRoleDtoToJSON,
    UserRoleDto,
    UserRoleDtoFromJSON,
    UserRoleDtoToJSON,
} from '../models';

export interface CreateUserRolesRequest {
    userId: string;
    setUserRoleDto: Array<SetUserRoleDto>;
}

export interface GetUserRolesRequest {
    userId: string;
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetUserRolesCountRequest {
    userId: string;
    search?: string;
}

export interface RemoveUserRolesRequest {
    userId: string;
    setUserRoleDto: Array<SetUserRoleDto>;
}

/**
 * 
 */
export class UserRolesApi extends runtime.BaseAPI {

    /**
     */
    async createUserRolesRaw(requestParameters: CreateUserRolesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UserRoleDto>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createUserRoles.');
        }

        if (requestParameters.setUserRoleDto === null || requestParameters.setUserRoleDto === undefined) {
            throw new runtime.RequiredError('setUserRoleDto','Required parameter requestParameters.setUserRoleDto was null or undefined when calling createUserRoles.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/application/users/roles/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setUserRoleDto.map(SetUserRoleDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserRoleDtoFromJSON));
    }

    /**
     */
    async createUserRoles(requestParameters: CreateUserRolesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserRoleDto>> {
        const response = await this.createUserRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserRolesRaw(requestParameters: GetUserRolesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UserRoleDto>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserRoles.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/application/users/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserRoleDtoFromJSON));
    }

    /**
     */
    async getUserRoles(requestParameters: GetUserRolesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserRoleDto>> {
        const response = await this.getUserRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserRolesCountRaw(requestParameters: GetUserRolesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserRolesCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/application/users/roles/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getUserRolesCount(requestParameters: GetUserRolesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getUserRolesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeUserRolesRaw(requestParameters: RemoveUserRolesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<UserRoleDto>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling removeUserRoles.');
        }

        if (requestParameters.setUserRoleDto === null || requestParameters.setUserRoleDto === undefined) {
            throw new runtime.RequiredError('setUserRoleDto','Required parameter requestParameters.setUserRoleDto was null or undefined when calling removeUserRoles.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/application/users/roles/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setUserRoleDto.map(SetUserRoleDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserRoleDtoFromJSON));
    }

    /**
     */
    async removeUserRoles(requestParameters: RemoveUserRolesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<UserRoleDto>> {
        const response = await this.removeUserRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
