import type { FC } from 'react';
import { useRef, useState } from 'react';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Popover, Tooltip } from '@mui/material';
import { DotsHorizontal as DotsHorizontalIcon } from '../icons/dots-horizontal';

export type MenuComponentProps = {
    children: React.ReactNode;
    tooltip?: string;
    icon?: React.ReactNode;
}

export const MenuComponent: FC<MenuComponentProps> = (props) => {
    const { children, tooltip = '', icon = <DotsHorizontalIcon fontSize="small" color={'disabled'} sx={{ m: 1 }} /> } = props;

    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const [openMenu, setOpenMenu] = useState<boolean>(false);

    const handleMenuOpen = (): void => {
        setOpenMenu(true);
    };

    const handleMenuClose = (): void => {
        setOpenMenu(false);
    };

    return (
        <>
            <Tooltip title={tooltip}>
                <IconButton
                    sx={{ p: 0 }}
                    onClick={handleMenuOpen}
                    ref={anchorRef}
                    {...props}
                >
                    {icon}
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                onClose={handleMenuClose}
                onClick={handleMenuClose}
                open={openMenu}
                PaperProps={{
                    sx: {
                        maxWidth: '30%',
                        width: 'auto'
                    }
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
            >
                {children}
            </Menu>
        </>
    );
};
