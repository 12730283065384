import { styled } from "@mui/material";

interface Props {
    children?: React.ReactNode,
    style?: React.CSSProperties
}

export const PagePanel = ({ children, style }: Props) => {
    return (
        <div
            style={{
                flex: 0,
                backgroundColor: '#fff',
                zIndex: 1,
                ...style
            }}
        >
            {children}
        </div>
    );
};

export const PageBody = ({ children, style }: Props) => {
    return (
        <div
            style={{
                flex: 1,
                display: 'flex',
                alignItems: 'flex-start',
                flexWrap: 'nowrap',
                maxHeight: '100%',
                overflowY: 'auto',
                ...style
            }}
        >
            {children}
        </div>
    );
};

export const Page = ({ children, style }: Props) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                maxHeight: '100%',
                ...style
            }}
        >
            {children}
        </div>
    );
};

export const Root = styled("div")({
    display: 'flex'
});

export const Bottom = styled("div")({
    flex: 0,
});

export const Content = styled("div")({
    flex: 1,
    maxHeight: '100%',
    overflowY: 'auto',
});

export const Main = styled("main")({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    justifyItems: 'flex-start',
    height: '100vh',
    overflow: 'auto',
});
