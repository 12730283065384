/* tslint:disable */
/* eslint-disable */
/**
 * Data360.DCR.Analytics
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DcrData360ReportFilter,
    DcrData360ReportFilterFromJSON,
    DcrData360ReportFilterFromJSONTyped,
    DcrData360ReportFilterToJSON,
} from './DcrData360ReportFilter';
import {
    ReportExecutionState,
    ReportExecutionStateFromJSON,
    ReportExecutionStateFromJSONTyped,
    ReportExecutionStateToJSON,
} from './ReportExecutionState';

/**
 * 
 * @export
 * @interface ReportStateDto
 */
export interface ReportStateDto {
    /**
     * 
     * @type {string}
     * @memberof ReportStateDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportStateDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportStateDto
     */
    user?: string | null;
    /**
     * 
     * @type {ReportExecutionState}
     * @memberof ReportStateDto
     */
    state?: ReportExecutionState;
    /**
     * 
     * @type {Date}
     * @memberof ReportStateDto
     */
    formingDateTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReportStateDto
     */
    message?: string | null;
    /**
     * 
     * @type {DcrData360ReportFilter}
     * @memberof ReportStateDto
     */
    filters?: DcrData360ReportFilter;
}

export function ReportStateDtoFromJSON(json: any): ReportStateDto {
    return ReportStateDtoFromJSONTyped(json, false);
}

export function ReportStateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportStateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'state': !exists(json, 'state') ? undefined : ReportExecutionStateFromJSON(json['state']),
        'formingDateTime': !exists(json, 'formingDateTime') ? undefined : (new Date(json['formingDateTime'])),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'filters': !exists(json, 'filters') ? undefined : DcrData360ReportFilterFromJSON(json['filters']),
    };
}

export function ReportStateDtoToJSON(value?: ReportStateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fileName': value.fileName,
        'user': value.user,
        'state': ReportExecutionStateToJSON(value.state),
        'formingDateTime': value.formingDateTime === undefined ? undefined : (value.formingDateTime.toISOString()),
        'message': value.message,
        'filters': DcrData360ReportFilterToJSON(value.filters),
    };
}

