import { createSvgIcon } from '@mui/material/utils';

export const DcrGroupHome = createSvgIcon(
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 10L4.16667 8.33333M4.16667 8.33333L10 2.5L15.8333 8.33333M4.16667 8.33333V16.6667C4.16667 16.8877 4.25446 17.0996 4.41074 17.2559C4.56702 17.4122 4.77899 17.5 5 17.5H7.5M15.8333 8.33333L17.5 10M15.8333 8.33333V16.6667C15.8333 16.8877 15.7455 17.0996 15.5893 17.2559C15.433 17.4122 15.221 17.5 15 17.5H12.5M7.5 17.5C7.72101 17.5 7.93298 17.4122 8.08926 17.2559C8.24554 17.0996 8.33333 16.8877 8.33333 16.6667V13.3333C8.33333 13.1123 8.42113 12.9004 8.57741 12.7441C8.73369 12.5878 8.94565 12.5 9.16667 12.5H10.8333C11.0543 12.5 11.2663 12.5878 11.4226 12.7441C11.5789 12.9004 11.6667 13.1123 11.6667 13.3333V16.6667C11.6667 16.8877 11.7545 17.0996 11.9107 17.2559C12.067 17.4122 12.279 17.5 12.5 17.5M7.5 17.5H12.5" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

    ,
'DcrGroupHome'
);
