/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressUniqueDto,
    AddressUniqueDtoFromJSON,
    AddressUniqueDtoFromJSONTyped,
    AddressUniqueDtoToJSON,
} from './AddressUniqueDto';
import {
    FormPropertyDto,
    FormPropertyDtoFromJSON,
    FormPropertyDtoFromJSONTyped,
    FormPropertyDtoToJSON,
} from './FormPropertyDto';
import {
    ITNDto,
    ITNDtoFromJSON,
    ITNDtoFromJSONTyped,
    ITNDtoToJSON,
} from './ITNDto';
import {
    NameDto,
    NameDtoFromJSON,
    NameDtoFromJSONTyped,
    NameDtoToJSON,
} from './NameDto';
import {
    OrgNameDto,
    OrgNameDtoFromJSON,
    OrgNameDtoFromJSONTyped,
    OrgNameDtoToJSON,
} from './OrgNameDto';
import {
    OrganizationEmailDto,
    OrganizationEmailDtoFromJSON,
    OrganizationEmailDtoFromJSONTyped,
    OrganizationEmailDtoToJSON,
} from './OrganizationEmailDto';
import {
    OrganizationPhoneDto,
    OrganizationPhoneDtoFromJSON,
    OrganizationPhoneDtoFromJSONTyped,
    OrganizationPhoneDtoToJSON,
} from './OrganizationPhoneDto';
import {
    OrganizationStatusDto,
    OrganizationStatusDtoFromJSON,
    OrganizationStatusDtoFromJSONTyped,
    OrganizationStatusDtoToJSON,
} from './OrganizationStatusDto';
import {
    OrganizationWebsiteDto,
    OrganizationWebsiteDtoFromJSON,
    OrganizationWebsiteDtoFromJSONTyped,
    OrganizationWebsiteDtoToJSON,
} from './OrganizationWebsiteDto';
import {
    TypeOrgDto,
    TypeOrgDtoFromJSON,
    TypeOrgDtoFromJSONTyped,
    TypeOrgDtoToJSON,
} from './TypeOrgDto';

/**
 * 
 * @export
 * @interface OrganizationDto
 */
export interface OrganizationDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    externalId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationDto
     */
    createdAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationDto
     */
    modifiedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganizationDto
     */
    ticks?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    statusId: string;
    /**
     * 
     * @type {NameDto}
     * @memberof OrganizationDto
     */
    status: NameDto;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    formPropertyId: string;
    /**
     * 
     * @type {FormPropertyDto}
     * @memberof OrganizationDto
     */
    formProperty: FormPropertyDto;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    typeOrgId: string;
    /**
     * 
     * @type {TypeOrgDto}
     * @memberof OrganizationDto
     */
    typeOrg: TypeOrgDto;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    addressUniqueId: string;
    /**
     * 
     * @type {AddressUniqueDto}
     * @memberof OrganizationDto
     */
    addressUnique: AddressUniqueDto;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    parentId: string;
    /**
     * 
     * @type {Array<OrgNameDto>}
     * @memberof OrganizationDto
     */
    names: Array<OrgNameDto>;
    /**
     * 
     * @type {OrganizationDto}
     * @memberof OrganizationDto
     */
    parent: OrganizationDto;
    /**
     * 
     * @type {ITNDto}
     * @memberof OrganizationDto
     */
    itn: ITNDto;
    /**
     * 
     * @type {Array<OrganizationPhoneDto>}
     * @memberof OrganizationDto
     */
    phones: Array<OrganizationPhoneDto>;
    /**
     * 
     * @type {OrganizationStatusDto}
     * @memberof OrganizationDto
     */
    organizationStatus: OrganizationStatusDto;
    /**
     * 
     * @type {Array<OrganizationEmailDto>}
     * @memberof OrganizationDto
     */
    emails: Array<OrganizationEmailDto>;
    /**
     * 
     * @type {Array<OrganizationWebsiteDto>}
     * @memberof OrganizationDto
     */
    websites: Array<OrganizationWebsiteDto>;
    /**
     * 
     * @type {number}
     * @memberof OrganizationDto
     */
    confidenceFactor?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationDto
     */
    poweredByElastic?: boolean;
}

export function OrganizationDtoFromJSON(json: any): OrganizationDto {
    return OrganizationDtoFromJSONTyped(json, false);
}

export function OrganizationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (json['modifiedAt'] === null ? null : new Date(json['modifiedAt'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'ticks': !exists(json, 'ticks') ? undefined : json['ticks'],
        'statusId': json['statusId'],
        'status': NameDtoFromJSON(json['status']),
        'formPropertyId': json['formPropertyId'],
        'formProperty': FormPropertyDtoFromJSON(json['formProperty']),
        'typeOrgId': json['typeOrgId'],
        'typeOrg': TypeOrgDtoFromJSON(json['typeOrg']),
        'addressUniqueId': json['addressUniqueId'],
        'addressUnique': AddressUniqueDtoFromJSON(json['addressUnique']),
        'parentId': json['parentId'],
        'names': ((json['names'] as Array<any>).map(OrgNameDtoFromJSON)),
        'parent': OrganizationDtoFromJSON(json['parent']),
        'itn': ITNDtoFromJSON(json['itn']),
        'phones': ((json['phones'] as Array<any>).map(OrganizationPhoneDtoFromJSON)),
        'organizationStatus': OrganizationStatusDtoFromJSON(json['organizationStatus']),
        'emails': ((json['emails'] as Array<any>).map(OrganizationEmailDtoFromJSON)),
        'websites': ((json['websites'] as Array<any>).map(OrganizationWebsiteDtoFromJSON)),
        'confidenceFactor': !exists(json, 'confidenceFactor') ? undefined : json['confidenceFactor'],
        'poweredByElastic': !exists(json, 'poweredByElastic') ? undefined : json['poweredByElastic'],
    };
}

export function OrganizationDtoToJSON(value?: OrganizationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalId': value.externalId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt === null ? null : value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt === null ? null : value.modifiedAt.toISOString()),
        'modifiedBy': value.modifiedBy,
        'ticks': value.ticks,
        'statusId': value.statusId,
        'status': NameDtoToJSON(value.status),
        'formPropertyId': value.formPropertyId,
        'formProperty': FormPropertyDtoToJSON(value.formProperty),
        'typeOrgId': value.typeOrgId,
        'typeOrg': TypeOrgDtoToJSON(value.typeOrg),
        'addressUniqueId': value.addressUniqueId,
        'addressUnique': AddressUniqueDtoToJSON(value.addressUnique),
        'parentId': value.parentId,
        'names': ((value.names as Array<any>).map(OrgNameDtoToJSON)),
        'parent': OrganizationDtoToJSON(value.parent),
        'itn': ITNDtoToJSON(value.itn),
        'phones': ((value.phones as Array<any>).map(OrganizationPhoneDtoToJSON)),
        'organizationStatus': OrganizationStatusDtoToJSON(value.organizationStatus),
        'emails': ((value.emails as Array<any>).map(OrganizationEmailDtoToJSON)),
        'websites': ((value.websites as Array<any>).map(OrganizationWebsiteDtoToJSON)),
        'confidenceFactor': value.confidenceFactor,
        'poweredByElastic': value.poweredByElastic,
    };
}

