import type {FC} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import {Button, IconButton, useTheme} from '@mui/material';
import {Simulate} from "react-dom/test-utils";
import {LanguagePopover} from "../dashboard/language-popover";
import {useSettings} from "../../core/hooks/use-settings";

interface SettingsButtonProps {
    onClick?: () => void;
}

export const SettingsButton: FC<SettingsButtonProps> = (props) => {
    const theme = useTheme();
    const settings = useSettings();

    return (

        <>
            <IconButton
                onClick={props.onClick}
                sx={{ml: 1,color: settings.layout == 'vertical' ? theme.palette.text.primary : theme.palette.text.secondary}}
            >
                <Box
                    sx={{
                        display: 'flex',
                        height: 25,
                        width: 25,
                        '& svg': {
                            width: '100%',
                            height: '100%'
                        }
                    }}
                >
                    <SvgIcon>
                        <TuneRoundedIcon/>
                    </SvgIcon>
                </Box>
            </IconButton>
        </>
    );
}

SettingsButton.propTypes = {
    onClick: PropTypes.func
};
