/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    ListSortDirection,
    PersonPhoneDto,
    PersonPhoneDtoFromJSON,
    SetPersonPhoneDto,
    SetPersonPhoneDtoToJSON,
    UpdatePersonPhoneDto,
    UpdatePersonPhoneDtoToJSON,
} from '../models';

export interface GetPersonPhoneByIdRequest {
    id: string;
}

export interface GetPersonPhonesRequest {
    search?: string;
    personId?: string;
    description?: string;
    phoneCategoryIds?: Array<string>;
    phoneStatusIds?: Array<string>;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetPersonPhonesCountRequest {
    search?: string;
    description?: string;
    phoneCategoryIds?: Array<string>;
    phoneStatusIds?: Array<string>;
    personId?: string;
}

export interface RemovePersonPhonesRequest {
    personId: string;
    setPersonPhoneDto: Array<SetPersonPhoneDto>;
}

export interface SetPersonPhonesRequest {
    personId: string;
    setPersonPhoneDto: Array<SetPersonPhoneDto>;
}

export interface UpdatePersonPhoneRequest {
    id: string;
    updatePersonPhoneDto: UpdatePersonPhoneDto;
}

/**
 * 
 */
export class PersonPhonesApi extends runtime.BaseAPI {

    /**
     */
    async getPersonPhoneByIdRaw(requestParameters: GetPersonPhoneByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonPhoneDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPersonPhoneById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/Persons/phones/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonPhoneDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonPhoneById(requestParameters: GetPersonPhoneByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonPhoneDto> {
        const response = await this.getPersonPhoneByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonPhonesRaw(requestParameters: GetPersonPhonesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonPhoneDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.phoneCategoryIds) {
            queryParameters['phoneCategoryIds'] = requestParameters.phoneCategoryIds;
        }

        if (requestParameters.phoneStatusIds) {
            queryParameters['phoneStatusIds'] = requestParameters.phoneStatusIds;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/Persons/phones`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonPhoneDtoFromJSON));
    }

    /**
     */
    async getPersonPhones(requestParameters: GetPersonPhonesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonPhoneDto>> {
        const response = await this.getPersonPhonesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonPhonesCountRaw(requestParameters: GetPersonPhonesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.phoneCategoryIds) {
            queryParameters['phoneCategoryIds'] = requestParameters.phoneCategoryIds;
        }

        if (requestParameters.phoneStatusIds) {
            queryParameters['phoneStatusIds'] = requestParameters.phoneStatusIds;
        }

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/Persons/phones/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonPhonesCount(requestParameters: GetPersonPhonesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPersonPhonesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removePersonPhonesRaw(requestParameters: RemovePersonPhonesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonPhoneDto>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling removePersonPhones.');
        }

        if (requestParameters.setPersonPhoneDto === null || requestParameters.setPersonPhoneDto === undefined) {
            throw new runtime.RequiredError('setPersonPhoneDto','Required parameter requestParameters.setPersonPhoneDto was null or undefined when calling removePersonPhones.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['PersonId'] = requestParameters.personId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/Persons/phones/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setPersonPhoneDto.map(SetPersonPhoneDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonPhoneDtoFromJSON));
    }

    /**
     */
    async removePersonPhones(requestParameters: RemovePersonPhonesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonPhoneDto>> {
        const response = await this.removePersonPhonesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setPersonPhonesRaw(requestParameters: SetPersonPhonesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonPhoneDto>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling setPersonPhones.');
        }

        if (requestParameters.setPersonPhoneDto === null || requestParameters.setPersonPhoneDto === undefined) {
            throw new runtime.RequiredError('setPersonPhoneDto','Required parameter requestParameters.setPersonPhoneDto was null or undefined when calling setPersonPhones.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/Persons/phones/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setPersonPhoneDto.map(SetPersonPhoneDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonPhoneDtoFromJSON));
    }

    /**
     */
    async setPersonPhones(requestParameters: SetPersonPhonesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonPhoneDto>> {
        const response = await this.setPersonPhonesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePersonPhoneRaw(requestParameters: UpdatePersonPhoneRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonPhoneDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePersonPhone.');
        }

        if (requestParameters.updatePersonPhoneDto === null || requestParameters.updatePersonPhoneDto === undefined) {
            throw new runtime.RequiredError('updatePersonPhoneDto','Required parameter requestParameters.updatePersonPhoneDto was null or undefined when calling updatePersonPhone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/Persons/phones/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePersonPhoneDtoToJSON(requestParameters.updatePersonPhoneDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonPhoneDtoFromJSON(jsonValue));
    }

    /**
     */
    async updatePersonPhone(requestParameters: UpdatePersonPhoneRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonPhoneDto> {
        const response = await this.updatePersonPhoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
