/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RequestStatus = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8
} as const;
export type RequestStatus = typeof RequestStatus[keyof typeof RequestStatus];


export function RequestStatusFromJSON(json: any): RequestStatus {
    return RequestStatusFromJSONTyped(json, false);
}

export function RequestStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestStatus {
    return json as RequestStatus;
}

export function RequestStatusToJSON(value?: RequestStatus | null): any {
    return value as any;
}

