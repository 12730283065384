import {Box, Card, CardHeader, Stack, Typography} from "@mui/material";
import {FC, ReactNode} from "react";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles/createTheme";
import * as React from "react";

interface Overview3Props {
    title:  React.ReactNode;
    description: string | number;
    subTitle: string;
    subDescription: string | number;
    icon: ReactNode;
    children?: ReactNode;
    sx?: SxProps<Theme>;
}

export const Overview3: FC<Overview3Props> = (props) => {

    return (
        <Card sx={{ flexBasis: '50%',...props.sx }}>
            <CardHeader
                subheader={<Typography
                    color="text.secondary"
                    sx={{ mb: 1 }}
                    variant="h5"
                >{props.description}</Typography>}
                sx={{ pb: 0 }}
                title={(
                    <Typography
                        color="text.secondary"
                        sx={{ mb: 1 }}
                        variant="h6"
                    >
                        <Typography
                            color="text.primary"
                            component="span"
                            variant="inherit"
                        >
                            {props.title}
                        </Typography>
                    </Typography>
                )}
            />
            {props.children}
            <Box
                sx={{
                    pb: 2,
                    px: 2
                }}
            >
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                >
                    {props.icon}
                    <div>
                        <Typography variant="subtitle2">
                            {props.subTitle}
                        </Typography>
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={0.5}
                        >
                            <Typography
                                color="inherit"
                                variant="body2"
                            >
                                {props.subDescription}
                            </Typography>
                        </Stack>
                    </div>
                </Stack>
            </Box>
        </Card>
    );
};
