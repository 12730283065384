import {ApexOptions} from "apexcharts";
import {useTheme} from "@mui/material";

export const useRequestByDateChartOptions = (color: string, labels: string[]): ApexOptions => {
    const theme = useTheme();

    return {
        chart: {
            background: 'transparent',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        colors: [color],
        dataLabels: {
            enabled: false
        },
        fill: {
            gradient: {
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 100]
            },
            type: 'gradient'
        },
        grid: {
            show: false,
            padding: {
                bottom: 0,
                left: 0,
                right: 0,
                top: 0
            }
        },
        stroke: {
            width: 2
        },
        theme: {
            mode: theme.palette.mode
        },
        tooltip: {
            enabled: false
        },
        xaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            labels: {
                show: false
            },
        },
        yaxis: {
            show: false
        }
    };
};
