import {getApiConfiguration} from '../../../../../core';
import {SpecialitiesApi} from '../../../../../services/management';
import {DictionaryCreateEntity, DictionaryEntity, DictionaryTableStore} from "../../DictionaryTableStore";
import {DictionaryPersonStore} from "../DictionaryPersonStore";

export class SpecialityTableStore extends DictionaryTableStore {

    isActiveStateUsed = true;

    constructor(store: DictionaryPersonStore) {
        super(store, 'speciality-store');
    }

    async Create(model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new SpecialitiesApi(getApiConfiguration()).createSpeciality({
            setSpecialityDto: model
        });
    }

    async Delete(id: string): Promise<DictionaryEntity> {
        return await new SpecialitiesApi(getApiConfiguration()).deleteSpeciality({id: id});
    }

    async GetById(id: string): Promise<DictionaryEntity> {
        return await new SpecialitiesApi(getApiConfiguration()).getSpecialityById({id: id});
    }

    async GetCount(search: string): Promise<{ count: number }> {
        return await new SpecialitiesApi(getApiConfiguration()).getSpecialitiesCount({search: search});
    }

    async GetList(filters: any): Promise<DictionaryEntity[]> {
        return await new SpecialitiesApi(getApiConfiguration()).getSpecialities(filters)
    }

    async Update(id: string, model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new SpecialitiesApi(getApiConfiguration()).updateSpeciality({id: id, setSpecialityDto: model});
    }
}
