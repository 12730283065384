import {getApiConfiguration} from '../../../../../core';
import {PersonPhoneStatusesApi} from "../../../../../services/management";
import {DictionaryCreateEntity, DictionaryEntity, DictionaryTableStore} from "../../DictionaryTableStore";
import {DictionaryPersonStore} from "../DictionaryPersonStore";

export class PersonPhoneStatusTableStore extends DictionaryTableStore {
    constructor(store: DictionaryPersonStore) {
        super(store, 'phone-status-store');
    }

    async Create(model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new PersonPhoneStatusesApi(getApiConfiguration()).createPersonPhoneStatus({setPhoneStatusDto: model});
    }

    async Delete(id: string): Promise<DictionaryEntity> {
        return await new PersonPhoneStatusesApi(getApiConfiguration()).deletePersonPhoneStatus({id: id});
    }

    async GetCount(search: string): Promise<{ count: number }> {
        return await new PersonPhoneStatusesApi(getApiConfiguration()).getPersonPhoneStatusesCount({search: search});
    }

    async GetList(filters: any): Promise<DictionaryEntity[]> {
        return await new PersonPhoneStatusesApi(getApiConfiguration()).getPersonPhoneStatuses(filters)
    }

    async Update(id: string, model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new PersonPhoneStatusesApi(getApiConfiguration()).updatePersonPhoneStatus({id: id, setPhoneStatusDto: model});
    }
}
