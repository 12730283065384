import {observer} from 'mobx-react';
import {Autocomplete, Box, Popper, TextField} from '@mui/material';
import React from 'react';
import {getValueInObject} from "../../../core/getValueInObject";
import {useRootStore} from "../../../stores";
import {NavLink} from "../../NavLink";
import {Link as LinkIcon} from "../../../icons/link";
import {DefaultSelectStore} from "../DefaultSelectStore";
import {useTranslation} from "react-i18next";


interface AcOption {
    code: string,
    description: string
}

type Variant = 'filled' | 'outlined' | 'standard';

interface Props<T> {
    store: DefaultSelectStore<T>;
    style?: React.CSSProperties;
    title: string;
    nameExp?: string | ((dto: T) => string);
    valueExp?: string;
    filterOptions?: (value: T[]) => T[];
    onClear?: () => void;
    onValue?: () => void;
    emptyValue?: string;
    disabled?: boolean;
    zIndex?: number;
    hasTitle?: boolean;
    hasLabel?: boolean;
    variant?: Variant;
    testid?: string;
    disableClearable?: boolean;
    link?: string;
    autoFocus?: boolean;
}

export const DefaultSelect = observer(({
                                           store,
                                           style,
                                           title,
                                           nameExp = 'nameRus',
                                           valueExp = 'id',
                                           filterOptions,
                                           onClear,
                                           onValue,
                                           emptyValue,
                                           disabled,
                                           zIndex = 1300,
                                           hasTitle = true,
                                           hasLabel = false,
                                           variant = 'outlined',
                                           testid,
                                           disableClearable = false,
                                           link,
                                           autoFocus = false
                                       }: Props<any>) => {
    const {themeColorStore} = useRootStore();
    const divider = themeColorStore.themeSettings === 'dark' ? '#2D3748' : '#E6E8F0';
    const {t} = useTranslation();


    const error = store.error;

    return (
        <Autocomplete
            disableClearable={disableClearable}
            noOptionsText={t(store.loading ? "common.download" : "ui.noOptions")}
            className={disabled ? 'Autocomplete-disabled' : ''}
            options={!!emptyValue ? store?.items : store?.items.filter(x => !!getValueInObject(x, nameExp))}
            getOptionLabel={x => (!!emptyValue ? (getValueInObject(x, nameExp) || emptyValue) : getValueInObject(x, nameExp)) + (x.isInactive ? ` (${t("common.noActive")})` : "")}
            PopperComponent={({style, ...props}) => (
                <Popper
                    placement="bottom"
                    {...props}
                    // style={{...style, zIndex: 1300, top: 10, marginTop: 10 }}
                    style={{...style, zIndex: zIndex}}
                    sx={{
                        borderWidth: 1,
                        borderColor: '#ac0',
                    }}

                />
            )}
            ListboxProps={{
                style: {
                    maxHeight: '30vh',
                    borderColor: divider,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderRadius: 8,
                }
            }}
            // ListboxProps={{ style: { backgroundColor: 'red', overflowY: 'hidden'}}}
            clearOnBlur
            readOnly={disabled}
            autoComplete
            onClose={x => {
                store.items = []
                store.setSearch("")
            }}
            fullWidth
            loading={store.loading}
            loadingText={t("common.download")}
            filterOptions={t => filterOptions ? filterOptions(t) : t}
            value={store.value}
            disabled={store.processing}
            style={{...style, ...{display: 'flex'}}}
            isOptionEqualToValue={(option, value) => option[valueExp] === value[valueExp]}
            onChange={(e, t) => {
                store.value = t
                onValue && onValue()
                if (!t) {
                    onClear && onClear()
                    store.search = ''
                }
            }}
            onOpen={async () => {
                store.items = []
                await store.pull()
            }}
            onInputChange={(e, t) => {
                if (e?.type === 'change') {
                    store.setSearch(t);
                }
            }}
            renderOption={(props: any, option: any) => (
                <li {...props} {...{'data-testid': `${testid}-selectList-select`}}>
                    <p style={{margin: 0}}>
                        {(!!emptyValue ? (getValueInObject(option, nameExp) || emptyValue) : getValueInObject(option, nameExp)) + (option.isInactive ? ` (${t("common.noActive")})` : "")}
                    </p>
                </li>
            )}
            renderInput={(params) =>
                <>
                    {(store.value && !!link) &&
                        <Box
                            style={{display: 'flex'}}
                            sx={{mr: 1}}>
                            <NavLink
                                to={link}
                                target="_blank">
                                <LinkIcon
                                    fontSize="small"/>
                            </NavLink>
                        </Box>
                    }
                    <TextField
                        {...params}
                        autoFocus={autoFocus}
                        disabled={disabled}
                        title={hasTitle ? title : undefined}
                        margin='dense'
                        size="small"
                        error={Boolean(error)}
                        helperText={error}
                        placeholder={hasLabel ? undefined : title}
                        // variant="standard"
                        InputLabelProps={{
                            sx: {
                                top: 3
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,
                            ...{'data-testid': `${testid}-select`},
                            // disableUnderline: !hasTitle,
                            sx: {
                                fieldset: {
                                    height: 48,
                                },
                            }
                        }}

                        label={hasLabel ? title : undefined}
                        variant={variant}
                        autoComplete='off'
                    />
                </>

            }
        />
    );


});
