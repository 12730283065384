/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetBrickDto
 */
export interface SetBrickDto {
    /**
     * 
     * @type {string}
     * @memberof SetBrickDto
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof SetBrickDto
     */
    localityId: string;
    /**
     * 
     * @type {string}
     * @memberof SetBrickDto
     */
    districtId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetBrickDto
     */
    okrugId?: string | null;
}

export function SetBrickDtoFromJSON(json: any): SetBrickDto {
    return SetBrickDtoFromJSONTyped(json, false);
}

export function SetBrickDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetBrickDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'localityId': json['localityId'],
        'districtId': !exists(json, 'districtId') ? undefined : json['districtId'],
        'okrugId': !exists(json, 'okrugId') ? undefined : json['okrugId'],
    };
}

export function SetBrickDtoToJSON(value?: SetBrickDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'localityId': value.localityId,
        'districtId': value.districtId,
        'okrugId': value.okrugId,
    };
}

