/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddCommentRequestCommand,
    AddCommentRequestCommandToJSON,
    CommentDto,
    CommentDtoFromJSON,
    DcrStatus,
    ListSortDirection,
    RequestDetailsModel,
    RequestDetailsModelFromJSON,
    RequestDto,
    RequestDtoFromJSON,
    RequestDtoPagedResponse,
    RequestDtoPagedResponseFromJSON,
    RequestRecordType,
    RequestStatus,
    SummaryChangeRequestStatusCommand,
    SummaryChangeRequestStatusCommandToJSON,
    TakeRequestResponsibleCommand,
    TakeRequestResponsibleCommandToJSON,
    UnTakeRequestResponsibleCommand,
    UnTakeRequestResponsibleCommandToJSON,
} from '../models';

export interface ApiDcrV1RequestsCheckRequestPutRequest {
    requestId?: string;
}

export interface ApiDcrV1RequestsCommentsPutRequest {
    addCommentRequestCommand?: AddCommentRequestCommand;
}

export interface ApiDcrV1RequestsDetailsRequestIdGetRequest {
    requestId: string;
}

export interface ApiDcrV1RequestsGetRequest {
    page?: number;
    take?: number;
    groupId?: string;
    dcrStatus?: Array<DcrStatus>;
    requestRecordType?: Array<RequestRecordType>;
    requestStatus?: Array<RequestStatus>;
    client?: Array<string>;
    responsible?: Array<string>;
    orderField?: string;
    order?: ListSortDirection;
    isAcceptedByMe?: boolean;
    isEscalation?: boolean;
    searchById?: string;
    searchByExternalId?: string;
    searchByCrmId?: string;
    searchByData360Id?: string;
    isOnlyCorrect?: boolean;
    startDate?: Date;
    endDate?: Date;
    azType?: Array<string>;
    searchByExternalIdTmp?: string;
    searchByBoundIdTmp?: string;
    searchByCrmIdTmp?: string;
    jurisdictionId?: string;
}

export interface ApiDcrV1RequestsGetNotVerifiedRequestIdGetRequest {
    crmId?: string;
    boundId?: string;
    entityType?: RequestRecordType;
}

export interface ApiDcrV1RequestsIdCommentsGetRequest {
    id: string;
}

export interface ApiDcrV1RequestsIdDeleteRequest {
    id: string;
}

export interface ApiDcrV1RequestsIdGetRequest {
    id?: string;
    isInternal?: boolean;
}

export interface ApiDcrV1RequestsSummaryChangePostRequest {
    summaryChangeRequestStatusCommand?: SummaryChangeRequestStatusCommand;
}

export interface ApiDcrV1RequestsTakePostRequest {
    takeRequestResponsibleCommand?: TakeRequestResponsibleCommand;
}

export interface ApiDcrV1RequestsUntakePostRequest {
    unTakeRequestResponsibleCommand?: UnTakeRequestResponsibleCommand;
}

export interface ApiDcrV1RequestsUpdateEmptyFieldsDataPutRequest {
    requestId?: string;
    emptyFields?: Array<string>;
}

/**
 * 
 */
export class RequestApi extends runtime.BaseAPI {

    /**
     */
    async apiDcrV1RequestsCheckRequestPutRaw(requestParameters: ApiDcrV1RequestsCheckRequestPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RequestDto>> {
        const queryParameters: any = {};

        if (requestParameters.requestId !== undefined) {
            queryParameters['requestId'] = requestParameters.requestId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/requests/check-request`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDcrV1RequestsCheckRequestPut(requestParameters: ApiDcrV1RequestsCheckRequestPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RequestDto> {
        const response = await this.apiDcrV1RequestsCheckRequestPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1RequestsCommentsPutRaw(requestParameters: ApiDcrV1RequestsCommentsPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/dcr/v1/requests/comments`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddCommentRequestCommandToJSON(requestParameters.addCommentRequestCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiDcrV1RequestsCommentsPut(requestParameters: ApiDcrV1RequestsCommentsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.apiDcrV1RequestsCommentsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1RequestsDetailsRequestIdGetRaw(requestParameters: ApiDcrV1RequestsDetailsRequestIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RequestDetailsModel>> {
        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling apiDcrV1RequestsDetailsRequestIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/requests/details/{requestId}`.replace(`{${"requestId"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestDetailsModelFromJSON(jsonValue));
    }

    /**
     */
    async apiDcrV1RequestsDetailsRequestIdGet(requestParameters: ApiDcrV1RequestsDetailsRequestIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RequestDetailsModel> {
        const response = await this.apiDcrV1RequestsDetailsRequestIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1RequestsGetRaw(requestParameters: ApiDcrV1RequestsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RequestDtoPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.take !== undefined) {
            queryParameters['Take'] = requestParameters.take;
        }

        if (requestParameters.groupId !== undefined) {
            queryParameters['GroupId'] = requestParameters.groupId;
        }

        if (requestParameters.dcrStatus) {
            queryParameters['DcrStatus'] = requestParameters.dcrStatus;
        }

        if (requestParameters.requestRecordType) {
            queryParameters['RequestRecordType'] = requestParameters.requestRecordType;
        }

        if (requestParameters.requestStatus) {
            queryParameters['RequestStatus'] = requestParameters.requestStatus;
        }

        if (requestParameters.client) {
            queryParameters['Client'] = requestParameters.client;
        }

        if (requestParameters.responsible) {
            queryParameters['Responsible'] = requestParameters.responsible;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['OrderField'] = requestParameters.orderField;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['Order'] = requestParameters.order;
        }

        if (requestParameters.isAcceptedByMe !== undefined) {
            queryParameters['IsAcceptedByMe'] = requestParameters.isAcceptedByMe;
        }

        if (requestParameters.isEscalation !== undefined) {
            queryParameters['IsEscalation'] = requestParameters.isEscalation;
        }

        if (requestParameters.searchById !== undefined) {
            queryParameters['SearchById'] = requestParameters.searchById;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['SearchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.searchByCrmId !== undefined) {
            queryParameters['SearchByCrmId'] = requestParameters.searchByCrmId;
        }

        if (requestParameters.searchByData360Id !== undefined) {
            queryParameters['SearchByData360Id'] = requestParameters.searchByData360Id;
        }

        if (requestParameters.isOnlyCorrect !== undefined) {
            queryParameters['IsOnlyCorrect'] = requestParameters.isOnlyCorrect;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.azType) {
            queryParameters['AzType'] = requestParameters.azType;
        }

        if (requestParameters.searchByExternalIdTmp !== undefined) {
            queryParameters['SearchByExternalIdTmp'] = requestParameters.searchByExternalIdTmp;
        }

        if (requestParameters.searchByBoundIdTmp !== undefined) {
            queryParameters['SearchByBoundIdTmp'] = requestParameters.searchByBoundIdTmp;
        }

        if (requestParameters.searchByCrmIdTmp !== undefined) {
            queryParameters['SearchByCrmIdTmp'] = requestParameters.searchByCrmIdTmp;
        }

        if (requestParameters.jurisdictionId !== undefined) {
            queryParameters['JurisdictionId'] = requestParameters.jurisdictionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestDtoPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDcrV1RequestsGet(requestParameters: ApiDcrV1RequestsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RequestDtoPagedResponse> {
        const response = await this.apiDcrV1RequestsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1RequestsGetNotVerifiedRequestIdGetRaw(requestParameters: ApiDcrV1RequestsGetNotVerifiedRequestIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.crmId !== undefined) {
            queryParameters['CrmId'] = requestParameters.crmId;
        }

        if (requestParameters.boundId !== undefined) {
            queryParameters['BoundId'] = requestParameters.boundId;
        }

        if (requestParameters.entityType !== undefined) {
            queryParameters['EntityType'] = requestParameters.entityType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/requests/GetNotVerifiedRequestId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiDcrV1RequestsGetNotVerifiedRequestIdGet(requestParameters: ApiDcrV1RequestsGetNotVerifiedRequestIdGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.apiDcrV1RequestsGetNotVerifiedRequestIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1RequestsIdCommentsGetRaw(requestParameters: ApiDcrV1RequestsIdCommentsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<CommentDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDcrV1RequestsIdCommentsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/requests/{id}/comments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CommentDtoFromJSON));
    }

    /**
     */
    async apiDcrV1RequestsIdCommentsGet(requestParameters: ApiDcrV1RequestsIdCommentsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<CommentDto>> {
        const response = await this.apiDcrV1RequestsIdCommentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1RequestsIdDeleteRaw(requestParameters: ApiDcrV1RequestsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling apiDcrV1RequestsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiDcrV1RequestsIdDelete(requestParameters: ApiDcrV1RequestsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.apiDcrV1RequestsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1RequestsIdGetRaw(requestParameters: ApiDcrV1RequestsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RequestDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        if (requestParameters.isInternal !== undefined) {
            queryParameters['IsInternal'] = requestParameters.isInternal;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/requests/id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDcrV1RequestsIdGet(requestParameters: ApiDcrV1RequestsIdGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RequestDto> {
        const response = await this.apiDcrV1RequestsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1RequestsSummaryChangePostRaw(requestParameters: ApiDcrV1RequestsSummaryChangePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RequestDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/dcr/v1/requests/summary-change`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SummaryChangeRequestStatusCommandToJSON(requestParameters.summaryChangeRequestStatusCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDcrV1RequestsSummaryChangePost(requestParameters: ApiDcrV1RequestsSummaryChangePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RequestDto> {
        const response = await this.apiDcrV1RequestsSummaryChangePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1RequestsTakePostRaw(requestParameters: ApiDcrV1RequestsTakePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RequestDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/dcr/v1/requests/take`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TakeRequestResponsibleCommandToJSON(requestParameters.takeRequestResponsibleCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDcrV1RequestsTakePost(requestParameters: ApiDcrV1RequestsTakePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RequestDto> {
        const response = await this.apiDcrV1RequestsTakePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1RequestsUntakePostRaw(requestParameters: ApiDcrV1RequestsUntakePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RequestDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/dcr/v1/requests/untake`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnTakeRequestResponsibleCommandToJSON(requestParameters.unTakeRequestResponsibleCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDcrV1RequestsUntakePost(requestParameters: ApiDcrV1RequestsUntakePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RequestDto> {
        const response = await this.apiDcrV1RequestsUntakePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDcrV1RequestsUpdateEmptyFieldsDataPutRaw(requestParameters: ApiDcrV1RequestsUpdateEmptyFieldsDataPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RequestDto>> {
        const queryParameters: any = {};

        if (requestParameters.requestId !== undefined) {
            queryParameters['requestId'] = requestParameters.requestId;
        }

        if (requestParameters.emptyFields) {
            queryParameters['emptyFields'] = requestParameters.emptyFields;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/requests/updateEmptyFieldsData`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDcrV1RequestsUpdateEmptyFieldsDataPut(requestParameters: ApiDcrV1RequestsUpdateEmptyFieldsDataPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RequestDto> {
        const response = await this.apiDcrV1RequestsUpdateEmptyFieldsDataPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
