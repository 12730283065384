import {getApiConfiguration} from '../../../../../core';
import {PersonPhoneCategoriesApi,} from "../../../../../services/management";
import {DictionaryCreateEntity, DictionaryEntity, DictionaryTableStore} from "../../DictionaryTableStore";
import {DictionaryPersonStore} from "../DictionaryPersonStore";

export class PersonPhoneCategoryTableStore extends DictionaryTableStore {
    constructor(store: DictionaryPersonStore) {
        super(store, 'phone-category-store');
    }

    async Create(model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new PersonPhoneCategoriesApi(getApiConfiguration()).createPersonPhoneCategory({setPhoneCategoryDto: model});
    }

    async Delete(id: string): Promise<DictionaryEntity> {
        return await new PersonPhoneCategoriesApi(getApiConfiguration()).deletePersonPhoneCategory({id: id});
    }

    async GetCount(search: string): Promise<{ count: number }> {
        return await new PersonPhoneCategoriesApi(getApiConfiguration()).getPersonPhoneCategoriesCount({search: search});
    }

    async GetList(filters: any): Promise<DictionaryEntity[]> {
        return await new PersonPhoneCategoriesApi(getApiConfiguration()).getPersonPhoneCategories(filters)
    }

    async Update(id: string, model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new PersonPhoneCategoriesApi(getApiConfiguration()).updatePersonPhoneCategory({id: id, setPhoneCategoryDto: model});
    }
}
