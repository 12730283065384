import {observer} from "mobx-react";
import {AuthorizePermission, Cell, Pagination, ParamsButtonGroup, Progress, Row, Search} from "../../../../components";
import {Box, Card, Checkbox, Table, TableBody} from "@mui/material";
import React from "react";
import {ISelected} from "../../../../core";
import {MbEmptyComponent} from "../../../../components/MbEmptyComponent";
import {AllPermissions} from "../../../../core/const";
import {BoundTableStore} from "../../../../stores/management/clients/tabs/bounds/items/BoundTableStore";

interface Props {
    store: BoundTableStore;
}

export const SpecialitiesTable = observer(({store}: Props) => {

    return (
        <Box sx={{minHeight: '250px'}}>
            <Box sx={{overflow: 'auto', pt: 2, mb: 2}}>
                <Search
                    autoFocus
                    style={{width: '100%'}}
                    value={store.search.value}
                    onChange={t => store.search.value = t}
                />
            </Box>
            <MbEmptyComponent
                isEmpty={store.items.length == 0 && !store.loading}
                position={'relative'}
                size={150}>
                <Card sx={{mb: 4, overflow: 'unset'}}>
                    <Table>
                        <TableBody>
                            {store.items.length > 0 ?
                                <>{store.items.map((t: ISelected & { name: string, id: string | number }) => {
                                        return <>
                                            <Row key={t.id} item={t}>
                                                <Cell>
                                                    <Checkbox
                                                        checked={t.selected}
                                                        onChange={(e) => {
                                                            t.selected = e.target.checked;
                                                            store.changeChecked(t.id);
                                                        }}
                                                        inputProps={{'aria-label': 'controlled'}}
                                                    />
                                                </Cell>
                                                <Cell>
                                                    {t.name}
                                                </Cell>
                                            </Row>
                                        </>
                                    }
                                )
                                } </> : <></>}
                        </TableBody>
                    </Table>
                    {store.items.length > 0 && <Pagination store={store}/>}
                    {!store.items.length && <Box
                        sx={{
                            flexGrow: 1,
                            py: 3,
                        }}
                    >
                        <Progress store={store}/>
                    </Box>
                    }
                </Card>

            </MbEmptyComponent>


            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <AuthorizePermission permissions={AllPermissions.client.update}>
                    <ParamsButtonGroup
                        testid="boundTable"
                        onCancel={() => store.reset()}
                        onSave={async () => await store.save()}
                        isCancelBtnDisabled={store.processing || store.equals}
                        isSaveBtnDisabled={store.processing || store.equals}
                    />
                </AuthorizePermission>

            </Box>
        </Box>
    );
});
