import {Tab, Tabs, Tooltip} from "@mui/material";

export interface TabsProps {
    index: number;
    onChange: (index: number) => void;
    style?: React.CSSProperties;
    children: React.ReactNode;
    multipleLines?: boolean;
}

export const PageTabs = ({index, onChange, multipleLines = false, ...other}: TabsProps) => {

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        const targetIndex = event.currentTarget.getAttribute('data-currentIndex');
        // onChange(Number.parseInt(newValue));
        onChange(parseInt(targetIndex || newValue));
    };

    if (multipleLines) {
        return (
            <Tabs
                indicatorColor="primary"
                onChange={handleChange}
                scrollButtons="auto"
                sx={{
                    mt: 2,
                    '& .MuiTabs-flexContainer': {
                        flexWrap: 'wrap',
                        overflow: 'unset'
                    },
                    overflow: 'unset'
                }}
                textColor="primary"
                value={index}
                variant="fullWidth"
                TabIndicatorProps={{sx: {display: 'none'}}}
                {...other}
            />
        )
    }
    return (
        <Tabs
            indicatorColor="primary"
            onChange={handleChange}
            scrollButtons="auto"
            sx={{mt: 2, overflow: 'unset'}}
            textColor="primary"
            value={index}
            variant="fullWidth"
            {...other}
        />
    )
}

export interface TabProps {
    icon?: React.ReactElement,
    label?: string;
    tabIndex?: number;
    title?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    testid?: string;
}

export const PageTab = ({icon, label, title, style, testid, ...other}: TabProps) => {
    if (title) {
        return (<Tooltip title={title}>
            <Tab
                data-testid={testid}
                label={label}
                icon={icon}
                style={style}
                {...other}
            /></Tooltip>)
    } else {
        return (<Tab
            data-testid={testid}
            label={label}
            icon={icon}
            style={style}
            {...other}
        />)
    }

}
