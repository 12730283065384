import {makeObservable, observable} from 'mobx';
import {DynamicalComponentStore} from "../../../../../../utils";
import {ISelected} from "../../../../../../../core";
import {PermissionTableStore} from "./PermissionTableStore";

export type PermissionItem = {
    parent?: string,
    key: string,
    name: string,
    idPermission?: string;
    selected: boolean;
};

export class PermissionItemStore extends DynamicalComponentStore implements ISelected {
    dto: PermissionItem;
    items: PermissionItemStore[] = []
    store: PermissionTableStore;

    constructor(store: PermissionTableStore, dto: PermissionItem, items: PermissionItemStore[] = []) {
        super();
        this.store = store;
        this.dto = dto;
        this.items = items;

        makeObservable(this, {
            dto: observable,
            items: observable
        })
    }

    async updateState(): Promise<void> {
    }

    get selected(): boolean {
        return this.dto.idPermission ? this.dto.selected : (this.items.length > 0 && this.items?.filter(x => !x.selected).length == 0)
    }

    get allItems(): PermissionItemStore[]{
        return this.dto.idPermission ? [] : this.items.concat(this.items.map(x => x.allItems).flatMap(x => x))
    }

    get permissionIds(): string[] {
        return !this.selected && !this.in ? [] :
            this.dto.idPermission ? [this.dto.idPermission] : this.items.map(x => x.permissionIds).flatMap(x => x);
    }

    get in(): boolean {
        return !this.selected && this.items.filter(x => x.selected || x.in).length > 0
    }

    get countPermissions(): number{
        return this.dto.idPermission ? 1 : this.items.map(x => x.countPermissions).reduce((x,y) => x + y, 0);
    }

    get countSelectedPermissions(): number{
        return this.dto.idPermission && this.dto.selected ? 1 : this.items.map(x => x.countSelectedPermissions).reduce((x,y) => x + y, 0);
    }

    set selected(value: boolean) {
        if (this.dto.idPermission){
            this.dto.selected = value;
            if(this.dto.selected){
                const name = this.dto.name ?? '';
                const split = name.split('.').map(x => x.toLowerCase());
                let index = -1;
                if (split.find(x => x == 'delete')) {
                    index = split.indexOf('delete')
                } else if (split.find(x => x == 'update')) {
                    index = split.indexOf('update')
                } else if (split.find(x => x == 'create')) {
                    index = split.indexOf('create')
                }
                if (index != -1) {
                    split[index] = 'read'
                    const readPermissionName = split.reduce((x, y) => x + '.' + y);
                    const readPermission = this.store!.allItems.find(x => x.dto.name!.toLowerCase() == readPermissionName)
                    if (readPermission && !readPermission.dto.selected) {
                        readPermission.dto.selected = true;
                    }
                }
            }
        }
        else {
            this.items.forEach(x => x.selected = value)
        }
    }
}
