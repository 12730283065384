import jwt_decode from 'jwt-decode';
import {makeAutoObservable} from 'mobx';
import {handleError400, RootStore} from '..';
import {DefaultSelectStore} from '../../components/shared/DefaultSelectStore';
import {getObject, setObject} from '../../core';
import {UserJurisdictionDto, UserJurisdictionsApi} from '../../services/auth';
import {AuthApi, TokenDto} from '../../services/auth/AuthApi';
import {UserVM} from './UserVM';
import {
  CategoryLocalitiesApi,
  JurisdictionLocalSettingDto,
  JurisdictionsApi,
  QueryType,
  TypeLocalitiesApi,
  TypeNamesApi,
  TypeOrgsApi
} from "../../services/management";
import {CategoryLocality, TypeLocality, TypeOrgs} from "../../core/const";
import i18n from '../../i18n';

export let accessToken: string;
export let refreshToken: string | null | undefined;
export let exp: number | null | undefined;
export let jurisdictionId: string;

interface Token {
  sub: string;
  name: string;
  roles: string[];
  scope: [];
  nbf: number;
  exp: number;
  iat: number;
  permission: [];
  jurisdictions?: [];
}

export interface AuthorizationState {
  rawToken?: string | null;
  refreshToken?: string | null;
  token?: Token | null;
  exp?: number | null;
  jurisdictions?: string[];
  currentJurisdiction: string;
}

export let JurisdictionData: {
  typeLocalityIds: {
    cityId: string
  },
  categoryLocality: {
    populatedId: string,
    regionId: string,
  },
  organizationTypes: {
    depsId: string,
    surgeryId: string,
    emptyId: string
  },
  orgTypeNames: {
    syndicate: string
  }
} = {
  organizationTypes: {
    depsId: "",
    surgeryId: "",
    emptyId: ""
  },
  typeLocalityIds: {
    cityId: ""
  },
  categoryLocality: {
    populatedId: "",
    regionId: ""
  },
  orgTypeNames: {
    syndicate: ""
  }
};

export type JurisdictionLocalSetting = JurisdictionLocalSettingDto & {
  numberLength: string,
  intLength: string,
}

export class AuthorizationStore {
  readonly store: RootStore;
  token?: Token | null;
  private _username: string = '';
  usernameError: string = '';
  private _password: string = '';
  passwordError: string = '';
  processing: boolean = false;
  user: UserVM | undefined | null;
  errorAuth: boolean = false;
  private _code: string = '';
  jurisdictions: DefaultSelectStore<UserJurisdictionDto> =
    new DefaultSelectStore<UserJurisdictionDto>(null, () =>
      this.id
        ? new UserJurisdictionsApi().getUserJurisdictions({ userId: this.id })
        : Promise.resolve([])
    );

  jurisdictionsSettings?: JurisdictionLocalSetting;

  constructor(store: RootStore) {
    this.store = store;
    const st = getObject<AuthorizationState>('authorization-store');
    this.jurisdictions.onChanged.push((e) => {
      jurisdictionId = e?.jurisdictionId!;
      this.store.managementStore.updateState();
      this.jurisdictionsSettings = undefined;

      Promise.all([
        new JurisdictionsApi().getJurisdictionSettingsByJurisdictionId(),
        new CategoryLocalitiesApi().getCategoryLocalityByName({
          name: CategoryLocality.RegionName
        }),
        new CategoryLocalitiesApi().getCategoryLocalityByName({
          name: CategoryLocality.PopulatedLocalitiesName
        }),
        new TypeOrgsApi().getTypeOrgByName({
          name: TypeOrgs.DepsName
        }),
        new TypeOrgsApi().getTypeOrgByName({
          name: TypeOrgs.SurgeryName
        }),
        new TypeLocalitiesApi().getTypeLocalityByName({
          name: TypeLocality.CityName
        }),
        new TypeOrgsApi().getTypeOrgs({
          queryType: QueryType.Tree,
          includeParents: false,
          page: 1,
          size: 1
        }),
        new TypeNamesApi().getTypeNames({
          search: 'синдикативное',
          page: 1,
          size: 1
        })
      ]).then(x => {
        const jurData = x[0];
        const regionId = x[1].id;
        const populatedLocalitiesId = x[2].id;
        const depsId = x[3].id;
        const surgeryId = x[4].id;
        const cityId = x[5].id;
        const orgTypeEmptyId = x[6][0]?.id;

        this.jurisdictionsSettings = {
          ...jurData,
          numberLength: jurData.phoneNumberRegex ? jurData.phoneNumberRegex.slice(jurData.phoneNumberRegex.indexOf("{") + 1, jurData.phoneNumberRegex.indexOf("}")) : "0",
          intLength: jurData.itnRegex ? this.itnHelper(jurData.itnRegex) : '0',
          zipRegex: jurData.zipRegex ?? "^[0-9]{6}$"
        };
        JurisdictionData = {
          categoryLocality: {
            populatedId: populatedLocalitiesId,
            regionId: regionId
          },
          organizationTypes: {
            depsId: depsId,
            surgeryId: surgeryId,
            emptyId: orgTypeEmptyId
          },
          typeLocalityIds: {
            cityId: cityId
          },
          orgTypeNames: {
            syndicate: x[7][0].id
          }
        }
      })

      this.saveState()

      localStorage.setItem('jurisdictionId', jurisdictionId);
    });
    // this.jurisdictions.defaultFirstValue = true;
    if (st) {
      this.createToken({
        refresh_token: st.refreshToken!,
        access_token: st.rawToken!,
      }, st.currentJurisdiction);
    }

    makeAutoObservable(this);
  }

  itnHelper(itnRegex: string) {
    return  itnRegex?.match(/[^{}]+(?=})/g)?.join(` ${i18n.t('common.or')} `) || ''
  }

  get permissions(): string[] {
    return this.token ? this.token?.permission : [];
  }

  hasRole(role: string) {
    return role && this.token?.roles?.includes(role!);
  }

  hasPermission(permission: string): boolean {
    return !!(this.permissions.length > 0 && this.permissions.find(x => x == permission)) || this.isAdmin;
  }

  get id(): string | undefined {
    return this.token?.sub;
  }

  get username() {
    return this._username;
  }

  get code() {
    return this._code;
  }

  set code(value: string) {
    this._code = value;
  }

  get isAuthenticated() {
    return Boolean(this.token?.name);
  }

  reset() {
    this.usernameError = '';
    this.passwordError = '';
    this._username = '';
    this._password = '';
    this.processing = false;
    this._code = '';
  }

  get isAdmin(): boolean {
    return !!this.token?.roles?.includes('Admin');
  }

  async refresh() {
    if (refreshToken) {
      try {
        const api: AuthApi = new AuthApi();
        const t = await api.refresh({
          refreshToken: refreshToken!,
        });
        await this.createToken(t);
      } catch {
        await this.logout();
      }
    }
  }

  async logout() {
    accessToken = '';
    refreshToken = '';
    exp = 0;
    this.token = null;
    this.user = null;
    this.saveState();
    localStorage.setItem('logout', '');
  }

  async Token(): Promise<void> {
    this.processing = true;
    try {
      const api: AuthApi = new AuthApi();
      const t = await api.token(this.code);
      await this.createToken(t);
      if (this.errorAuth) this.errorAuth = false;
    } catch (ex) {
      this.processing = false;
      this.errorAuth = true;
      const errorResult = await handleError400(ex);
      if (errorResult) {
        this.usernameError = errorResult.getFieldError('Username');
        this.passwordError = errorResult.getFieldError('Password');
      }
    }
  }

  async createToken(t: TokenDto, jurisdiction?: string | undefined) {
    accessToken = t.access_token;
    refreshToken = t.refresh_token;
    jurisdictionId = jurisdiction ?? "";
    this.token = jwt_decode<Token>(accessToken ?? '');
    exp = this.token.exp;
    this.user = new UserVM(this, this.token.name);
    this.saveState();
    this.reset();
    await this.jurisdictions.pull().then(x => {
      this.jurisdictions.value =
          jurisdiction ? this.jurisdictions.items.find((x) => x.jurisdictionId == jurisdiction) ?? null :
              (this.jurisdictions.items.find((x) => x.jurisdiction.name === 'Россия') ??
                  this.jurisdictions.items.find((x) => !!x.jurisdictionId) ??
                  null);
    });
  }


  saveState() {
    setObject<AuthorizationState>('authorization-store', {
      rawToken: accessToken,
      refreshToken: refreshToken,
      exp: exp,
      token: this.token,
      currentJurisdiction: jurisdictionId,
    });
  }
}

