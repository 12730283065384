/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Exchange.Analytics.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationTypesAggregation
 */
export interface OrganizationTypesAggregation {
    /**
     * 
     * @type {string}
     * @memberof OrganizationTypesAggregation
     */
    organizationTypeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationTypesAggregation
     */
    organizationTypeName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganizationTypesAggregation
     */
    succeededCount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationTypesAggregation
     */
    failedCount?: number;
}

export function OrganizationTypesAggregationFromJSON(json: any): OrganizationTypesAggregation {
    return OrganizationTypesAggregationFromJSONTyped(json, false);
}

export function OrganizationTypesAggregationFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationTypesAggregation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationTypeId': !exists(json, 'organizationTypeId') ? undefined : json['organizationTypeId'],
        'organizationTypeName': !exists(json, 'organizationTypeName') ? undefined : json['organizationTypeName'],
        'succeededCount': !exists(json, 'succeededCount') ? undefined : json['succeededCount'],
        'failedCount': !exists(json, 'failedCount') ? undefined : json['failedCount'],
    };
}

export function OrganizationTypesAggregationToJSON(value?: OrganizationTypesAggregation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationTypeId': value.organizationTypeId,
        'organizationTypeName': value.organizationTypeName,
        'succeededCount': value.succeededCount,
        'failedCount': value.failedCount,
    };
}

