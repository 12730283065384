import {TableCell, TableSortLabel, Theme} from '@mui/material';
import {IOrderStore} from '../../core';
import {ListSortDirection} from '../../services/management';
import {SxProps} from "@mui/system";

type Props<T> = {
    orderBy?: (keyof T) | string;
    children?: React.ReactNode;
    store?: IOrderStore<T>;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    style?: React.CSSProperties;
    styleText?: React.CSSProperties;
    sx?: SxProps<Theme>;
    width?: string;
    testid?: string;
};

export const HCell = <T extends {}>({
                                        orderBy,
                                        store,
                                        children,
                                        align,
                                        style,
                                        sx,
                                        width,
                                        testid,
                                        styleText,
                                        ...other
                                    }: Props<T>) => {

    const handleClick = async () => {
        if (store && orderBy) {
            await store.setOrder(orderBy, store.order === ListSortDirection.Descending ? ListSortDirection.Ascending : ListSortDirection.Descending);
        }
    };

    const active = (): boolean => {
        if (store && orderBy) {
            return orderBy === store.orderBy;
        }
        return false;
    };

    return (
        <TableCell
            data-testid={`${testid}-tableHead`}
            sx={sx}
            align={align}
            style={{
                paddingLeft: 0,
                paddingRight: 0,
                fontWeight: 800,
                padding: '6px 0',
                ...style,
            }}
            width={width}
            {...other}
        >
            <div style={{display: 'flex'}}>
                <p data-testid={`${testid}-tableHead-value`} style={styleText}>{children}</p>
                <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                    {
                        orderBy &&
                        <TableSortLabel
                            active={active()}
                            direction={store?.order === ListSortDirection.Descending ? 'desc' : 'asc'}
                            onClick={handleClick}
                        >
                        </TableSortLabel>
                    }
                </div>
            </div>

        </TableCell>
    );
};
