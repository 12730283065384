/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    LocalityDto,
    LocalityDtoFromJSON,
    LocalityDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    QueryType,
    QueryTypeFromJSON,
    QueryTypeToJSON,
    SetLocalityDto,
    SetLocalityDtoFromJSON,
    SetLocalityDtoToJSON,
} from '../models';

export interface CanBeEndpointRequest {
    localityId?: string;
}

export interface CreateLocalityRequest {
    setLocalityDto?: SetLocalityDto;
}

export interface DeleteLocalityRequest {
    id: string;
}

export interface GetLocalitiesRequest {
    byLocalityTypeId?: string;
    byLocalityTypeIds?: Array<string>;
    search?: string;
    searchByExternalId?: string;
    queryType?: QueryType;
    parentId?: string;
    orderBy?: string;
    categories?: Array<string>;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetLocalitiesCountRequest {
    byLocalityTypeId?: string;
    byLocalityTypeIds?: Array<string>;
    search?: string;
    searchByExternalId?: string;
    queryType?: QueryType;
    categories?: Array<string>;
    parentId?: string;
}

export interface GetLocalityByIdRequest {
    id: string;
}

export interface GetLocalityByNameRequest {
    name: string;
}

export interface UpdateLocalityRequest {
    id: string;
    setLocalityDto: SetLocalityDto;
}

/**
 * 
 */
export class LocalitiesApi extends runtime.BaseAPI {

    /**
     */
    async canBeEndpointRaw(requestParameters: CanBeEndpointRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.localityId !== undefined) {
            queryParameters['localityId'] = requestParameters.localityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/localities/canBeEndpoint`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async canBeEndpoint(requestParameters: CanBeEndpointRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.canBeEndpointRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createLocalityRaw(requestParameters: CreateLocalityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<LocalityDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/localities`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetLocalityDtoToJSON(requestParameters.setLocalityDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LocalityDtoFromJSON(jsonValue));
    }

    /**
     */
    async createLocality(requestParameters: CreateLocalityRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<LocalityDto> {
        const response = await this.createLocalityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteLocalityRaw(requestParameters: DeleteLocalityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<LocalityDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteLocality.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/localities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LocalityDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteLocality(requestParameters: DeleteLocalityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<LocalityDto> {
        const response = await this.deleteLocalityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLocalitiesRaw(requestParameters: GetLocalitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<LocalityDto>>> {
        const queryParameters: any = {};

        if (requestParameters.byLocalityTypeId !== undefined) {
            queryParameters['byLocalityTypeId'] = requestParameters.byLocalityTypeId;
        }

        if (requestParameters.byLocalityTypeIds) {
            queryParameters['byLocalityTypeIds'] = requestParameters.byLocalityTypeIds;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.queryType !== undefined) {
            queryParameters['queryType'] = requestParameters.queryType;
        }

        if (requestParameters.parentId !== undefined) {
            queryParameters['parentId'] = requestParameters.parentId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.categories) {
            queryParameters['categories'] = requestParameters.categories;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/localities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LocalityDtoFromJSON));
    }

    /**
     */
    async getLocalities(requestParameters: GetLocalitiesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<LocalityDto>> {
        const response = await this.getLocalitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLocalitiesCountRaw(requestParameters: GetLocalitiesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.byLocalityTypeId !== undefined) {
            queryParameters['byLocalityTypeId'] = requestParameters.byLocalityTypeId;
        }

        if (requestParameters.byLocalityTypeIds) {
            queryParameters['byLocalityTypeIds'] = requestParameters.byLocalityTypeIds;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.queryType !== undefined) {
            queryParameters['queryType'] = requestParameters.queryType;
        }

        if (requestParameters.categories) {
            queryParameters['categories'] = requestParameters.categories;
        }

        if (requestParameters.parentId !== undefined) {
            queryParameters['parentId'] = requestParameters.parentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/localities/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getLocalitiesCount(requestParameters: GetLocalitiesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getLocalitiesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLocalityByIdRaw(requestParameters: GetLocalityByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<LocalityDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getLocalityById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/localities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LocalityDtoFromJSON(jsonValue));
    }

    /**
     */
    async getLocalityById(requestParameters: GetLocalityByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<LocalityDto> {
        const response = await this.getLocalityByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLocalityByNameRaw(requestParameters: GetLocalityByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<LocalityDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getLocalityByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/localities/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LocalityDtoFromJSON(jsonValue));
    }

    /**
     */
    async getLocalityByName(requestParameters: GetLocalityByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<LocalityDto> {
        const response = await this.getLocalityByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateLocalityRaw(requestParameters: UpdateLocalityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<LocalityDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateLocality.');
        }

        if (requestParameters.setLocalityDto === null || requestParameters.setLocalityDto === undefined) {
            throw new runtime.RequiredError('setLocalityDto','Required parameter requestParameters.setLocalityDto was null or undefined when calling updateLocality.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/localities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetLocalityDtoToJSON(requestParameters.setLocalityDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LocalityDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateLocality(requestParameters: UpdateLocalityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<LocalityDto> {
        const response = await this.updateLocalityRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
