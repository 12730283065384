import {makeObservable, observable} from 'mobx';
import {PagedItems, TableLocalFilters, TableStore} from "../../utils";
import {DictionaryItemStore} from "./DictionaryItemStore";
import {getObject, setObject} from "../../../core";
import {DictionaryOrganizationsStore} from "./organizations/DictionaryOrganizationsStore";
import {DictionaryPersonStore} from "./persons/DictionaryPersonStore";
import {DefaultFormModalStore} from "../../../components/shared/DefaultFormModalStore";
import {DefaultValueStore} from "../../../components/shared/DefaultValueStore";

interface DictionaryTableStoreLocalFilters extends TableLocalFilters<DictionaryItemStore> {
}

export type DictionaryCreateEntity = {
    nameRus: string,
    nameEng?: string | null;
}

export type DictionaryEntity = {
    nameRus: string,
    nameEng?: string | null;
    modifiedAt?: Date | null;
    id: string
    isInactive?: boolean | undefined;
}

export abstract class DictionaryTableStore extends TableStore<DictionaryItemStore> {

    isActiveStateUsed: boolean = false;

    readonly store: DictionaryOrganizationsStore | DictionaryPersonStore;
    readonly creator: DefaultFormModalStore<{
        nameRus: DefaultValueStore<string>
        nameEng: DefaultValueStore<string>
    }>;
    public current: DictionaryItemStore | undefined | null;
    deleteDialogState: boolean = false;
    filterName: string;

    protected constructor(store: DictionaryOrganizationsStore | DictionaryPersonStore, filterName: string) {
        super();
        this.store = store;
        this.filterName = filterName;
        this.creator = new DefaultFormModalStore({
            nameRus: new DefaultValueStore<string>(""),
            nameEng: new DefaultValueStore<string>("")
        }, (e) => this.submit(e))

        this.creator.afterSubmit = async () => this.pull()

        this.creator.fields.nameRus.required = true;
        this.creator.fields.nameEng.required = true;

        this._orderBy = "nameRus";

        const filters = getObject<DictionaryTableStoreLocalFilters>(filterName);
        if (filters) {
            this._page = filters.page;
            this._size = filters.size;
            this.search.setValueWithoutEffects(filters.search);
            this._order = filters.order;
            this._orderBy = filters.orderBy;
        }
        makeObservable(this, {
            creator: observable,
            current: observable,
            deleteDialogState: observable
        });
    }

    abstract GetList(filters: any): Promise<DictionaryEntity[]>;

    abstract GetCount(search: string | null | undefined): Promise<{ count: number }>;

    abstract Create(model: DictionaryCreateEntity): Promise<DictionaryEntity>;

    abstract Update(id: string, model: DictionaryCreateEntity): Promise<DictionaryEntity>;

    abstract Delete(id: string): Promise<DictionaryEntity>;

    async request(): Promise<PagedItems<DictionaryItemStore>> {
        const filters = {
            page: this.page + 1,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy
        };
        const result = await this.GetList(filters);
        const count = await this.GetCount(this.search.value ?? null);
        const newList: DictionaryItemStore[] = [];
        for (const item of result) {
            const existsItem = this.items.find(t => t.dto.id === item.id);
            if (existsItem) {
                existsItem.update(item);
                newList.push(existsItem);
                continue;
            }
            newList.push(new DictionaryItemStore(this, item))
        }
        return new PagedItems<DictionaryItemStore>(newList, count.count)
    };

    onFiltersSave() {
        setObject<DictionaryTableStoreLocalFilters>(this.filterName, {
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            page: this.page,
            size: this.size
        });
    }

    async submit(model: DictionaryCreateEntity): Promise<void> {
        await this.Create(model);
    };

    async save(id: string, model: DictionaryCreateEntity): Promise<void> {
        const result = await this.Update(id, model);
        this.current?.update(result);
    };

    async delete(): Promise<void> {
         await this.Delete(this.current?.dto.id!);
    };
}
