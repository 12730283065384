import {observer} from "mobx-react";
import {Box} from "@mui/material";
import React from "react";
import {FormRoot, ParamsButtonGroup} from "../../../../../components";
import {useTranslation} from "react-i18next";
import {callTheProcessingFunc, StoreProps, StyleProps} from "../../../../../stores";
import {DefaultMultiSelect} from "../../../../../components/shared/components/DefaultMultiSelect";
import {
    UserJurisdictionsTabStore
} from "../../../../../stores/management/users/tabs/Users/tabs/UserJurisdictionsTabStore";

export const JurisdictionsTab = observer(({store, style}: StoreProps<UserJurisdictionsTabStore> & StyleProps) => {
    const {t} = useTranslation();

    return (
        <FormRoot style={style}>
            <DefaultMultiSelect store={store.allJurisdictionsControl}
                                title={t('usersPage.tabs.jurisdictions')}
                                valueExp="id"
                                nameExp="nameRus"
                                tags={true}
            />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <ParamsButtonGroup
                    testid="parametersUser"
                    onCancel={() => store.allJurisdictionsControl.reset()}
                    onSave={async () => await callTheProcessingFunc(store, (e) => store.submit())()}
                    isCancelBtnDisabled={store.processing || store.allJurisdictionsControl.equals}
                    isSaveBtnDisabled={store.processing || store.allJurisdictionsControl.equals || !store.allJurisdictionsControl.valid}
                />
            </Box>
        </FormRoot>
    );
});
