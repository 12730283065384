import { Box, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthorizePermission } from '../../../components';
import { DefaultDate } from '../../../components/shared/DefaultDate';
import { DefaultSelect } from '../../../components/shared/components/DefaultSelect';
import { AnalyticsDateFiltersStore } from '../../../stores/management/analytics/AnalyticsDateFiltersStore';

interface Props {
    store: AnalyticsDateFiltersStore;
    title: string;
    children: React.ReactNode;
}

export const AnalyticsPage = observer((props: Props) => {
    const { t } = useTranslation();

    return (
        <AuthorizePermission permissions="" role={'Admin'} showWarning>
            <>
                <Box
                    sx={{
                        mb: 3,
                    }}
                >
                    <Box component="main" sx={{ flexGrow: 1 }}>
                        <Grid
                            container
                            disableEqualOverflow
                            spacing={{ xs: 3, lg: 4 }}
                        >
                            <Grid xs={12}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    spacing={5}
                                >
                                    <div>
                                        <Typography variant="h3">
                                            {props.title}
                                        </Typography>
                                    </div>
                                    <div style={{ width: '60%' }}>
                                        <Stack
                                            direction="row"
                                            alignItems={'left'}
                                            alignContent={'left'}
                                            spacing={2}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    marginLeft: 'auto',
                                                    width: '100%',
                                                }}
                                            >
                                                <DefaultDate
                                                    store={
                                                        props.store.startDate
                                                    }
                                                    style={{
                                                        marginTop: 8,
                                                        flex: 1,
                                                        marginLeft: 5,
                                                        height: 45,
                                                    }}
                                                    title={t(
                                                        'dcrPage.requestTable.startDate'
                                                    )}
                                                />
                                                <DefaultDate
                                                    store={props.store.endDate}
                                                    style={{
                                                        marginTop: 8,
                                                        flex: 1,
                                                        marginLeft: 5,
                                                        height: 45,
                                                    }}
                                                    title={t(
                                                        'dcrPage.requestTable.endDate'
                                                    )}
                                                />
                                                <DefaultSelect
                                                    store={props.store.periods}
                                                    disableClearable
                                                    nameExp={
                                                        props.store.periods
                                                            .nameExp
                                                    }
                                                    valueExp="value"
                                                    hasLabel
                                                    style={{
                                                        flex: 1,
                                                        marginLeft: 5,
                                                    }}
                                                    title={t('common.period')}
                                                />
                                            </div>
                                        </Stack>
                                    </div>
                                </Stack>
                            </Grid>
                            {props.children}
                        </Grid>
                    </Box>
                </Box>
            </>
        </AuthorizePermission>
    );
});
