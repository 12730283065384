import {observer} from 'mobx-react';
import React from "react";
import {FormRoot, FormRow} from "../../../../components";
import {DefaultSelect} from '../../../../components/shared/components/DefaultSelect';
import {FormPropertyCreatorStore} from "../../../../stores/management/organizations/creator/FormPropertyCreatorStore";
import {useTranslation} from "react-i18next";
import {DefaultValue} from '../../../../components/shared/components/DefaultValue';
import {OrganizationDto, OrgNameDto, TypeOrgDto} from '../../../../services/management';
import {DefaultCheckbox} from "../../../../components/shared/components/DefaultCheckbox";
import {DefaultPhoneValue} from "../../../../components/shared/components/DefaultPhoneValue";

interface Props {
    store: FormPropertyCreatorStore;
}

export const FormPropertyCreator = observer(({ store }: Props) => {
    const { t } = useTranslation();

    return (
        <FormRoot>
            <FormRow>
                <DefaultCheckbox store={store.form.fields.isParent} title={t('common.Parent')}/>
            </FormRow>
            {!store.form.fields.isParent.value &&
                <DefaultSelect store={store.form.fields.parentOrganization}
                               style={{marginTop: -20,marginBottom: 10 }}
                               title={t('common.Parent')}
                               nameExp={(item: OrganizationDto) => item.status?.nameRus.toLowerCase() === 'дубль' ? '' : item.names.find((x: OrgNameDto) => x.typeName?.nameRus.toLowerCase() === 'синдикативное')?.name || item?.names[0]?.name}
                               testid="parentCreator-parentOrganization"
                               hasLabel />
            }
            <FormRow>
                <DefaultValue store={store.form.fields.name} title={t('common.nameOrg')}/>
            </FormRow>
            <FormRow>
                <DefaultValue store={store.form.fields.itn} title={t('common.itn')}/>
            </FormRow>
            <FormRow>
                <DefaultSelect store={store.form.fields.formProperty}
                    title={t('common.formProperty')}
                    testid="formPropertyCreator-formProperty"
                    hasLabel />
            </FormRow>
            {!store.form.fields.isParent.value ?
                <FormRow>
                    <DefaultSelect store={store.form.fields.type}
                        title={t('common.type')}
                        testid="formPropertyCreator-type"
                                   onValue={() => store.form.fields.parentOrganization.validate()}
                        hasLabel />
                </FormRow> :
                <FormRow>
                    <DefaultSelect store={store.form.fields.type}
                        title={t('common.type')}
                        testid="formPropertyCreator-type"
                        nameExp={(item: TypeOrgDto) => store._store._store.typeDepartmentList.includes(item.id) ? '' : item?.nameRus }
                                   onValue={() => store.form.fields.parentOrganization.validate()}
                        hasLabel />
                </FormRow>
            }
            <FormRow>
                <DefaultSelect store={store.form.fields.status}
                    title={t('common.status')}
                    emptyValue={t('common.working')}
                    testid="formPropertyCreator-status"
                    hasLabel />
            </FormRow>
            <FormRow>
                <DefaultPhoneValue store={store.form.fields.phoneNumber} title={t('common.phone')}/>
            </FormRow>
            {!!store.form.fields.phoneNumber.value &&
                <>
                    <FormRow>
                        <DefaultSelect store={store.form.fields.phoneStatus}
                            title={t('common.status')}
                            testid="formPropertyCreator-phoneStatus"
                            hasLabel />
                    </FormRow>
                    <FormRow>
                        <DefaultSelect store={store.form.fields.phoneType}
                            title={t('common.type')}
                            testid="formPropertyCreator-phoneType"
                            hasLabel />
                    </FormRow>
                    <FormRow>
                        <DefaultSelect store={store.form.fields.phoneCategory}
                            title={t('common.category')}
                            testid="formPropertyCreator-phoneCategory"
                            hasLabel />
                    </FormRow>
                    <FormRow sx={{ pb: 2 }}>
                        <DefaultValue
                            style={{ width: "100%", marginTop: 24 }}
                            store={store.form.fields.additionalNumber}
                            title={t('common.additionalNumber')} />
                    </FormRow>
                </>
            }
        </FormRoot>
    );
});
