/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ClinicCategoryDto,
    ClinicCategoryDtoFromJSON,
    ClinicCategoryDtoToJSON,
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OCMObjectState,
    OCMObjectStateFromJSON,
    OCMObjectStateToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetClinicCategoryDto,
    SetClinicCategoryDtoFromJSON,
    SetClinicCategoryDtoToJSON,
} from '../models';

export interface CreateClinicCategoryRequest {
    setClinicCategoryDto: SetClinicCategoryDto;
}

export interface DeleteClinicCategoryRequest {
    id: string;
}

export interface GetClinicCategoriesRequest {
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    showOnlyActive?: boolean;
}

export interface GetClinicCategoriesCountRequest {
    search?: string;
    showOnlyActive?: boolean;
}

export interface GetClinicCategoriesCountFallbackRequest {
    search?: string;
    states?: Array<OCMObjectState>;
    showOnlyActive?: boolean;
}

export interface GetClinicCategoriesFallbackRequest {
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
    showOnlyActive?: boolean;
}

export interface GetClinicCategoryByIdRequest {
    id: string;
}

export interface GetClinicCategoryByNameRequest {
    name: string;
}

export interface UpdateClinicCategoryRequest {
    id: string;
    setClinicCategoryDto: SetClinicCategoryDto;
}

/**
 * 
 */
export class ClinicCategoriesApi extends runtime.BaseAPI {

    /**
     */
    async createClinicCategoryRaw(requestParameters: CreateClinicCategoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ClinicCategoryDto>> {
        if (requestParameters.setClinicCategoryDto === null || requestParameters.setClinicCategoryDto === undefined) {
            throw new runtime.RequiredError('setClinicCategoryDto','Required parameter requestParameters.setClinicCategoryDto was null or undefined when calling createClinicCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/types/cliniccategories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetClinicCategoryDtoToJSON(requestParameters.setClinicCategoryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async createClinicCategory(requestParameters: CreateClinicCategoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ClinicCategoryDto> {
        const response = await this.createClinicCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteClinicCategoryRaw(requestParameters: DeleteClinicCategoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ClinicCategoryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteClinicCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/types/cliniccategories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteClinicCategory(requestParameters: DeleteClinicCategoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ClinicCategoryDto> {
        const response = await this.deleteClinicCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClinicCategoriesRaw(requestParameters: GetClinicCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ClinicCategoryDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.showOnlyActive !== undefined) {
            queryParameters['showOnlyActive'] = requestParameters.showOnlyActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/types/cliniccategories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClinicCategoryDtoFromJSON));
    }

    /**
     */
    async getClinicCategories(requestParameters: GetClinicCategoriesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ClinicCategoryDto>> {
        const response = await this.getClinicCategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClinicCategoriesCountRaw(requestParameters: GetClinicCategoriesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.showOnlyActive !== undefined) {
            queryParameters['showOnlyActive'] = requestParameters.showOnlyActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/types/cliniccategories/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getClinicCategoriesCount(requestParameters: GetClinicCategoriesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getClinicCategoriesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClinicCategoriesCountFallbackRaw(requestParameters: GetClinicCategoriesCountFallbackRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        if (requestParameters.showOnlyActive !== undefined) {
            queryParameters['showOnlyActive'] = requestParameters.showOnlyActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/types/cliniccategories/count/fallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getClinicCategoriesCountFallback(requestParameters: GetClinicCategoriesCountFallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getClinicCategoriesCountFallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClinicCategoriesFallbackRaw(requestParameters: GetClinicCategoriesFallbackRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ClinicCategoryDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        if (requestParameters.showOnlyActive !== undefined) {
            queryParameters['showOnlyActive'] = requestParameters.showOnlyActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/types/cliniccategories/fallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClinicCategoryDtoFromJSON));
    }

    /**
     */
    async getClinicCategoriesFallback(requestParameters: GetClinicCategoriesFallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ClinicCategoryDto>> {
        const response = await this.getClinicCategoriesFallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClinicCategoryByIdRaw(requestParameters: GetClinicCategoryByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ClinicCategoryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getClinicCategoryById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/types/cliniccategories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async getClinicCategoryById(requestParameters: GetClinicCategoryByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ClinicCategoryDto> {
        const response = await this.getClinicCategoryByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClinicCategoryByNameRaw(requestParameters: GetClinicCategoryByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ClinicCategoryDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getClinicCategoryByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/types/cliniccategories/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async getClinicCategoryByName(requestParameters: GetClinicCategoryByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ClinicCategoryDto> {
        const response = await this.getClinicCategoryByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateClinicCategoryRaw(requestParameters: UpdateClinicCategoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ClinicCategoryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateClinicCategory.');
        }

        if (requestParameters.setClinicCategoryDto === null || requestParameters.setClinicCategoryDto === undefined) {
            throw new runtime.RequiredError('setClinicCategoryDto','Required parameter requestParameters.setClinicCategoryDto was null or undefined when calling updateClinicCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/types/cliniccategories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetClinicCategoryDtoToJSON(requestParameters.setClinicCategoryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateClinicCategory(requestParameters: UpdateClinicCategoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ClinicCategoryDto> {
        const response = await this.updateClinicCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
