import {makeObservable, observable} from 'mobx';
import {getApiConfiguration, IProcessing} from "../../../../../../core";
import {
    ClinicCategoriesApi,
    ClinicCategoryDto,
    GetTypeOrgsRequest,
    QueryType,
    SetTypeOrgDto,
    TypeOrgDto,
    TypeOrgsApi
} from "../../../../../../services/management";
import {DefaultValueStore} from '../../../../../../components/shared/DefaultValueStore';
import {DefaultFormModalStore} from "../../../../../../components/shared/DefaultFormModalStore";
import {DefaultSelectStore} from '../../../../../../components/shared/DefaultSelectStore';
import {ItemsLoadStore} from "../../../../../utils";
import {TypeOrganizationItemStore} from "./TypeOrganizationItemStore";
import {DictionaryOrganizationsStore} from "../../DictionaryOrganizationsStore";


export class TypeOrganizationTableStore extends ItemsLoadStore<TypeOrganizationItemStore> implements IProcessing {
    isActiveStateUsed = true;

    readonly store: DictionaryOrganizationsStore;
    processing: boolean = false;
    search: DefaultValueStore<string> = new DefaultValueStore<string>("", null, async (e) => !!e ? await this.pull() : this.pull())
    creator: DefaultFormModalStore<{
        nameRus: DefaultValueStore<string>,
        nameEng: DefaultValueStore<string>,
        clinicCategory: DefaultSelectStore<ClinicCategoryDto>,
        parent: DefaultSelectStore<TypeOrgDto>,
    }> = new DefaultFormModalStore({
        nameRus: new DefaultValueStore<string>(""),
        nameEng: new DefaultValueStore<string>(""),
        clinicCategory: new DefaultSelectStore<ClinicCategoryDto>(
            null, (x) => new ClinicCategoriesApi(getApiConfiguration()).getClinicCategories(x)),
        parent: new DefaultSelectStore<TypeOrgDto>(
            null, (x) => new TypeOrgsApi(getApiConfiguration()).getTypeOrgs(x)),
    }, this.create.bind(this));

    deleteDialogState: boolean = false;
    current: TypeOrganizationItemStore | undefined | null;
    clinicCategoryId: string | undefined = undefined;
    parentId: string | undefined = undefined;
    public emptyId: string | undefined;

    constructor(store: DictionaryOrganizationsStore) {
        super()
        this.store = store;
        makeObservable(this, {
            deleteDialogState: observable,
            current: observable,
        });
        this.creator.afterSubmit = async () => this.pull();
        this.creator.fields.nameRus.required = true;
        this.creator.fields.nameEng.required = true;
        this.creator.fields.clinicCategory.required = true;

        this.creator.fields.clinicCategory.onChanged = [(e) => this.clinicCategoryId = e?.id]
        this.creator.fields.parent.onChanged = [(e) => this.parentId = e?.id]
    }

    async save(id: string, e: SetTypeOrgDto): Promise<void> {
        const item = await new TypeOrgsApi(getApiConfiguration()).updateTypeOrg({id: id, setTypeOrgDto: e});
        this.current?.update(item)
    };

    //словарь типы организаций не должен удаляться
    // async delete(): Promise<void> {
    //     await new TypeOrgsApi(getApiConfiguration()).deleteTypeOrg({id: this.current?.dto.id!});
    // };

    async create(e: SetTypeOrgDto): Promise<void> {
        const typeOrgDto = {
            nameRus: e.nameRus,
            nameEng: e.nameEng,
            clinicCategoryId: this.clinicCategoryId ? this.clinicCategoryId : '',
            parentId: this.parentId ?? this.emptyId
        }

        await new TypeOrgsApi(getApiConfiguration()).createTypeOrg({setTypeOrgDto: typeOrgDto});
    };

    get selectedLocalityIds(): string[] {
        return this.value.map(x => x.selectedChildren).reduce(function (x, y) {
            return x.concat(y);
        }, []).concat(this.value.filter(x => x.expand)).map(x => x.dto.id)
    }


    async beforeLoad(): Promise<void> {
        const filters = {
            queryType: QueryType.Tree,
            page: 1,
            size: 1,
            includeParents: false
        } as GetTypeOrgsRequest;

        this.emptyId = (await new TypeOrgsApi(getApiConfiguration()).getTypeOrgs(filters)).map(x => x.id)[0] ?? null;
        return super.beforeLoad();
    }

    async request(): Promise<TypeOrganizationItemStore[]> {

        const filters = {
            queryType: !(this.search.value ?? undefined) ? QueryType.Tree : undefined,
            search: this.search.value,
            parentId: this.emptyId,
            page: 1,
            size: 10000,
            includeParents: !!(this.search.value ?? undefined)
        } as GetTypeOrgsRequest;

        const api: TypeOrgsApi = new TypeOrgsApi(getApiConfiguration());
        let result = await api.getTypeOrgs(filters);
        const tree: TypeOrgDto[] = []

        for (let i = 0; i < result.length; i++) {
            let element: TypeOrgDto = result[i]
            let hasElement = tree.filter(x => x.id == element.id)[0]
            if (!hasElement) {
                tree.push(element)
                let hasParent = result[i].parentId != this.emptyId && !tree.filter(x => x.id == element.parentId)[0];
                while (hasParent) {
                    element = element!.parent!;
                    tree.push(element)
                    const a = element?.parentId != this.emptyId
                    hasParent = a && !tree.filter(x => x!.id == element?.parentId)[0];
                }
            }
        }

        const nest = (items: TypeOrgDto[], id: string | null = null, link = 'parentId'): TypeOrganizationItemStore[] =>{
            return items
                .filter((item: any) => item[link] === id)
                .map(item => {
                    return new TypeOrganizationItemStore(this, item, nest(items.filter(x => x.id != item.id), item.id), !!this.search.value || !!this.selectedLocalityIds.find(x => x == item.id))
                });
        }


        return nest(tree, this.emptyId).filter(x => x.dto.id != this.emptyId);
    }
}
