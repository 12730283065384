import {makeAutoObservable} from "mobx";
import {getApiConfiguration, IProcessing} from "../../../../../core";
import {DefaultMultiSelectStore} from "../../../../../components/shared/DefaultMultiSelectStore";
import {
    FormPropertiesApi,
    FormPropertyDto,
    OrganizationStatusDto,
    OrganizationStatusesApi,
    TypeOrgDto,
    TypeOrgsApi
} from "../../../../../services/management";
import {LocalityFilterStore} from "../../../../../components/select/Locality/LocalityFilterStore";
import {ExportFormat, mappingExportFormatToString} from "../services";
import {searchStatusWorkOrgHandler} from "../../../../utils";
import {ExportTableStore} from "../ExportTableStore";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";

export class ExportOrganizationStore implements IProcessing {
    readonly store: ExportTableStore;
    readonly formPropertyFilter: DefaultMultiSelectStore<FormPropertyDto> = new DefaultMultiSelectStore([],
        (filters) => new FormPropertiesApi(getApiConfiguration()).getFormProperties(filters));
    readonly statusFilter: DefaultMultiSelectStore<OrganizationStatusDto> = new DefaultMultiSelectStore([],
        async (filters) => {
            let statuses = await new OrganizationStatusesApi(getApiConfiguration()).getOrganizationStatuses({
                ...filters,
                orderBy: 'name_rus',
                order: 'ascending'
            })
            return searchStatusWorkOrgHandler(statuses, filters)
        },);
    readonly typeFilter: DefaultMultiSelectStore<TypeOrgDto> = new DefaultMultiSelectStore([],
        (filters) => new TypeOrgsApi(getApiConfiguration()).getTypeOrgs(filters),);
    readonly localitiesFilter: LocalityFilterStore = new LocalityFilterStore();
    readonly exportTypes: DefaultMultiSelectStore<ExportFormat> = new DefaultMultiSelectStore([] as ExportFormat[], Object.values(ExportFormat) as ExportFormat[])
    public createDate: DefaultValueStore<Date> = new DefaultValueStore<Date>(new Date())
    public updateDate: DefaultValueStore<Date> = new DefaultValueStore<Date>(null)

    loading: boolean = false;
    error: boolean = false;
    downloaded: boolean = false;
    processing: boolean = false;

    constructor(store: ExportTableStore) {
        this.store = store;
        this.exportTypes.valueExp = undefined;
        this.exportTypes.nameExp = (e) => mappingExportFormatToString(e);
        this.exportTypes.required = true;

        makeAutoObservable(this);
    }

    resetFilters() {
        this.createDate.reset();
        this.updateDate.reset();
        this.formPropertyFilter.reset()
        this.statusFilter.reset()
        this.typeFilter.reset()
        this.localitiesFilter.resetItems();
        this.exportTypes.reset()
    }

    async create(): Promise<void> {
        this.downloaded = false;
        this.loading = true;
        const filters = {
            statusIds: this.statusFilter.value?.length ? this.statusFilter.value.map(x => x.id) : undefined,
            typeOrgIds: this.typeFilter.value?.length ? this.typeFilter.value.map(x => x.id) : undefined,
            formPropertyIds: this.formPropertyFilter.value?.length ? this.formPropertyFilter.value.map(x => x.id) : undefined,
            localityIds: this.localitiesFilter.items.length ? this.localitiesFilter.items.map(x => x.id) : undefined,
            searchByHouse: this.localitiesFilter.house ? this.localitiesFilter.house : undefined,
            exportTypes: this.exportTypes.value?.length ? this.exportTypes.value.reduce((sum, current) => sum + current, 0) : undefined,
            createDate: this.createDate.value,
            updateDate: this.updateDate.value
        };
        //const api: ExportFilesApi = new ExportFilesApi(getApiConfiguration() as any);
        try {
            // const result = await api.getOrganizationsFile(filters)
            // this._blob = await result.blob;
            // this._filename = result.filename;
        } catch (e) {
        }
        await this.store.pull()
        this.loading = false;
    };
}
