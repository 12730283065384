import {PagedItems, TableStore} from "../../../../utils";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";
import {DefaultMultiSelectStore} from "../../../../../components/shared/DefaultMultiSelectStore";
import {ClientApi, ClientDto} from "../../../../../services/dcr";
import {AnalyticsPageStore} from "../../AnalyticsPageStore";
import {action, computed, makeObservable, observable} from "mobx";
import {
    DcrData360ReportApi,
    DcrStatus, ReportExecutionState, ReportStateDto,
    RequestRecordType,
    RequestStatus
} from "../../../../../services/analytics/dcr";
import {UserDto, UsersApi} from "../../../../../services/management";
import {getApiConfiguration, IExpanded, toDateString} from "../../../../../core";
import {t} from "i18next";
import {SeverityPillColor} from "../../../../../components/severity-pill";

export class DcrReportStore extends TableStore<ReportStateDto & { createdDate: string } & IExpanded> {
    readonly status: DefaultMultiSelectStore<RequestStatus> = new DefaultMultiSelectStore<RequestStatus>([],
        (e) => Promise.resolve(["NeedReverification", "Decline", "NotVerified", "OnVerification", "Partialy", "Verified", "WaitingVerification"]),
        null,
        null,
        "",
        (e) => t("analytics.dcr.statuses." + e))
    readonly entity: DefaultMultiSelectStore<RequestRecordType> = new DefaultMultiSelectStore<RequestRecordType>([],
        (e) => Promise.resolve(["Person", "Organization"]),
        null,
        null,
        "",
        (e) => t("dcrPage.type." + e.toLowerCase()))
    readonly type: DefaultMultiSelectStore<DcrStatus>
        = new DefaultMultiSelectStore<DcrStatus>([],
        (e) => Promise.resolve(["Create", "Update"]),
        null,
        null,
        "",
        (e) => t("dcrPage." + e.toLowerCase()))
    readonly clients: DefaultMultiSelectStore<ClientDto> = new DefaultMultiSelectStore<ClientDto>([],
        (e) => new ClientApi().apiDcrV1ClientsGet(e),
        null,
        null,
        "id",
        "name")
    readonly startDate: DefaultValueStore<Date> = new DefaultValueStore<Date>(null, null, async (e) => await this.updateState());
    readonly endDate: DefaultValueStore<Date> = new DefaultValueStore<Date>(null, null, async (e) => await this.updateState());
    readonly responsible: DefaultMultiSelectStore<UserDto> = new DefaultMultiSelectStore([],
        (x) => new UsersApi(getApiConfiguration()).getUsers(x),
        null,
        null,
        "id",
        "name")

    private store: AnalyticsPageStore;

    constructor(store: AnalyticsPageStore) {
        super();
        this.store = store;
    }

    resetFilters() {
        this.startDate.reset()
        this.endDate.reset()
        this.clients.reset()
        this.status.reset()
        this.type.reset()
        this.entity.reset()
    }

    async createReport(): Promise<void> {
        this.processing = true;
        try {
            await new DcrData360ReportApi(getApiConfiguration()).formReport({
                status: this.status.value?.length ? this.status.value : undefined,
                type: this.type.value?.length ? this.type.value : undefined,
                entity: this.entity.value?.length ? this.entity.value : undefined,
                clients: this.clients.value?.length ? this.clients.value.map(x => x.name!) : undefined,
                startDate: this.startDate.value ? this.startDate.value : undefined,
                endDate: this.endDate.value ? this.endDate.value : undefined,
            })
        } finally {
            this.processing = false;
            await this.updateState()
        }
    }

    async downloadReport(reportName: string): Promise<void> {
        new DcrData360ReportApi(getApiConfiguration()).downloadReport({
            filter: reportName
        }).then(blob => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', reportName);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
        })
    }

    statusHandler(state: ReportExecutionState
    ):
        SeverityPillColor {
        switch (state) {
            case ReportExecutionState.InProcess:
                return 'info'
            case ReportExecutionState.Finished:
                return 'success'
            case ReportExecutionState.Error:
                return 'error'
        }
        return 'success'
    }

    async request(): Promise<PagedItems<ReportStateDto & { createdDate: string } & IExpanded>> {
        const {items, total} = await new DcrData360ReportApi().apiDcrAnalyticsDcrData360ReportReportStateGet({
            page: this.page + 1,
            order: this.order === "ascending" ? "Ascending" : "Descending",
            take: this.size,
            orderField: this.orderBy
        })

        const newList = items?.map(x => ({
            ...x,
            expand: false,
            filters: {
                ...x.filters,
                type: x.filters?.type?.length === 0 ? undefined : x.filters?.type,
                clients: x.filters?.clients?.length === 0 ? undefined : x.filters?.clients,
                entity: x.filters?.entity?.length === 0 ? undefined : x.filters?.entity,
                status: x.filters?.status?.length === 0 ? undefined : x.filters?.status,
                responsibleIds: x.filters?.responsibleIds?.length === 0 ? undefined : x.filters?.status
            },
            createdDate: toDateString(x.formingDateTime)
        })) ?? [];

        return new PagedItems<ReportStateDto & { createdDate: string } & IExpanded>(newList,total)
    }
}