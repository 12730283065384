import {makeAutoObservable} from "mobx";
import {IFormValueWithError} from "../../../../../core";
import i18n from "i18next";

export class MappingItemStore<T extends {
    clientValue: string,
    entityId: string | number
}> implements IFormValueWithError<string | null> {
    _dto: T;
    id: string | number;
    private _value: string | null;
    error: string = "";
    name: string;
    deleteDialogState: boolean = false;
    processing: boolean = false;

    constructor(dto: T, name: string) {
        this._dto = dto;
        this.name = name;
        this.id = dto.entityId;
        this._value = this._dto.clientValue;
        makeAutoObservable(this);
    }

    reset(): void {
        this.value = this._dto.clientValue;
    }

    get valid(): boolean {
        return !this.error
    }

    set value(value: string | null) {
        this._value = value
        this.validate()
    }

    get value(): string | null {
        return this._value
    }

    update(dto: string | null): void {
        this._value = dto;
    }

    validate(): void {
        this.error = !this.value ? i18n.t('errors.empty') : ""
    }

    get equals(): boolean {
        return this.value == this._dto.clientValue;
    }
}

