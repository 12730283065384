/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {Data360UpdateStatus, Data360UpdateStatusFromJSON, Data360UpdateStatusToJSON,} from './Data360UpdateStatus';

/**
 * 
 * @export
 * @interface Data360Update
 */
export interface Data360Update {
    /**
     * 
     * @type {Data360UpdateStatus}
     * @memberof Data360Update
     */
    status?: Data360UpdateStatus;
    /**
     * 
     * @type {string}
     * @memberof Data360Update
     */
    error?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Data360Update
     */
    updater?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Data360Update
     */
    creater?: string | null;
}

export function Data360UpdateFromJSON(json: any): Data360Update {
    return Data360UpdateFromJSONTyped(json, false);
}

export function Data360UpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): Data360Update {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : Data360UpdateStatusFromJSON(json['status']),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'updater': !exists(json, 'updater') ? undefined : json['updater'],
        'creater': !exists(json, 'creater') ? undefined : json['creater'],
    };
}

export function Data360UpdateToJSON(value?: Data360Update | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': Data360UpdateStatusToJSON(value.status),
        'error': value.error,
        'updater': value.updater,
        'creater': value.creater,
    };
}

