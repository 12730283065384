import {makeObservable, observable} from 'mobx';
import {DefaultMultiSelectStore} from '../../../../../../components/shared/DefaultMultiSelectStore';
import {getApiConfiguration, IProcessing} from '../../../../../../core';
import {RoleDto, RolesApi, UserRolesApi,} from '../../../../../../services/management';
import {LoadStore} from '../../../../../utils';
import {UserItemStore} from '../UserItemStore';

export class UserRolesTabStore extends LoadStore implements IProcessing {
    readonly _store: UserItemStore;
    allRolesControl: DefaultMultiSelectStore<RoleDto> =
        new DefaultMultiSelectStore([], async (filters) => {
            const rolesList = await new RolesApi(
                getApiConfiguration()
            ).getRoles(filters);
            return rolesList;
        });
    processing: boolean = false;

    constructor(store: UserItemStore) {
        super();
        this._store = store;
        makeObservable(this, {
            processing: observable,
        });
    }

    async remove(ids: string[]) {
        await new UserRolesApi(
            getApiConfiguration({ successTooltip: false })
        ).removeUserRoles({
            userId: this._store.dto.id,
            setUserRoleDto: ids.map((x) => ({
                roleId: x,
                userId: this._store.dto.id,
            })),
        });
    }


    async set(ids: string[]) {
        await new UserRolesApi(getApiConfiguration()).createUserRoles({
            userId: this._store.dto?.id!,
            setUserRoleDto: ids.map((x) => ({
                roleId: x,
                userId: this._store.dto.id,
            })),
        });
    }

    async submit(): Promise<void> {
        const userRoleIds = this.allRolesControl.firstValue.map((x) => x.id);
        const currentIds = this.allRolesControl.value.map((x) => x.id);

        const deletesIds = userRoleIds.filter((x) => !currentIds.includes(x));
        const insetIds = currentIds.filter((x) => !userRoleIds.includes(x));

        await this.remove(deletesIds);
        await this.set(insetIds);
        await this.allRolesControl.update(this.allRolesControl.value);
    }

    async request(): Promise<void> {
        let filters: any = {
            page: 1,
            size: 1000,
            userId: this._store.dto.id,
        };
        const result = await new UserRolesApi(
            getApiConfiguration()
        ).getUserRoles(filters);

        this.allRolesControl.update(
            result.map((x) => x.role)
        );
    }
}
