/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Exchange.Analytics.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SpecialityAggregation
 */
export interface SpecialityAggregation {
    /**
     * 
     * @type {string}
     * @memberof SpecialityAggregation
     */
    specialityId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpecialityAggregation
     */
    specialityName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SpecialityAggregation
     */
    succeededCount?: number;
    /**
     * 
     * @type {number}
     * @memberof SpecialityAggregation
     */
    failedCount?: number;
}

export function SpecialityAggregationFromJSON(json: any): SpecialityAggregation {
    return SpecialityAggregationFromJSONTyped(json, false);
}

export function SpecialityAggregationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialityAggregation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'specialityId': !exists(json, 'specialityId') ? undefined : json['specialityId'],
        'specialityName': !exists(json, 'specialityName') ? undefined : json['specialityName'],
        'succeededCount': !exists(json, 'succeededCount') ? undefined : json['succeededCount'],
        'failedCount': !exists(json, 'failedCount') ? undefined : json['failedCount'],
    };
}

export function SpecialityAggregationToJSON(value?: SpecialityAggregation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'specialityId': value.specialityId,
        'specialityName': value.specialityName,
        'succeededCount': value.succeededCount,
        'failedCount': value.failedCount,
    };
}

