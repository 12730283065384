import {Box, FormControlLabel, Switch} from '@mui/material';
import {observer} from 'mobx-react';
import {useTranslation} from "react-i18next";
import {AuthorizePermission, ParamsButtonGroup, Progress} from "../../../../components";
import {OrganizationParentSelect} from "../../../../components/select/OrganizationParent/OrganizationParentSelect";
import {AllPermissions} from '../../../../core/const';
import {
    OrganizationParentStore
} from "../../../../stores/management/organizations/details/parentChild/OrganizationParentStore";

interface Props {
    store: OrganizationParentStore;
}

export const OrganizationParent = observer(({ store }: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <div style={{ padding: 10 }}>
                <FormControlLabel
                    labelPlacement="end"
                    label={t('common.Parent')}
                    style={{ marginLeft: 0, marginTop: 12 }}
                    control={
                        <Switch checked={store.hasNoParent} disabled={store._store.isDouble} onClick={() => {
                            store.changeHasNoParent();
                        }} />
                    }
                />
                {!store.hasNoParent &&
                    <>
                        {
                            store.organization &&
                            <OrganizationParentSelect organization={store.organization} style={{marginRight: 20}}
                                                      zIndex={0} disabled={store._store.isDouble}/>
                        }
                        < Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <AuthorizePermission permissions={AllPermissions.organization.parent.update}>
                                <ParamsButtonGroup
                                    testid="organizationParent"
                                    onCancel={() => store.reset()}
                                    onSave={() => store.submit()}
                                    isCancelBtnDisabled={store.equals() || store._store.store.processing}
                                    isSaveBtnDisabled={store._store.store.processing || store.equals() || store._store.isDouble}
                                    processing={store._store.store}
                                />
                            </AuthorizePermission>
                        </Box>
                    </>
                }
            </div>
            {
                (!store.items.length && store.loading) && <Box
                    sx={{
                        flexGrow: 1,
                        py: 3,
                    }}
                >
                    <Progress store={store} />
                </Box>
            }
        </>
    );
});
