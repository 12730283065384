import {makeAutoObservable} from "mobx";
import {DefaultFormStore} from "../../../../components/shared/DefaultFormStore";
import {DefaultValueStore} from "../../../../components/shared/DefaultValueStore";
import {downloadFile, getBlobRequestSettings} from "../../../../core/blob";
import {getApiConfiguration} from "../../../../core";
import {ImportTabsStore} from "./ImportTabsStore";
import {ImportExportLocalitiesApi} from "../../../../services/management/apis/ImportExportLocalitiesApi";
import {ImportManagementApi} from "../../../../services/import";


const ImportAddressTemplate = {
    name: "ImportAddressTemplate.csv",
    path: 'assets/files/ImportAddressTemplate.csv'
}


export class ImportAddressStore {
    readonly importAddressStore: DefaultFormStore<{
        fileObjects: DefaultValueStore<File[]>,
    }> = new DefaultFormStore({
        fileObjects: new DefaultValueStore([] as File[])
    }, async (e) => {
        const over = getBlobRequestSettings();

        await new ImportExportLocalitiesApi(getApiConfiguration()).importLocalities({
            formFile: this.importAddressStore.model.fileObjects[0]
        }, over)
    })

    constructor(store: ImportTabsStore) {
        makeAutoObservable(this)
    }

    async pullFormat(): Promise<void> {
        await new ImportManagementApi(getApiConfiguration()).getDocumentTemplate({importType: 5})
            .then(async x => {
                await downloadFile(await x.blob(),
                    x.headers?.get('Content-Disposition')?.split('filename=')[1]?.split(';')[0] ??
                    `ImportAddressTemplate_${Date.now()}.xlsx`)
            });
    };
}
