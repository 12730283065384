/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {ResDto1, ResDto1FromJSON, ResDto1ToJSON,} from './ResDto1';
import {ResDto2, ResDto2FromJSON, ResDto2ToJSON,} from './ResDto2';

/**
 * 
 * @export
 * @interface FieldsToUpdateDto
 */
export interface FieldsToUpdateDto {
    /**
     * 
     * @type {Array<ResDto1>}
     * @memberof FieldsToUpdateDto
     */
    emptyFields?: Array<ResDto1> | null;
    /**
     * 
     * @type {Array<ResDto2>}
     * @memberof FieldsToUpdateDto
     */
    wrongFieldTypes?: Array<ResDto2> | null;
}

export function FieldsToUpdateDtoFromJSON(json: any): FieldsToUpdateDto {
    return FieldsToUpdateDtoFromJSONTyped(json, false);
}

export function FieldsToUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldsToUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emptyFields': !exists(json, 'emptyFields') ? undefined : (json['emptyFields'] === null ? null : (json['emptyFields'] as Array<any>).map(ResDto1FromJSON)),
        'wrongFieldTypes': !exists(json, 'wrongFieldTypes') ? undefined : (json['wrongFieldTypes'] === null ? null : (json['wrongFieldTypes'] as Array<any>).map(ResDto2FromJSON)),
    };
}

export function FieldsToUpdateDtoToJSON(value?: FieldsToUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emptyFields': value.emptyFields === undefined ? undefined : (value.emptyFields === null ? null : (value.emptyFields as Array<any>).map(ResDto1ToJSON)),
        'wrongFieldTypes': value.wrongFieldTypes === undefined ? undefined : (value.wrongFieldTypes === null ? null : (value.wrongFieldTypes as Array<any>).map(ResDto2ToJSON)),
    };
}

