/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Api.Import
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ImportAction = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;
export type ImportAction = typeof ImportAction[keyof typeof ImportAction];


export function ImportActionFromJSON(json: any): ImportAction {
    return ImportActionFromJSONTyped(json, false);
}

export function ImportActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportAction {
    return json as ImportAction;
}

export function ImportActionToJSON(value?: ImportAction | null): any {
    return value as any;
}

