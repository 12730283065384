import {computed, makeObservable, observable} from 'mobx';
import {OrganizationItemStore} from '../../../index';
import {getApiConfiguration, getObject, setObject} from '../../../../../core';
import {OrganizationDto, OrgNamesApi,} from '../../../../../services/management';
import {PagedItems, TableLocalFilters, TableStore} from '../../../../utils';
import {OrganizationNameItemStore} from "./OrganizationNameItemStore";
import {OrganizationNameCreatorStore} from './OrganizationNameCreatorStore';

export class OrganizationNameTableStore extends TableStore<OrganizationNameItemStore> {
    _orgStore: OrganizationItemStore
    _dto: OrganizationDto
    organizationId: string
    current: OrganizationNameItemStore | undefined | null;
    readonly creator: OrganizationNameCreatorStore
    _listNames: string[] = [];

    constructor(orgStore: OrganizationItemStore, dto: OrganizationDto) {
        super();
        this._orgStore = orgStore
        this._dto = dto
        this.organizationId = this._dto.id

        const filters = getObject<TableLocalFilters<OrganizationNameItemStore>>('organizations-name-store-id-' + this._dto.id);

        if (filters) {
            this._page = filters.page;
            this._size = filters.size;
            this.search.setValueWithoutEffects(filters.search);;
            this._order = filters.order;
            this._orderBy = filters.orderBy;
        }

        this.creator = new OrganizationNameCreatorStore(this);

        makeObservable(this, {
            organizationId: observable,
            current: observable,
            _listNames: observable,
            listNames: computed,
        });
    }

    get listNames(): string[] {
        return this.items.map(x => x.dto?.typeName?.nameRus)
    }

    async request(): Promise<PagedItems<OrganizationNameItemStore>> {
        const countFilters = {
            organizationId: this._dto.id,
        }
        const api: OrgNamesApi = new OrgNamesApi(getApiConfiguration());
        const count = await api.getOrgNamesCount(countFilters);

        const filters = {
            page: this.page + 1,
            size: count.count,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            organizationId: this.organizationId,
        };
        const result = await api.getOrgNames(filters);

        const newList: OrganizationNameItemStore[] = [];
        const syndicated = result?.find(x => x.typeName?.nameRus.toLowerCase() === 'синдикативное');
        if(!!syndicated) {
            newList.push(new OrganizationNameItemStore(this, syndicated))
        };
        for (const item of result) {
            if (item.id === syndicated?.id) continue;
            newList.push(new OrganizationNameItemStore(this, item))
        };

        return new PagedItems<OrganizationNameItemStore>(newList,count.count)
    };


    get isDouble(): boolean {
        return this._orgStore.isDouble;
    }

    onFiltersSave() {
        setObject<TableLocalFilters<OrganizationNameItemStore>>('organizations-name-store-id-' + this._dto.id, {
            page: this.page,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
        });
    }
}
