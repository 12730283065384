import {getApiConfiguration} from '../../../../../core';
import {PersonEmailStatusesApi} from "../../../../../services/management";
import {DictionaryCreateEntity, DictionaryEntity, DictionaryTableStore} from "../../DictionaryTableStore";
import {DictionaryPersonStore} from "../DictionaryPersonStore";

export class PersonEmailStatusTableStore extends DictionaryTableStore {
    constructor(store: DictionaryPersonStore) {
        super(store, 'email-status-store');
    }

    async Create(model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new PersonEmailStatusesApi(getApiConfiguration()).createPersonEmailStatus({setEmailStatusDto: model});
    }

    async Delete(id: string): Promise<DictionaryEntity> {
        return await new PersonEmailStatusesApi(getApiConfiguration()).deletePersonEmailStatus({id: id});
    }

    async GetCount(search: string): Promise<{ count: number }> {
        return await new PersonEmailStatusesApi(getApiConfiguration()).getPersonEmailStatusesCount({search: search});
    }

    async GetList(filters: any): Promise<DictionaryEntity[]> {
        return await new PersonEmailStatusesApi(getApiConfiguration()).getPersonEmailStatuses(filters)
    }

    async Update(id: string, model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new PersonEmailStatusesApi(getApiConfiguration()).updatePersonEmailStatus({id, setEmailStatusDto: model});
    }
}
