/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    ListSortDirection,
    OCMObjectState,
    SetWebsiteStatusDto,
    SetWebsiteStatusDtoToJSON,
    WebsiteStatusDto,
    WebsiteStatusDtoFromJSON,
} from '../models';

export interface CreatePersonWebsiteStatusRequest {
    setWebsiteStatusDto: SetWebsiteStatusDto;
}

export interface DeletePersonWebsiteStatusRequest {
    id: string;
}

export interface GetPersonWebsiteStatusByIdRequest {
    id: string;
}

export interface GetPersonWebsiteStatusByNameRequest {
    name: string;
}

export interface GetPersonWebsiteStatusesRequest {
    search?: string;
    orderBy?: string;
    searchByExternalId?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface GetPersonWebsiteStatusesCountRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface UpdatePersonWebsiteStatusRequest {
    id: string;
    setWebsiteStatusDto: SetWebsiteStatusDto;
}

/**
 * 
 */
export class PersonWebsiteStatusesApi extends runtime.BaseAPI {

    /**
     */
    async createPersonWebsiteStatusRaw(requestParameters: CreatePersonWebsiteStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WebsiteStatusDto>> {
        if (requestParameters.setWebsiteStatusDto === null || requestParameters.setWebsiteStatusDto === undefined) {
            throw new runtime.RequiredError('setWebsiteStatusDto','Required parameter requestParameters.setWebsiteStatusDto was null or undefined when calling createPersonWebsiteStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/websites/statuses/person`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetWebsiteStatusDtoToJSON(requestParameters.setWebsiteStatusDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebsiteStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async createPersonWebsiteStatus(requestParameters: CreatePersonWebsiteStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WebsiteStatusDto> {
        const response = await this.createPersonWebsiteStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePersonWebsiteStatusRaw(requestParameters: DeletePersonWebsiteStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WebsiteStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePersonWebsiteStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/websites/statuses/person/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebsiteStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async deletePersonWebsiteStatus(requestParameters: DeletePersonWebsiteStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WebsiteStatusDto> {
        const response = await this.deletePersonWebsiteStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonWebsiteStatusByIdRaw(requestParameters: GetPersonWebsiteStatusByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WebsiteStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPersonWebsiteStatusById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/websites/statuses/person/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebsiteStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonWebsiteStatusById(requestParameters: GetPersonWebsiteStatusByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WebsiteStatusDto> {
        const response = await this.getPersonWebsiteStatusByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonWebsiteStatusByNameRaw(requestParameters: GetPersonWebsiteStatusByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WebsiteStatusDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getPersonWebsiteStatusByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/websites/statuses/person/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebsiteStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonWebsiteStatusByName(requestParameters: GetPersonWebsiteStatusByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WebsiteStatusDto> {
        const response = await this.getPersonWebsiteStatusByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonWebsiteStatusesRaw(requestParameters: GetPersonWebsiteStatusesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<WebsiteStatusDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/websites/statuses/person`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WebsiteStatusDtoFromJSON));
    }

    /**
     */
    async getPersonWebsiteStatuses(requestParameters: GetPersonWebsiteStatusesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<WebsiteStatusDto>> {
        const response = await this.getPersonWebsiteStatusesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonWebsiteStatusesCountRaw(requestParameters: GetPersonWebsiteStatusesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/websites/statuses/person/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonWebsiteStatusesCount(requestParameters: GetPersonWebsiteStatusesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPersonWebsiteStatusesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePersonWebsiteStatusRaw(requestParameters: UpdatePersonWebsiteStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WebsiteStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePersonWebsiteStatus.');
        }

        if (requestParameters.setWebsiteStatusDto === null || requestParameters.setWebsiteStatusDto === undefined) {
            throw new runtime.RequiredError('setWebsiteStatusDto','Required parameter requestParameters.setWebsiteStatusDto was null or undefined when calling updatePersonWebsiteStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/websites/statuses/person/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetWebsiteStatusDtoToJSON(requestParameters.setWebsiteStatusDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebsiteStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async updatePersonWebsiteStatus(requestParameters: UpdatePersonWebsiteStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WebsiteStatusDto> {
        const response = await this.updatePersonWebsiteStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
