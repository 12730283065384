import {makeObservable, observable} from 'mobx';
import {getApiConfiguration, getObject, setObject} from '../../../../../../core';
import {PagedItems, TableLocalFilters, TableStore} from '../../../../../utils';
import {PersonPhoneItemStore} from "./PersonPhoneItemStore";
import {DefaultSelectStore} from "../../../../../../components/shared/DefaultSelectStore";
import {DefaultValueStore} from "../../../../../../components/shared/DefaultValueStore";
import i18n from "i18next";
import {
    GetPersonPhonesCountRequest,
    PersonDto,
    PersonPhoneCategoriesApi,
    PersonPhonesApi,
    PersonPhoneStatusesApi,
    PersonPhoneTypesApi,
    PhoneCategoryDto,
    PhoneStatusDto,
    PhoneTypeDto
} from '../../../../../../services/management';
import {DefaultFormModalStore} from "../../../../../../components/shared/DefaultFormModalStore";
import {JurisdictionLocalSetting} from "../../../../../authorization/AuthorizationStore";
import {PersonItemStore} from "../../../PersonItemStore";
import {DefaultMultiSelectStore} from "../../../../../../components/shared/DefaultMultiSelectStore";

export class PersonPhoneTableStore extends TableStore<PersonPhoneItemStore> {
    _persStore: PersonItemStore
    _dto: PersonDto
    personId: string
    current: PersonPhoneItemStore | undefined | null;
    readonly creator: DefaultFormModalStore<{
        phone: DefaultValueStore<string>,
        type: DefaultSelectStore<PhoneTypeDto>,
        status: DefaultSelectStore<PhoneStatusDto>,
        category: DefaultSelectStore<PhoneCategoryDto>,
        description: DefaultValueStore<string>
    }> = new DefaultFormModalStore({
        phone: new DefaultValueStore<string>("", (value) =>
            new RegExp(this.jurisdictionLocalSetting?.phoneNumberRegex!)
                .test(value ?? "") ? "" : i18n.t('dcrPage.errors.phoneFormat').replace("{length}", this.jurisdictionLocalSetting?.numberLength!)),
        type: new DefaultSelectStore<PhoneTypeDto>(
            null, (x) => new PersonPhoneTypesApi(getApiConfiguration()).getPersonPhoneTypes(x)),
        status: new DefaultSelectStore<PhoneStatusDto>(
            null, (x) => new PersonPhoneStatusesApi(getApiConfiguration()).getPersonPhoneStatuses(x)),
        category: new DefaultSelectStore<PhoneCategoryDto>(
            null, (x) => new PersonPhoneCategoriesApi(getApiConfiguration()).getPersonPhoneCategories(x)),
        description: new DefaultValueStore<string>("", (value) =>
            value?.length! > 180 ? i18n.t('errors.lengthMax').replace("lengthMax", "180") : "")
    }, (model) => this.create(model));

    statusFilter: DefaultMultiSelectStore<PhoneStatusDto> = new DefaultMultiSelectStore<PhoneStatusDto>([],
        (x) => new PersonPhoneStatusesApi(getApiConfiguration()).getPersonPhoneStatuses(x), (x) => this.updateState());
    categoryFilter: DefaultMultiSelectStore<PhoneCategoryDto> = new DefaultMultiSelectStore<PhoneCategoryDto>([],
        (x) => new PersonPhoneCategoriesApi(getApiConfiguration()).getPersonPhoneCategories(x), (x) => this.updateState());
    description: DefaultValueStore<string> = new DefaultValueStore<string>("", null, (x) => this.updateState());

    constructor(persStore: PersonItemStore, dto: PersonDto) {
        super();
        this._persStore = persStore
        this._dto = dto
        this.personId = this._dto.id

        this.creator.fields.type.required = true;
        this.creator.fields.status.required = true;
        this.creator.fields.category.required = true;

        const filters = getObject<TableLocalFilters<PersonPhoneItemStore>>('persons-phone-store-id-' + this._dto.id);

        if (filters) {
            this._page = filters.page;
            this._size = filters.size;
            this.search.setValueWithoutEffects(filters.search);;
            this._order = filters.order;
            this._orderBy = filters.orderBy;
        }

        makeObservable(this, {
            personId: observable,
            current: observable,
        });
    }

    get isDisable(): boolean {
        return this._persStore.disable;
    }

    get jurisdictionLocalSetting(): JurisdictionLocalSetting | undefined {
        return this._persStore._store.store.store.authorizationStore?.jurisdictionsSettings
    }

    async request(): Promise<PagedItems<PersonPhoneItemStore>> {
        const countFilters: GetPersonPhonesCountRequest = {
            personId: this._dto.id,
            phoneStatusIds: this.statusFilter.value?.length ? this.statusFilter.value.map(x => x.id) : undefined,
            phoneCategoryIds: this.categoryFilter.value?.length ? this.categoryFilter.value.map(x => x.id) : undefined,
        }
        const api: PersonPhonesApi = new PersonPhonesApi(getApiConfiguration());
        const count = await api.getPersonPhonesCount(countFilters);

        const filters = {
            page: this.page + 1,
            size: count.count,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            personId: this.personId,
            phoneStatusIds: this.statusFilter.value?.length ? this.statusFilter.value.map(x => x.id) : undefined,
            phoneCategoryIds: this.categoryFilter.value?.length ? this.categoryFilter.value.map(x => x.id) : undefined,
            description: this.description.value ?? ""
        };
        const result = await api.getPersonPhones(filters);

        const newList: PersonPhoneItemStore[] = [];
        for (const item of result) {
            const existsItem = this.items.find(t => t.dto.id === item.id);
            if (existsItem) {
                existsItem.update(item);
                newList.push(existsItem);
                continue;
            }
            newList.push(new PersonPhoneItemStore(this, item))
        }

        return new PagedItems<PersonPhoneItemStore>(newList, count.count)
    };

    async create(model: any): Promise<void> {
        this.processing = true;
        const api: PersonPhonesApi = new PersonPhonesApi(getApiConfiguration());
        try {
            await api.setPersonPhones({
                personId: this._dto.id,
                setPersonPhoneDto: [
                    {
                        personId: this._dto.id,
                        phoneCategoryId: this.creator.fields.category.value?.id!,
                        phoneNumber: this.creator.fields.phone.value!,
                        phoneStatusId: this.creator.fields.status.value?.id!,
                        phoneTypeId: this.creator.fields.type.value?.id!,
                        description: this.creator.fields.description.value,
                    }
                ]
            });
            await this.pull()
        } finally {
            this.processing = false;
        }
    };

    onFiltersSave() {
        setObject<TableLocalFilters<PersonPhoneItemStore>>('persons-phone-store-id-' + this._dto.id, {
            page: this.page,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
        });
    }
}
