import React from "react";
import {Box, ListItem, ListItemText, Typography} from "@mui/material";
import {NavLink} from "../../components/NavLink";
import {Link as LinkIcon} from "../../../src/icons/link";
import {RequestField} from "../../stores/management/dcr/types";
import {useTranslation} from "react-i18next";

interface DcrListItemProps {
    name: string;
    value?: string;
    children?: React.ReactNode;
    width?: string;
    slim?: boolean;
    style?: React.CSSProperties;
    field?: RequestField;
    link?: string;
}

export const DcrListItem = (props: DcrListItemProps) => {
    const {name, value, children, width, slim, style, field, link} = props;
    const {t} = useTranslation();

    return (
        <ListItem
            sx={{px: 0, py: 0.5}}
            // style={{width: width ? width : "auto", ...style, alignItems: "flex-start"}}
            style={{width: !slim ? width : "auto"}}
        >
            <ListItemText
                disableTypography
                style={style}
                primary={name && (
                    <Box  sx={{minWidth: 180, display: "flex", flexDirection: 'row', alignItems: 'center'}}>
                        <Typography
                            // sx={{minWidth: 180}}
                            variant="subtitle2"
                            color="textSecondary"
                            style={{marginBottom: 'auto', maxWidth: 160}}
                        >
                            {name}
                        </Typography>
                        {
                            ((!!field?.link && field?.store.value) || link) &&
                            <NavLink to={(field?.link ?? link)!} style={{height: 2, marginLeft: 2, marginBottom: 'auto', marginTop: '10px'}}
                                     target="_blank">
                                <LinkIcon fontSize="small"  />
                            </NavLink>
                        }
                    </Box>
                )}
                secondary={(
                    <Box
                        sx={{
                            flex: 1,
                            mt: 0,
                            ml: 3,
                            marginLeft: 'unset',
                            marginRight: '24px'
                            // width: 280
                        }}
                    >
                        {children || (
                            <Typography
                                variant="body1"
                            >
                                {value ?? t('dcrPage.empty')}
                            </Typography>
                        )}
                    </Box>
                )}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    my: 0
                }}
            />
        </ListItem>
    )

}
