import {observer} from "mobx-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {SimpleButton} from "../../../../components";
import {RequestStatusEnum} from "../../../../stores/management/dcr/types";
import {CrmSpecification} from "../../../../stores/management/dcr/requests/specifications/clients/CrmSpecification";

interface Props {
    store: CrmSpecification;
}

export const CrmDetailsButtonsComponent = observer(({store}: Props) => {
    const {t} = useTranslation();

    return (
        <>
            {
                store.store.edit &&
                <>
                    <SimpleButton
                        onClick={async () => {
                            await store.changeStatus(RequestStatusEnum.Verified)
                        }}
                        title={t('dcrPage.buttons.accept')}
                        color="secondary"
                        disabled={store.store.loading || !store.valid}
                    />
                </>
            }
            {
                store.store.isTake &&
                <SimpleButton
                    onClick={async () => await store.changeStatus(RequestStatusEnum.Decline)}
                    title={t('dcrPage.buttons.decline')}
                    disabled={store.store.loading}
                    color="error"
                    variant="outlined"
                />
            }
        </>

    );
});
