/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 * 
 * @export
 * @interface CreateAssignedPersonJobTagDto
 */
export interface CreateAssignedPersonJobTagDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAssignedPersonJobTagDto
     */
    jobId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAssignedPersonJobTagDto
     */
    tagId?: string | null;
}

export function CreateAssignedPersonJobTagDtoFromJSON(json: any): CreateAssignedPersonJobTagDto {
    return CreateAssignedPersonJobTagDtoFromJSONTyped(json, false);
}

export function CreateAssignedPersonJobTagDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAssignedPersonJobTagDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobId': !exists(json, 'jobId') ? undefined : json['jobId'],
        'tagId': !exists(json, 'tagId') ? undefined : json['tagId'],
    };
}

export function CreateAssignedPersonJobTagDtoToJSON(value?: CreateAssignedPersonJobTagDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobId': value.jobId,
        'tagId': value.tagId,
    };
}

