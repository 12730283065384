import { observer } from 'mobx-react';
// import { ConnectionStatus } from '../stores/signalr';
// import Brightness1Icon from '@mui/icons-material/Brightness1';

interface ConnectionStatusProps {
    // status: ConnectionStatus,
    style?: React.CSSProperties
}

export const ConnectionIndicator = observer(({ style }: ConnectionStatusProps) => {
    // const renderStatus = () => {
    //     switch (status) {
    //         case ConnectionStatus.Online: return <Brightness1Icon color='primary' style={style} />;
    //         case ConnectionStatus.Connecting: return <Brightness1Icon color='secondary' style={style} />;
    //         case ConnectionStatus.Offline: return <Brightness1Icon color='disabled' style={style} />;
    //         default: return '';
    //     }
    // };

    return (
        <>
            {/*{renderStatus()}*/}
        </>
    );
});
