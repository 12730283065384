/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    ListSortDirection,
    OCMObjectState,
    SetSurnameDto,
    SetSurnameDtoToJSON,
    SurnameDto,
    SurnameDtoFromJSON,
} from '../models';

export interface CreateSurnameRequest {
    setSurnameDto: SetSurnameDto;
}

export interface DeleteSurnameRequest {
    id: string;
}

export interface GetSurnameByIdRequest {
    id: string;
}

export interface GetSurnameByNameRequest {
    name: string;
}

export interface GetSurnamesRequest {
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface GetSurnamesBySearchRequest {
    search?: string;
}

export interface GetSurnamesCountRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface GetSurnamesCountFallbackRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface GetSurnamesFallbackRequest {
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface UpdateSurnameRequest {
    id: string;
    setSurnameDto: SetSurnameDto;
}

/**
 * 
 */
export class SurnamesApi extends runtime.BaseAPI {

    /**
     */
    async createSurnameRaw(requestParameters: CreateSurnameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SurnameDto>> {
        if (requestParameters.setSurnameDto === null || requestParameters.setSurnameDto === undefined) {
            throw new runtime.RequiredError('setSurnameDto','Required parameter requestParameters.setSurnameDto was null or undefined when calling createSurname.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/persons/surnames`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetSurnameDtoToJSON(requestParameters.setSurnameDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SurnameDtoFromJSON(jsonValue));
    }

    /**
     */
    async createSurname(requestParameters: CreateSurnameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SurnameDto> {
        const response = await this.createSurnameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteSurnameRaw(requestParameters: DeleteSurnameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SurnameDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSurname.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/surnames/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SurnameDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteSurname(requestParameters: DeleteSurnameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SurnameDto> {
        const response = await this.deleteSurnameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSurnameByIdRaw(requestParameters: GetSurnameByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SurnameDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSurnameById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/surnames/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SurnameDtoFromJSON(jsonValue));
    }

    /**
     */
    async getSurnameById(requestParameters: GetSurnameByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SurnameDto> {
        const response = await this.getSurnameByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSurnameByNameRaw(requestParameters: GetSurnameByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SurnameDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getSurnameByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/surnames/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SurnameDtoFromJSON(jsonValue));
    }

    /**
     */
    async getSurnameByName(requestParameters: GetSurnameByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SurnameDto> {
        const response = await this.getSurnameByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSurnamesRaw(requestParameters: GetSurnamesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<SurnameDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/surnames`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SurnameDtoFromJSON));
    }

    /**
     */
    async getSurnames(requestParameters: GetSurnamesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<SurnameDto>> {
        const response = await this.getSurnamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSurnamesBySearchRaw(requestParameters: GetSurnamesBySearchRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<SurnameDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/surnames/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SurnameDtoFromJSON));
    }

    /**
     */
    async getSurnamesBySearch(requestParameters: GetSurnamesBySearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<SurnameDto>> {
        const response = await this.getSurnamesBySearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSurnamesCountRaw(requestParameters: GetSurnamesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/surnames/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getSurnamesCount(requestParameters: GetSurnamesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getSurnamesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSurnamesCountFallbackRaw(requestParameters: GetSurnamesCountFallbackRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/surnames/count/fallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getSurnamesCountFallback(requestParameters: GetSurnamesCountFallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getSurnamesCountFallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSurnamesFallbackRaw(requestParameters: GetSurnamesFallbackRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<SurnameDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/surnames/fallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SurnameDtoFromJSON));
    }

    /**
     */
    async getSurnamesFallback(requestParameters: GetSurnamesFallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<SurnameDto>> {
        const response = await this.getSurnamesFallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateSurnameRaw(requestParameters: UpdateSurnameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SurnameDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSurname.');
        }

        if (requestParameters.setSurnameDto === null || requestParameters.setSurnameDto === undefined) {
            throw new runtime.RequiredError('setSurnameDto','Required parameter requestParameters.setSurnameDto was null or undefined when calling updateSurname.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/persons/surnames/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetSurnameDtoToJSON(requestParameters.setSurnameDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SurnameDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateSurname(requestParameters: UpdateSurnameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SurnameDto> {
        const response = await this.updateSurnameRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
