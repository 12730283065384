/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    PersonDepJobDto,
    PersonDepJobDtoFromJSON,
    PersonDepJobDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetPersonDepJobDto,
    SetPersonDepJobDtoFromJSON,
    SetPersonDepJobDtoToJSON,
    UpdatePersonDepJobDto,
    UpdatePersonDepJobDtoFromJSON,
    UpdatePersonDepJobDtoToJSON,
} from '../models';

export interface CreatePersonDepJobRequest {
    setPersonDepJobDto?: SetPersonDepJobDto;
}

export interface DeletePersonDepJobRequest {
    id: string;
}

export interface GetPersonDepJobsRequest {
    personId: string;
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    postIds?: Array<string>;
    statusIds?: Array<string>;
    organizationIds?: Array<string>;
    fullEquals?: boolean;
}

export interface GetPersonDepJobsCountRequest {
    personId: string;
    search?: string;
    postIds?: Array<string>;
    statusIds?: Array<string>;
    organizationIds?: Array<string>;
    fullEquals?: boolean;
}

export interface TickDepJobRequest {
    id: string;
}

export interface UpdatePersonDepJobRequest {
    id: string;
    updatePersonDepJobDto: UpdatePersonDepJobDto;
}

/**
 * 
 */
export class DepJobsApi extends runtime.BaseAPI {

    /**
     */
    async createPersonDepJobRaw(requestParameters: CreatePersonDepJobRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonDepJobDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/depjobs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPersonDepJobDtoToJSON(requestParameters.setPersonDepJobDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDepJobDtoFromJSON(jsonValue));
    }

    /**
     */
    async createPersonDepJob(requestParameters: CreatePersonDepJobRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonDepJobDto> {
        const response = await this.createPersonDepJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePersonDepJobRaw(requestParameters: DeletePersonDepJobRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonDepJobDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePersonDepJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/depjobs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDepJobDtoFromJSON(jsonValue));
    }

    /**
     */
    async deletePersonDepJob(requestParameters: DeletePersonDepJobRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonDepJobDto> {
        const response = await this.deletePersonDepJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonDepJobsRaw(requestParameters: GetPersonDepJobsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonDepJobDto>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getPersonDepJobs.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.postIds) {
            queryParameters['postIds'] = requestParameters.postIds;
        }

        if (requestParameters.statusIds) {
            queryParameters['statusIds'] = requestParameters.statusIds;
        }

        if (requestParameters.organizationIds) {
            queryParameters['organizationIds'] = requestParameters.organizationIds;
        }

        if (requestParameters.fullEquals !== undefined) {
            queryParameters['fullEquals'] = requestParameters.fullEquals;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/depjobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonDepJobDtoFromJSON));
    }

    /**
     */
    async getPersonDepJobs(requestParameters: GetPersonDepJobsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonDepJobDto>> {
        const response = await this.getPersonDepJobsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonDepJobsCountRaw(requestParameters: GetPersonDepJobsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getPersonDepJobsCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.postIds) {
            queryParameters['postIds'] = requestParameters.postIds;
        }

        if (requestParameters.statusIds) {
            queryParameters['statusIds'] = requestParameters.statusIds;
        }

        if (requestParameters.organizationIds) {
            queryParameters['organizationIds'] = requestParameters.organizationIds;
        }

        if (requestParameters.fullEquals !== undefined) {
            queryParameters['fullEquals'] = requestParameters.fullEquals;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/depjobs/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonDepJobsCount(requestParameters: GetPersonDepJobsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPersonDepJobsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tickDepJobRaw(requestParameters: TickDepJobRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonDepJobDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tickDepJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/depjobs/{id}/tick`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDepJobDtoFromJSON(jsonValue));
    }

    /**
     */
    async tickDepJob(requestParameters: TickDepJobRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonDepJobDto> {
        const response = await this.tickDepJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePersonDepJobRaw(requestParameters: UpdatePersonDepJobRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonDepJobDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePersonDepJob.');
        }

        if (requestParameters.updatePersonDepJobDto === null || requestParameters.updatePersonDepJobDto === undefined) {
            throw new runtime.RequiredError('updatePersonDepJobDto','Required parameter requestParameters.updatePersonDepJobDto was null or undefined when calling updatePersonDepJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/depjobs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePersonDepJobDtoToJSON(requestParameters.updatePersonDepJobDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDepJobDtoFromJSON(jsonValue));
    }

    /**
     */
    async updatePersonDepJob(requestParameters: UpdatePersonDepJobRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonDepJobDto> {
        const response = await this.updatePersonDepJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
