import {makeAutoObservable} from 'mobx';
import {RootStore} from '..';
import {getObject, IExpanded, IHasTabs, setObject} from '../../core';
import {UsersPageStore} from "./users/UsersPageStore";
import {ClientTableStore} from "./clients/ClientTableStore";
import {OrganizationTableStore} from './organizations/OrganizationTableStore';
import {RequestTableStore} from "./dcr/requests/RequestTableStore";
import {ResponseTableStore} from "./dcr/responses/ResponseTableStore";
import {ImportTabsStore} from './data/import/ImportTabsStore';
import {ExportTabsStore} from './data/export/ExportTabsStore';
import {AdminStore} from "./admin/AdminStore";
import {AnalyticsPageStore} from "./analytics/AnalyticsPageStore";
import {DictionaryPersonStore} from "./dictionaries/persons/DictionaryPersonStore";
import {DictionaryOrganizationsStore} from "./dictionaries/organizations/DictionaryOrganizationsStore";
import {JurisdictionsTableStore} from "./jurisdictions/JurisdictionsTableStore";
import {PersonTableStore} from "./persons/PersonTableStore";

interface ManagementStoreLocalFilters {
    tabIndex: number;
    expand: boolean
}

export class ManagementStore implements IHasTabs, IExpanded {
    readonly store: RootStore;

    readonly dictionaryPersonStore: DictionaryPersonStore;
    readonly dictionaryOrganizationsStore: DictionaryOrganizationsStore;
    readonly personStore: PersonTableStore;
    readonly clientTableStore: ClientTableStore;
    readonly organizationTableStore: OrganizationTableStore;
    readonly usersPageStore: UsersPageStore;
    readonly dcrRequestStore: RequestTableStore;
    readonly dcrResponseStore: ResponseTableStore;
    readonly importStore: ImportTabsStore;
    readonly exportStore: ExportTabsStore;
    readonly adminStore: AdminStore;
    readonly dashboardsPageStore: AnalyticsPageStore;
    readonly jurisdictionsTableStore: JurisdictionsTableStore;

    private _tabIndex: number = 0;
    private _expand: boolean = false;

    constructor(store: RootStore) {
        this.store = store;
        this.dictionaryPersonStore = new DictionaryPersonStore(this);
        this.dictionaryOrganizationsStore = new DictionaryOrganizationsStore(this);
        this.personStore = new PersonTableStore(this);
        this.organizationTableStore = new OrganizationTableStore(this);
        this.usersPageStore = new UsersPageStore(this);
        this.dcrRequestStore = new RequestTableStore(this);
        this.dcrResponseStore = new ResponseTableStore(this);
        this.clientTableStore = new ClientTableStore(this);
        this.importStore = new ImportTabsStore(this);
        this.exportStore = new ExportTabsStore(this);
        this.adminStore = new AdminStore(this);
        this.dashboardsPageStore = new AnalyticsPageStore(this);
        this.jurisdictionsTableStore = new JurisdictionsTableStore(this)

        const filters = getObject<ManagementStoreLocalFilters>('management-store');
        if (filters) {
            this._tabIndex = filters.tabIndex;
            this._expand = filters.expand;
        }
        makeAutoObservable(this);
    }

    get expand() {
        return this._expand;
    }

    set expand(value: boolean) {
        this._expand = value;
        this.saveFilters();
    }

    get tabIndex() {
        return this._tabIndex;
    }

    set tabIndex(value: number) {
        this._tabIndex = value;
        this.saveFilters();
        this.updateState();
    }

    updateState() {
        if (this._tabIndex === 0) {

        } else if (this._tabIndex === 1) {
            this.personStore.updateState();
        } else if (this._tabIndex === 2) {
            this.organizationTableStore.updateState();
        } else if (this._tabIndex === 3) {
            this.dictionaryPersonStore.updateState();
        } else if (this._tabIndex === 4) {
            this.dictionaryOrganizationsStore.updateState();
        } else if (this._tabIndex === 5) {
            this.usersPageStore.updateState();
        } else if (this._tabIndex === 6) {
            this.clientTableStore.updateState();
        } else if (this._tabIndex === 7) {
            this.dcrRequestStore.updateState()
        } else if (this._tabIndex === 8) {
            this.dcrResponseStore.updateState()
        } else if (this._tabIndex === 9) {
            this.importStore.updateState()
        } else if (this._tabIndex === 10) {
            this.exportStore.updateState()
        } else if ([12, 13, 14, 15].indexOf(this._tabIndex) > -1) {
            this.dashboardsPageStore.updateState()
        }
        else if (this._tabIndex === 16) {
            this.jurisdictionsTableStore.updateState()
        }
    }

    saveFilters() {
        setObject<ManagementStoreLocalFilters>('management-store', {
            tabIndex: this._tabIndex,
            expand: this._expand
        });
    }
}
