import { observer } from "mobx-react";
import { Body, Cell, EmptyRow, ExpandButton, ExpandRow, HCell, Head, Pagination, Row } from "../../../../../../components";
import { useTranslation } from "react-i18next";
import React, { Fragment, useState } from 'react';
import { Container, Table, Typography } from "@mui/material";
import { PeopleDetails } from "./PeopleDetails";
import {
    PeopleTableTabStore
} from "../../../../../../stores/management/users/tabs/Groups/tabs/PeopleTableTab/PeopleTableTabStore";
import {StoreProps, StyleProps} from "../../../../../../stores";


export const PeoplesTableTab = observer(({ store, style }: StoreProps<PeopleTableTabStore> & StyleProps) => {
    const { t } = useTranslation();
    const styleText = { marginLeft: 'auto', paddingRight: 16 };

    return (
        <Container maxWidth="xl" style={{ padding: 0 }} sx={{ mt: 2, mb: 2 }}>
            <Table sx={{ minWidth: 600 }}>
                <Head>
                    <HCell width='5%' testid="DCRGroupPeoples-expand"></HCell>
                    <HCell store={store} testid="DCRGroupPeoples-users">{t('usersPage.dcrGroup.users')}</HCell>
                    <HCell store={store} styleText={styleText} testid="DCRGroupPeoples-clients">{t('usersPage.dcrGroup.clients')}</HCell>
                    <HCell store={store} styleText={styleText} testid="DCRGroupPeoples-entities">{t('usersPage.dcrGroup.entities')}</HCell>
                    <HCell store={store} styleText={styleText} testid="DCRGroupPeoples-typeRequest">{t('usersPage.dcrGroup.typeRequest')}</HCell>
                    <HCell width='5%' testid="DCRGroupPeoples-action"></HCell>
                </Head>
                <Body>
                    {
                        store.items.length > 0
                            ? store.items.map(item => {
                                return (<Fragment key={item._dto.userId}>
                                    <Row
                                        key={item._dto.userId}
                                        item={item}
                                    >
                                        <ExpandButton item={item} isOpen={item.expand} testid="DCRGroupPeoples"/>

                                        <Cell testid="DCRGroupPeoples-users">
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {item._dto.userName}
                                            </Typography>
                                        </Cell>
                                        <Cell testid="DCRGroupPeoples-clients">
                                            <Typography variant="subtitle2" align='right'
                                                        color="textSecondary">{item.form.model.clients.length}</Typography>
                                        </Cell>
                                        <Cell testid="DCRGroupPeoples-entities">
                                            <Typography variant="subtitle2" align='right'
                                                        color="textSecondary">{item.form.model.entityTypes.length}</Typography>
                                        </Cell>
                                        <Cell testid="DCRGroupPeoples-typeRequest">
                                            <Typography variant="subtitle2" align='right'
                                                        color="textSecondary">{item.form.model.dcrStatuses.length}</Typography>
                                        </Cell>
                                        <Cell />
                                    </Row>
                                    {item.expand && (
                                        <ExpandRow key={`${item._dto.userId}_`} item={item}>
                                            <PeopleDetails store={item} />
                                        </ExpandRow>)}
                                </Fragment>)
                            })
                            : <EmptyRow />
                    }
                </Body>
            </Table>
            {store.items.length > 0 && <Pagination store={store} />}

        </Container>
    );
});

