/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OrganizationNoteDto,
    OrganizationNoteDtoFromJSON,
    OrganizationNoteDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetOrganizationNoteDto,
    SetOrganizationNoteDtoFromJSON,
    SetOrganizationNoteDtoToJSON,
} from '../models';

export interface CreateOrganizationNoteRequest {
    setOrganizationNoteDto: SetOrganizationNoteDto;
}

export interface DeleteOrganizationNoteRequest {
    id: string;
}

export interface GetOrganizationNoteByIdRequest {
    id: string;
}

export interface GetOrganizationNotesRequest {
    organizationId?: string;
    createdByUserId?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetOrganizationNotesCountRequest {
    organizationId?: string;
    createdByUserId?: string;
}

export interface UpdateOrganizationNoteRequest {
    id: string;
    setOrganizationNoteDto: SetOrganizationNoteDto;
}

/**
 * 
 */
export class OrganizationNotesApi extends runtime.BaseAPI {

    /**
     */
    async createOrganizationNoteRaw(requestParameters: CreateOrganizationNoteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationNoteDto>> {
        if (requestParameters.setOrganizationNoteDto === null || requestParameters.setOrganizationNoteDto === undefined) {
            throw new runtime.RequiredError('setOrganizationNoteDto','Required parameter requestParameters.setOrganizationNoteDto was null or undefined when calling createOrganizationNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/Organizations/notes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetOrganizationNoteDtoToJSON(requestParameters.setOrganizationNoteDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationNoteDtoFromJSON(jsonValue));
    }

    /**
     */
    async createOrganizationNote(requestParameters: CreateOrganizationNoteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationNoteDto> {
        const response = await this.createOrganizationNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteOrganizationNoteRaw(requestParameters: DeleteOrganizationNoteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationNoteDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOrganizationNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/Organizations/notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationNoteDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteOrganizationNote(requestParameters: DeleteOrganizationNoteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationNoteDto> {
        const response = await this.deleteOrganizationNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationNoteByIdRaw(requestParameters: GetOrganizationNoteByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationNoteDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrganizationNoteById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/Organizations/notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationNoteDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationNoteById(requestParameters: GetOrganizationNoteByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationNoteDto> {
        const response = await this.getOrganizationNoteByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationNotesRaw(requestParameters: GetOrganizationNotesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationNoteDto>>> {
        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.createdByUserId !== undefined) {
            queryParameters['createdByUserId'] = requestParameters.createdByUserId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/Organizations/notes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationNoteDtoFromJSON));
    }

    /**
     */
    async getOrganizationNotes(requestParameters: GetOrganizationNotesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationNoteDto>> {
        const response = await this.getOrganizationNotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationNotesCountRaw(requestParameters: GetOrganizationNotesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.createdByUserId !== undefined) {
            queryParameters['createdByUserId'] = requestParameters.createdByUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/Organizations/notes/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationNotesCount(requestParameters: GetOrganizationNotesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getOrganizationNotesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrganizationNoteRaw(requestParameters: UpdateOrganizationNoteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationNoteDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrganizationNote.');
        }

        if (requestParameters.setOrganizationNoteDto === null || requestParameters.setOrganizationNoteDto === undefined) {
            throw new runtime.RequiredError('setOrganizationNoteDto','Required parameter requestParameters.setOrganizationNoteDto was null or undefined when calling updateOrganizationNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/Organizations/notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetOrganizationNoteDtoToJSON(requestParameters.setOrganizationNoteDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationNoteDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateOrganizationNote(requestParameters: UpdateOrganizationNoteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationNoteDto> {
        const response = await this.updateOrganizationNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
