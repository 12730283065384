/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {ClientDto, ClientDtoFromJSON, ClientDtoToJSON,} from './ClientDto';
import {JurisdictionDto, JurisdictionDtoFromJSON, JurisdictionDtoToJSON,} from './JurisdictionDto';

/**
 *
 * @export
 * @interface ClientJurisdictionDto
 */
export interface ClientJurisdictionDto {
    /**
     *
     * @type {string}
     * @memberof ClientJurisdictionDto
     */
    id?: string | null;
    /**
     *
     * @type {string}
     * @memberof ClientJurisdictionDto
     */
    clientId?: string | null;
    /**
     *
     * @type {string}
     * @memberof ClientJurisdictionDto
     */
    jurisdictionId?: string | null;
    /**
     *
     * @type {JurisdictionDto}
     * @memberof ClientJurisdictionDto
     */
    jurisdiction?: JurisdictionDto;
    /**
     *
     * @type {ClientDto}
     * @memberof ClientJurisdictionDto
     */
    client?: ClientDto;
}

export function ClientJurisdictionDtoFromJSON(json: any): ClientJurisdictionDto {
    return ClientJurisdictionDtoFromJSONTyped(json, false);
}

export function ClientJurisdictionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientJurisdictionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'id': !exists(json, 'id') ? undefined : json['id'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'jurisdictionId': !exists(json, 'jurisdictionId') ? undefined : json['jurisdictionId'],
        'jurisdiction': !exists(json, 'jurisdiction') ? undefined : JurisdictionDtoFromJSON(json['jurisdiction']),
        'client': !exists(json, 'client') ? undefined : ClientDtoFromJSON(json['client']),
    };
}

export function ClientJurisdictionDtoToJSON(value?: ClientJurisdictionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'id': value.id,
        'clientId': value.clientId,
        'jurisdictionId': value.jurisdictionId,
        'jurisdiction': JurisdictionDtoToJSON(value.jurisdiction),
        'client': ClientDtoToJSON(value.client),
    };
}

