/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {exists, mapValues} from '../runtime';
/**
 * 
 * @export
 * @interface ImportLocality
 */
export interface ImportLocality {
    /**
     * 
     * @type {string}
     * @memberof ImportLocality
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportLocality
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportLocality
     */
    parentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportLocality
     */
    typeLocalityId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportLocality
     */
    status?: string | null;
}

export function ImportLocalityFromJSON(json: any): ImportLocality {
    return ImportLocalityFromJSONTyped(json, false);
}

export function ImportLocalityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportLocality {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'typeLocalityId': !exists(json, 'typeLocalityId') ? undefined : json['typeLocalityId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function ImportLocalityToJSON(value?: ImportLocality | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'id': value.id,
        'name': value.name,
        'parentId': value.parentId,
        'typeLocalityId': value.typeLocalityId,
        'status': value.status,
    };
}

