/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetOrganizationWebsiteDto
 */
export interface SetOrganizationWebsiteDto {
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationWebsiteDto
     */
    websiteAddress: string;
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationWebsiteDto
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationWebsiteDto
     */
    websiteStatusId: string;
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationWebsiteDto
     */
    description?: string | null;
}

export function SetOrganizationWebsiteDtoFromJSON(json: any): SetOrganizationWebsiteDto {
    return SetOrganizationWebsiteDtoFromJSONTyped(json, false);
}

export function SetOrganizationWebsiteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetOrganizationWebsiteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'websiteAddress': json['websiteAddress'],
        'organizationId': json['organizationId'],
        'websiteStatusId': json['websiteStatusId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function SetOrganizationWebsiteDtoToJSON(value?: SetOrganizationWebsiteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'websiteAddress': value.websiteAddress,
        'organizationId': value.organizationId,
        'websiteStatusId': value.websiteStatusId,
        'description': value.description,
    };
}

