/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {JurisdictionDto, JurisdictionDtoFromJSON, JurisdictionDtoToJSON,} from './JurisdictionDto';
import {UserDto, UserDtoFromJSON, UserDtoToJSON,} from './UserDto';

/**
 *
 * @export
 * @interface UserJurisdictionDto
 */
export interface UserJurisdictionDto {
    /**
     *
     * @type {string}
     * @memberof UserJurisdictionDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UserJurisdictionDto
     */
    jurisdictionId: string;
    /**
     * 
     * @type {UserDto}
     * @memberof UserJurisdictionDto
     */
    user: UserDto;
    /**
     * 
     * @type {JurisdictionDto}
     * @memberof UserJurisdictionDto
     */
    jurisdiction: JurisdictionDto;
}

export function UserJurisdictionDtoFromJSON(json: any): UserJurisdictionDto {
    return UserJurisdictionDtoFromJSONTyped(json, false);
}

export function UserJurisdictionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserJurisdictionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'jurisdictionId': json['jurisdictionId'],
        'user': UserDtoFromJSON(json['user']),
        'jurisdiction': JurisdictionDtoFromJSON(json['jurisdiction']),
    };
}

export function UserJurisdictionDtoToJSON(value?: UserJurisdictionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'jurisdictionId': value.jurisdictionId,
        'user': UserDtoToJSON(value.user),
        'jurisdiction': JurisdictionDtoToJSON(value.jurisdiction),
    };
}

