import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { IPage } from '../../../core';
import { Box, IconButton, SxProps, TablePagination, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';

type Props = {
    store: IPage;
    style?: React.CSSProperties;
    sx?: SxProps;
    pageOptions?: number[];
};

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

const FONT_SIZE = 10
const DEFAULT_INPUT_WIDTH = 24

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    const [pageNumber, setPageNumber] = useState(page)
    const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH)

    useEffect(() => setPageNumber(prev => prev = page + 1), [page])

    useEffect(() => {
        if (pageNumber.toString().length * FONT_SIZE > DEFAULT_INPUT_WIDTH) {
            setInputWidth((pageNumber.toString().length + 1) * FONT_SIZE)
        } else {
            setInputWidth(DEFAULT_INPUT_WIDTH)
        }
    }, [pageNumber])

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>, numberPage: number) => {
        if (numberPage < 1) {
            setPageNumber(1); 
            return onPageChange(event, 0);
        };
        if (numberPage > Math.max(0, Math.ceil(count / rowsPerPage))) {
            setPageNumber(prev => prev = Math.ceil(count / rowsPerPage));
            return onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        }
        onPageChange(event, numberPage - 1);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const keyPress = (event: any) => {
        if(event.key === "Enter"){
            handleButtonClick(event, pageNumber)
        }
     }

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton onClick={(e) => handleButtonClick(e, pageNumber)}><PageviewOutlinedIcon /></IconButton>
                <TextField
                    onKeyDown={keyPress}
                    autoComplete='off'
                    variant="standard"
                    style={{ textAlign: 'end' }}
                    type="number"
                    value={Number(pageNumber).toString()}
                    onChange={(e) => {setPageNumber(Number(e.target.value))}}
                    InputProps={{
                        style: { paddingTop: 6, width: `${inputWidth}px`, textAlign: "end", fontSize: '0.875rem' },
                        disableUnderline: true,
                    }}
                />
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
        </Box>
    );
}

export const PaginationRight = observer(({ store, style, sx, pageOptions=[10, 25, 50, 100] }: Props) => {
    const { t } = useTranslation();

    const handleChangePage = async (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        await store.setPaging(newPage, store.size);
    };

    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
        if (event) {
            await store.setPaging(0, parseInt(event.target.value, 10));
        }
    };

    return (
        <TablePagination
            style={{ border: 'none', ...style }}
            count={store.count}
            rowsPerPage={store.size}
            page={store.page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={pageOptions}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('common.paginationText')}
            labelDisplayedRows={
                ({ from, to, count, page }) => {
                    return `${t('common.page')}` + ' ' + (page + 1) + ` ${t('common.paginationCount')} ${Math.ceil(count / store.size)}`
                }
            }
            ActionsComponent={TablePaginationActions}
            SelectProps={{
                inputProps: {
                    'aria-label': 'rows per page',
                },
                native: true,
            }}
            sx={sx}
        />

    );
});