import {Box} from "@mui/material";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {AuthorizePermission, FormRoot, ParamsButtonGroup,} from '../../../components';
import {AllPermissions} from "../../../core/const";
import {DefaultMultiSelect} from "../../../components/shared/components/DefaultMultiSelect";
import {DefaultSelect} from "../../../components/shared/components/DefaultSelect";
import React from "react";
import {PersonSpecialityStore} from "../../../stores/management/persons/tabs/speciality/PersonSpecialityStore";

interface Props {
    store: PersonSpecialityStore;
    style?: React.CSSProperties;
}

export const PersonSpecialities = observer(({store, style}: Props) => {
    const {t} = useTranslation();

    return (
        <FormRoot>
            <DefaultMultiSelect
                disabled={store.isDisable}
                store={store.speciality.fields.specialities}
                tags
                title={t('common.specialities')}/>
            <DefaultSelect store={store.speciality.fields.primary}
                           disabled={store.isDisable}
                           hasLabel
                           title={t('common.primarySpeciality')}/>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}>

                <AuthorizePermission permissions={AllPermissions.person.personspecialities.update}>
                    <ParamsButtonGroup
                        testid="specialitiesControl"
                        onCancel={() => store.speciality.reset()}
                        onSave={async () => await store.speciality.submit()}
                        isCancelBtnDisabled={store.speciality.processing || store.loading || store.speciality.equals || store.isDisable}
                        isSaveBtnDisabled={!store.speciality.valid || store.speciality.processing || store.loading || store.speciality.equals || store.isDisable}
                    />
                </AuthorizePermission>
            </Box>
        </FormRoot>
    );
});
