/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Api.Export
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {CommonFilter, CommonFilterFromJSON, CommonFilterToJSON,} from './CommonFilter';
import {DictionaryType, DictionaryTypeFromJSON, DictionaryTypeToJSON,} from './DictionaryType';
import {FileType, FileTypeFromJSON, FileTypeToJSON,} from './FileType';
import {JwtBound, JwtBoundFromJSON, JwtBoundToJSON,} from './JwtBound';
import {RequestType, RequestTypeFromJSON, RequestTypeToJSON,} from './RequestType';

/**
 * 
 * @export
 * @interface ExportJobInfo
 */
export interface ExportJobInfo {
    /**
     * 
     * @type {FileType}
     * @memberof ExportJobInfo
     */
    fileTypes?: FileType;
    /**
     * 
     * @type {string}
     * @memberof ExportJobInfo
     */
    userId?: string | null;
    /**
     * 
     * @type {DictionaryType}
     * @memberof ExportJobInfo
     */
    dictionaryTypes?: DictionaryType;
    /**
     * 
     * @type {string}
     * @memberof ExportJobInfo
     */
    streamName?: string | null;
    /**
     * 
     * @type {CommonFilter}
     * @memberof ExportJobInfo
     */
    filters?: CommonFilter;
    /**
     * 
     * @type {JwtBound}
     * @memberof ExportJobInfo
     */
    bound?: JwtBound;
    /**
     * 
     * @type {RequestType}
     * @memberof ExportJobInfo
     */
    type?: RequestType;
}

export function ExportJobInfoFromJSON(json: any): ExportJobInfo {
    return ExportJobInfoFromJSONTyped(json, false);
}

export function ExportJobInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportJobInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileTypes': !exists(json, 'fileTypes') ? undefined : FileTypeFromJSON(json['fileTypes']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'dictionaryTypes': !exists(json, 'dictionaryTypes') ? undefined : DictionaryTypeFromJSON(json['dictionaryTypes']),
        'streamName': !exists(json, 'streamName') ? undefined : json['streamName'],
        'filters': !exists(json, 'filters') ? undefined : CommonFilterFromJSON(json['filters']),
        'bound': !exists(json, 'bound') ? undefined : JwtBoundFromJSON(json['bound']),
        'type': !exists(json, 'type') ? undefined : RequestTypeFromJSON(json['type']),
    };
}

export function ExportJobInfoToJSON(value?: ExportJobInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileTypes': FileTypeToJSON(value.fileTypes),
        'userId': value.userId,
        'dictionaryTypes': DictionaryTypeToJSON(value.dictionaryTypes),
        'streamName': value.streamName,
        'filters': CommonFilterToJSON(value.filters),
        'bound': JwtBoundToJSON(value.bound),
        'type': RequestTypeToJSON(value.type),
    };
}

