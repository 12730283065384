/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {ChangeRequestField, ChangeRequestFieldFromJSON, ChangeRequestFieldToJSON,} from './ChangeRequestField';
import {ClientType, ClientTypeFromJSON, ClientTypeToJSON,} from './ClientType';
import {RequestStatus, RequestStatusFromJSON, RequestStatusToJSON,} from './RequestStatus';

/**
 * 
 * @export
 * @interface SummaryChangeRequestStatusCommand
 */
export interface SummaryChangeRequestStatusCommand {
    /**
     * 
     * @type {string}
     * @memberof SummaryChangeRequestStatusCommand
     */
    id?: string | null;
    /**
     * 
     * @type {Array<ChangeRequestField>}
     * @memberof SummaryChangeRequestStatusCommand
     */
    fields?: Array<ChangeRequestField> | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SummaryChangeRequestStatusCommand
     */
    additionalFields?: { [key: string]: string; } | null;
    /**
     * 
     * @type {RequestStatus}
     * @memberof SummaryChangeRequestStatusCommand
     */
    status?: RequestStatus;
    /**
     * 
     * @type {string}
     * @memberof SummaryChangeRequestStatusCommand
     */
    comment?: string | null;
    /**
     * 
     * @type {ClientType}
     * @memberof SummaryChangeRequestStatusCommand
     */
    clientType?: ClientType;
    /**
     * 
     * @type {boolean}
     * @memberof SummaryChangeRequestStatusCommand
     */
    readonly isDouble?: boolean;
    /**
     * 
     * @type {RequestStatus}
     * @memberof SummaryChangeRequestStatusCommand
     */
    changeStatus?: RequestStatus;
    /**
     * 
     * @type {string}
     * @memberof SummaryChangeRequestStatusCommand
     */
    readonly changeComment?: string | null;
}

export function SummaryChangeRequestStatusCommandFromJSON(json: any): SummaryChangeRequestStatusCommand {
    return SummaryChangeRequestStatusCommandFromJSONTyped(json, false);
}

export function SummaryChangeRequestStatusCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryChangeRequestStatusCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fields': !exists(json, 'fields') ? undefined : (json['fields'] === null ? null : (json['fields'] as Array<any>).map(ChangeRequestFieldFromJSON)),
        'additionalFields': !exists(json, 'additionalFields') ? undefined : json['additionalFields'],
        'status': !exists(json, 'status') ? undefined : RequestStatusFromJSON(json['status']),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'clientType': !exists(json, 'clientType') ? undefined : ClientTypeFromJSON(json['clientType']),
        'isDouble': !exists(json, 'isDouble') ? undefined : json['isDouble'],
        'changeStatus': !exists(json, 'changeStatus') ? undefined : RequestStatusFromJSON(json['changeStatus']),
        'changeComment': !exists(json, 'changeComment') ? undefined : json['changeComment'],
    };
}

export function SummaryChangeRequestStatusCommandToJSON(value?: SummaryChangeRequestStatusCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fields': value.fields === undefined ? undefined : (value.fields === null ? null : (value.fields as Array<any>).map(ChangeRequestFieldToJSON)),
        'additionalFields': value.additionalFields,
        'status': RequestStatusToJSON(value.status),
        'comment': value.comment,
        'clientType': ClientTypeToJSON(value.clientType),
        'changeStatus': RequestStatusToJSON(value.changeStatus),
    };
}

