/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    FormPropertyDto,
    FormPropertyDtoFromJSON,
    FormPropertyDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetFormPropertyDto,
    SetFormPropertyDtoFromJSON,
    SetFormPropertyDtoToJSON,
} from '../models';

export interface CreateFormPropertyRequest {
    setFormPropertyDto: SetFormPropertyDto;
}

export interface DeleteFormPropertyRequest {
    id: string;
}

export interface GetFormPropertiesRequest {
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetFormPropertiesCountRequest {
    search?: string;
}

export interface GetFormPropertyByIdRequest {
    id: string;
}

export interface GetFormPropertyByNameRequest {
    name: string;
}

export interface UpdateFormPropertyRequest {
    id: string;
    setFormPropertyDto: SetFormPropertyDto;
}

/**
 * 
 */
export class FormPropertiesApi extends runtime.BaseAPI {

    /**
     */
    async createFormPropertyRaw(requestParameters: CreateFormPropertyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FormPropertyDto>> {
        if (requestParameters.setFormPropertyDto === null || requestParameters.setFormPropertyDto === undefined) {
            throw new runtime.RequiredError('setFormPropertyDto','Required parameter requestParameters.setFormPropertyDto was null or undefined when calling createFormProperty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/formproperties`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetFormPropertyDtoToJSON(requestParameters.setFormPropertyDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormPropertyDtoFromJSON(jsonValue));
    }

    /**
     */
    async createFormProperty(requestParameters: CreateFormPropertyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FormPropertyDto> {
        const response = await this.createFormPropertyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteFormPropertyRaw(requestParameters: DeleteFormPropertyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FormPropertyDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteFormProperty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/formproperties/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormPropertyDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteFormProperty(requestParameters: DeleteFormPropertyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FormPropertyDto> {
        const response = await this.deleteFormPropertyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFormPropertiesRaw(requestParameters: GetFormPropertiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<FormPropertyDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/formproperties`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FormPropertyDtoFromJSON));
    }

    /**
     */
    async getFormProperties(requestParameters: GetFormPropertiesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<FormPropertyDto>> {
        const response = await this.getFormPropertiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFormPropertiesCountRaw(requestParameters: GetFormPropertiesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/formproperties/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getFormPropertiesCount(requestParameters: GetFormPropertiesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getFormPropertiesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFormPropertyByIdRaw(requestParameters: GetFormPropertyByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FormPropertyDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getFormPropertyById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/formproperties/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormPropertyDtoFromJSON(jsonValue));
    }

    /**
     */
    async getFormPropertyById(requestParameters: GetFormPropertyByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FormPropertyDto> {
        const response = await this.getFormPropertyByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFormPropertyByNameRaw(requestParameters: GetFormPropertyByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FormPropertyDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getFormPropertyByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/formproperties/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormPropertyDtoFromJSON(jsonValue));
    }

    /**
     */
    async getFormPropertyByName(requestParameters: GetFormPropertyByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FormPropertyDto> {
        const response = await this.getFormPropertyByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateFormPropertyRaw(requestParameters: UpdateFormPropertyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FormPropertyDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateFormProperty.');
        }

        if (requestParameters.setFormPropertyDto === null || requestParameters.setFormPropertyDto === undefined) {
            throw new runtime.RequiredError('setFormPropertyDto','Required parameter requestParameters.setFormPropertyDto was null or undefined when calling updateFormProperty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/formproperties/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetFormPropertyDtoToJSON(requestParameters.setFormPropertyDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormPropertyDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateFormProperty(requestParameters: UpdateFormPropertyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FormPropertyDto> {
        const response = await this.updateFormPropertyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
