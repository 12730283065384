import {Box} from "@mui/system";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {ConfirmationForm, DeleteButton, ExpertDialog, FormRow, SimpleButton, TextInput} from "../../../components";
import {ImportItemStore} from "../../../stores/management/data/import/ImportItemStore";
import {callTheProcessingFunc} from "../../../stores";

interface Props {
    store: ImportItemStore;
    tabsName: string;
    style?: React.CSSProperties;
}

export const ImportDetails = observer(({ store, tabsName, style }: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <ExpertDialog
                title={`${t("buttons.delete")} ${t("dataPage.File")}`}
                open={store._store.deleteDialogState}
                onClose={() => store._store.deleteDialogState = false}
            >
                <ConfirmationForm
                    disabled={store.processing}
                    onSubmit={async () => await callTheProcessingFunc(store, store._store.delete.bind(store._store), store.pull.bind(store))()}
                    onClose={() => store._store.deleteDialogState = false}
                >
                    {t('common.deleteConfirm')} {t("dataPage.File")}?
                </ConfirmationForm>
            </ExpertDialog>
            <Box sx={{ p: 2 }}>
                <FormRow style={{ height: "max-content", marginBottom: 10 }}>
                    <TextInput
                        sx={{
                            mx: 2,
                            mb: 1,
                            width: '100%',
                            "&.MuiInputBase-root": {
                                height: '100%'
                            }
                        }}
                        value={store.dto.log}
                        onChange={t => t}
                        maxRows={8}
                        multiline
                        disabled={true}
                    />
                </FormRow>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'normal', ml: 2 }}>
                        <SimpleButton
                            variant='outlined'
                            onClick={() => {
                                if (store.status > 4) {
                                    callTheProcessingFunc(store, store.downloadAfterImportFile.bind(store), store.pull.bind(store))()
                                } else {
                                    callTheProcessingFunc(store, store.downloadPreImportReport.bind(store), store.pull.bind(store))()
                                }
                            }}
                            title={t('dataPage.report')}
                            style={{ width: 100, marginRight: 8 }}
                            disabled={store.status === 0 || store.processing}
                        />

                        <SimpleButton
                            onClick={async () => await callTheProcessingFunc(store, store.importSuccessfulRecorders.bind(store))()}
                            title={t('dataPage.addData')}
                            style={{ width: 165 }}
                            disabled={store.status !== 3 || store.processing}
                        />
                    </Box>
                    <DeleteButton onClick={() => store._store.deleteDialogState = true}
                                  disabled={store.processing}/>
                </Box>
            </Box>
        </>
    );
});
