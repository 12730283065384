import {observer} from "mobx-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {OrganizationStore} from "../../../../stores/management/dcr/requests/specifications/entity/OrganizationStore";
import {FormRoot} from "../../../../components";
import {Box, Grid} from "@mui/material";
import {DcrListItem} from "../../DcrListItem";

interface Props {
    store: OrganizationStore;
}

export const OrganizationExternalFirstValues = observer(({store}: Props) => {
    const {t} = useTranslation();
    return (
        <FormRoot>
            {
                !store?.loading ?
                    (
                        <Grid container spacing={2}>
                            <Grid container item xs={6} direction="column">
                                <DcrListItem
                                    name={t('dcrPage.fields.name')}
                                    value={store._store.getFirstValue("name")}>
                                </DcrListItem>
                                <DcrListItem
                                    name={t('dcrPage.fields.companytype')}
                                    value={store._store.getFirstValue("companytype")}>
                                </DcrListItem>
                                <DcrListItem
                                    name={t('dcrPage.fields.postcode')}
                                    value={store._store.getFirstValue("postcode")}>
                                </DcrListItem>
                                <DcrListItem
                                    name={t('dcrPage.fields.phone1')}
                                    value={store._store.getFirstValue("phone1")}>
                                </DcrListItem>
                                <DcrListItem
                                    name={t('dcrPage.fields.status')}
                                    value={store._store.getFirstValue("status")}>
                                </DcrListItem>
                            </Grid>
                            <Grid container item xs={6} direction="column">
                                <DcrListItem
                                    name={t('dcrPage.fields.region')}
                                    value={store._store.getFirstValue("region")}>
                                </DcrListItem>
                                <DcrListItem
                                    name={t('dcrPage.fields.city')}
                                    value={store._store.getFirstValue("city")}>
                                </DcrListItem>
                                <DcrListItem
                                    name={t('dcrPage.fields.streettype')}
                                    value={store._store.getFirstValue("streettype")}>
                                </DcrListItem>
                                <DcrListItem
                                    name={t('dcrPage.fields.street')}
                                    value={store._store.getFirstValue("street")}>
                                </DcrListItem>
                                <DcrListItem
                                    name={t('dcrPage.fields.building')}
                                    value={store._store.getFirstValue("building")}>
                                </DcrListItem>
                            </Grid>
                        </Grid>
                    )
                    : <Box sx={{pt: 2}}>{t("common.download")}...</Box>
            }
        </FormRoot>
    );
});
