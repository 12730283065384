/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    DobleSearchType,
    DobleSearchTypeFromJSON,
    DobleSearchTypeToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OrganizationDobleDto,
    OrganizationDobleDtoFromJSON,
    OrganizationDobleDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    QueryType,
    QueryTypeFromJSON,
    QueryTypeToJSON,
    SetOrganizationDobleDto,
    SetOrganizationDobleDtoFromJSON,
    SetOrganizationDobleDtoToJSON,
} from '../models';

export interface CreateOrganizationDobleRequest {
    setOrganizationDobleDto: SetOrganizationDobleDto;
}

export interface DeleteOrganizationDobleRequest {
    id: string;
}

export interface GetOrganizationDobleByIdRequest {
    id: string;
}

export interface GetOrganizationDoblesRequest {
    searchType?: DobleSearchType;
    searchId?: string;
    orderBy?: string;
    queryType?: QueryType;
    parentId?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetOrganizationDoblesCountRequest {
    search?: string;
    searchByExternalId?: string;
    parentId?: string;
    clinicCategoryId?: string;
    queryType?: QueryType;
}

export interface UpdateOrganizationDobleRequest {
    id: string;
    setOrganizationDobleDto: SetOrganizationDobleDto;
}

/**
 * 
 */
export class OrganizationDoblesApi extends runtime.BaseAPI {

    /**
     */
    async createOrganizationDobleRaw(requestParameters: CreateOrganizationDobleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationDobleDto>> {
        if (requestParameters.setOrganizationDobleDto === null || requestParameters.setOrganizationDobleDto === undefined) {
            throw new runtime.RequiredError('setOrganizationDobleDto','Required parameter requestParameters.setOrganizationDobleDto was null or undefined when calling createOrganizationDoble.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/dobles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetOrganizationDobleDtoToJSON(requestParameters.setOrganizationDobleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDobleDtoFromJSON(jsonValue));
    }

    /**
     */
    async createOrganizationDoble(requestParameters: CreateOrganizationDobleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationDobleDto> {
        const response = await this.createOrganizationDobleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteOrganizationDobleRaw(requestParameters: DeleteOrganizationDobleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationDobleDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOrganizationDoble.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/dobles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDobleDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteOrganizationDoble(requestParameters: DeleteOrganizationDobleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationDobleDto> {
        const response = await this.deleteOrganizationDobleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationDobleByIdRaw(requestParameters: GetOrganizationDobleByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationDobleDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrganizationDobleById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/dobles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDobleDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationDobleById(requestParameters: GetOrganizationDobleByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationDobleDto> {
        const response = await this.getOrganizationDobleByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationDoblesRaw(requestParameters: GetOrganizationDoblesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationDobleDto>>> {
        const queryParameters: any = {};

        if (requestParameters.searchType !== undefined) {
            queryParameters['searchType'] = requestParameters.searchType;
        }

        if (requestParameters.searchId !== undefined) {
            queryParameters['searchId'] = requestParameters.searchId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.queryType !== undefined) {
            queryParameters['queryType'] = requestParameters.queryType;
        }

        if (requestParameters.parentId !== undefined) {
            queryParameters['parentId'] = requestParameters.parentId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/dobles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationDobleDtoFromJSON));
    }

    /**
     */
    async getOrganizationDobles(requestParameters: GetOrganizationDoblesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationDobleDto>> {
        const response = await this.getOrganizationDoblesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationDoblesCountRaw(requestParameters: GetOrganizationDoblesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.parentId !== undefined) {
            queryParameters['parentId'] = requestParameters.parentId;
        }

        if (requestParameters.clinicCategoryId !== undefined) {
            queryParameters['clinicCategoryId'] = requestParameters.clinicCategoryId;
        }

        if (requestParameters.queryType !== undefined) {
            queryParameters['queryType'] = requestParameters.queryType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/dobles/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationDoblesCount(requestParameters: GetOrganizationDoblesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getOrganizationDoblesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrganizationDobleRaw(requestParameters: UpdateOrganizationDobleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationDobleDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrganizationDoble.');
        }

        if (requestParameters.setOrganizationDobleDto === null || requestParameters.setOrganizationDobleDto === undefined) {
            throw new runtime.RequiredError('setOrganizationDobleDto','Required parameter requestParameters.setOrganizationDobleDto was null or undefined when calling updateOrganizationDoble.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/dobles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetOrganizationDobleDtoToJSON(requestParameters.setOrganizationDobleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDobleDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateOrganizationDoble(requestParameters: UpdateOrganizationDobleRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationDobleDto> {
        const response = await this.updateOrganizationDobleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
