import {getApiConfiguration} from '../../../../../core';
import {NameDto, PersonSpecialitiesApi, SpecialitiesApi, SpecialityDto} from '../../../../../services/management';
import {LoadStore} from '../../../../utils';
import {PersonItemStore} from "../../PersonItemStore";
import {DefaultFormStore} from "../../../../../components/shared/DefaultFormStore";
import {DefaultMultiSelectStore} from "../../../../../components/shared/DefaultMultiSelectStore";
import {DefaultSelectStore} from "../../../../../components/shared/DefaultSelectStore";

export class PersonSpecialityStore extends LoadStore {
    readonly _store: PersonItemStore;
    speciality: DefaultFormStore<{
        specialities: DefaultMultiSelectStore<SpecialityDto>,
        primary: DefaultSelectStore<SpecialityDto>,
    }>;

    constructor(store: PersonItemStore) {
        super();
        this._store = store;
        this.speciality = new DefaultFormStore({
            specialities: new DefaultMultiSelectStore([], (x) => new SpecialitiesApi(getApiConfiguration()).getSpecialities({
                ...x,
                showOnlyActive: true
            })),
            primary: new DefaultSelectStore<SpecialityDto>(store._dto.primarySpeciality ?? null, (x) => Promise.resolve(this.speciality.fields.specialities.value ?? []))
        }, async (e) => await this.save())

        this.speciality.fields.primary.required = true;
        this.speciality.fields.specialities.required = true;

        this.speciality.fields.specialities.onChanged.push((e) => {
            if (e && e.length > 0) {
                if (!this.speciality.fields.primary.value) {
                    this.speciality.fields.primary.value = e[0]
                } else {
                    if (!e.map(x => x.id).find(x => x == this.speciality.fields.primary.value?.id)) {
                        this.speciality.fields.primary.value = e[0]
                    }
                }
            } else {
                this.speciality.fields.primary.value = null;
            }
        })
    }

    get isDisable(): boolean {
        return this._store.disable;
    }

    async save() {
        const specialitiesIds = this.speciality.fields.specialities.firstValue.map(x => x.id);
        const currentIds = this.speciality.fields.specialities.value.map(x => x.id);

        const deletesIds = specialitiesIds.filter(x => !currentIds.includes(x));
        const insetIds = currentIds.filter(x => !specialitiesIds.includes(x));

        try {
            if (deletesIds.length > 0)
                await new PersonSpecialitiesApi(getApiConfiguration({successTooltip: false})).removePersonSpecialities({
                    personId: this._store.id,
                    requestBody: deletesIds
                })
            if (insetIds.length > 0)
                await new PersonSpecialitiesApi(getApiConfiguration({successTooltip: false})).setPersonSpecialities({
                    personId: this._store.id,
                    requestBody: insetIds
                })
            this.speciality.fields.specialities.update(this.speciality.fields.specialities.value)
            this.speciality.fields.primary.update(this.speciality.fields.primary.value)

            this._store.primarySpeciality.value = this.speciality.fields.primary.valueId
            await this._store.save()
        } catch (e) {

        }
    }


    async request(): Promise<void> {
        const result = await new PersonSpecialitiesApi(getApiConfiguration()).getPersonSpecialities({
            page: 1,
            size: 10000,
            search: '',
            personId: this._store.id,
        });

        const data = result.map(x => x.speciality);

        this.speciality.fields.specialities.update(data.concat(
            this._store._dto.primarySpeciality && !data.find(x => x.id == this._store._dto.primarySpeciality?.id) ?
                [this._store!._dto!.primarySpeciality as NameDto] : []))
    }
}
