/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetRoleDto
 */
export interface SetRoleDto {
    /**
     * 
     * @type {string}
     * @memberof SetRoleDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetRoleDto
     */
    description?: string | null;
}

export function SetRoleDtoFromJSON(json: any): SetRoleDto {
    return SetRoleDtoFromJSONTyped(json, false);
}

export function SetRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetRoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function SetRoleDtoToJSON(value?: SetRoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
    };
}

