import {Box, Breadcrumbs, Button, Grid, Typography} from '@mui/material';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {AuthorizePermission, Page, PageTab, PageTabs,} from '../../../components';
import {useManagementStore, useRootStore} from '../../../stores';
import {ImportTable} from './ImportTable';
import {useEffect} from "react";
import {AllPermissions} from "../../../core/const";
import {ImportCreate} from "./ImportCreate";

export const ImportTabs = observer(() => {
    const {importStore} = useManagementStore();
    const {t} = useTranslation();
    const {signalRStore} = useRootStore();

    useEffect(() => {
        const fetchData = async () => {
            await signalRStore.startConnection("import")
        }
        fetchData()
        return () => {
            signalRStore.stopConnection("import").then()
        }
    }, [])

    const renderTabs = (index: number) => {
        switch (index) {
            case 0:
                return (
                    <AuthorizePermission
                        permissions={AllPermissions.import.person.read}
                        showWarning
                    >
                        <ImportTable
                            store={importStore.importPersonTableStore}
                            permission={AllPermissions.import.person.create}
                            tabsName={'person'}
                        />
                    </AuthorizePermission>
                );
            case 1:
                return (
                    <AuthorizePermission
                        permissions={AllPermissions.import.organization.read}
                        showWarning
                    >
                        <ImportTable
                            permission={AllPermissions.import.organization.create}
                            store={importStore.importOrganizationTableStore}
                            tabsName={'organization'}
                        />
                    </AuthorizePermission>
                );
            case 2:
                return (
                    <AuthorizePermission
                        permissions={AllPermissions.import.address.create}
                        showWarning
                    >
                        <Box sx={{mt: 4}}>
                            <Grid
                                container
                                justifyContent="end"
                                spacing={1}
                            >
                                <Button variant='outlined'
                                        onClick={() => importStore.importAddressStore.pullFormat()}>{t('dataPage.dataFormat')}
                                </Button>
                            </Grid>

                            <ImportCreate store={importStore.importAddressStore.importAddressStore}/>
                        </Box>
                    </AuthorizePermission>
                );
        }
    };

    return (
        <Box>
            <>
                <Box>
                    <Breadcrumbs separator="/" sx={{mt: 1}}>
                        <Typography color="primary" variant="h6">
                            {t('pageNames.data')}
                        </Typography>
                        <Typography color="textSecondary" variant="h6">
                            {t('dataPage.dataTabs.import')}
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <PageTabs
                    index={importStore.tabIndex}
                    onChange={(t) => {
                        importStore.tabIndex = t;
                    }}
                >
                    <PageTab
                        label={t('dataPage.dataTabs.persons').toUpperCase()}
                    />
                    <PageTab
                        label={t('dataPage.dataTabs.organizations').toUpperCase()}
                    />
                    <PageTab
                        label={t('dataPage.dataTabs.addresses').toUpperCase()}
                    />
                </PageTabs>
                <Page style={{flex: 1, width: '100%', height: '100%'}}>
                    {renderTabs(importStore.tabIndex)}
                </Page>
            </>
        </Box>
    );
});
