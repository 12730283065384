/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface RemovePersonEmailDto
 */
export interface RemovePersonEmailDto {
    /**
     * 
     * @type {string}
     * @memberof RemovePersonEmailDto
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof RemovePersonEmailDto
     */
    personId: string;
}

export function RemovePersonEmailDtoFromJSON(json: any): RemovePersonEmailDto {
    return RemovePersonEmailDtoFromJSONTyped(json, false);
}

export function RemovePersonEmailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemovePersonEmailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailAddress': json['emailAddress'],
        'personId': json['personId'],
    };
}

export function RemovePersonEmailDtoToJSON(value?: RemovePersonEmailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailAddress': value.emailAddress,
        'personId': value.personId,
    };
}

