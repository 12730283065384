import {GroupItemStore} from "../GroupItemStore";
import {DcrGroupDto} from "../../../../../../services/dcr";
import {DefaultValueStore} from "../../../../../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from "../../../../../../components/shared/DefaultSelectStore";
import {DefaultMultiSelectStore} from "../../../../../../components/shared/DefaultMultiSelectStore";
import {UserDto, UsersApi} from "../../../../../../services/management";
import {makeAutoObservable} from "mobx";
import {getApiConfiguration} from "../../../../../../core";
import {DefaultFormStore} from "../../../../../../components/shared/DefaultFormStore";

export class ParametersTabStore {
    readonly store: GroupItemStore;
    dto?: DcrGroupDto;

    form: DefaultFormStore<{
        name: DefaultValueStore<string>,
        owner: DefaultSelectStore<UserDto>,
        participants: DefaultMultiSelectStore<UserDto>
    }>

    constructor(store: GroupItemStore) {
        this.store = store;
        makeAutoObservable(this)
        this.form = new DefaultFormStore({
            name: new DefaultValueStore<string>(""),
            owner: new DefaultSelectStore(null,
                (filters) => new UsersApi(getApiConfiguration()).getUsers(filters), null,"id", "name"),
            participants: new DefaultMultiSelectStore([],
                (x) => new UsersApi(getApiConfiguration()).getUsers(x))
        }, (e) => this.store.store.save(this.store))
        this.form.fields.owner.required = true;
        this.form.fields.name.required = true;
    }

    update(dto: DcrGroupDto){
        this.dto = dto;
        this.form.fields.name.update(this.dto.name!)
        this.form.fields.owner.update({id: this.dto.ownerId!, username: this.dto.ownerName})
        this.form.fields.participants.update(this.dto.users.map(x => ({id: x})))
    }
}
