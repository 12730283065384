import {ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject,} from "@apollo/client";
import {accessToken, jurisdictionId} from "./stores/authorization/AuthorizationStore";
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";
import SnackbarUtils from "./components/snackbar/SnackbarUtils";
import {logout} from "./stores";
import {asyncMap} from "@apollo/client/utilities";
import {t} from "i18next";

export const GRAPHQL_URLS = {
    CLIENTS: 'api/management/v1/application/clients',
    MAPPINGS: 'mappings/graphql'
}

const httpLink = (url: string) => new HttpLink({
    uri: process.env.REACT_APP_API + '/' + url,
});

const headerLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            authorization: `Bearer ${accessToken}`,
            'Jurisdiction': jurisdictionId
        }
    }
});


const logoutLink = onError(({ networkError }) => {
    console.log(1312312,networkError)

    if(networkError && 'statusCode' in networkError){
        console.log(1312312)
        if ([500, 422, 400, 404].indexOf(networkError.statusCode) > -1) {
            SnackbarUtils.error(networkError?.message ?? "")
        }
        if (networkError.statusCode === 401)
            logout()
    }
})

const usdToEurLink = new ApolloLink((operation, forward) => {
    return asyncMap(forward(operation), async (response) => {
        const context = operation.getContext()
        if(!!context?.response?.ok){
            const type = operation.query.definitions[0]
            if((type as any)['operation'] == 'mutation')
                SnackbarUtils.success(t("common.saved"))
        }
        return response;
    });
});

export const getAppoloClient = (url: string) : ApolloClient<NormalizedCacheObject> => {
    return new ApolloClient({
        link: usdToEurLink.concat(headerLink).concat(httpLink(url)).concat(logoutLink),
        cache: new InMemoryCache({
            addTypename: false,
        }),
        defaultOptions:{
            query: {
                fetchPolicy: 'no-cache',
            }
        },
        headers: {
            'Content-Type': 'application/json'
        },
    });
}
