/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {ClientS3Settings, ClientS3SettingsFromJSON, ClientS3SettingsToJSON,} from './ClientS3Settings';
import {ClientStatus, ClientStatusFromJSON, ClientStatusToJSON,} from './ClientStatus';
import {ClientType, ClientTypeFromJSON, ClientTypeToJSON,} from './ClientType';

/**
 * 
 * @export
 * @interface Client
 */
export interface Client {
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    boundId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    host?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    login?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    password?: string | null;
    /**
     * 
     * @type {ClientS3Settings}
     * @memberof Client
     */
    s3Settings?: ClientS3Settings;
    /**
     * 
     * @type {ClientType}
     * @memberof Client
     */
    type?: ClientType;
    /**
     * 
     * @type {ClientStatus}
     * @memberof Client
     */
    status?: ClientStatus;
}

export function ClientFromJSON(json: any): Client {
    return ClientFromJSONTyped(json, false);
}

export function ClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): Client {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'boundId': !exists(json, 'boundId') ? undefined : json['boundId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'host': !exists(json, 'host') ? undefined : json['host'],
        'login': !exists(json, 'login') ? undefined : json['login'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        's3Settings': !exists(json, 's3Settings') ? undefined : ClientS3SettingsFromJSON(json['s3Settings']),
        'type': !exists(json, 'type') ? undefined : ClientTypeFromJSON(json['type']),
        'status': !exists(json, 'status') ? undefined : ClientStatusFromJSON(json['status']),
    };
}

export function ClientToJSON(value?: Client | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'boundId': value.boundId,
        'name': value.name,
        'host': value.host,
        'login': value.login,
        'password': value.password,
        's3Settings': ClientS3SettingsToJSON(value.s3Settings),
        'type': ClientTypeToJSON(value.type),
        'status': ClientStatusToJSON(value.status),
    };
}

