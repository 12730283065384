/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddOrganizationDto,
    AddOrganizationDtoFromJSON,
    AddOrganizationDtoToJSON,
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OrganizationDto,
    OrganizationDtoFromJSON,
    OrganizationDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    QueryType,
    QueryTypeFromJSON,
    QueryTypeToJSON,
    UpdateOrganizationDto,
    UpdateOrganizationDtoFromJSON,
    UpdateOrganizationDtoToJSON,
    UpdateOrganizationITNDto,
    UpdateOrganizationITNDtoFromJSON,
    UpdateOrganizationITNDtoToJSON,
} from '../models';

export interface CreateOrganizationRequest {
    addOrganizationDto: AddOrganizationDto;
    isCustomCoordinates?: boolean;
}

export interface DeleteOrganizationRequest {
    id: string;
}

export interface GetOrganizationByIdRequest {
    id: string;
}

export interface GetOrganizationsRequest {
    search?: string;
    queryType?: QueryType;
    parentId?: string;
    statusIds?: Array<string>;
    formPropertyIds?: Array<string>;
    clinicCategoryId?: string;
    typeOrgIds?: Array<string>;
    orderBy?: string;
    localityIds?: Array<string>;
    searchById?: string;
    searchByExternalId?: string;
    order?: ListSortDirection;
    searchByHouse?: string;
    itn?: string;
    emptyItn?: boolean;
    hasNoParent?: boolean;
    searchByAntidoubleId?: string;
    page?: number;
    size?: number;
}

export interface GetOrganizationsCountRequest {
    search?: string;
    queryType?: QueryType;
    parentId?: string;
    statusIds?: Array<string>;
    formPropertyIds?: Array<string>;
    clinicCategoryId?: string;
    localityIds?: Array<string>;
    typeOrgIds?: Array<string>;
    searchById?: string;
    searchByExternalId?: string;
    searchByHouse?: string;
    itn?: string;
    emptyItn?: boolean;
    hasNoParent?: boolean;
    searchByAntidoubleId?: string;
}

export interface SetOrganizationsITNsRequest {
    updateOrganizationITNDto: Array<UpdateOrganizationITNDto>;
}

export interface TickOrganizationRequest {
    id: string;
}

export interface UpdateOrganizationRequest {
    id: string;
    updateOrganizationDto: UpdateOrganizationDto;
}

/**
 * 
 */
export class OrganizationsApi extends runtime.BaseAPI {

    /**
     */
    async createOrganizationRaw(requestParameters: CreateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationDto>> {
        if (requestParameters.addOrganizationDto === null || requestParameters.addOrganizationDto === undefined) {
            throw new runtime.RequiredError('addOrganizationDto','Required parameter requestParameters.addOrganizationDto was null or undefined when calling createOrganization.');
        }

        const queryParameters: any = {};

        if (requestParameters.isCustomCoordinates !== undefined) {
            queryParameters['isCustomCoordinates'] = requestParameters.isCustomCoordinates;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrganizationDtoToJSON(requestParameters.addOrganizationDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDtoFromJSON(jsonValue));
    }

    /**
     */
    async createOrganization(requestParameters: CreateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationDto> {
        const response = await this.createOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteOrganizationRaw(requestParameters: DeleteOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteOrganization(requestParameters: DeleteOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationDto> {
        const response = await this.deleteOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationByIdRaw(requestParameters: GetOrganizationByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrganizationById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationById(requestParameters: GetOrganizationByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationDto> {
        const response = await this.getOrganizationByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationsRaw(requestParameters: GetOrganizationsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.queryType !== undefined) {
            queryParameters['queryType'] = requestParameters.queryType;
        }

        if (requestParameters.parentId !== undefined) {
            queryParameters['parentId'] = requestParameters.parentId;
        }

        if (requestParameters.statusIds) {
            queryParameters['statusIds'] = requestParameters.statusIds;
        }

        if (requestParameters.formPropertyIds) {
            queryParameters['formPropertyIds'] = requestParameters.formPropertyIds;
        }

        if (requestParameters.clinicCategoryId !== undefined) {
            queryParameters['clinicCategoryId'] = requestParameters.clinicCategoryId;
        }

        if (requestParameters.typeOrgIds) {
            queryParameters['typeOrgIds'] = requestParameters.typeOrgIds;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.localityIds) {
            queryParameters['localityIds'] = requestParameters.localityIds;
        }

        if (requestParameters.searchById !== undefined) {
            queryParameters['searchById'] = requestParameters.searchById;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.searchByHouse !== undefined) {
            queryParameters['searchByHouse'] = requestParameters.searchByHouse;
        }

        if (requestParameters.itn !== undefined) {
            queryParameters['itn'] = requestParameters.itn;
        }

        if (requestParameters.emptyItn !== undefined) {
            queryParameters['emptyItn'] = requestParameters.emptyItn;
        }

        if (requestParameters.hasNoParent !== undefined) {
            queryParameters['hasNoParent'] = requestParameters.hasNoParent;
        }

        if (requestParameters.searchByAntidoubleId !== undefined) {
            queryParameters['searchByAntidoubleId'] = requestParameters.searchByAntidoubleId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationDtoFromJSON));
    }

    /**
     */
    async getOrganizations(requestParameters: GetOrganizationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationDto>> {
        const response = await this.getOrganizationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationsCountRaw(requestParameters: GetOrganizationsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.queryType !== undefined) {
            queryParameters['queryType'] = requestParameters.queryType;
        }

        if (requestParameters.parentId !== undefined) {
            queryParameters['parentId'] = requestParameters.parentId;
        }

        if (requestParameters.statusIds) {
            queryParameters['statusIds'] = requestParameters.statusIds;
        }

        if (requestParameters.formPropertyIds) {
            queryParameters['formPropertyIds'] = requestParameters.formPropertyIds;
        }

        if (requestParameters.clinicCategoryId !== undefined) {
            queryParameters['clinicCategoryId'] = requestParameters.clinicCategoryId;
        }

        if (requestParameters.localityIds) {
            queryParameters['localityIds'] = requestParameters.localityIds;
        }

        if (requestParameters.typeOrgIds) {
            queryParameters['typeOrgIds'] = requestParameters.typeOrgIds;
        }

        if (requestParameters.searchById !== undefined) {
            queryParameters['searchById'] = requestParameters.searchById;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.searchByHouse !== undefined) {
            queryParameters['searchByHouse'] = requestParameters.searchByHouse;
        }

        if (requestParameters.itn !== undefined) {
            queryParameters['itn'] = requestParameters.itn;
        }

        if (requestParameters.emptyItn !== undefined) {
            queryParameters['emptyItn'] = requestParameters.emptyItn;
        }

        if (requestParameters.hasNoParent !== undefined) {
            queryParameters['hasNoParent'] = requestParameters.hasNoParent;
        }

        if (requestParameters.searchByAntidoubleId !== undefined) {
            queryParameters['searchByAntidoubleId'] = requestParameters.searchByAntidoubleId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationsCount(requestParameters: GetOrganizationsCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getOrganizationsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setOrganizationsITNsRaw(requestParameters: SetOrganizationsITNsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateOrganizationITNDto === null || requestParameters.updateOrganizationITNDto === undefined) {
            throw new runtime.RequiredError('updateOrganizationITNDto','Required parameter requestParameters.updateOrganizationITNDto was null or undefined when calling setOrganizationsITNs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateOrganizationITNDto.map(UpdateOrganizationITNDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setOrganizationsITNs(requestParameters: SetOrganizationsITNsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.setOrganizationsITNsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async tickOrganizationRaw(requestParameters: TickOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tickOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/{id}/tick`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDtoFromJSON(jsonValue));
    }

    /**
     */
    async tickOrganization(requestParameters: TickOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationDto> {
        const response = await this.tickOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrganizationRaw(requestParameters: UpdateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrganization.');
        }

        if (requestParameters.updateOrganizationDto === null || requestParameters.updateOrganizationDto === undefined) {
            throw new runtime.RequiredError('updateOrganizationDto','Required parameter requestParameters.updateOrganizationDto was null or undefined when calling updateOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationDtoToJSON(requestParameters.updateOrganizationDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateOrganization(requestParameters: UpdateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationDto> {
        const response = await this.updateOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
