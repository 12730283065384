/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OrganizationDtoMappingDto,
    OrganizationDtoMappingDtoFromJSON,
    OrganizationDtoMappingDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetMappingDto,
    SetMappingDtoFromJSON,
    SetMappingDtoToJSON,
} from '../models';

export interface CreateOrganizationsMappingsRequest {
    boundId: string;
    setMappingDto: Array<SetMappingDto>;
}

export interface GetOrganizationMappingByIdRequest {
    id?: string;
    boundId?: string;
}

export interface GetOrganizationValidCrmIdsRequest {
    clientIds?: Array<string>;
    boundId?: string;
}

export interface GetOrganizationsMappingsRequest {
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    boundId?: string;
    crmId?: string;
    organizationId?: string;
}

export interface GetOrganizationsMappingsCountRequest {
    search?: string;
    boundId?: string;
    crmId?: string;
    organizationId?: string;
}

export interface RemoveOrganizationsMappingsRequest {
    boundId: string;
    setMappingDto: Array<SetMappingDto>;
}

/**
 * 
 */
export class OrganizationsMappingsApi extends runtime.BaseAPI {

    /**
     */
    async createOrganizationsMappingsRaw(requestParameters: CreateOrganizationsMappingsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationDtoMappingDto>>> {
        if (requestParameters.boundId === null || requestParameters.boundId === undefined) {
            throw new runtime.RequiredError('boundId','Required parameter requestParameters.boundId was null or undefined when calling createOrganizationsMappings.');
        }

        if (requestParameters.setMappingDto === null || requestParameters.setMappingDto === undefined) {
            throw new runtime.RequiredError('setMappingDto','Required parameter requestParameters.setMappingDto was null or undefined when calling createOrganizationsMappings.');
        }

        const queryParameters: any = {};

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/mappings/organizations/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setMappingDto.map(SetMappingDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationDtoMappingDtoFromJSON));
    }

    /**
     */
    async createOrganizationsMappings(requestParameters: CreateOrganizationsMappingsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationDtoMappingDto>> {
        const response = await this.createOrganizationsMappingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationMappingByIdRaw(requestParameters: GetOrganizationMappingByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationDtoMappingDto>>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/mappings/organizations/byId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationDtoMappingDtoFromJSON));
    }

    /**
     */
    async getOrganizationMappingById(requestParameters: GetOrganizationMappingByIdRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationDtoMappingDto>> {
        const response = await this.getOrganizationMappingByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationValidCrmIdsRaw(requestParameters: GetOrganizationValidCrmIdsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters.clientIds) {
            queryParameters['clientIds'] = requestParameters.clientIds;
        }

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/mappings/organizations/checkByCrmIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getOrganizationValidCrmIds(requestParameters: GetOrganizationValidCrmIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<string>> {
        const response = await this.getOrganizationValidCrmIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationsMappingsRaw(requestParameters: GetOrganizationsMappingsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationDtoMappingDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        if (requestParameters.crmId !== undefined) {
            queryParameters['crmId'] = requestParameters.crmId;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/mappings/organizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationDtoMappingDtoFromJSON));
    }

    /**
     */
    async getOrganizationsMappings(requestParameters: GetOrganizationsMappingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationDtoMappingDto>> {
        const response = await this.getOrganizationsMappingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationsMappingsCountRaw(requestParameters: GetOrganizationsMappingsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        if (requestParameters.crmId !== undefined) {
            queryParameters['crmId'] = requestParameters.crmId;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/mappings/organizations/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationsMappingsCount(requestParameters: GetOrganizationsMappingsCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getOrganizationsMappingsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeOrganizationsMappingsRaw(requestParameters: RemoveOrganizationsMappingsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationDtoMappingDto>>> {
        if (requestParameters.boundId === null || requestParameters.boundId === undefined) {
            throw new runtime.RequiredError('boundId','Required parameter requestParameters.boundId was null or undefined when calling removeOrganizationsMappings.');
        }

        if (requestParameters.setMappingDto === null || requestParameters.setMappingDto === undefined) {
            throw new runtime.RequiredError('setMappingDto','Required parameter requestParameters.setMappingDto was null or undefined when calling removeOrganizationsMappings.');
        }

        const queryParameters: any = {};

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/mappings/organizations/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setMappingDto.map(SetMappingDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationDtoMappingDtoFromJSON));
    }

    /**
     */
    async removeOrganizationsMappings(requestParameters: RemoveOrganizationsMappingsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationDtoMappingDto>> {
        const response = await this.removeOrganizationsMappingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
