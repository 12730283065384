import {observer} from 'mobx-react';
import {Chip, Container} from '@mui/material';
import {LocalityFilterStore} from "./LocalityFilterStore";
import {useTranslation} from "react-i18next";
import {ExpertDialog, FormRoot} from "../../form";
import {LocalitySelectWithTypes} from "./LocalitySelectWithTypes";
import React from "react";
import {CreateButtonsGroup} from "../../Buttons";

interface Props {
    store: LocalityFilterStore;
    style?: React.CSSProperties,
}

export const LocalityFilter = observer(({store, style}: Props) => {
    const {t} = useTranslation();

    const localityValue = store.locality.currentLocality?.value;

    return (
        <div style={{display: 'flex', flexDirection: 'row', ...style}}>
            <ExpertDialog
                title={t('create.addLocality')}
                open={store.Open}
                onClose={() => store.Open = false}
            >
                <FormRoot>
                    <LocalitySelectWithTypes locality={store.locality.locality} store={store}
                                             style={{marginRight: 20}}/>
                    <CreateButtonsGroup onCancel={() => store.Open = false}
                                        isCancelBtnDisabled={false}
                                        onSave={async () => await store.save()}
                                        isSaveBtnDisabled={!localityValue || (localityValue.id == localityValue.parentId)}
                                        testid="localityFilter"/>
                </FormRoot>
            </ExpertDialog>

            <Container
                maxWidth="xl"
                style={{
                    width: '100%', margin: 0, height: "max-content",
                    paddingLeft: 10, display: "flex", flexDirection: "row",
                    alignItems: 'center', flexWrap: "wrap"
                }}>

                {store.items.map((item, index) => {
                    return <Chip label={`${item.name} ${item.house}`} onDelete={() => {
                        store.deleteFilter(index)
                    }} style={{margin: '5px 20px 5px 0'}} key={item.id}/>
                })}
            </Container>

        </div>
    );
});


