/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleDto,
    RoleDtoFromJSON,
    RoleDtoFromJSONTyped,
    RoleDtoToJSON,
} from './RoleDto';
import {
    UserDto,
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './UserDto';

/**
 * 
 * @export
 * @interface UserRoleDto
 */
export interface UserRoleDto {
    /**
     * 
     * @type {string}
     * @memberof UserRoleDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDto
     */
    externalId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserRoleDto
     */
    createdAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDto
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserRoleDto
     */
    modifiedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDto
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserRoleDto
     */
    ticks?: number;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDto
     */
    roleId: string;
    /**
     * 
     * @type {UserDto}
     * @memberof UserRoleDto
     */
    user: UserDto;
    /**
     * 
     * @type {RoleDto}
     * @memberof UserRoleDto
     */
    role: RoleDto;
}

export function UserRoleDtoFromJSON(json: any): UserRoleDto {
    return UserRoleDtoFromJSONTyped(json, false);
}

export function UserRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (json['modifiedAt'] === null ? null : new Date(json['modifiedAt'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'ticks': !exists(json, 'ticks') ? undefined : json['ticks'],
        'userId': json['userId'],
        'roleId': json['roleId'],
        'user': UserDtoFromJSON(json['user']),
        'role': RoleDtoFromJSON(json['role']),
    };
}

export function UserRoleDtoToJSON(value?: UserRoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalId': value.externalId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt === null ? null : value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt === null ? null : value.modifiedAt.toISOString()),
        'modifiedBy': value.modifiedBy,
        'ticks': value.ticks,
        'userId': value.userId,
        'roleId': value.roleId,
        'user': UserDtoToJSON(value.user),
        'role': RoleDtoToJSON(value.role),
    };
}

