import {makeObservable, observable} from 'mobx';
import {getOrganizationAddressHandler, getOrgName, PagedItems, TableStore} from "../../../../../utils";
import {getApiConfiguration, toDateTimeString} from "../../../../../../core";
import {JobsApi, PersonJobDto} from "../../../../../../services/management";
import {PersonItemStore} from "../../../PersonItemStore";

export interface JobDoubleItemStore {
    post: string,
    address: string,
    orgType: string,
    createDate: string,
    date: string,
    id: string,
    status?: string,
    organizationName: string,
    dto: PersonJobDto,
}

export class JobDoubleTableStore extends TableStore<JobDoubleItemStore> {
    readonly store: PersonItemStore;
    current: JobDoubleItemStore | undefined | null;

    constructor(store: PersonItemStore) {
        super();
        this.store = store;

        makeObservable(this, {
            current: observable
        });
    }

    async request(): Promise<PagedItems<JobDoubleItemStore>> {
        let filters: any = {
            page: this.page + 1,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            personId: this.store.id,
            statusIds: 4,
            states: [0, 1],
        };
        const api: JobsApi = new JobsApi(getApiConfiguration());
        const count = await api.getAllPersonJobsCount(filters);
        const newList: JobDoubleItemStore[] = [];
        try {
            const result = await api.getAllPersonJobs(filters);
            for (const item of result) {
                newList.push({
                    address: getOrganizationAddressHandler(item.organization?.addressUnique),
                    createDate: item.createdAt ? toDateTimeString(item?.createdAt) : "",
                    date: item.modifiedAt ? toDateTimeString(item?.modifiedAt) : "",
                    orgType: item.organization?.typeOrg?.nameRus,
                    post: item.post.nameRus,
                    id: item.id,
                    status: item.status?.nameRus,
                    organizationName: getOrgName(item.organization),
                    dto: item
                })
            }
        } finally {
        }
        return new PagedItems<JobDoubleItemStore>(newList, count.count)
    }
}
