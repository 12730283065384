import {makeAutoObservable} from "mobx";
import {ManagementStore} from "../ManagementStore";
import {ExchangeDashboardsStore} from "./pages/Exhange/ExchangeDashboardsStore";
import {ExchangeReportStore} from "./pages/Exhange/ExchangeReportStore";
import {DcrDashboardsStore} from "./pages/Dcr/DcrDashboardsStore";
import {DcrReportStore} from "./pages/Dcr/DcrReportStore";

export class AnalyticsPageStore {
    store: ManagementStore;
    exchangeDashboardsStore: ExchangeDashboardsStore;
    exchangeReportStore: ExchangeReportStore;
    dcrDashboardsStore: DcrDashboardsStore;
    dcrReportStore: DcrReportStore;

    constructor(store: ManagementStore) {
        makeAutoObservable(this);
        this.store = store;
        this.exchangeDashboardsStore = new ExchangeDashboardsStore(this)
        this.exchangeReportStore = new ExchangeReportStore(this)
        this.dcrDashboardsStore = new DcrDashboardsStore(this)
        this.dcrReportStore = new DcrReportStore(this)
    }

    updateState() {
        if (this.store.tabIndex == 12) {
            this.exchangeDashboardsStore.updateState()
        }
        else if (this.store.tabIndex == 13) {
            this.exchangeReportStore.updateState()
        }
        else if (this.store.tabIndex == 14) {
            this.dcrDashboardsStore.updateState()
        }
        else if (this.store.tabIndex == 15) {
            this.dcrReportStore.updateState()
        }
    }
}