import {IForm, IFormValueWithError, IProcessing} from "../../core";
import {action, computed, makeObservable, observable} from "mobx";

export class DefaultFormStore<T extends { [key: string]: IFormValueWithError<any> }> implements IForm, IProcessing {
    public readonly _fields: T;
    processing: boolean = false;
    protected _submitFunc?: (model: any) => Promise<void>;

    constructor(fields: T, submitFunc?: (model: any) => Promise<void>) {
        this._fields = fields;
        this._submitFunc = submitFunc;
        makeObservable(this, {
            _fields: observable,
            processing: observable,
            fields: computed,
            model: computed,
            valid: computed,
            equals: computed,
            reset: action,
            validate: action,
            submit: action,
            afterSubmit: observable
        });
    }

    get fields() {
        return this._fields;
    }

    get model() {
        let model: any = {}
        Object.keys(this._fields).forEach((key: string) => {
            model[key] = this._fields[key].value
        })
        return model;
    }

    get valid(): boolean {
        return Object.keys(this._fields).filter((key: string) => !this._fields[key].valid).length == 0
    }

    get equals(): boolean {
        return Object.keys(this._fields)
            .filter((key: string) => this._fields[key] != undefined)
            .filter((key: string) => !this._fields[key].equals).length == 0
    }

    reset() {
        Object.keys(this._fields).forEach((key: string) => this._fields[key]?.reset())
        // this.validate()
        this.processing = false;
    }

    validate() {
        for (const key of Object.keys(this._fields)) {
            this._fields[key]?.validate();
        }
    }

    async submit() {
        await this.validate();
        if (this.valid && !!this._submitFunc) {
            this.processing = true;
            try {
                this._submitFunc(this.model).then(x => {
                    if (this.afterSubmit) {
                        this.afterSubmit();
                    }
                }).finally(() => this.processing = false);
            } finally {
            }
        }
    }

    afterSubmit?: () => Promise<void> | void;
}
