import {TableCell, Theme} from '@mui/material';
import {SxProps} from "@mui/system";

type Props = {
    children?: React.ReactNode;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    style?: React.CSSProperties;
    span?: number | undefined;
    titleText?: string;
    sx?: SxProps<Theme>;
    width?: string;
    height?: string;
    colSpan?: number;
    testid?: string;
};

export const Cell = ({children, align, style, span, titleText, sx, width, height, colSpan, testid}: Props) => {

    return (
        <TableCell sx={{
            ...{
                margin: 0,
                padding: 0,
                paddingRight: 2
            },
            ...sx,
        }}
                   align={align}
                   width={width}
                   height={height}
                   colSpan={colSpan}
                   style={style}
                   data-testid={`${testid}-tableBody`}
        >
            {children}
        </TableCell>
    );
};
