import {getObject, setObject} from '../../../../core';
import {ManagementStore} from "../../ManagementStore";
import {ImportTableStore} from './ImportTableStore';
import {ImportFile} from "../../../../services/import";
import {DynamicalComponentStore} from "../../../utils";
import {ImportAddressStore} from "./ImportAddressStore";

interface ImportStoreLocalFilters {
    tabIndex: number;
    expand: boolean;
}

export class ImportTabsStore extends DynamicalComponentStore {
    readonly store: ManagementStore;
    readonly importPersonTableStore: ImportTableStore = new ImportTableStore("Persons", this);
    readonly importOrganizationTableStore: ImportTableStore = new ImportTableStore("Organizations", this);
    readonly importAddressStore: ImportAddressStore = new ImportAddressStore(this);

    constructor(store: ManagementStore) {
        super();

        this.store = store;

        const filters = getObject<ImportStoreLocalFilters>('import-store');
        if (filters) {
            this._tabIndex = filters.tabIndex;
            this.expand = filters.expand;
        }

        this.store.store.signalRStore.registerSubs("import", {
            "ImportFileCreate": async (e: ImportFile) => {
                await this.updateState()
            },
            "ImportFileUpdate": async (e: ImportFile) => {
                await this.updateState()
            }
        })
    }

    async updateState(): Promise<void> {
        this.saveLocalFilters()
        if (this._tabIndex === 0) {
            await this.importPersonTableStore.pull();
        } else if (this._tabIndex === 1) {
            await this.importOrganizationTableStore.pull();
        }
    }

    saveLocalFilters = () => {
        setObject<ImportStoreLocalFilters>('import-store', {
            tabIndex: this._tabIndex,
            expand: this.expand,
        })
    }
}
