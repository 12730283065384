/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    ListSortDirection,
    OrganizationEmailDto,
    OrganizationEmailDtoFromJSON,
    RemoveOrganizationEmailDto,
    RemoveOrganizationEmailDtoToJSON,
    SetOrganizationEmailDto,
    SetOrganizationEmailDtoToJSON,
    UpdateOrganizationEmailDto,
    UpdateOrganizationEmailDtoToJSON,
} from '../models';

export interface GetOrganizationEmailByIdRequest {
    id: string;
}

export interface GetOrganizationEmailsRequest {
    search?: string;
    description?: string;
    organizationId?: string;
    emailTypeIds?: Array<string>;
    emailStatusIds?: Array<string>;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetOrganizationEmailsCountRequest {
    search?: string;
    description?: string;
    organizationId?: string;
    emailTypeIds?: Array<string>;
    emailStatusIds?: Array<string>;
}

export interface RemoveOrganizationEmailsRequest {
    organizationId: string;
    removeOrganizationEmailDto: Array<RemoveOrganizationEmailDto>;
}

export interface SetOrganizationEmailsRequest {
    organizationId: string;
    setOrganizationEmailDto: Array<SetOrganizationEmailDto>;
}

export interface UpdateOrganizationEmailRequest {
    id: string;
    updateOrganizationEmailDto: UpdateOrganizationEmailDto;
}

/**
 * 
 */
export class OrganizationEmailsApi extends runtime.BaseAPI {

    /**
     */
    async getOrganizationEmailByIdRaw(requestParameters: GetOrganizationEmailByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationEmailDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrganizationEmailById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/emails/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationEmailById(requestParameters: GetOrganizationEmailByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationEmailDto> {
        const response = await this.getOrganizationEmailByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationEmailsRaw(requestParameters: GetOrganizationEmailsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationEmailDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.emailTypeIds) {
            queryParameters['emailTypeIds'] = requestParameters.emailTypeIds;
        }

        if (requestParameters.emailStatusIds) {
            queryParameters['emailStatusIds'] = requestParameters.emailStatusIds;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/emails`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationEmailDtoFromJSON));
    }

    /**
     */
    async getOrganizationEmails(requestParameters: GetOrganizationEmailsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationEmailDto>> {
        const response = await this.getOrganizationEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationEmailsCountRaw(requestParameters: GetOrganizationEmailsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.emailTypeIds) {
            queryParameters['emailTypeIds'] = requestParameters.emailTypeIds;
        }

        if (requestParameters.emailStatusIds) {
            queryParameters['emailStatusIds'] = requestParameters.emailStatusIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/emails/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationEmailsCount(requestParameters: GetOrganizationEmailsCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getOrganizationEmailsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeOrganizationEmailsRaw(requestParameters: RemoveOrganizationEmailsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationEmailDto>>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling removeOrganizationEmails.');
        }

        if (requestParameters.removeOrganizationEmailDto === null || requestParameters.removeOrganizationEmailDto === undefined) {
            throw new runtime.RequiredError('removeOrganizationEmailDto','Required parameter requestParameters.removeOrganizationEmailDto was null or undefined when calling removeOrganizationEmails.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/emails/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.removeOrganizationEmailDto.map(RemoveOrganizationEmailDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationEmailDtoFromJSON));
    }

    /**
     */
    async removeOrganizationEmails(requestParameters: RemoveOrganizationEmailsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationEmailDto>> {
        const response = await this.removeOrganizationEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setOrganizationEmailsRaw(requestParameters: SetOrganizationEmailsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationEmailDto>>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling setOrganizationEmails.');
        }

        if (requestParameters.setOrganizationEmailDto === null || requestParameters.setOrganizationEmailDto === undefined) {
            throw new runtime.RequiredError('setOrganizationEmailDto','Required parameter requestParameters.setOrganizationEmailDto was null or undefined when calling setOrganizationEmails.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/emails/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setOrganizationEmailDto.map(SetOrganizationEmailDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationEmailDtoFromJSON));
    }

    /**
     */
    async setOrganizationEmails(requestParameters: SetOrganizationEmailsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationEmailDto>> {
        const response = await this.setOrganizationEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrganizationEmailRaw(requestParameters: UpdateOrganizationEmailRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationEmailDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrganizationEmail.');
        }

        if (requestParameters.updateOrganizationEmailDto === null || requestParameters.updateOrganizationEmailDto === undefined) {
            throw new runtime.RequiredError('updateOrganizationEmailDto','Required parameter requestParameters.updateOrganizationEmailDto was null or undefined when calling updateOrganizationEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/emails/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationEmailDtoToJSON(requestParameters.updateOrganizationEmailDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateOrganizationEmail(requestParameters: UpdateOrganizationEmailRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationEmailDto> {
        const response = await this.updateOrganizationEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
