import {makeAutoObservable} from 'mobx';
import {GroupItemStore} from "../GroupItemStore";
import {DefaultValueStore} from "../../../../../../components/shared/DefaultValueStore";
import {ClientApi, ClientDto, DcrGroupDto} from "../../../../../../services/dcr";
import {DefaultMultiSelectStore} from "../../../../../../components/shared/DefaultMultiSelectStore";
import {DefaultFormModalStore} from "../../../../../../components/shared/DefaultFormModalStore";

export class SettingsTabStore {
    dto?: DcrGroupDto;
    readonly store: GroupItemStore;
    readonly form: DefaultFormModalStore<{
        responseTime: DefaultValueStore<string>,
        clients: DefaultMultiSelectStore<ClientDto>
    }>

    constructor(store: GroupItemStore) {
        this.store = store;
        this.form = new DefaultFormModalStore({
            responseTime: new DefaultValueStore("00:00"),
            clients: new DefaultMultiSelectStore([],
                (x) => new ClientApi().apiDcrV1ClientsGet(x))
        }, (e) => this.store.store.save(this.store))
        makeAutoObservable(this)
    }

    update(dto: DcrGroupDto){
        this.dto = dto;
        this.form.fields.responseTime.update(this.dto!.responseTime!)
        this.form.fields.clients.update(this.dto.clients!.map(x => ({id: x})))
    }
}
