/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Api.Import
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonStatusDto
 */
export interface PersonStatusDto {
    /**
     * 
     * @type {number}
     * @memberof PersonStatusDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PersonStatusDto
     */
    nameRus: string;
    /**
     * 
     * @type {string}
     * @memberof PersonStatusDto
     */
    nameEng?: string | null;
}

export function PersonStatusDtoFromJSON(json: any): PersonStatusDto {
    return PersonStatusDtoFromJSONTyped(json, false);
}

export function PersonStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonStatusDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'id': !exists(json, 'id') ? undefined : json['id'],
        'nameRus': json['nameRus'],
        'nameEng': !exists(json, 'nameEng') ? undefined : json['nameEng'],
    };
}

export function PersonStatusDtoToJSON(value?: PersonStatusDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'nameRus': value.nameRus,
        'nameEng': value.nameEng,
    };
}

