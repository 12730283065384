import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import type {FC, ReactNode} from 'react';

import {SideNavItem} from './side-nav-item';

interface Item {
    disabled?: boolean;
    external?: boolean;
    icon?: ReactNode;
    items?: Item[];
    label?: ReactNode;
    path?: string;
    title: string;
    permissions?: string[]
}

const renderItems = ({
    depth = 0,
    items,
    pathname,
}: {
    depth?: number;
    items: Item[];
    pathname?: string | null;
}): JSX.Element[] =>
    items.reduce(
        (acc: JSX.Element[], item) =>
            reduceChildRoutes({
                acc,
                depth,
                item,
                pathname,
            }),
        []
    );

const reduceChildRoutes = ({
    acc,
    depth,
    item,
    pathname,
}: {
    acc: JSX.Element[];
    depth: number;
    item: Item;
    pathname?: string | null;
}): Array<JSX.Element> => {
    const checkPath = !!(item.path && pathname);
    const partialMatch = checkPath
        ? (item.items?.length ?? 0) > 0
            ? pathname?.indexOf(item.path!) == 0
            : pathname.includes(item.path!)
        : false;
    const exactMatch = checkPath ? pathname === item.path : false;

    if (item.items) {
        acc.push(
            <SideNavItem
                active={partialMatch}
                depth={depth}
                disabled={item.disabled}
                icon={item.icon}
                key={item.title}
                label={item.label}
                open={partialMatch}
                title={item.title}
                permissions={item.permissions}
            >
                <Stack
                    component="ul"
                    spacing={0.5}
                    sx={{
                        listStyle: 'none',
                        m: 0,
                        p: 0,
                    }}
                >
                    {renderItems({
                        depth: depth + 1,
                        items: item.items,
                        pathname,
                    })}
                </Stack>
            </SideNavItem>
        );
    } else {
        acc.push(
            <SideNavItem
                active={exactMatch}
                depth={depth}
                disabled={item.disabled}
                external={item.external}
                icon={item.icon}
                key={item.title}
                label={item.label}
                path={item.path}
                title={item.title}
                permissions={item.permissions}
            />
        );
    }

    return acc;
};

interface SideNavSectionProps {
    items?: Item[];
    pathname?: string | null;
    subheader?: string;
}

export const SideNavSection: FC<SideNavSectionProps> = (props) => {
    const { items = [], pathname, subheader = '', ...other } = props;

    return (
        <Stack
            component="ul"
            spacing={0.5}
            sx={{
                listStyle: 'none',
                m: 0,
                p: 0,
            }}
            {...other}
        >
            {subheader && (
                <Box
                    component="li"
                    sx={{
                        color: 'var(--nav-section-title-color)',
                        fontSize: 12,
                        fontWeight: 700,
                        lineHeight: 1.66,
                        mb: 1,
                        ml: 1,
                        textTransform: 'uppercase',
                    }}
                >
                    {subheader}
                </Box>
            )}
            {renderItems({ items, pathname })}
        </Stack>
    );
};

SideNavSection.propTypes = {
    items: PropTypes.array,
    pathname: PropTypes.string,
    subheader: PropTypes.string,
};
