import {observer} from "mobx-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {FormRoot} from "../../../../components";
import {PersonStore} from "../../../../stores/management/dcr/requests/specifications/entity/PersonStore";
import {OrganizationPhoneDto, PersonSpecialityDto} from "../../../../services/management";
import {Box, List, Typography} from "@mui/material";
import {CopyToClipboard} from "../../../../components/CopyToClipboard";
import {toDateTimeString} from "../../../../core";
import {emptyFieldHandler, getStatusNameHandler} from "../../../../stores";
import {DcrListItem} from "../../DcrListItem";
import {NavLink} from "../../../../components/NavLink";

interface Props {
    store: PersonStore;
}

export const Person = observer(({store}: Props) => {
    const {t} = useTranslation();
    const valueStyle = {overflow: 'hidden', textOverflow: 'ellipsis'}

    return (
        <FormRoot>
            {
                !store?.loading ?
                    ((store?.data?.id)?
                            <List>
                                <DcrListItem name={'Data360 Id'}>
                                    <NavLink to={`/persons/${store.data.id}`} target="_blank">{store.data.id}</NavLink>
                                    <CopyToClipboard copyText={store.data.id}/>
                                </DcrListItem>


                                <DcrListItem name={t("dcrPage.fields.date")}
                                             value={toDateTimeString(store.data.modifiedAt!)}
                                             slim={true}
                                />
                                <Box style={{
                                    display: "flex",
                                }}>
                                    <DcrListItem name={t("dcrPage.fields.person")}>
                                        <Typography variant="body1">
                                            {store.data.surname.nameRus} {store.data.firstName.nameRus} {store.data?.middleName?.nameRus}
                                        </Typography>
                                    </DcrListItem>
                                    <DcrListItem name={t("dcrPage.forms.person.gender")}
                                                 value={store.data.gender?.nameRus}
                                        // slim={true}
                                    />
                                </Box>
                                <Box style={{
                                    display: "flex",
                                    alignItems: 'center'
                                }}>
                                    <DcrListItem name={t("dcrPage.forms.person.primarySpeciality")}
                                                 value={emptyFieldHandler(store.data.primarySpeciality?.nameRus)}
                                        // slim={true}
                                    />

                                    <DcrListItem name={t("dcrPage.forms.person.specialty")}
                                        // style={{marginLeft: 24}}
                                        // slim={true}
                                    >
                                        <div>
                                            {store.specialities.map((x: PersonSpecialityDto, index) =>
                                                <div style={valueStyle} key={index}>
                                                    <Typography variant="body1">{x.speciality.nameRus}</Typography>
                                                </div>
                                            )}
                                        </div>
                                    </DcrListItem>
                                </Box>
                                <DcrListItem name={t("dcrPage.fields.company")}>
                                    {
                                        store.jobInfo ? <NavLink target="_blank"
                                                                 to={`/organizations/${store.jobInfo?.organizationId}`}>
                                                <div style={{...valueStyle}}>
                                                    <Typography variant="body1">{store.jobInfo?.name}</Typography></div>
                                            </NavLink> :
                                            <div style={valueStyle}>{t('dcrPage.empty')}</div>
                                    }
                                </DcrListItem>

                                <DcrListItem name={t("dcrPage.forms.organization.address")}
                                             value={store.address}
                                />
                                <Box style={{
                                    display: "flex",
                                    alignItems: 'center'
                                }}>
                                    <DcrListItem name={t("dcrPage.fields.position")}
                                                 value={emptyFieldHandler(store.jobInfo?.position)}
                                        // slim={true}
                                    />

                                    <DcrListItem name={t("dcrPage.fields.status")}
                                                 value={getStatusNameHandler(store.jobInfo?.status)}
                                        // slim={true}
                                    />
                                </Box>

                                <DcrListItem name={t("dcrPage.fields.phone1")}

                                >
                                    <div style={valueStyle}>
                                        {store.data.primaryJob && store.jobs.includes(store.data.primaryJob!) ?
                                            <div>{store.data.primaryJob?.organization?.phones.length > 0 ?
                                                <div>{store.data.primaryJob.organization.phones.map((phone: OrganizationPhoneDto) =>
                                                    <Typography variant="body1">{phone.phone.phoneNumber}</Typography>)}
                                                </div> :
                                                <Typography variant="body1">{t('dcrPage.empty')}</Typography>
                                            }</div> :
                                            <div>{store.jobs[0]?.organization?.phones.length > 0 ?
                                                <div>{store.jobs[0].organization.phones.map((phone: OrganizationPhoneDto) =>
                                                    <div>{emptyFieldHandler(phone.phone.phoneNumber)}</div>)}
                                                </div> : t('dcrPage.empty')
                                            }</div>
                                        }
                                    </div>
                                </DcrListItem>
                                <DcrListItem name={t("dcrPage.fields.additionalJobs")}>
                                    {
                                        <NavLink to={`/persons/${store.data.id}`} target="_blank">{store.jobsCount}</NavLink>
                                    }
                                </DcrListItem>
                            </List> :
                            store._store.errorApi && <Box sx={{pt: 2, color: 'red'}}>{store.errorMessage}</Box>
                    )
                    : <Box sx={{pt: 2}}>{t("common.download")}...</Box>
            }
        </FormRoot>
    );
});
