import {makeAutoObservable} from 'mobx';
import {PersonDto} from '../../../../../services/management';
import {PersonEmailTableStore} from './emails/PersonEmailTableStore';
import {PersonWebsiteTableStore} from './website/PersonWebsiteTableStore';
import {PersonPhoneTableStore} from './phones/PersonPhoneTableStore';
import {PersonItemStore} from "../../PersonItemStore";
import {IHasTabs} from "../../../../../core";

export class PersonOthersTabsStore implements IHasTabs {
    readonly store: PersonItemStore;
    readonly emailStore: PersonEmailTableStore;
    readonly websiteStore: PersonWebsiteTableStore;
    readonly phoneStore: PersonPhoneTableStore;

    constructor(store: PersonItemStore, dto: PersonDto) {
        this.store = store;
        this.emailStore = new PersonEmailTableStore(store, dto);
        this.websiteStore = new PersonWebsiteTableStore(store, dto);
        this.phoneStore = new PersonPhoneTableStore(store, dto);

        makeAutoObservable(this);
    }

    private _tabIndex: number = 0;

    async updateState() {
        switch (this.tabIndex) {
            case 0: {
                await this.phoneStore.updateState()
                break
            }
            case 1: {
                await this.emailStore.updateState()
                break
            }
            case 2: {
                await this.websiteStore.updateState()
                break
            }
        }
    }

    get tabIndex() {
        return this._tabIndex;
    }

    set tabIndex(index: number) {
        this._tabIndex = index
        this.updateState()
    }
}
