/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AssignedPersonDepJobTagDto,
    AssignedPersonDepJobTagDtoFromJSON,
    AssignedPersonDepJobTagDtoToJSON,
    CreateAssignedPersonDepJobTagDto,
    CreateAssignedPersonDepJobTagDtoFromJSON,
    CreateAssignedPersonDepJobTagDtoToJSON,
    PersonDepJobDto,
    PersonDepJobDtoFromJSON,
    PersonDepJobDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface CreateAssignedPersonDepJobTagRequest {
    createAssignedPersonDepJobTagDto?: CreateAssignedPersonDepJobTagDto;
}

export interface DeleteAssignedPersonDepJobTagRequest {
    id: string;
}

export interface SetAssignedDepJobTagsRequest {
    depJobId?: string;
    requestBody?: Array<string>;
}

/**
 * 
 */
export class AssignedPersonDepJobTagsApi extends runtime.BaseAPI {

    /**
     */
    async createAssignedPersonDepJobTagRaw(requestParameters: CreateAssignedPersonDepJobTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AssignedPersonDepJobTagDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/depjobs/tags/assigned`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAssignedPersonDepJobTagDtoToJSON(requestParameters.createAssignedPersonDepJobTagDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssignedPersonDepJobTagDtoFromJSON(jsonValue));
    }

    /**
     */
    async createAssignedPersonDepJobTag(requestParameters: CreateAssignedPersonDepJobTagRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AssignedPersonDepJobTagDto> {
        const response = await this.createAssignedPersonDepJobTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAssignedPersonDepJobTagRaw(requestParameters: DeleteAssignedPersonDepJobTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AssignedPersonDepJobTagDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAssignedPersonDepJobTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/depjobs/tags/assigned/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssignedPersonDepJobTagDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteAssignedPersonDepJobTag(requestParameters: DeleteAssignedPersonDepJobTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AssignedPersonDepJobTagDto> {
        const response = await this.deleteAssignedPersonDepJobTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setAssignedDepJobTagsRaw(requestParameters: SetAssignedDepJobTagsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonDepJobDto>> {
        const queryParameters: any = {};

        if (requestParameters.depJobId !== undefined) {
            queryParameters['depJobId'] = requestParameters.depJobId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/depjobs/tags/assigned/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDepJobDtoFromJSON(jsonValue));
    }

    /**
     */
    async setAssignedDepJobTags(requestParameters: SetAssignedDepJobTagsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonDepJobDto> {
        const response = await this.setAssignedDepJobTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
