/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetPersonEmailDto
 */
export interface SetPersonEmailDto {
    /**
     * 
     * @type {string}
     * @memberof SetPersonEmailDto
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonEmailDto
     */
    personId: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonEmailDto
     */
    emailStatusId: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonEmailDto
     */
    emailTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonEmailDto
     */
    description?: string | null;
}

export function SetPersonEmailDtoFromJSON(json: any): SetPersonEmailDto {
    return SetPersonEmailDtoFromJSONTyped(json, false);
}

export function SetPersonEmailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetPersonEmailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailAddress': json['emailAddress'],
        'personId': json['personId'],
        'emailStatusId': json['emailStatusId'],
        'emailTypeId': json['emailTypeId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function SetPersonEmailDtoToJSON(value?: SetPersonEmailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailAddress': value.emailAddress,
        'personId': value.personId,
        'emailStatusId': value.emailStatusId,
        'emailTypeId': value.emailTypeId,
        'description': value.description,
    };
}

