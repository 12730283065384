import { createSvgIcon } from '@mui/material/utils';

export const EyeOff = createSvgIcon(
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M3.707 2.29301C3.5184 2.11085 3.2658 2.01006 3.0036 2.01234C2.7414 2.01461 2.49059 2.11978 2.30518 2.30519C2.11978 2.4906 2.01461 2.74141 2.01233 3.00361C2.01005 3.26581 2.11084 3.51841 2.293 3.70701L16.293 17.707C16.4816 17.8892 16.7342 17.99 16.9964 17.9877C17.2586 17.9854 17.5094 17.8802 17.6948 17.6948C17.8802 17.5094 17.9854 17.2586 17.9877 16.9964C17.99 16.7342 17.8892 16.4816 17.707 16.293L16.234 14.82C17.7915 13.5781 18.9433 11.8999 19.542 10C18.268 5.94301 14.478 3.00001 10 3.00001C8.43243 2.99788 6.88651 3.36586 5.488 4.07401L3.708 2.29301H3.707ZM7.968 6.55301L9.482 8.06801C9.82109 7.97796 10.1779 7.97856 10.5167 8.06974C10.8555 8.16092 11.1644 8.33949 11.4124 8.58758C11.6605 8.83566 11.8391 9.14455 11.9303 9.48334C12.0215 9.82213 12.0221 10.1789 11.932 10.518L13.446 12.032C13.897 11.268 14.0812 10.3758 13.9697 9.49569C13.8581 8.61557 13.4571 7.7975 12.8298 7.17019C12.2025 6.54287 11.3844 6.1419 10.5043 6.03036C9.62421 5.91881 8.73198 6.10302 7.968 6.55401V6.55301Z" fill="#6B7280"/>
<path d="M12.454 16.697L9.75 13.992C8.77769 13.9311 7.86103 13.5174 7.17206 12.8286C6.48309 12.1398 6.06917 11.2233 6.008 10.251L2.335 6.578C1.49021 7.58402 0.852349 8.74692 0.458 10C1.732 14.057 5.523 17 10 17C10.847 17 11.669 16.895 12.454 16.697Z" fill="#6B7280"/>
</svg>,
  'EyeOff'
);

