/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Api.Export
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {ExportFile, ExportFileFromJSON, ExportImportType,} from '../models';

export interface DeleteFileRequest {
    fileId?: string;
}

export interface DownloadFileRequest {
    fileId?: string;
}

export interface EditFileNameRequest {
    fileId?: string;
    newFileName?: string;
}

export interface GetFilesRequest {
    userId?: string;
    exportType?: ExportImportType;
}

/**
 * 
 */
export class ExportManagementApi extends runtime.BaseAPI {

    /**
     */
    async deleteFileRaw(requestParameters: DeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.fileId !== undefined) {
            queryParameters['fileId'] = requestParameters.fileId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ExportManagement/DeleteFile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteFile(requestParameters: DeleteFileRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteFileRaw(requestParameters, initOverrides);
    }

    /**
     */
    async downloadFileRaw(requestParameters: DownloadFileRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.fileId !== undefined) {
            queryParameters['fileId'] = requestParameters.fileId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ExportManagement/DownloadFile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async downloadFile(requestParameters: DownloadFileRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<any> {
        await this.downloadFileRaw(requestParameters, initOverrides);
    }

    /**
     */
    async editFileNameRaw(requestParameters: EditFileNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.fileId !== undefined) {
            queryParameters['fileId'] = requestParameters.fileId;
        }

        if (requestParameters.newFileName !== undefined) {
            queryParameters['newFileName'] = requestParameters.newFileName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ExportManagement/EditFileName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async editFileName(requestParameters: EditFileNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.editFileNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFilesRaw(requestParameters: GetFilesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ExportFile>>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.exportType !== undefined) {
            queryParameters['exportType'] = requestParameters.exportType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ExportManagement/GetFiles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExportFileFromJSON));
    }

    /**
     */
    async getFiles(requestParameters: GetFilesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ExportFile>> {
        const response = await this.getFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
