/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Exchange.Analytics.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ClientsAggregation,
    ClientsAggregationFromJSON,
    ClientsAggregationToJSON,
    ClientsAggregationHistogram,
    ClientsAggregationHistogramFromJSON,
    ClientsAggregationHistogramToJSON,
    CountsHistogram,
    CountsHistogramFromJSON,
    CountsHistogramToJSON,
    ElasticFeedPagedResponse,
    ElasticFeedPagedResponseFromJSON,
    ElasticFeedPagedResponseToJSON,
    EntityType,
    EntityTypeFromJSON,
    EntityTypeToJSON,
    EntityTypesAggregation,
    EntityTypesAggregationFromJSON,
    EntityTypesAggregationToJSON,
    FeedStatus,
    FeedStatusFromJSON,
    FeedStatusToJSON,
    NameDto,
    NameDtoFromJSON,
    NameDtoToJSON,
    OrganizationTypesAggregation,
    OrganizationTypesAggregationFromJSON,
    OrganizationTypesAggregationToJSON,
    SpecialityAggregation,
    SpecialityAggregationFromJSON,
    SpecialityAggregationToJSON,
    SummaryAggregation,
    SummaryAggregationFromJSON,
    SummaryAggregationToJSON,
} from '../models';

export interface ApiAnalyticsFeedsClientsAggregationGetRequest {
    from?: Date;
    to?: Date;
    entityType?: EntityType;
    limit?: number;
}

export interface ApiAnalyticsFeedsClientsAggregationHistogramErrorsGetRequest {
    from?: Date;
    to?: Date;
    entityType?: EntityType;
    interval?: string;
    clientIds?: Array<string>;
}

export interface ApiAnalyticsFeedsClientsCountGetRequest {
    from?: Date;
    to?: Date;
}

export interface ApiAnalyticsFeedsCountHistogramGetRequest {
    from?: Date;
    to?: Date;
    interval?: string;
}

export interface ApiAnalyticsFeedsEntitytypesAggregationGetRequest {
    from?: Date;
    to?: Date;
    interval?: string;
    clientIds?: Array<string>;
}

export interface ApiAnalyticsFeedsErrorsCountHistogramGetRequest {
    from?: Date;
    to?: Date;
    interval?: string;
}

export interface ApiAnalyticsFeedsFindGetRequest {
    from?: Date;
    to?: Date;
    clientIds?: Array<string>;
    rootEntityId?: string;
    entityType?: Array<EntityType>;
    organizationTypeIds?: Array<string>;
    specialityIds?: Array<string>;
    status?: Array<FeedStatus>;
    page?: number;
    size?: number;
}

export interface ApiAnalyticsFeedsOrganizationtypesAggregationGetRequest {
    from?: Date;
    to?: Date;
    clientId?: string;
}

export interface ApiAnalyticsFeedsSpecialitiesAggregationGetRequest {
    from?: Date;
    to?: Date;
    clientId?: string;
    limit?: number;
}

export interface ApiAnalyticsFeedsSummaryAggregationGetRequest {
    from?: Date;
    to?: Date;
    clientId?: string;
}

/**
 * 
 */
export class FeedsControllerNewApi extends runtime.BaseAPI {

    /**
     */
    async apiAnalyticsFeedsClientsAggregationGetRaw(requestParameters: ApiAnalyticsFeedsClientsAggregationGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ClientsAggregation>>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.entityType !== undefined) {
            queryParameters['entityType'] = requestParameters.entityType;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/analytics/feeds/clients-aggregation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientsAggregationFromJSON));
    }

    /**
     */
    async apiAnalyticsFeedsClientsAggregationGet(requestParameters: ApiAnalyticsFeedsClientsAggregationGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ClientsAggregation>> {
        const response = await this.apiAnalyticsFeedsClientsAggregationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAnalyticsFeedsClientsAggregationHistogramErrorsGetRaw(requestParameters: ApiAnalyticsFeedsClientsAggregationHistogramErrorsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ClientsAggregationHistogram>>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.entityType !== undefined) {
            queryParameters['entityType'] = requestParameters.entityType;
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        if (requestParameters.clientIds) {
            queryParameters['clientIds'] = requestParameters.clientIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/analytics/feeds/clients-aggregation-histogram-errors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientsAggregationHistogramFromJSON));
    }

    /**
     */
    async apiAnalyticsFeedsClientsAggregationHistogramErrorsGet(requestParameters: ApiAnalyticsFeedsClientsAggregationHistogramErrorsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ClientsAggregationHistogram>> {
        const response = await this.apiAnalyticsFeedsClientsAggregationHistogramErrorsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAnalyticsFeedsClientsCountGetRaw(requestParameters: ApiAnalyticsFeedsClientsCountGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/analytics/feeds/clients-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAnalyticsFeedsClientsCountGet(requestParameters: ApiAnalyticsFeedsClientsCountGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<number> {
        const response = await this.apiAnalyticsFeedsClientsCountGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAnalyticsFeedsClientsGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<NameDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/analytics/feeds/clients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NameDtoFromJSON));
    }

    /**
     */
    async apiAnalyticsFeedsClientsGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<NameDto>> {
        const response = await this.apiAnalyticsFeedsClientsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAnalyticsFeedsCountHistogramGetRaw(requestParameters: ApiAnalyticsFeedsCountHistogramGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<CountsHistogram>>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/analytics/feeds/count-histogram`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CountsHistogramFromJSON));
    }

    /**
     */
    async apiAnalyticsFeedsCountHistogramGet(requestParameters: ApiAnalyticsFeedsCountHistogramGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<CountsHistogram>> {
        const response = await this.apiAnalyticsFeedsCountHistogramGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAnalyticsFeedsEntitytypesAggregationGetRaw(requestParameters: ApiAnalyticsFeedsEntitytypesAggregationGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EntityTypesAggregation>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        if (requestParameters.clientIds) {
            queryParameters['clientIds'] = requestParameters.clientIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/analytics/feeds/entitytypes-aggregation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityTypesAggregationFromJSON(jsonValue));
    }

    /**
     */
    async apiAnalyticsFeedsEntitytypesAggregationGet(requestParameters: ApiAnalyticsFeedsEntitytypesAggregationGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EntityTypesAggregation> {
        const response = await this.apiAnalyticsFeedsEntitytypesAggregationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAnalyticsFeedsErrorsCountHistogramGetRaw(requestParameters: ApiAnalyticsFeedsErrorsCountHistogramGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<CountsHistogram>>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/analytics/feeds/errors-count-histogram`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CountsHistogramFromJSON));
    }

    /**
     */
    async apiAnalyticsFeedsErrorsCountHistogramGet(requestParameters: ApiAnalyticsFeedsErrorsCountHistogramGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<CountsHistogram>> {
        const response = await this.apiAnalyticsFeedsErrorsCountHistogramGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAnalyticsFeedsFindGetRaw(requestParameters: ApiAnalyticsFeedsFindGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ElasticFeedPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['From'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['To'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.clientIds) {
            queryParameters['ClientIds'] = requestParameters.clientIds;
        }

        if (requestParameters.rootEntityId !== undefined) {
            queryParameters['RootEntityId'] = requestParameters.rootEntityId;
        }

        if (requestParameters.entityType) {
            queryParameters['EntityType'] = requestParameters.entityType;
        }

        if (requestParameters.organizationTypeIds) {
            queryParameters['OrganizationTypeIds'] = requestParameters.organizationTypeIds;
        }

        if (requestParameters.specialityIds) {
            queryParameters['SpecialityIds'] = requestParameters.specialityIds;
        }

        if (requestParameters.status) {
            queryParameters['Status'] = requestParameters.status;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['Size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/analytics/feeds/find`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ElasticFeedPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAnalyticsFeedsFindGet(requestParameters: ApiAnalyticsFeedsFindGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ElasticFeedPagedResponse> {
        const response = await this.apiAnalyticsFeedsFindGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAnalyticsFeedsOrganizationtypesAggregationGetRaw(requestParameters: ApiAnalyticsFeedsOrganizationtypesAggregationGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationTypesAggregation>>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/analytics/feeds/organizationtypes-aggregation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationTypesAggregationFromJSON));
    }

    /**
     */
    async apiAnalyticsFeedsOrganizationtypesAggregationGet(requestParameters: ApiAnalyticsFeedsOrganizationtypesAggregationGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationTypesAggregation>> {
        const response = await this.apiAnalyticsFeedsOrganizationtypesAggregationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAnalyticsFeedsSpecialitiesAggregationGetRaw(requestParameters: ApiAnalyticsFeedsSpecialitiesAggregationGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<SpecialityAggregation>>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/analytics/feeds/specialities-aggregation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpecialityAggregationFromJSON));
    }

    /**
     */
    async apiAnalyticsFeedsSpecialitiesAggregationGet(requestParameters: ApiAnalyticsFeedsSpecialitiesAggregationGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<SpecialityAggregation>> {
        const response = await this.apiAnalyticsFeedsSpecialitiesAggregationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAnalyticsFeedsSummaryAggregationGetRaw(requestParameters: ApiAnalyticsFeedsSummaryAggregationGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<SummaryAggregation>>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/analytics/feeds/summary-aggregation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SummaryAggregationFromJSON));
    }

    /**
     */
    async apiAnalyticsFeedsSummaryAggregationGet(requestParameters: ApiAnalyticsFeedsSummaryAggregationGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<SummaryAggregation>> {
        const response = await this.apiAnalyticsFeedsSummaryAggregationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
