import {getApiConfiguration} from "../../../../../core";
import {BoundDto, PersonDto, PersonsApi,} from "../../../../../services/management";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from "../../../../../components/shared/DefaultSelectStore";
import {MappingTableStore} from "./MappingTableStore";
import {MappingsStore} from "./MappingsStore";
import {DefaultFormModalStore} from "../../../../../components/shared/DefaultFormModalStore";
import {getAppoloClient, GRAPHQL_URLS} from "../../../../../graphql";
import {
    PersonMappingFragment,
    PersonMappingsQuery,
    PersonMappingsQueryVariables,
    RemovePersonMappingMutation,
    RemovePersonMappingMutationVariables,
    SetPersonMappingMutation,
    SetPersonMappingMutationVariables,
} from "../../../../../gql/graphql";
import {PERSON_MAPPING_QUERY} from "../../../../../graphql/queries.graphql";
import {REMOVE_PERSON_MAPPING_MUTATION, SET_PERSON_MAPPING_MUTATION} from "../../../../../graphql/mutations.graphql";
import {jurisdictionId} from "../../../../authorization/AuthorizationStore";
import {getPersonFullNameHandler} from "../../../../utils";

export class PersonTableStore extends MappingTableStore<PersonMappingFragment> {
    constructor(store: MappingsStore, dto: BoundDto) {
        super(store, dto, 'client-mappings-person-store');
        this.link = "persons";

        this.creator.fields.entity.nameExp = (x) => getPersonFullNameHandler(x)
        this.creator.fields.entity.defaultSort = true;
        this.creator.fields.entity.required = true;
        this.creator.fields.value.required = true;
    }

    creator: DefaultFormModalStore<{ value: DefaultValueStore<string>; entity: DefaultSelectStore<any> }> =
        new DefaultFormModalStore({
            entity: new DefaultSelectStore<PersonDto>(
                null, (x) => new PersonsApi(getApiConfiguration()).getPersons({
                    ...x,
                    orderBy: 'surname',
                    order: 'ascending'
                })),
            value: new DefaultValueStore<string>("")
        }, (model) => this.create());

    async getMappings(filters: any): Promise<{
        items: { item: PersonMappingFragment; name: string }[];
        count: number
    }> {
        delete filters['order']

        const {data} = await getAppoloClient(GRAPHQL_URLS.MAPPINGS).query<PersonMappingsQuery, PersonMappingsQueryVariables>({
            query: PERSON_MAPPING_QUERY, variables: filters,
        })

        return {
            items: (data.personMappings?.items ?? []).map(x => ({item: x, name: ""})),
            count: data.personMappings?.totalCount ?? 0
        }
    }

    async removeMapping(filter: {
        boundId: string;
        setMappingDto: Array<{ clientValue: string; entityId: string }>
    }): Promise<void> {
        for (let val of filter.setMappingDto) {
            await getAppoloClient(GRAPHQL_URLS.MAPPINGS).mutate<RemovePersonMappingMutation, RemovePersonMappingMutationVariables>({
                mutation: REMOVE_PERSON_MAPPING_MUTATION, variables: {
                    clientId: val.clientValue,
                    entityId: val.entityId,
                    jurisdictionId: jurisdictionId
                }
            })
        }
    }

    async setMapping(filter: {
        boundId: string;
        setMappingDto: Array<{ clientValue: string; entityId: string }>
    }): Promise<{ item: PersonMappingFragment; name: string }[]> {
        const list: { item: PersonMappingFragment; name: string }[] = [];
        for (let val of filter.setMappingDto) {
            const item = await getAppoloClient(GRAPHQL_URLS.MAPPINGS).mutate<SetPersonMappingMutation, SetPersonMappingMutationVariables>({
                mutation: SET_PERSON_MAPPING_MUTATION, variables: {
                    entityId: val.entityId!,
                    clientValue: val.clientValue,
                    clientId: filter.boundId,
                    jurisdictionId: jurisdictionId
                }
            })
            list.push(({item: item.data?.setPersonMapping!, name: ""}))
        }
        return list;
    }
}
