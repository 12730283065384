/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface SetOrganizationDobleDto
 */
export interface SetOrganizationDobleDto {
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationDobleDto
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationDobleDto
     */
    dobleId: string;
}

export function SetOrganizationDobleDtoFromJSON(json: any): SetOrganizationDobleDto {
    return SetOrganizationDobleDtoFromJSONTyped(json, false);
}

export function SetOrganizationDobleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetOrganizationDobleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationId': json['organizationId'],
        'dobleId': json['dobleId'],
    };
}

export function SetOrganizationDobleDtoToJSON(value?: SetOrganizationDobleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationId': value.organizationId,
        'dobleId': value.dobleId,
    };
}

