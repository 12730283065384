import {getApiConfiguration} from '../../../../../core';
import {PersonEmailTypesApi} from "../../../../../services/management";
import {DictionaryCreateEntity, DictionaryEntity, DictionaryTableStore} from "../../DictionaryTableStore";
import {DictionaryPersonStore} from "../DictionaryPersonStore";

export class PersonEmailTypeTableStore extends DictionaryTableStore {
    constructor(store: DictionaryPersonStore) {
        super(store, 'email-type-store');
    }

    async Create(model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new PersonEmailTypesApi(getApiConfiguration()).createPersonEmailType({setEmailTypeDto: model});
    }

    async Delete(id: string): Promise<DictionaryEntity> {
        return await new PersonEmailTypesApi(getApiConfiguration()).deletePersonEmailType({id: id});
    }

    async GetCount(search: string): Promise<{ count: number }> {
        return await new PersonEmailTypesApi(getApiConfiguration()).getPersonEmailTypesCount({search: search});
    }

    async GetList(filters: any): Promise<DictionaryEntity[]> {
        return await new PersonEmailTypesApi(getApiConfiguration()).getPersonEmailTypes(filters)
    }

    async Update(id: string, model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new PersonEmailTypesApi(getApiConfiguration()).updatePersonEmailType({id: id, setEmailTypeDto: model});
    }

}
