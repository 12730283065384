import {makeAutoObservable} from 'mobx';
import {OrganizationItemStore} from '../../OrganizationItemStore';
import {OrganizationPhoneTableStore} from './phones/OrganizationPhoneTableStore';
import {OrganizationDto} from '../../../../../services/management';
import {OrganizationEmailTableStore} from './emails/OrganizationEmailTableStore';
import {OrganizationWebsiteTableStore} from './website/OrganizationWebsiteTableStore';
import {IHasTabs} from "../../../../../core";

export class OthersTabsStore implements IHasTabs {
    readonly store: OrganizationItemStore;
    readonly phoneStore: OrganizationPhoneTableStore;
    readonly emailStore: OrganizationEmailTableStore;
    readonly websiteStore: OrganizationWebsiteTableStore;

    constructor(store: OrganizationItemStore, dto: OrganizationDto) {
        this.store = store;
        this.phoneStore = new OrganizationPhoneTableStore(store, dto);
        this.emailStore = new OrganizationEmailTableStore(store, dto);
        this.websiteStore = new OrganizationWebsiteTableStore(store, dto);

        makeAutoObservable(this);
    }

    private _tabIndex: number = 0;

    async updateState() {
        switch (this.tabIndex) {
            case 0: {
                await this.phoneStore.updateState()
                break
            }
            case 1: {
                await this.emailStore.updateState()
                break
            }
            case 2: {
                await this.websiteStore.updateState()
                break
            }
        }
    }

    get tabIndex() {
        return this._tabIndex;
    }

    set tabIndex(index: number) {
        this._tabIndex = index
        this.updateState()
    }
}
