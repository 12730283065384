/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {RequestFieldType, RequestFieldTypeFromJSON, RequestFieldTypeToJSON,} from './RequestFieldType';
import {RequestRecordType, RequestRecordTypeFromJSON, RequestRecordTypeToJSON,} from './RequestRecordType';

/**
 * 
 * @export
 * @interface FieldInfoDto
 */
export interface FieldInfoDto {
    /**
     * 
     * @type {string}
     * @memberof FieldInfoDto
     */
    field: string;
    /**
     * 
     * @type {string}
     * @memberof FieldInfoDto
     */
    value: string;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfoDto
     */
    isAdditional: boolean;
    /**
     * 
     * @type {RequestFieldType}
     * @memberof FieldInfoDto
     */
    requestFieldType: RequestFieldType;
    /**
     * 
     * @type {RequestRecordType}
     * @memberof FieldInfoDto
     */
    type: RequestRecordType;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfoDto
     */
    isRequired: boolean;
}

export function FieldInfoDtoFromJSON(json: any): FieldInfoDto {
    return FieldInfoDtoFromJSONTyped(json, false);
}

export function FieldInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': json['field'],
        'value': json['value'],
        'isAdditional': json['isAdditional'],
        'requestFieldType': RequestFieldTypeFromJSON(json['requestFieldType']),
        'type': RequestRecordTypeFromJSON(json['type']),
        'isRequired': json['isRequired'],
    };
}

export function FieldInfoDtoToJSON(value?: FieldInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': value.field,
        'value': value.value,
        'isAdditional': value.isAdditional,
        'requestFieldType': RequestFieldTypeToJSON(value.requestFieldType),
        'type': RequestRecordTypeToJSON(value.type),
        'isRequired': value.isRequired,
    };
}

