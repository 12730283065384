import {computed, makeObservable, observable} from "mobx";
import {OrganizationItemStore, PagedItems} from "../../../..";
import {getApiConfiguration, getObject, setObject} from "../../../../../core";
import {DepEmployeesApi, EmployeesApi, OrganizationDto} from "../../../../../services/management";
import {TableLocalFilters, TableStore} from "../../../../utils";
import {OrganizationEmployeesItemStore} from "./OrganizationEmployeesItemStore";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";


export class OrganizationEmployeesTableStore extends TableStore<OrganizationEmployeesItemStore> {
    store: OrganizationItemStore
    _dto: OrganizationDto
    name: DefaultValueStore<string> =
        new DefaultValueStore<string>("", null, (e) => this.updateState())
    surname: DefaultValueStore<string> =
        new DefaultValueStore<string>("", null, (e) => this.updateState())
    middleName: DefaultValueStore<string> =
        new DefaultValueStore<string>("", null, (e) => this.updateState())

    current: OrganizationEmployeesItemStore | undefined | null;

    constructor(orgStore: OrganizationItemStore, dto: OrganizationDto) {
        super();
        this.store = orgStore;
        this._dto = dto;
        this._order = 'descending';

        const filters = getObject<TableLocalFilters<OrganizationEmployeesItemStore>>('organizations-employees-store-id-' + this.store._dto.id);

        if (filters) {
            this._page = filters.page;
            this._size = filters.size;
            this.search.setValueWithoutEffects(filters.search);
            this._order = filters.order;
            this._orderBy = filters.orderBy;
        }

        makeObservable(this, {
            current: observable,
            depOrg: computed,
        });
    }


    get depOrg(): boolean {
        return this.store._store.typeDepartmentList.includes(this._dto.typeOrgId)
    }

    get isDouble(): boolean {
        return this.store.isDouble;
    }

    async request(): Promise<PagedItems<OrganizationEmployeesItemStore>> {
        const filters = {
            page: this.page + 1,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            firstName: this.name.value ?? "",
            surname: this.surname.value ?? "",
            surName: this.surname.value ?? "",
            middleName: this.middleName.value ?? "",
        };
        const api: EmployeesApi = new EmployeesApi(getApiConfiguration());
        const api_2: DepEmployeesApi = new DepEmployeesApi(getApiConfiguration());
        const newList: OrganizationEmployeesItemStore[] = [];
        let count: number | null | undefined = 0;

        try {
            await this.store._store.getTypeDepartmentList();

            const items = await (this.depOrg ? api_2.getPersonDepEmployees({
                    ...filters,
                    orderBy: this.orderBy?.replace('firstName', 'name'),
                    organizationDepId: this._dto.id
                }) :
                api.getEmployees({...filters, organizationId: this._dto.id}));

            count = (await (this.depOrg ? api_2.getDepEmployeesCount({
                    ...filters,
                    organizationDepId: this._dto.id
                }) :
                api.getEmployeesCount({...filters, organizationId: this._dto.id}))).count;

            for (const item of items) {
                newList.push(new OrganizationEmployeesItemStore(this, item))
            }
        } catch (e) {

        }
        return new PagedItems<OrganizationEmployeesItemStore>(newList, count)
    };

    onFiltersSave() {
        setObject<TableLocalFilters<OrganizationEmployeesItemStore>>('organizations-employees-store-id-' + this.store._dto.id, {
            page: this.page,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
        });
    }
}
