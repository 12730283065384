import {useRoutes} from "react-router-dom";
import './i18n';
import {routes} from "./pages/routes";
import {SettingsConsumer, SettingsProvider} from "./contexts/settings";
import {createTheme} from "./theme";
import {ThemeProvider} from "@mui/material/styles";
import {Helmet} from "react-helmet-async";
import {CssBaseline} from "@mui/material";
import {SettingsDrawer} from "./components/settings/settings-drawer";
import {Toaster} from "react-hot-toast";
import {useEffect} from "react";
import {useRootStore} from "./stores";
import {jurisdictionId} from "./stores/authorization/AuthorizationStore";

const App = () => {
    const element = useRoutes(routes);
    const {authorizationStore, managementStore} = useRootStore();

    useEffect(() => {
        const callback = async (e: any) => {
            if (e.key == 'jurisdictionId' && jurisdictionId != e.newValue) {
                authorizationStore.jurisdictions.value = authorizationStore.jurisdictions.items.find(x => x.jurisdictionId == e.newValue) ??
                    authorizationStore.jurisdictions.value
            }
            if (e.key == 'logout') {
                await authorizationStore.logout()
            }
            if (e.key == 'personUpdate') {
                await managementStore.personStore.updateState()
                managementStore.personStore.current?.updateState()
            }
            if (e.key == 'organizationUpdate') {
                await managementStore.organizationTableStore.updateState()
                managementStore.organizationTableStore.current?.updateState()
            }
        }
        window.addEventListener('storage', callback, false)
        return () => window.removeEventListener('storage', callback)
    }, [])
    return (
        <SettingsProvider>
            <SettingsConsumer>
                {(settings) => {
                    // Prevent theme flicker when restoring custom settings from browser storage
                    if (!settings.isInitialized) {
                        // return null;
                    }

                    const theme = createTheme({
                        colorPreset: settings.colorPreset,
                        contrast: settings.contrast,
                        direction: settings.direction,
                        paletteMode: settings.paletteMode,
                        responsiveFontSizes: settings.responsiveFontSizes
                    });

                    return (
                        <ThemeProvider theme={theme}>
                            <Helmet>
                                <meta
                                    name="color-scheme"
                                    content={settings.paletteMode}
                                />
                                <meta
                                    name="theme-color"
                                    content={theme.palette.neutral[900]}
                                />
                            </Helmet>
                            <div>
                                <CssBaseline/>
                                {

                                    <>
                                        {element}
                                        <SettingsDrawer
                                            canReset={settings.isCustom}
                                            onClose={settings.handleDrawerClose}
                                            onReset={settings.handleReset}
                                            onUpdate={settings.handleUpdate}
                                            open={settings.openDrawer}
                                            values={{
                                                colorPreset: settings.colorPreset,
                                                contrast: settings.contrast,
                                                direction: settings.direction,
                                                paletteMode: settings.paletteMode,
                                                responsiveFontSizes: settings.responsiveFontSizes,
                                                stretch: settings.stretch,
                                                layout: settings.layout,
                                                navColor: settings.navColor
                                            }}
                                        />
                                    </>
                                }
                                <Toaster/>
                            </div>
                        </ThemeProvider>
                    );
                }}
            </SettingsConsumer>
        </SettingsProvider>)
};

export default App;

