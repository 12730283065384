import { observer } from 'mobx-react';
import { Login } from '../pages';
import { useAuthorizationStore } from '../stores';

interface Props {
    children?: React.ReactNode
}

export const Authentication = observer(({ children }: Props) => {
    const store = useAuthorizationStore();

    return (
        <>
            {
                store.isAuthenticated
                ? children
                : <Login />
            }

        </>
    );
});