/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    ListSortDirection,
    OrganizationPhoneDto,
    OrganizationPhoneDtoFromJSON,
    SetOrganizationPhoneDto,
    SetOrganizationPhoneDtoToJSON,
} from '../models';

export interface CreateOrganizationPhoneRequest {
    setOrganizationPhoneDto: SetOrganizationPhoneDto;
}

export interface DeleteOrganizationPhoneRequest {
    id: string;
}

export interface GetOrganizationPhoneByIdRequest {
    id: string;
}

export interface GetOrganizationPhonesRequest {
    search?: string;
    description?: string;
    organizationId?: string;
    phoneCategoryIds?: Array<string>;
    phoneStatusIds?: Array<string>;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetOrganizationPhonesCountRequest {
    search?: string;
    description?: string;
    phoneCategoryIds?: Array<string>;
    phoneStatusIds?: Array<string>;
    organizationId?: string;
}

export interface RemoveOrganizationPhonesRequest {
    organizationId: string;
    setOrganizationPhoneDto: Array<SetOrganizationPhoneDto>;
}

export interface SetOrganizationPhonesRequest {
    organizationId: string;
    setOrganizationPhoneDto: Array<SetOrganizationPhoneDto>;
}

export interface UpdateOrganizationPhoneRequest {
    id: string;
    setOrganizationPhoneDto: SetOrganizationPhoneDto;
}

/**
 * 
 */
export class OrganizationPhonesApi extends runtime.BaseAPI {

    /**
     */
    async createOrganizationPhoneRaw(requestParameters: CreateOrganizationPhoneRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationPhoneDto>> {
        if (requestParameters.setOrganizationPhoneDto === null || requestParameters.setOrganizationPhoneDto === undefined) {
            throw new runtime.RequiredError('setOrganizationPhoneDto','Required parameter requestParameters.setOrganizationPhoneDto was null or undefined when calling createOrganizationPhone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/phones`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetOrganizationPhoneDtoToJSON(requestParameters.setOrganizationPhoneDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationPhoneDtoFromJSON(jsonValue));
    }

    /**
     */
    async createOrganizationPhone(requestParameters: CreateOrganizationPhoneRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationPhoneDto> {
        const response = await this.createOrganizationPhoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteOrganizationPhoneRaw(requestParameters: DeleteOrganizationPhoneRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationPhoneDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOrganizationPhone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/phones/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationPhoneDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteOrganizationPhone(requestParameters: DeleteOrganizationPhoneRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationPhoneDto> {
        const response = await this.deleteOrganizationPhoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationPhoneByIdRaw(requestParameters: GetOrganizationPhoneByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationPhoneDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrganizationPhoneById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/phones/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationPhoneDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationPhoneById(requestParameters: GetOrganizationPhoneByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationPhoneDto> {
        const response = await this.getOrganizationPhoneByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationPhonesRaw(requestParameters: GetOrganizationPhonesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationPhoneDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.phoneCategoryIds) {
            queryParameters['phoneCategoryIds'] = requestParameters.phoneCategoryIds;
        }

        if (requestParameters.phoneStatusIds) {
            queryParameters['phoneStatusIds'] = requestParameters.phoneStatusIds;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/phones`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationPhoneDtoFromJSON));
    }

    /**
     */
    async getOrganizationPhones(requestParameters: GetOrganizationPhonesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationPhoneDto>> {
        const response = await this.getOrganizationPhonesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationPhonesCountRaw(requestParameters: GetOrganizationPhonesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.phoneCategoryIds) {
            queryParameters['phoneCategoryIds'] = requestParameters.phoneCategoryIds;
        }

        if (requestParameters.phoneStatusIds) {
            queryParameters['phoneStatusIds'] = requestParameters.phoneStatusIds;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/phones/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationPhonesCount(requestParameters: GetOrganizationPhonesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getOrganizationPhonesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeOrganizationPhonesRaw(requestParameters: RemoveOrganizationPhonesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationPhoneDto>>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling removeOrganizationPhones.');
        }

        if (requestParameters.setOrganizationPhoneDto === null || requestParameters.setOrganizationPhoneDto === undefined) {
            throw new runtime.RequiredError('setOrganizationPhoneDto','Required parameter requestParameters.setOrganizationPhoneDto was null or undefined when calling removeOrganizationPhones.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/phones/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setOrganizationPhoneDto.map(SetOrganizationPhoneDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationPhoneDtoFromJSON));
    }

    /**
     */
    async removeOrganizationPhones(requestParameters: RemoveOrganizationPhonesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationPhoneDto>> {
        const response = await this.removeOrganizationPhonesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setOrganizationPhonesRaw(requestParameters: SetOrganizationPhonesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationPhoneDto>>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling setOrganizationPhones.');
        }

        if (requestParameters.setOrganizationPhoneDto === null || requestParameters.setOrganizationPhoneDto === undefined) {
            throw new runtime.RequiredError('setOrganizationPhoneDto','Required parameter requestParameters.setOrganizationPhoneDto was null or undefined when calling setOrganizationPhones.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/phones/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setOrganizationPhoneDto.map(SetOrganizationPhoneDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationPhoneDtoFromJSON));
    }

    /**
     */
    async setOrganizationPhones(requestParameters: SetOrganizationPhonesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationPhoneDto>> {
        const response = await this.setOrganizationPhonesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrganizationPhoneRaw(requestParameters: UpdateOrganizationPhoneRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationPhoneDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrganizationPhone.');
        }

        if (requestParameters.setOrganizationPhoneDto === null || requestParameters.setOrganizationPhoneDto === undefined) {
            throw new runtime.RequiredError('setOrganizationPhoneDto','Required parameter requestParameters.setOrganizationPhoneDto was null or undefined when calling updateOrganizationPhone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/phones/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetOrganizationPhoneDtoToJSON(requestParameters.setOrganizationPhoneDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationPhoneDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateOrganizationPhone(requestParameters: UpdateOrganizationPhoneRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationPhoneDto> {
        const response = await this.updateOrganizationPhoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
