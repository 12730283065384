/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OrganizationStatusDto,
    OrganizationStatusDtoFromJSON,
    OrganizationStatusDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetOrganizationStatusDto,
    SetOrganizationStatusDtoFromJSON,
    SetOrganizationStatusDtoToJSON,
} from '../models';

export interface CreateOrganizationStatusRequest {
    setOrganizationStatusDto: SetOrganizationStatusDto;
}

export interface DeleteOrganizationStatusRequest {
    id: string;
}

export interface GetOrganizationStatusByIdRequest {
    id: string;
}

export interface GetOrganizationStatusByNameRequest {
    name: string;
}

export interface GetOrganizationStatusesRequest {
    search?: string;
    orderBy?: string;
    searchByExternalId?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetOrganizationStatusesCountRequest {
    search?: string;
    searchByExternalId?: string;
}

export interface UpdateOrganizationStatusRequest {
    id: string;
    setOrganizationStatusDto: SetOrganizationStatusDto;
}

/**
 * 
 */
export class OrganizationStatusesApi extends runtime.BaseAPI {

    /**
     */
    async createOrganizationStatusRaw(requestParameters: CreateOrganizationStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationStatusDto>> {
        if (requestParameters.setOrganizationStatusDto === null || requestParameters.setOrganizationStatusDto === undefined) {
            throw new runtime.RequiredError('setOrganizationStatusDto','Required parameter requestParameters.setOrganizationStatusDto was null or undefined when calling createOrganizationStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/statuses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetOrganizationStatusDtoToJSON(requestParameters.setOrganizationStatusDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async createOrganizationStatus(requestParameters: CreateOrganizationStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationStatusDto> {
        const response = await this.createOrganizationStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteOrganizationStatusRaw(requestParameters: DeleteOrganizationStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOrganizationStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/statuses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteOrganizationStatus(requestParameters: DeleteOrganizationStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationStatusDto> {
        const response = await this.deleteOrganizationStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationStatusByIdRaw(requestParameters: GetOrganizationStatusByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrganizationStatusById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/statuses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationStatusById(requestParameters: GetOrganizationStatusByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationStatusDto> {
        const response = await this.getOrganizationStatusByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationStatusByNameRaw(requestParameters: GetOrganizationStatusByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationStatusDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getOrganizationStatusByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/statuses/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationStatusByName(requestParameters: GetOrganizationStatusByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationStatusDto> {
        const response = await this.getOrganizationStatusByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationStatusesRaw(requestParameters: GetOrganizationStatusesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationStatusDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/statuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationStatusDtoFromJSON));
    }

    /**
     */
    async getOrganizationStatuses(requestParameters: GetOrganizationStatusesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationStatusDto>> {
        const response = await this.getOrganizationStatusesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationStatusesCountRaw(requestParameters: GetOrganizationStatusesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/statuses/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationStatusesCount(requestParameters: GetOrganizationStatusesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getOrganizationStatusesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrganizationStatusRaw(requestParameters: UpdateOrganizationStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrganizationStatus.');
        }

        if (requestParameters.setOrganizationStatusDto === null || requestParameters.setOrganizationStatusDto === undefined) {
            throw new runtime.RequiredError('setOrganizationStatusDto','Required parameter requestParameters.setOrganizationStatusDto was null or undefined when calling updateOrganizationStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/statuses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetOrganizationStatusDtoToJSON(requestParameters.setOrganizationStatusDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateOrganizationStatus(requestParameters: UpdateOrganizationStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationStatusDto> {
        const response = await this.updateOrganizationStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
