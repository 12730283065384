/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetGenderDto
 */
export interface SetGenderDto {
    /**
     * 
     * @type {string}
     * @memberof SetGenderDto
     */
    nameRus: string;
    /**
     * 
     * @type {string}
     * @memberof SetGenderDto
     */
    nameEng?: string | null;
}

export function SetGenderDtoFromJSON(json: any): SetGenderDto {
    return SetGenderDtoFromJSONTyped(json, false);
}

export function SetGenderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetGenderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nameRus': json['nameRus'],
        'nameEng': !exists(json, 'nameEng') ? undefined : json['nameEng'],
    };
}

export function SetGenderDtoToJSON(value?: SetGenderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nameRus': value.nameRus,
        'nameEng': value.nameEng,
    };
}

