import {observer} from 'mobx-react';
import {Avatar, Box, Button, Card, CardContent, CardHeader, IconButton, Typography} from '@mui/material';
import React from "react";
import {useTranslation} from "react-i18next";
import {RequestCommentsStore} from "../../../../stores/management/dcr/requests/RequestCommentsStore";
import {red} from '@mui/material/colors';
import moment from "moment/moment";
import {DefaultValue} from "../../../../components/shared/components/DefaultValue";
import {FormRoot, ToolDivider} from "../../../../components";
import {Scrollbars} from 'react-custom-scrollbars';
import CloseIcon from "@mui/icons-material/Close";
import {RequestStatusEnum} from "../../../../stores/management/dcr/types";

interface Props {
    store: RequestCommentsStore;
}

export const RequestComments = observer(({store}: Props) => {
    const {t} = useTranslation();

    return (
        <Box sx={{width: '100%', marginTop: 1}} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{flex: 1}}>
                <Typography variant="h6">
                    {t('dcrPage.comments')}
                </Typography>
                {
                    store.comments.length == 0 &&
                    <Typography style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 400
                    }}>
                        {t('dcrPage.empty')}
                    </Typography>
                }
                {
                    store.comments.length > 0 &&
                    <Scrollbars style={{height: 400, marginTop: 15, top: '4px'}} autoHide>
                        {store.comments.map((x, i) =>
                            <Card key={i} style={{
                                background: store.store.store.store.themeColorStore.themeSettings == 'dark' ? '#192732' : 'rgb(179 220 253)',
                                borderRadius: 'unset',
                                borderLeft: '5px #515BA4 solid',
                                marginBottom: 5
                            }}>
                                <CardHeader
                                    style={{padding: 15}}
                                    avatar={
                                        <Avatar sx={{bgcolor: red[500]}} aria-label="recipe">
                                            {(x.userName ?? x.name)![0]!}
                                        </Avatar>
                                    }
                                    title={(x.userName ?? x.name)}
                                />
                                <CardContent
                                    style={{padding: 19, paddingTop: 0, display: 'flex', flexDirection: 'column'}}>
                                    <Typography variant="body2" color="text.secondary">
                                        {x.text}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" style={{marginLeft: 'auto'}}>
                                        {moment(x.date!).format('DD/MM/YYYY HH:mm')}
                                    </Typography>
                                </CardContent>
                            </Card>)
                        }
                    </Scrollbars>
                }
                {(!store.RequestItemStore?.isTake || store.RequestItemStore.dto.status != RequestStatusEnum.OnVerification) &&
                    <IconButton aria-label="close"
                                sx={{
                                    position: 'absolute',
                                    marginTop: 0.5,
                                    right: theme => theme.spacing(1),
                                    top: theme => theme.spacing(1),
                                    color: theme => theme.palette.grey[500],
                                }}
                                onClick={() => store.closeModal()}
                    >
                        <CloseIcon/>
                    </IconButton>
                }
            </Box>

            {
                store.RequestItemStore?.isTake && store.RequestItemStore.dto.status == RequestStatusEnum.OnVerification &&
                <>
                    <ToolDivider style={{height: 'auto'}} vertical/>
                    <Box flex={1}>
                        <Box>
                            <Typography variant="h6" style={{marginLeft: 15}}>
                                {t('dcrPage.putComments')}
                            </Typography>
                            <IconButton aria-label="close"
                                        sx={{
                                            position: 'absolute',
                                            marginTop: 0.5,
                                            right: theme => theme.spacing(1),
                                            top: theme => theme.spacing(1),
                                            color: theme => theme.palette.grey[500],
                                        }}
                                        onClick={() => store.closeModal()}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                        <FormRoot>
                            <Box sx={{p: 2}}>
                                <DefaultValue store={store.commentValue}
                                              testid="RequestDetails-commentStore"
                                              title={""}
                                              multiline
                                              InputProps={{
                                                  sx: {
                                                      height: '100%'
                                                  }
                                              }}
                                              autoFocus
                                              rows={14}
                                              style={{width: '100%', height: '100%'}}/>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'row', padding: '1px 8px'}}
                            >

                                <Button
                                    onClick={async () => await store.submit()}
                                    size="large"
                                    color="secondary"
                                    variant={"text"}
                                    style={{
                                        minWidth: 'unset',
                                        backgroundColor: 'transparent',
                                        lineHeight: 'unset'
                                    }}
                                    type="submit"
                                    disabled={store.loading || !store.valid}
                                >
                                    {t('buttons.save')}
                                </Button>
                                <Button
                                    onClick={() => {
                                        store.Open = false
                                        store.reset()
                                    }}
                                    color="error"
                                    size="large"
                                    variant={"text"}
                                    style={{
                                        minWidth: 'unset',
                                        backgroundColor: 'transparent',
                                        lineHeight: 'unset',
                                        marginLeft: 15
                                    }}
                                >
                                    {t('buttons.cancel')}
                                </Button>
                            </Box>
                        </FormRoot>
                    </Box>
                </>
            }
        </Box>
    );
});
