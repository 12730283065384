/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClientStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;
export type ClientStatus = typeof ClientStatus[keyof typeof ClientStatus];


export function ClientStatusFromJSON(json: any): ClientStatus {
    return ClientStatusFromJSONTyped(json, false);
}

export function ClientStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientStatus {
    return json as ClientStatus;
}

export function ClientStatusToJSON(value?: ClientStatus | null): any {
    return value as any;
}

