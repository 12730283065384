import {getApiConfiguration} from "../../../../../core";
import {BoundDto, PersonStatusDto, PersonStatusesApi,} from "../../../../../services/management";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from "../../../../../components/shared/DefaultSelectStore";
import {MappingTableStore} from "./MappingTableStore";
import {MappingsStore} from "./MappingsStore";
import {DefaultFormModalStore} from "../../../../../components/shared/DefaultFormModalStore";
import {getAppoloClient, GRAPHQL_URLS} from "../../../../../graphql";
import {
    InputMaybe,
    PersonStatusMappingFilterInput,
    PersonStatusMappingFragment,
    PersonStatusMappingsQuery,
    PersonStatusMappingsQueryVariables,
    RemovePersonStatusMappingMutation,
    RemovePersonStatusMappingMutationVariables,
    SetPersonStatusMappingMutation,
    SetPersonStatusMappingMutationVariables
} from "../../../../../gql/graphql";
import {PERSON_STATUS_MAPPING_QUERY,} from "../../../../../graphql/queries.graphql";
import {
    REMOVE_PERSON_STATUS_MAPPING_MUTATION,
    SET_PERSON_STATUS_MAPPING_MUTATION
} from "../../../../../graphql/mutations.graphql";
import {jurisdictionId} from "../../../../authorization/AuthorizationStore";

export class PersonStatusTableStore extends MappingTableStore<PersonStatusMappingFragment> {
    constructor(store: MappingsStore, dto: BoundDto) {
        super(store, dto, 'client-mappings-typeOrg-store');

        this.creator.fields.entity.defaultSort = true;
        this.creator.fields.entity.required = true;
        this.creator.fields.value.required = true;
    }

    creator: DefaultFormModalStore<{ value: DefaultValueStore<string>; entity: DefaultSelectStore<any> }> =
        new DefaultFormModalStore({
            entity: new DefaultSelectStore<PersonStatusDto>(
                null, (x) => new PersonStatusesApi(getApiConfiguration()).getPersonStatuses()),
            value: new DefaultValueStore<string>("")
        }, (model) => this.create());

    async getMappings(filters: any): Promise<{
        items: { item: PersonStatusMappingFragment; name: string }[];
        count: number
    }> {
        delete filters['order']

        const ff: InputMaybe<Array<PersonStatusMappingFilterInput>> = [];

        if (this.searchById.value) {
            ff.push({
                entityId: {eq: parseInt(this.searchById.value)}
            })
        }

        filters.where.and = ff;

        const {data} = await getAppoloClient(GRAPHQL_URLS.MAPPINGS).query<PersonStatusMappingsQuery, PersonStatusMappingsQueryVariables>({
            query: PERSON_STATUS_MAPPING_QUERY, variables: filters,
        })

        return {
            items: (data.personStatusMappings?.items ?? []).map(x => ({item: x, name: x.names?.nameRus ?? ""})),
            count: data.personStatusMappings?.totalCount ?? 0
        }
    }

    async removeMapping(filter: {
        boundId: string;
        setMappingDto: Array<{ clientValue: string; entityId: string | number }>
    }): Promise<void> {
        for (let val of filter.setMappingDto) {
            await getAppoloClient(GRAPHQL_URLS.MAPPINGS).mutate<RemovePersonStatusMappingMutation, RemovePersonStatusMappingMutationVariables>({
                mutation: REMOVE_PERSON_STATUS_MAPPING_MUTATION, variables: {
                    clientId: val.clientValue,
                    entityId: Number(val.entityId),
                    jurisdictionId: jurisdictionId
                }
            })
        }
    }

    async setMapping(filter: {
        boundId: string;
        setMappingDto: Array<{ clientValue: string; entityId: string }>
    }): Promise<{ item: PersonStatusMappingFragment; name: string }[]> {
        const list: { item: PersonStatusMappingFragment; name: string }[] = [];
        for (let val of filter.setMappingDto) {
            const item = await getAppoloClient(GRAPHQL_URLS.MAPPINGS).mutate<SetPersonStatusMappingMutation, SetPersonStatusMappingMutationVariables>({
                mutation: SET_PERSON_STATUS_MAPPING_MUTATION, variables: {
                    entityId: Number(val.entityId),
                    clientValue: val.clientValue,
                    clientId: filter.boundId,
                    jurisdictionId: jurisdictionId
                }
            })
            list.push(({
                item: item.data?.setPersonStatusMapping!,
                name: item.data?.setPersonStatusMapping.names?.nameRus ?? ""
            }))
        }
        return list;
    }
}
