import { makeAutoObservable } from 'mobx';
import { AuthorizationStore } from "./AuthorizationStore";
import { IExpanded, IHasTabs, getObject, setObject } from '../../core';

interface UserState {
    detailsTabIndex: number;
    tabIndex: number;
    expand: boolean;
}

export class UserVM implements IHasTabs, IExpanded {
    readonly _store: AuthorizationStore;
    username: string;
    processing: boolean = false;
    private _detailsTabIndex = 0;
    private _tabIndex: number = 0;
    private _expand: boolean = false;

    constructor(store: AuthorizationStore, username: string) {
        this._store = store;
        this.username = username;
        const st = getObject<UserState>(`user-state`);
        if(st){
            this._detailsTabIndex = st.detailsTabIndex;
            this._tabIndex = st.tabIndex;
            this._expand = st.expand;
        }

        makeAutoObservable(this);
    }

    get expand() {
        return this._expand;
    }

    set expand(value: boolean) {
        this._expand = value;
        this.saveFilters();
        if(this.expand){
            this.handleTabChange();
        }
    }

    get detailsTabIndex() {
        return this._detailsTabIndex;
    }

    set detailsTabIndex(value: number) {
        this._detailsTabIndex = value;
        this.saveFilters();
        this.handleTabChange();
    }

    get tabIndex() {
        return this._tabIndex;
    }

    set tabIndex(value: number) { // это внутренние табы, если они нужны. Нужно при переходе в сущность обновить стейт выбронного таба
        this._tabIndex = value;
        this.saveFilters();
        if(this._tabIndex === 0){

        }
        else if (this._tabIndex === 3) {

        }
    }

    handleTabChange() { // это внейшний таб, детейл в таблице. Если они нужны.
        if(this._detailsTabIndex === 0){
            //параметры. Тут по идеи нечего обновлять
        }
        else if(this._detailsTabIndex === 1){
            // обновить стейт если тут будет сложный стор
        }
        else if(this._detailsTabIndex === 2){
             // обновить стейт если тут будет сложный стор
        }
    }

    saveFilters() {
        setObject<UserState>(`user-state`, {
            detailsTabIndex: this._detailsTabIndex,
            tabIndex: this._tabIndex,
            expand: this._expand
        });
    }
}
