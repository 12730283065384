import {makeAutoObservable} from 'mobx';
import {
    DepJobsApi,
    JobsApi,
    JobStatusesApi,
    OrganizationDto,
    OrgNameDto,
    PersonStatusDto
} from "../../../../../../services/management";
import {getApiConfiguration} from "../../../../../../core";
import {DefaultSelectStore} from '../../../../../../components/shared/DefaultSelectStore';
import {DefaultValueStore} from '../../../../../../components/shared/DefaultValueStore';
import {JobArchiveTableStore} from "./JobArchiveTableStore";
import {DefaultFormModalStore} from "../../../../../../components/shared/DefaultFormModalStore";

export class JobArchiveCreatorStore {
    readonly creator: DefaultFormModalStore<{
        personOrganization: DefaultValueStore<string>,
        personPost: DefaultValueStore<string>,
        personStatus: DefaultSelectStore<PersonStatusDto>
        recoveryReason: DefaultValueStore<string>
    }> = new DefaultFormModalStore({
        personOrganization: new DefaultValueStore<string>(""),
        personPost: new DefaultValueStore<string>(""),
        personStatus: new DefaultSelectStore(null,
            (filters) => new JobStatusesApi(getApiConfiguration()).getJobStatuses({...filters})),
        recoveryReason: new DefaultValueStore<string>("")
    }, async (e) => await this.create())


    readonly store: JobArchiveTableStore;

    constructor(store: JobArchiveTableStore) {
        this.store = store;
        
        this.creator.afterSubmit = async () => await this.store._store.loadPerson();
        this.creator.fields.personStatus.required = true;
        this.creator.fields.recoveryReason.required = true;

        makeAutoObservable(this);
    }

    getNameOrg(x: OrganizationDto): OrgNameDto {
        return x.names.find((x: OrgNameDto) => x.typeName?.nameRus.toLowerCase() === 'синдикативное') || x?.names[0]
    }

    Open(value: boolean) {
        const item = this.store.current;
        this.creator.Open = value;
        this.creator.fields.personOrganization.setValueWithoutEffects(value && item ? this.getNameOrg(item.dto.organization)?.name : "")
        this.creator.fields.personPost.setValueWithoutEffects(value && item ? item.dto.post.nameRus : "")
    }

    get valid(): boolean {
        return !!this.store.current && this.creator.valid;
    }

    async create(): Promise<void> {
        this.creator.validate();
        if (!this.valid) {
            return;
        }
        const item = this.store.current!;
        const model = {
            personId: this.store._store.id,
            postId: item.dto.post.id!,
            statusId: this.creator.fields.personStatus.valueId!,
            recoveryReason: this.creator.model.recoveryReason
        }

        
        item.isTypeDepartment ? await new DepJobsApi(getApiConfiguration()).createPersonDepJob({
            setPersonDepJobDto: {...model, ...{organizationDepId: item.dto.organization?.id!}}
        }) :  await new JobsApi(getApiConfiguration()).createPersonJob({
            setPersonJobDto: {...model, ...{organizationId: item.dto.organization?.id!}}
        }) ;
    };
}
