import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, {FC, ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {CardContent} from "@mui/material";

interface Overview2Props {
    title: React.ReactNode;
    count: string | number;
    description?: string;
    icon?: ReactNode;
    sx?: React.CSSProperties;
    cardSx?: React.CSSProperties;
}

export const Overview4: FC<Overview2Props> = (props) => {
    const {t} = useTranslation();

    return (
        <Card sx={props.sx}>
            <CardContent sx={props.cardSx}>
                <Stack
                    alignItems="start"
                    direction="column"
                    spacing={2}
                >
                    <Typography
                        color="text.secondary"
                        variant="body2"
                        sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '-webkit-fill-available'}}
                    >
                        {props.title}
                    </Typography>

                    <Stack
                        alignItems="start"
                        direction="row"
                        sx={{marginTop: 7}}
                        spacing={3}
                    >
                        {props.icon}
                        <Typography variant="h5"
                                    sx={{marginTop: '2px!important;',marginLeft: "10px!important;"}}>
                            {props.count}
                        </Typography>
                        {
                            props.description &&
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >
                                {props.description}
                            </Typography>
                        }

                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
}
