/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {DcrStatus, DcrStatusFromJSON, DcrStatusToJSON,} from './DcrStatus';
import {RequestRecordType, RequestRecordTypeFromJSON, RequestRecordTypeToJSON,} from './RequestRecordType';

/**
 * 
 * @export
 * @interface DcrGroupPermissionDto
 */
export interface DcrGroupPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof DcrGroupPermissionDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof DcrGroupPermissionDto
     */
    userName?: string | null;
    /**
     * 
     * @type {Array<DcrStatus>}
     * @memberof DcrGroupPermissionDto
     */
    dcrStatuses?: Array<DcrStatus> | null;
    /**
     * 
     * @type {Array<RequestRecordType>}
     * @memberof DcrGroupPermissionDto
     */
    entityTypes?: Array<RequestRecordType> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DcrGroupPermissionDto
     */
    regions?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DcrGroupPermissionDto
     */
    clients?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DcrGroupPermissionDto
     */
    spicalities?: Array<string> | null;
}

export function DcrGroupPermissionDtoFromJSON(json: any): DcrGroupPermissionDto {
    return DcrGroupPermissionDtoFromJSONTyped(json, false);
}

export function DcrGroupPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DcrGroupPermissionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'dcrStatuses': !exists(json, 'dcrStatuses') ? undefined : (json['dcrStatuses'] === null ? null : (json['dcrStatuses'] as Array<any>).map(DcrStatusFromJSON)),
        'entityTypes': !exists(json, 'entityTypes') ? undefined : (json['entityTypes'] === null ? null : (json['entityTypes'] as Array<any>).map(RequestRecordTypeFromJSON)),
        'regions': !exists(json, 'regions') ? undefined : json['regions'],
        'clients': !exists(json, 'clients') ? undefined : json['clients'],
        'spicalities': !exists(json, 'spicalities') ? undefined : json['spicalities'],
    };
}

export function DcrGroupPermissionDtoToJSON(value?: DcrGroupPermissionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'userName': value.userName,
        'dcrStatuses': value.dcrStatuses === undefined ? undefined : (value.dcrStatuses === null ? null : (value.dcrStatuses as Array<any>).map(DcrStatusToJSON)),
        'entityTypes': value.entityTypes === undefined ? undefined : (value.entityTypes === null ? null : (value.entityTypes as Array<any>).map(RequestRecordTypeToJSON)),
        'regions': value.regions,
        'clients': value.clients,
        'spicalities': value.spicalities,
    };
}

