import {observer} from "mobx-react";
import {
    FormRoot,
    Page,
} from "../../../../components";
import {useTranslation} from "react-i18next";
import {Box, Chip, Container, Typography} from "@mui/material";
import {StoreProps} from "../../../../stores";
import React from "react";
import {PermissionItem} from "../../../../stores/management/users/tabs/Permissions/PermissionTableStore";


export const PermissionDetails = observer(({store}: StoreProps<PermissionItem>) => {
    const {t} = useTranslation();

    return (
        <Page>
            <Box sx={{mx: 1}}>
                <FormRoot
                    style={{margin: '0 20px'}}
                >
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        style={{marginBottom: 'auto', maxWidth: 160}}
                    >
                        {`${t("usersPage.permissions.table.permissions")}:`}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Container maxWidth="xl"
                                   style={{display: 'flex', flexWrap: 'wrap', marginTop: 10, padding: 0}}>
                            {(store.permissions ?? []).map((item) => {
                                return <Chip key={item.id} label={item.name} size="medium" style={{marginRight: 15, padding: 0, marginBottom: 15}}/>
                            })}
                        </Container>
                    </Box>
                </FormRoot>
            </Box>
        </Page>
    );
});
