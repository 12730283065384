import {Box, Button} from "@mui/material";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {AddLocalityButton, ResetFiltersButton} from '../../../../components';
import {LocalityFilter} from "../../../../components/select/Locality/LocalityFilter";
import {DefaultMultiSelect} from "../../../../components/shared/components/DefaultMultiSelect";
import {ExportOrganizationStore} from "../../../../stores/management/data/export/tabs/ExportOrganizationStore";
import {DefaultDate} from "../../../../components/shared/DefaultDate";

interface Props {
    exportOrganizationCreateStore: ExportOrganizationStore;
}

export const ExportOrganizationCreate = observer(({ exportOrganizationCreateStore }: Props) => {
    const { t } = useTranslation();

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                backgroundColor: 'background.default',
            }}
        >
            <Box sx={{ mt: 1 }}
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <DefaultMultiSelect
                    autoFocus
                    store={exportOrganizationCreateStore.formPropertyFilter}
                    title={t('common.formProperty')}
                    style={{ width: '33%' }}
                />
                <DefaultMultiSelect store={exportOrganizationCreateStore.statusFilter}
                    title={t('common.status')}
                    style={{ marginLeft: 16, width: '33%' }}
                />
                <DefaultMultiSelect store={exportOrganizationCreateStore.typeFilter}
                    title={t('common.type')}
                    style={{ marginLeft: 16, width: '33%' }}
                />
            </Box>
            <Box
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <DefaultDate store={exportOrganizationCreateStore.createDate}
                             style={{marginTop: 16, width: '33%'}}
                             title={t('dataPage.table.createDate')}/>

                <DefaultDate store={exportOrganizationCreateStore.updateDate}
                             style={{marginTop: 16, marginLeft: 16, width: '33%'}}
                             title={t('dataPage.table.modifiedDate')}/>

                <DefaultMultiSelect store={exportOrganizationCreateStore.exportTypes}
                    title={t('dataPage.exportType')}
                    nameExp={exportOrganizationCreateStore.exportTypes.nameExp}
                    valueExp={null}
                    style={{ marginLeft: 16, width: '33%' }}
                />
            </Box>
            <Box style={{ display: 'flex', height: 37 }} sx={{ my: 2 }}>
                <AddLocalityButton onClick={() => exportOrganizationCreateStore.localitiesFilter.open()} />
                <LocalityFilter store={exportOrganizationCreateStore.localitiesFilter} style={{ flex: 1, marginLeft: 40 }} />
                <ResetFiltersButton onClick={() => exportOrganizationCreateStore.resetFilters()} />
                {/* <AuthorizePermission permissions=""> */}
                <Button variant='contained' color="success" sx={{ml: 2}}
                        onClick={() => exportOrganizationCreateStore.create()}
                        disabled={exportOrganizationCreateStore.loading || exportOrganizationCreateStore.exportTypes.valid}>
                    {t('buttons.create')}
                </Button>
                {/* </AuthorizePermission> */}
            </Box>
        </Box >
    )
});
