import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';

import { Avatar, Box, ButtonBase, IconButton, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../core/hooks/use-settings';
import { UserCircle as UserCircleIcon } from '../../icons/user-circle';
import { useAuthorizationStore } from '../../stores';
import { AccountPopover } from '../dashboard(old)/account-popover';
import { useSections } from './config';
import { HorizontalLayout } from './horizontal-layout';
import { JurisdictionPopover } from './jurisdiction-popover';
import { LanguagePopover } from './language-popover';
import { VerticalLayout } from './vertical-layout';
import {observer} from "mobx-react";

interface LayoutProps {
    children?: ReactNode;
}
type Language = 'ru' | 'en';

const languages: Record<Language, string> = {
    ru: '/static/icons/ru_flag.svg',
    en: '/static/icons/us_flag.svg',
};

export const LanguageButton = () => {
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const { i18n } = useTranslation();
    const [openPopover, setOpenPopover] = useState<boolean>(false);

    const handleOpenPopover = (): void => {
        setOpenPopover(true);
    };

    const handleClosePopover = (): void => {
        setOpenPopover(false);
    };

    return (
        <>
            <IconButton
                onClick={handleOpenPopover}
                ref={anchorRef}
                sx={{ ml: 1 }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        height: 20,
                        width: 20,
                        '& img': {
                            width: '100%',
                        },
                    }}
                >
                    <img alt="" src={languages[i18n.language as Language]} />
                </Box>
            </IconButton>
            <LanguagePopover
                anchorEl={anchorRef.current}
                onClose={handleClosePopover}
                open={openPopover}
            />
        </>
    );
};

export const JurisdictionButton = observer(() => {
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const [openPopover, setOpenPopover] = useState<boolean>(false);
    const authorizationStore = useAuthorizationStore();
    const { t } = useTranslation();
    const handleOpenPopover = (): void => {
        setOpenPopover(true);
    };

    const handleClosePopover = (): void => {
        setOpenPopover(false);
    };

    return (
        <>
            <IconButton
                onClick={handleOpenPopover}
                ref={anchorRef}
                sx={{
                    ml: 1,
                    borderRadius: 1,
                    height: 40,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        '& img': {
                            width: '100%',
                        },
                    }}
                >
                    <Typography variant="subtitle2">
                        {authorizationStore.jurisdictions.value?.jurisdiction.name}
                    </Typography>
                </Box>
            </IconButton>

            <JurisdictionPopover
                anchorEl={anchorRef.current}
                onClose={handleClosePopover}
                open={openPopover}
            />
        </>
    );
});

export const AccountButton = () => {
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const [openPopover, setOpenPopover] = useState<boolean>(false);
    const authorizationStore = useAuthorizationStore();
    const user = {
        avatar: '/static/mock-images/avatars/avatar-anika_visser.png',
        name: authorizationStore.user?.username,
    };

    const handleOpenPopover = (): void => {
        setOpenPopover(true);
    };

    const handleClosePopover = (): void => {
        setOpenPopover(false);
    };

    if (!authorizationStore.isAuthenticated) {
        return <></>;
    }
    return (
        <>
            <Box
                component={ButtonBase}
                onClick={handleOpenPopover}
                ref={anchorRef}
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    ml: 2,
                }}
            >
                <Avatar
                    sx={{
                        height: 40,
                        width: 40,
                    }}
                    src={user.avatar}
                >
                    <UserCircleIcon
                        fontSize="small"
                        style={{ marginLeft: 3 }}
                    />
                </Avatar>
            </Box>
            <AccountPopover
                anchorEl={anchorRef.current}
                onClose={handleClosePopover}
                open={openPopover}
            />
        </>
    );
};

export const Layout: FC<LayoutProps> = (props) => {
    const settings = useSettings();
    const sections = useSections();

    if (settings.layout === 'horizontal') {
        return (
            <HorizontalLayout
                sections={sections}
                navColor={settings.navColor}
                {...props}
            />
        );
    }

    return (
        <VerticalLayout
            sections={sections}
            navColor={settings.navColor}
            {...props}
        />
    );
};

Layout.propTypes = {
    children: PropTypes.node,
};
