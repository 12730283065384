import { Box, Breadcrumbs, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
    AuthorizePermission,
    Page,
    PageTab,
    PageTabs,
} from '../../../components';
import { AllPermissions, SectionTypeDictionary } from '../../../core/const';
import { useManagementStore } from '../../../stores';
import { DictionaryTable } from '../DictionaryTable';
import { TypeOrganizationTree } from './typeOrganization/TypeOrganizationTree';

export const DictionariesOrganizations = observer(() => {
    const { dictionaryOrganizationsStore } = useManagementStore();
    const { t } = useTranslation();
    const renderTabs = (index: number) => {
        switch (index) {
            case 0:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.organization
                                .cliniccategory.read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={
                                dictionaryOrganizationsStore.clinicCategoryStore
                            }
                            sectionType={SectionTypeDictionary.clinicCategory}
                            dictionaryName={'clinicCategory'}
                            dictionaryType={
                                AllPermissions.dictionary.organization
                                    .cliniccategory
                            }
                        />
                    </AuthorizePermission>
                );
            case 1:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.organization.typename.read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryOrganizationsStore.typeNamesStore}
                            sectionType={SectionTypeDictionary.typename}
                            dictionaryName={'typeName'}
                            dictionaryType={
                                AllPermissions.dictionary.organization.typename
                            }
                        />
                    </AuthorizePermission>
                );
            case 2:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.organization.typeorg.read
                        }
                        showWarning
                    >
                        <TypeOrganizationTree
                            store={
                                dictionaryOrganizationsStore.typeOrganizationStore
                            }
                        />
                    </AuthorizePermission>
                );
            case 3:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.organization.phonestatus
                                .read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryOrganizationsStore.phoneStatus}
                            sectionType={SectionTypeDictionary.phonestatus}
                            dictionaryName={'phoneStatus'}
                            dictionaryType={
                                AllPermissions.dictionary.organization
                                    .phonestatus
                            }
                        />
                    </AuthorizePermission>
                );
            case 4:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.organization.phonetype
                                .read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryOrganizationsStore.phoneType}
                            sectionType={SectionTypeDictionary.phonetype}
                            dictionaryName={'phoneType'}
                            dictionaryType={
                                AllPermissions.dictionary.organization.phonetype
                            }
                        />
                    </AuthorizePermission>
                );
            case 5:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.organization.phonecategory
                                .read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryOrganizationsStore.phoneCategory}
                            sectionType={SectionTypeDictionary.phonecategory}
                            dictionaryName={'phoneCategory'}
                            dictionaryType={
                                AllPermissions.dictionary.organization
                                    .phonecategory
                            }
                        />
                    </AuthorizePermission>
                );
            case 6:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.organization.emailstatus
                                .read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryOrganizationsStore.emailStatus}
                            sectionType={SectionTypeDictionary.emailstatus}
                            dictionaryName={'emailStatus'}
                            dictionaryType={
                                AllPermissions.dictionary.organization
                                    .emailstatus
                            }
                        />
                    </AuthorizePermission>
                );
            case 7:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.organization.emailtype
                                .read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryOrganizationsStore.emailType}
                            sectionType={SectionTypeDictionary.emailtype}
                            dictionaryName={'typeEmails'}
                            dictionaryType={
                                AllPermissions.dictionary.organization.emailtype
                            }
                        />
                    </AuthorizePermission>
                );
            case 8:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.organization.websitestatus
                                .read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryOrganizationsStore.websiteStatus}
                            sectionType={SectionTypeDictionary.websitestatus}
                            dictionaryName={'websiteStatus'}
                            dictionaryType={
                                AllPermissions.dictionary.organization
                                    .websitestatus
                            }
                        />
                    </AuthorizePermission>
                );
        }
    };

    return (
        <>
            <>
                <Box>
                    <Breadcrumbs separator="/" sx={{ mt: 1 }}>
                        <Typography color="primary" variant="h6">
                            {t('pageNames.dictionaries')}
                        </Typography>

                        <Typography color="textSecondary" variant="h6">
                            {t('pageNames.organizations')}
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <PageTabs
                    index={dictionaryOrganizationsStore.tabIndex}
                    onChange={(t) => {
                        dictionaryOrganizationsStore.tabIndex = t;
                    }}
                    multipleLines
                >
                    <PageTab
                        label={t('dictionary.tabNames.clinicCategories')}
                        testid="dictionariesOrganizations-clinicCategories-tabs"
                        style={{ minWidth: 200 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.typeNames')}
                        testid="dictionariesOrganizations-typeNames-tabs"
                        style={{ minWidth: 200 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.typeOrgs')}
                        testid="dictionariesOrganizations-typeOrgs-tabs"
                        style={{ minWidth: 200 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.phoneStatus')}
                        testid="dictionariesOrganizations-phoneStatus-tabs"
                        style={{ minWidth: 200 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.phoneType')}
                        testid="dictionariesOrganizations-phoneType-tabs"
                        style={{ minWidth: 200 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.phoneCategories')}
                        testid="dictionariesOrganizations-phoneCategories-tabs"
                        style={{ minWidth: 200 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.emailStatus')}
                        testid="dictionariesOrganizations-emailStatus-tabs"
                        style={{ minWidth: 200 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.typeEmails')}
                        testid="dictionariesOrganizations-typeEmails-tabs"
                        style={{ minWidth: 200 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.websiteStatus')}
                        testid="dictionariesOrganizations-websiteStatus-tabs"
                        style={{ minWidth: 200 }}
                    />
                </PageTabs>
                <Page style={{ flex: 1, width: '100%', height: '100%' }}>
                    {renderTabs(dictionaryOrganizationsStore.tabIndex)}
                </Page>
            </>
        </>
    );
});
