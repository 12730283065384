import type {FC} from 'react';
import {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import {useTheme} from '@mui/material/styles';
import type {Section} from '../config';
import {TenantSwitch} from '../tenant-switch';
import {SideNavSection} from './side-nav-section';
import {usePathname} from "../../../core/hooks/use-pathname";
import {Scrollbar} from "../../../scrollbar";
import {RouterLink} from "../../router-link";
import {Logo} from '../../../icons/logo';
import {NavColor} from "../../../contexts/settings/types";
import {IconButton} from "@mui/material";
import {ChevronLeft as ChevronLeftIcon} from "../../../icons/chevron-left";
import {Menu as MenuIcon} from "../../../icons/menu";
import {TopNavSection} from "../horizontal-layout/top-nav-section";

const SIDE_NAV_WIDTH = 286;
const SMALL_SIDE_NAV_WIDTH = 81;

const useCssVars = (color: NavColor): Record<string, string> => {
    const theme = useTheme();

    return useMemo(
        (): Record<string, string> => {
            switch (color) {
                case 'blend-in':
                    if (theme.palette.mode === 'dark') {
                        return {
                            '--nav-bg': theme.palette.background.default,
                            '--nav-color': theme.palette.neutral[100],
                            '--nav-border-color': theme.palette.neutral[700],
                            '--nav-logo-border': theme.palette.neutral[700],
                            '--nav-section-title-color': theme.palette.neutral[400],
                            '--nav-item-color': theme.palette.neutral[400],
                            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
                            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
                            '--nav-item-active-color': theme.palette.text.primary,
                            '--nav-item-disabled-color': theme.palette.neutral[600],
                            '--nav-item-icon-color': theme.palette.neutral[500],
                            '--nav-item-icon-active-color': theme.palette.primary.main,
                            '--nav-item-icon-disabled-color': theme.palette.neutral[700],
                            '--nav-item-chevron-color': theme.palette.neutral[700],
                            '--nav-scrollbar-color': theme.palette.neutral[400]
                        };
                    } else {
                        return {
                            '--nav-bg': theme.palette.background.default,
                            '--nav-color': theme.palette.text.primary,
                            '--nav-border-color': theme.palette.neutral[100],
                            '--nav-logo-border': theme.palette.neutral[100],
                            '--nav-section-title-color': theme.palette.neutral[400],
                            '--nav-item-color': theme.palette.text.secondary,
                            '--nav-item-hover-bg': theme.palette.action.hover,
                            '--nav-item-active-bg': theme.palette.action.selected,
                            '--nav-item-active-color': theme.palette.text.primary,
                            '--nav-item-disabled-color': theme.palette.neutral[400],
                            '--nav-item-icon-color': theme.palette.neutral[400],
                            '--nav-item-icon-active-color': theme.palette.primary.main,
                            '--nav-item-icon-disabled-color': theme.palette.neutral[400],
                            '--nav-item-chevron-color': theme.palette.neutral[400],
                            '--nav-scrollbar-color': theme.palette.neutral[900]
                        };
                    }

                case 'discrete':
                    if (theme.palette.mode === 'dark') {
                        return {
                            '--nav-bg': theme.palette.neutral[900],
                            '--nav-color': theme.palette.neutral[100],
                            '--nav-border-color': theme.palette.neutral[700],
                            '--nav-logo-border': theme.palette.neutral[700],
                            '--nav-section-title-color': theme.palette.neutral[400],
                            '--nav-item-color': theme.palette.neutral[400],
                            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
                            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
                            '--nav-item-active-color': theme.palette.text.primary,
                            '--nav-item-disabled-color': theme.palette.neutral[600],
                            '--nav-item-icon-color': theme.palette.neutral[500],
                            '--nav-item-icon-active-color': theme.palette.primary.main,
                            '--nav-item-icon-disabled-color': theme.palette.neutral[700],
                            '--nav-item-chevron-color': theme.palette.neutral[700],
                            '--nav-scrollbar-color': theme.palette.neutral[400]
                        };
                    } else {
                        return {
                            '--nav-bg': theme.palette.neutral[50],
                            '--nav-color': theme.palette.text.primary,
                            '--nav-border-color': theme.palette.divider,
                            '--nav-logo-border': theme.palette.neutral[200],
                            '--nav-section-title-color': theme.palette.neutral[500],
                            '--nav-item-color': theme.palette.neutral[500],
                            '--nav-item-hover-bg': theme.palette.action.hover,
                            '--nav-item-active-bg': theme.palette.action.selected,
                            '--nav-item-active-color': theme.palette.text.primary,
                            '--nav-item-disabled-color': theme.palette.neutral[400],
                            '--nav-item-icon-color': theme.palette.neutral[400],
                            '--nav-item-icon-active-color': theme.palette.primary.main,
                            '--nav-item-icon-disabled-color': theme.palette.neutral[400],
                            '--nav-item-chevron-color': theme.palette.neutral[400],
                            '--nav-scrollbar-color': theme.palette.neutral[900]
                        };
                    }

                case 'evident':
                    if (theme.palette.mode === 'dark') {
                        return {
                            '--nav-bg': theme.palette.neutral[800],
                            '--nav-color': theme.palette.common.white,
                            '--nav-border-color': 'transparent',
                            '--nav-logo-border': theme.palette.neutral[700],
                            '--nav-section-title-color': theme.palette.neutral[400],
                            '--nav-item-color': theme.palette.neutral[400],
                            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
                            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
                            '--nav-item-active-color': theme.palette.common.white,
                            '--nav-item-disabled-color': theme.palette.neutral[500],
                            '--nav-item-icon-color': theme.palette.neutral[400],
                            '--nav-item-icon-active-color': theme.palette.primary.main,
                            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
                            '--nav-item-chevron-color': theme.palette.neutral[600],
                            '--nav-scrollbar-color': theme.palette.neutral[400]
                        };
                    } else {
                        return {
                            '--nav-bg': theme.palette.neutral[800],
                            '--nav-color': theme.palette.common.white,
                            '--nav-border-color': 'transparent',
                            '--nav-logo-border': theme.palette.neutral[700],
                            '--nav-section-title-color': theme.palette.neutral[400],
                            '--nav-item-color': theme.palette.neutral[400],
                            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
                            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
                            '--nav-item-active-color': theme.palette.common.white,
                            '--nav-item-disabled-color': theme.palette.neutral[500],
                            '--nav-item-icon-color': theme.palette.neutral[400],
                            '--nav-item-icon-active-color': theme.palette.primary.main,
                            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
                            '--nav-item-chevron-color': theme.palette.neutral[600],
                            '--nav-scrollbar-color': theme.palette.neutral[400]
                        };
                    }

                default:
                    return {};
            }
        },
        [theme, color]
    );
};

interface SideNavProps {
    color?: NavColor;
    sections?: Section[];
    openSideBar: boolean;
    setOpenSideBar: (value: boolean) => void;
}

export const SideNav: FC<SideNavProps> = (props) => {
    const {color = 'evident', sections = [],openSideBar, setOpenSideBar} = props;
    const pathname = usePathname();
    const cssVars = useCssVars(color);

    return (
        <Drawer
            anchor="left"
            open
            PaperProps={{
                sx: {
                    ...cssVars,
                    backgroundColor: 'var(--nav-bg)',
                    borderRightColor: 'var(--nav-border-color)',
                    borderRightStyle: 'solid',
                    borderRightWidth: 1,
                    color: 'var(--nav-color)',
                    width: openSideBar ? SIDE_NAV_WIDTH : SMALL_SIDE_NAV_WIDTH,
                    transition: '0.4s',
                    transitionProperty: 'width'
                }
            }}
            variant="permanent"
        >
            <Scrollbar
                sx={{
                    height: '100%',
                    '& .simplebar-content': {
                        height: '100%'
                    },
                    '& .simplebar-scrollbar:before': {
                        background: 'var(--nav-scrollbar-color)'
                    }
                }}
            >
                <Stack sx={{height: '100%'}}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={2}
                        sx={{p: 3, width: openSideBar ? '285px' : '40px', height: 90}}
                    >
                        {
                            openSideBar ?
                                <>
                                    <Box
                                        component={RouterLink}
                                        href={'/'}
                                        sx={{
                                            borderColor: 'var(--nav-logo-border)',
                                            borderRadius: 1,
                                            borderStyle: 'solid',
                                            borderWidth: 1,
                                            display: 'flex',
                                            height: 40,
                                            p: '4px',
                                            width: 40
                                        }}
                                    >
                                        <Logo/>
                                    </Box>
                                    <TenantSwitch sx={{flexGrow: 1}}/>
                                    <IconButton onClick={() => setOpenSideBar(false)}
                                                data-testid="chevronLeftIcon-icon">
                                        <ChevronLeftIcon/>
                                    </IconButton>
                                </> :
                                <IconButton
                                    data-testid="menuIcon-icon"
                                    onClick={() => setOpenSideBar(true)}
                                    sx={{
                                        display: {
                                            xs: 'inline-flex',
                                        },
                                    }}
                                >
                                    <MenuIcon fontSize="small"/>
                                </IconButton>
                        }
                    </Stack>
                    <Stack
                        component="nav"
                        spacing={2}
                        sx={{
                            flexGrow: 1,
                            px: 2
                        }}
                    >
                        {openSideBar ? sections.map((section, index) => (
                            <SideNavSection
                                items={section.items}
                                key={index}
                                pathname={pathname}
                                subheader={section.subheader}
                            />
                        )) : sections.map((section, index) => (
                            <TopNavSection
                                mode='small'
                                items={section.items}
                                key={index}
                                direction='column'
                                pathname={pathname}
                                subheader={section.subheader}
                            />
                        ))
                        }
                    </Stack>
                </Stack>
            </Scrollbar>
        </Drawer>
    );
};

SideNav.propTypes = {
    color: PropTypes.oneOf<NavColor>(['blend-in', 'discrete', 'evident']),
    sections: PropTypes.array
};
