import {observer} from 'mobx-react';
import {Box, Button} from '@mui/material';
import React from "react";
import {useTranslation} from 'react-i18next';
import {DropzoneArea} from "mui-file-dropzone";
import {DefaultSelect} from '../../../components/shared/components/DefaultSelect';
import {Description} from '@mui/icons-material';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import {DefaultValueStore} from "../../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from "../../../components/shared/DefaultSelectStore";
import {ClientFragment} from "../../../gql/graphql";
import {DefaultFormStore} from "../../../components/shared/DefaultFormStore";

interface Props {
    store: DefaultFormStore<{
        fileObjects: DefaultValueStore<File[]>,
        clients?: DefaultSelectStore<ClientFragment>
    }>;
}

export const ImportCreate = observer(({ store }: Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ p: 2 }}>
      <DropzoneArea
          fileObjects={store.fields.fileObjects.value}
          onChange={(val) => store.fields.fileObjects.value = val}
        getFileAddedMessage={(name) => `${t('dataPage.File')} ${name} ${t('dataPage.addFileText')}`}
        getFileRemovedMessage={(name) => `${t('dataPage.File')} ${name} ${t('dataPage.removeFileText')}`}
        showFileNames={true}
        maxFileSize={52428800}
        dropzoneText={t('dataPage.dropText')}
        Icon={CloudDownloadOutlinedIcon}
        getPreviewIcon={() => <Description sx={{ width: '40px', height: '40px', color: '#14B8A6' }} />}
        previewGridProps={{
          container: {
            marginTop: 4,
            justifyContent: 'center'
          }
        }}
        filesLimit={1}
        acceptedFiles={['.csv']}
      />
        {
            store.fields.clients &&
            <DefaultSelect store={store.fields.clients}
                           title={t('usersPage.dcrGroup.clients')}
                           nameExp={'name'}
                           hasLabel
                           style={{marginTop: 16}}
            />
        }

      <Box style={{ marginTop: 16, display: 'flex', alignSelf: 'flex-end' }}>
        <Button
          type="button"
          variant="outlined"
          size="small"
          color="secondary"
          onClick={async () => await store.submit()}
          disabled={store.processing || (!!store.fields.fileObjects.value && store.fields.fileObjects.value!.length < 1)}
        >
          {t('buttons.send')}
        </Button>
      </Box>
    </Box>
  );
});
