import {makeAutoObservable} from 'mobx';
import {getApiConfiguration, IExpanded, IForm, IProcessing} from '../../../../../../core';
import {PersonPhoneTableStore} from "./PersonPhoneTableStore";
import {DefaultSelectStore} from "../../../../../../components/shared/DefaultSelectStore";
import {
    PersonPhoneCategoriesApi,
    PersonPhoneDto,
    PersonPhonesApi,
    PersonPhoneStatusesApi,
    PersonPhoneTypesApi,
    PhoneCategoryDto,
    PhoneStatusDto,
    PhoneTypeDto
} from '../../../../../../services/management';
import {DefaultValueStore} from "../../../../../../components/shared/DefaultValueStore";
import i18n from "i18next";
import {JurisdictionLocalSetting} from "../../../../../authorization/AuthorizationStore";


export class PersonPhoneItemStore implements IProcessing, IExpanded, IForm {
    readonly _store: PersonPhoneTableStore;
    dto: PersonPhoneDto;
    processing: boolean = false;
    deleteDialogState: boolean = false;
    expand: boolean = false;

    status: DefaultSelectStore<PhoneStatusDto>
    type: DefaultSelectStore<PhoneTypeDto>
    category: DefaultSelectStore<PhoneCategoryDto>
    description: DefaultValueStore<string>;
    phone: DefaultValueStore<string>

    constructor(store: PersonPhoneTableStore, dto: PersonPhoneDto) {
        this._store = store;
        this.dto = dto;
        this.phone = new DefaultValueStore<string>(dto.phone.phoneNumber, (value) =>
            new RegExp(this.jurisdictionLocalSetting?.phoneNumberRegex!)
                .test(value ?? "") ? "" : i18n.t('dcrPage.errors.phoneFormat').replace("{length}", this.jurisdictionLocalSetting?.numberLength!));
        this.phone.required = true;

        this.status = new DefaultSelectStore<PhoneStatusDto>(
            dto.phoneStatus, (x) => new PersonPhoneStatusesApi(getApiConfiguration()).getPersonPhoneStatuses(x))
        this.status.required = true;

        this.type =  new DefaultSelectStore<PhoneTypeDto>(
            dto.phoneType, (x) => new PersonPhoneTypesApi(getApiConfiguration()).getPersonPhoneTypes(x))
        this.status.required = true;

        this.category = new DefaultSelectStore<PhoneCategoryDto>(
            dto.phoneCategory, (x) => new PersonPhoneCategoriesApi(getApiConfiguration()).getPersonPhoneCategories(x))
        this.status.required = true;

        this.description = new DefaultValueStore<string>(dto?.description ?? '', (value) =>
            value?.length! > 180 ? i18n.t('errors.lengthMax').replace("lengthMax", "180") : "")
        makeAutoObservable(this);
    }

    get jurisdictionLocalSetting(): JurisdictionLocalSetting | undefined {
        return this._store._persStore._store.store.store.authorizationStore?.jurisdictionsSettings
    }

    async delete(): Promise<void> {
        this.processing = true;
        const api: PersonPhonesApi = new PersonPhonesApi(getApiConfiguration());
        try {
            const result = await api.removePersonPhones({
                personId: this._store.personId,
                setPersonPhoneDto: [
                    {
                        phoneTypeId: this.dto.phoneType.id,
                        personId: this._store.personId,
                        phoneStatusId: this.dto.phoneStatus.id,
                        phoneNumber: this.dto.phone.phoneNumber,
                        phoneCategoryId: this.dto.phoneCategory.id
                    }
                ]
            });
            this._store.items = this._store.items.filter(t => t.dto.id !== result[0].id);
            await this._store.pull();
        }
        catch (e) {

        }
        this._store.current = null;
        this.processing = false;
    };

    get equals(): boolean {
        return this.status.equals && this.category.equals && this.type.equals && this.description.equals && this.phone.equals
    }

    get valid(): boolean {
        return this.status.valid && this.category.valid && this.type.valid && this.phone.valid && this.description.valid
    }

    validate(){
        this.status.validate();
        this.category.validate();
        this.type.validate();
        this.phone.validate()
        this.description.validate()
    }

    update(dto: PersonPhoneDto){
        this.dto = dto;
        this.status.update(dto?.phoneStatus);
        this.type.update(dto?.phoneType);
        this.category.update(dto?.phoneCategory);
        this.description.update(dto?.description);
        this.phone.update(dto?.phone.phoneNumber);
    }

    reset(): void {
        this.status.reset();
        this.category.reset();
        this.type.reset();
        this.phone.reset();
        this.description.reset()
        this.validate();
    }

    async submit(){
        this.validate()
        if(!this.valid)
            return
        this.processing = true;
        const api: PersonPhonesApi = new PersonPhonesApi(getApiConfiguration());

        try {
            let item = await api.updatePersonPhone({
                id: this.dto.id,
                updatePersonPhoneDto: {
                    personPhoneStatusId: this.status.value?.id!,
                    personPhoneTypeId: this.type.value?.id!,
                    personPhoneCategoryId: this.category.value?.id!,
                    personPhone: this.phone.value ?? this.dto.phone.phoneNumber,
                    description: this.description.value
                }
            })
            this._store.updateState();
        } finally {
            this.processing = false;
        }
    }
}
