import {DictionaryOrganizationsStore} from "../DictionaryOrganizationsStore";
import {getApiConfiguration} from '../../../../../core';
import {OrganizationPhoneCategoriesApi} from "../../../../../services/management";
import {DictionaryCreateEntity, DictionaryEntity, DictionaryTableStore} from "../../DictionaryTableStore";

export class PhoneCategoryTableStore extends DictionaryTableStore {
    constructor(store: DictionaryOrganizationsStore) {
        super(store, 'phone-category-store');
    }

    async Create(model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new OrganizationPhoneCategoriesApi(getApiConfiguration()).createOrganizationPhoneCategory({setPhoneCategoryDto: model});
    }

    async Delete(id: string): Promise<DictionaryEntity> {
        return await new OrganizationPhoneCategoriesApi(getApiConfiguration()).deleteOrganizationPhoneCategory({id: id});
    }

    async GetCount(search: string): Promise<{ count: number }> {
        return await new OrganizationPhoneCategoriesApi(getApiConfiguration()).getOrganizationPhoneCategoriesCount({search: search});
    }

    async GetList(filters: any): Promise<DictionaryEntity[]> {
        return await new OrganizationPhoneCategoriesApi(getApiConfiguration()).getOrganizationPhoneCategories(filters);
    }

    async Update(id: string, model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new OrganizationPhoneCategoriesApi(getApiConfiguration()).updateOrganizationPhoneCategory({id: id, setPhoneCategoryDto: model});
    }
}
