import {observer} from "mobx-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {OrganizationStore} from "../../../../stores/management/dcr/requests/specifications/entity/OrganizationStore";
import {FormRoot} from "../../../../components";
import {Box, List} from "@mui/material";
import {CopyToClipboard} from "../../../../components/CopyToClipboard";
import {toDateTimeString} from "../../../../core";
import {emptyFieldHandler, getOrgName, getStatusNameHandler} from "../../../../stores";
import {DcrListItem} from "../../DcrListItem";
import {NavLink} from "../../../../components/NavLink";

interface Props {
    store: OrganizationStore;
}

export const Organization = observer(({store}: Props) => {
    const {t} = useTranslation();
    return (
        <FormRoot>
            {
                !store?.loading ?
                    ((store?.data?.id) ?
                            <List>
                                <DcrListItem name={'Data360 Id'}>
                                    <NavLink to={`/organizations/${store.data.id}`} target="_blank">
                                        {store.data.id}
                                    </NavLink>
                                    <CopyToClipboard copyText={store.data.id}/>
                                </DcrListItem>
                                <DcrListItem name={t("dcrPage.fields.date")}
                                             value={toDateTimeString(store.data.modifiedAt!)}
                                />
                                <DcrListItem name={t("dcrPage.forms.organization.name")}
                                             value={getOrgName(store.data)}
                                />
                                <Box style={{
                                    display: "flex",
                                }}>
                                    <DcrListItem name={t("dcrPage.forms.organization.typeOrg")}
                                                 value={emptyFieldHandler(store.data.typeOrg?.nameRus)}
                                    />
                                    <DcrListItem name={t("dcrPage.forms.organization.status")}
                                                 value={getStatusNameHandler(store.data.status)}
                                    />
                                    <DcrListItem name={t("dcrPage.forms.organization.formProperty")}
                                                 value={emptyFieldHandler(store.data.formProperty?.nameRus)}
                                    />
                                </Box>
                                <DcrListItem name={t("dcrPage.forms.organization.phone")}>
                                    <div>
                                        {store.numbers.length > 0 ? store.numbers.map((x: string, index) =>
                                            <div key={index}>{x}</div>) : t('dcrPage.empty')
                                        }
                                    </div>
                                </DcrListItem>

                                <DcrListItem name={t("dcrPage.forms.organization.address")}
                                             value={store.address}
                                />

                                <DcrListItem name={t("dcrPage.fields.parent")}>
                                    {store.hasParent ?
                                        <NavLink to={`/organizations/${store.data.parentId}`} target="_blank">
                                        {getOrgName(store.data.parent)}
                                    </NavLink> : <div>{t('dcrPage.empty')}</div> }
                                </DcrListItem>
                            </List> :
                            store._store.errorApi && <Box sx={{
                                pt: 2,
                                color: 'red'
                            }}>{store.errorMessage}</Box>
                    )
                    : <Box sx={{pt: 2}}>{t("common.download")}...</Box>
            }
        </FormRoot>
    );
});
