import { TextField } from '@mui/material';
import React from 'react';
import { SxProps } from '@mui/system';

export interface TextInputProps {
  label?: string;
  value?: string | null;
  error?: boolean;
  autoFocus?: boolean;
  errorText?: string;
  outlined?: boolean;
  disabled?: boolean;
  type?: 'text' | 'password'
  onChange: (value: string) => void;
  style?: React.CSSProperties;
  multiline?: boolean;
  rows?: number;
  maxRows?: number;
  sx?: SxProps;
}

export const TextInput = ({ value, error, errorText, label, outlined, disabled, type, onChange, style, multiline, autoFocus, rows, maxRows, sx}: TextInputProps) => {

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange(e.currentTarget.value);
  };

  return (
    <TextField
      style={style}
      sx={sx}
      type={type ? type : 'text'}
      label={label}
      variant={outlined ? 'outlined' : 'standard'}
      disabled={disabled}
      size="small"
      fullWidth
      error={error}
      autoFocus={autoFocus}
      helperText={errorText}
      value={value}
      rows={rows}
      maxRows={maxRows}
      multiline={multiline}
      onChange={e => handleChange(e)}
      autoComplete='off'
    />
  );
};

