import {observer} from "mobx-react";
import {EmptyRow, ExpandRow, Progress, Search,} from '../../../../components';
import {PermissionDetails} from "./PermissionDetails";
import {
    PermissionItem,
    PermissionTableStore
} from "../../../../stores/management/users/tabs/Permissions/PermissionTableStore";
import {useTranslation} from "react-i18next";
import React, {Fragment} from "react";
import {Box, Card, IconButton, Table, Typography} from "@mui/material";
import {StoreProps} from "../../../../stores";
import {TreeView} from '@mui/x-tree-view/TreeView';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
import {ChevronDown as ChevronDownIcon} from "../../../../icons/chevron-down";
import {ChevronRight as ChevronRightIcon} from "../../../../icons/chevron-right";

const StyledTreeItem = observer((props: { store: PermissionItem }) => {

    return (
        <TreeItem
            key={props.store.key}
            nodeId={props.store.key}
            onClick={() => {
                props.store.expand = !props.store.expand
            }}

            icon={props.store.items.length > 0 || props.store.permissions.length > 0 ?
                <IconButton
                    aria-label="expand row"
                    size="large"
                    sx={{ml: 2, pl: 2}}
                >
                    {props.store.expand ? <ChevronDownIcon fontSize="small"/> :
                        <ChevronRightIcon fontSize="small"/>}
                </IconButton> : <></>}

            label={
                <Box sx={{ml: '15px'}}>
                    <Typography variant="subtitle2" sx={{height: 56, display: 'flex', alignItems: 'center'}}>
                        {props.store.name}
                    </Typography>

                    {props.store.expand && props.store.permissions.length > 0 &&
                        <ExpandRow item={props.store}>
                            <PermissionDetails store={props.store}/>
                        </ExpandRow>
                    }
                </Box>
            }
        >
            <TreeItem nodeId={props.store.key + "load"}/>
            {Array.isArray(props.store.items)
                ? props.store.items.map(node =>
                    <StyledTreeItem store={node}/>)
                : null}
        </TreeItem>
    )
});


export const PermissionTableTab = observer(({store}: StoreProps<PermissionTableStore>) => {
    const {t} = useTranslation();

    return (
        <Box>
            <Box sx={{
                flexGrow: 1,
            }}
            >
                <Box sx={{mb: 2}}>
                    <Box
                        sx={{
                            mt: 2,
                            mb: 1
                        }}
                        style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                    >
                        <Search
                            autoFocus
                            value={store.search.value}
                            onChange={t => store.search.value = t}
                            style={{width: '87%', marginRight: 8}}
                            testid="claims"
                        />
                    </Box>
                    <Box
                        sx={{
                            mt: 1,
                            mb: 2
                        }}
                        style={{display: 'flex', justifyContent: 'space-between'}}
                    >

                    </Box>
                    <Card sx={{mb: 8, overflow: 'unset'}}>

                        <TreeView
                            aria-label="customized"
                            // expanded={store.selectedLocalityIds}
                            sx={{flexGrow: 1, maxWidth: '100%', width: '100%'}}
                        >
                            {
                                store.items.length > 0
                                    ? store.items?.map(x =>
                                        <StyledTreeItem store={x}/>
                                    )
                                    : <Table><EmptyRow/></Table>
                            }
                        </TreeView>

                        {(!store.value.items || store.loading) &&
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    py: 3,
                                }}
                            >
                                <Progress store={store}/>
                            </Box>
                        }


                    </Card>
                </Box>
            </Box>
        </Box>
    );
});
