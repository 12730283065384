/* tslint:disable */
/* eslint-disable */
/**
 * Data360.DCR.Analytics
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DcrData360ReportDto,
    DcrData360ReportDtoFromJSON,
    DcrData360ReportDtoToJSON,
    DcrStatus,
    DcrStatusFromJSON,
    DcrStatusToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    ReportStateDtoPagedResponse,
    ReportStateDtoPagedResponseFromJSON,
    ReportStateDtoPagedResponseToJSON,
    RequestRecordType,
    RequestRecordTypeFromJSON,
    RequestRecordTypeToJSON,
    RequestStatus,
    RequestStatusFromJSON,
    RequestStatusToJSON,
} from '../models';

export interface ApiDcrAnalyticsDcrData360ReportReportStateGetRequest {
    page?: number;
    take?: number;
    orderField?: string;
    order?: ListSortDirection;
}

export interface DownloadReportRequest {
    filter?: string;
}

export interface FormReportRequest {
    startDate?: Date;
    endDate?: Date;
    status?: Array<RequestStatus>;
    entity?: Array<RequestRecordType>;
    type?: Array<DcrStatus>;
    clients?: Array<string>;
    responsibleIds?: Array<string>;
}

/**
 * 
 */
export class DcrData360ReportApi extends runtime.BaseAPI {

    /**
     */
    async apiDcrAnalyticsDcrData360ReportReportStateGetRaw(requestParameters: ApiDcrAnalyticsDcrData360ReportReportStateGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ReportStateDtoPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.take !== undefined) {
            queryParameters['Take'] = requestParameters.take;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['OrderField'] = requestParameters.orderField;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['Order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/analytics/dcrData360Report/reportState`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportStateDtoPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDcrAnalyticsDcrData360ReportReportStateGet(requestParameters: ApiDcrAnalyticsDcrData360ReportReportStateGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ReportStateDtoPagedResponse> {
        const response = await this.apiDcrAnalyticsDcrData360ReportReportStateGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async downloadReportRaw(requestParameters: DownloadReportRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/analytics/dcrData360Report/downloadReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response) as any;
    }

    /**
     */
    async downloadReport(requestParameters: DownloadReportRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<any> {
        const response = await this.downloadReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formReportRaw(requestParameters: FormReportRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<DcrData360ReportDto>>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.status) {
            queryParameters['Status'] = requestParameters.status;
        }

        if (requestParameters.entity) {
            queryParameters['Entity'] = requestParameters.entity;
        }

        if (requestParameters.type) {
            queryParameters['Type'] = requestParameters.type;
        }

        if (requestParameters.clients) {
            queryParameters['Clients'] = requestParameters.clients;
        }

        if (requestParameters.responsibleIds) {
            queryParameters['ResponsibleIds'] = requestParameters.responsibleIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/analytics/dcrData360Report/formReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DcrData360ReportDtoFromJSON));
    }

    /**
     */
    async formReport(requestParameters: FormReportRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<DcrData360ReportDto>> {
        const response = await this.formReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
