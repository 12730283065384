import {getApiConfiguration, getObject, setObject} from "../../../../core";
import {compare} from "../../../../core/compareArray";
import {ExportImportType} from "../../../../services/import";
import {PagedItems, TableLocalFilters, TableStore} from "../../../utils";
import {ExportItemStore} from "./ExportItemStore";
import {ExportTabsStore} from "./ExportTabsStore";
import {ExportManagementApi, GetFilesRequest} from "../../../../services/export";
import {ExportPersonStore} from "./tabs/ExportPersonStore";
import {makeObservable, observable} from "mobx";
import {ExportOrganizationStore} from "./tabs/ExportOrganizationStore";
import {ExportDictionaryStore} from "./tabs/ExportDictionaryStore";

export type ExportCreatorStore = ExportPersonStore | ExportOrganizationStore | ExportDictionaryStore;
export type ExportType = 'Persons' | 'Organizations' | 'Dictionaries'

const exportTypeConvert = (store: ExportTableStore, type: 'Persons' | 'Organizations' | 'Dictionaries'): {
    type: ExportImportType,
    creator: ExportPersonStore | ExportOrganizationStore | ExportDictionaryStore
} => {
    switch (type) {
        case "Persons": {
            return {
                type: ExportImportType.NUMBER_1,
                creator: new ExportPersonStore(store)
            }
        }
        case "Organizations": {
            return {
                type: ExportImportType.NUMBER_2,
                creator: new ExportOrganizationStore(store)
            }
        }
        case "Dictionaries": {
            return {
                type: ExportImportType.NUMBER_3,
                creator: new ExportDictionaryStore(store)
            }
        }
    }
}

export class ExportTableStore extends TableStore<ExportItemStore> {
    readonly store: ExportTabsStore;
    creator: ExportCreatorStore;
    current: ExportItemStore | undefined | null;
    exportType: ExportImportType;
    deleteDialogState: boolean = false;
    typeKey: ExportType;

    constructor(store: ExportTabsStore, typeKey: ExportType) {
        super();
        this.store = store;
        this.typeKey = typeKey;
        const {creator, type} = exportTypeConvert(this, typeKey);
        this.creator = creator;
        this.exportType = type;
        const filters = getObject<TableLocalFilters<ExportItemStore>>(`Export${this.typeKey}Table-store`);
        if (filters) {
            this._page = filters.page ?? 0;
            this._size = filters.size;
        }
        makeObservable(this, {
            current: observable
        })
    }

    async request(): Promise<PagedItems<ExportItemStore>> {
        const filters = {
            exportType: this.exportType,
        };

        const newList: ExportItemStore[] = [];

        const result = await new ExportManagementApi(getApiConfiguration() as any).getFiles(filters as GetFilesRequest);

        for (const item of result) {
            const existsItem = this.items.find(t => t.id === item.id);
            if (existsItem) {
                existsItem.update(item);
                newList.push(existsItem);
                continue;
            }
            newList.push(new ExportItemStore(this.typeKey, this, item))
        }

        return new PagedItems<ExportItemStore>([...newList.sort((x, y) => compare(y, x, x => x.modifiedAt))], newList.length)
    };


    async delete(): Promise<void> {
        await new ExportManagementApi(getApiConfiguration() as any).deleteFile({fileId: this.current?.id});
    };

    onFiltersSave() {
        setObject<TableLocalFilters<ExportItemStore>>(`Export${this.typeKey}Table-store`, {
            search: this.search.value ?? '',
            order: this.order,
            page: this.page,
            size: this.size,
        });
    };
}
