/* tslint:disable */
/* eslint-disable */
/**
 * Data360.DCR.Analytics
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequestsReportDto,
    RequestsReportDtoFromJSON,
    RequestsReportDtoFromJSONTyped,
    RequestsReportDtoToJSON,
} from './RequestsReportDto';

/**
 * 
 * @export
 * @interface RequestsReportDtoPagedResponse
 */
export interface RequestsReportDtoPagedResponse {
    /**
     * 
     * @type {number}
     * @memberof RequestsReportDtoPagedResponse
     */
    total?: number;
    /**
     * 
     * @type {Array<RequestsReportDto>}
     * @memberof RequestsReportDtoPagedResponse
     */
    items?: Array<RequestsReportDto> | null;
}

export function RequestsReportDtoPagedResponseFromJSON(json: any): RequestsReportDtoPagedResponse {
    return RequestsReportDtoPagedResponseFromJSONTyped(json, false);
}

export function RequestsReportDtoPagedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestsReportDtoPagedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(RequestsReportDtoFromJSON)),
    };
}

export function RequestsReportDtoPagedResponseToJSON(value?: RequestsReportDtoPagedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(RequestsReportDtoToJSON)),
    };
}

