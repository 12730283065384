import {observer} from "mobx-react";
import {Page, PageTab, PageTabs} from "../../../../components";
import {useTranslation} from "react-i18next";
import {ParametersTab} from "./tabs/ParametersTab";
import {PeoplesTableTab} from "./tabs/people/PeoplesTableTab";
import {SettingsTab} from "./tabs/SettingsTab";
import {GroupItemStore} from "../../../../stores/management/users/tabs/Groups/GroupItemStore";
import {DcrGroupHome} from '../../../../icons/dcrGroupHome'
import {Settings} from "../../../../icons/settings";
import {Users} from "../../../../icons/users";
import {StoreProps, StyleProps} from "../../../../stores";
export const GroupDetails = observer(({store, style}: StoreProps<GroupItemStore> & StyleProps) => {
    const {t} = useTranslation();

    const renderTabs = (index: number) => {
        switch (index) {
            case 0:
                return <ParametersTab store={store.ParametersTabStore}/>;
            case 1:
                return <SettingsTab store={store.SettingsTabStore}/>;
            case 2:
                return <PeoplesTableTab store={store.PeopleTableStore}/>;
            default:
                return null;
        }
    };

    return (
        <Page
            style={style}
        >
            <PageTabs
                index={store.tabIndex}
                onChange={(t) => store.tabIndex = t}>
                <PageTab icon={<DcrGroupHome/>} title={t('usersPage.tabs.params')} testid="details-params-tabs" style={{width: '30%'}}/>
                <PageTab icon={<Settings/>} title={t('usersPage.tabs.settings')} testid="details-settings-tabs" style={{width: '30%'}}/>
                <PageTab icon={<Users/>} title={t('usersPage.tabs.settingsQuery')} testid="details-users-tabs" style={{width: '30%'}}/>
            </PageTabs>
            <div style={{marginTop: 4, marginBottom: 4}}>
                {renderTabs(store.tabIndex)}
            </div>
        </Page>
    );
});
