import {Box} from "@mui/material";
import {observer} from "mobx-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {AuthorizePermission, ParamsButtonGroup} from "../../../../../components";
import {DefaultSelect} from "../../../../../components/shared/components/DefaultSelect";
import {DefaultValue} from "../../../../../components/shared/components/DefaultValue";
import {AllPermissions} from "../../../../../core/const";
import {
    OrganizationPhoneItemStore
} from "../../../../../stores/management/organizations/details/others/phones/OrganizationPhoneItemStore";
import {DefaultPhoneValue} from "../../../../../components/shared/components/DefaultPhoneValue";

interface Props {
    store: OrganizationPhoneItemStore;
    style?: React.CSSProperties;
}

export const OrganizationPhoneDetails = observer(({store, style}: Props) => {
    const {t} = useTranslation();

    return (
        <Box style={{...style}} sx={{px: 2, pt: 2}}>
            <Box>
                <DefaultPhoneValue
                    style={{width: '49%'}}
                    store={store.phone}
                    disabled
                    title={t('common.phone')}
                />
                <DefaultValue
                    testid="organizationPhoneDetails-additionalNumber"
                    style={{width: '50%', marginLeft: '8px'}}
                    store={store.additionalNumber}
                    title={t('common.additionalNumber')}
                    disabled={store.processing || store._store.isDouble}
                />
            </Box>
            <Box sx={{display: "flex", justifyContent: 'space-between', mb: 1}}>
                <DefaultSelect store={store.status}
                               title={t('common.status')}
                               style={{width: '33%'}}
                               hasLabel
                               disabled={store.processing || store._store.isDouble}
                />
                <DefaultSelect store={store.type}
                               title={t('common.type')}
                               style={{width: '33%'}}
                               hasLabel
                               disabled={store.processing || store._store.isDouble}
                />
                <DefaultSelect store={store.category}
                               title={t('common.category')}
                               style={{width: '33%'}}
                               hasLabel
                               disabled={store.processing || store._store.isDouble}
                />
            </Box>
            <Box>
                <DefaultValue
                    testid="organizationPhoneDetails-note"
                    style={{width: '100%'}}
                    store={store.description}
                    title={t('common.note')}
                    disabled={store.processing || store._store.isDouble}
                    multiline
                    InputProps={{
                        sx: {
                            width: '100%',
                            "&.MuiInputBase-root": {
                                height: '100%'
                            }
                        }
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 2
                }}
            >
                <AuthorizePermission permissions={AllPermissions.organization.phones.update}>
                    <ParamsButtonGroup
                        testid="OrgPhoneDetails"
                        onCancel={() => store.reset()}
                        onSave={async () => await store.submit()}
                        isCancelBtnDisabled={store.processing || store.equals}
                        isSaveBtnDisabled={store.processing || store.equals || !store.valid || store._store.isDouble}
                    />
                </AuthorizePermission>
            </Box>
        </Box>
    );
});
