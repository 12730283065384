/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {NameDto, NameDtoFromJSON, NameDtoToJSON,} from './NameDto';
import {PersonDto, PersonDtoFromJSON, PersonDtoToJSON,} from './PersonDto';
import {WebsiteDto, WebsiteDtoFromJSON, WebsiteDtoToJSON,} from './WebsiteDto';

/**
 * 
 * @export
 * @interface PersonWebsiteDto
 */
export interface PersonWebsiteDto {
    /**
     * 
     * @type {string}
     * @memberof PersonWebsiteDto
     */
    id: string;
    /**
     * 
     * @type {WebsiteDto}
     * @memberof PersonWebsiteDto
     */
    website: WebsiteDto;
    /**
     * 
     * @type {PersonDto}
     * @memberof PersonWebsiteDto
     */
    person: PersonDto;
    /**
     * 
     * @type {NameDto}
     * @memberof PersonWebsiteDto
     */
    websiteStatus: NameDto;
    /**
     * 
     * @type {Date}
     * @memberof PersonWebsiteDto
     */
    createdAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof PersonWebsiteDto
     */
    modifiedAt?: Date | null;
    /**
     *
     * @type {string}
     * @memberof PersonWebsiteDto
     */
    description?: string | null;
}

export function PersonWebsiteDtoFromJSON(json: any): PersonWebsiteDto {
    return PersonWebsiteDtoFromJSONTyped(json, false);
}

export function PersonWebsiteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonWebsiteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'website': WebsiteDtoFromJSON(json['website']),
        'person': PersonDtoFromJSON(json['person']),
        'websiteStatus': NameDtoFromJSON(json['websiteStatus']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (json['modifiedAt'] === null ? null : new Date(json['modifiedAt'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function PersonWebsiteDtoToJSON(value?: PersonWebsiteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'website': WebsiteDtoToJSON(value.website),
        'person': PersonDtoToJSON(value.person),
        'websiteStatus': NameDtoToJSON(value.websiteStatus),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt === null ? null : value.createdAt.toISOString()),
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt === null ? null : value.modifiedAt.toISOString()),
        'description': value.description,
    };
}

