import {ListSortDirection} from '../services/management';

export enum SectionType {
    Persons = 1,
    Organizations,
    Users,
    Clients,
    Application,
    Addresses,
    Phones,
    Mappings,
    ImportPersons,
    ImportOrganizations,
    ExportPersons,
    ExportOrganizations,
    ExportDictionaries,
}
export enum TCurrentSectionDictionary {
    typename = 'typename',
    typeorg = 'typeorg',
    clinicCategory = 'clinicCategory',
    phonestatus = 'phonestatus',
    phonetype = 'phonetype',
    phonecategory = 'phonecategory',
    emailstatus = 'emailstatus',
    emailtype = 'emailtype',
    websitestatus = 'websitestatus',
}

export interface IDictionaryOperations {
    create: string;
    read: string;
    update: string;
    delete: string;
    inactiveRead?: string;
    inactiveUpdate?: string;
}

export enum Operation {
    Create = 1,
    Read,
    Update,
    Delete,
    Set,
    Remove,
    Export,
}
export enum OperationNames {
    c = 'Create',
    r = 'Read',
    u = 'Update',
    d = 'Delete',
    s = 'Set',
    rm = 'Remove',
    e = 'Export',
}

export enum SectionNames {
    p = 'Persons',
    o = 'Organizations',
    c = 'Clients',
    a = 'Application',
    adr = 'Addresses',
    ph = 'Phones',
    u = 'Users',
    m = 'Mappings',
    ip = 'ImportPersons',
    io = 'ImportOrganizations',
    ep = 'ExportPersons',
    eo = 'ExportOrganizations',
    ed = 'ExportDictionaries',
}

export interface IFilters {
    page: number;
    size: number;
}

export enum Order {
    Asc = 'Ascending',
    Desc = 'Descending',
}

export interface ITableFilters extends IFilters {
    search?: string;
    order?: Order;
    orderBy?: string;
}

export interface ITableResult<T extends {}> {
    items: Array<T>;
    count: number;
}

export interface IIdObject {
    id: string;
}

export interface IOCMObject extends IIdObject {
    createdAt: Date;
    createdBy: string;
    modifiedAt: Date;
    modifiedBy: string | undefined | null;
}

export interface IOrderStore<T> {
    setOrder(
        orderBy: keyof T | string,
        order: ListSortDirection
    ): Promise<void>;
    order: ListSortDirection;
    orderBy?: keyof T;
}

export interface IExpanded {
    expand: boolean;
}

export interface IHasTabs {
    tabIndex: number;
}

export interface IPage {
    page: number;
    size: number;
    count: number;
    setPaging(page: number, size: number): Promise<void>;
}

export interface ILoad {
    loading: boolean;
    pull(): Promise<void> | void;
}

export interface ISelected {
    selected: boolean;
}

export interface IUserPicker {
    userPicker: boolean;
    processingId?: string | null;
    isUserSelected: (userId: string) => boolean;
    selectUser: (userId: string) => Promise<void>;
}

export interface IDictionaryItemName {
    deleteDialogState: boolean;
    processing: boolean;
    equals: boolean;
    valid: boolean;
    save: () => Promise<void>;
    delete: () => Promise<void>;
    restore: () => void;
    nameRus: string;
    nameRusError: string;
    nameEng: string;
    detailsTabIndex?: number;
}

export interface IForm {
    valid: boolean;
    reset(): void;
    validate(): void;
    submit?(): Promise<void> | void;
}

export interface IModal {
    Open: boolean;
}

export interface IProcessing {
    processing: boolean;
}

export interface IFormValue<T> {
    value: T | null;
    update(dto: T | null): void;
    reset(): void | null;
    templateOptions?: any;
    type?: string;
    getReadValue?: (value: T | null) => string;
    onChanged?: ((value: T | null) => void)[];
    equals?: boolean;
}

export interface IFormValueWithError<T> extends IFormValue<T> {
    required?: boolean;
    error: string;
    valid: boolean;
    validate(): void;
}

export interface IDictionarySimpleName {
    deleteDialogState: boolean;
    processing: boolean;
    equals: boolean;
    valid: boolean;
    save: () => Promise<void>;
    delete: () => Promise<void>;
    restore: () => void;
    name: string;
    nameError: string;
    detailsTabIndex: number;
    operation?: string;
    section?: string;
}

export interface HasId {
    id: string;
}

export interface IdNameEntity<T> {
    id: T;
    name: string;
}
