import {IFormValueWithError} from "../../../core";
import {LocalityDto} from "../../../services/management";
import {ILocalityFilterState, LocalitySelectStore} from "./LocalitySelectStore";
import {makeAutoObservable} from "mobx";
import i18n from "i18next";
import {JurisdictionData} from "../../../stores/authorization/AuthorizationStore";

export class LocalityValueStore implements IFormValueWithError<LocalityDto> {
    locality: LocalitySelectStore;
    onValueChange?: (dto: LocalityDto | null) => void;
    state?: ILocalityFilterState;
    dto?: LocalityDto;

    constructor(dto?: LocalityDto, onValueChange?: (dto: LocalityDto | null) => void) {
        this.onValueChange = onValueChange;
        this.state = this.initState(dto);
        this.dto = dto;
        this.locality = new LocalitySelectStore(null, this.state, (e) => {
            this.validate()
            if (this.onValueChange)
                this.onValueChange(e)
        })
        makeAutoObservable(this);
    }

    get currentLocality(): LocalitySelectStore | null {
        let cur = this.locality;
        while (cur?.locality?.id) {
            cur = cur?.locality
        }
        return cur;
    }

    get equals(): boolean {
        return (this.dto?.id == this.currentLocality?.id);
    }

    get fullName(): string {
        let name = "";
        let cur = this.locality;
        name = cur.name;
        while (cur.locality?.id) {
            cur = cur?.locality
            name = name + ", " + cur.name
        }
        return name;
    }

    get value() {
        return this.currentLocality?._value ? this.currentLocality._value : null;
    }

    get valid(): boolean {
        return !!this?.currentLocality?.isEndpoint
    }

    reset(): void {
        this.locality = new LocalitySelectStore(null, this.initState(this.dto), (e) => {
            if (this.onValueChange)
                this.onValueChange(e)
        })
        this.error = "";
    }

    validate(): void {
        this.error = this?.currentLocality?.isEndpoint ? "" : i18n.t('errors.wrongValue')
    }

    update(dto: LocalityDto | null): void {
        this.locality = new LocalitySelectStore(null, this.initState(dto), (e) => {
            if (this.onValueChange)
                this.onValueChange(e)
        })
    }

    initState(dto: LocalityDto | undefined | null) : ILocalityFilterState | undefined{
        let localityFilterState: ILocalityFilterState | undefined = dto ?? undefined;

        if (dto) {
            while (true) {
                let parent: ILocalityFilterState | undefined =
                    localityFilterState?.parent ? {
                        ...localityFilterState?.parent,
                        locality: localityFilterState
                    } : undefined;
                if (parent && parent.id != localityFilterState?.id)
                    localityFilterState = parent
                else break;
            }
        }

        return localityFilterState;
    }


    get defCityId(): string | null {
        let hasCity = false;
        let hasPopulatedLocalities = false;
        let value = '';
        let local: LocalitySelectStore | null = this.locality
        while (!!local) {
            if (!!local.value?.typeLocality) {
                hasCity = local.value?.typeLocality?.id == JurisdictionData.typeLocalityIds.cityId;
                hasPopulatedLocalities = local.value?.typeLocality?.categoryLocality?.id == JurisdictionData.categoryLocality.populatedId
                if (hasCity || hasPopulatedLocalities) {
                    value = local.value.id;
                }
            }
            local = local.locality
        }

        return value;
    }

    error: string = ""
}
