/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    ListSortDirection,
    SetTypeLocalityDto,
    SetTypeLocalityDtoToJSON,
    TypeLocalityDto,
    TypeLocalityDtoFromJSON,
} from '../models';

export interface CreateTypeLocalityRequest {
    setTypeLocalityDto: SetTypeLocalityDto;
}

export interface DeleteTypeLocalityRequest {
    id: string;
}

export interface GetTypeLocalitiesRequest {
    search?: string;
    orderBy?: string;
    searchByExternalId?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetTypeLocalitiesCountRequest {
    search?: string;
    searchByExternalId?: string;
}

export interface GetTypeLocalitiesCountFallbackRequest {
    search?: string;
    searchByExternalId?: string;
}

export interface GetTypeLocalitiesFallbackRequest {
    search?: string;
    orderBy?: string;
    searchByExternalId?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetTypeLocalityByIdRequest {
    id: string;
}

export interface GetTypeLocalityByNameRequest {
    name: string;
}

export interface UpdateTypeLocalityRequest {
    id: string;
    setTypeLocalityDto: SetTypeLocalityDto;
}

/**
 * 
 */
export class TypeLocalitiesApi extends runtime.BaseAPI {

    /**
     */
    async createTypeLocalityRaw(requestParameters: CreateTypeLocalityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TypeLocalityDto>> {
        if (requestParameters.setTypeLocalityDto === null || requestParameters.setTypeLocalityDto === undefined) {
            throw new runtime.RequiredError('setTypeLocalityDto','Required parameter requestParameters.setTypeLocalityDto was null or undefined when calling createTypeLocality.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/addresses/locality/types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetTypeLocalityDtoToJSON(requestParameters.setTypeLocalityDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeLocalityDtoFromJSON(jsonValue));
    }

    /**
     */
    async createTypeLocality(requestParameters: CreateTypeLocalityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TypeLocalityDto> {
        const response = await this.createTypeLocalityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTypeLocalityRaw(requestParameters: DeleteTypeLocalityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TypeLocalityDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTypeLocality.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/locality/types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeLocalityDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteTypeLocality(requestParameters: DeleteTypeLocalityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TypeLocalityDto> {
        const response = await this.deleteTypeLocalityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTypeLocalitiesRaw(requestParameters: GetTypeLocalitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<TypeLocalityDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/locality/types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TypeLocalityDtoFromJSON));
    }

    /**
     */
    async getTypeLocalities(requestParameters: GetTypeLocalitiesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<TypeLocalityDto>> {
        const response = await this.getTypeLocalitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTypeLocalitiesCountRaw(requestParameters: GetTypeLocalitiesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/locality/types/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getTypeLocalitiesCount(requestParameters: GetTypeLocalitiesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getTypeLocalitiesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTypeLocalitiesCountFallbackRaw(requestParameters: GetTypeLocalitiesCountFallbackRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/locality/types/count/fallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getTypeLocalitiesCountFallback(requestParameters: GetTypeLocalitiesCountFallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getTypeLocalitiesCountFallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTypeLocalitiesFallbackRaw(requestParameters: GetTypeLocalitiesFallbackRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<TypeLocalityDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/locality/types/fallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TypeLocalityDtoFromJSON));
    }

    /**
     */
    async getTypeLocalitiesFallback(requestParameters: GetTypeLocalitiesFallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<TypeLocalityDto>> {
        const response = await this.getTypeLocalitiesFallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTypeLocalityByIdRaw(requestParameters: GetTypeLocalityByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TypeLocalityDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTypeLocalityById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/locality/types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeLocalityDtoFromJSON(jsonValue));
    }

    /**
     */
    async getTypeLocalityById(requestParameters: GetTypeLocalityByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TypeLocalityDto> {
        const response = await this.getTypeLocalityByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTypeLocalityByNameRaw(requestParameters: GetTypeLocalityByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TypeLocalityDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getTypeLocalityByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/locality/types/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeLocalityDtoFromJSON(jsonValue));
    }

    /**
     */
    async getTypeLocalityByName(requestParameters: GetTypeLocalityByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TypeLocalityDto> {
        const response = await this.getTypeLocalityByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTypeLocalityRaw(requestParameters: UpdateTypeLocalityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TypeLocalityDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTypeLocality.');
        }

        if (requestParameters.setTypeLocalityDto === null || requestParameters.setTypeLocalityDto === undefined) {
            throw new runtime.RequiredError('setTypeLocalityDto','Required parameter requestParameters.setTypeLocalityDto was null or undefined when calling updateTypeLocality.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/addresses/locality/types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetTypeLocalityDtoToJSON(requestParameters.setTypeLocalityDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeLocalityDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateTypeLocality(requestParameters: UpdateTypeLocalityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TypeLocalityDto> {
        const response = await this.updateTypeLocalityRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
