import {makeAutoObservable} from "mobx";
import {DefaultSelectStore} from "../../../../../components/shared/DefaultSelectStore";
import {getApiConfiguration, IExpanded, IForm, IProcessing, toDateTimeString} from "../../../../../core";
import i18n from "../../../../../i18n";
import {
    DepJobsApi,
    JobsApi,
    PersonDepJobDto,
    PersonJobDto,
    PersonJobStatusesApi,
    PersonStatusDto,
    TypeOrgsApi
} from "../../../../../services/management";
import {OrganizationEmployeesTableStore} from "./OrganizationEmployeesTableStore";
import {JurisdictionData} from "../../../../authorization/AuthorizationStore";


export class OrganizationEmployeesItemStore implements IProcessing, IExpanded, IForm {
    readonly _store: OrganizationEmployeesTableStore;
    dto: PersonJobDto | PersonDepJobDto;
    processing: boolean = false;
    deleteDialogState: boolean = false;
    expand: boolean = false;
    private _date?: Date | null;

    status: DefaultSelectStore<PersonStatusDto>

    constructor(store: OrganizationEmployeesTableStore, dto: PersonJobDto | PersonDepJobDto) {
        this._store = store;
        this.dto = dto;
        this._date = dto.modifiedAt;
        this.status = new DefaultSelectStore<PersonStatusDto>(
            dto?.status || null, (filters) => new PersonJobStatusesApi(getApiConfiguration()).getPersonJobStatuses({ ...filters }),
            (value) => !value ? i18n.t('errors.empty') : "")

        makeAutoObservable(this);
    }

    get modifiedAt() {
        if (this._date) {
            return toDateTimeString(this._date);
        }
        return '';
    }

    async delete(): Promise<void> {
        if (this._store.items.length == 1)
            return;
        this.processing = true;
        try {
            let result = await new TypeOrgsApi(getApiConfiguration()).getTypeOrgs({
                parentId: JurisdictionData.organizationTypes.depsId,
                queryType: 'tree',
                size: 100,
                showOnlyStructural: true
            });
            const isTypeDepartment = result.map(x => x.id).includes(this._store._dto!.typeOrgId);
            if (isTypeDepartment) {
                await new DepJobsApi(getApiConfiguration()).deletePersonDepJob({id: this.dto.id})
            } else {
                await new JobsApi(getApiConfiguration()).deletePersonJob({id: this.dto.id})
            }

            this._store.items = this._store.items.filter(t => t.dto.id !== result[0].id);
        }
        catch (e) {

        }
        this._store.current = null;
        this.processing = false;
    };

    get equals(): boolean {
        return this.dto?.status?.id === this.status.value?.id
    }

    get valid(): boolean {
        return this.status.valid;
    }

    validate() {
        this.status.validate();
    }

    update(dto: PersonJobDto | PersonDepJobDto) {
        this.dto = dto;
        this.status.update(dto?.status || null);
    }

    reset(): void {
        this.status.reset();
        this.validate();
    }

    async save(): Promise<void> {
        this.processing = true;
        const api: JobsApi = new JobsApi(getApiConfiguration());
        try {
            await api.updatePersonJob({
                id: this.dto.id,
                updatePersonJobDto: {
                    postId: this.dto.post.id,
                    statusId: this.status.value?.id!
                }
            })
            this.status.update(this.status.value);
            this._store.pull();
        } finally {
            this.processing = false;
        }
    };

    async saveDep(): Promise<void> {
        this.processing = true;
        const api: DepJobsApi = new DepJobsApi(getApiConfiguration());
        try {
            await api.updatePersonDepJob({
                id: this.dto.id,
                updatePersonDepJobDto: {
                    postId: this.dto.post.id,
                    statusId: this.status.value?.id!
                }
            })
            this.status.update(this.status.value);
            this._store.pull();
        } finally {
            this.processing = false;
        }
    };
}
