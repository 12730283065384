/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface DcrGroupDto
 */
export interface DcrGroupDto {
    /**
     * 
     * @type {string}
     * @memberof DcrGroupDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DcrGroupDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrGroupDto
     */
    ownerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrGroupDto
     */
    ownerName?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DcrGroupDto
     */
    clients?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DcrGroupDto
     */
    responseTime?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DcrGroupDto
     */
    users: Array<string>;
}

export function DcrGroupDtoFromJSON(json: any): DcrGroupDto {
    return DcrGroupDtoFromJSONTyped(json, false);
}

export function DcrGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DcrGroupDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
        'ownerName': !exists(json, 'ownerName') ? undefined : json['ownerName'],
        'clients': !exists(json, 'clients') ? undefined : json['clients'],
        'responseTime': !exists(json, 'responseTime') ? undefined : json['responseTime'],
        'users': json['users'],
    };
}

export function DcrGroupDtoToJSON(value?: DcrGroupDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'ownerId': value.ownerId,
        'ownerName': value.ownerName,
        'clients': value.clients,
        'responseTime': value.responseTime,
        'users': value.users,
    };
}

