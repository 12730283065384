import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    List,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AddButton,
    AuthorizePermission,
    Cell,
    EmptyBox,
    ExpandButton,
    ExpandRow,
    HCell,
    Pagination,
    Progress,
    ResetFiltersButton,
} from '../../../../components';
import { CopyToClipboard } from '../../../../components/CopyToClipboard';
import { FiltersComponent } from '../../../../components/filters/FiltersComponent';
import { SeverityPill } from '../../../../components/severity-pill';
import { DefaultDate } from '../../../../components/shared/DefaultDate';
import { DefaultMultiSelect } from '../../../../components/shared/components/DefaultMultiSelect';
import { toDateStringOrEmpty } from '../../../../core';
import { CloudDownload } from '../../../../icons/cloud-download';
import { useManagementStore } from '../../../../stores';

export const DcrReport = observer(() => {
    const { t } = useTranslation();
    const { dashboardsPageStore } = useManagementStore();
    const store = dashboardsPageStore.dcrReportStore;

    const getValueArray = (value?: string[] | null): string =>
        !value
            ? t('common.all')
            : value.length === 0
            ? t('common.empty')
            : value.reduce((x, y) => x + ', ' + y);

    const getFields = (title: string, value: any) => (
        <ListItem sx={{ px: 0, py: 0.5 }} style={{ width: 'auto' }}>
            <ListItemText
                disableTypography
                primary={
                    <Box
                        sx={{
                            minWidth: 180,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            style={{ marginBottom: 'auto', maxWidth: 160 }}
                        >
                            {title}
                        </Typography>
                    </Box>
                }
                secondary={
                    <Box
                        sx={{
                            flex: 1,
                            mt: 0,
                            ml: 3,
                            marginLeft: 'unset',
                            marginRight: '24px',
                            // width: 280
                        }}
                    >
                        {value}
                    </Box>
                }
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    my: 0,
                }}
            />
        </ListItem>
    );

    return (
        <AuthorizePermission permissions="" showWarning role={'Admin'}>
            <Breadcrumbs separator="/">
                <Typography color="primary" variant="h6">
                    {t('analytics.reports')}
                </Typography>
                <Typography color="textSecondary" variant="h6">
                    {t('pageNames.dcr')}
                </Typography>
            </Breadcrumbs>
            <Box>
                <FiltersComponent>
                    <Box
                        sx={{ mb: 1 }}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: 0,
                        }}
                    >
                        <DefaultDate
                            store={store.startDate}
                            style={{ width: '24%', marginTop: 16 }}
                            title={t('analytics.dcr.report.filters.startDate')}
                        />
                        <DefaultDate
                            store={store.endDate}
                            style={{ width: '24%', marginTop: 16 }}
                            title={t('analytics.dcr.report.filters.endDate')}
                        />
                        <DefaultMultiSelect
                            store={store.clients}
                            title={t('analytics.dcr.report.filters.clients')}
                            nameExp={store.clients.nameExp}
                            valueExp={store.clients.valueExp}
                            style={{ width: '24%' }}
                        />
                        <DefaultMultiSelect
                            store={store.responsible}
                            title={t(
                                'analytics.dcr.report.filters.responsibility'
                            )}
                            nameExp={store.responsible.nameExp}
                            valueExp={store.responsible.valueExp}
                            style={{ width: '24%' }}
                        />
                    </Box>
                    <Box
                        sx={{ mb: 1 }}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: 10,
                            marginTop: -8,
                        }}
                    >
                        <DefaultMultiSelect
                            store={store.status}
                            title={t('analytics.dcr.report.filters.status')}
                            nameExp={store.status.nameExp}
                            valueExp={store.status.valueExp}
                            style={{ width: '32%' }}
                        />
                        <DefaultMultiSelect
                            store={store.type}
                            title={t('analytics.dcr.report.filters.type')}
                            nameExp={store.type.nameExp}
                            valueExp={store.type.valueExp}
                            style={{ width: '32%' }}
                        />
                        <DefaultMultiSelect
                            store={store.entity}
                            title={t('analytics.dcr.report.filters.entity')}
                            nameExp={store.entity.nameExp}
                            valueExp={store.entity.valueExp}
                            style={{ width: '32%' }}
                        />
                    </Box>
                    <Box
                        style={{ display: 'flex', height: 'auto' }}
                        sx={{ my: 1 }}
                    >
                        <ResetFiltersButton
                            onClick={() => store.resetFilters()}
                        />
                        <AddButton
                            onClick={async () => await store.createReport()}
                            testid="persons-create-btn"
                            style={{ marginLeft: 'auto', width: 'max-content' }}
                            title={t('analytics.dcr.report.create')}
                        />
                    </Box>
                </FiltersComponent>

                <Card sx={{ mb: 8, overflow: 'unset' }}>
                    <Table sx={{ minWidth: 1250 }}>
                        <TableHead
                            style={{ position: 'sticky', top: 63, zIndex: 999 }}
                        >
                            <TableRow>
                                <HCell width="3%"></HCell>
                                <HCell
                                    style={{ padding: '6px 30px' }}
                                    store={store}
                                >
                                    {t('analytics.dcr.report.columns.id')}
                                </HCell>
                                <HCell store={store}>
                                    {t('analytics.dcr.report.columns.name')}
                                </HCell>
                                <HCell store={store}>
                                    {t(
                                        'analytics.dcr.report.columns.createdBy'
                                    )}
                                </HCell>
                                <HCell store={store}>
                                    {t(
                                        'analytics.dcr.report.columns.createdDate'
                                    )}
                                </HCell>
                                <HCell store={store}>
                                    {t('analytics.dcr.report.columns.state')}
                                </HCell>
                                <HCell store={store}></HCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {store.items.length > 0 &&
                                store.items.map((i) => {
                                    return (
                                        <Fragment key={i.id}>
                                            <TableRow
                                                style={{ height: 60 }}
                                                hover
                                                key={i.id}
                                            >
                                                <ExpandButton
                                                    item={i}
                                                    isOpen={i.expand}
                                                />
                                                <Cell
                                                    style={{ paddingLeft: 30 }}
                                                >
                                                    <Typography
                                                        variant="subtitle2"
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        {`${i.id!.substring(
                                                            0,
                                                            14
                                                        )}...`}
                                                        <CopyToClipboard
                                                            copyText={i.id!}
                                                        />
                                                    </Typography>
                                                </Cell>
                                                <Cell>
                                                    <Typography variant="subtitle2">
                                                        {i.fileName}
                                                    </Typography>
                                                </Cell>
                                                <Cell>
                                                    <Typography variant="subtitle2">
                                                        {i.user}
                                                    </Typography>
                                                </Cell>
                                                <Cell
                                                    style={{
                                                        paddingRight: 30,
                                                        marginRight: 10,
                                                    }}
                                                >
                                                    <Typography variant="subtitle2">
                                                        {i.createdDate}
                                                    </Typography>
                                                </Cell>
                                                <Cell>
                                                    <SeverityPill
                                                        color={store.statusHandler(
                                                            i.state!
                                                        )}
                                                    >
                                                        {t(
                                                            'analytics.dcr.report.state.' +
                                                                i.state
                                                        )}
                                                    </SeverityPill>
                                                </Cell>
                                                <Cell style={{ width: '80px' }}>
                                                    <Button
                                                        onClick={() =>
                                                            store.downloadReport(
                                                                i.fileName!
                                                            )
                                                        }
                                                    >
                                                        <CloudDownload
                                                            fontSize="medium"
                                                            style={{
                                                                fill: 'blue',
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </Button>
                                                </Cell>
                                            </TableRow>
                                            {i.expand && (
                                                <ExpandRow
                                                    key={`${i.id}_`}
                                                    item={i}
                                                >
                                                    <List
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                            flexWrap: 'wrap',
                                                            alignItems:
                                                                'flex-start',
                                                            padding: 'unset',
                                                            margin: '20px',
                                                            paddingLeft: '25px',
                                                        }}
                                                    >
                                                        {getFields(
                                                            t(
                                                                'analytics.dcr.report.filters.startDate'
                                                            ),
                                                            toDateStringOrEmpty(
                                                                i.filters
                                                                    ?.startDate
                                                            )
                                                        )}
                                                        {getFields(
                                                            t(
                                                                'analytics.dcr.report.filters.endDate'
                                                            ),
                                                            toDateStringOrEmpty(
                                                                i.filters
                                                                    ?.endDate
                                                            )
                                                        )}
                                                        {getFields(
                                                            t(
                                                                'analytics.dcr.report.filters.clients'
                                                            ),
                                                            getValueArray(
                                                                i.filters
                                                                    ?.clients
                                                            )
                                                        )}
                                                        {getFields(
                                                            t(
                                                                'analytics.dcr.report.filters.responsibility'
                                                            ),
                                                            getValueArray(
                                                                i.filters
                                                                    ?.responsibleIds
                                                            )
                                                        )}
                                                        {getFields(
                                                            t(
                                                                'analytics.dcr.report.filters.status'
                                                            ),
                                                            getValueArray(
                                                                i.filters
                                                                    ?.status
                                                            )
                                                        )}
                                                        {getFields(
                                                            t(
                                                                'analytics.dcr.report.filters.entity'
                                                            ),
                                                            getValueArray(
                                                                i.filters
                                                                    ?.entity
                                                            )
                                                        )}
                                                        {getFields(
                                                            t(
                                                                'analytics.dcr.report.filters.type'
                                                            ),
                                                            getValueArray(
                                                                i.filters?.type
                                                            )
                                                        )}
                                                    </List>
                                                </ExpandRow>
                                            )}
                                        </Fragment>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    {!store.items.length && <EmptyBox />}
                    {store.items.length > 0 && <Pagination store={store} />}
                    <Progress store={store} />
                </Card>
            </Box>
        </AuthorizePermission>
        //    </Authorize>
    );
});
