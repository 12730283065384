/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Exchange.Analytics.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FeedStatus = {
    Pending: 'Pending',
    Enqueued: 'Enqueued',
    InProgress: 'InProgress',
    Succeeded: 'Succeeded',
    Failed: 'Failed',
    Rejected: 'Rejected'
} as const;
export type FeedStatus = typeof FeedStatus[keyof typeof FeedStatus];


export function FeedStatusFromJSON(json: any): FeedStatus {
    return FeedStatusFromJSONTyped(json, false);
}

export function FeedStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedStatus {
    return json as FeedStatus;
}

export function FeedStatusToJSON(value?: FeedStatus | null): any {
    return value as any;
}

