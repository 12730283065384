import {observer} from "mobx-react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTranslation} from "react-i18next";
import {MappingTable} from "./MappingTable";
import {AccordionTitle} from "../bounds/Bounds";
import {useTheme} from '@mui/material/styles';
import {MappingsStore} from "../../../../stores/management/clients/tabs/mappings/MappingsStore";

interface Props {
    store: MappingsStore;
}

export const Mappings = observer(({store}: Props) => {
    const {t} = useTranslation();
    const theme = useTheme();

    return (
        <div style={{width: "100%"}}>
            <Accordion expanded={store.clientCategoryStore.expand}
                       onChange={(e, isExpanded) => store.clientCategoryStore.expand = isExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <AccordionTitle isExpand={store.clientCategoryStore.expand}
                                    title={t('clientPage.mappings.accordions.clientCategory')}/>
                </AccordionSummary>
                <AccordionDetails>
                    <MappingTable store={store.clientCategoryStore}
                                  entityName={t("clientPage.mappings.accordions.clientCategory")}/>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={store.organizationStore.expand}
                       onChange={(e, isExpanded) => store.organizationStore.expand = isExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <AccordionTitle isExpand={store.organizationStore.expand}
                                    title={t('clientPage.mappings.accordions.organizations')}/>
                </AccordionSummary>
                <AccordionDetails>
                    <MappingTable withName={false} store={store.organizationStore}
                                  entityName={t("clientPage.mappings.accordions.organizations")}/>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={store.personStore.expand}
                       onChange={(e, isExpanded) => store.personStore.expand = isExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <AccordionTitle isExpand={store.personStore.expand}
                                    title={t('clientPage.mappings.accordions.person')}/>
                </AccordionSummary>
                <AccordionDetails>
                    <MappingTable withName={false} store={store.personStore}
                                  entityName={t("clientPage.mappings.accordions.person")}/>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={store.postStore.expand}
                       onChange={(e, isExpanded) => store.postStore.expand = isExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <AccordionTitle isExpand={store.postStore.expand} title={t('clientPage.mappings.accordions.post')}/>
                </AccordionSummary>
                <AccordionDetails>
                    <MappingTable store={store.postStore} entityName={t("clientPage.mappings.accordions.post")}/>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={store.recordTypeStore.expand}
                       onChange={(e, isExpanded) => store.recordTypeStore.expand = isExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <AccordionTitle isExpand={store.recordTypeStore.expand}
                                    title={t('clientPage.mappings.accordions.recordType')}/>
                </AccordionSummary>
                <AccordionDetails>
                    <MappingTable store={store.recordTypeStore}
                                  entityName={t("clientPage.mappings.accordions.recordType")}/>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={store.specialityStore.expand}
                       onChange={(e, isExpanded) => store.specialityStore.expand = isExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <AccordionTitle isExpand={store.specialityStore.expand}
                                    title={t('clientPage.mappings.accordions.specialty')}/>
                </AccordionSummary>
                <AccordionDetails>
                    <MappingTable store={store.specialityStore}
                                  entityName={t("clientPage.mappings.accordions.specialty")}/>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={store.typeOrgStore.expand}
                       onChange={(e, isExpanded) => store.typeOrgStore.expand = isExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <AccordionTitle isExpand={store.typeOrgStore.expand}
                                    title={t('clientPage.mappings.accordions.typeOrg')}/>
                </AccordionSummary>
                <AccordionDetails>
                    <MappingTable store={store.typeOrgStore} entityName={t("clientPage.mappings.accordions.typeOrg")}/>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={store.localityStore.expand}
                       onChange={(e, isExpanded) => store.localityStore.expand = isExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <AccordionTitle isExpand={store.localityStore.expand}
                                    title={t('clientPage.mappings.accordions.locality')}/>
                </AccordionSummary>
                <AccordionDetails>
                    <MappingTable store={store.localityStore}
                                  entityName={t("clientPage.mappings.accordions.locality")}/>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={store.brickStore.expand}
                       onChange={(e, isExpanded) => store.brickStore.expand = isExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <AccordionTitle isExpand={store.brickStore.expand}
                                    title={t('clientPage.mappings.accordions.bricks')}/>
                </AccordionSummary>
                <AccordionDetails>
                    <MappingTable store={store.brickStore} entityName={t("clientPage.mappings.accordions.bricks")}/>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={store.typeLocalityTableStore.expand}
                       onChange={(e, isExpanded) => store.typeLocalityTableStore.expand = isExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <AccordionTitle isExpand={store.typeLocalityTableStore.expand}
                                    title={t('clientPage.mappings.accordions.typeLocality')}/>
                </AccordionSummary>
                <AccordionDetails>
                    <MappingTable store={store.typeLocalityTableStore}
                                  entityName={t("clientPage.mappings.accordions.typeLocality")}/>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={store.organizationStatusTableStore.expand}
                       onChange={(e, isExpanded) => store.organizationStatusTableStore.expand = isExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <AccordionTitle isExpand={store.organizationStatusTableStore.expand}
                                    title={t('clientPage.mappings.accordions.organizationStatus')}/>
                </AccordionSummary>
                <AccordionDetails>
                    <MappingTable store={store.organizationStatusTableStore}
                                  entityName={t("clientPage.mappings.accordions.organizationStatus")}/>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={store.personStatusTableStore.expand}
                       onChange={(e, isExpanded) => store.personStatusTableStore.expand = isExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <AccordionTitle isExpand={store.personStatusTableStore.expand}
                                    title={t('clientPage.mappings.accordions.personStatus')}/>
                </AccordionSummary>
                <AccordionDetails>
                    <MappingTable store={store.personStatusTableStore}
                                  entityName={t("clientPage.mappings.accordions.personStatus")}/>
                </AccordionDetails>
            </Accordion>
        </div>
    );
});
