/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    EmailStatusDto,
    EmailStatusDtoFromJSON,
    EmailStatusDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OCMObjectState,
    OCMObjectStateFromJSON,
    OCMObjectStateToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetEmailStatusDto,
    SetEmailStatusDtoFromJSON,
    SetEmailStatusDtoToJSON,
} from '../models';

export interface CreateOrganizationEmailStatusRequest {
    setEmailStatusDto: SetEmailStatusDto;
}

export interface DeleteOrganizationEmailStatusRequest {
    id: string;
}

export interface GetOrganizationEmailStatusByIdRequest {
    id: string;
}

export interface GetOrganizationEmailStatusByNameRequest {
    name: string;
}

export interface GetOrganizationEmailStatusesRequest {
    search?: string;
    orderBy?: string;
    searchByExternalId?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface GetOrganizationEmailStatusesCountRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface UpdateOrganizationEmailStatusRequest {
    id: string;
    setEmailStatusDto: SetEmailStatusDto;
}

/**
 * 
 */
export class OrganizationEmailStatusesApi extends runtime.BaseAPI {

    /**
     */
    async createOrganizationEmailStatusRaw(requestParameters: CreateOrganizationEmailStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailStatusDto>> {
        if (requestParameters.setEmailStatusDto === null || requestParameters.setEmailStatusDto === undefined) {
            throw new runtime.RequiredError('setEmailStatusDto','Required parameter requestParameters.setEmailStatusDto was null or undefined when calling createOrganizationEmailStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/emails/statuses/organization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetEmailStatusDtoToJSON(requestParameters.setEmailStatusDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async createOrganizationEmailStatus(requestParameters: CreateOrganizationEmailStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailStatusDto> {
        const response = await this.createOrganizationEmailStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteOrganizationEmailStatusRaw(requestParameters: DeleteOrganizationEmailStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOrganizationEmailStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/statuses/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteOrganizationEmailStatus(requestParameters: DeleteOrganizationEmailStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailStatusDto> {
        const response = await this.deleteOrganizationEmailStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationEmailStatusByIdRaw(requestParameters: GetOrganizationEmailStatusByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrganizationEmailStatusById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/statuses/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationEmailStatusById(requestParameters: GetOrganizationEmailStatusByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailStatusDto> {
        const response = await this.getOrganizationEmailStatusByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationEmailStatusByNameRaw(requestParameters: GetOrganizationEmailStatusByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailStatusDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getOrganizationEmailStatusByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/statuses/organization/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationEmailStatusByName(requestParameters: GetOrganizationEmailStatusByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailStatusDto> {
        const response = await this.getOrganizationEmailStatusByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationEmailStatusesRaw(requestParameters: GetOrganizationEmailStatusesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<EmailStatusDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/statuses/organization`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmailStatusDtoFromJSON));
    }

    /**
     */
    async getOrganizationEmailStatuses(requestParameters: GetOrganizationEmailStatusesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<EmailStatusDto>> {
        const response = await this.getOrganizationEmailStatusesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationEmailStatusesCountRaw(requestParameters: GetOrganizationEmailStatusesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/statuses/organization/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationEmailStatusesCount(requestParameters: GetOrganizationEmailStatusesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getOrganizationEmailStatusesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrganizationEmailStatusRaw(requestParameters: UpdateOrganizationEmailStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrganizationEmailStatus.');
        }

        if (requestParameters.setEmailStatusDto === null || requestParameters.setEmailStatusDto === undefined) {
            throw new runtime.RequiredError('setEmailStatusDto','Required parameter requestParameters.setEmailStatusDto was null or undefined when calling updateOrganizationEmailStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/emails/statuses/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetEmailStatusDtoToJSON(requestParameters.setEmailStatusDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateOrganizationEmailStatus(requestParameters: UpdateOrganizationEmailStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailStatusDto> {
        const response = await this.updateOrganizationEmailStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
