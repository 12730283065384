/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface JurisdictionLocalSettingDto
 */
export interface JurisdictionLocalSettingDto {
    /**
     * 
     * @type {string}
     * @memberof JurisdictionLocalSettingDto
     */
    jurisdictionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JurisdictionLocalSettingDto
     */
    phoneNumberRegex?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JurisdictionLocalSettingDto
     */
    itnRegex?: string | null;
    /**
     *
     * @type {string}
     * @memberof JurisdictionLocalSettingDto
     */
    zipRegex?: string | null;
}

export function JurisdictionLocalSettingDtoFromJSON(json: any): JurisdictionLocalSettingDto {
    return JurisdictionLocalSettingDtoFromJSONTyped(json, false);
}

export function JurisdictionLocalSettingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JurisdictionLocalSettingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jurisdictionId': !exists(json, 'jurisdictionId') ? undefined : json['jurisdictionId'],
        'phoneNumberRegex': !exists(json, 'phoneNumberRegex') ? undefined : json['phoneNumberRegex'],
        'itnRegex': !exists(json, 'itnRegex') ? undefined : json['itnRegex'],
        'zipRegex': !exists(json, 'zipRegex') ? undefined : json['zipRegex'],
    };
}

export function JurisdictionLocalSettingDtoToJSON(value?: JurisdictionLocalSettingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jurisdictionId': value.jurisdictionId,
        'phoneNumberRegex': value.phoneNumberRegex,
        'itnRegex': value.itnRegex,
        'zipRegex': value.zipRegex,
    };
}

