/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface UpdatePersonWebsiteDto
 */
export interface UpdatePersonWebsiteDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonWebsiteDto
     */
    websiteStatusId?: string | null;
    /**
     *
     * @type {string}
     * @memberof UpdatePersonWebsiteDto
     */
    address?: string | null;
    /**
     *
     * @type {string}
     * @memberof UpdatePersonWebsiteDto
     */
    description?: string | null;
}

export function UpdatePersonWebsiteDtoFromJSON(json: any): UpdatePersonWebsiteDto {
    return UpdatePersonWebsiteDtoFromJSONTyped(json, false);
}

export function UpdatePersonWebsiteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePersonWebsiteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'websiteStatusId': !exists(json, 'websiteStatusId') ? undefined : json['websiteStatusId'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function UpdatePersonWebsiteDtoToJSON(value?: UpdatePersonWebsiteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'websiteStatusId': value.websiteStatusId,
        'address': value.address,
        'description': value.description,
    };
}

