/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Api.Export
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {ExportImportType, ExportImportTypeFromJSON, ExportImportTypeToJSON,} from './ExportImportType';
import {ExportJobInfo, ExportJobInfoFromJSON, ExportJobInfoToJSON,} from './ExportJobInfo';
import {ExportStatus, ExportStatusFromJSON, ExportStatusToJSON,} from './ExportStatus';
import {MinioMongoFileInfo, MinioMongoFileInfoFromJSON, MinioMongoFileInfoToJSON,} from './MinioMongoFileInfo';

/**
 * 
 * @export
 * @interface ExportFile
 */
export interface ExportFile {
    /**
     * 
     * @type {string}
     * @memberof ExportFile
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExportFile
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExportFile
     */
    userName?: string | null;
    /**
     * 
     * @type {MinioMongoFileInfo}
     * @memberof ExportFile
     */
    storageFile?: MinioMongoFileInfo;
    /**
     * 
     * @type {ExportJobInfo}
     * @memberof ExportFile
     */
    exportJobInfo?: ExportJobInfo;
    /**
     * 
     * @type {ExportStatus}
     * @memberof ExportFile
     */
    status?: ExportStatus;
    /**
     * 
     * @type {ExportImportType}
     * @memberof ExportFile
     */
    exportType?: ExportImportType;
    /**
     * 
     * @type {Date}
     * @memberof ExportFile
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ExportFile
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExportFile
     */
    log?: string | null;
}

export function ExportFileFromJSON(json: any): ExportFile {
    return ExportFileFromJSONTyped(json, false);
}

export function ExportFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'storageFile': !exists(json, 'storageFile') ? undefined : MinioMongoFileInfoFromJSON(json['storageFile']),
        'exportJobInfo': !exists(json, 'exportJobInfo') ? undefined : ExportJobInfoFromJSON(json['exportJobInfo']),
        'status': !exists(json, 'status') ? undefined : ExportStatusFromJSON(json['status']),
        'exportType': !exists(json, 'exportType') ? undefined : ExportImportTypeFromJSON(json['exportType']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'log': !exists(json, 'log') ? undefined : json['log'],
    };
}

export function ExportFileToJSON(value?: ExportFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'userName': value.userName,
        'storageFile': MinioMongoFileInfoToJSON(value.storageFile),
        'exportJobInfo': ExportJobInfoToJSON(value.exportJobInfo),
        'status': ExportStatusToJSON(value.status),
        'exportType': ExportImportTypeToJSON(value.exportType),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'description': value.description,
        'log': value.log,
    };
}

