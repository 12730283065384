import {makeObservable, observable} from 'mobx';
import {getObject, setObject} from '../../../core';
import {DefaultValueStore} from "../../../components/shared/DefaultValueStore";
import {DictionaryEntity, DictionaryTableStore} from "./DictionaryTableStore";
import {DynamicalComponentStore} from "../../utils";
import {DefaultFormStore} from "../../../components/shared/DefaultFormStore";

interface DictionaryItemStoreLocalFilters {
    expand: boolean;
}

export class DictionaryItemStore extends DynamicalComponentStore{
    readonly store: DictionaryTableStore;
    public dto: DictionaryEntity;
    form: DefaultFormStore<{
        nameRus: DefaultValueStore<string>,
        nameEng: DefaultValueStore<string>,
        isActive: DefaultValueStore<boolean>
    }>

    constructor(store: DictionaryTableStore, dto: DictionaryEntity) {
        super();
        this.store = store;
        this.dto = dto;
        this.form = new DefaultFormStore({
            nameRus: new DefaultValueStore<string>(dto.nameRus),
            nameEng: new DefaultValueStore<string>(dto.nameEng),
            isActive: new DefaultValueStore<boolean>(!dto.isInactive)
        }, async (e) => {
            await this.store.save(this.dto.id, {...e, ...{isInactive: !e.isActive}})
        })
        this.form.fields.nameRus.required = true;

        const filters = getObject<DictionaryItemStoreLocalFilters>(`${store.filterName}-${this.dto.id}`);
        if(filters){
            this._expand = filters.expand;
        }
        makeObservable(this,{
            dto: observable
        });
    }

    update(dto: DictionaryEntity) {
        this.dto = dto;
        this.form.fields.nameRus.update(dto.nameRus);
        this.form.fields.nameEng.update(dto.nameEng ?? "");
        this.form.fields.isActive.update(!dto.isInactive)
    }

    saveFilters() {
        setObject<DictionaryItemStoreLocalFilters>(`${this.store.filterName}-${this.dto.id}`, {
            expand: this._expand
        });
    }

    async updateState(): Promise<void> {
        this.saveFilters();
    }
}
