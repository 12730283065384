import {makeAutoObservable} from "mobx";
import {getApiConfiguration, IExpanded, toDateTimeString} from "../../../../core";
import {OrganizationDoblesApi, OrganizationDto} from "../../../../services/management";
import {getOrganizationAddressHandler, getOrgName} from "../../../utils";
import {OrganizationEmployeesTableStore} from "../details/employees/OrganizationEmployeesTableStore";
import {OrganizationItemStore} from "../OrganizationItemStore";
import {DoubleTableStore} from "./DoubleTableStore";

export class DoubleItemStore implements IExpanded {
    readonly _store: DoubleTableStore;
    readonly _dto: OrganizationDto & { doubleId: string | null, date: Date | null };
    readonly _edit: boolean;
    id: string;
    externalId: string;
    private _expand: boolean = false;
    processing: boolean = false;
    jobDouble: OrganizationEmployeesTableStore;
    jobAntiDouble: OrganizationEmployeesTableStore;
    addDialogState: boolean = false;
    deleteDialogState: boolean = false;
    orgName: string = ''
    address: string = '';

    constructor(store: DoubleTableStore, dto: OrganizationDto & { doubleId: string | null, date: Date | null }, edit: boolean = false) {
        this._store = store;
        this._dto = dto;
        this._edit = edit;
        this.id = dto.id;
        this.externalId = dto?.externalId ?? '';
        const doubleItemStore = new OrganizationItemStore(store?.store?._store, dto);
        this.jobDouble = new OrganizationEmployeesTableStore(doubleItemStore, this._dto);
        this.jobAntiDouble = new OrganizationEmployeesTableStore(store?.store, store.store._dto);
        this.orgName = getOrgName(dto);
        this.address = getOrganizationAddressHandler(dto?.addressUnique);

        makeAutoObservable(this);
    }

    get expand() {
        return this._expand;
    }

    get createdDate(): string {
        if (!this._dto.date) return '';
        return toDateTimeString(this._dto.date);
    }

    get modifiedDate(): string {
        if (!this._dto?.modifiedAt) return '';
        return toDateTimeString(this._dto?.modifiedAt);
    }

    openDetails() {
        this.jobDouble.pull();
        this.jobAntiDouble.pull();
    }

    get stateDouble() {
        if (!!this._edit) {
            return {
                color: 'orange',
                title: 'Не добавлено'
            }
        }
        return {
            color: 'green',
            title: 'Добавлено'
        }
    }

    set expand(value: boolean) {
        this._expand = value;
    }

    async submitAntiDoble(): Promise<void> {
        this.processing = true;
        this._store.loading = true;
        const api: OrganizationDoblesApi = new OrganizationDoblesApi(getApiConfiguration());
        try {
            let updateOrg = await this._store.store._store.getOrganizationHuck(this._dto.id);
            await updateOrg?.propertiesStore.pullDoble();
            await api.createOrganizationDoble({
                setOrganizationDobleDto: {
                    dobleId: this._store.store.id,
                    organizationId: this._dto.id,
                }
            });
            updateOrg = await this._store.store._store.getOrganizationHuck(this._dto.id);
            updateOrg?._store.updateState();
        } finally {
            this._store.loading = false;
            this._store.items = this._store.items.filter(x => x.id !== this._dto.id);
            this._store.pull();
            this.processing = false;
            this.addDialogState = false;
        }
    }

    async removeDoble() {
        this.processing = true;
        this.deleteDialogState = false;
        this._store.loading = true;
        try {
            await new OrganizationDoblesApi().deleteOrganizationDoble({
                id: this._dto.doubleId!
            });
        } finally {
            this._store.loading = false;
            this.processing = true;
            await this._store.pull();
        }
        const updateOrg = await this._store.store._store.getOrganizationHuck(this._dto.id);
        updateOrg?._store.updateState();
    }
}
