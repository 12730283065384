import {IExpanded} from '../../../core';
import {makeAutoObservable} from "mobx";
import {JurisdictionsTableStore} from "./JurisdictionsTableStore";
import {JurisdictionDto} from "../../../services/management";
import {DefaultFormStore} from "../../../components/shared/DefaultFormStore";
import {DefaultValueStore} from "../../../components/shared/DefaultValueStore";

export class JurisdictionItemStore implements IExpanded {
    readonly store: JurisdictionsTableStore;
    details: DefaultFormStore<{
        nameRus: DefaultValueStore<string>,
        nameEng: DefaultValueStore<string>
    }>;
    public dto: JurisdictionDto;
    public expand: boolean = false;

    constructor(store: JurisdictionsTableStore, dto: JurisdictionDto) {
        this.store = store;
        this.dto = dto;
        this.details = new DefaultFormStore(
            {
                nameEng: new DefaultValueStore<string>(this.dto.nameEng),
                nameRus: new DefaultValueStore<string>(this.dto.nameRus)
            },
        )
        makeAutoObservable(this);
    }

    update(dto: JurisdictionDto){
        this.dto = dto;
        this.details.fields.nameRus.update(this.dto.nameRus)
        this.details.fields.nameEng.update(this.dto.nameEng)
    }
}

