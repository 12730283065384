export function getValueInObject<T>(a: T, property?: string | ((obj1: T, obj2: T) => any) | null): any | null | undefined {
    if ((!a && a !== 0) || !property)
        return String(a);

    const getByProperty = (_a: T, _property: string) => {
        return (_a as any)[_property];
    };

    const getByConvert = (_a: T, _property: (obj: T) => any) => {
        return _property(_a)
    };


    if (typeof (property) == 'string') {
        return getByProperty(a, String(property));
    } else {
        return getByConvert(a, property as (obj: T) => any);
    }
}

