import {IExpanded, IHasTabs, ILoad, IProcessing} from '../core';
import {
    AddressUniqueDto,
    BrickDto,
    ListSortDirection,
    OrganizationDto,
    OrganizationStatusDto,
    PersonDto,
    PersonStatusDto,
    ProblemDetails
} from '../services/management';
import {IAddress} from "./management";
import i18n from '../i18n';
import {debounce, DebouncedFunc} from "lodash";
import {OrganizationStatuses} from '../core/const';
import {DefaultValueStore} from "../components/shared/DefaultValueStore";
import {action, computed, makeObservable, observable} from "mobx";
import {SortEnumType} from "../gql/graphql";
import {JurisdictionData} from "./authorization/AuthorizationStore";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";

export interface TableLocalFilters<T> {
    order: ListSortDirection;
    orderBy?: keyof T | undefined | string;
    search: string;
    page: number;
    size: number;
    searchById?: string;
    searchByExternalId?: string;
}

export interface StoreProps<T> {
    store: T
}

export interface StyleProps {
    style?: React.CSSProperties;
}


export function callTheProcessingFunc<T>(
    store: IProcessing,
    func: (rest?: T | null) => Promise<void>,
    after: ((rest?: T | null) => Promise<void> | void) | null = null,
    errorCall: (() => Promise<void> | void) | null = null): (rest?: T | null) => Promise<void> {
    async function f(rr?: T | null): Promise<void> {
        store.processing = true;

        await func(rr).then(async x => {
            if (after)
                await after(rr)
        }).catch(async x => {
            if (errorCall)
                await errorCall()
        }).finally(() => {
            store.processing = false;
        })
    }

    return f;
}


export abstract class LoadStore implements ILoad {
    loading: boolean = false;
    private wait: number = 600;
    private readonly debounceRequest: DebouncedFunc<any> = debounce(this.request.bind(this), this.wait)

    protected constructor() {
        makeObservable(this, {
            loading: observable,
            pull: action,
            request: action
        })
    }

    async pull(): Promise<void> {
        this.loading = true;
        await this.debounceRequest()
        this.loading = false;
    }

    abstract request(): Promise<void>
}

export abstract class ValueLoadStore<T> {
    value: T | null | undefined;
    loading: boolean = false;
    private wait: number = 800;
    private readonly debounceRequest: DebouncedFunc<any> = debounce(this.initValue.bind(this), this.wait)

    constructor() {
        makeObservable(this, {
            loading: observable,
            pull: action,
            request: action,
            value: observable,
            updateState: action,
            afterLoad: action,
            beforeLoad: action,
            initValue: action
        })
    }

    async updateState() {
        await this.pull();
    }

    async pull(): Promise<void> {
        await this.debounceRequest()
    }

    async afterLoad(): Promise<void> {

    }

    async beforeLoad(): Promise<void> {

    }

    key: string = ''

    async initValue(): Promise<void> {
        this.loading = true;
        const key = generateUniqueID();

        try {
            await this.beforeLoad();
            this.key = key;
            const data = await this.request()
            if (this.key == key) {
                this.value = data
            }
            await this.afterLoad();
        } finally {
            if (this.key == key) {
                this.loading = false;
            }
        }
    }

    abstract request(): Promise<T>
}

export abstract class DynamicalComponentStore implements IExpanded, IHasTabs {
    public _tabIndex: number = 0;
    public _expand: boolean = false;

    protected constructor() {
        makeObservable(this, {
            _tabIndex: observable,
            _expand: observable,
            expand: computed,
            tabIndex: computed,
            updateState: action
        })
    }

    get expand() {
        return this._expand;
    }

    set expand(value: boolean) {
        this._expand = value;
        if (this._expand)
            this.updateState().then()
    }

    get tabIndex() {
        return this._tabIndex;
    }

    set tabIndex(value: number) {
        this._tabIndex = value;
        this.updateState().then()
    }

    abstract updateState(): Promise<void>;
}

export abstract class ItemsLoadStore<T> extends ValueLoadStore<T[]> {
    value: T[] = []
}

export class PagedItems<T> {
    items: T[]
    count: number;

    constructor(items: T[] = [], count: number = 0) {
        this.items = items;
        this.count = count;

        makeObservable(this, {
            items: observable,
            count: observable,
        });
    }
}

export abstract class TableStore<T> extends ValueLoadStore<PagedItems<T>> implements IProcessing {
    value: PagedItems<T> = new PagedItems()
    processing: boolean = false;
    search: DefaultValueStore<string> = new DefaultValueStore<string>("", null, (e) => this.updateState());
    searchById: DefaultValueStore<string> = new DefaultValueStore<string>("", null, (e) => this.updateState());
    searchByExternalId: DefaultValueStore<string> = new DefaultValueStore<string>("", null, (e) => this.updateState());
    _page: number = 0;
    _size: number = 25;
    _order: ListSortDirection = ListSortDirection.Ascending;
    _orderBy?: keyof T | string;

    get items(): T[] {
        return this.value?.items ?? []
    }

    set items(items: T[]) {
        this.value.items = items;
    }

    get count(): number {
        return this.value?.count ?? 0;
    }

    set count(count: number) {
        this.value.count = count;
    }

    protected constructor() {
        super();
        makeObservable(this, {
            processing: observable,
            _page: observable,
            _size: observable,
            _order: observable,
            _orderBy: observable,
            search: observable,
            count: computed,
            searchById: observable,
            searchByExternalId: observable,
            page: computed,
            size: computed,
            order: computed,
            orderBy: computed,
            items: computed,
            setOrder: action,
            setPaging: action
        })
    }

    async setPaging(page: number, size: number): Promise<void> {
        this._page = page;
        this._size = size;
        this.onFiltersSave();
        await this.pull();
    }

    async updateState() {
        this._page = 0;
        this.count = 0;
        this.onFiltersSave()
        await this.pull();
    }

    get page() {
        return this._page;
    }

    set page(value: number) {
        this._page = value;
        this.onFiltersSave();
        this.pull().then();
    }

    get size() {
        return this._size;
    }

    set size(value: number) {
        this._size = value;
        this.onFiltersSave();
        this.pull().then();
    }

    async setOrder(orderBy: keyof T, order: ListSortDirection): Promise<void> {
        this._orderBy = orderBy;
        this._order = order;
        this._page = 0;
        this.onFiltersSave();
        await this.pull();
    }

    get orderBy() {
        return this._orderBy;
    }

    set orderBy(value: keyof T | undefined | string) {
        this._orderBy = value;
        this.onFiltersSave();
        this.pull().then();
    }

    get order() {
        return this._order;
    }

    get orderByAppolo(): SortEnumType {
        return this._order == "ascending" ? SortEnumType.Asc : SortEnumType.Desc;
    }

    set order(value: ListSortDirection) {
        this._order = value;
        this.onFiltersSave();
        this.pull().then();
    }

    onFiltersSave(): void {

    };

    pullItem(item: T) {
        this.items.unshift(item)
        this.items.pop()
    }
}

export const handleError400 = async (ex: any): Promise<Error400 | null> => {
    const res = (await ex.response.json()) as ProblemDetails;
    if (!res) return null;
    return new Error400(res);
};

export class Error400 {
    private _problemDetails: ProblemDetails;

    constructor(ex: ProblemDetails) {
        this._problemDetails = ex;
    }

    get title(): string {
        return this._problemDetails.title ?? '';
    }

    getFieldError(name: string): string {
        if (!this._problemDetails.errors || this._problemDetails.errors.length === 0) {
            return '';
        }
        return this._problemDetails.errors[name] ?? '';
    }
}

export const getOrganizationAddressHandler = (address: AddressUniqueDto) => {
    if (!address || !address.locality)
        return "";

    let hasCity: boolean = false;
    let result: string[] = [];

    const flattenObject = (obj: IAddress & { id: string }) => {
        if (obj.typeLocality.id === JurisdictionData.typeLocalityIds.cityId) {
            hasCity = true;
        }
        result.push(`${obj.typeLocality.nameRus} ${obj.nameRus}`)
        if (obj['parent'] && typeof obj['parent'] === 'object' && obj["parent"]["id"] !== obj["id"]) {
            flattenObject(obj['parent'] as IAddress & { id: string })
        }
    };
    flattenObject(address.locality)

    let house = address.house ? `, д. ${address.house}` : '';
    let building = address.building ? `, стр. ${address.building}` : '';

    if (!hasCity && (address?.cityId !== address?.localityId)) {
        let city = address.city?.nameRus ? `${address.city?.typeLocality?.nameRus?.toLowerCase() ?? 'г.'} ${address.city?.nameRus}, ` : '';
        return result.length == 1 ? `${city}${result[0]}${house}` :
            (city + result.reverse()
                .reduce((value, address, index) => value += `${address}` + (index == result.length - 1 ? "" : ", ")
                    , "") + house + building)
    }

    return result.length == 1 ? `${result[0]}${house}` :
        (result.reverse()
            .reduce((value, address, index) => value += `${address}` + (index == result.length - 1 ? "" : ", ")
                , "") + house + building)
}

export const getBrickAddressHandler = (address: BrickDto) => {
    let result: string[] = [];

    const flattenObject = (obj: IAddress & { id: string }) => {
        result.push(obj.nameRus)
        if (obj['parent'] && typeof obj['parent'] === 'object' && obj["parent"]["id"] !== obj["id"]) {
            flattenObject(obj['parent'] as IAddress & { id: string })
        }
    };
    flattenObject(address.locality);
    result.reverse();
    if (!!address.district) {
        result.push(address.district.name)
    }
    if (!!address.okrug) {
        result.push(address.okrug.name)
    }
    return result.length == 1 ? result[0] : result.join('/');
}

export const getStatusNameHandler = (status: PersonStatusDto | OrganizationStatusDto | null | undefined): string => {
    if (!status) return i18n.t('common.empty');
    return !!status.nameRus.length ? status.nameRus : i18n.t('common.working');
}

export const getPersonFullNameHandler = (entity: PersonDto): string => {

    let fio = entity?.surname?.nameRus + " " + entity?.firstName?.nameRus + " " + entity?.middleName?.nameRus;
    return fio;
}

export const emptyFieldHandler = (str: string | undefined): string => {
    return !str || !str.length ? i18n.t('dcrPage.empty') : str;
}

export const getOrgName = (x: OrganizationDto) => {
    return x?.names.find(e => e.typeName?.nameRus.toLowerCase() === 'синдикативное')?.name || x?.names[0]?.name;
}

export const searchStatusWorkOrgHandler = (items: OrganizationStatusDto[], filters: any): OrganizationStatusDto[] => {
    if (!!filters.search!) {
        let workStatus = {id: OrganizationStatuses.Work, nameRus: ''};
        items = (!!filters.search! && new RegExp(filters.search!, "i").test('работает'))
            ? [workStatus, ...items]
            : items
    }
    return items
} 
