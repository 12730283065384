import {makeAutoObservable} from 'mobx';
import {getApiConfiguration, ILoad} from "../../../../../../core";
import {FieldInfoDto, RequestDto} from "../../../../../../services/dcr";
import {
    AddressUniqueDto,
    AddressUniquesApi,
    OrganizationDto,
    OrganizationsApi,
    OrganizationsMappingsApi,
    TypeOrgDto,
} from "../../../../../../services/management";
import {RequestDetailsStore} from "../../RequestDetailsStore";
import {getOrganizationAddressHandler, getOrgName,} from "../../../../../utils";
import {t} from "i18next";
import {OrganizationField, RequestField} from "../../../types";
import {BaseFieldBuilder, DefaultBuilder, DoubleBuilderBuilder} from "../../builders/BaseFieldBuilder";
import {
    AdditionalNumberBuilder,
    ArchiveReasonnBuilder,
    ClinicCategoryBuilder,
    CodeBuilder,
    CompanyTypeBuilder,
    OriginalIdBuilder,
    ParentBuilder,
    PhoneBuilder,
    PhoneCategoryBuilder,
    PhoneStatusBuilder,
    PhoneTypeBuilder,
} from "../../builders/OrganizationBuilders";
import {CustomFieldStore} from "../../builders/fieldsStores/CustomFieldStore";
import {AddressStore} from "../../builders/fieldsStores/AddressStore";
import {FullAddressFields} from "../../../services";
import {JurisdictionData} from "../../../../../authorization/AuthorizationStore";

export class OrganizationStore implements ILoad {
    readonly _store: RequestDetailsStore;
    data: OrganizationDto = {} as OrganizationDto;
    addressDto: AddressUniqueDto = {} as AddressUniqueDto;
    loading: boolean = false;
    loaded: boolean = false;
    city: string = "";
    region: string = "";
    _customFieldStore: { [key in string]: CustomFieldStore<OrganizationStore> } = {}

    builders: { [key in string]: BaseFieldBuilder<OrganizationStore> } = {
        name: new DefaultBuilder(this),
        originalId: new OriginalIdBuilder(this),
        isDouble: new DoubleBuilderBuilder(this),
        archivereason: new ArchiveReasonnBuilder(this),
        additionalNumber: new AdditionalNumberBuilder(this),
        phonestatus: new PhoneStatusBuilder(this),
        phone1: new PhoneBuilder(this),
        code: new CodeBuilder(this),
        cliniccategory: new ClinicCategoryBuilder(this),
        companytype: new CompanyTypeBuilder(this),
        parent: new ParentBuilder(this),
        phonecategory: new PhoneCategoryBuilder(this),
        phonetype: new PhoneTypeBuilder(this),
    }

    get customFieldStore(): { [key in string]: CustomFieldStore<OrganizationStore> } {
        if (!this._customFieldStore['address'] && this._store.requestFields.find(x => FullAddressFields.indexOf(x.field!) > -1)) {
            this._customFieldStore['address'] = new AddressStore(this);
        }
        return this._customFieldStore;
    }

    get dto(): RequestDto {
        return this._store.dto;
    }

    constructor(store: RequestDetailsStore) {
        this._store = store;
        makeAutoObservable(this);
    }

    get address(): string {
        return !!this.data ? getOrganizationAddressHandler(this.addressDto) : ""
    }

    get hasParent(): boolean {
        return !!this.data.parentId && this.data.parentId != this.data.id
    }

    get numbers(): string[] {
        return this.data.phones.map(x => ({
            phone: !!x.phone?.phoneNumber ? x.phone.phoneNumber : t('dcrPage.empty'),
            status: !!x.phoneStatus?.nameRus ? x.phoneStatus.nameRus : t('dcrPage.empty'),
            type: !!x.phoneType?.nameRus ? x.phoneType.nameRus : t('dcrPage.empty'),
            category: !!x.phoneCategory?.nameRus ? x.phoneCategory.nameRus : t('dcrPage.empty'),
        })).map(x => `${x.phone} - ${x.type} - ${x.status} - ${x.category}`)
    }

    get currentName(): string {
        return this.builders['name']?.field?.store.value ?? getOrgName(this.data);
    }

    get currentType(): TypeOrgDto | undefined {
        return this.builders['companytype']?.field?.store.value ?? this.data?.typeOrg ?? undefined;
    }

    async changeAfterInitFields(fields: RequestField[]): Promise<void> {
        if (!!fields.find(x => x.field == "parent")) {
            const parentField = fields.find(x => x.field == "parent")!;
            const typeField = fields.find(x => x.field == "companytype")!;
            parentField.store.required = typeField ?
                typeField!.store.value ?
                    typeField!.store.value.parentId == JurisdictionData.organizationTypes.depsId : false : false;
        }
        let companytype = fields.find(x => x.field === "companytype");
        if (!!companytype) {
            const field = await this.builders['cliniccategory'].build({
                field: 'cliniccategory',
                value: "",
            } as FieldInfoDto);
            fields.push(field)
        }
    }

    get errorMessage(): string {
        return this.dto?.data360Id ?
            `Организация с Id: ${this.dto?.data360Id} - не найдена!` :
            this.dto?.externalId ?
                `Организация с ExternalId: ${this.dto?.externalId} - не найдена!` :
                this._store.notVerifiedCreateRequestId ? `Запрос на создание ${this._store.notVerifiedCreateRequestId} не верифицирован` :
                    'Не удалось найти запись в Data360'
    }

    async getEntityId(): Promise<string | undefined> {
        const api_ = new OrganizationsMappingsApi(getApiConfiguration());
        return (await api_.getOrganizationsMappings({
            crmId: this.dto.crmId,
            boundId: this.dto.boundId ?? undefined
        })).find((x) => x.entityId)?.entityId
    }

    async pull(): Promise<void> {
        this.loading = true;

        try {
            let originalId;
            const api = new OrganizationsApi(getApiConfiguration());
            const originalIdField = this._store.requestFields.find(x => x.field == "originalId");
            if(!!originalIdField){originalId = originalIdField?.value;}
            else{originalId = this._store.dto.data360Id;}

            if (this.dto.dcrStatus === 1) {
                this.data = this.dto.data360Id ?
                    await api.getOrganizationById({id: this.dto.data360Id}) :
                    this.dto.externalId ?
                        (await api.getOrganizations({searchByExternalId: this.dto.externalId.toString()}))[0] :
                        await api.getOrganizationById({id: await this.getEntityId() || ''});
            } else {
                this.data = this.dto.status == 7 ?
                    await api.getOrganizationById({id: originalId || ""}) :
                    this.dto.data360Id ?
                        await api.getOrganizationById({id: this.dto.data360Id}) :
                        (await api.getOrganizations({searchByExternalId: this.dto.externalId.toString()}))[0]
            }

            if (this.data.id) {
                this.addressDto = await new AddressUniquesApi(getApiConfiguration())
                    .getAddressUniqueById({
                        id: this.data.addressUniqueId
                    })
                this.customFieldStore['address']?.update(null);
            }

            if(this.data.parentId) {
                this.data.parent = await api.getOrganizationById({id: this.data.parentId})
            }

        } finally {
            this.loading = false;
            this.loaded = true;
        }
    }

    async generateField(element: FieldInfoDto): Promise<RequestField> {
        return await this.builders[<OrganizationField>element.field].build(element)
    }
}
