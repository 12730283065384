import {makeObservable, observable} from 'mobx';
import {PagedItems, TableLocalFilters, TableStore} from "../../../../utils";
import {getApiConfiguration, getObject, setObject} from "../../../../../core";
import {RoleItemStore} from "./RoleItemStore";
import {DefaultFormModalStore} from "../../../../../components/shared/DefaultFormModalStore";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";
import {t} from "i18next";
import {DefaultMultiSelectStore} from "../../../../../components/shared/DefaultMultiSelectStore";
import {
    GetRolesRequest,
    PermissionDto,
    PermissionsApi, RolePermissionsApi,
    RolesApi, RoleUsersApi, SetRolePermissionDto, SetUserRoleDto, UserDto, UsersApi
} from "../../../../../services/auth";

interface RoleStoreLocalFilters extends TableLocalFilters<RoleItemStore> {
}

export class RoleTableStore extends TableStore<RoleItemStore> {
    current: RoleItemStore | undefined | null;

    deleteDialogState: boolean = false;

    users: DefaultMultiSelectStore<UserDto> =
        new DefaultMultiSelectStore<UserDto>([], (filters) => new UsersApi(getApiConfiguration()).getUsers(filters),
            (e) => this.updateState().then());

    permissions: DefaultMultiSelectStore<PermissionDto> =
        new DefaultMultiSelectStore<PermissionDto>([], (filters) => new PermissionsApi(getApiConfiguration()).getPermissions(filters),
            (e) => this.updateState().then())

    creator: DefaultFormModalStore<{
        name: DefaultValueStore<string>,
        description: DefaultValueStore<string>,
        users: DefaultMultiSelectStore<UserDto>,
        permissions: DefaultMultiSelectStore<PermissionDto>
    }> = new DefaultFormModalStore({
        name: new DefaultValueStore<string>(""),
        description: new DefaultValueStore<string>(""),
        users: new DefaultMultiSelectStore<UserDto>([], (filters) => new UsersApi(getApiConfiguration()).getUsers(filters)),
        permissions: new DefaultMultiSelectStore<PermissionDto>([], (filters) => new PermissionsApi(getApiConfiguration()).getPermissions(filters)),
    }, this.create.bind(this))

    constructor() {
        super();
        this.creator.afterSubmit = this.updateState.bind(this)
        this.creator.fields.name.required = true;
        this.creator.fields.name.validatorFuncAsync = async (e) => {
            try {
                const exists = await new RolesApi(getApiConfiguration({errorTooltip: false})).getRoleByName({name: this.creator.model.name});
                return exists ? t("errors.exists") : "";
            } finally {
            }
            return "";
        }
        const filters = getObject<RoleStoreLocalFilters>('role-store');
        if (filters) {
            this._page = filters.page;
            this._size = filters.size;
            this.search.setValueWithoutEffects(filters.search);
            this._order = filters.order;
            this._orderBy = filters.orderBy;
        }
        makeObservable(this, {
            current: observable,
            deleteDialogState: observable
        });
    }

    async getRole(id: string): Promise<RoleItemStore | undefined | null> {
        if (this.current && this.current.dto.id === id) {
            return this.current;
        }
        const existItem = this.items.find(t => t.dto.id === id);
        if (existItem) {
            return this.current = existItem;
        }
        const api = new RolesApi(getApiConfiguration());
        const result = await api.getRoleById({id: id});
        if (result) {
            return this.current = new RoleItemStore(this, result);
        }
        return undefined;
    };

    onFiltersSave() {
        setObject<RoleStoreLocalFilters>('role-store', {
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            page: this.page,
            size: this.size
        });
    }

    async create(model: any): Promise<void> {
        const result = await new RolesApi(getApiConfiguration({successTooltip: true})).createRole({
            setRoleDto: {
                name: model.name,
                description: model.description
            }
        })
        const userIds: SetUserRoleDto[] = model.users?.map((x: UserDto) => ({
            roleId: result.id,
            userId: x.id
        }));
        if (userIds)
            await new RoleUsersApi(getApiConfiguration({successTooltip: false})).createRoleUsers({
                roleId: result.id,
                setUserRoleDto: userIds
            })
        const permissionIds: SetRolePermissionDto[] = model.permissions?.map((x: PermissionDto) => ({
            roleId: result.id,
            permissionId: x.id
        }))
        if (permissionIds)
            await new RolePermissionsApi(getApiConfiguration({successTooltip: false})).createRolePermissions({
                roleId: result.id,
                setRolePermissionDto: permissionIds
            })
    };

    async delete(): Promise<void> {
        const result = await new RolesApi(getApiConfiguration()).deleteRole({id: this.current!.dto.id});
        this.items = this.items.filter(t => t.dto.id !== result.id);
        for (const t of this.items) {
            if (t.dto.id === result.id) {
                this.current = null;
            }
        }
    };

    async request(): Promise<PagedItems<RoleItemStore>> {
        const filters = {
            page: this.page + 1,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order == "ascending" ? 1 : 0,
            orderBy: this.orderBy,
            users: this.users.value.length > 0 ? this.users.value?.map(x => x.id) : undefined,
            permissions: this.permissions.value.length > 0 ? this.permissions.value.map(x => x.id) : undefined,
        } as GetRolesRequest;
        const api: RolesApi = new RolesApi(getApiConfiguration());
        const result = await api.getRoles(filters);
        const count = await api.getRolesCount(filters);
        const newList: RoleItemStore[] = [];
        for (const item of result) {
            const existsItem = this.items.find(t => t.dto.id === item.id);
            if (existsItem) {
                existsItem.update(item);
                newList.push(existsItem);
                continue;
            }
            newList.push(new RoleItemStore(this, item))
        }

        return new PagedItems<RoleItemStore>(newList, count.count)
    }
}
