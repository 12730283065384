/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Api.Import
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {ExportImportType, ImportFile, ImportFileFromJSON,} from '../models';

export interface DeleteImportEntryRequest {
    fileId?: string;
}

export interface DownloadAfterImportFileRequest {
    fileId?: string;
}

export interface DownloadPreImportReportRequest {
    fileId?: string;
}

export interface EditFileNameRequest {
    fileId?: string;
    newFileName?: string;
}

export interface GetDocumentTemplateRequest {
    importType?: ExportImportType;
}

export interface GetImportEntriesRequest {
    userId?: string;
    importType?: ExportImportType;
    page?: number;
    size?: number;
}

export interface GetImportEntriesCountRequest {
    userId?: string;
    importType?: ExportImportType;
}

/**
 *
 */
export class ImportManagementApi extends runtime.BaseAPI {

    /**
     */
    async deleteImportEntryRaw(requestParameters: DeleteImportEntryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.fileId !== undefined) {
            queryParameters['fileId'] = requestParameters.fileId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ImportManagement/v1/DeleteImportEntry`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteImportEntry(requestParameters: DeleteImportEntryRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteImportEntryRaw(requestParameters, initOverrides);
    }

    /**
     */
    async downloadAfterImportFileRaw(requestParameters: DownloadAfterImportFileRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<any> {
        const queryParameters: any = {};

        if (requestParameters.fileId !== undefined) {
            queryParameters['fileId'] = requestParameters.fileId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ImportManagement/v1/DownloadAfterImportFile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return response;
    }

    /**
     */
    async downloadAfterImportFile(requestParameters: DownloadAfterImportFileRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<any> {
        return await this.downloadAfterImportFileRaw(requestParameters, initOverrides);
    }

    /**
     */
    async downloadPreImportReportRaw(requestParameters: DownloadPreImportReportRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<any> {
        const queryParameters: any = {};

        if (requestParameters.fileId !== undefined) {
            queryParameters['fileId'] = requestParameters.fileId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ImportManagement/v1/DownloadPreImportReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return response;
    }

    /**
     */
    async downloadPreImportReport(requestParameters: DownloadPreImportReportRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<any> {
        return await this.downloadPreImportReportRaw(requestParameters, initOverrides);
    }

    /**
     */
    async editFileNameRaw(requestParameters: EditFileNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.fileId !== undefined) {
            queryParameters['fileId'] = requestParameters.fileId;
        }

        if (requestParameters.newFileName !== undefined) {
            queryParameters['newFileName'] = requestParameters.newFileName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ImportManagement/v1/EditFileName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async editFileName(requestParameters: EditFileNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.editFileNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDocumentTemplateRaw(requestParameters: GetDocumentTemplateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<any> {
        const queryParameters: any = {};

        if (requestParameters.importType !== undefined) {
            queryParameters['importType'] = requestParameters.importType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ImportManagement/v1/GetDocumentTemplate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return response;
    }

    /**
     */
    async getDocumentTemplate(requestParameters: GetDocumentTemplateRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<any> {
        return await this.getDocumentTemplateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getImportEntriesRaw(requestParameters: GetImportEntriesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ImportFile>>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.importType !== undefined) {
            queryParameters['importType'] = requestParameters.importType;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ImportManagement/v1/GetImportEntries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ImportFileFromJSON));
    }

    /**
     */
    async getImportEntries(requestParameters: GetImportEntriesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ImportFile>> {
        const response = await this.getImportEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getImportEntriesCountRaw(requestParameters: GetImportEntriesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.importType !== undefined) {
            queryParameters['importType'] = requestParameters.importType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ImportManagement/v1/GetImportEntriesCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getImportEntriesCount(requestParameters: GetImportEntriesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<number> {
        const response = await this.getImportEntriesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
