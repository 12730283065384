import { Button } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export interface Props {
  snackbarKey: SnackbarKey,
}

export function SnackbarCloseButton({ snackbarKey }: Props ) {
  const { closeSnackbar } = useSnackbar();

  return (
    <Button
      onClick={() => closeSnackbar(snackbarKey)}
      sx={{
        height: 40,
        width: 40,
        color: '#fff'
      }}
    >
      <CancelOutlinedIcon />
    </Button>
  )
}