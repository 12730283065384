import {makeAutoObservable} from 'mobx';
import {DefaultValueStore} from "../../../../components/shared/DefaultValueStore";
import {IProcessing} from "../../../../core";
import {DefaultSelectStore} from '../../../../components/shared/DefaultSelectStore';
import {
    ClientFragment,
    ClientType,
    SetDcrSettingsClientMutation,
    SetDcrSettingsClientMutationVariables
} from "../../../../gql/graphql";
import {getAppoloClient, GRAPHQL_URLS} from "../../../../graphql";
import {SET_DCR_SETTINGS_CLIENT_MUTATION} from "../../../../graphql/mutations.graphql";
import {DefaultFormModalStore} from "../../../../components/shared/DefaultFormModalStore";
import {ClientStore} from "../client/ClientStore";

export class ClientDcrSettingsStore implements IProcessing {
    readonly _store: ClientStore;
    private _dto: ClientFragment;
    processing: boolean = false;

    details: DefaultFormModalStore<{
        disabled: DefaultValueStore<boolean>,
        host: DefaultValueStore<string>,
        login: DefaultValueStore<string>
        password: DefaultValueStore<string>,
        clientType: DefaultSelectStore<ClientType>
    }>

    constructor(store: ClientStore, dto: ClientFragment) {
        this._store = store;
        this._dto = dto;

        this.details = new DefaultFormModalStore({
            disabled: new DefaultValueStore<boolean>(dto.settings?.dcrSetting?.disabled ?? false, null, x => {
                this.details.fields.host.required = !x;
                this.details.fields.login.required = !x;
                this.details.fields.clientType.required = !x;
                this.details.validate()
            }),
            host: new DefaultValueStore<string>(dto.settings?.dcrSetting?.host ?? ""),
            login: new DefaultValueStore<string>(dto.settings?.dcrSetting?.login ?? ""),
            password: new DefaultValueStore<string>(dto.settings?.dcrSetting?.password ?? ""),
            clientType: new DefaultSelectStore<ClientType>(dto.settings?.dcrSetting?.clientType ?? null,
                [ClientType.Api, ClientType.Crm, ClientType.File]),
        }, async (e) => {
            await this.submit()
        })

        this.details.fields.host.required = !dto.settings?.dcrSetting?.disabled;
        this.details.fields.login.required = !dto.settings?.dcrSetting?.disabled;
        this.details.fields.clientType.required = !dto.settings?.dcrSetting?.disabled;

        makeAutoObservable(this);
    }


    update(dto: ClientFragment) {
        this._dto = dto;
        this.details.fields.disabled.update(dto.settings?.dcrSetting?.disabled)
        this.details.fields.host.update(dto.settings?.dcrSetting?.host)
        this.details.fields.login.update(dto.settings?.dcrSetting?.login)
        this.details.fields.password.update(dto.settings?.dcrSetting?.password)
        this.details.fields.clientType.update(dto.settings?.dcrSetting?.clientType!)
    }

    async submit(): Promise<void> {
        this.details.validate()

        if (this.details.valid) {
            await getAppoloClient(GRAPHQL_URLS.CLIENTS).mutate<SetDcrSettingsClientMutation, SetDcrSettingsClientMutationVariables>({
                mutation: SET_DCR_SETTINGS_CLIENT_MUTATION, variables: {
                    clientId: this._dto.id,
                    disabled: !!this.details.fields.disabled.value,
                    host: this.details.fields.host.value!,
                    login: this.details.fields.login.value!,
                    password: this.details.fields.password.value ?? this._dto.settings?.dcrSetting?.password ?? "",
                    clientType: this.details.fields.clientType.value!,
                }
            })
            await this._store._store._store.getClient(this._dto.id)
        }
    };
}
