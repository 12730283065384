import { createContext } from "react";
import { useContext } from "react";
import { useLocalStore } from 'mobx-react'
import { RootStore } from "./RootStore";

const rootStore = new RootStore();
export const StoreContext = createContext<RootStore>(rootStore);
export const useRootStore = () => useContext(StoreContext);
export const useManagementStore = () => rootStore.managementStore;
export const useAuthorizationStore = () => rootStore.authorizationStore;
export const useLocalizationStore = () => rootStore.localizationStore;
export const useSignalRStore = () => rootStore.signalRStore;

export const refreshTokenFunc = async () => rootStore.authorizationStore.refresh();
export const logout = async () => rootStore.authorizationStore.logout();

export const StoreProvider = ({ children }: any) => {
    const store: RootStore = useLocalStore(() => rootStore);

    return (
        <StoreContext.Provider value={store}>
            {children}
        </StoreContext.Provider>
    );
}
