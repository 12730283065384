/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    FirstNameDto,
    FirstNameDtoFromJSON,
    FirstNameDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OCMObjectState,
    OCMObjectStateFromJSON,
    OCMObjectStateToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetFirstNameDto,
    SetFirstNameDtoFromJSON,
    SetFirstNameDtoToJSON,
} from '../models';

export interface CreateFirstNameRequest {
    setFirstNameDto: SetFirstNameDto;
}

export interface DeleteFirstNameRequest {
    id: string;
}

export interface GetFirstNameByIdRequest {
    id: string;
}

export interface GetFirstNameByNameRequest {
    name: string;
}

export interface GetFirstNameBySearchRequest {
    search?: string;
}

export interface GetFirstNamesRequest {
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface GetFirstNamesCountRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface GetFirstNamesCountFallbackRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface GetFirstNamesFallbackRequest {
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface UpdateFirstNameRequest {
    id: string;
    setFirstNameDto: SetFirstNameDto;
}

/**
 * 
 */
export class FirstNamesApi extends runtime.BaseAPI {

    /**
     */
    async createFirstNameRaw(requestParameters: CreateFirstNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FirstNameDto>> {
        if (requestParameters.setFirstNameDto === null || requestParameters.setFirstNameDto === undefined) {
            throw new runtime.RequiredError('setFirstNameDto','Required parameter requestParameters.setFirstNameDto was null or undefined when calling createFirstName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/persons/firstnames`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetFirstNameDtoToJSON(requestParameters.setFirstNameDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FirstNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async createFirstName(requestParameters: CreateFirstNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FirstNameDto> {
        const response = await this.createFirstNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteFirstNameRaw(requestParameters: DeleteFirstNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FirstNameDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteFirstName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/firstnames/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FirstNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteFirstName(requestParameters: DeleteFirstNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FirstNameDto> {
        const response = await this.deleteFirstNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFirstNameByIdRaw(requestParameters: GetFirstNameByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FirstNameDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getFirstNameById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/firstnames/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FirstNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async getFirstNameById(requestParameters: GetFirstNameByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FirstNameDto> {
        const response = await this.getFirstNameByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFirstNameByNameRaw(requestParameters: GetFirstNameByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FirstNameDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getFirstNameByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/firstnames/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FirstNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async getFirstNameByName(requestParameters: GetFirstNameByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FirstNameDto> {
        const response = await this.getFirstNameByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFirstNameBySearchRaw(requestParameters: GetFirstNameBySearchRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<FirstNameDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/firstnames/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FirstNameDtoFromJSON));
    }

    /**
     */
    async getFirstNameBySearch(requestParameters: GetFirstNameBySearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<FirstNameDto>> {
        const response = await this.getFirstNameBySearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFirstNamesRaw(requestParameters: GetFirstNamesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<FirstNameDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/firstnames`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FirstNameDtoFromJSON));
    }

    /**
     */
    async getFirstNames(requestParameters: GetFirstNamesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<FirstNameDto>> {
        const response = await this.getFirstNamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFirstNamesCountRaw(requestParameters: GetFirstNamesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/firstnames/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getFirstNamesCount(requestParameters: GetFirstNamesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getFirstNamesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFirstNamesCountFallbackRaw(requestParameters: GetFirstNamesCountFallbackRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/firstnames/count/fallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getFirstNamesCountFallback(requestParameters: GetFirstNamesCountFallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getFirstNamesCountFallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFirstNamesFallbackRaw(requestParameters: GetFirstNamesFallbackRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<FirstNameDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/firstnames/fallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FirstNameDtoFromJSON));
    }

    /**
     */
    async getFirstNamesFallback(requestParameters: GetFirstNamesFallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<FirstNameDto>> {
        const response = await this.getFirstNamesFallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateFirstNameRaw(requestParameters: UpdateFirstNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FirstNameDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateFirstName.');
        }

        if (requestParameters.setFirstNameDto === null || requestParameters.setFirstNameDto === undefined) {
            throw new runtime.RequiredError('setFirstNameDto','Required parameter requestParameters.setFirstNameDto was null or undefined when calling updateFirstName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/persons/firstnames/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetFirstNameDtoToJSON(requestParameters.setFirstNameDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FirstNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateFirstName(requestParameters: UpdateFirstNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FirstNameDto> {
        const response = await this.updateFirstNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
