import {makeObservable, observable} from 'mobx';
import {GroupApi} from "../../../../../services/dcr";
import {PagedItems, TableLocalFilters, TableStore} from '../../../../utils';
import {GroupItemStore} from './GroupItemStore';
import {getApiConfiguration, getObject, setObject} from '../../../../../core';
import {UsersPageStore} from "../../UsersPageStore";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from "../../../../../components/shared/DefaultSelectStore";
import {UserDto, UsersApi} from "../../../../../services/management";
import {DefaultMultiSelectStore} from "../../../../../components/shared/DefaultMultiSelectStore";
import {DefaultFormModalStore} from "../../../../../components/shared/DefaultFormModalStore";

interface DCRGroupStoreLocalFilters extends TableLocalFilters<GroupItemStore> {
}

export class GroupTableStore extends TableStore<GroupItemStore> {
    readonly creator: DefaultFormModalStore<{
        name: DefaultValueStore<string>,
        owner: DefaultSelectStore<UserDto>,
        participants: DefaultMultiSelectStore<UserDto>
    }> = new DefaultFormModalStore({
        name: new DefaultValueStore<string>(""),
        participants: new DefaultMultiSelectStore([],
            (x) => new UsersApi(getApiConfiguration()).getUsers(x)),
        owner: new DefaultSelectStore(null,
            (x) => new UsersApi(getApiConfiguration()).getUsers(x))
    }, this.submit.bind(this));

    readonly store: UsersPageStore;
    deleteDialogState: boolean = false;
    current?: GroupItemStore | null;

    constructor(store: UsersPageStore) {
        super();
        this.store = store;
        this.creator.afterSubmit = this.pull.bind(this);
        if (this.store.store.store.authorizationStore.isAuthenticated)
            this.creator.fields.owner.value = {
                name: this.store.store.store.authorizationStore.username,
                id: this.store.store.store.authorizationStore.id,
            } as UserDto

        const filters = getObject<DCRGroupStoreLocalFilters>('dcrGroups-store');
        if (filters) {
            this._page = filters.page;
            this._size = filters.size;
            this.search.setValueWithoutEffects(filters.search);
            this._order = filters.order;
            this._orderBy = filters.orderBy;
        }
        makeObservable(this, {
            current: observable,
            deleteDialogState: observable,
        });
    }

    async request(): Promise<PagedItems<GroupItemStore>> {
        const filters = {
            page: this.page + 1,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy
        };
        const api: GroupApi = new GroupApi(getApiConfiguration());
        let {items, total} = await api.getGroups(filters);
        const newList: GroupItemStore[] = [];
        for (const item of items!) {
            const existsItem = this.items.find(t => t.dto.id === item.id);
            if (existsItem) {
                existsItem.dto = item;
                newList.push(existsItem);
                continue;
            }
            newList.push(new GroupItemStore(this, item))
        }
        return new PagedItems<GroupItemStore>(newList, total)
    };

    onFiltersSave() {
        setObject<DCRGroupStoreLocalFilters>('dcrGroups-store', {
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            page: this.page,
            size: this.size
        });
    }

    async save(item: GroupItemStore): Promise<void> {
        const result = await new GroupApi(getApiConfiguration() as any).updateGroup({dcrGroupDto: item.model});
        await item.update(result);
    };

    async delete(id: string): Promise<void> {
        await new GroupApi(getApiConfiguration() as any).deleteGroup({id: id});
    };

    async submit(): Promise<void> {
        await new GroupApi(getApiConfiguration() as any).createGroup({
            setDcrGroupDto: {
                name: this.creator.model.name!,
                ownerId: this.creator.model.owner.id!,
                users: this.creator.model.participants?.map((x: any) => x.id),
            }
        });
    };
}
