/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetOrganizationEmailDto
 */
export interface SetOrganizationEmailDto {
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationEmailDto
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationEmailDto
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationEmailDto
     */
    emailStatusId: string;
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationEmailDto
     */
    emailTypeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationEmailDto
     */
    description?: string | null;
}

export function SetOrganizationEmailDtoFromJSON(json: any): SetOrganizationEmailDto {
    return SetOrganizationEmailDtoFromJSONTyped(json, false);
}

export function SetOrganizationEmailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetOrganizationEmailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailAddress': json['emailAddress'],
        'organizationId': json['organizationId'],
        'emailStatusId': json['emailStatusId'],
        'emailTypeId': !exists(json, 'emailTypeId') ? undefined : json['emailTypeId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function SetOrganizationEmailDtoToJSON(value?: SetOrganizationEmailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailAddress': value.emailAddress,
        'organizationId': value.organizationId,
        'emailStatusId': value.emailStatusId,
        'emailTypeId': value.emailTypeId,
        'description': value.description,
    };
}

