/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CreatePersonTagDto,
    CreatePersonTagDtoToJSON,
    TagDto,
    TagDtoFromJSON,
    UpdatePersonTagDto,
    UpdatePersonTagDtoToJSON,
} from '../models';

export interface CreatePersonTagRequest {
    createPersonTagDto?: CreatePersonTagDto;
}

export interface DeletePersonTagRequest {
    id: string;
}

export interface GetPersonTagsRequest {
    name?: string;
    page?: number;
    pageSize?: number;
}

export interface GetPersonTagsCountRequest {
    name?: string;
}

export interface UpdatePersonTagRequest {
    id: string;
    updatePersonTagDto?: UpdatePersonTagDto;
}

/**
 * 
 */
export class PersonTagsApi extends runtime.BaseAPI {

    /**
     */
    async createPersonTagRaw(requestParameters: CreatePersonTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TagDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/persons/tags`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePersonTagDtoToJSON(requestParameters.createPersonTagDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagDtoFromJSON(jsonValue));
    }

    /**
     */
    async createPersonTag(requestParameters: CreatePersonTagRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TagDto> {
        const response = await this.createPersonTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePersonTagRaw(requestParameters: DeletePersonTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TagDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePersonTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagDtoFromJSON(jsonValue));
    }

    /**
     */
    async deletePersonTag(requestParameters: DeletePersonTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TagDto> {
        const response = await this.deletePersonTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonTagsRaw(requestParameters: GetPersonTagsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<TagDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TagDtoFromJSON));
    }

    /**
     */
    async getPersonTags(requestParameters: GetPersonTagsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<TagDto>> {
        const response = await this.getPersonTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonTagsCountRaw(requestParameters: GetPersonTagsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/tags/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonTagsCount(requestParameters: GetPersonTagsCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPersonTagsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePersonTagRaw(requestParameters: UpdatePersonTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TagDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePersonTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/persons/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePersonTagDtoToJSON(requestParameters.updatePersonTagDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagDtoFromJSON(jsonValue));
    }

    /**
     */
    async updatePersonTag(requestParameters: UpdatePersonTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TagDto> {
        const response = await this.updatePersonTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
