import { Box, Breadcrumbs, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
    AuthorizePermission,
    Page,
    PageTab,
    PageTabs,
} from '../../../components';
import { AllPermissions, SectionTypeDictionary } from '../../../core/const';
import { useManagementStore } from '../../../stores';
import { DictionaryTable } from '../DictionaryTable';

export const DictionariesPersons = observer(() => {
    const { dictionaryPersonStore } = useManagementStore();
    const { t } = useTranslation();
    const renderTabs = (index: number) => {
        switch (index) {
            case 0:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.person.firstname.read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryPersonStore.nameStore}
                            sectionType={SectionTypeDictionary.firstname}
                            dictionaryType={
                                AllPermissions.dictionary.person.firstname
                            }
                            dictionaryName={'name'}
                        />
                    </AuthorizePermission>
                );
            case 1:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.person.middlename.read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryPersonStore.MiddleNameTableStore}
                            sectionType={SectionTypeDictionary.middlename}
                            dictionaryName={'middleName'}
                            dictionaryType={
                                AllPermissions.dictionary.person.middlename
                            }
                        />
                    </AuthorizePermission>
                );
            case 2:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.person.surname.read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryPersonStore.surnameStore}
                            sectionType={SectionTypeDictionary.surname}
                            dictionaryName={'surname'}
                            dictionaryType={
                                AllPermissions.dictionary.person.surname
                            }
                        />
                    </AuthorizePermission>
                );
            case 3:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.person.gender.read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryPersonStore.genderStore}
                            dictionaryName={'gender'}
                            dictionaryType={
                                AllPermissions.dictionary.person.gender
                            }
                            sectionType={SectionTypeDictionary.gender}
                        />
                    </AuthorizePermission>
                );
            case 4:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.person.speciality.read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryPersonStore.specialityStore}
                            sectionType={SectionTypeDictionary.speciality}
                            dictionaryName={'speciality'}
                            dictionaryType={
                                AllPermissions.dictionary.person.speciality
                            }
                        />
                    </AuthorizePermission>
                );
            case 5:
                return (
                    <AuthorizePermission
                        permissions={AllPermissions.dictionary.person.post.read}
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryPersonStore.positionTableStore}
                            sectionType={SectionTypeDictionary.post}
                            dictionaryName={'position'}
                            dictionaryType={
                                AllPermissions.dictionary.person.post
                            }
                        />
                    </AuthorizePermission>
                );
            case 6:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.person.phonestatus.read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryPersonStore.phoneStatus}
                            sectionType={SectionTypeDictionary.phonestatus}
                            dictionaryName={'phoneStatus'}
                            dictionaryType={
                                AllPermissions.dictionary.person.phonestatus
                            }
                        />
                    </AuthorizePermission>
                );
            case 7:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.person.phonetype.read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryPersonStore.phoneType}
                            sectionType={SectionTypeDictionary.phonetype}
                            dictionaryName={'phoneType'}
                            dictionaryType={
                                AllPermissions.dictionary.person.phonetype
                            }
                        />
                    </AuthorizePermission>
                );
            case 8:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.person.phonecategory.read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryPersonStore.phoneCategory}
                            sectionType={SectionTypeDictionary.phonecategory}
                            dictionaryName={'phoneCategory'}
                            dictionaryType={
                                AllPermissions.dictionary.person.phonecategory
                            }
                        />
                    </AuthorizePermission>
                );
            case 9:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.person.emailstatus.read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryPersonStore.emailStatus}
                            sectionType={SectionTypeDictionary.emailstatus}
                            dictionaryName={'emailStatus'}
                            dictionaryType={
                                AllPermissions.dictionary.person.emailstatus
                            }
                        />
                    </AuthorizePermission>
                );
            case 10:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.person.emailtype.read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryPersonStore.emailType}
                            sectionType={SectionTypeDictionary.emailtype}
                            dictionaryType={
                                AllPermissions.dictionary.person.emailtype
                            }
                            dictionaryName="emailtype"
                        />
                    </AuthorizePermission>
                );
            case 11:
                return (
                    <AuthorizePermission
                        permissions={
                            AllPermissions.dictionary.person.websitestatus.read
                        }
                        showWarning
                    >
                        <DictionaryTable
                            store={dictionaryPersonStore.websiteStatus}
                            sectionType={SectionTypeDictionary.websitestatus}
                            dictionaryName={'websiteStatus'}
                            dictionaryType={
                                AllPermissions.dictionary.person.websitestatus
                            }
                        />
                    </AuthorizePermission>
                );
        }
    };
    return (
        <Box>
            <>
                <Box>
                    <Breadcrumbs separator="/" sx={{ mt: 1 }}>
                        <Typography color="primary" variant="h6">
                            {t('pageNames.dictionaries')}
                        </Typography>

                        <Typography color="textSecondary" variant="h6">
                            {t('pageNames.persons')}
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <PageTabs
                    index={dictionaryPersonStore.tabIndex}
                    onChange={(t) => {
                        dictionaryPersonStore.tabIndex = t;
                    }}
                    multipleLines
                >
                    <PageTab
                        label={t('dictionary.tabNames.names')}
                        testid="dictionariesPersons-names-tabs"
                        style={{ minWidth: 150 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.middleNames')}
                        testid="dictionariesPersons-middleNames-tabs"
                        style={{ minWidth: 150 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.surnames')}
                        testid="dictionariesPersons-surnames-tabs"
                        style={{ minWidth: 150 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.genders')}
                        testid="dictionariesPersons-genders-tabs"
                        style={{ minWidth: 150 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.specialities')}
                        testid="dictionariesPersons-specialities-tabs"
                        style={{ minWidth: 150 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.positions')}
                        testid="dictionariesPersons-positions-tabs"
                        style={{ minWidth: 150 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.phoneStatus')}
                        testid="dictionariesPersons-phoneStatus-tabs"
                        style={{ minWidth: 150 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.phoneType')}
                        testid="dictionariesPersons-phoneType-tabs"
                        style={{ minWidth: 150 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.phoneCategories')}
                        testid="dictionariesPersons-phoneCategories-tabs"
                        style={{ minWidth: 150 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.emailStatus')}
                        testid="dictionariesPersons-emailStatus-tabs"
                        style={{ minWidth: 150 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.typeEmails')}
                        testid="dictionariesPersons-typeEmails-tabs"
                        style={{ minWidth: 150 }}
                    />
                    <PageTab
                        label={t('dictionary.tabNames.websiteStatus')}
                        testid="dictionariesPersons-websiteStatus-tabs"
                        style={{ minWidth: 150 }}
                    />
                </PageTabs>
                <Page style={{ flex: 1, width: '100%', height: '100%' }}>
                    {renderTabs(dictionaryPersonStore.tabIndex)}
                </Page>
            </>
        </Box>
    );
});
