import {makeAutoObservable} from "mobx";
import {AnalyticsPageStore} from "../../AnalyticsPageStore";
import {DefaultSelectStore} from "../../../../../components/shared/DefaultSelectStore";
import {ILoad} from "../../../../../core";
import {
    AnalyticsApi,
    CountInfoDto,
    Interval,
    RequestsAggregationDto,
    RequestsByDateDto,
    RequestsByResponsibilityDto,
    RequestsClientsStatusDto,
    RequestStatus,
} from "../../../../../services/analytics/dcr";
import {t} from "i18next";
import {debounce, DebouncedFunc} from "lodash";
import {LineChartData} from "../../../../../components/charts/line/LineChart";
import {AnalyticsDateFiltersStore} from "../../AnalyticsDateFiltersStore";
import {ClientApi, ClientDto} from "../../../../../services/dcr";
import {UserDto} from "../../../../../services/management";
import {DCRStatusEnum, RequestStatusEnum, RequestTypeEnum} from "../../../dcr/types";

export class DcrDashboardsStore implements ILoad {
    store: AnalyticsPageStore;

    statusCards = [
        {name: RequestStatus.WaitingVerification, values: [RequestStatus.WaitingVerification], tableValues: [RequestStatusEnum.WaitingVerification]},
        {name: RequestStatus.OnVerification, values: [RequestStatus.OnVerification], tableValues: [RequestStatusEnum.OnVerification]},
        {
            name: RequestStatus.Verified,
            values: [RequestStatus.Verified, RequestStatus.Partialy],
            tableValues: [RequestStatusEnum.Verified, RequestStatusEnum.Partialy],
        },
        {
            name: RequestStatus.Decline,
            values: [RequestStatus.Decline, RequestStatus.NotVerified],
            tableValues: [RequestStatusEnum.Decline, RequestStatusEnum.NotVerified]
        },
        {name: RequestStatus.Double, values: [RequestStatus.Double], tableValues: [RequestStatusEnum.Double]},
        {name: "Error", values: ["Error"], color: "error", tableValues: []},
    ]
    private readonly debounceRequest: DebouncedFunc<any> = debounce(this.request.bind(this), 800)

    readonly client: DefaultSelectStore<ClientDto> = new DefaultSelectStore<ClientDto>(null,
        (e) => new ClientApi().apiDcrV1ClientsGet(e),
        null,
        "name",
        "name")

    dateFilters: AnalyticsDateFiltersStore = new AnalyticsDateFiltersStore(this);

    loading: boolean = false;

    clientsCount: CountInfoDto | null = null;
    requestsCount: CountInfoDto | null = null;
    speedCount: CountInfoDto | null = null;
    statusesCount: RequestsAggregationDto[] = [];
    personRequestsCountByClient: RequestsClientsStatusDto[] = [];
    organizationRequestsCountByClient: RequestsClientsStatusDto[] = [];
    personRequestsByClientDate: RequestsByDateDto[] = [];
    organizationRequestsByClientDate: RequestsByDateDto[] = [];
    requestsByClientsResponsibility: RequestsByResponsibilityDto[] = []
    requestByDate: RequestsByDateDto[] = [];
    speedCountByDate: RequestsByDateDto[] = [];
    countWithMoreRequestsByPerson: RequestsAggregationDto[] = [];
    countWithMoreRequestsByOrganization: RequestsAggregationDto[] = [];

    constructor(store: AnalyticsPageStore) {
        this.store = store;
        this.client.onChanged.push(async (e) => await this.requestClient())
        makeAutoObservable(this);
    }

    saveReportFilters(prop: {
        status?: RequestStatusEnum[],
        entity?: RequestTypeEnum[],
        type?: DCRStatusEnum[],
        client?: ClientDto,
        isError?: boolean,
        responsibility?: string
    }) {
        this.store.store.dcrRequestStore.resetFilters();
        this.store.store.dcrRequestStore.startDate.setValueWithoutEffects(this.dateFilters.startDate.value!)
        this.store.store.dcrRequestStore.endDate.setValueWithoutEffects(this.dateFilters.endDate.value!)
        this.store.store.dcrRequestStore.requestStatus.setValueWithoutEffects(prop.status ? prop.status.map(x => ({
            value: String(x),
            label: ""
        })) : [])
        this.store.store.dcrRequestStore.requestRecordType.setValueWithoutEffects(prop.entity ? prop.entity.map(x => ({
            value: String(x),
            label: ""
        })) : [])
        this.store.store.dcrRequestStore.dcrStatus.setValueWithoutEffects(prop.type ? prop.type.map(x => ({
            value: String(x),
            label: ""
        })) : [])

        if (prop.client) {
            this.store.store.dcrRequestStore.client.setValueWithoutEffects([prop.client])
        }
        if (prop.responsibility) {
            this.store.store.dcrRequestStore.responsible.setValueWithoutEffects([{
                id: prop.responsibility,
                nameRus: ''
            } as UserDto])
        }
        if (prop.isError) {
            this.store.store.dcrRequestStore.correctRequest.setValueWithoutEffects({
                value: '0',
                label: ''
            })
        }
    }

    get valid(): boolean {
        return this.dateFilters.valid;
    }

    get statusesCard(): { name: string, count: number, values: RequestStatusEnum[] }[] {
        return this.statusCards.map(x => ({
            name: x.name,
            count: this.statusesCount.find(f => x.values.indexOf(f.name! as never) > -1)?.count ?? 0,
            values: x.tableValues
        }))
    }

    get interval(): Interval {
        if (this.dateFilters.interval == "1w") {
            return 'day'
        } else if (this.dateFilters.interval == "1M") {
            return 'month'
        } else if (this.dateFilters.interval == "1d") {
            return 'day'
        } else return 'hour'
    }

    get requestsByClientRangeData(): LineChartData[] {
        return [
            {
                name: t("pageNames.persons"),
                data: this.personRequestsByClientDate ? this.dateFilters.formattingData(this.personRequestsByClientDate?.map(x => ({
                    key: x.date!,
                    count: x.count!
                }))) : []
            },
            {
                name: t("pageNames.organizations"),
                data: this.organizationRequestsByClientDate ? this.dateFilters.formattingData(this.organizationRequestsByClientDate?.map(x => ({
                    key: x.date!,
                    count: x.count!
                }))) : []
            }
        ]
    }

    get requestByDateData(): number[] {
        return this.dateFilters.formattingData(this.requestByDate?.map(x => ({key: x.date!, count: x.count!}) ?? []));
    }

    get speedCountByDateData(): number[] {
        return this.dateFilters.formattingData(this.speedCountByDate?.map(x => ({
            key: x.date!,
            count: x.count!
        }) ?? []));
    }

    async updateState(): Promise<void> {
        await this.pull()
    }

    get responsibilityTable(): { name: string, onVerification: number, verified: number, decline: number, id: string }[] {
        return this.requestsByClientsResponsibility.map(x => ({
            name: x.name!,
            id: x.id!,
            onVerification: x.statuses
                ?.filter(x => x.name == RequestStatus.OnVerification)
                .map(x => x.count!)
                .concat([0])
                .reduce((x, y) => (x + y)) ?? 0,
            decline: x.statuses
                ?.filter(x => x.name == RequestStatus.Decline || x.name == RequestStatus.NotVerified)
                .map(x => x.count!)
                .concat([0])
                .reduce((x, y) => (x + y)) ?? 0,
            verified: x.statuses
                ?.filter(x => x.name == RequestStatus.Verified || x.name == RequestStatus.Partialy)
                .map(x => x.count!)
                .concat([0])
                .reduce((x, y) => (x + y)) ?? 0,
        }))
    }

    async request(): Promise<void> {

        if (!this.valid)
            return;

        this.loading = true;

        const analyticsApi = new AnalyticsApi();

        const from = this.dateFilters.startDate.value ? this.dateFilters.startDate.value : undefined
        const to = this.dateFilters.endDate.value ? this.dateFilters.endDate.value : undefined

        Promise.all([
            analyticsApi.getClientsCount({startDate: from, endDate: to}).then(x => this.clientsCount = x),
            analyticsApi.getRequestsCount({startDate: from, endDate: to}).then(x => this.requestsCount = x),
            analyticsApi.getRequestsCountByDate({
                startDate: from,
                endDate: to,
                interval: this.interval,
                entityType: ["Person", "Organization"]
            }).then(x => this.requestByDate = x),
            analyticsApi.getSpeedCountByDate({
                startDate: from,
                endDate: to,
                interval: this.interval,
            }).then(x => this.speedCountByDate = x),
            analyticsApi.getSpeedCount({startDate: from, endDate: to}).then(x => this.speedCount = x),
            analyticsApi.getStatusesCount({startDate: from, endDate: to}).then(x => this.statusesCount = x),
            analyticsApi.getRequestsCountByClient({
                startDate: from,
                endDate: to,
                entityType: ["Person"]
            }).then(x => this.personRequestsCountByClient = x),
            analyticsApi.getRequestsCountByClient({
                startDate: from,
                endDate: to,
                entityType: ["Organization"]
            }).then(x => this.organizationRequestsCountByClient = x),
            analyticsApi.getRequestsCountByEntity({
                startDate: from,
                endDate: to,
                entityType: ["Organization"]
            }).then(x => this.countWithMoreRequestsByOrganization = x),
            analyticsApi.getRequestsCountByEntity({
                startDate: from,
                endDate: to,
                entityType: ["Person"]
            }).then(x => this.countWithMoreRequestsByPerson = x),
            this.requestClient()
        ]).then(x => this.loading = false)
    }

    async requestClient(): Promise<void> {
        const analyticsApi = new AnalyticsApi();

        const from = this.dateFilters.startDate.value ? this.dateFilters.startDate.value : undefined
        const to = this.dateFilters.endDate.value ? this.dateFilters.endDate.value : undefined
        const clientId = this.client.value ? this.client.value.id! : undefined;

        analyticsApi.getRequestsCountByDate({
            startDate: from,
            endDate: to,
            interval: this.interval,
            client: clientId,
            entityType: ["Organization"]
        }).then(x => this.organizationRequestsByClientDate = x)
        analyticsApi.getRequestsCountByDate({
            startDate: from,
            endDate: to,
            interval: this.interval,
            client: clientId,
            entityType: ["Person"]
        }).then(x => this.personRequestsByClientDate = x)
        analyticsApi.getRequestsCountByResponsibility({
            startDate: from,
            endDate: to,
            client: clientId,
        }).then(x => this.requestsByClientsResponsibility = x)
    }

    async pull(): Promise<void> {
        await this.debounceRequest()
    }
}
