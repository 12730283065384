/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    PersonDepJobDto,
    PersonDepJobDtoFromJSON,
    PersonDepJobDtoFromJSONTyped,
    PersonDepJobDtoToJSON,
} from './PersonDepJobDto';
import {
    TagDto,
    TagDtoFromJSON,
    TagDtoFromJSONTyped,
    TagDtoToJSON,
} from './TagDto';

/**
 * 
 * @export
 * @interface AssignedPersonDepJobTagDto
 */
export interface AssignedPersonDepJobTagDto {
    /**
     * 
     * @type {string}
     * @memberof AssignedPersonDepJobTagDto
     */
    id?: string | null;
    /**
     * 
     * @type {PersonDepJobDto}
     * @memberof AssignedPersonDepJobTagDto
     */
    personDepJob?: PersonDepJobDto;
    /**
     * 
     * @type {TagDto}
     * @memberof AssignedPersonDepJobTagDto
     */
    jobTag?: TagDto;
}

export function AssignedPersonDepJobTagDtoFromJSON(json: any): AssignedPersonDepJobTagDto {
    return AssignedPersonDepJobTagDtoFromJSONTyped(json, false);
}

export function AssignedPersonDepJobTagDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignedPersonDepJobTagDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'personDepJob': !exists(json, 'personDepJob') ? undefined : PersonDepJobDtoFromJSON(json['personDepJob']),
        'jobTag': !exists(json, 'jobTag') ? undefined : TagDtoFromJSON(json['jobTag']),
    };
}

export function AssignedPersonDepJobTagDtoToJSON(value?: AssignedPersonDepJobTagDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'personDepJob': PersonDepJobDtoToJSON(value.personDepJob),
        'jobTag': TagDtoToJSON(value.jobTag),
    };
}

