import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {AddressStore} from "../../../../stores/management/dcr/requests/builders/fieldsStores/AddressStore";
import {Box, List, Typography} from "@mui/material";
import {LocalitySelect} from "../../../../components/select/Locality/LocalitySelect";
import {DefaultValue} from "../../../../components/shared/components/DefaultValue";
import {DefaultSelect} from "../../../../components/shared/components/DefaultSelect";
import {DefaultCheckbox} from "../../../../components/shared/components/DefaultCheckbox";


interface Props {
    store: AddressStore;
}

export const AddressComponents = observer(({store}: Props) => {
    const {t} = useTranslation();
    const [step, setStep] = useState(0);
    const stepRef = React.useRef<HTMLDivElement | null>(null)

    const stepLocality = () => {
        if (!stepRef.current) return;
        const localityElement = stepRef.current?.getElementsByClassName('autocompleteLocality').length;
        setStep(localityElement)
    };


    const elementStyle = {
        width: store.store._store._store._store.isOpenInDrawer.value ? '30%' : '30%',
        marginRight: '24px',
        marginBottom: '8px'
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>

            {
                <Box >
                    <Box sx={{minWidth: 180, display: "flex", flexDirection: 'row', alignItems: 'center'}}>
                        <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            style={{marginBottom: 'auto', maxWidth: 160}}
                        >
                        {
                            t("dcrPage.blocks.address")
                        }
                        </Typography>
                    </Box>
                    {
                        <Box
                            sx={{
                                flex: 1,
                                mt: 0,
                                ml: 3,
                                marginLeft: 'unset',
                                marginRight: '24px'
                                // width: 280
                            }}
                        >
                            <Typography
                                variant="body1"
                            >
                                {
                                    store.fullAddress
                                }
                            </Typography>
                        </Box>
                    }
                </Box>
            }

            {
                store.store._store.edit ?
                    <>
                        <Box ref={stepRef} sx={{
                            paddingTop: '10px',
                            flexDirection: "row",
                            display: 'flex',
                            color: 'red',
                            width: '100%',
                            ".autocompleteLocality": {
                                width: '33%',
                                paddingRight: '20px'
                            }
                        }}>
                            <LocalitySelect locality={store.addressStore.locality.locality}
                                            style={{marginRight: 20}}
                                            stepLocality={stepLocality}
                                            step={step}/>
                        </Box>
                        {!!store?.addressStore.locality?.currentLocality?.isEndpoint &&
                            <List style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: "wrap",
                                alignItems: 'flex-start',
                                padding: 'unset'
                            }}>
                                {store.addressStore.hasDistrictRegion &&
                                    <>
                                        <DefaultSelect
                                            store={store.addressStore.district}
                                            nameExp={(x) => x.mdmRegionType ? `${x?.name} (${x.mdmRegionType?.nameRus})` : `${x?.name}`}
                                            title={t('localities.district')}
                                            style={{...elementStyle}}
                                            testid="organizationAddress-district"
                                            hasLabel/>
                                        <DefaultSelect
                                            store={store.addressStore.okrug}
                                            nameExp={(x) => x.mdmRegionType ? `${x?.name} (${x.mdmRegionType?.nameRus})` : `${x?.name}`}
                                            title={t('localities.okrug')}
                                            style={{...elementStyle}}
                                            testid="organizationAddress-okrug"
                                            hasLabel/>
                                    </>
                                }
                                <DefaultValue
                                    data-testid="addressCreator-house-input"
                                    style={{...elementStyle}}
                                    title={t('localities.house')}
                                    store={store.addressStore.house}
                                />

                                <DefaultValue
                                    data-testid="addressCreator-building-input"
                                    style={{...elementStyle}}
                                    title={t('localities.building')}
                                    store={store.addressStore.building}
                                />
                                <DefaultSelect
                                    store={store.addressStore.entryType}
                                    title={t('localities.entryType')}
                                    testid="addressCreator-entryType"
                                    style={{...elementStyle}}
                                    hasLabel />

                                {!!store.addressStore.entryType.value && <DefaultValue
                                    data-testid="addressCreator-entryValue-input"
                                    style={{...elementStyle}}
                                    title={t('localities.entryValue')}
                                    store={store.addressStore.entryValue}
                                />}

                                <DefaultValue store={store.addressStore.zip}
                                               title={t('localities.zip')}
                                               style={{...elementStyle}}
                                              testid="organizationAddress-zip"/>


                                <DefaultCheckbox store={store.addressStore.isCustomCoordinates}
                                                 style={{
                                                     ...elementStyle, marginLeft: '0px', marginTop: '11px'
                                                 }}
                                                 title={t('common.isCustomCoordinates')}/>

                                {
                                    store.addressStore.isCustomCoordinates.value &&
                                    <div style={{...elementStyle}}>
                                        <DefaultValue
                                            data-testid="organizationAddress-latitude-input"
                                            title="Широта"
                                            style={{width: '49%', marginRight: '0.3em'}}
                                            store={store.addressStore.latitude}
                                        />
                                        <DefaultValue
                                            data-testid="organizationAddress-longtitude-input"
                                            title="Долгота"
                                            style={{width: '49%'}}
                                            store={store.addressStore.longtitude}
                                        />
                                    </div>
                                }
                            </List>
                        }
                    </> :
                    <>
                        {
                            store.store._store._store.isCompletedStatus &&
                            <Box>
                                <Box sx={{
                                    minWidth: 180,
                                    display: "flex",
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingTop: '10px'
                                }}>
                                    <Typography
                                        variant="subtitle2"
                                        color="textSecondary"
                                        style={{marginBottom: 'auto', maxWidth: 160}}
                                    >
                                        {t("common.saveAddress")}
                                    </Typography>
                                </Box>
                                {
                                    <Box
                                        sx={{
                                            flex: 1,
                                            mt: 0,
                                            ml: 3,
                                            marginLeft: 'unset',
                                            marginRight: '24px'
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                        >
                                            {
                                                store.store.address
                                            }
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                        }
                    </>
            }
        </Box>
    );
});
