import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
    AuthorizePermission,
    FormRoot,
    ParamsButtonGroup,
} from '../../../../../components';
import { DefaultMultiSelect } from '../../../../../components/shared/components/DefaultMultiSelect';
import { AllPermissions } from '../../../../../core/const';
import {
    StoreProps,
    StyleProps,
    callTheProcessingFunc,
} from '../../../../../stores';
import { UserRolesTabStore } from '../../../../../stores/management/users/tabs/Users/tabs/UserRolesTabStore';

export const RolesTab = observer(
    ({ store, style }: StoreProps<UserRolesTabStore> & StyleProps) => {
        const { t } = useTranslation();

        return (
            <FormRoot style={style}>
                <AuthorizePermission
                    permissions={AllPermissions.user.userroles.read}
                    showWarning
                >
                    <AuthorizePermission
                        permissions={AllPermissions.user.userroles.create}
                    >
                        <DefaultMultiSelect
                            store={store.allRolesControl}
                            title={t('usersPage.tabs.roles')}
                            valueExp="id"
                            nameExp="name"
                            tags={true}
                        />
                    </AuthorizePermission>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <AuthorizePermission
                            permissions={AllPermissions.user.userroles.delete}
                        >
                            <ParamsButtonGroup
                                testid="parametersUser"
                                onCancel={() => store.allRolesControl.reset()}
                                onSave={async () =>
                                    await callTheProcessingFunc(store, (e) =>
                                        store.submit()
                                    )()
                                }
                                isCancelBtnDisabled={
                                    store.processing ||
                                    store.allRolesControl.equals
                                }
                                isSaveBtnDisabled={
                                    store.processing ||
                                    store.allRolesControl.equals ||
                                    !store.allRolesControl.valid
                                }
                            />
                        </AuthorizePermission>
                    </Box>
                </AuthorizePermission>
            </FormRoot>
        );
    }
);
