/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Exchange.Analytics.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SummaryCounts,
    SummaryCountsFromJSON,
    SummaryCountsFromJSONTyped,
    SummaryCountsToJSON,
} from './SummaryCounts';

/**
 * 
 * @export
 * @interface SummaryAggregation
 */
export interface SummaryAggregation {
    /**
     * 
     * @type {string}
     * @memberof SummaryAggregation
     */
    clientId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SummaryAggregation
     */
    clientName?: string | null;
    /**
     * 
     * @type {SummaryCounts}
     * @memberof SummaryAggregation
     */
    persons?: SummaryCounts;
    /**
     * 
     * @type {SummaryCounts}
     * @memberof SummaryAggregation
     */
    organizations?: SummaryCounts;
    /**
     * 
     * @type {SummaryCounts}
     * @memberof SummaryAggregation
     */
    total?: SummaryCounts;
}

export function SummaryAggregationFromJSON(json: any): SummaryAggregation {
    return SummaryAggregationFromJSONTyped(json, false);
}

export function SummaryAggregationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryAggregation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'clientName': !exists(json, 'clientName') ? undefined : json['clientName'],
        'persons': !exists(json, 'persons') ? undefined : SummaryCountsFromJSON(json['persons']),
        'organizations': !exists(json, 'organizations') ? undefined : SummaryCountsFromJSON(json['organizations']),
        'total': !exists(json, 'total') ? undefined : SummaryCountsFromJSON(json['total']),
    };
}

export function SummaryAggregationToJSON(value?: SummaryAggregation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientId': value.clientId,
        'clientName': value.clientName,
        'persons': SummaryCountsToJSON(value.persons),
        'organizations': SummaryCountsToJSON(value.organizations),
        'total': SummaryCountsToJSON(value.total),
    };
}

