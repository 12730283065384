/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface UnTakeRequestResponsibleCommand
 */
export interface UnTakeRequestResponsibleCommand {
    /**
     * 
     * @type {string}
     * @memberof UnTakeRequestResponsibleCommand
     */
    id?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof UnTakeRequestResponsibleCommand
     */
    isInternal?: boolean;
}

export function UnTakeRequestResponsibleCommandFromJSON(json: any): UnTakeRequestResponsibleCommand {
    return UnTakeRequestResponsibleCommandFromJSONTyped(json, false);
}

export function UnTakeRequestResponsibleCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnTakeRequestResponsibleCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isInternal': !exists(json, 'isInternal') ? undefined : json['isInternal'],
    };
}

export function UnTakeRequestResponsibleCommandToJSON(value?: UnTakeRequestResponsibleCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isInternal': value.isInternal,
    };
}

