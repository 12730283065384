/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 * 
 * @export
 * @interface CountDto
 */
export interface CountDto {
    /**
     * 
     * @type {number}
     * @memberof CountDto
     */
    count: number;
}

export function CountDtoFromJSON(json: any): CountDto {
    return CountDtoFromJSONTyped(json, false);
}

export function CountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
    };
}

export function CountDtoToJSON(value?: CountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
    };
}

