/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Api.Import
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    EmailDto,
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import {
    NameDto,
    NameDtoFromJSON,
    NameDtoFromJSONTyped,
    NameDtoToJSON,
} from './NameDto';
import {
    OrganizationDto,
    OrganizationDtoFromJSON,
    OrganizationDtoFromJSONTyped,
    OrganizationDtoToJSON,
} from './OrganizationDto';

/**
 *
 * @export
 * @interface OrganizationEmailDto
 */
export interface OrganizationEmailDto {
    /**
     *
     * @type {string}
     * @memberof OrganizationEmailDto
     */
    id: string;
    /**
     *
     * @type {EmailDto}
     * @memberof OrganizationEmailDto
     */
    email: EmailDto;
    /**
     *
     * @type {OrganizationDto}
     * @memberof OrganizationEmailDto
     */
    organization: OrganizationDto;
    /**
     *
     * @type {NameDto}
     * @memberof OrganizationEmailDto
     */
    emailStatus: NameDto;
    /**
     *
     * @type {NameDto}
     * @memberof OrganizationEmailDto
     */
    emailType: NameDto;
}

export function OrganizationEmailDtoFromJSON(json: any): OrganizationEmailDto {
    return OrganizationEmailDtoFromJSONTyped(json, false);
}

export function OrganizationEmailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationEmailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'id': json['id'],
        'email': EmailDtoFromJSON(json['email']),
        'organization': OrganizationDtoFromJSON(json['organization']),
        'emailStatus': NameDtoFromJSON(json['emailStatus']),
        'emailType': NameDtoFromJSON(json['emailType']),
    };
}

export function OrganizationEmailDtoToJSON(value?: OrganizationEmailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'id': value.id,
        'email': EmailDtoToJSON(value.email),
        'organization': OrganizationDtoToJSON(value.organization),
        'emailStatus': NameDtoToJSON(value.emailStatus),
        'emailType': NameDtoToJSON(value.emailType),
    };
}

