/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {RequestFieldType, RequestFieldTypeFromJSON, RequestFieldTypeToJSON,} from './RequestFieldType';

/**
 * 
 * @export
 * @interface RequestField
 */
export interface RequestField {
    /**
     * 
     * @type {number}
     * @memberof RequestField
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RequestField
     */
    field?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestField
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestField
     */
    oldName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestField
     */
    newValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestField
     */
    newName?: string | null;
    /**
     * 
     * @type {RequestFieldType}
     * @memberof RequestField
     */
    fieldType?: RequestFieldType;
}

export function RequestFieldFromJSON(json: any): RequestField {
    return RequestFieldFromJSONTyped(json, false);
}

export function RequestFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'field': !exists(json, 'field') ? undefined : json['field'],
        'oldValue': !exists(json, 'oldValue') ? undefined : json['oldValue'],
        'oldName': !exists(json, 'oldName') ? undefined : json['oldName'],
        'newValue': !exists(json, 'newValue') ? undefined : json['newValue'],
        'newName': !exists(json, 'newName') ? undefined : json['newName'],
        'fieldType': !exists(json, 'fieldType') ? undefined : RequestFieldTypeFromJSON(json['fieldType']),
    };
}

export function RequestFieldToJSON(value?: RequestField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'field': value.field,
        'oldValue': value.oldValue,
        'oldName': value.oldName,
        'newValue': value.newValue,
        'newName': value.newName,
        'fieldType': RequestFieldTypeToJSON(value.fieldType),
    };
}

