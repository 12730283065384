import {ManagementStore} from '..';
import {callTheProcessingFunc, PagedItems, TableStore} from '../../utils';
import {DefaultValueStore} from "../../../components/shared/DefaultValueStore";
import {JurisdictionsApi} from "../../../services/management";
import {DefaultFormModalStore} from "../../../components/shared/DefaultFormModalStore";
import {JurisdictionItemStore} from "./JurisdictionItemStore";
import {makeObservable, observable} from "mobx";

export class JurisdictionsTableStore extends TableStore<JurisdictionItemStore> {
    readonly store: ManagementStore;
    deleteDialogState: boolean = false;
    current: JurisdictionItemStore | null = null;
    creator: DefaultFormModalStore<{
        nameRus: DefaultValueStore<string>,
        nameEng: DefaultValueStore<string>,
        itnRegex: DefaultValueStore<string>,
        phoneNumberRegex: DefaultValueStore<string>
    }> = new DefaultFormModalStore(
        {
            nameEng: new DefaultValueStore<string>(""),
            nameRus: new DefaultValueStore<string>(""),
            itnRegex: new DefaultValueStore<string>("", (e) => {
                let isValid = true;
                try {
                    new RegExp(e ?? "");
                } catch (e) {
                    isValid = false;
                }
                return isValid ? "" : "Значение не является регулярным выражением"
            }),
            phoneNumberRegex: new DefaultValueStore<string>("", (e) => {
                let isValid = true;
                try {
                    new RegExp(e ?? "");
                } catch (e) {
                    isValid = false;
                }
                return isValid ? "" : "Значение не является регулярным выражением"
            })
        }, async (e) => {
            await callTheProcessingFunc(this, () => this.create(e))()
            await this.updateState()
        }
    )

    constructor(store: ManagementStore) {
        super();
        this.creator.fields.nameRus.required = true;
        this.creator.fields.nameEng.required = true;
        this.creator.fields.itnRegex.required = true;
        this.creator.fields.phoneNumberRegex.required = true;

        makeObservable(this, {
            creator: observable,
            current: observable,
            deleteDialogState: observable
        });
        this.store = store;
    }

    async request(): Promise<PagedItems<JurisdictionItemStore>> {
        this.loading = true;
        const filters = {
            page: this.page + 1,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy
        };
        const data = await new JurisdictionsApi().getJurisdictions(filters)
        const count = await new JurisdictionsApi().getJurisdictionsCount(filters)

        const newList: JurisdictionItemStore[] = [];

        for (const item of data ?? []) {
            const existsItem = this.items.find(t => t.dto.id === item.id);
            if (existsItem) {
                existsItem.update(item);
                newList.push(existsItem);
                continue;
            }
            newList.push(new JurisdictionItemStore(this, item))
        }
        this.loading = false;

        return new PagedItems(newList, count.count)
    };

    async create(dto: any): Promise<void>{
        await new JurisdictionsApi().createJurisdiction({setJurisdictionDto: dto})
    }

    async delete() {
        await new JurisdictionsApi().deleteJurisdiction({id: this.current?.dto.id!})
    }
}
