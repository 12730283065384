/* tslint:disable */
/* eslint-disable */
/**
 * Data360.DCR.Analytics
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CountInfoDto
 */
export interface CountInfoDto {
    /**
     * 
     * @type {number}
     * @memberof CountInfoDto
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof CountInfoDto
     */
    infoCount?: number;
}

export function CountInfoDtoFromJSON(json: any): CountInfoDto {
    return CountInfoDtoFromJSONTyped(json, false);
}

export function CountInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'infoCount': !exists(json, 'infoCount') ? undefined : json['infoCount'],
    };
}

export function CountInfoDtoToJSON(value?: CountInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'infoCount': value.infoCount,
    };
}

