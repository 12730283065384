/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OCMObjectState,
    OCMObjectStateFromJSON,
    OCMObjectStateToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetTypeNameDto,
    SetTypeNameDtoFromJSON,
    SetTypeNameDtoToJSON,
    TypeNameDto,
    TypeNameDtoFromJSON,
    TypeNameDtoToJSON,
} from '../models';

export interface CreateTypeNameRequest {
    setTypeNameDto: SetTypeNameDto;
}

export interface DeleteTypeNameRequest {
    id: string;
}

export interface GetTypeNameByIdRequest {
    id: string;
}

export interface GetTypeNameByNameRequest {
    name: string;
}

export interface GetTypeNamesRequest {
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetTypeNamesCountRequest {
    search?: string;
}

export interface GetTypeNamesCountFallbackRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface GetTypeNamesFallbackRequest {
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface UpdateTypeNameRequest {
    id: string;
    setTypeNameDto: SetTypeNameDto;
}

/**
 * 
 */
export class TypeNamesApi extends runtime.BaseAPI {

    /**
     */
    async createTypeNameRaw(requestParameters: CreateTypeNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TypeNameDto>> {
        if (requestParameters.setTypeNameDto === null || requestParameters.setTypeNameDto === undefined) {
            throw new runtime.RequiredError('setTypeNameDto','Required parameter requestParameters.setTypeNameDto was null or undefined when calling createTypeName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/names/types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetTypeNameDtoToJSON(requestParameters.setTypeNameDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async createTypeName(requestParameters: CreateTypeNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TypeNameDto> {
        const response = await this.createTypeNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTypeNameRaw(requestParameters: DeleteTypeNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TypeNameDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTypeName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/names/types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteTypeName(requestParameters: DeleteTypeNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TypeNameDto> {
        const response = await this.deleteTypeNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTypeNameByIdRaw(requestParameters: GetTypeNameByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TypeNameDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTypeNameById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/names/types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async getTypeNameById(requestParameters: GetTypeNameByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TypeNameDto> {
        const response = await this.getTypeNameByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTypeNameByNameRaw(requestParameters: GetTypeNameByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TypeNameDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getTypeNameByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/names/types/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async getTypeNameByName(requestParameters: GetTypeNameByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TypeNameDto> {
        const response = await this.getTypeNameByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTypeNamesRaw(requestParameters: GetTypeNamesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<TypeNameDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/names/types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TypeNameDtoFromJSON));
    }

    /**
     */
    async getTypeNames(requestParameters: GetTypeNamesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<TypeNameDto>> {
        const response = await this.getTypeNamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTypeNamesCountRaw(requestParameters: GetTypeNamesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/names/types/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getTypeNamesCount(requestParameters: GetTypeNamesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getTypeNamesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTypeNamesCountFallbackRaw(requestParameters: GetTypeNamesCountFallbackRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/names/types/count/fallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getTypeNamesCountFallback(requestParameters: GetTypeNamesCountFallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getTypeNamesCountFallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTypeNamesFallbackRaw(requestParameters: GetTypeNamesFallbackRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<TypeNameDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/names/types/fallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TypeNameDtoFromJSON));
    }

    /**
     */
    async getTypeNamesFallback(requestParameters: GetTypeNamesFallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<TypeNameDto>> {
        const response = await this.getTypeNamesFallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTypeNameRaw(requestParameters: UpdateTypeNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TypeNameDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTypeName.');
        }

        if (requestParameters.setTypeNameDto === null || requestParameters.setTypeNameDto === undefined) {
            throw new runtime.RequiredError('setTypeNameDto','Required parameter requestParameters.setTypeNameDto was null or undefined when calling updateTypeName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/names/types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetTypeNameDtoToJSON(requestParameters.setTypeNameDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateTypeName(requestParameters: UpdateTypeNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TypeNameDto> {
        const response = await this.updateTypeNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
