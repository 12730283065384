/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface SetUserRoleDto
 */
export interface SetUserRoleDto {
    /**
     * 
     * @type {string}
     * @memberof SetUserRoleDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof SetUserRoleDto
     */
    roleId: string;
}

export function SetUserRoleDtoFromJSON(json: any): SetUserRoleDto {
    return SetUserRoleDtoFromJSONTyped(json, false);
}

export function SetUserRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetUserRoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'roleId': json['roleId'],
    };
}

export function SetUserRoleDtoToJSON(value?: SetUserRoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'roleId': value.roleId,
    };
}

