import {makeAutoObservable} from 'mobx';
import {getApiConfiguration, IForm, IProcessing} from "../../core";
import i18n from "i18next";
import {DefaultValueStore} from "../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from "../../components/shared/DefaultSelectStore";
import {LocalityValueStore} from "../../components/select/Locality/LocalityValueStore";
import {MdmRegion} from "../../core/const";
import {EntryTypeDto, EntryTypesApi, LocalityDto, MdmRegionDto, MdmRegionsApi, ZipDto} from "../../services/management";
import {JurisdictionData, JurisdictionLocalSetting} from "../../stores/authorization/AuthorizationStore";


export interface AddressDto {
    house: string | undefined;
    building: string | undefined;
    zip: ZipDto | undefined
    district: MdmRegionDto | undefined;
    okrug: MdmRegionDto | undefined;
    latitude: string | undefined;
    longtitude: string | undefined;
    locality: LocalityDto | undefined
    entryType?: EntryTypeDto | null;
    entryValue?: number | null;
}

export class DefaultAddressesStore implements IProcessing, IForm {
    //defCityId?: string | null | undefined;
    locality: LocalityValueStore;
    house: DefaultValueStore<string>
    building: DefaultValueStore<string>
    entryType: DefaultSelectStore<EntryTypeDto>
    entryValue: DefaultValueStore<number>
    zip: DefaultValueStore<string>
    district: DefaultSelectStore<MdmRegionDto>
    okrug: DefaultSelectStore<MdmRegionDto>
    latitude: DefaultValueStore<string>;
    longtitude: DefaultValueStore<string>;
    isCustomCoordinates: DefaultValueStore<boolean> = new DefaultValueStore<boolean>(false);
    loading: boolean = false;
    additionalCheckEndpoint: boolean = false;
    processing: boolean = false;
    clearValue: boolean = true;
    store?: { jurisdictionLocalSetting?: JurisdictionLocalSetting }

    constructor(dto?: AddressDto, store?: { jurisdictionLocalSetting?: JurisdictionLocalSetting }) {
        this.store = store;
        this.house = new DefaultValueStore<string>(dto?.house, (value) => !value ? i18n.t('errors.empty') : "");
        this.building = new DefaultValueStore<string>(dto?.building);
        this.zip = new DefaultValueStore<string>(dto?.zip?.postIndex, (e) => {
            return new RegExp(this.store?.jurisdictionLocalSetting?.zipRegex!).test(e ?? "") ? "" : "Неверный формат"
        })

        this.entryType = new DefaultSelectStore<EntryTypeDto>(
            dto?.entryType ?? null, (x) => new EntryTypesApi(getApiConfiguration()).getEntryTypes(x))

        this.entryType.onChanged = [async (e) => {
            if (!e) this.entryValue.value = null
        }]

        this.entryValue = new DefaultValueStore<number>(dto?.entryValue ?? null);

        this.district = new DefaultSelectStore<MdmRegionDto>(dto?.district || null,
            (filter) => new MdmRegionsApi(getApiConfiguration()).getMdmRegions({
                ...filter,
                cityId: [this.locality.defCityId ?? ''],
                types: [MdmRegion.DistrictId]
            }))
        this.district.required = true;
        this.okrug = new DefaultSelectStore<MdmRegionDto>(dto?.okrug || null,
            (filter) => new MdmRegionsApi(getApiConfiguration()).getMdmRegions({
                ...filter,
                cityId: [this.locality.defCityId ?? ''],
                types: [MdmRegion.Okrug, MdmRegion.Okrug2],
            }))
        this.latitude = new DefaultValueStore<string>(dto?.latitude,
            (value) => !this.isCustomCoordinates.value ? "" : !new RegExp(/^(\+|-)?(?:90(?:(?:(\.|,)0{1,7})?)|(?:[0-9]|[1-8][0-9])(?:(?:(\.|,)[0-9]{1,7})))$/).test(value ?? "") ? i18n.t('errors.coordinates') : (value?.replace(/\./g, ',') === this.longtitude.value?.replace(/\./g, ',')) ? i18n.t('errors.latitude') : "")
        this.longtitude = new DefaultValueStore<string>(dto?.longtitude,
            (value) => !this.isCustomCoordinates.value ? "" : !new RegExp(/^(\+|-)?(?:180(?:(?:(\.|,)0{1,7})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:(\.|,)[0-9]{1,7})))$/).test(value ?? "") ? i18n.t('errors.coordinates') : (value?.replace(/\./g, ',') === this.latitude.value?.replace(/\./g, ',')) ? i18n.t('errors.longitude') : "")

        this.locality = new LocalityValueStore(dto?.locality, (e) => {
            if (this.clearValue) {
                this.zip.value = null;
                this.entryType.value = null;
                this.entryValue.value = null;
                this.house.value = "";
                this.building.value = "";
                this.latitude.value = "";
                this.longtitude.value = "";
            }
            this.district.value = null;
            this.okrug.value = null;
            if (!!this.defCityId)
                this.checkDistrict()

            this.validate()
        })
        this.isCustomCoordinates.onChanged.push((e) => {
            this.latitude.validate()
            this.longtitude.validate()
        })
        this.zip.required = true;
        makeAutoObservable(this);
    }

    get equals(): boolean {
        return this.house.equals &&
            this.building.equals &&
            this.district.equals &&
            this.zip.equals &&
            this.entryType.equals &&
            this.entryValue.equals &&
            this.latitude.equals &&
            this.longtitude.equals &&
            this.okrug.equals;
    }

    _hasDistrict: boolean = false;

    update(dto: AddressDto) {
        this.house.update(dto?.house)
        this.building.update(dto?.building)
        this.latitude.update(dto?.latitude)
        this.longtitude.update(dto?.longtitude)
        this.zip?.update(dto?.zip?.postIndex)
        this.entryType?.update(dto?.entryType ?? null)
        this.entryValue?.update(dto?.entryValue ?? null)
        this.district?.update(dto?.district ?? null)
        this.okrug?.update(dto?.okrug ?? null)
        this.locality = new LocalityValueStore(dto?.locality, (e) => {
            if (this.clearValue) {
                this.zip.value = null;
                this.entryType.value = null;
                this.entryValue.value = null;
                this.house.value = "";
                this.building.value = "";
                this.latitude.value = "";
                this.longtitude.value = "";
            }
            this.district.value = null;
            this.okrug.value = null;
            if (!!this.defCityId)
                this.checkDistrict()
            this.validate()
        })
        this.locality.currentLocality?.checkEndpointFn(this.locality.currentLocality?.id)
        this.checkDistrict()
    }

    lazyUpdate() {
        this.house.update(this.house?.value)
        this.building.update(this?.building?.value)
        this.latitude.update(this?.latitude?.value)
        this.longtitude.update(this?.longtitude?.value)
        this.zip?.update(this?.zip?.value)
        this.entryType?.update(this?.entryType?.value ?? null)
        this.entryValue?.update(this?.entryValue?.value ?? null)
        this.district?.update(this?.district?.value ?? null)
        this.okrug?.update(this?.okrug?.value ?? null)
    }

    checkDistrict() {
        this.district.pull().then(x => {
            this._hasDistrict = this.district.items.length > 0
            this.district.validate()
        })
    }

    get valid(): boolean {
        return this.house.valid &&
            this.building.valid &&
            this.locality.valid &&
            (!this.isCustomCoordinates.value || (this.longtitude.valid && this.latitude.valid)) &&
            this.zip.valid && this.entryType.valid && this.entryValue.valid &&
            (!this.hasDistrictRegion || this.district.valid)
    }

    async validate(): Promise<void> {
        this.house.validate();
        this.latitude.validate();
        this.building.validate();
        this.longtitude.validate();
        this.zip.validate();
        this.entryType.validate();
        this.entryValue.validate();
        this.locality?.validate();
        await this.district?.validate();
    }

    reset(): void {
        this.house.reset();
        this.latitude.reset();
        this.building.reset();
        this.longtitude.reset();
        this.zip.reset();
        this.entryType.reset();
        this.entryValue.reset();
        this.locality?.reset();
        this.district?.reset();
    }

    get defCityId(): string | null {
        let hasCity = false;
        let hasPopulatedLocalities = false;
        let value = '';
        let local = this.locality.locality
        while (!!local.locality) {
            if (!!local.value?.typeLocality) {
                hasCity = local.value?.typeLocality?.id == JurisdictionData.typeLocalityIds.cityId;
                hasPopulatedLocalities = local.value?.typeLocality?.categoryLocality?.id == JurisdictionData.categoryLocality.populatedId
                if (hasCity || hasPopulatedLocalities) {
                    value = local.value.id;
                }
            }
            local = local.locality
        }

        return value;
    }

    get hasDistrictRegion(): boolean {
        return !!this.locality.defCityId && this._hasDistrict
    }
}

