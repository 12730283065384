/* tslint:disable */
/* eslint-disable */
/**
 * Data360.DCR.Analytics
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DcrStatus,
    DcrStatusFromJSON,
    DcrStatusFromJSONTyped,
    DcrStatusToJSON,
} from './DcrStatus';
import {
    RequestRecordType,
    RequestRecordTypeFromJSON,
    RequestRecordTypeFromJSONTyped,
    RequestRecordTypeToJSON,
} from './RequestRecordType';
import {
    RequestStatus,
    RequestStatusFromJSON,
    RequestStatusFromJSONTyped,
    RequestStatusToJSON,
} from './RequestStatus';

/**
 * 
 * @export
 * @interface DcrData360ReportDto
 */
export interface DcrData360ReportDto {
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    dcrClientName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DcrData360ReportDto
     */
    dcrModifiedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DcrData360ReportDto
     */
    dcrCreationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    dcrId?: string | null;
    /**
     * 
     * @type {RequestRecordType}
     * @memberof DcrData360ReportDto
     */
    dcrEntity?: RequestRecordType;
    /**
     * 
     * @type {DcrStatus}
     * @memberof DcrData360ReportDto
     */
    dcrType?: DcrStatus;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    dcrComment?: string | null;
    /**
     * 
     * @type {RequestStatus}
     * @memberof DcrData360ReportDto
     */
    dcrStatus?: RequestStatus;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    dcrData360Id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    dcrMorionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    dcrCrmId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DcrData360ReportDto
     */
    dcrIsErrorRequest?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DcrData360ReportDto
     */
    dcrCrmRequestId?: number;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    dcrResponsibleId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldOrgName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldOrgType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldOrgCompanyType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldOrgCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldOrgParent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldOrgCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldOrgRegion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldOrgStreetType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldOrgStreet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldOrgBuilding?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldOrgPostcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldOrgPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldPersonCompany?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldPersonLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldPersonFirstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldPersonMiddlename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldPersonSex?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldPersonPosition?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldPersonSpecialization?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    crmFieldArchivereason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    data360FieldOrgName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    data360FieldOrgType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    data360FieldOrgCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    data360FieldOrgRegion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    data360FieldOrgStreetType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    data360FieldOrgStreet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    data360FieldOrgBuilding?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    data360FieldOrgHouse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    data360FieldOrgPostcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    data360FieldOrgPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    data360FieldOrgItn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    data360FieldPersonCompanyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    data360FieldPersonLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    data360FieldPersonFirstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    data360FieldPersonMiddlename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    data360FieldPersonGender?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    data360FieldPersonPosition?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DcrData360ReportDto
     */
    data360FieldPersonSpecialization?: string | null;
}

export function DcrData360ReportDtoFromJSON(json: any): DcrData360ReportDto {
    return DcrData360ReportDtoFromJSONTyped(json, false);
}

export function DcrData360ReportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DcrData360ReportDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dcrClientName': !exists(json, 'dcrClientName') ? undefined : json['dcrClientName'],
        'dcrModifiedDate': !exists(json, 'dcrModifiedDate') ? undefined : (new Date(json['dcrModifiedDate'])),
        'dcrCreationDate': !exists(json, 'dcrCreationDate') ? undefined : (new Date(json['dcrCreationDate'])),
        'dcrId': !exists(json, 'dcrId') ? undefined : json['dcrId'],
        'dcrEntity': !exists(json, 'dcrEntity') ? undefined : RequestRecordTypeFromJSON(json['dcrEntity']),
        'dcrType': !exists(json, 'dcrType') ? undefined : DcrStatusFromJSON(json['dcrType']),
        'dcrComment': !exists(json, 'dcrComment') ? undefined : json['dcrComment'],
        'dcrStatus': !exists(json, 'dcrStatus') ? undefined : RequestStatusFromJSON(json['dcrStatus']),
        'dcrData360Id': !exists(json, 'dcrData360Id') ? undefined : json['dcrData360Id'],
        'dcrMorionId': !exists(json, 'dcrMorionId') ? undefined : json['dcrMorionId'],
        'dcrCrmId': !exists(json, 'dcrCrmId') ? undefined : json['dcrCrmId'],
        'dcrIsErrorRequest': !exists(json, 'dcrIsErrorRequest') ? undefined : json['dcrIsErrorRequest'],
        'dcrCrmRequestId': !exists(json, 'dcrCrmRequestId') ? undefined : json['dcrCrmRequestId'],
        'dcrResponsibleId': !exists(json, 'dcrResponsibleId') ? undefined : json['dcrResponsibleId'],
        'crmFieldOrgName': !exists(json, 'crmFieldOrgName') ? undefined : json['crmFieldOrgName'],
        'crmFieldOrgType': !exists(json, 'crmFieldOrgType') ? undefined : json['crmFieldOrgType'],
        'crmFieldOrgCompanyType': !exists(json, 'crmFieldOrgCompanyType') ? undefined : json['crmFieldOrgCompanyType'],
        'crmFieldOrgCode': !exists(json, 'crmFieldOrgCode') ? undefined : json['crmFieldOrgCode'],
        'crmFieldOrgParent': !exists(json, 'crmFieldOrgParent') ? undefined : json['crmFieldOrgParent'],
        'crmFieldOrgCity': !exists(json, 'crmFieldOrgCity') ? undefined : json['crmFieldOrgCity'],
        'crmFieldOrgRegion': !exists(json, 'crmFieldOrgRegion') ? undefined : json['crmFieldOrgRegion'],
        'crmFieldOrgStreetType': !exists(json, 'crmFieldOrgStreetType') ? undefined : json['crmFieldOrgStreetType'],
        'crmFieldOrgStreet': !exists(json, 'crmFieldOrgStreet') ? undefined : json['crmFieldOrgStreet'],
        'crmFieldOrgBuilding': !exists(json, 'crmFieldOrgBuilding') ? undefined : json['crmFieldOrgBuilding'],
        'crmFieldOrgPostcode': !exists(json, 'crmFieldOrgPostcode') ? undefined : json['crmFieldOrgPostcode'],
        'crmFieldOrgPhone': !exists(json, 'crmFieldOrgPhone') ? undefined : json['crmFieldOrgPhone'],
        'crmFieldPersonCompany': !exists(json, 'crmFieldPersonCompany') ? undefined : json['crmFieldPersonCompany'],
        'crmFieldPersonLastname': !exists(json, 'crmFieldPersonLastname') ? undefined : json['crmFieldPersonLastname'],
        'crmFieldPersonFirstname': !exists(json, 'crmFieldPersonFirstname') ? undefined : json['crmFieldPersonFirstname'],
        'crmFieldPersonMiddlename': !exists(json, 'crmFieldPersonMiddlename') ? undefined : json['crmFieldPersonMiddlename'],
        'crmFieldPersonSex': !exists(json, 'crmFieldPersonSex') ? undefined : json['crmFieldPersonSex'],
        'crmFieldPersonPosition': !exists(json, 'crmFieldPersonPosition') ? undefined : json['crmFieldPersonPosition'],
        'crmFieldPersonSpecialization': !exists(json, 'crmFieldPersonSpecialization') ? undefined : json['crmFieldPersonSpecialization'],
        'crmFieldArchivereason': !exists(json, 'crmFieldArchivereason') ? undefined : json['crmFieldArchivereason'],
        'data360FieldOrgName': !exists(json, 'data360FieldOrgName') ? undefined : json['data360FieldOrgName'],
        'data360FieldOrgType': !exists(json, 'data360FieldOrgType') ? undefined : json['data360FieldOrgType'],
        'data360FieldOrgCity': !exists(json, 'data360FieldOrgCity') ? undefined : json['data360FieldOrgCity'],
        'data360FieldOrgRegion': !exists(json, 'data360FieldOrgRegion') ? undefined : json['data360FieldOrgRegion'],
        'data360FieldOrgStreetType': !exists(json, 'data360FieldOrgStreetType') ? undefined : json['data360FieldOrgStreetType'],
        'data360FieldOrgStreet': !exists(json, 'data360FieldOrgStreet') ? undefined : json['data360FieldOrgStreet'],
        'data360FieldOrgBuilding': !exists(json, 'data360FieldOrgBuilding') ? undefined : json['data360FieldOrgBuilding'],
        'data360FieldOrgHouse': !exists(json, 'data360FieldOrgHouse') ? undefined : json['data360FieldOrgHouse'],
        'data360FieldOrgPostcode': !exists(json, 'data360FieldOrgPostcode') ? undefined : json['data360FieldOrgPostcode'],
        'data360FieldOrgPhone': !exists(json, 'data360FieldOrgPhone') ? undefined : json['data360FieldOrgPhone'],
        'data360FieldOrgItn': !exists(json, 'data360FieldOrgItn') ? undefined : json['data360FieldOrgItn'],
        'data360FieldPersonCompanyName': !exists(json, 'data360FieldPersonCompanyName') ? undefined : json['data360FieldPersonCompanyName'],
        'data360FieldPersonLastname': !exists(json, 'data360FieldPersonLastname') ? undefined : json['data360FieldPersonLastname'],
        'data360FieldPersonFirstname': !exists(json, 'data360FieldPersonFirstname') ? undefined : json['data360FieldPersonFirstname'],
        'data360FieldPersonMiddlename': !exists(json, 'data360FieldPersonMiddlename') ? undefined : json['data360FieldPersonMiddlename'],
        'data360FieldPersonGender': !exists(json, 'data360FieldPersonGender') ? undefined : json['data360FieldPersonGender'],
        'data360FieldPersonPosition': !exists(json, 'data360FieldPersonPosition') ? undefined : json['data360FieldPersonPosition'],
        'data360FieldPersonSpecialization': !exists(json, 'data360FieldPersonSpecialization') ? undefined : json['data360FieldPersonSpecialization'],
    };
}

export function DcrData360ReportDtoToJSON(value?: DcrData360ReportDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dcrClientName': value.dcrClientName,
        'dcrModifiedDate': value.dcrModifiedDate === undefined ? undefined : (value.dcrModifiedDate.toISOString()),
        'dcrCreationDate': value.dcrCreationDate === undefined ? undefined : (value.dcrCreationDate.toISOString()),
        'dcrId': value.dcrId,
        'dcrEntity': RequestRecordTypeToJSON(value.dcrEntity),
        'dcrType': DcrStatusToJSON(value.dcrType),
        'dcrComment': value.dcrComment,
        'dcrStatus': RequestStatusToJSON(value.dcrStatus),
        'dcrData360Id': value.dcrData360Id,
        'dcrMorionId': value.dcrMorionId,
        'dcrCrmId': value.dcrCrmId,
        'dcrIsErrorRequest': value.dcrIsErrorRequest,
        'dcrCrmRequestId': value.dcrCrmRequestId,
        'dcrResponsibleId': value.dcrResponsibleId,
        'crmFieldOrgName': value.crmFieldOrgName,
        'crmFieldOrgType': value.crmFieldOrgType,
        'crmFieldOrgCompanyType': value.crmFieldOrgCompanyType,
        'crmFieldOrgCode': value.crmFieldOrgCode,
        'crmFieldOrgParent': value.crmFieldOrgParent,
        'crmFieldOrgCity': value.crmFieldOrgCity,
        'crmFieldOrgRegion': value.crmFieldOrgRegion,
        'crmFieldOrgStreetType': value.crmFieldOrgStreetType,
        'crmFieldOrgStreet': value.crmFieldOrgStreet,
        'crmFieldOrgBuilding': value.crmFieldOrgBuilding,
        'crmFieldOrgPostcode': value.crmFieldOrgPostcode,
        'crmFieldOrgPhone': value.crmFieldOrgPhone,
        'crmFieldPersonCompany': value.crmFieldPersonCompany,
        'crmFieldPersonLastname': value.crmFieldPersonLastname,
        'crmFieldPersonFirstname': value.crmFieldPersonFirstname,
        'crmFieldPersonMiddlename': value.crmFieldPersonMiddlename,
        'crmFieldPersonSex': value.crmFieldPersonSex,
        'crmFieldPersonPosition': value.crmFieldPersonPosition,
        'crmFieldPersonSpecialization': value.crmFieldPersonSpecialization,
        'crmFieldArchivereason': value.crmFieldArchivereason,
        'data360FieldOrgName': value.data360FieldOrgName,
        'data360FieldOrgType': value.data360FieldOrgType,
        'data360FieldOrgCity': value.data360FieldOrgCity,
        'data360FieldOrgRegion': value.data360FieldOrgRegion,
        'data360FieldOrgStreetType': value.data360FieldOrgStreetType,
        'data360FieldOrgStreet': value.data360FieldOrgStreet,
        'data360FieldOrgBuilding': value.data360FieldOrgBuilding,
        'data360FieldOrgHouse': value.data360FieldOrgHouse,
        'data360FieldOrgPostcode': value.data360FieldOrgPostcode,
        'data360FieldOrgPhone': value.data360FieldOrgPhone,
        'data360FieldOrgItn': value.data360FieldOrgItn,
        'data360FieldPersonCompanyName': value.data360FieldPersonCompanyName,
        'data360FieldPersonLastname': value.data360FieldPersonLastname,
        'data360FieldPersonFirstname': value.data360FieldPersonFirstname,
        'data360FieldPersonMiddlename': value.data360FieldPersonMiddlename,
        'data360FieldPersonGender': value.data360FieldPersonGender,
        'data360FieldPersonPosition': value.data360FieldPersonPosition,
        'data360FieldPersonSpecialization': value.data360FieldPersonSpecialization,
    };
}

