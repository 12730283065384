import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
    AuthorizePermission,
    FormRoot,
    ParamsButtonGroup,

} from '../../../../../components';
import { DefaultValue } from '../../../../../components/shared/components/DefaultValue';
import { AllPermissions } from '../../../../../core/const';
import { StoreProps, StyleProps } from '../../../../../stores';
import { UserItemStore } from '../../../../../stores/management/users/tabs/Users/UserItemStore';
import { Eye as EyeIcon } from '../../../../../icons/eye';
import { EyeOff as EyeOffIcon } from '../../../../../icons/eye-off';


export const ParametersTab = observer(
    ({ store, style }: StoreProps<UserItemStore> & StyleProps) => {
        const { t } = useTranslation();

        return (
            <>
                <FormRoot style={style}>
                    <DefaultValue
                        title={t('usersPage.login')}
                        store={store.parameters.form.fields.login}
                        testid="parametersUser-login"
                        style={{ width: '90%', marginBottom: 20 }}
                    />

                    <DefaultValue
                        title={t('usersPage.username')}
                        store={store.parameters.form.fields.name}
                        testid="parametersUser-username"
                        style={{ width: '90%', marginBottom: 20 }}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            marginBottom: 2,
                            flexDirection: 'row',
                        }}
                    >
                        <DefaultValue
                            title={t('usersPage.newPass')}
                            store={store.parameters.form.fields.password}
                            testid="parametersUser-newPass"
                            style={{ width: '90%' }}
                            type={!store.parameters.isShowPassword ? "password" : "text"}
                        />

                        <Button
                            onClick={() => store.parameters.isShowPassword = !store.parameters.isShowPassword }
                        >
                            <Tooltip title={!store.parameters.isShowPassword ? t('common.show'): t('common.hide')}>
                            {!store.parameters.isShowPassword ? <EyeIcon />: <EyeOffIcon />}
                            </Tooltip>
                        </Button>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            marginBottom: 2,
                            flexDirection: 'row',
                        }}
                    >
                        <DefaultValue
                            title={t('usersPage.confirmNewPass')}
                            store={store.parameters.form.fields.confirmPassword}
                            testid="parametersUser-confirmNewPass"
                            style={{ width: '90%'}}
                            type={!store.parameters.isShowConfirmPassword ? "password" : "text"}
                        />

                        <Button
                        sx={{height: 43, mt: 1}}
                            onClick={() => store.parameters.isShowConfirmPassword = !store.parameters.isShowConfirmPassword }
                        >
                            <Tooltip title={!store.parameters.isShowConfirmPassword ? t('common.show'): t('common.hide')}>
                            {!store.parameters.isShowConfirmPassword ? <EyeIcon />: <EyeOffIcon />}
                            </Tooltip>
                        </Button>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <AuthorizePermission
                            permissions={AllPermissions.user.update}
                        >
                            <ParamsButtonGroup
                                testid="parametersUser"
                                onCancel={() => store.parameters.form.reset()}
                                onSave={async () =>
                                    await store.parameters.form.submit()
                                }
                                isCancelBtnDisabled={
                                    store.parameters.form.processing ||
                                    store.parameters.form.equals
                                }
                                isSaveBtnDisabled={
                                    store.parameters.form.processing ||
                                    store.parameters.form.equals ||
                                    !store.parameters.form.valid
                                }
                            />
                        </AuthorizePermission>
                    </Box>
                </FormRoot>
            </>
        );
    }
);
