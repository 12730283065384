/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BoundTypeOrgDto,
    BoundTypeOrgDtoFromJSON,
    BoundTypeOrgDtoToJSON,
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface GetBoundTypeOrgsRequest {
    boundId: string;
    search?: string;
    jurisdictionId?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetBoundTypeOrgsCountRequest {
    boundId: string;
    search?: string;
    jurisdictionId?: string;
}

export interface RemoveBoundTypeOrgsRequest {
    boundId: string;
    requestBody: Array<string>;
}

export interface SetBoundTypeOrgsRequest {
    boundId: string;
    requestBody: Array<string>;
}

/**
 * 
 */
export class ClientTypeOrgsApi extends runtime.BaseAPI {

    /**
     */
    async getBoundTypeOrgsRaw(requestParameters: GetBoundTypeOrgsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<BoundTypeOrgDto>>> {
        if (requestParameters.boundId === null || requestParameters.boundId === undefined) {
            throw new runtime.RequiredError('boundId','Required parameter requestParameters.boundId was null or undefined when calling getBoundTypeOrgs.');
        }

        const queryParameters: any = {};

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.jurisdictionId !== undefined) {
            queryParameters['jurisdictionId'] = requestParameters.jurisdictionId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/clients/typeOrgs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BoundTypeOrgDtoFromJSON));
    }

    /**
     */
    async getBoundTypeOrgs(requestParameters: GetBoundTypeOrgsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<BoundTypeOrgDto>> {
        const response = await this.getBoundTypeOrgsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBoundTypeOrgsCountRaw(requestParameters: GetBoundTypeOrgsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        if (requestParameters.boundId === null || requestParameters.boundId === undefined) {
            throw new runtime.RequiredError('boundId','Required parameter requestParameters.boundId was null or undefined when calling getBoundTypeOrgsCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.jurisdictionId !== undefined) {
            queryParameters['jurisdictionId'] = requestParameters.jurisdictionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/clients/typeOrgs/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getBoundTypeOrgsCount(requestParameters: GetBoundTypeOrgsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getBoundTypeOrgsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeBoundTypeOrgsRaw(requestParameters: RemoveBoundTypeOrgsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<BoundTypeOrgDto>>> {
        if (requestParameters.boundId === null || requestParameters.boundId === undefined) {
            throw new runtime.RequiredError('boundId','Required parameter requestParameters.boundId was null or undefined when calling removeBoundTypeOrgs.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling removeBoundTypeOrgs.');
        }

        const queryParameters: any = {};

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/clients/typeOrgs/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BoundTypeOrgDtoFromJSON));
    }

    /**
     */
    async removeBoundTypeOrgs(requestParameters: RemoveBoundTypeOrgsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<BoundTypeOrgDto>> {
        const response = await this.removeBoundTypeOrgsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setBoundTypeOrgsRaw(requestParameters: SetBoundTypeOrgsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<BoundTypeOrgDto>>> {
        if (requestParameters.boundId === null || requestParameters.boundId === undefined) {
            throw new runtime.RequiredError('boundId','Required parameter requestParameters.boundId was null or undefined when calling setBoundTypeOrgs.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling setBoundTypeOrgs.');
        }

        const queryParameters: any = {};

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/clients/typeOrgs/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BoundTypeOrgDtoFromJSON));
    }

    /**
     */
    async setBoundTypeOrgs(requestParameters: SetBoundTypeOrgsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<BoundTypeOrgDto>> {
        const response = await this.setBoundTypeOrgsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
