import {Box, Typography} from '@mui/material';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {useAuthorizationStore} from '../stores';

interface PermissionsProps {
    permissions?: string;
    arrPermissions?: string[];
    children?: React.ReactNode;
    showWarning?: boolean;
    role?: string;
}

export const AuthorizePermission = observer(
    ({permissions, children, showWarning, role, arrPermissions}: PermissionsProps) => {

        const {t} = useTranslation();
        const store = useAuthorizationStore();
        const userPermissions = store.permissions;
        const accessRole = role && store.token?.roles?.includes(role!);

        const a = arrPermissions ?? [];
        if (permissions) {
            a.push(permissions)
        }

        const accessPermission =
            store.isAdmin ||
            a.length == 0 ||
            (userPermissions && userPermissions.filter(x => a.includes(x)).length > 0);

        const errorNode = (
            <>
                {showWarning ? (
                    <Box
                        sx={{display: 'flex', justifyContent: 'center', p: 2}}
                    >
                        <Typography variant="subtitle2" color="textSecondary">
                            {t('authorization.noPermission')}
                        </Typography>
                    </Box>
                ) : (
                    <></>
                )}
            </>
        );

        if (role && !accessRole) {
            return errorNode;
        }

        if (accessPermission || accessRole) {
            return <>{children}</>;
        }

        if (showWarning) {
            return (
                <Box sx={{display: 'flex', justifyContent: 'center', p: 2}}>
                    <Typography variant="subtitle2" color="textSecondary">
                        {t('authorization.noPermission')}
                    </Typography>
                </Box>
            );
        }

        return errorNode;
    }
);
