/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetPersonDepJobDto
 */
export interface SetPersonDepJobDto {
    /**
     * 
     * @type {string}
     * @memberof SetPersonDepJobDto
     */
    organizationDepId: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonDepJobDto
     */
    personId: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonDepJobDto
     */
    postId: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonDepJobDto
     */
    personJobId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SetPersonDepJobDto
     */
    statusId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SetPersonDepJobDto
     */
    recoveryReason?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SetPersonDepJobDto
     */
    tags?: Array<string> | null;
    /**
     *
     * @type {boolean}
     * @memberof UpdatePersonDepJobDto
     */
    telemedicine?: boolean | null;
}

export function SetPersonDepJobDtoFromJSON(json: any): SetPersonDepJobDto {
    return SetPersonDepJobDtoFromJSONTyped(json, false);
}

export function SetPersonDepJobDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetPersonDepJobDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationDepId': json['organizationDepId'],
        'personId': json['personId'],
        'postId': json['postId'],
        'personJobId': !exists(json, 'personJobId') ? undefined : json['personJobId'],
        'statusId': !exists(json, 'statusId') ? undefined : json['statusId'],
        'recoveryReason': !exists(json, 'recoveryReason') ? undefined : json['recoveryReason'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'telemedicine': !exists(json, 'telemedicine') ? undefined : json['telemedicine'],
    };
}

export function SetPersonDepJobDtoToJSON(value?: SetPersonDepJobDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationDepId': value.organizationDepId,
        'personId': value.personId,
        'postId': value.postId,
        'personJobId': value.personJobId,
        'statusId': value.statusId,
        'recoveryReason': value.recoveryReason,
        'tags': value.tags,
        'telemedicine': value.telemedicine,
    };
}

