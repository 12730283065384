import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { IconButton, SvgIcon, Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/system/colorManipulator';
import type { FC } from 'react';
import { useSettings } from '../../../core/hooks/use-settings';
import { SettingsButton } from '../../settings/settings-button';
import { AccountButton, JurisdictionButton, LanguageButton } from '../layout';
const TOP_NAV_HEIGHT = 64;

interface TopNavProps {
    sideNavWidth: number;
    onMobileNavOpen?: () => void;
}

export const TopNav: FC<TopNavProps> = (props) => {
    const { onMobileNavOpen, sideNavWidth, ...other } = props;
    const settings = useSettings();
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    return (
        <Box
            component="header"
            sx={{
                backdropFilter: 'blur(6px)',
                backgroundColor: (theme) =>
                    alpha(theme.palette.background.default, 0.8),
                position: 'sticky',
                left: {
                    lg: `${sideNavWidth}px`,
                },
                top: 0,
                width: {
                    lg: `calc(100% - ${sideNavWidth}px)`,
                },
                zIndex: (theme) => theme.zIndex.appBar,
            }}
            {...other}
        >
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{
                    minHeight: TOP_NAV_HEIGHT,
                    px: 2,
                }}
            >
                <Stack alignItems="center" direction="row" spacing={2}>
                    {!lgUp && (
                        <IconButton onClick={onMobileNavOpen}>
                            <SvgIcon>
                                <MenuOutlinedIcon />
                            </SvgIcon>
                        </IconButton>
                    )}
                </Stack>
                <Stack alignItems="center" direction="row" spacing={2}>
                    <SettingsButton onClick={settings.handleDrawerOpen} />
                    <JurisdictionButton />
                    <LanguageButton />
                    <AccountButton />
                </Stack>
            </Stack>
        </Box>
    );
};

TopNav.propTypes = {};
