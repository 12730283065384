import {Box, InputAdornment, TextField} from "@mui/material";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {AuthorizePermission, FormRow, ParamsButtonGroup} from "../../../../../components";
import {DefaultMultiSelect} from "../../../../../components/shared/components/DefaultMultiSelect";
import {AllPermissions} from "../../../../../core/const";
import {StoreProps, StyleProps} from "../../../../../stores";
import {SettingsTabStore} from "../../../../../stores/management/users/tabs/Groups/tabs/SettingsTabStore";

export const SettingsTab = observer(({ store, style }: StoreProps<SettingsTabStore> & StyleProps) => {
    const { t } = useTranslation();

    return (
        <Box>
            <Box sx={{ mb: 2, mt: 0, pt: 0 }}>
                <DefaultMultiSelect
                    autoFocus
                    testid="SettingsTab-clients"
                    style={{ width: 510, paddingTop: 10 }}
                    disableUnderline={false}
                    title={t('usersPage.dcrGroup.clients')}
                    nameExp={'name'}
                    store={store.form.fields.clients}
                />
            </Box>

            <FormRow>
                <TextField
                    style={{ width: 510, marginRight: 50 }}
                    id="time"
                    label={t('usersPage.responseTime')}
                    type="time"
                    value={store.form.fields.responseTime.value}
                    defaultValue={"00:00"}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {t('usersPage.format')}
                            </InputAdornment>
                        ),
                    }}
                    sx={{ width: 150 }}
                    onChange={(e) => store.form.fields.responseTime.value = e.target.value}
                    autoComplete='off'
                />
            </FormRow>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <AuthorizePermission permissions={AllPermissions.user.update}>
                    <ParamsButtonGroup
                        testid="SettingsTab"
                        onCancel={() => store.store.restore()}
                        onSave={async () => await store.form.submit()}
                        isCancelBtnDisabled={store.store.store.processing || store.form.equals}
                        isSaveBtnDisabled={store.store.store.processing || store.form.equals}
                    />
                </AuthorizePermission>

            </Box>
        </Box>
    );
});
