/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OCMObjectState,
    OCMObjectStateFromJSON,
    OCMObjectStateToJSON,
    PhoneTypeDto,
    PhoneTypeDtoFromJSON,
    PhoneTypeDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetPhoneTypeDto,
    SetPhoneTypeDtoFromJSON,
    SetPhoneTypeDtoToJSON,
} from '../models';

export interface CreateOrganizationPhoneTypeRequest {
    setPhoneTypeDto: SetPhoneTypeDto;
}

export interface DeleteOrganizationPhoneTypeRequest {
    id: string;
}

export interface GetOrganizationPhoneTypeByIdRequest {
    id: string;
}

export interface GetOrganizationPhoneTypeByNameRequest {
    name: string;
}

export interface GetOrganizationPhoneTypesRequest {
    search?: string;
    orderBy?: string;
    searchByExternalId?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface GetOrganizationPhoneTypesCountRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface UpdateOrganizationPhoneTypeRequest {
    id: string;
    setPhoneTypeDto: SetPhoneTypeDto;
}

/**
 * 
 */
export class OrganizationPhoneTypesApi extends runtime.BaseAPI {

    /**
     */
    async createOrganizationPhoneTypeRaw(requestParameters: CreateOrganizationPhoneTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneTypeDto>> {
        if (requestParameters.setPhoneTypeDto === null || requestParameters.setPhoneTypeDto === undefined) {
            throw new runtime.RequiredError('setPhoneTypeDto','Required parameter requestParameters.setPhoneTypeDto was null or undefined when calling createOrganizationPhoneType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/phones/types/organization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPhoneTypeDtoToJSON(requestParameters.setPhoneTypeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async createOrganizationPhoneType(requestParameters: CreateOrganizationPhoneTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneTypeDto> {
        const response = await this.createOrganizationPhoneTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteOrganizationPhoneTypeRaw(requestParameters: DeleteOrganizationPhoneTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOrganizationPhoneType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/types/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteOrganizationPhoneType(requestParameters: DeleteOrganizationPhoneTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneTypeDto> {
        const response = await this.deleteOrganizationPhoneTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationPhoneTypeByIdRaw(requestParameters: GetOrganizationPhoneTypeByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrganizationPhoneTypeById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/types/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationPhoneTypeById(requestParameters: GetOrganizationPhoneTypeByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneTypeDto> {
        const response = await this.getOrganizationPhoneTypeByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationPhoneTypeByNameRaw(requestParameters: GetOrganizationPhoneTypeByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneTypeDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getOrganizationPhoneTypeByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/types/organization/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationPhoneTypeByName(requestParameters: GetOrganizationPhoneTypeByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneTypeDto> {
        const response = await this.getOrganizationPhoneTypeByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationPhoneTypesRaw(requestParameters: GetOrganizationPhoneTypesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PhoneTypeDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/types/organization`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PhoneTypeDtoFromJSON));
    }

    /**
     */
    async getOrganizationPhoneTypes(requestParameters: GetOrganizationPhoneTypesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PhoneTypeDto>> {
        const response = await this.getOrganizationPhoneTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationPhoneTypesCountRaw(requestParameters: GetOrganizationPhoneTypesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/types/organization/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationPhoneTypesCount(requestParameters: GetOrganizationPhoneTypesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getOrganizationPhoneTypesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrganizationPhoneTypeRaw(requestParameters: UpdateOrganizationPhoneTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrganizationPhoneType.');
        }

        if (requestParameters.setPhoneTypeDto === null || requestParameters.setPhoneTypeDto === undefined) {
            throw new runtime.RequiredError('setPhoneTypeDto','Required parameter requestParameters.setPhoneTypeDto was null or undefined when calling updateOrganizationPhoneType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/phones/types/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetPhoneTypeDtoToJSON(requestParameters.setPhoneTypeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateOrganizationPhoneType(requestParameters: UpdateOrganizationPhoneTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneTypeDto> {
        const response = await this.updateOrganizationPhoneTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
