import {makeAutoObservable} from "mobx";
import {getApiConfiguration, IProcessing} from "../../../../../core";
import {DefaultMultiSelectStore} from "../../../../../components/shared/DefaultMultiSelectStore";
import {
    GenderDto,
    GendersApi,
    PersonStatusDto,
    PersonStatusesApi,
    PostDto,
    PostsApi,
    SpecialitiesApi,
    SpecialityDto
} from "../../../../../services/management";
import {LocalityFilterStore} from "../../../../../components/select/Locality/LocalityFilterStore";
import {ExportFormat, mappingExportFormatToString} from "../services";
import {ExportTableStore} from "../ExportTableStore";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";

export class ExportPersonStore implements IProcessing {
    readonly store: ExportTableStore;
    readonly statusesFilter: DefaultMultiSelectStore<PersonStatusDto> = new DefaultMultiSelectStore([],
        (filters) => new PersonStatusesApi(getApiConfiguration()).getPersonStatuses(filters));
    readonly postsFilter: DefaultMultiSelectStore<PostDto> = new DefaultMultiSelectStore([],
        (filters) => new PostsApi(getApiConfiguration()).getPosts(filters));
    readonly specialityFilter: DefaultMultiSelectStore<SpecialityDto> = new DefaultMultiSelectStore([],
        (filters) => new SpecialitiesApi(getApiConfiguration()).getSpecialities(filters));
    readonly genderFilter: DefaultMultiSelectStore<GenderDto> = new DefaultMultiSelectStore([],
        (filters) => new GendersApi(getApiConfiguration()).getGenders(filters));
    readonly localitiesFilter: LocalityFilterStore = new LocalityFilterStore();
    readonly exportTypes: DefaultMultiSelectStore<ExportFormat> = new DefaultMultiSelectStore([] as ExportFormat[], Object.values(ExportFormat) as ExportFormat[])
    public createDate: DefaultValueStore<Date> = new DefaultValueStore<Date>(new Date())
    public updateDate: DefaultValueStore<Date> = new DefaultValueStore<Date>(null)

    loading: boolean = false;
    error: boolean = false;
    downloaded: boolean = false;
    processing: boolean = false;

    constructor(store: ExportTableStore) {
        this.store = store;
        this.exportTypes.valueExp = undefined;
        this.exportTypes.nameExp = (e) => mappingExportFormatToString(e);
        this.exportTypes.required = true;

        makeAutoObservable(this);
    }


    get localities(): string[] {
        return this.localitiesFilter.items.map(x => x.id)
    }

    resetFilters() {
        this.statusesFilter.reset()
        this.postsFilter.reset()
        this.specialityFilter.reset()
        this.exportTypes.reset();
        this.genderFilter.reset()
        this.createDate.reset();
        this.updateDate.reset();
        this.localitiesFilter.resetItems();
    }

    async create(): Promise<void> {
        this.downloaded = false;
        // this._filename = "";
        this.loading = true;
        const filters = {
            statuses: this.statusesFilter.value?.length ? this.statusesFilter.value.map(x => x.id) : undefined,
            posts: this.postsFilter.value?.length ? this.postsFilter.value.map(x => x.id) : undefined,
            specialities: this.specialityFilter.value?.length ? this.specialityFilter.value.map(x => x.id) : undefined,
            genderId: this.genderFilter.value?.length ? this.genderFilter.value.map(x => x.id) : undefined,
            localityIds: this.localitiesFilter.items.length ? this.localitiesFilter.items.map(x => x.id) : undefined,
            exportTypes: this.exportTypes.value?.length ? this.exportTypes.value.reduce((sum, current) => sum + current, 0) : undefined,
            createDate: this.createDate.value,
            updateDate: this.updateDate.value
        }
        //const api: ExportFilesApi = new ExportFilesApi(getApiConfiguration() as any);
        try {
            // await api.getPersonsFile(filters)
            // this._blob = await result.blob;
            // this._filename = result.filename;
        } catch (e) {
        }
        await this.store.pull()
        this.loading = false;
    };

}
