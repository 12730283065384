import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {LocalitySelectStore} from "./LocalitySelectStore";
import {useTranslation} from "react-i18next";
import {LocalitySelect} from "./LocalitySelect";
import {LocalityFilterStore} from "./LocalityFilterStore";
import {LocalityTypeSelect} from "./LocalityTypeSelect";
import { TextInput } from '../../form';

interface Props {
    locality: LocalitySelectStore;
    store: LocalityFilterStore;
    style?: React.CSSProperties;
}

export const LocalitySelectWithTypes = observer(({locality, store, style}: Props) => {
    const [text, setText] = useState(locality._search);
    const {t} = useTranslation();
    const [step, setStep] = useState(0);
    const stepRef = React.useRef<HTMLDivElement| null>(null)   
    const [noStreet, setNoStreet] = useState(false);
    const [canBeEndpoint, setCanBeEndpoint] = useState(false);

    useEffect(() =>{
        setCanBeEndpoint(false)
    }, [store?.locality?.currentLocality?.localityType?.value?.id, store?.locality?.currentLocality?.value])

    useEffect(() => {
        const timeOutId = setTimeout(async () => {
            if (text === locality._search) {
                return;
            }

            await locality.setSearch(text);

        }, 500);
        return () => clearTimeout(timeOutId);
    }, [text]);

    const noStreetFn = (x: boolean) => {
        setNoStreet(x)
    }

    const canBeEndpointFn = (x: boolean) => {
        setCanBeEndpoint(x)
    }

    const stepLocality = () => {
        if(!stepRef.current) return;
        const localityElement = stepRef.current?.getElementsByClassName('autocompleteLocality').length;
        setStep(localityElement)
        setNoStreet(false)
        store.resetHouse()
    };

    return (
        <>
            <div ref={stepRef}>
                <LocalityTypeSelect store={locality} stepLocality={stepLocality}/>
                {store.locality.locality.localityType &&
                    <LocalitySelect locality={store.locality.locality} stepLocality={stepLocality} step={step} typeLocaly noStreetFn={noStreetFn} canBeEndpointFn={canBeEndpointFn} testid="localitySelectWithTypes"/>}
                {(store?.locality?.currentLocality?.isStreet || noStreet || canBeEndpoint) &&
                    <TextInput
                        data-testid="localitySelectWithTypes-house-input"
                        label={t('localities.house')}
                        value={store.house.value}
                        error={!store.house.valid}
                        errorText={store.house.error}
                        onChange={t => store.house.value = t}
                        style={{marginBottom: 25}}
                    />
                }
            </div>
        </>
    );
});
