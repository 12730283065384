/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Exchange.Analytics.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ElasticFeed,
    ElasticFeedFromJSON,
    ElasticFeedFromJSONTyped,
    ElasticFeedToJSON,
} from './ElasticFeed';

/**
 * 
 * @export
 * @interface ElasticFeedPagedResponse
 */
export interface ElasticFeedPagedResponse {
    /**
     * 
     * @type {number}
     * @memberof ElasticFeedPagedResponse
     */
    total?: number;
    /**
     * 
     * @type {Array<ElasticFeed>}
     * @memberof ElasticFeedPagedResponse
     */
    items?: Array<ElasticFeed> | null;
}

export function ElasticFeedPagedResponseFromJSON(json: any): ElasticFeedPagedResponse {
    return ElasticFeedPagedResponseFromJSONTyped(json, false);
}

export function ElasticFeedPagedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElasticFeedPagedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(ElasticFeedFromJSON)),
    };
}

export function ElasticFeedPagedResponseToJSON(value?: ElasticFeedPagedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(ElasticFeedToJSON)),
    };
}

