import {IForm, ILoad, IModal, IProcessing} from "../../../../core";
import {DefaultValueStore} from "../../../../components/shared/DefaultValueStore";
import {CommentDto, RequestApi} from "../../../../services/dcr";
import {makeAutoObservable} from "mobx";
import {RequestItemStore} from "./RequestItemStore";
import {t} from "i18next";
import {RequestTableStore} from "./RequestTableStore";

export class RequestCommentsStore implements ILoad, IModal, IForm, IProcessing {
    loading: boolean = false;
    comments: CommentDto[] = [];
    commentValue: DefaultValueStore<string> = new DefaultValueStore<string>("");
    processing: boolean = false;
    Open: boolean = false;
    RequestItemStore: RequestItemStore | null = null;
    store: RequestTableStore;

    constructor(store: RequestTableStore) {
        this.store = store;
        makeAutoObservable(this);
        this.commentValue.required = true;
        this.commentValue.setValidator((e) => e && e?.length > 256 ? t("errors.lengthText").replace("lengthText", '256') : "");
    }

    async openModal(RequestItemStore: RequestItemStore) {
        this.reset()
        this.RequestItemStore = RequestItemStore;
        await this.pull();
        this.validate()
        this.Open = true;
    }

    closeModal() {
        this!.Open = false
        this!.reset()
    }

    async pull(): Promise<void> {
        this.loading = true;
        if (!!this.RequestItemStore)
            this.comments = await new RequestApi().apiDcrV1RequestsIdCommentsGet({id: this.RequestItemStore!.id})
        this.loading = false;
    }

    reset(): void {
        this.RequestItemStore = null;
        this.comments = [];
        this.commentValue.reset()
    }

    async submit(): Promise<void> {
        this.processing = true;
        await new RequestApi().apiDcrV1RequestsCommentsPut({
            addCommentRequestCommand: {
                requestId: this.RequestItemStore?.id,
                text: this.commentValue.value
            }
        })
        this.processing = false;
        await this.pull()
        this.RequestItemStore!.dto.commentCount = this.comments.length
        this.RequestItemStore!.details.dto.commentCount = this.comments.length
        this.commentValue.reset();
    }

    get valid(): boolean {
        return this.commentValue.valid;
    }

    validate(): void {
        this.commentValue.validate();
    }
}
