import {Box} from "@mui/system";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {ConfirmationForm, DeleteButton, ExpertDialog, ParamsButtonGroup} from "../../../components";
import {DefaultValue} from "../../../components/shared/components/DefaultValue";
import {ExportItemStore} from "../../../stores/management/data/export/ExportItemStore";
import {callTheProcessingFunc} from "../../../stores";

interface Props {
    store: ExportItemStore;
    style?: React.CSSProperties;
}

export const ExportDetails = observer(({ store, style }: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <ExpertDialog
                title={`${t("buttons.delete")} ${t("dataPage.File")}`}
                open={store._store.deleteDialogState}
                onClose={() => store._store.deleteDialogState = false}
            >
                <ConfirmationForm
                    disabled={store.processing}
                    onSubmit={async () => await callTheProcessingFunc(store, store._store.delete.bind(store._store), store.pull.bind(this))()}
                    onClose={() => store._store.deleteDialogState = false}
                >
                    {t('common.deleteConfirm')} {t("dataPage.File")}?
                </ConfirmationForm>
            </ExpertDialog>
            <Box sx={{ p: 2 }}>
                <DefaultValue title={t('dataPage.table.name')} store={store.name}
                    style={{ width: '90%', marginBottom: 20 }} />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <ParamsButtonGroup
                        onCancel={() => store.name.reset()}
                        onSave={async () => await callTheProcessingFunc(store, store.save.bind(this), store.pull.bind(this))()}
                        isCancelBtnDisabled={store.processing || store.name.equals}
                        isSaveBtnDisabled={store.processing || store.name.equals || !store.name.valid}
                    />
                </Box>
                <DeleteButton onClick={() => store._store.deleteDialogState = true}
                              disabled={store.processing}/>
            </Box>
        </>
    );
});
