
import moment from "moment";
import {t} from "i18next";

export const toISODateString = (date: Date): string => moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

export const toDateString = (date?: Date | null): string => date ? moment(date).format("DD.MM.YYYY") : "";

export const toDateStringOrEmpty = (date?: Date | null): string => date ? moment(date).format("DD.MM.YYYY") : t("common.empty");

export const toDateTimeString = (date?: Date | null): string => date ? moment(date).format("DD.MM.YYYY HH:mm")  : "";

export const parseISODate = (iSODateString: string): Date => moment(iSODateString).toDate();

export const toDate = (date: Date): Date => moment(date).utc().toDate();

export const isValidDate = (iSODateString: string) => {
    try {
        return moment(iSODateString).isValid();
    }
    catch {
        return false;
    }
}

export const isValidDateRange = (min: Date, max: Date) => 
    endOfDay(max) >= startOfDay(min);

export const belongsToDateRange = (min: Date, date: Date, max: Date) => 
    startOfDay(min) < noonOfDay(date) && noonOfDay(date) >= endOfDay(max);

export const startOfDay = (date: Date): Date => 
    new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

export const endOfDay = (date: Date): Date => {
    const result = startOfDay(date);
    result.setHours(23, 59, 59);
    return result;
}

export const noonOfDay = (date: Date): Date => {
    const result = startOfDay(date);
    result.setHours(12, 0, 0);
    return result;
}