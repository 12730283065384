import {makeAutoObservable} from "mobx";
import {IFormValueWithError} from "../../core";
import {t} from "i18next";

export class DefaultValueStore<T> implements IFormValueWithError<T | undefined | null>{

    private _value: T | null | undefined;
    public error: string  = "";
    public validatorFunc: ((value: T | null | undefined) => string) | null;

    public validatorFuncAsync: ((value: T | null | undefined) => Promise<string>) | null = null;
    public onChanged: ((e: T | null | undefined) => void)[] = [];
    public formattingValue: ((value: T | null | undefined) =>  T | null | undefined) | null = null;

    private _initValue: T | null | undefined;
    private _type: string = 'string';
    required: boolean = false;
    disabled: boolean = false;

    getReadValue: (value: T | null | undefined) => string = (value) => !!value ? value + "" : t("common.empty");

    constructor(value: T | null | undefined, validator: ((value: T | null | undefined) => string) | null = null, onChanged: (e: T | null | undefined) => void = () => {}) {
        this._value = value;
        this._initValue = value;
        this.validatorFunc = validator;
        this.onChanged.push(onChanged);
        makeAutoObservable(this);
    }

    get type(): string{
        return this._type
    }

    set type(type: string){
        this._type = type;
    }

    get readValue(): string {
        return this.getReadValue(this.value)
    }

    setValueWithoutEffects(value: T | null | undefined){
        this._value = this.formattingValue ? this.formattingValue(value) : value;
        this.validate()
    }

    set value(value){
        this._value = this.formattingValue ? this.formattingValue(value) : value;
        this.validate()
        if(this.onChanged.length > 0)
            this.onChanged.forEach(x => x(value))
    }

    get value(): T | null | undefined{
        return this._value;
    }

    reset(){
        this._value = this._initValue;
        this.validate()
        if(this.onChanged.length > 0)
            this.onChanged.forEach(x => x(this._value))
        this.error = "";
    }

    get equals(): boolean{
        return this._initValue === this.value;
    }

    get valid(){
        return !this.error
    }

    async validate(){
        this.error = ''
        if(this.required){
            this.error = !this._value ? t("common.required") : ""
            if(!!this.error)
                return
        }
        if(this.validatorFunc){
            this.error = this.validatorFunc(this._value)
            if(!!this.error)
                return;
        }
        if(this.validatorFuncAsync){
            this.error = await this.validatorFuncAsync(this._value)
        }
    }

    setValidator(validator: ((value: T | null | undefined) => string) | null = null){
        this.validatorFunc = validator;
        this.validate()
    }

    update(dto: T | null | undefined): void {
        this._initValue = dto;
        this.reset()
    }
}
