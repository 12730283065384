import {makeAutoObservable} from 'mobx';
import {getApiConfiguration, IExpanded, IForm, IProcessing} from '../../../../../../core';
import {
    OrganizationWebsiteDto,
    OrganizationWebsitesApi,
    OrganizationWebsiteStatusesApi,
    WebsiteStatusDto
} from '../../../../../../services/management';
import {DefaultSelectStore} from "../../../../../../components/shared/DefaultSelectStore";
import i18n from "i18next";
import {OrganizationWebsiteTableStore} from './OrganizationWebsiteTableStore';
import {DefaultValueStore} from "../../../../../../components/shared/DefaultValueStore";


export class OrganizationWebsiteItemStore implements IProcessing, IExpanded, IForm {
    readonly _store: OrganizationWebsiteTableStore;
    dto: OrganizationWebsiteDto;
    processing: boolean = false;
    deleteDialogState: boolean = false;
    expand: boolean = false;
    status: DefaultSelectStore<WebsiteStatusDto>
    address: DefaultValueStore<string>
    description: DefaultValueStore<string>

    constructor(store: OrganizationWebsiteTableStore, dto: OrganizationWebsiteDto) {
        this._store = store;
        this.dto = dto;
        this.address = new DefaultValueStore<string>(dto.website.address,
            (value) => new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/).test(value ?? "") ? "" : i18n.t('errors.website'));
        this.address.required = true;
        this.status = new DefaultSelectStore<WebsiteStatusDto>(
            dto?.websiteStatus, (x) => new OrganizationWebsiteStatusesApi(getApiConfiguration()).getOrganizationWebsiteStatuses(x),
            (value) => !value ? i18n.t('errors.empty') : "");
        this.description = new DefaultValueStore<string>(dto?.description ?? '', (value) =>
            value?.length! > 180 ? i18n.t('errors.lengthMax').replace("lengthMax", "180") : "")
        makeAutoObservable(this);
    }

    async delete(): Promise<void> {
        this.processing = true;
        const api: OrganizationWebsitesApi = new OrganizationWebsitesApi(getApiConfiguration());
        try {
            const result = await api.removeOrganizationWebsites({
                organizationId: this._store.organizationId,
                removeOrganizationWebsiteDto: [
                    {
                        websiteAddress: this.dto.website.address,
                        organizationId: this._store.organizationId,
                    }
                ]
            });
            this._store.items = this._store.items.filter(t => t.dto.id !== result[0].id);
            await this._store.pull();
        } catch (e) {

        }
        this._store.current = null;
        this.processing = false;
    };

    get equals(): boolean {
        return this.status.equals && this.address.equals && this.description.equals
    }

    get valid(): boolean {
        return this.status.valid && this.address.valid && this.description.valid
    }

    validate() {
        this.status.validate();
        this.address.validate();
        this.description.validate()
    }

    update(dto: OrganizationWebsiteDto) {
        this.dto = dto;
        this.status.update(dto.websiteStatus);
        this.address.update(dto.website.address)
        this.description.update(dto.description)
        // this.type.update(dto.phoneType);
        // this.category.update(dto.phoneCategory);
    }

    reset(): void {
        this.status.reset();
        this.address.reset();
        this.description.reset()
        // this.category.reset();
        // this.type.reset();
        this.validate();
    }

    async submit() {
        this.validate()
        if (!this.valid)
            return
        this.processing = true;
        const api: OrganizationWebsitesApi = new OrganizationWebsitesApi(getApiConfiguration());

        try {
            let item = await api.updateOrganizationWebsite({
                id: this.dto.id,
                updateOrganizationWebsiteDto: {
                    websiteStatusId: this.status.value?.id!,
                    address: this.address.value ?? this.dto.website.address,
                    description: this.description.value
                }
            })
            this._store.updateState();
        } finally {
            this.processing = false;
        }
    }
}
