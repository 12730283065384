import {observer} from 'mobx-react';
import {Box, Container} from "@mui/material";
import {ReactNode} from "react";
import {useSettings} from "../../../core/hooks/use-settings";

export const PageLayout = observer((props: {children?: ReactNode, header?: ReactNode }) => {
    const settings = useSettings();

    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                    paddingTop: '15px',
                    paddingBottom: 0,
                    backgroundColor: 'background.default',
                }}
            >
                <Container maxWidth={settings.stretch ? false : 'xl'} style={{transition: '0.4s'}}>
                    <Box
                        sx={{
                            mb: 3
                        }}
                    >
                        {props.header}
                        {props.children}
                    </Box>
                </Container>
            </Box>
        </>
    );
});
