import {Box, Breadcrumbs, Typography} from "@mui/material";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {AuthorizePermission, Page, PageTab, PageTabs} from "../../../components";
import {useManagementStore} from "../../../stores";
import {ExportDictionaryCreate} from "./tabs/ExportDictionaryCreate";
import {ExportOrganizationCreate} from "./tabs/ExportOrganizationCreate";
import {ExportPersonCreate} from "./tabs/ExportPersonCreate";
import {ExportTable} from "./ExportTable";
import {ExportPersonStore} from "../../../stores/management/data/export/tabs/ExportPersonStore";
import {ExportOrganizationStore} from "../../../stores/management/data/export/tabs/ExportOrganizationStore";
import {ExportDictionaryStore} from "../../../stores/management/data/export/tabs/ExportDictionaryStore";
import {ExportAddresses} from "./tabs/ExportAddresses";
import {AllPermissions} from "../../../core/const";

export const ExportTabs = observer(() => {
    const { exportStore } = useManagementStore();
    const { t } = useTranslation();

    const renderTabs = (index: number) => {

        switch (index) {
            case 0:
                return <AuthorizePermission
                    arrPermissions={[AllPermissions.export.address.typelocality.read, AllPermissions.export.address.typelocality.read]}
                    showWarning>
                    <ExportAddresses store={exportStore.exportAddressesStore}></ExportAddresses>
                </AuthorizePermission>
            case 1:
                return <ExportTable store={exportStore.exportPersonTableStore}> <ExportPersonCreate
                    exportPersonCreateStore={exportStore.exportPersonTableStore.creator as ExportPersonStore}/>
                </ExportTable>
            case 2:
                return <ExportTable store={exportStore.exportOrganizationTableStore}> <ExportOrganizationCreate
                    exportOrganizationCreateStore={exportStore.exportOrganizationTableStore.creator as ExportOrganizationStore}/>
                </ExportTable>
            case 3:
                return <ExportTable store={exportStore.exportDictionaryTableStore}> <ExportDictionaryCreate
                    exportDictionaryCreateStore={exportStore.exportDictionaryTableStore.creator as ExportDictionaryStore}/>
                </ExportTable>
        }
    };

    return (
        <Box>
                {/* <AuthorizePermission permissions=''> */}
                <Box>
                    <Breadcrumbs
                        separator="/"
                        sx={{ mt: 1 }}
                    >
                        <Typography color="primary"
                            variant="h6">{t('pageNames.data')}</Typography>
                        <Typography color="textSecondary"
                            variant="h6">
                            {t('dataPage.dataTabs.export')}
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <PageTabs
                    index={exportStore.tabIndex}
                    onChange={(t) => {
                        exportStore.tabIndex = t
                    }}
                >
                    <PageTab label={t('dataPage.export.addresses').toUpperCase()}/>
                    {/*<PageTab label={t('dataPage.dataTabs.persons').toUpperCase()}/>*/}
                    {/*<PageTab label={t('dataPage.dataTabs.organizations').toUpperCase()}/>*/}
                    {/*<PageTab label={t('dataPage.dataTabs.dictionaries').toUpperCase()}/>*/}
                </PageTabs>
                <Page style={{ flex: 1, width: '100%', height: '100%' }}>
                    {renderTabs(exportStore.tabIndex)}
                </Page>
        </Box>
    );
})
