import {makeAutoObservable} from 'mobx';
import {getApiConfiguration} from "../../../core";
import {LocalitiesApi, LocalityDto, QueryType, TypeLocalitiesApi, TypeLocalityDto} from '../../../services/management';
import {DefaultSelectStore} from "../../shared/DefaultSelectStore";
import {JurisdictionData} from "../../../stores/authorization/AuthorizationStore";

export interface ILocalityFilterState extends LocalityDto {
    locality?: ILocalityFilterState;
}

export class LocalitySelectStore {
    items?: LocalityDto[];
    _value: ILocalityFilterState | null = null;
    loading: boolean = false;
    locality: LocalitySelectStore | null = null;
    _search: string = '';
    _store: LocalitySelectStore | undefined | null;
    _externalStreetId: string = '6';
    onValueChange?: (dto: LocalityDto | null) => void;
    typeAddress: boolean = false;
    localityType: DefaultSelectStore<TypeLocalityDto>;
    cityId?: string | undefined;
    isEndpoint: boolean = false;

    constructor(store?: LocalitySelectStore | null, state?: ILocalityFilterState, onValueChange?: (dto: LocalityDto | null) => void) {
        this.onValueChange = onValueChange;
        this.cityId = store?.cityId; 
        this.localityType = new DefaultSelectStore<TypeLocalityDto>(null,
            (x) => new TypeLocalitiesApi(getApiConfiguration()).getTypeLocalities(x))
        if (state) {
            this._value = state
            if (state.locality) {
                this.typeAddress = true;
                this.locality = new LocalitySelectStore(this, state.locality, onValueChange);
            }
        }

        this._store = store;
        makeAutoObservable(this);
    }

    add() {
        this.locality = new LocalitySelectStore(this, undefined, this.onValueChange);
    }

    resetLocalityType (){
        this.value = null;
    }

    onTypeAddress(i: boolean) {
        this.typeAddress = i;
    }

    set value(dto: LocalityDto | null) {
        if (!dto || (this._value && dto.id !== this._value?.id)) {
            this.locality = null;
        }
        this._value = dto;
        if (this.onValueChange) {
            this.onValueChange(this._value)
        }
        if (dto?.typeLocality?.id === JurisdictionData.typeLocalityIds.cityId) {
            this.cityId = dto.id
        }
        if (this._value)
            this.checkEndpointFn(this._value.id).then()
    }

    get state(): ILocalityFilterState | null {
        if (!this._value) return null;

        const result: ILocalityFilterState = {
            nameRus: this._value.nameRus,
            nameEng: this._value.nameEng,
            id: this._value?.id,
            typeLocality: this._value?.typeLocality,
            parent: this._value?.parent,
            parentId: this._value.parentId,
            nameLocal: this._value.nameLocal
        }

        if (this.locality && this.locality.state) {
            result.locality = this.locality.state
        }

        return result;
    }

    get value() {
        return this._value;
    }

    get id(): string {
        if (this._value) {
            return this._value.id;
        }

        return ''
    }

    get name(): string {
        if (this._value) {
            return this._value.typeLocality.nameRus + " " + this._value.nameRus;
        }

        return ''
    }

    delete() {
        if (this._store) {
            this._store.locality = null
        }
        if (this.onValueChange) {
            this.onValueChange(this._value)
        }
        if(this._value?.parentId) {
            this.checkEndpointFn(this._value?.parentId).then()
        }
    }

    async setSearch(value: string): Promise<void> {
        // if (this._value) return //если выбрали селектом - не искать
        this._search = value;
        await this.pull();
    }

    async pull(): Promise<void> {
        const api: LocalitiesApi = new LocalitiesApi(getApiConfiguration());
        this.loading = true;
        const filters = {
            page: 1,
            size: 25,
            search: this._search,
            parentId: this._store?.id,
        };
        if (this.localityType.value?.id) {
            //@ts-ignore
            filters.byLocalityTypeId = this.localityType.value?.id
            this.onTypeAddress(true)
        } else {
            //@ts-ignore
            filters.queryType = QueryType.Tree
        }

        this.items = await api.getLocalities(filters);
        this.loading = false;
    };

    async checkEndpointFn(id: string): Promise<void> {
        if(!id)
            return;
        this.loading = true;
        const api: LocalitiesApi = new LocalitiesApi(getApiConfiguration());
        //@ts-ignore
        this.isEndpoint = (await api.canBeEndpoint({localityId: id})) === "true"
        this.loading = false;
    }

    get isStreet(): boolean {
        return this.value?.typeLocality?.categoryLocality?.externalId == this._externalStreetId ||
            this.value?.typeLocality?.categoryLocality?.nameRus == "Улицы";
    }
}
