/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    MiddleNameDto,
    MiddleNameDtoFromJSON,
    MiddleNameDtoToJSON,
    OCMObjectState,
    OCMObjectStateFromJSON,
    OCMObjectStateToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetMiddleNameDto,
    SetMiddleNameDtoFromJSON,
    SetMiddleNameDtoToJSON,
} from '../models';

export interface CreateMiddleNameRequest {
    setMiddleNameDto: SetMiddleNameDto;
}

export interface DeleteMiddleNameRequest {
    id: string;
}

export interface GetMiddleNameByIdRequest {
    id: string;
}

export interface GetMiddleNameByNameRequest {
    name: string;
}

export interface GetMiddleNameBySearchRequest {
    search?: string;
}

export interface GetMiddleNamesRequest {
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface GetMiddleNamesCountRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface GetMiddleNamesCountFallbackRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface GetMiddleNamesFallbackRequest {
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface UpdateMiddleNameRequest {
    id: string;
    setMiddleNameDto: SetMiddleNameDto;
}

/**
 * 
 */
export class MiddleNamesApi extends runtime.BaseAPI {

    /**
     */
    async createMiddleNameRaw(requestParameters: CreateMiddleNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MiddleNameDto>> {
        if (requestParameters.setMiddleNameDto === null || requestParameters.setMiddleNameDto === undefined) {
            throw new runtime.RequiredError('setMiddleNameDto','Required parameter requestParameters.setMiddleNameDto was null or undefined when calling createMiddleName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/persons/middlenames`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetMiddleNameDtoToJSON(requestParameters.setMiddleNameDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MiddleNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async createMiddleName(requestParameters: CreateMiddleNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MiddleNameDto> {
        const response = await this.createMiddleNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteMiddleNameRaw(requestParameters: DeleteMiddleNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MiddleNameDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMiddleName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/middlenames/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MiddleNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteMiddleName(requestParameters: DeleteMiddleNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MiddleNameDto> {
        const response = await this.deleteMiddleNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMiddleNameByIdRaw(requestParameters: GetMiddleNameByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MiddleNameDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMiddleNameById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/middlenames/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MiddleNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async getMiddleNameById(requestParameters: GetMiddleNameByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MiddleNameDto> {
        const response = await this.getMiddleNameByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMiddleNameByNameRaw(requestParameters: GetMiddleNameByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MiddleNameDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getMiddleNameByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/middlenames/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MiddleNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async getMiddleNameByName(requestParameters: GetMiddleNameByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MiddleNameDto> {
        const response = await this.getMiddleNameByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMiddleNameBySearchRaw(requestParameters: GetMiddleNameBySearchRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MiddleNameDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/middlenames/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MiddleNameDtoFromJSON));
    }

    /**
     */
    async getMiddleNameBySearch(requestParameters: GetMiddleNameBySearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MiddleNameDto>> {
        const response = await this.getMiddleNameBySearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMiddleNamesRaw(requestParameters: GetMiddleNamesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MiddleNameDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/middlenames`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MiddleNameDtoFromJSON));
    }

    /**
     */
    async getMiddleNames(requestParameters: GetMiddleNamesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MiddleNameDto>> {
        const response = await this.getMiddleNamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMiddleNamesCountRaw(requestParameters: GetMiddleNamesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/middlenames/count/fallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getMiddleNamesCount(requestParameters: GetMiddleNamesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getMiddleNamesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMiddleNamesCountFallbackRaw(requestParameters: GetMiddleNamesCountFallbackRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/middlenames/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getMiddleNamesCountFallback(requestParameters: GetMiddleNamesCountFallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getMiddleNamesCountFallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMiddleNamesFallbackRaw(requestParameters: GetMiddleNamesFallbackRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MiddleNameDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/middlenames/fallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MiddleNameDtoFromJSON));
    }

    /**
     */
    async getMiddleNamesFallback(requestParameters: GetMiddleNamesFallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MiddleNameDto>> {
        const response = await this.getMiddleNamesFallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateMiddleNameRaw(requestParameters: UpdateMiddleNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MiddleNameDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMiddleName.');
        }

        if (requestParameters.setMiddleNameDto === null || requestParameters.setMiddleNameDto === undefined) {
            throw new runtime.RequiredError('setMiddleNameDto','Required parameter requestParameters.setMiddleNameDto was null or undefined when calling updateMiddleName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/persons/middlenames/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetMiddleNameDtoToJSON(requestParameters.setMiddleNameDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MiddleNameDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateMiddleName(requestParameters: UpdateMiddleNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MiddleNameDto> {
        const response = await this.updateMiddleNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
