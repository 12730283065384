import {makeObservable, observable} from 'mobx';
import {ManagementStore} from '../../../index';
import {getApiConfiguration, getObject, setObject} from '../../../../../core';
import {UsersApi} from '../../../../../services/management';
import {PagedItems, TableLocalFilters, TableStore} from '../../../../utils';
import {UserItemStore} from "./UserItemStore";
import {DefaultFormModalStore} from "../../../../../components/shared/DefaultFormModalStore";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";
import i18n from '../../../../../i18n';

interface UsersStoreLocalFilters extends TableLocalFilters<UserItemStore> {
}

export class UserTableStore extends TableStore<UserItemStore> {
    readonly store: ManagementStore;
    readonly creator: DefaultFormModalStore<{
        name: DefaultValueStore<string>,
        userName: DefaultValueStore<string>,
        password: DefaultValueStore<string>,
        rePassword: DefaultValueStore<string>
    }> = new DefaultFormModalStore({
        name: new DefaultValueStore<string>(""),
        userName: new DefaultValueStore<string>(""),
        password: new DefaultValueStore<string>(""),
        rePassword: new DefaultValueStore<string>("",
            (e) => this.creator.fields.password.value != this.creator.fields.rePassword.value ? i18n.t("common.notMatch") : '')
    },  this.create.bind(this))

    current: UserItemStore | undefined | null;

    constructor(store: ManagementStore) {
        super();
        this.store = store;
        this.creator.fields.name.required = true;
        this.creator.fields.userName.required = true;
        this.creator.fields.password.required = true;
        this.creator.fields.rePassword.required = true;

        const filters = getObject<UsersStoreLocalFilters>('users-store');
        if (filters) {
            this._page = filters.page || 0;
            this._size = filters.size;
            this.search.setValueWithoutEffects(filters.search);
            this._order = filters.order;
            this._orderBy = filters.orderBy;
        }

        makeObservable(this, {
            current: observable,
        });
    }

    async getUser(id: string): Promise<UserItemStore | undefined | null> {
        if (this.current && this.current.dto.id === id) {
            return this.current;
        }
        const existItem = this.items.find(t => t.dto.id === id);
        if (existItem) {
            return this.current = existItem;
        }
        const api = new UsersApi(getApiConfiguration());
        const result = await api.getUserById({id: id});
        if (result) {
            return this.current = new UserItemStore(this, result);
        }
        return undefined;
    };

    async request(): Promise<PagedItems<UserItemStore>> {
        const filters = {
            page: this.page + 1,
            size: this.size,
            search: this.search.value?? '',
            order: this.order,
            orderBy: this.orderBy,
        };

        const api = new UsersApi(getApiConfiguration());
        const result = await api.getUsers(filters);
        const count = await api.getUsersCount(filters);
        const newList: UserItemStore[] = [];
        for (const item of result) {
            const existsItem = this.items.find(t => t.dto.id === item.id);
            if (existsItem) {
                existsItem.parameters.update(item);
                newList.push(existsItem);
                continue;
            }
            newList.push(new UserItemStore(this, item))
        }

        return new PagedItems<UserItemStore>(newList,count.count)
    };

    onFiltersSave() {
        setObject<UsersStoreLocalFilters>('users-store', {
            search: this.search.value?? '',
            order: this.order,
            orderBy: this.orderBy,
            page: this.page,
            size: this.size,
        });
    }

    async create(): Promise<void> {
        const result = await new UsersApi(getApiConfiguration()).createUser({
            setUserDto: {
                username: this.creator.model.userName,
                password: this.creator.model.password,
                name: this.creator.model.name
            }
        });
        const newUserResult = await new UsersApi(getApiConfiguration()).getUserById({id: result.id});
        this.current = new UserItemStore(this, newUserResult);
        this.items = [this.current, ...this.items];
        this.current.expand = true;
    };
}
