import {action, computed, makeObservable} from 'mobx';
import {DefaultValueStore} from '../../../../components/shared/DefaultValueStore';
import {getApiConfiguration, getObject, IProcessing, setObject, toDateTimeString} from '../../../../core';
import i18n from '../../../../i18n';
import {ExportFile, ExportManagementApi} from '../../../../services/export';
import {ExportTableStore, ExportType} from './ExportTableStore';
import {downloadFile} from "../../../../core/blob";
import {DynamicalComponentStore} from "../../../utils";

interface PersonViewModelLocalFilters {
    expand: boolean;
}

export class ExportItemStore extends DynamicalComponentStore implements IProcessing {
    readonly _store: ExportTableStore;
    public dto: ExportFile;
    name: DefaultValueStore<string>;
    processing: boolean = false;
    key: ExportType;

    constructor(key: ExportType, store: ExportTableStore, dto: ExportFile) {
        super();
        this._store = store;
        this.dto = dto;
        this.key = key;

        this.name = new DefaultValueStore<string>(dto.storageFile?.fileName!, (value) => new RegExp('^[^\/:*?"<>.|+]+$').test(value || '') ? '' : `${i18n.t('errors.empty')}, ${i18n.t('errors.invalidSymbols')}`);

        const filters = getObject<PersonViewModelLocalFilters>(`export${this.key}-item-${this.id}`);
        if (filters) {
            this._expand = filters.expand;
        }

        makeObservable(this, {
            modifiedAt: computed,
            status: computed,
            id: computed,
            save: action,
            exportFile: action,
        });
    }

    get modifiedAt() {
        if (this.dto.createdAt) {
            return toDateTimeString(this.dto.createdAt);
        }
        return '';
    }

    get status() {
        return this.dto.status ?? 0;
    }

    get id() {
        return this.dto.id || ''
    }

    async updateState(): Promise<void> {
        this.saveFilters();
    }

    update(dto: ExportFile) {
        this.dto = dto;
        this.name.update(dto.storageFile?.fileName ? (dto.storageFile?.fileName)?.slice(0, -4) : '');
    }

    async save(): Promise<void> {
        await new ExportManagementApi(getApiConfiguration()).editFileName({
            fileId: this.id,
            newFileName: this.name.value ?? '',
        });
    };

    async exportFile(): Promise<void> {
        await new ExportManagementApi(getApiConfiguration())
            .downloadFile({fileId: this.id})
            .then(async x =>
                await downloadFile(await x.blob(),
                    x.headers?.get('Content-Disposition')?.split('filename=')[1]?.split(';')[0] ?? `Export${this.key}Report_${Date.now()}.csv`)
            );
    }

    saveFilters() {
        setObject<PersonViewModelLocalFilters>(`export${this.key}-item-${this.id}`, {
            expand: this._expand
        });
    }

    async pull() {
        await this._store.pull()
    }
}
