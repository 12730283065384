/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {ImportLocality, ImportLocalityFromJSON,} from '../models';

export interface ImportLocalitiesRequest {
    formFile?: any;
}

/**
 *
 */
export class ImportExportLocalitiesApi extends runtime.BaseAPI {

    /**
     */
    async getCityLocalitiesRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<any> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/localityimportexport/GetCityLocalities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return response;
    }

    /**
     */
    async getCityLocalities(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<any> {
        return await this.getCityLocalitiesRaw(initOverrides);
    }

    /**
     */
    async getExportTypeLocalitiesRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<any> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/localityimportexport/GetExportTypeLocalities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return response;
    }

    /**
     */
    async getExportTypeLocalities(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<any> {
        return await this.getExportTypeLocalitiesRaw(initOverrides);
    }

    /**
     */
    async importLocalitiesRaw(requestParameters: ImportLocalitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ImportLocality>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            {contentType: 'multipart/form-data'},
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.formFile !== undefined) {
            //@ts-ignore
            formParams.append('formFile', requestParameters.formFile);
        }

        const response = await this.request({
            path: `/api/management/v1/addresses/localityimportexport/ImportLocalities`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ImportLocalityFromJSON));
    }

    /**
     */
    async importLocalities(requestParameters: ImportLocalitiesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ImportLocality>> {
        const response = await this.importLocalitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
