export function compare<T>(a: T, b: T, property: string | ((obj1: T, obj2: T) => any)): 1 | -1 | 0 {
    const compareByProperty  =  (_a: T, _b: T, _property: string) => {
        if ((_a as any)[_property]  < (_a as any)[_property]) {
            return -1;
        }
        if ((_a as any)[_property] > (_a as any)[_property]) {
            return 1;
        }
        return 0;
    };

    const compareByConvert  =  (_a: T, _b: T, _property: (obj: T) => any) => {
        if (_property(_a) < _property(_b)) {
            return -1;
        }
        if (_property(_a) > _property(_b)) {
            return 1;
        }
        return 0;
    };

    if (typeof(property) == 'string') {
        return compareByProperty(a, b, String(property));
    }
    else {
        return compareByConvert(a, b, property as (obj: T) => any);
    }
}

export function compareArraysIds(a: string[], b: string[]){
    var isEqual = false;
    if (Array.isArray(a) && Array.isArray(b) && a.length == b.length){
        a.sort();
        b.sort();
        var i;
        for (i = 0; i < a.length; i++){
            if (a[i] === b[i]){
                isEqual = true;
            } else{
                isEqual = false;
                break;
            }
        }

    }
    return isEqual;
}