import {observer} from "mobx-react";
import {Box} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {AuthorizePermission, FormRoot, ParamsButtonGroup} from "../../../components";
import {DefaultMultiSelect} from "../../../components/shared/components/DefaultMultiSelect";
import {callTheProcessingFunc, StoreProps, StyleProps} from "../../../stores";
import SnackbarUtils from "../../../components/snackbar/SnackbarUtils";
import {AllPermissions} from "../../../core/const";
import {ClientJurisdictionsStore} from "../../../stores/management/clients/tabs/ClientJurisdictionsStore";

export const JurisdictionsTab = observer(({store, style}: StoreProps<ClientJurisdictionsStore> & StyleProps) => {
    const {t} = useTranslation();

    return (
        <FormRoot style={style}>
            <DefaultMultiSelect store={store.allJurisdictionsControl}
                                title={t('usersPage.tabs.jurisdictions')}
                                valueExp="id"
                                nameExp="nameRus"
                                tags={true}
            />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <AuthorizePermission permissions={AllPermissions.client.update}>
                    <ParamsButtonGroup
                        testid="parametersUser"
                        onCancel={() => store.allJurisdictionsControl.reset()}
                        onSave={async () => await callTheProcessingFunc(store, (e) => store.submit(), () => {
                            store.pull()
                            SnackbarUtils.success(t("common.saved"))
                        })()}
                        isCancelBtnDisabled={store.processing || store.allJurisdictionsControl.equals}
                        isSaveBtnDisabled={store.processing || store.allJurisdictionsControl.equals || !store.allJurisdictionsControl.valid}
                    />
                </AuthorizePermission>

            </Box>
        </FormRoot>
    );
});
