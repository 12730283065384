import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import React, {CSSProperties, FC, ReactNode} from "react";
import {ArrowRight as ArrowRightIcon} from "../../icons/arrow-right";
import {useTranslation} from "react-i18next";

interface OverviewProps {
    amount: number;
    title: string;
    icon?: ReactNode;
    children?: React.ReactNode;
    style?: CSSProperties | undefined;
    onClickDetails?: () => void;
}

export const Overview: FC<OverviewProps> = (props) => {
    const {t} = useTranslation();

    return (
        <Card>
            <Stack
                style={props.style}
                alignItems="center"
                direction={{
                    xs: 'column',
                    sm: 'row'
                }}
                spacing={3}
                sx={{
                    px: 4,
                    py: 3
                }}
            >
                <div>
                    {props.icon}
                </div>
                <Box sx={{flexGrow: 1}}>
                    <Typography
                        color="text.secondary"
                        variant="body2"
                    >
                        {props.title}
                    </Typography>
                    <Typography
                        color="text.primary"
                        variant="h4"
                    >
                        {props.amount}
                    </Typography>
                </Box>
                {
                    props.children &&
                    <Box sx={{ width: 200 }}>
                        {props.children}
                    </Box>
                }

            </Stack>
            {
                props.onClickDetails &&
                <>
                    <Divider/>
                    <CardActions>
                        <Button
                            color="inherit"
                            onClick={props.onClickDetails}
                            endIcon={(
                                <SvgIcon>
                                    <ArrowRightIcon/>
                                </SvgIcon>
                            )}
                            size="small"
                        >
                            {t("common.details")}
                        </Button>
                    </CardActions>
                </>
            }
        </Card>
    );
}
