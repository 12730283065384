/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface UpdateOrganizationEmailDto
 */
export interface UpdateOrganizationEmailDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationEmailDto
     */
    emailStatusId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationEmailDto
     */
    emailTypeId?: string | null;
    /**
     *
     * @type {string}
     * @memberof UpdateOrganizationEmailDto
     */
    description?: string | null;
}

export function UpdateOrganizationEmailDtoFromJSON(json: any): UpdateOrganizationEmailDto {
    return UpdateOrganizationEmailDtoFromJSONTyped(json, false);
}

export function UpdateOrganizationEmailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOrganizationEmailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailStatusId': !exists(json, 'emailStatusId') ? undefined : json['emailStatusId'],
        'emailTypeId': !exists(json, 'emailTypeId') ? undefined : json['emailTypeId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function UpdateOrganizationEmailDtoToJSON(value?: UpdateOrganizationEmailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailStatusId': value.emailStatusId,
        'emailTypeId': value.emailTypeId,
        'description': value.description,
    };
}

