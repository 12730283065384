/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 * 
 * @export
 * @interface CreateAssignedPersonTagDto
 */
export interface CreateAssignedPersonTagDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAssignedPersonTagDto
     */
    personId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAssignedPersonTagDto
     */
    tagId?: string | null;
}

export function CreateAssignedPersonTagDtoFromJSON(json: any): CreateAssignedPersonTagDto {
    return CreateAssignedPersonTagDtoFromJSONTyped(json, false);
}

export function CreateAssignedPersonTagDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAssignedPersonTagDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personId': !exists(json, 'personId') ? undefined : json['personId'],
        'tagId': !exists(json, 'tagId') ? undefined : json['tagId'],
    };
}

export function CreateAssignedPersonTagDtoToJSON(value?: CreateAssignedPersonTagDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personId': value.personId,
        'tagId': value.tagId,
    };
}

