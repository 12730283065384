import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {ResponseTableStore} from "../../../stores/management/dcr/responses/ResponseTableStore";
import {Cell, EmptyBox, ExpandButton, ExpandRow, HCell, Pagination, Progress} from "../../../components";
import {ResponseDetails} from "./ResponseDetails";
import {DefaultMultiSelect} from "../../../components/shared/components/DefaultMultiSelect";
import {CopyToClipboard} from "../../../components/CopyToClipboard";
import React, {Fragment, useRef, useState} from "react";
import {Box, Card, FormControlLabel, Switch, Table, TableBody, TableHead, TableRow, Typography} from "@mui/material";
import {Scrollbar} from "../../../scrollbar";
import {SeverityPill, SeverityPillColor} from "../../../components/severity-pill";
import {DcrListInner, requestStatusHandler} from "../requests/RequestTable";
import {DcrDrawer} from "../DcrDrawer";
import {ResponseItemStore} from "../../../stores/management/dcr/responses/ResponseItemStore";

interface Props {
    responseTableStore: ResponseTableStore;
}

export const ResponseTable = observer(({responseTableStore}: Props) => {
    const {t} = useTranslation();
    const [drawer, setDrawer] = useState<{ isOpen: boolean; response?: ResponseItemStore }>({
        isOpen: false,
        response: undefined
    });
    const [openDetails, setOpenDetails] = useState<string | null>(null);

    const handleOpenDetails = (productId: string): void => {
        setOpenDetails((prevValue) => (prevValue === productId ? null : productId));
    };
    const rootRef = useRef<HTMLDivElement | null>(null);

    const handleOpenDrawer = (request: ResponseItemStore): void => {
        setDrawer({
            isOpen: true,
            response: request
        });
    };

    const handleCloseDrawer = () => {
        setDrawer({
            isOpen: false,
            response: undefined
        });
    };

    return (
        <Box>
            <Box
                sx={{
                    mt: 1,
                }}
                style={{display: 'flex', justifyContent: 'space-between'}}
            >
                <DefaultMultiSelect store={responseTableStore.client}
                                    title={t('dcrPage.requestTable.client')}
                                    nameExp="name"
                                    valueExp="name"
                                    style={{width: '32%'}}
                                    testid="ResponseTable-client"
                />
                <DefaultMultiSelect store={responseTableStore.dcrStatus}
                                    title={t('dcrPage.requestTable.dcrStatus')}
                                    nameExp={responseTableStore.dcrStatus.nameExp}
                                    valueExp="value"
                                    style={{width: '32%'}}
                                    testid="ResponseTable-dcrStatus"
                />
                <DefaultMultiSelect store={responseTableStore.requestRecordType}
                                    title={t('dcrPage.requestTable.typeRequest')}
                                    nameExp={responseTableStore.requestRecordType.nameExp}
                                    valueExp="value"
                                    style={{width: '32%'}}
                                    testid="ResponseTable-requestRecordType"
                />

            </Box>
            <Box
                sx={{
                    mb: 1
                }}
                style={{display: 'flex', justifyContent: 'space-between'}}
            >
                <DefaultMultiSelect store={responseTableStore.requestStatus}
                                    title={t('dcrPage.responseTable.requestStatus')}
                                    nameExp={responseTableStore.requestStatus.nameExp}
                                    valueExp="value"
                                    style={{width: '32%'}}
                                    testid="ResponseTable-requestStatus"
                />
                <DefaultMultiSelect store={responseTableStore.responseStatus}
                                    title={t('dcrPage.responseTable.responseStatus')}
                                    nameExp={responseTableStore.responseStatus.nameExp}
                                    valueExp="value"
                                    style={{width: '32%'}}
                                    testid="ResponseTable-responseStatus"
                />
                <Box style={{width: '32%', display: 'flex', alignItems: 'center'}}>
                    <FormControlLabel
                        labelPlacement="end"
                        label={t('common.openDcr')}
                        style={{marginLeft: 0, marginTop: 12}}
                        control={
                            <Switch
                                checked={responseTableStore.isOpenInDrawer}
                                onClick={() => {
                                    setOpenDetails(null);
                                    handleCloseDrawer();
                                    responseTableStore.isOpenInDrawer = !responseTableStore.isOpenInDrawer
                                }}/>
                        }
                    />
                </Box>
            </Box>
            <Box
                component="main"
                ref={rootRef}
                style={{padding: 0}}
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexGrow: 1,
                    overflow: 'hidden'
                }}
            >
                <DcrListInner open={drawer.isOpen}>
                    <Card sx={{overflow: 'unset'}}>
                        <Scrollbar>
                            <Table sx={{minWidth: 1050}}>
                                <TableHead>
                                    <TableRow>
                                        <HCell width='5%' testid="responseTable-expand"></HCell>
                                        <HCell store={responseTableStore} testid="responseTable-requestId">{t('dcrPage.responseTable.requestId')}</HCell>
                                        <HCell orderBy="client"
                                               store={responseTableStore} testid="responseTable-client">{t('dcrPage.responseTable.client')}</HCell>
                                        <HCell orderBy="requestRecordType" testid="responseTable-type"
                                               store={responseTableStore}>{t('dcrPage.responseTable.type')}</HCell>
                                        <HCell orderBy="createdAt" testid="responseTable-createdAt"
                                               store={responseTableStore}>{t('dcrPage.responseTable.createdAt')}</HCell>
                                        <HCell orderBy="requestStatus" testid="responseTable-requestStatus"
                                               store={responseTableStore}>{t('dcrPage.responseTable.requestStatus')}</HCell>
                                        <HCell orderBy="responseStatus" testid="responseTable-responseStatus"
                                               store={responseTableStore}>{t('dcrPage.responseTable.responseStatus')}</HCell>
                                        <HCell orderBy="modifiedDate" testid="responseTable-date"
                                               store={responseTableStore}>{t('dcrPage.responseTable.date')}</HCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        responseTableStore.items.length > 0
                                        && responseTableStore.items.map(i => {
                                            const open = i.dto.requestId === openDetails;
                                            return <Fragment key={i.dto.requestId}>
                                                <TableRow
                                                    hover
                                                    key={i.dto.requestId}
                                                >
                                                    <ExpandButton item={i}
                                                                  testid="responseTable"
                                                                  isOpen={open || (drawer.isOpen && drawer.response === i)}
                                                                  isDcr={responseTableStore.isOpenInDrawer}
                                                                  onClick={() => {
                                                                      if (responseTableStore.isOpenInDrawer) {
                                                                          if (drawer.isOpen && drawer.response === i) {
                                                                              handleCloseDrawer()
                                                                          } else {
                                                                              handleOpenDrawer(i)
                                                                          }
                                                                      } else {
                                                                          handleOpenDetails(i.dto.requestId)
                                                                      }
                                                                  }}
                                                    />
                                                    <Cell style={{paddingRight: 10, marginRight: 10}} testid="responseTable-requestId">
                                                        <Typography variant="subtitle2"
                                                                    style={{display: "flex", alignItems: 'center'}}>
                                                            {`${i.dto.requestId.substring(0, 11)}...`}
                                                            <CopyToClipboard copyText={i.dto.requestId}/>
                                                        </Typography>
                                                    </Cell>

                                                    <Cell testid="responseTable-client">
                                                        <Typography variant="subtitle2" color="textSecondary">
                                                            {i.dto.client}
                                                        </Typography>
                                                    </Cell>

                                                    <Cell testid="responseTable-type">
                                                        <Typography variant="subtitle2"
                                                                    color="textSecondary">
                                                            {i.requestTypeName}
                                                        </Typography>
                                                    </Cell>

                                                    <Cell testid="responseTable-createdAt">
                                                        <Typography variant="subtitle2"
                                                                    color="textSecondary">
                                                            {i.dto.nCreateDate}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell testid="responseTable-requestStatus">
                                                        <SeverityPill
                                                            color={requestStatusHandler(i.dto.requestStatus) as SeverityPillColor}>
                                                            {i.requestStatusName}
                                                        </SeverityPill>
                                                    </Cell>
                                                    <Cell testid="responseTable-responseStatus">
                                                        <Typography variant="subtitle2"
                                                                    color="textSecondary">
                                                            {i.responseStatusName}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell testid="responseTable-date">
                                                        <Typography variant="subtitle2" color="textSecondary">
                                                            {i.dto.nModifiedDate}
                                                        </Typography>
                                                    </Cell>
                                                </TableRow>
                                                {open && (
                                                    <ExpandRow key={`${i.dto.requestId}_`} item={i}>
                                                        <ResponseDetails
                                                            onClose={handleCloseDrawer}
                                                            store={i.details}
                                                            style={{
                                                                margin: '20px 12px 0 20px'
                                                            }}
                                                            isOpenInDrawer={responseTableStore.isOpenInDrawer}
                                                        />
                                                    </ExpandRow>)}
                                            </Fragment>
                                        })
                                    }
                                </TableBody>
                                {!responseTableStore.items.length && <EmptyBox/>}
                            </Table>
                        </Scrollbar>
                        {responseTableStore.items.length > 0 && <Pagination store={responseTableStore}/>}

                        <Progress store={responseTableStore}/>

                    </Card>
                </DcrListInner>
                <DcrDrawer open={drawer.isOpen} onClose={handleCloseDrawer}>
                    {drawer.response ?
                        <ResponseDetails
                            onClose={handleCloseDrawer}
                            store={drawer.response?.details}
                            isOpenInDrawer={responseTableStore.isOpenInDrawer}
                        />

                        : <></>}
                </DcrDrawer>
            </Box>
        </Box>
    );
});
