/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface RemoveOrganizationWebsiteDto
 */
export interface RemoveOrganizationWebsiteDto {
    /**
     * 
     * @type {string}
     * @memberof RemoveOrganizationWebsiteDto
     */
    websiteAddress: string;
    /**
     * 
     * @type {string}
     * @memberof RemoveOrganizationWebsiteDto
     */
    organizationId: string;
}

export function RemoveOrganizationWebsiteDtoFromJSON(json: any): RemoveOrganizationWebsiteDto {
    return RemoveOrganizationWebsiteDtoFromJSONTyped(json, false);
}

export function RemoveOrganizationWebsiteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveOrganizationWebsiteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'websiteAddress': json['websiteAddress'],
        'organizationId': json['organizationId'],
    };
}

export function RemoveOrganizationWebsiteDtoToJSON(value?: RemoveOrganizationWebsiteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'websiteAddress': value.websiteAddress,
        'organizationId': value.organizationId,
    };
}

