import {getApiConfiguration} from '../../../../../core';
import {PersonPhoneTypesApi} from "../../../../../services/management";
import {DictionaryCreateEntity, DictionaryEntity, DictionaryTableStore} from "../../DictionaryTableStore";
import {DictionaryPersonStore} from "../DictionaryPersonStore";

export class PersonPhoneTypeTableStore extends DictionaryTableStore {
    constructor(store: DictionaryPersonStore) {
        super(store, 'phone-status-store');
    }

    async Create(model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new PersonPhoneTypesApi(getApiConfiguration()).createPersonPhoneType({setPhoneTypeDto: model});
    }

    async Delete(id: string): Promise<DictionaryEntity> {
        return await new PersonPhoneTypesApi(getApiConfiguration()).deletePersonPhoneType({id: id});
    }

    async GetCount(search: string): Promise<{ count: number }> {
        return await new PersonPhoneTypesApi(getApiConfiguration()).getPersonPhoneTypesCount({search: search});
    }

    async GetList(filters: any): Promise<DictionaryEntity[]> {
        return await new PersonPhoneTypesApi(getApiConfiguration()).getPersonPhoneTypes(filters)
    }

    async Update(id: string, model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new PersonPhoneTypesApi(getApiConfiguration()).updatePersonPhoneType({id: id, setPhoneTypeDto: model});
    }
}
