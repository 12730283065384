import { TableRow } from "@mui/material";

type Props<T extends {}> = {
    item: T;
    onClick?: (item: T) => void;
    children?: React.ReactNode;
    style?: React.CSSProperties;
    hover?: boolean;
};

export const Row = <T extends {}>({ item, onClick, children, style, hover, ...other }: Props<T>) => {

    const handleClick = () => {
        if (onClick) {
            onClick(item);
        }
    };

    return (
        <TableRow
            hover={hover}
            onClick={handleClick}
            tabIndex={-1}
            style={style}
            {...other}
        >
            {children}
        </TableRow>
    );
};
