import {styled, Table, Typography, useTheme} from "@mui/material";
import React, {FC} from "react";
import {EmptyData} from "../../icons/plus";
import {useTranslation} from "react-i18next";

type Props = {
    children: React.ReactNode;
    stickyHeader?: boolean;
};


const EmptyBlock = styled('div', {shouldForwardProp: (prop) => prop !== 'width'})<{ position?: 'absolute' | 'relative'; }>(
    ({theme, position}) => ({
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: position ?? 'absolute',
        top: '0px'
    })
);

export const EmptyBlock2: FC<{ position?: 'absolute' | 'relative', size?: number }> = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <EmptyBlock position={props.position}>
            <EmptyData sx={{
                stroke: theme.palette.text.secondary,
                fill: theme.palette.text.secondary,
                height: props.size ?? 200,
                width: props.size ?? 200
            }}/>
            <Typography
                style={{marginTop: -30}}
                color="text.secondary"
                component="span"
                variant="body2"
            >
                {t("common.empty")}
            </Typography>

        </EmptyBlock>
    );
};

export const EmptyBlock3: FC = () => {
    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <EmptyBlock>

            <EmptyData sx={{
                stroke: theme.palette.text.secondary,
                fill: theme.palette.text.secondary,
                height: 200,
                width: 200
            }}></EmptyData>
            <Typography
                style={{marginTop: -30}}
                color="text.secondary"
                component="span"
                variant="body2"
            >
                {t("common.empty")}
            </Typography>

        </EmptyBlock>
    );
};


export const EmptyBlock4: FC = () => {
    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <EmptyBlock position="relative">

            <EmptyData sx={{
                stroke: theme.palette.text.secondary,
                fill: theme.palette.text.secondary,
                height: 200,
                width: 200
            }}></EmptyData>
            <Typography
                style={{marginTop: -30}}
                color="text.secondary"
                component="span"
                variant="body2"
            >
                {t("common.empty")}
            </Typography>

        </EmptyBlock>
    );
};



export const PageTable = ({stickyHeader, children, ...other}: Props) => {

    return (
        <Table
            stickyHeader={stickyHeader}
            size="small"
            {...other}
        >
            {children}
        </Table>
    );
};
