import AssessmentIcon from '@mui/icons-material/Assessment';
import DashboardIcon from "@mui/icons-material/Dashboard";
import SvgIcon from '@mui/material/SvgIcon';
import type {ReactNode} from 'react';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Operation, SectionType} from "../../core";
import {Apartment as OrganizationsIcon} from "../../icons/apartment";
import {AssignmentInd as ClientsIcon} from "../../icons/assignment-ind";
import {Data as DataIcon} from "../../icons/data";
import {Dcr as DcrIcon} from "../../icons/dcr";
import {MenuBook as DictionariesIcon} from "../../icons/menu-book";
import {UserCircle as UsersIcon} from "../../icons/user-circle";
import {Users as PersonsIcon} from "../../icons/users";
import {OfficeBuilding} from "../../icons/office-building";
import {AllPermissions, OrganizationDictionary, PersonDictionary} from "../../core/const";
import {Upload} from "../../icons/upload";

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
  section?: SectionType;
  operation?: Operation;
  permissions?: string[]
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {
  const { t } = useTranslation();

  return useMemo<Section[]>(
      () => {
        return [
          {
            items: [
              {
                title: t('pageNames.persons'),
                path: '/persons',
                permissions: [AllPermissions.person.read],
                icon: (
                    <SvgIcon fontSize="small">
                      <PersonsIcon fontSize="small"/>
                    </SvgIcon>
                ),
              },
              {
                title: t('pageNames.organizations'),
                path: '/organizations',
                permissions: [AllPermissions.organization.read],
                icon: (
                    <SvgIcon fontSize="small">
                      <OrganizationsIcon fontSize="small"/>
                    </SvgIcon>
                ),
              },
              {
                title: t('pageNames.dictionaries'),
                path: '/dictionaries',
                permissions: [...PersonDictionary, ...OrganizationDictionary],
                icon: (
                    <SvgIcon fontSize="small">
                      <DictionariesIcon fontSize="small"/>
                    </SvgIcon>
                ),
                items: [
                  {
                    title: t('dictionary.tabNames.persons'),
                    path: '/dictionaries/persons',
                    permissions: PersonDictionary,
                  },
                  {
                    title: t('dictionary.tabNames.organizations'),
                    path: '/dictionaries/organizations',
                    permissions: OrganizationDictionary
                  }
                ]
              },
              {
                title: t('pageNames.users'),
                path: '/users',
                permissions: [AllPermissions.user.read],
                icon: (
                    <SvgIcon fontSize="small">
                      <UsersIcon fontSize="small"/>
                    </SvgIcon>
                ),
              },
              {
                title: t('pageNames.clients'),
                path: '/clients',
                permissions: [AllPermissions.client.read],
                icon: (
                    <SvgIcon fontSize="small">
                      <ClientsIcon fontSize="small"/>
                    </SvgIcon>
                ),
              },
              {
                title: t('pageNames.dcr'),
                path: '/dcr',
                icon: (
                    <SvgIcon fontSize="small">
                      <DcrIcon fontSize="small"/>
                    </SvgIcon>
                ),
                items: [
                  {
                    title: t('dcrPage.dcrTabs.requests'),
                    path: '/dcr'
                  },
                  // {
                  //   title: t('dcrPage.dcrTabs.responses'),
                  //   path: '/dcr/responses'
                  // },
                ]
              },
              {
                title: t('dataPage.dataTabs.import'),
                path: '/data',
                permissions: [AllPermissions.import.person.read, AllPermissions.import.organization.read, AllPermissions.import.address.create],
                icon: (
                    <SvgIcon fontSize="small">
                      <DataIcon fontSize="small"/>
                    </SvgIcon>
                ),
              },
              {
                title: t('dataPage.dataTabs.export'),
                path: '/export',
                permissions: [AllPermissions.export.address.typelocality.read, AllPermissions.export.address.citylocalities.read],
                icon: (
                    <SvgIcon fontSize="small">
                      <Upload fontSize="small"/>
                    </SvgIcon>
                ),
              },
              {
                title: t('pageNames.dashboards'),
                path: '/analytics',
                role: "Admin",
                icon: (
                    <SvgIcon fontSize="small">
                      <DashboardIcon fontSize="small"/>
                    </SvgIcon>
                ),
                items: [
                  {
                    title: t('pageNames.exchange'),
                    path: '/analytics/exchange/dashboard',
                    role: "Admin",
                  },
                  {
                    title: t('pageNames.dcr'),
                    path: '/analytics/dcr/dashboard',
                    role: "Admin",
                  }
                ]
              },
              {
                title: t("analytics.reports"),
                path: '/analytics/exchange/report',
                role: "Admin",
                icon: (
                    <SvgIcon fontSize="small">
                      <AssessmentIcon fontSize="small"/>
                    </SvgIcon>
                ),
                items: [
                  {
                    title: t('pageNames.exchange'),
                    path: '/analytics/exchange/report',
                    role: "Admin",
                  },
                  {
                    title: t('pageNames.dcr'),
                    path: '/analytics/dcr/report',
                    role: "Admin",
                  },
                ]
              },
              {
                title: t('pageNames.jurisdictions'),
                path: '/jurisdictions',
                permissions: [AllPermissions.jurisdiction.read],
                icon: (
                    <SvgIcon fontSize="small">
                      <OfficeBuilding fontSize="small"/>
                    </SvgIcon>
                ),
              },
            ]
          },
        ];
      },
      [t]
  );
};
