import type {FC} from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {Avatar, Box, Divider, ListItemIcon, ListItemText, MenuItem, Popover, Switch, Typography} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import {UserCircle as UserCircleIcon} from '../../icons/user-circle';
import {useAuthorizationStore, useRootStore} from "../../stores";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";

interface AccountPopoverProps {
    anchorEl: null | Element;
    onClose?: () => void;
    open?: boolean;
}

export const AccountPopover: FC<AccountPopoverProps> = observer((props) => {
// export const AccountPopover: FC<AccountPopoverProps> = (props) => {
    const {anchorEl, onClose, open, ...other} = props;
    const authorizationStore = useAuthorizationStore();
    const {t} = useTranslation();
    const {themeColorStore} = useRootStore();
    // const router = useRouter();
    // const { logout } = useAuth();
    // To get the user from the authContext, you can use
    // `const { user } = useAuth();`
    const user = {
        avatar: '/static/mock-images/avatars/avatar-anika_visser.png',
        name: authorizationStore.user?.username
    };

    const handleLogout = async (): Promise<void> => {
        try {
            onClose?.();
            await authorizationStore.logout()
            // await logout();
            // router.push('/').catch(console.error);
        } catch (err) {
            console.error(err);
            toast.error('Unable to logout.');
        }
    };

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom'
            }}
            keepMounted
            onClose={onClose}
            open={!!open}
            PaperProps={{sx: {width: 300}}}
            transitionDuration={0}
            {...other}
        >
            <Box
                sx={{
                    alignItems: 'center',
                    p: 2,
                    display: 'flex'
                }}
            >
                <Avatar
                    src={user.avatar}
                    sx={{
                        height: 40,
                        width: 40
                    }}
                >

                    <UserCircleIcon fontSize="small"/>

                </Avatar>
                <Box
                    sx={{
                        ml: 1
                    }}
                >
                    <Typography variant="body1" data-testid="user-title">
                        {user.name}
                    </Typography>
                </Box>
            </Box>
            <Divider/>
            <Box sx={{my: 1}}>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText
                        primary={(
                            <Typography variant="body1">
                                {t('common.logout')}
                            </Typography>
                        )}
                    />
                </MenuItem>
            </Box>
        </Popover>
    );
});

AccountPopover.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    open: PropTypes.bool
};
