/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NameDto
 */
export interface NameDto {
    /**
     * 
     * @type {string}
     * @memberof NameDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NameDto
     */
    nameRus: string;
    /**
     * 
     * @type {string}
     * @memberof NameDto
     */
    nameEng: string;
}

export function NameDtoFromJSON(json: any): NameDto {
    return NameDtoFromJSONTyped(json, false);
}

export function NameDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NameDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'nameRus': json['nameRus'],
        'nameEng': json['nameEng'],
    };
}

export function NameDtoToJSON(value?: NameDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'nameRus': value.nameRus,
        'nameEng': value.nameEng,
    };
}

