import {makeAutoObservable} from 'mobx';
import {getApiConfiguration, IExpanded, IForm, IProcessing} from '../../../../../../core';
import {
    EmailStatusDto,
    EmailTypeDto,
    OrganizationEmailDto,
    OrganizationEmailsApi,
    OrganizationEmailStatusesApi,
    OrganizationEmailTypesApi,
} from '../../../../../../services/management';
import {DefaultSelectStore} from "../../../../../../components/shared/DefaultSelectStore";
import i18n from "i18next";
import {OrganizationEmailTableStore} from './OrganizationEmailTableStore';
import {DefaultValueStore} from "../../../../../../components/shared/DefaultValueStore";


export class OrganizationEmailItemStore implements IProcessing, IExpanded, IForm {
    readonly _store: OrganizationEmailTableStore;
    dto: OrganizationEmailDto;
    processing: boolean = false;
    deleteDialogState: boolean = false;
    expand: boolean = false;
    type: DefaultSelectStore<EmailTypeDto>;
    status: DefaultSelectStore<EmailStatusDto>;
    description: DefaultValueStore<string>
    email: DefaultValueStore<string>

    constructor(store: OrganizationEmailTableStore, dto: OrganizationEmailDto) {
        this._store = store;
        this.dto = dto;
        this.email = new DefaultValueStore<string>(dto.email.address ?? "");
        this.type = new DefaultSelectStore<EmailTypeDto>(
            dto.emailType, (x) => new OrganizationEmailTypesApi(getApiConfiguration()).getOrganizationEmailTypes(x),
            (value) => !value ? i18n.t('errors.empty') : "");
        this.status = new DefaultSelectStore<EmailStatusDto>(
            dto.emailStatus, (x) => new OrganizationEmailStatusesApi(getApiConfiguration()).getOrganizationEmailStatuses(x),
            (value) => !value ? i18n.t('errors.empty') : "");
        this.description = new DefaultValueStore<string>(dto?.description ?? '', (value) =>
            value?.length! > 180 ? i18n.t('errors.lengthMax').replace("lengthMax", "180") : "")
        makeAutoObservable(this);
    }

    async delete(): Promise<void> {
        this.processing = true;
        const api: OrganizationEmailsApi = new OrganizationEmailsApi(getApiConfiguration());
        try {
            const result = await api.removeOrganizationEmails({
                organizationId: this._store.organizationId,
                removeOrganizationEmailDto: [
                    {
                        emailAddress: this.dto.email.address,
                        organizationId: this._store.organizationId
                    }
                ]
            });
            this._store.items = this._store.items.filter(t => t.dto.id !== result[0].id);
            await this._store.pull();
        } catch (e) {

        }
        this._store.current = null;
        this.processing = false;
    };

    get equals(): boolean {
        return this.status.equals && this.type.equals && this.description.equals
    }

    get valid(): boolean {
        return this.status.valid && this.type.valid && this.description.valid
    }

    validate() {
        this.status.validate();
        this.type.validate();
        this.description.validate()
    }

    update(dto: OrganizationEmailDto) {
        this.dto = dto;
        this.status.update(dto.emailStatus);
        this.type.update(dto.emailType);
        this.description.update(dto.description)
    }

    reset(): void {
        this.status.reset();
        this.type.reset();
        this.description.reset();
        this.validate();
    }

    async submit() {
        this.validate()
        if (!this.valid)
            return
        this.processing = true;
        const api: OrganizationEmailsApi = new OrganizationEmailsApi(getApiConfiguration());

        try {
            let item = await api.updateOrganizationEmail({
                id: this.dto.id,
                updateOrganizationEmailDto: {
                    emailStatusId: this.status.value?.id!,
                    emailTypeId: this.type.value?.id!,
                    description: this.description.value
                }
            })
            this._store.updateState();
        } finally {
            this.processing = false;
        }
    }
}
