import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import {ArrowRight as ArrowRightIcon} from "../../icons/arrow-right";
import {alpha} from "@mui/system/colorManipulator";
import {Avatar} from "@mui/material";

export const ClientsIcon: React.FC = () =>  {
    return (
        <Avatar sx={{backgroundColor: 'transparent', height: 66, width: 66}}>
            <Box
                sx={{
                    animation: 'pulse ease 750ms infinite',
                    borderRadius: '50%',
                    p: 0.5,
                    '@keyframes pulse': {
                        '0%': {
                            boxShadow: 'none'
                        },
                        '100%': {
                            boxShadow: (theme) => `0px 0px 0px 6px ${alpha(theme.palette.error.main, 0.1)}`
                        }
                    }
                }}
            >
                <Box
                    sx={{
                        backgroundColor: 'error.main',
                        borderRadius: '50%',
                        height: 30,
                        width: 30
                    }}
                />
            </Box>
        </Avatar>
    );
}