/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Api.Import
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    NameDto,
    NameDtoFromJSON,
    NameDtoFromJSONTyped,
    NameDtoToJSON,
} from './NameDto';
import {
    PersonJobDto,
    PersonJobDtoFromJSON,
    PersonJobDtoFromJSONTyped,
    PersonJobDtoToJSON,
} from './PersonJobDto';
import {
    PersonStatusDto,
    PersonStatusDtoFromJSON,
    PersonStatusDtoFromJSONTyped,
    PersonStatusDtoToJSON,
} from './PersonStatusDto';
import {
    SpecialityDto,
    SpecialityDtoFromJSON,
    SpecialityDtoFromJSONTyped,
    SpecialityDtoToJSON,
} from './SpecialityDto';
import {
    TagDto,
    TagDtoFromJSON,
    TagDtoFromJSONTyped,
    TagDtoToJSON,
} from './TagDto';

/**
 * 
 * @export
 * @interface PersonDto
 */
export interface PersonDto {
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    externalId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PersonDto
     */
    createdAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PersonDto
     */
    modifiedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    ticks?: number;
    /**
     * 
     * @type {NameDto}
     * @memberof PersonDto
     */
    firstName: NameDto;
    /**
     * 
     * @type {NameDto}
     * @memberof PersonDto
     */
    middleName?: NameDto;
    /**
     * 
     * @type {NameDto}
     * @memberof PersonDto
     */
    surname: NameDto;
    /**
     * 
     * @type {NameDto}
     * @memberof PersonDto
     */
    gender: NameDto;
    /**
     * 
     * @type {SpecialityDto}
     * @memberof PersonDto
     */
    primarySpeciality?: SpecialityDto;
    /**
     * 
     * @type {PersonJobDto}
     * @memberof PersonDto
     */
    primaryJob?: PersonJobDto;
    /**
     * 
     * @type {boolean}
     * @memberof PersonDto
     */
    poweredByElastic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PersonDto
     */
    hasNoMiddleName?: boolean;
    /**
     * 
     * @type {PersonStatusDto}
     * @memberof PersonDto
     */
    personStatus?: PersonStatusDto;
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof PersonDto
     */
    tags?: Array<TagDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    confidenceFactor?: number | null;
}

export function PersonDtoFromJSON(json: any): PersonDto {
    return PersonDtoFromJSONTyped(json, false);
}

export function PersonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (json['modifiedAt'] === null ? null : new Date(json['modifiedAt'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'ticks': !exists(json, 'ticks') ? undefined : json['ticks'],
        'firstName': NameDtoFromJSON(json['firstName']),
        'middleName': !exists(json, 'middleName') ? undefined : NameDtoFromJSON(json['middleName']),
        'surname': NameDtoFromJSON(json['surname']),
        'gender': NameDtoFromJSON(json['gender']),
        'primarySpeciality': !exists(json, 'primarySpeciality') ? undefined : SpecialityDtoFromJSON(json['primarySpeciality']),
        'primaryJob': !exists(json, 'primaryJob') ? undefined : PersonJobDtoFromJSON(json['primaryJob']),
        'poweredByElastic': !exists(json, 'poweredByElastic') ? undefined : json['poweredByElastic'],
        'hasNoMiddleName': !exists(json, 'hasNoMiddleName') ? undefined : json['hasNoMiddleName'],
        'personStatus': !exists(json, 'personStatus') ? undefined : PersonStatusDtoFromJSON(json['personStatus']),
        'tags': !exists(json, 'tags') ? undefined : (json['tags'] === null ? null : (json['tags'] as Array<any>).map(TagDtoFromJSON)),
        'confidenceFactor': !exists(json, 'confidenceFactor') ? undefined : json['confidenceFactor'],
    };
}

export function PersonDtoToJSON(value?: PersonDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalId': value.externalId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt === null ? null : value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt === null ? null : value.modifiedAt.toISOString()),
        'modifiedBy': value.modifiedBy,
        'ticks': value.ticks,
        'firstName': NameDtoToJSON(value.firstName),
        'middleName': NameDtoToJSON(value.middleName),
        'surname': NameDtoToJSON(value.surname),
        'gender': NameDtoToJSON(value.gender),
        'primarySpeciality': SpecialityDtoToJSON(value.primarySpeciality),
        'primaryJob': PersonJobDtoToJSON(value.primaryJob),
        'poweredByElastic': value.poweredByElastic,
        'hasNoMiddleName': value.hasNoMiddleName,
        'personStatus': PersonStatusDtoToJSON(value.personStatus),
        'tags': value.tags === undefined ? undefined : (value.tags === null ? null : (value.tags as Array<any>).map(TagDtoToJSON)),
        'confidenceFactor': value.confidenceFactor,
    };
}

