import {RequestsClientsStatusDto} from "../../../../../services/analytics/dcr";
import {ApexOptions} from "apexcharts";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";

export const useClientPersonChartOptions = (data: RequestsClientsStatusDto[]): ApexOptions => {
    const theme = useTheme();
    const {t} = useTranslation();

    const labels = data.map(x => x.clients!)
    const series = data.map(x => (x?.statuses && x.statuses?.length > 0) ? x.statuses?.map(x => x.count ?? 0).reduce((x, y) => x + y, 0) : 0)

    const colors = useMemo(() => {
        return labels.map(x => '#' + Math.random().toString(16).substr(-6))
    }, [labels.length])

    const tooltipFunc = ({seriesIndex}: any): any => {
        const vr = data[seriesIndex]!;
        const count = vr.statuses!.map(x => x.count ?? 0).reduce((x, t) => x + t)

        const b = [`
            <div style="display: flex; padding-bottom: 10px">
                <b style="margin-right: 5px">${vr.clients!}:</b>
                <div style="margin-left: auto">${count}</div>
            </div>`]
        const a = vr.statuses!.map(x => `
            <div style="display: flex">
                <b style="margin-right: 5px">${t('analytics.dcr.statuses.' + x.name!)}:</b>
                <div style="margin-left: auto">${x.count}</div>
            </div>`);

        const color = theme.palette.mode == "dark" ? "white" : "black"

        return `
            <div style="padding: 0 10px 10px 10px; z-index: 99999; color: ${color}">
                <div style="display: flex; flex-direction: column; padding-top: 10px">
                    ${b.concat(a).reduce((x, y) => x + y)}
                </div>
            </div>

        `;
    }

    return {
        chart: {
            background: 'transparent',
            stacked: false,
            toolbar: {
                show: false
            },
        },
        colors: colors,
        dataLabels: {
            enabled: false
        },
        fill: {
            opacity: 1,
            type: 'solid'
        },
        labels,
        series,
        legend: {
            horizontalAlign: 'right',
            labels: {
                colors: theme.palette.text.secondary
            },
            position: 'right',
            show: true,
            width: 300,
            formatter(legendName: string, opts?: any): string {
                return `<div style="vertical-align: top;display: inline-block;margin-top: 5px;margin-left: 2px;width: 240px">
                            <div style="display: flex; width: 250px">
                                <div>${opts.w.config.labels[opts.seriesIndex]}</div>
                                <div style="margin-left: auto">${opts.w.config.series[opts.seriesIndex]}</div>
                            </div>
                        </div>`
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            showAlways: true,
                            fontWeight: 400,
                            color: theme.palette.text.secondary,
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce((a: number, b: number) => {
                                    return a + b
                                })
                            }
                        },
                        value: {
                            fontSize: '18px',
                            fontWeight: 800,
                        },
                        name: {
                            color: theme.palette.text.secondary,
                            formatter: function (w) {
                                return w == 'Total' ? t("common.allCount") : w
                            }
                        },
                    }
                }
            }
        },
        states: {
            active: {
                filter: {
                    type: 'none'
                }
            },
            hover: {
                filter: {
                    type: 'none'
                }
            }
        },
        stroke: {
            width: 0
        },
        theme: {
            mode: theme.palette.mode
        },
        tooltip: {
            custom: tooltipFunc.bind(this),
            cssClass: "apextooltip-custom"
        }
    };
};
