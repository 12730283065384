import {ManagementStore} from "../ManagementStore";
import {getApiConfiguration, ILoad, IProcessing} from "../../../core";
import {AdminApi, ClientApi, ClientDto} from "../../../services/dcr";
import {DefaultSelectStore} from "../../../components/shared/DefaultSelectStore";
import { makeObservable, observable} from "mobx";

export class AdminStore implements ILoad {
    loading: boolean = false;
    readonly client: DefaultSelectStore<ClientDto>;

    readonly exportClient: DefaultSelectStore<ClientDto>;


    readonly store: ManagementStore;

    constructor(store: ManagementStore) {
        this.client = new DefaultSelectStore<ClientDto>(null,
            (e) => new ClientApi().apiDcrV1ClientsGet({...e, ...{type: 1}}))
        this.exportClient = new DefaultSelectStore<ClientDto>(null,
            (e) => new ClientApi().apiDcrV1ClientsGet({...e, ...{type: 1}}))
        this.store = store;
        makeObservable(this, {loading: observable});
    }

    async missingDcr(isPerson: boolean = true): Promise<void> {
        this.loading = true;
        try {
            await new AdminApi(getApiConfiguration() as any).apiDcrV1AdminMissedDcrPost({
                    missedRequestCommand: {
                        clientId: this.client.valueId,
                        isPerson: isPerson
                    }
                }
            )
        }
        catch (e) {
            
        }
        this.loading = false;
    };

    async refreshHash(): Promise<void> {
        this.loading = true;
        try {
            await new AdminApi().apiDcrV1AdminRefreshHashGetRaw()
        }
        catch (e) {

        }
        this.loading = false;
    };


    async reloadCorrectRequests(): Promise<void> {
        this.loading = true;
        try {
            await new AdminApi().apiDcrV1AdminCheckRequestsPost({body: {}})
        }
        catch (e) {

        }
        this.loading = false;
    };

    async exportAzRequests(): Promise<void> {
        this.loading = true;
        try {
            await new AdminApi().apiDcrV1AdminAzExportPost({azRequestsExportCommand: {clientId: this.exportClient.valueId}})
        }
        catch (e) {

        }
        this.loading = false;
    };

    pull(): Promise<void> | void {
        return undefined;
    }
}
