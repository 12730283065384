/* tslint:disable */
/* eslint-disable */
/**
 * Data360.DCR.Analytics
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RequestStatus = {
    WaitingVerification: 'WaitingVerification',
    OnVerification: 'OnVerification',
    Verified: 'Verified',
    Decline: 'Decline',
    NeedReverification: 'NeedReverification',
    NotVerified: 'NotVerified',
    Double: 'Double',
    Partialy: 'Partialy'
} as const;
export type RequestStatus = typeof RequestStatus[keyof typeof RequestStatus];


export function RequestStatusFromJSON(json: any): RequestStatus {
    return RequestStatusFromJSONTyped(json, false);
}

export function RequestStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestStatus {
    return json as RequestStatus;
}

export function RequestStatusToJSON(value?: RequestStatus | null): any {
    return value as any;
}

