/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    ListSortDirection,
    PersonEmailDto,
    PersonEmailDtoFromJSON,
    RemovePersonEmailDto,
    RemovePersonEmailDtoToJSON,
    SetPersonEmailDto,
    SetPersonEmailDtoToJSON,
    UpdatePersonEmailDto,
    UpdatePersonEmailDtoToJSON,
} from '../models';

export interface GetPersonEmailByIdRequest {
    id: string;
}

export interface GetPersonEmailsRequest {
    search?: string;
    personId?: string;
    description?: string;
    emailTypeIds?: Array<string>;
    emailStatusIds?: Array<string>;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetPersonEmailsCountRequest {
    search?: string;
    emailTypeIds?: Array<string>;
    description?: string;
    emailStatusIds?: Array<string>;
    personId?: string;
}

export interface RemovePersonEmailsRequest {
    personId: string;
    removePersonEmailDto: Array<RemovePersonEmailDto>;
}

export interface SetPersonEmailsRequest {
    personId: string;
    setPersonEmailDto: Array<SetPersonEmailDto>;
}

export interface UpdatePersonEmailRequest {
    id: string;
    updatePersonEmailDto: UpdatePersonEmailDto;
}

/**
 * 
 */
export class PersonEmailsApi extends runtime.BaseAPI {

    /**
     */
    async getPersonEmailByIdRaw(requestParameters: GetPersonEmailByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonEmailDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPersonEmailById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/Persons/emails/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonEmailById(requestParameters: GetPersonEmailByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonEmailDto> {
        const response = await this.getPersonEmailByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonEmailsRaw(requestParameters: GetPersonEmailsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonEmailDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.emailTypeIds) {
            queryParameters['emailTypeIds'] = requestParameters.emailTypeIds;
        }

        if (requestParameters.emailStatusIds) {
            queryParameters['emailStatusIds'] = requestParameters.emailStatusIds;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/Persons/emails`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonEmailDtoFromJSON));
    }

    /**
     */
    async getPersonEmails(requestParameters: GetPersonEmailsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonEmailDto>> {
        const response = await this.getPersonEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonEmailsCountRaw(requestParameters: GetPersonEmailsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.emailTypeIds) {
            queryParameters['emailTypeIds'] = requestParameters.emailTypeIds;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.emailStatusIds) {
            queryParameters['emailStatusIds'] = requestParameters.emailStatusIds;
        }

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/Persons/emails/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonEmailsCount(requestParameters: GetPersonEmailsCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPersonEmailsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removePersonEmailsRaw(requestParameters: RemovePersonEmailsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonEmailDto>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling removePersonEmails.');
        }

        if (requestParameters.removePersonEmailDto === null || requestParameters.removePersonEmailDto === undefined) {
            throw new runtime.RequiredError('removePersonEmailDto','Required parameter requestParameters.removePersonEmailDto was null or undefined when calling removePersonEmails.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['PersonId'] = requestParameters.personId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/Persons/emails/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.removePersonEmailDto.map(RemovePersonEmailDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonEmailDtoFromJSON));
    }

    /**
     */
    async removePersonEmails(requestParameters: RemovePersonEmailsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonEmailDto>> {
        const response = await this.removePersonEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setPersonEmailsRaw(requestParameters: SetPersonEmailsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonEmailDto>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling setPersonEmails.');
        }

        if (requestParameters.setPersonEmailDto === null || requestParameters.setPersonEmailDto === undefined) {
            throw new runtime.RequiredError('setPersonEmailDto','Required parameter requestParameters.setPersonEmailDto was null or undefined when calling setPersonEmails.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/Persons/emails/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setPersonEmailDto.map(SetPersonEmailDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonEmailDtoFromJSON));
    }

    /**
     */
    async setPersonEmails(requestParameters: SetPersonEmailsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonEmailDto>> {
        const response = await this.setPersonEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePersonEmailRaw(requestParameters: UpdatePersonEmailRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonEmailDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePersonEmail.');
        }

        if (requestParameters.updatePersonEmailDto === null || requestParameters.updatePersonEmailDto === undefined) {
            throw new runtime.RequiredError('updatePersonEmailDto','Required parameter requestParameters.updatePersonEmailDto was null or undefined when calling updatePersonEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/Persons/emails/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePersonEmailDtoToJSON(requestParameters.updatePersonEmailDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async updatePersonEmail(requestParameters: UpdatePersonEmailRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonEmailDto> {
        const response = await this.updatePersonEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
