/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    ListSortDirection,
    OrganizationWebsiteDto,
    OrganizationWebsiteDtoFromJSON,
    RemoveOrganizationWebsiteDto,
    RemoveOrganizationWebsiteDtoToJSON,
    SetOrganizationWebsiteDto,
    SetOrganizationWebsiteDtoToJSON,
    UpdateOrganizationWebsiteDto,
    UpdateOrganizationWebsiteDtoToJSON,
} from '../models';

export interface GetOrganizationWebsiteByIdRequest {
    id: string;
}

export interface GetOrganizationWebsitesRequest {
    search?: string;
    description?: string;
    organizationId?: string;
    websiteStatusIds?: Array<string>;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetOrganizationWebsitesCountRequest {
    search?: string;
    description?: string;
    websiteStatusIds?: Array<string>;
    organizationId?: string;
}

export interface RemoveOrganizationWebsitesRequest {
    organizationId: string;
    removeOrganizationWebsiteDto: Array<RemoveOrganizationWebsiteDto>;
}

export interface SetOrganizationWebsitesRequest {
    organizationId: string;
    setOrganizationWebsiteDto: Array<SetOrganizationWebsiteDto>;
}

export interface UpdateOrganizationWebsiteRequest {
    id: string;
    updateOrganizationWebsiteDto: UpdateOrganizationWebsiteDto;
}

/**
 * 
 */
export class OrganizationWebsitesApi extends runtime.BaseAPI {

    /**
     */
    async getOrganizationWebsiteByIdRaw(requestParameters: GetOrganizationWebsiteByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationWebsiteDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrganizationWebsiteById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/websites/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationWebsiteDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationWebsiteById(requestParameters: GetOrganizationWebsiteByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationWebsiteDto> {
        const response = await this.getOrganizationWebsiteByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationWebsitesRaw(requestParameters: GetOrganizationWebsitesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationWebsiteDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.websiteStatusIds) {
            queryParameters['websiteStatusIds'] = requestParameters.websiteStatusIds;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/websites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationWebsiteDtoFromJSON));
    }

    /**
     */
    async getOrganizationWebsites(requestParameters: GetOrganizationWebsitesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationWebsiteDto>> {
        const response = await this.getOrganizationWebsitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationWebsitesCountRaw(requestParameters: GetOrganizationWebsitesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.websiteStatusIds) {
            queryParameters['websiteStatusIds'] = requestParameters.websiteStatusIds;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/websites/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationWebsitesCount(requestParameters: GetOrganizationWebsitesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getOrganizationWebsitesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeOrganizationWebsitesRaw(requestParameters: RemoveOrganizationWebsitesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationWebsiteDto>>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling removeOrganizationWebsites.');
        }

        if (requestParameters.removeOrganizationWebsiteDto === null || requestParameters.removeOrganizationWebsiteDto === undefined) {
            throw new runtime.RequiredError('removeOrganizationWebsiteDto','Required parameter requestParameters.removeOrganizationWebsiteDto was null or undefined when calling removeOrganizationWebsites.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/websites/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.removeOrganizationWebsiteDto.map(RemoveOrganizationWebsiteDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationWebsiteDtoFromJSON));
    }

    /**
     */
    async removeOrganizationWebsites(requestParameters: RemoveOrganizationWebsitesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationWebsiteDto>> {
        const response = await this.removeOrganizationWebsitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setOrganizationWebsitesRaw(requestParameters: SetOrganizationWebsitesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationWebsiteDto>>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling setOrganizationWebsites.');
        }

        if (requestParameters.setOrganizationWebsiteDto === null || requestParameters.setOrganizationWebsiteDto === undefined) {
            throw new runtime.RequiredError('setOrganizationWebsiteDto','Required parameter requestParameters.setOrganizationWebsiteDto was null or undefined when calling setOrganizationWebsites.');
        }

        const queryParameters: any = {};

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/websites/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setOrganizationWebsiteDto.map(SetOrganizationWebsiteDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationWebsiteDtoFromJSON));
    }

    /**
     */
    async setOrganizationWebsites(requestParameters: SetOrganizationWebsitesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationWebsiteDto>> {
        const response = await this.setOrganizationWebsitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrganizationWebsiteRaw(requestParameters: UpdateOrganizationWebsiteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationWebsiteDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrganizationWebsite.');
        }

        if (requestParameters.updateOrganizationWebsiteDto === null || requestParameters.updateOrganizationWebsiteDto === undefined) {
            throw new runtime.RequiredError('updateOrganizationWebsiteDto','Required parameter requestParameters.updateOrganizationWebsiteDto was null or undefined when calling updateOrganizationWebsite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/websites/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationWebsiteDtoToJSON(requestParameters.updateOrganizationWebsiteDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationWebsiteDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateOrganizationWebsite(requestParameters: UpdateOrganizationWebsiteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationWebsiteDto> {
        const response = await this.updateOrganizationWebsiteRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
