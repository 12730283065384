/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AssignedPersonTagDto,
    AssignedPersonTagDtoFromJSON,
    AssignedPersonTagDtoToJSON,
    CreateAssignedPersonTagDto,
    CreateAssignedPersonTagDtoFromJSON,
    CreateAssignedPersonTagDtoToJSON,
    PersonDto,
    PersonDtoFromJSON,
    PersonDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface CreateAssignedPersonTagRequest {
    createAssignedPersonTagDto?: CreateAssignedPersonTagDto;
}

export interface DeleteAssignedPersonTagRequest {
    id: string;
}

export interface SetAssignedPersonTagsRequest {
    personId?: string;
    requestBody?: Array<string>;
}

/**
 * 
 */
export class AssignedPersonTagsApi extends runtime.BaseAPI {

    /**
     */
    async createAssignedPersonTagRaw(requestParameters: CreateAssignedPersonTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AssignedPersonTagDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/persons/tags/assigned`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAssignedPersonTagDtoToJSON(requestParameters.createAssignedPersonTagDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssignedPersonTagDtoFromJSON(jsonValue));
    }

    /**
     */
    async createAssignedPersonTag(requestParameters: CreateAssignedPersonTagRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AssignedPersonTagDto> {
        const response = await this.createAssignedPersonTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAssignedPersonTagRaw(requestParameters: DeleteAssignedPersonTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AssignedPersonTagDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAssignedPersonTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/tags/assigned/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssignedPersonTagDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteAssignedPersonTag(requestParameters: DeleteAssignedPersonTagRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AssignedPersonTagDto> {
        const response = await this.deleteAssignedPersonTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setAssignedPersonTagsRaw(requestParameters: SetAssignedPersonTagsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonDto>> {
        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/persons/tags/assigned/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDtoFromJSON(jsonValue));
    }

    /**
     */
    async setAssignedPersonTags(requestParameters: SetAssignedPersonTagsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonDto> {
        const response = await this.setAssignedPersonTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
