import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import {
    Box,
    Card, Checkbox,
    IconButton,
    Table,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {
    AddButton,
    AuthorizePermission,
    HCell,
    Progress, Search
} from '../../../../../../components';
import {ChevronDown as ChevronDownIcon} from "../../../../../../icons/chevron-down";
import {ChevronRight as ChevronRightIcon} from "../../../../../../icons/chevron-right";
import {StoreProps, StyleProps, callTheProcessingFunc} from "../../../../../../stores";
import {
    PermissionItemStore
} from "../../../../../../stores/management/users/tabs/Roles/tabs/permission/PermissionItemStore";
import {PermissionTableStore} from "../../../../../../stores/management/users/tabs/Roles/tabs/permission/PermissionTableStore";
import {AllPermissions} from "../../../../../../core/const";


const StyledTreeItem = observer((props: { store: PermissionItemStore }) => {
    const { t } = useTranslation();

    return (
        <TreeItem
            key={props.store.dto.key}
            nodeId={props.store.dto.key}
            onClick={() => {
                props.store.expand = !props.store.expand;
            }}
            icon={
                props.store.items.length > 0 ? (
                    <IconButton
                        aria-label="expand row"
                        size="large"
                        sx={{ ml: 2, pl: 2 }}
                    >
                        {props.store.expand ? (
                            <ChevronDownIcon fontSize="small" />
                        ) : (
                            <ChevronRightIcon fontSize="small" />
                        )}
                    </IconButton>
                ) : (
                    <></>
                )
            }
            label={
                <Box sx={{ ml: '15px', display: 'flex' }}>
                    <Checkbox
                        style={{ pointerEvents: 'auto', width: '5%' }}
                        checked={props.store.selected}
                        indeterminate={props.store.in}
                        onClick={(t: any) => {
                            t.stopPropagation();
                            props.store.selected = !props.store.selected;
                        }}
                    />
                    <Typography
                        variant="subtitle2"
                        sx={{
                            height: 56,
                            display: 'flex',
                            alignItems: 'center',
                            width: '65%',
                        }}
                    >
                        {props.store.dto.name}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            height: 56,
                            display: 'flex',
                            alignItems: 'center',
                            width: '15%',
                        }}
                    >
                        {!props.store.dto.idPermission
                            ? props.store.countPermissions
                            : null}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            height: 56,
                            display: 'flex',
                            alignItems: 'center',
                            width: '15%',
                        }}
                    >
                        {!props.store.dto.idPermission
                            ? props.store.countSelectedPermissions
                            : null}
                    </Typography>
                </Box>
            }
        >
            <TreeItem nodeId={props.store.dto.key + 'load'} />
            {Array.isArray(props.store.items)
                ? props.store.items.map((node) => (
                      <StyledTreeItem store={node} />
                  ))
                : null}
        </TreeItem>
    );
});

export const PermissionTableTab = observer(
    ({ store, style }: StoreProps<PermissionTableStore> & StyleProps) => {
        const { t } = useTranslation();

    return (
        <Box style={style}>
            <Box sx={{
                flexGrow: 1,
            }}
            >
                <Box sx={{mb: 2}}>
                    <Box
                        sx={{
                            mt: 2,
                            mb: 5
                        }}
                        style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                    >
                        <Search
                            value={store.search.value}
                            onChange={t => store.search.value = t}
                            style={{width: '87%', marginRight: 8}}
                        />
                        <AuthorizePermission permissions={AllPermissions.role.rolepermissions.create}>
                            <AddButton onClick={() => callTheProcessingFunc(store,
                                (e) => store.save(),
                                async (e) => await store.store._store.pull())()} title={t('buttons.save')}
                                disabled={store.equals}/>
                        </AuthorizePermission>
                    </Box>
                    <Card sx={{mb: 8, overflow: 'unset'}}>
                        <TreeView
                            aria-label="customized"
                            // expanded={store.selectedLocalityIds}
                            sx={{flexGrow: 1, maxWidth: '100%', width: '100%'}}
                        >
                            <Table>
                                <TableHead style={{position: 'sticky', top: 64, zIndex: 999}}>
                                    <TableRow>
                                        <HCell width='5%'/>
                                        <HCell store={store}>{t('common.name')}</HCell>
                                        <HCell store={store} width='15%'>{t('usersPage.roles.tabs.permissionsTable.count')}</HCell>
                                        <HCell store={store} width='15%'>{t('usersPage.roles.tabs.permissionsTable.selected')}</HCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            {
                                store.items.length > 0
                                && store.items?.map(x =>
                                    <StyledTreeItem store={x}/>
                                )
                            }

                        </TreeView>

                            {(!store.value.items || store.loading) && (
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        py: 3,
                                    }}
                                >
                                    <Progress store={store} />
                                </Box>
                            )}
                        </Card>
                    </Box>
                </Box>
            </Box>
        );
    }
);
