/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetOrganizationStatusDto
 */
export interface SetOrganizationStatusDto {
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationStatusDto
     */
    nameRus: string;
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationStatusDto
     */
    nameEng?: string | null;
}

export function SetOrganizationStatusDtoFromJSON(json: any): SetOrganizationStatusDto {
    return SetOrganizationStatusDtoFromJSONTyped(json, false);
}

export function SetOrganizationStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetOrganizationStatusDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nameRus': json['nameRus'],
        'nameEng': !exists(json, 'nameEng') ? undefined : json['nameEng'],
    };
}

export function SetOrganizationStatusDtoToJSON(value?: SetOrganizationStatusDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nameRus': value.nameRus,
        'nameEng': value.nameEng,
    };
}

