import {action, computed, makeObservable, observable} from "mobx";
import {getApiConfiguration} from "../../../../../core";
import {PagedItems, TableStore} from "../../../../utils";
import {DoubleItemStore} from "./DoubleItemStore";
import {PersonItemStore} from "../../PersonItemStore";
import {PersonDoblesApi, PersonDto, PersonsApi} from "../../../../../services/management";
import {DefaultSelectStore} from "../../../../../components/shared/DefaultSelectStore";
import i18n from "../../../../../i18n";
import {PersonStatusesNew} from "../../../../../core/const";

export class DoubleTableStore extends TableStore<DoubleItemStore> {
    readonly store: PersonItemStore;
    antiDobleCheck: boolean = false;
    selectPerson: DefaultSelectStore<PersonDto>;
    current: DoubleItemStore | undefined | null;

    constructor(store: PersonItemStore) {
        super();
        this.store = store;
        this.selectPerson = new DefaultSelectStore(null,
            (filters) => new PersonsApi(getApiConfiguration()).getPersons(filters),
            (value) => this.checkValid(value));
        this.selectPerson.validatorFuncAsync = this.validFn;
        this.selectPerson.isSearchById = true;

        makeObservable(this, {
            current: observable,
            checkValid: action,
            validFn: action,
            childProcessing: computed
        });
    }

    get isAdmin(): boolean {
        return this.store._store.store.store.authorizationStore.isAdmin;
    }

    get isDisable(): boolean {
        return this.store.disable;
    }

    checkValid(value: PersonDto | null): string {
        if (value?.id === this.store?.id) return i18n.t('errors.equalPersId');
        if (this.items.find(x => x.id === value?.id)) return i18n.t('errors.dobleWasAdded').replace("dobleWasAdded", value?.id ?? '');
        if (value?.personStatus?.id === PersonStatusesNew.Double) return i18n.t('errors.cannotAddDouble');
        return ''
    }

    async validFn(value: PersonDto | null | undefined): Promise<string> {
        if (!value) return "";
        const api: PersonsApi = new PersonsApi(getApiConfiguration());
        const api_2: PersonDoblesApi = new PersonDoblesApi(getApiConfiguration());
        try {
            await api.getPersonById({ id: value?.id });
            const result = await api_2.getPersonDobles({ searchType: 'doble', searchId: value?.id });
            if (!!result.length) return i18n.t('errors.hasDobleId').replace("hasDobleId", value?.id);
            const result2 = await api_2.getPersonDobles({ searchType: 'antiDoble', searchId: value?.id });
            if (!!result2.length) return i18n.t('errors.hasAntiDobleId').replace("hasAntiDobleId", value?.id);
            return "";
        } catch (e) {
            return i18n.t('errors.wrongId').replace("wrongId", value?.id);
        }
    }

    async addDoubleToTable() {
        await this.selectPerson.validate();
        if (!this.selectPerson.value) return;
        if (!this.selectPerson.valid) return;
        this.items = [new DoubleItemStore(this, { ...this.selectPerson.value, doubleId: null, date: null }, true), ...this.items];
        this.selectPerson.reset();
    }

    get childProcessing(): boolean {
        return !!this.items.find(x => x.processing);
    }

    async request(): Promise<PagedItems<DoubleItemStore>> {
        const api: PersonDoblesApi = new PersonDoblesApi(getApiConfiguration());
        const newList: DoubleItemStore[] = this.items.filter(x => !x._dto.doubleId);
        let count = 0;
        try {
            const result = await api.getPersonDobles({ searchType: 'antiDoble', searchId: this.store?.id });
            count = result.length ?? 0;
            for (const item of result) {
                newList.push(new DoubleItemStore(this, { ...item.person, doubleId: item.id, date: item?.createdAt ?? null }));
            }
        } catch (e) {
        }
        this.antiDobleCheck = !!count;
        return new PagedItems<DoubleItemStore>(newList, count)
    };
}
