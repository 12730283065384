import {action, computed, makeObservable, observable} from 'mobx';
import {
    DepJobsApi,
    GetOrganizationsRequest,
    JobsApi,
    JobStatusesApi,
    OrganizationDto,
    OrganizationsApi,
    PersonJobDto,
    PersonStatusDto,
    PostDto,
    PostsApi,
    TypeOrgsApi
} from "../../../../../services/management";
import {getApiConfiguration, IForm, IModal, IProcessing} from "../../../../../core";
import {DefaultSelectStore} from '../../../../../components/shared/DefaultSelectStore';
import {LocalityFilterStore} from "../../../../../components/select/Locality/LocalityFilterStore";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";
import {getOrgName, ValueLoadStore} from "../../../../utils";
import {JurisdictionData} from "../../../../authorization/AuthorizationStore";

export class JobCreatorStore extends ValueLoadStore<{ depIds: string[], jobs: PersonJobDto[] }> implements IForm, IModal, IProcessing {
    personOrganization: DefaultSelectStore<OrganizationDto>;
    parentJob: DefaultSelectStore<PersonJobDto>;
    personPost: DefaultSelectStore<PostDto>;
    personStatus: DefaultSelectStore<PersonStatusDto>;
    telemedicine: DefaultValueStore<boolean> = new DefaultValueStore<boolean>(false);
    localitiesFilter: LocalityFilterStore = new LocalityFilterStore();
    searchById: DefaultValueStore<string> = new DefaultValueStore<string>("");
    _open: boolean = false;
    processing: boolean = false;
    personId: string | null;
    afterFunc?: () => Promise<void>;

    constructor(personId: string | null = null, afterFunc?: () => Promise<void>) {
        super()
        this.personId = personId;
        this.afterFunc = afterFunc;

        this.personOrganization = new DefaultSelectStore(null,
            (filters) => {
                const ff: GetOrganizationsRequest = {...filters}
                ff.localityIds = this.localitiesFilter.locality.currentLocality?.value ?
                    [this.localitiesFilter.locality.currentLocality?.value.id] : undefined;
                ff.searchByHouse = this.localitiesFilter.house?.value ?? undefined;
                ff.searchById = this.searchById.value ?? undefined;
                return new OrganizationsApi(getApiConfiguration()).getOrganizations(ff)
            });
        this.personOrganization.required = true;
        this.personOrganization.nameExp = (item) => item.status?.nameRus.toLowerCase() === 'дубль' ? '' : getOrgName(item)

        this.personOrganization.onChanged.push((e) => {
            if (this.isDepOrg) {
                this.parentJob.validate()
            }
        })

        this.personPost = new DefaultSelectStore(null,
            (filters) => new PostsApi(getApiConfiguration()).getPosts({
                ...filters,
                orderBy: 'name_rus',
                order: 'ascending',
                showOnlyActive: true
            }));
        this.personPost.required = true

        this.personStatus = new DefaultSelectStore(null,
            (filters) => new JobStatusesApi(getApiConfiguration()).getJobStatuses({...filters}));
        this.personStatus.required = true

        this.parentJob = new DefaultSelectStore(null,
            (filters) => new JobsApi(getApiConfiguration()).getPersonJobs({
                ...filters,
                organizationIds: [this.personOrganization.value ? this.personOrganization.value?.parentId : ''],
                personId: this.personId ?? ""
            }),
            null);

        this.parentJob.nameExp = (e) => {
            let name = getOrgName(e.organization);
            if (name.length > 35) {
                name = `${name.substring(0, 35)}...`
            }
            return `${name} - ${e.post?.nameRus}`
        }

        makeObservable(this, {
            processing: observable,
            _open: observable,
            Open: computed,
            onParentJob: action,
            isDepOrg: computed,
            onPersonPost: action,
            hasJobParent: computed,
            valid: computed,
            reset: action,
            validate: action,
            create: action,
            createM: action,
            createD: action
        })
    }

    onParentJob() {
        this.parentJob.value = this.isDepOrg && this.hasJobParent.length === 1
            ? this.value?.jobs.find(x => x?.organization.id === this.personOrganization.value?.parentId)!
            : null;
        this.onPersonPost()
    }

    get isDepOrg() {
        return this.personOrganization.value?.typeOrg ? this.value?.depIds.includes(this.personOrganization.value?.typeOrg.id) : false
    }

    onPersonPost() {
        this.personPost.value = !!this.parentJob.value?.id ? this.parentJob.value?.post as PostDto : null;
    }

    get hasJobParent() {
        return this.value?.jobs.filter(x => x?.organization.id === this.personOrganization.value?.parentId) ?? []
    }

    get Open() {
        return this._open;
    }

    set Open(value: boolean) {
        this._open = value;
        if (value)
            this.pull()
        this.reset();
    }

    get valid(): boolean {
        return this.personOrganization.valid && this.personPost.valid && (!this.personId || this.personStatus.valid);
    }

    reset() {
        this.personOrganization.reset();
        this.personPost.reset();
        this.personStatus.reset();
        this.parentJob.reset();
        this.localitiesFilter.locality.reset();
        this.searchById.reset()
        this.onParentJob();
    }

    validate() {
        this.personOrganization.validate();
        this.personPost.validate();
        this.personStatus.validate();
    }

    async create(personId: string | null = this.personId): Promise<void> {
        if (!personId) {
            return;
        }

        this.validate();
        if (!this.valid) {
            return;
        }
        this.processing = true;

        try {
            this.isDepOrg ? await this.createD(personId) : await this.createM(personId)

            if (this.afterFunc) {
                await this.afterFunc()
            }

            this.Open = false;
        } catch (e) {

        }
        this.processing = false;
    }


    async createM(personId: string): Promise<void> {
        await new JobsApi(getApiConfiguration()).createPersonJob({
            setPersonJobDto: {
                personId: personId,
                organizationId: this.personOrganization.value?.id!,
                postId: this.personPost.value?.id!,
                statusId: this.personStatus.value?.id!,
                telemedicine: this.telemedicine.value
            }
        });
    };

    async createD(personId: string): Promise<void> {
        await new DepJobsApi(getApiConfiguration()).createPersonDepJob({
            setPersonDepJobDto: {
                personId: personId,
                organizationDepId: this.personOrganization.value?.id!,
                postId: this.personPost.value?.id!,
                statusId: this.personStatus.value?.id!,
                personJobId: this.parentJob.value?.id || null,
                telemedicine: this.telemedicine.value
            }
        });
    };


    async request(): Promise<{ depIds: string[], jobs: PersonJobDto[] }> {
        const res: { depIds: string[], jobs: PersonJobDto[] } = {jobs: [], depIds: []}

        try {
            res.depIds = (await new TypeOrgsApi(getApiConfiguration()).getTypeOrgs({
                parentId: JurisdictionData.organizationTypes.depsId,
                queryType: 'tree',
                size: 1000,
                showOnlyStructural: true
            })).map(x => x.id)
        } catch (e) {
        }
        if (this.personId) {
            try {
                res.jobs = await new JobsApi(getApiConfiguration()).getAllPersonJobs({
                    page: 1,
                    size: 1000,
                    personId: this.personId,
                    statusIds: [0, 1, 2, 3],
                    states: [0, 1]
                });
            } catch (e) {

            }
        }

        return res;
    }
}
