import {observer} from 'mobx-react';
import {CreateButtonsGroup, FormRoot} from '../../components';
import React from 'react';
import {useTranslation} from "react-i18next";
import {DefaultValue} from "../../components/shared/components/DefaultValue";
import {DefaultFormModalStore} from "../../components/shared/DefaultFormModalStore";
import {DefaultValueStore} from "../../components/shared/DefaultValueStore";


interface Props {
    store: DefaultFormModalStore<{
        nameRus: DefaultValueStore<string>,
        nameEng: DefaultValueStore<string>
    }>
    children?: React.ReactNode,
    placeholder?: string | undefined,
}

export const DictionaryCreator = observer(({store, children, placeholder}: Props) => {
    const {t} = useTranslation();
    return (
        <FormRoot>
            <DefaultValue
                autoFocus
                style={{width: '100%'}}
                testid="dictionariesCreator-nameRus-input"
                title={placeholder ? `${placeholder} (Rus)` : `${t('common.name')} (Rus)`}
                store={store.fields.nameRus}
            />
            <DefaultValue
                style={{width: '100%'}}
                testid="dictionariesCreator-nameEng-input"
                title={placeholder ? `${placeholder} (Eng)` : `${t('common.name')} (Eng)`}
                store={store.fields.nameEng}
            />
            {children}

            <CreateButtonsGroup onCancel={() => store.Open = false}
                                isCancelBtnDisabled={store.processing}
                                onSave={async () => await store.submit()}
                                isSaveBtnDisabled={store.processing || !store.valid}
                                testid="dictionariesCreator"/>
        </FormRoot>
    );
});
