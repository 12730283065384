import {makeObservable, observable} from 'mobx';
import {getOrganizationAddressHandler, getOrgName, PagedItems, TableStore} from "../../../../../utils";
import {getApiConfiguration, toDateTimeString} from "../../../../../../core";
import {JobsApi, TypeOrgsApi,} from "../../../../../../services/management";
import {PersonItemStore} from "../../../PersonItemStore";
import {JurisdictionData} from "../../../../../authorization/AuthorizationStore";
import {JobArchiveCreatorStore} from "./JobArchiveCreatorStore";
import {JobDoubleItemStore} from "../jobDouble/JobDoubleTableStore";

export interface JobDoubleArchiveItemStore extends JobDoubleItemStore {
    isTypeDepartment: boolean
}

export class JobArchiveTableStore extends TableStore<JobDoubleArchiveItemStore> {
    readonly _store: PersonItemStore;
    current: JobDoubleArchiveItemStore | undefined | null;
    readonly creator: JobArchiveCreatorStore = new JobArchiveCreatorStore(this);

    constructor(store: PersonItemStore) {
        super();
        this._store = store;

        makeObservable(this, {
            current: observable
        });
    }

    async request(): Promise<PagedItems<JobDoubleArchiveItemStore>> {
        let filters: any = {
            page: this.page + 1,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            personId: this._store.id,
            states: 2,
        };
        const api: JobsApi = new JobsApi(getApiConfiguration());
        const count = await api.getAllPersonJobsCount(filters);
        const newList: JobDoubleArchiveItemStore[] = [];
        try {
            const depList = (await new TypeOrgsApi(getApiConfiguration()).getTypeOrgs({
                parentId: JurisdictionData.organizationTypes.depsId,
                queryType: 'tree',
                size: 100,
                showOnlyStructural: true
            })).map(x => x.id)
            const result = await api.getAllPersonJobs(filters);
            for (const item of result) {
                newList.push({
                    address: getOrganizationAddressHandler(item.organization?.addressUnique),
                    createDate: item.createdAt ? toDateTimeString(item?.createdAt) : "",
                    date: item.modifiedAt ? toDateTimeString(item?.modifiedAt) : "",
                    orgType: item.organization?.typeOrg?.nameRus,
                    post: item.post.nameRus,
                    id: item.id,
                    status: item.status?.nameRus,
                    organizationName: getOrgName(item.organization),
                    dto: item,
                    isTypeDepartment: depList?.includes(item.organization?.typeOrgId)
                })
            }
        } finally {
        }
        return new PagedItems<JobDoubleArchiveItemStore>(newList, count.count)
    }
}
