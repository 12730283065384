/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Api.Import
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {ExportImportType, ExportImportTypeFromJSON, ExportImportTypeToJSON,} from './ExportImportType';
import {ImportAction, ImportActionFromJSON, ImportActionToJSON,} from './ImportAction';
import {ImportStatus, ImportStatusFromJSON, ImportStatusToJSON,} from './ImportStatus';
import {MinioMongoFileInfo, MinioMongoFileInfoFromJSON, MinioMongoFileInfoToJSON,} from './MinioMongoFileInfo';

/**
 * 
 * @export
 * @interface ImportFile
 */
export interface ImportFile {
    /**
     * 
     * @type {string}
     * @memberof ImportFile
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportFile
     */
    userJurisdictionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportFile
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportFile
     */
    boundId?: string | null;
    /**
     * 
     * @type {MinioMongoFileInfo}
     * @memberof ImportFile
     */
    preparedImportJson?: MinioMongoFileInfo;
    /**
     * 
     * @type {MinioMongoFileInfo}
     * @memberof ImportFile
     */
    afterImportReport?: MinioMongoFileInfo;
    /**
     * 
     * @type {MinioMongoFileInfo}
     * @memberof ImportFile
     */
    preImportReport?: MinioMongoFileInfo;
    /**
     * 
     * @type {ImportStatus}
     * @memberof ImportFile
     */
    status?: ImportStatus;
    /**
     * 
     * @type {number}
     * @memberof ImportFile
     */
    rowsCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ImportFile
     */
    errorsRowCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ImportFile
     */
    warningsRowCount?: number | null;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ImportFile
     */
    errors?: { [key: string]: number; } | null;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ImportFile
     */
    warnings?: { [key: string]: number; } | null;
    /**
     * 
     * @type {Date}
     * @memberof ImportFile
     */
    createDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ImportFile
     */
    updateDate?: Date;
    /**
     * 
     * @type {ImportAction}
     * @memberof ImportFile
     */
    importAction?: ImportAction;
    /**
     * 
     * @type {ExportImportType}
     * @memberof ImportFile
     */
    importType?: ExportImportType;
    /**
     * 
     * @type {boolean}
     * @memberof ImportFile
     */
    ignoreErrors?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ImportFile
     */
    log?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportFile
     */
    name?: string | null;
}

export function ImportFileFromJSON(json: any): ImportFile {
    return ImportFileFromJSONTyped(json, false);
}

export function ImportFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userJurisdictionId': !exists(json, 'userJurisdictionId') ? undefined : json['userJurisdictionId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'boundId': !exists(json, 'boundId') ? undefined : json['boundId'],
        'preparedImportJson': !exists(json, 'preparedImportJson') ? undefined : MinioMongoFileInfoFromJSON(json['preparedImportJson']),
        'afterImportReport': !exists(json, 'afterImportReport') ? undefined : MinioMongoFileInfoFromJSON(json['afterImportReport']),
        'preImportReport': !exists(json, 'preImportReport') ? undefined : MinioMongoFileInfoFromJSON(json['preImportReport']),
        'status': !exists(json, 'status') ? undefined : ImportStatusFromJSON(json['status']),
        'rowsCount': !exists(json, 'rowsCount') ? undefined : json['rowsCount'],
        'errorsRowCount': !exists(json, 'errorsRowCount') ? undefined : json['errorsRowCount'],
        'warningsRowCount': !exists(json, 'warningsRowCount') ? undefined : json['warningsRowCount'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
        'warnings': !exists(json, 'warnings') ? undefined : json['warnings'],
        'createDate': !exists(json, 'createDate') ? undefined : (new Date(json['createDate'])),
        'updateDate': !exists(json, 'updateDate') ? undefined : (new Date(json['updateDate'])),
        'importAction': !exists(json, 'importAction') ? undefined : ImportActionFromJSON(json['importAction']),
        'importType': !exists(json, 'importType') ? undefined : ExportImportTypeFromJSON(json['importType']),
        'ignoreErrors': !exists(json, 'ignoreErrors') ? undefined : json['ignoreErrors'],
        'log': !exists(json, 'log') ? undefined : json['log'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function ImportFileToJSON(value?: ImportFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userJurisdictionId': value.userJurisdictionId,
        'userId': value.userId,
        'boundId': value.boundId,
        'preparedImportJson': MinioMongoFileInfoToJSON(value.preparedImportJson),
        'afterImportReport': MinioMongoFileInfoToJSON(value.afterImportReport),
        'preImportReport': MinioMongoFileInfoToJSON(value.preImportReport),
        'status': ImportStatusToJSON(value.status),
        'rowsCount': value.rowsCount,
        'errorsRowCount': value.errorsRowCount,
        'warningsRowCount': value.warningsRowCount,
        'errors': value.errors,
        'warnings': value.warnings,
        'createDate': value.createDate === undefined ? undefined : (value.createDate.toISOString()),
        'updateDate': value.updateDate === undefined ? undefined : (value.updateDate.toISOString()),
        'importAction': ImportActionToJSON(value.importAction),
        'importType': ExportImportTypeToJSON(value.importType),
        'ignoreErrors': value.ignoreErrors,
        'log': value.log,
        'name': value.name,
    };
}

