/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetUserDto
 */
export interface SetUserDto {
    /**
     * 
     * @type {string}
     * @memberof SetUserDto
     */
    username?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetUserDto
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetUserDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SetUserDto
     */
    jurisdictionIds?: Array<string> | null;
}

export function SetUserDtoFromJSON(json: any): SetUserDto {
    return SetUserDtoFromJSONTyped(json, false);
}

export function SetUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': !exists(json, 'username') ? undefined : json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'jurisdictionIds': !exists(json, 'jurisdictionIds') ? undefined : json['jurisdictionIds'],
    };
}

export function SetUserDtoToJSON(value?: SetUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'password': value.password,
        'name': value.name,
        'jurisdictionIds': value.jurisdictionIds,
    };
}

