import {observer} from 'mobx-react';
import React, {useState} from "react";
import {LocalitySelect} from "../../../../components/select/Locality/LocalitySelect";
import {FormRoot, FormRow} from "../../../../components";
import {DefaultSelect} from "../../../../components/shared/components/DefaultSelect";
import {useTranslation} from "react-i18next";
import {DefaultAddressesStore} from "../../../../shared/address/DefaultAddressStore";
import {DefaultValue} from "../../../../components/shared/components/DefaultValue";
import {DefaultCheckbox} from "../../../../components/shared/components/DefaultCheckbox";
import {Box} from '@mui/material';

interface Props {
    store: DefaultAddressesStore;
}

export const AddressCreator = observer(({store}: Props) => {
    const {t} = useTranslation();
    const [step, setStep] = useState(0);
    const stepRef = React.useRef<HTMLDivElement| null>(null);

    const stepLocality = () => {
        if(!stepRef.current) return;
        const localityElement = stepRef.current?.getElementsByClassName('autocompleteLocality').length;
        setStep(localityElement);
    };

    return (
        <FormRoot ref={stepRef}>
            <FormRow style={{flexDirection: 'column', height: 'max-content'}}>
                <LocalitySelect locality={store.locality?.locality} style={{flex: 1}}
                                stepLocality={stepLocality} step={step} />
            </FormRow>
            {!!store?.locality?.currentLocality?.isEndpoint &&
                <>
                    <FormRow>
                        <DefaultValue
                            data-testid="addressCreator-house-input"
                            style={{flex: 1}}
                            title={t('localities.house')}
                            store={store.house}
                        />
                        <DefaultValue
                            data-testid="addressCreator-building-input"
                            style={{flex: 1, marginLeft: 8}}
                            title={t('localities.building')}
                            store={store.building}
                    />
                </FormRow>

                <FormRow>
                    <Box sx={{width: '50%'}}>
                        <DefaultSelect
                            store={store.entryType}
                            title={t('localities.entryType')}
                            testid="addressCreator-entryType"
                            hasLabel />
                    </Box>
                    {!!store.entryType.value &&  <DefaultValue
                        data-testid="addressCreator-entryValue-input"
                        style={{flex: 1, marginLeft: 8}}
                        title={t('localities.entryValue')}
                        store={store.entryValue}
                    />}
                </FormRow>

                <FormRow style={{marginTop: 10}}>
                    <DefaultCheckbox store={store.isCustomCoordinates}
                        style={{flex: 1}}
                        title={t('common.isCustomCoordinates')} />
                </FormRow>
                    {
                        !!store.isCustomCoordinates.value &&
                        <FormRow style={{marginTop: 10}}>
                            <DefaultValue
                                data-testid="addressCreator-latitude-input"
                                title={t('localities.gps_la')}
                                style={{flex: 1}}
                                store={store.latitude}
                            />
                            <DefaultValue
                                data-testid="addressCreator-longtitude-input"
                                style={{flex: 1, marginLeft: '8px'}}
                                title={t('localities.gps_lo')}
                                store={store.longtitude}
                            />
                        </FormRow>
                    }
                    <FormRow style={{marginTop: 10}}>
                        <DefaultValue store={store.zip}
                            title={t('localities.zip')}
                                      testid="addressCreator-zip"/>
                    </FormRow>
                    {store.hasDistrictRegion &&
                        <>
                            <FormRow>
                                <DefaultSelect
                                    store={store.district}
                                    nameExp={'name'}
                                    title={t('localities.district')}
                                    testid="addressCreator-district"
                                    hasLabel />
                            </FormRow>
                            <FormRow>
                                <DefaultSelect
                                    store={store.okrug}
                                    nameExp={'name'}
                                    title={t('localities.okrug')}
                                    testid="addressCreator-okrug"
                                    hasLabel />
                            </FormRow>
                        </>
                    }
                </>
            }
        </FormRoot>
    );
});
