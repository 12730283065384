import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import type {FC, ReactNode} from 'react';

import {Operation, SectionType} from '../../../core';
import {TopNavItem} from './top-nav-item';

interface Item {
    disabled?: boolean;
    external?: boolean;
    icon?: ReactNode;
    items?: Item[];
    label?: ReactNode;
    path?: string;
    title: string;
    section?: SectionType;
    operation?: Operation;
    permissions?: string[]
}

interface TopNavSectionProps {
    items?: Item[];
    pathname?: string | null;
    subheader?: string;
    direction?: 'row' | 'column';
    mode?: 'full' | 'small';
}

export const TopNavSection: FC<TopNavSectionProps> = (props) => {
    const { items = [], pathname, direction = 'row', mode = 'full' } = props;

    return (
        <Stack
            component="ul"
            direction={direction}
            spacing={1}
            sx={{
                listStyle: 'none',
                m: 0,
                p: 0,
            }}
        >
            {items.map((item) => {
                const checkPath = !!(item.path && pathname);
                const partialMatch = checkPath
                    ? (item.items?.length ?? 0) > 0
                        ? pathname?.indexOf(item.path!) == 0
                        : pathname.includes(item.path!)
                    : false;
                const exactMatch = checkPath ? pathname === item.path : false;

                // Branch

                if (item.items) {
                    return (
                        <TopNavItem
                            mode={mode}
                            active={partialMatch}
                            disabled={item.disabled}
                            icon={item.icon}
                            items={item.items}
                            key={item.title}
                            label={item.label}
                            title={item.title}
                            permissions={item.permissions}
                        />
                    );
                }

                // Leaf

                return (
                    <TopNavItem
                        mode={mode}
                        active={exactMatch}
                        disabled={item.disabled}
                        external={item.external}
                        icon={item.icon}
                        key={item.title}
                        label={item.label}
                        path={item.path}
                        title={item.title}
                        permissions={item.permissions}
                    />
                );
            })}
        </Stack>
    );
};

TopNavSection.propTypes = {
    items: PropTypes.array,
    pathname: PropTypes.string,
    subheader: PropTypes.string,
};
