/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationDto,
    OrganizationDtoFromJSON,
    OrganizationDtoFromJSONTyped,
    OrganizationDtoToJSON,
} from './OrganizationDto';

/**
 * 
 * @export
 * @interface OrganizationDobleDto
 */
export interface OrganizationDobleDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDobleDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDobleDto
     */
    externalId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationDobleDto
     */
    createdAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDobleDto
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationDobleDto
     */
    modifiedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDobleDto
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganizationDobleDto
     */
    ticks?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDobleDto
     */
    organizationId: string;
    /**
     * 
     * @type {OrganizationDto}
     * @memberof OrganizationDobleDto
     */
    organization: OrganizationDto;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDobleDto
     */
    dobleId: string;
    /**
     * 
     * @type {OrganizationDto}
     * @memberof OrganizationDobleDto
     */
    doble: OrganizationDto;
}

export function OrganizationDobleDtoFromJSON(json: any): OrganizationDobleDto {
    return OrganizationDobleDtoFromJSONTyped(json, false);
}

export function OrganizationDobleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationDobleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (json['modifiedAt'] === null ? null : new Date(json['modifiedAt'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'ticks': !exists(json, 'ticks') ? undefined : json['ticks'],
        'organizationId': json['organizationId'],
        'organization': OrganizationDtoFromJSON(json['organization']),
        'dobleId': json['dobleId'],
        'doble': OrganizationDtoFromJSON(json['doble']),
    };
}

export function OrganizationDobleDtoToJSON(value?: OrganizationDobleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalId': value.externalId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt === null ? null : value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt === null ? null : value.modifiedAt.toISOString()),
        'modifiedBy': value.modifiedBy,
        'ticks': value.ticks,
        'organizationId': value.organizationId,
        'organization': OrganizationDtoToJSON(value.organization),
        'dobleId': value.dobleId,
        'doble': OrganizationDtoToJSON(value.doble),
    };
}

