import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {Page, PageTab, PageTabs} from "../../../components";
import {JobsTabsStore} from "../../../stores/management/persons/tabs/jobs/JobsTabsStore";
import {JobArchiveTable} from "./jobArchive/JobArchiveTable";
import { JobDoubleTable } from "./jobDouble/JobDoubleTable";
import { Jobs } from "./Jobs";

interface DetailsProps {
    store: JobsTabsStore;
    style?: React.CSSProperties;
}

export const JobsTabs = observer(({ store, style }: DetailsProps) => {
    const { t } = useTranslation();

    const renderTabs = (index: number) => {
        switch (index) {
            case 0:
                return <Jobs store={store.JobsStore} />;
            case 1:
                return <JobDoubleTable store={store.JobDoubleStore} />;
            case 2:
                return <JobArchiveTable store={store.JobArchiveStore} />;
            default:
                return null;
        }
    };

    return (
        <Page
            style={style}
        >
            <PageTabs
                style={{ marginTop: -4, marginBottom: 8 }}
                index={store.tabIndex}
                onChange={async (t) => await store.setTabIndex(t)}>
                <PageTab label={t('common.availableJob').toUpperCase()} testid="JobsTabs-available-tabs" />
                <PageTab label={t('common.doubles').toUpperCase()} testid="JobsTabs-double-tabs" />
                <PageTab label={t('common.archival').toUpperCase()} testid="JobsTabs-archive-tabs" />
            </PageTabs>
            <div style={{ marginTop: 4, marginBottom: 4 }}>
                {renderTabs(store.tabIndex)}
            </div>
        </Page>
    );
});
