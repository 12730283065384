import {BaseSpecification} from "./BaseSpecification";
import {mappingDcrStatusToString} from "../../../services";
import {PersonStore} from "../entity/PersonStore";
import {RequestDetailsStore} from "../../RequestDetailsStore";
import {t} from "i18next";
import {action, computed, makeObservable} from "mobx";
import {createElement} from "react";
import {
    AzDetailsAfterComponent,
    AzDetailsBeforeComponent,
    AzDetailsButtonsComponent
} from "../../../../../../pages/dcr/requests/specifications/AzSpecification";
import {DcrInfoField} from "./CrmSpecification";
import {DCRStatusEnum, OrganizationField, PersonField, RequestField, RequestTypeEnum} from "../../../types";

const AddressField = ["region", "city", "okrug", "district", "street", "streettype", "building"]

export class AzSpecification extends BaseSpecification {

    constructor(store: RequestDetailsStore) {
        super(store);
        this.store.commentStore.setValidator((value) => !value ? t("common.wrongValue") : "");
        this.DetailsAfterComponent = createElement(AzDetailsAfterComponent, {store: this}, null)
        this.DetailsBeforeComponent = createElement(AzDetailsBeforeComponent, {store: this}, null)
        this.DetailsButtonsComponent = createElement(AzDetailsButtonsComponent, {store: this}, null)

        makeObservable(this, {
            DcrInfoFields: computed,
            escalationComment: computed,
            DcrStatusName: computed,
            SpecValid: computed,
            valid: computed,
            changeAfterInitFieldsSpec: action,
        });
    }

    createIfExist(fields: DcrInfoField[], additionalKey: string){
        if (this.store.dto.additionalFields?.[additionalKey])
            fields.push({
                name: t('common.' + additionalKey),
                value: this.store.dto.additionalFields?.[additionalKey] ?? t("dcrPage.empty")
            })
    }

    get DcrInfoFields(): DcrInfoField[] {
        const fields = [
            {name: t('dcrPage.status'), value: this.store._store.statusName},
            {name: t('dcrPage.dcrStatus'), value: this.DcrStatusName},
            {name: t('dcrPage.createDate'), value: this.store._store.createdDate},
            {name: t('dcrPage.modifiedDate'), value: this.store._store.modifiedDate},
            {name: t('Crm Id'), value: this.store.dto.crmId.slice(0, this.store.dto.crmId.indexOf('_'))},
        ];

        if (this.store.dto.type == RequestTypeEnum.Person) {
            this.createIfExist(fields,'position')
            this.createIfExist(fields,'specialization')
            this.createIfExist(fields,'additionalSpecialization')
        }

        this.createIfExist(fields,'escalationComment')
        this.createIfExist(fields,'dublicate')

        return fields;
    }

    get escalationComment(): string | null | undefined {
        return this.store.dto.additionalFields?.escalationComment
    }

    get DcrStatusName(): string {
        return mappingDcrStatusToString(this.store.dto.dcrStatus as DCRStatusEnum) +
            (this.store.dto.dcrStatus === DCRStatusEnum.Update ? ` (${this.store.dto!.additionalFields?.azType})` : "")
    }

    get SpecValid(): boolean {
        return this.store.commentStore.valid
    }

    async changeAfterInitFieldsSpec(fields: RequestField[]): Promise<void> {
        const spec = fields.find(x => x.field == 'specialization')
        if (spec && !spec.store.value && this.store.dto.dcrStatus == DCRStatusEnum.Update) {
            const data = (this.store.data as PersonStore);
            spec.store.value = data.data.primarySpeciality ?? data.specialities[0];
            const isPrimary = fields.find(x => x.field == 'isPrimarySpeciality')
            if (isPrimary)
                isPrimary.store.value = !!data.data.primarySpeciality
        }
    }

    addTextField(field: (RequestField & { getValue: (e: RequestField) => string })) {
        const value = field.getValue(field);
        const comment = field.name + ': ' + (!!value ? value : t("common.empty"));
        this.store.commentStore.value = this.store.commentStore?.value && this.store.commentStore.value?.trim().length > 0 ? this.store.commentStore.value + " " + comment : comment;
    }

    handleAzDoubleCheckBoxComment() {
        //дубль чек
        if (this.store.fields.find(x => x.field == "isDouble")?.store.value) {
            this.store.commentStore.disabled = true;
        }
        //дубль анчек
        else {
            if (this.store.fields.filter(x => x.field == "originalId")?.[0]?.store?.value?.id) {
                this.store.commentStore.value = "";
            }
            this.store.commentStore.disabled = false;
        }
    }

    handleAzDoubleValComment() {
        const originalId = this.store.fields.filter(x => x.field == "originalId")?.[0]?.store?.value?.id;
        const isDouble = this.store.fields.filter(x => x.field == "isDouble")?.[0]?.store?.value;

        if (isDouble) {
            this.store.commentStore.value = originalId ? (this.store.dto.type == 1 ?
                `Создание дубль-персоны. ID карточки клиента ${originalId}` :
                `Создание дубль-организации. ID карточки Организации ${originalId}`) : ""
        }
    }


    get commentField(): (RequestField & { getValue: (e: RequestField) => string })[] {
        const defaultGetValue = (e: RequestField) => e.store.readValue;

        const infoFields = this.store.fields.map(x => ({...x, ...{getValue: defaultGetValue}}));

        const addressField = infoFields.filter(x => AddressField.indexOf(x.field) > -1)

        if (addressField.length > 0) {
            const valueWitnName: (OrganizationField | PersonField)[] = ["streettype", "street", "building"]
            const addressFieldIndex = infoFields.indexOf(addressField[0]);

            const addressGetValue = (e: RequestField) => {
                return addressField.length == 0 ?
                    addressField[0].getValue(addressField[0]) :
                    addressField
                        .map(x => ({name: x.name, value: x.getValue(x), field: x.field}))
                        .filter(x => !!x.value)
                        .map(x => x.value + (valueWitnName.indexOf(x.field) > -1 ? " (" + x.name + ")" : ""))
                        .reduce((x, y) => x + ", " + y)
            }

            infoFields.splice(addressFieldIndex, 0, {
                ...{} as RequestField, ...{
                    name: "Адрес",
                    getValue: addressGetValue
                }
            });
        }

        if (this.store.dto.dcrStatus == DCRStatusEnum.Update) {
            let label = (this.store.dto.type == RequestTypeEnum.Person ? t("dcrPage.type.person") : t("dcrPage.type.organization")) + " Id";

            infoFields.splice(0, 0, {
                ...{} as RequestField, ...{
                    name: label,
                    getValue: (e: any) => this.store.data?.data?.id
                }
            });
        }

        return infoFields;
    }
}
