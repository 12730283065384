import {getApiConfiguration} from "../../../../../core";
import {BoundDto, SpecialitiesApi, SpecialityDto,} from "../../../../../services/management";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from "../../../../../components/shared/DefaultSelectStore";
import {MappingTableStore} from "./MappingTableStore";
import {MappingsStore} from "./MappingsStore";
import {DefaultFormModalStore} from "../../../../../components/shared/DefaultFormModalStore";
import {getAppoloClient, GRAPHQL_URLS} from "../../../../../graphql";
import {
    RemoveSpecialityMappingMutation,
    RemoveSpecialityMappingMutationVariables,
    SetSpecialityMappingMutation,
    SetSpecialityMappingMutationVariables,
    SpecialityMappingFragment,
    SpecialityMappingsQuery,
    SpecialityMappingsQueryVariables,
} from "../../../../../gql/graphql";
import {SPECIALITY_MAPPING_QUERY} from "../../../../../graphql/queries.graphql";
import {
    REMOVE_SPECIALITY_MAPPING_MUTATION,
    SET_SPECIALITY_MAPPING_MUTATION
} from "../../../../../graphql/mutations.graphql";
import {jurisdictionId} from "../../../../authorization/AuthorizationStore";

export class SpecialityTableStore extends MappingTableStore<SpecialityMappingFragment> {
    constructor(store: MappingsStore, dto: BoundDto) {
        super(store, dto, 'client-mappings-specoality-store');

        this.creator.fields.entity.defaultSort = true;
        this.creator.fields.entity.required = true;
        this.creator.fields.value.required = true;
    }

    creator: DefaultFormModalStore<{ value: DefaultValueStore<string>; entity: DefaultSelectStore<any> }> =
        new DefaultFormModalStore({
            entity: new DefaultSelectStore<SpecialityDto>(
                null, (x) => new SpecialitiesApi(getApiConfiguration()).getSpecialities({
                    ...x,
                    orderBy: 'nameRus',
                    order: 'ascending'
                })),
            value: new DefaultValueStore<string>("")
        }, (model) => this.create());

    async getMappings(filters: any): Promise<{
        items: { item: SpecialityMappingFragment; name: string }[];
        count: number
    }> {
        const {data} = await getAppoloClient(GRAPHQL_URLS.MAPPINGS).query<SpecialityMappingsQuery, SpecialityMappingsQueryVariables>({
            query: SPECIALITY_MAPPING_QUERY, variables: filters,
        })

        return {
            items: (data.specialityMappings?.items ?? []).map(x => ({item: x, name: x.names?.nameRus ?? ""})),
            count: data.specialityMappings?.totalCount ?? 0
        }
    }

    async removeMapping(filter: {
        boundId: string;
        setMappingDto: Array<{ clientValue: string; entityId: string }>
    }): Promise<void> {
        for (let val of filter.setMappingDto) {
            await getAppoloClient(GRAPHQL_URLS.MAPPINGS).mutate<RemoveSpecialityMappingMutation, RemoveSpecialityMappingMutationVariables>({
                mutation: REMOVE_SPECIALITY_MAPPING_MUTATION, variables: {
                    clientId: val.clientValue,
                    entityId: val.entityId,
                    jurisdictionId: jurisdictionId
                }
            })
        }
    }

    async setMapping(filter: {
        boundId: string;
        setMappingDto: Array<{ clientValue: string; entityId: string }>
    }): Promise<{ item: SpecialityMappingFragment; name: string }[]> {
        const list: { item: SpecialityMappingFragment; name: string }[] = [];
        for (let val of filter.setMappingDto) {
            const item = await getAppoloClient(GRAPHQL_URLS.MAPPINGS).mutate<SetSpecialityMappingMutation, SetSpecialityMappingMutationVariables>({
                mutation: SET_SPECIALITY_MAPPING_MUTATION, variables: {
                    entityId: val.entityId!,
                    clientValue: val.clientValue,
                    clientId: filter.boundId,
                    jurisdictionId: jurisdictionId
                }
            })
            list.push(({
                item: item.data?.setSpecialityMapping!,
                name: item.data?.setSpecialityMapping.names?.nameRus ?? ""
            }))
        }
        return list;
    }
}
