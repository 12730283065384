import {Box, Card, Table, TableBody, TableHead, TableRow, Typography,} from '@mui/material';
import {observer} from 'mobx-react';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {
    AddButton,
    AuthorizePermission,
    Cell,
    ConfirmationForm,
    EmptyBox,
    ExpandButton,
    ExpandRow,
    ExpertDialog,
    HCell,
    Pagination,
    Progress,
    Search,
} from '../../../../components';
import {MenuComponent} from '../../../../components/MenuComponent';
import {MenuItemComponent} from '../../../../components/MenuItemComponent';
import {NavLink} from '../../../../components/NavLink';
import {DefaultMultiSelect} from '../../../../components/shared/components/DefaultMultiSelect';
import {toDateTimeString} from '../../../../core';
import {AllPermissions} from '../../../../core/const';
import {DeleteIcon} from '../../../../icons/deleteIcon';
import {callTheProcessingFunc, StoreProps} from '../../../../stores';
import {RoleTableStore} from '../../../../stores/management/users/tabs/Roles/RoleTableStore';
import {RoleCreator} from './RoleCreator';
import {RoleDetails} from './RoleDetails';

export const RoleTableTab = observer(
    ({ store }: StoreProps<RoleTableStore>) => {
        const { t } = useTranslation();

        return (
            <Box>
                <AuthorizePermission
                    permissions={AllPermissions.role.read}
                    showWarning
                >
                    {store.deleteDialogState && (
                        <ExpertDialog
                            title="Удаление"
                            open={store.deleteDialogState}
                            onClose={() => (store.deleteDialogState = false)}
                        >
                            <ConfirmationForm
                                disabled={store.processing}
                                onSubmit={async () =>
                                    await callTheProcessingFunc(
                                        store,
                                        (e) => store.delete(),
                                        async (e) => await store.pull()
                                    )()
                                }
                                onClose={() =>
                                    (store.deleteDialogState = false)
                                }
                            >
                                {t('common.deleteConfirm')} "
                                {store.current?.dto.name}"?
                            </ConfirmationForm>
                        </ExpertDialog>
                    )}

                    <Box sx={{ flexGrow: 1 }}>
                        <ExpertDialog
                            title={t('create.role')}
                            open={store.creator.Open}
                            onClose={() => (store.creator.Open = false)}
                        >
                            <RoleCreator store={store.creator} />
                        </ExpertDialog>

                        <Box sx={{ mb: 2, mt: 2 }}>
                            <Box
                                style={{ marginLeft: 'auto', display: 'table' }}
                            >
                                <AuthorizePermission
                                    permissions={AllPermissions.role.create}
                                >
                                    <AddButton
                                        onClick={() =>
                                            (store.creator.Open = true)
                                        }
                                    />
                                </AuthorizePermission>
                            </Box>

                            <Box
                                sx={{ mt: 2, mb: 2 }}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >

                                <Search
                                    autoFocus
                                    value={store.search.value}
                                    onChange={(t) => (store.search.value = t)}
                                    style={{ width: '32%', marginTop: 8 }}
                                    testid="roles"
                                />
                                <DefaultMultiSelect
                                    store={store.users}
                                    title={t(
                                        'usersPage.roles.table.filter.users'
                                    )}
                                    nameExp="name"
                                    valueExp="id"
                                    style={{ width: '32%' }}
                                />
                                <DefaultMultiSelect
                                    store={store.permissions}
                                    title={t(
                                        'usersPage.roles.table.filter.permissions'
                                    )}
                                    nameExp="name"
                                    valueExp="id"
                                    style={{ width: '32%' }}
                                />
                            </Box>

                            <Card sx={{ mb: 8, overflow: 'unset' }}>
                                <Table sx={{ minWidth: 800 }}>
                                    <TableHead
                                        style={{
                                            position: 'sticky',
                                            top: 64,
                                            zIndex: 999,
                                        }}
                                    >
                                        <TableRow>
                                            <HCell
                                                width="5%"
                                                testid="roles-expand"
                                            />
                                            <HCell
                                                store={store}
                                                testid="roles-name"
                                            >
                                                {t('common.name')}
                                            </HCell>
                                            <HCell>
                                                {t(
                                                    'usersPage.roles.table.permissions'
                                                )}
                                            </HCell>
                                            <HCell>
                                                {t(
                                                    'usersPage.roles.table.users'
                                                )}
                                            </HCell>
                                            <HCell
                                                orderBy="modifiedAt"
                                                store={store}
                                                width="15%"
                                                testid="roles-date"
                                            >
                                                {t(
                                                    'usersPage.roles.table.date'
                                                )}
                                            </HCell>
                                            <HCell
                                                style={{
                                                    width: 30,
                                                    padding: '0 8px 0 0',
                                                }}
                                            ></HCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {store.items.length > 0 &&
                                            store.items.map((role) => {
                                                return (
                                                    <Fragment key={role.dto.id}>
                                                        <TableRow
                                                            hover
                                                            onClick={() =>
                                                                (store.current =
                                                                    role)
                                                            }
                                                            key={role.dto.id}
                                                        >
                                                            <ExpandButton
                                                                item={role}
                                                                isOpen={
                                                                    role.expand
                                                                }
                                                                testid="roles"
                                                            />

                                                            <Cell testid="roles-name">
                                                                <NavLink
                                                                    to={`/roles/${role.dto.id}`}
                                                                >
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        sx={{
                                                                            overflow:
                                                                                'hidden',
                                                                            textOverflow:
                                                                                'ellipsis',
                                                                        }}
                                                                    >
                                                                        {
                                                                            role
                                                                                .dto
                                                                                .name
                                                                        }
                                                                    </Typography>
                                                                </NavLink>
                                                            </Cell>
                                                            <Cell
                                                                style={{
                                                                    maxWidth: 150,
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="textSecondary"
                                                                >
                                                                    {role.dto
                                                                        ?.permissionsCount ??
                                                                        0}
                                                                </Typography>
                                                            </Cell>
                                                            <Cell
                                                                style={{
                                                                    maxWidth: 150,
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="textSecondary"
                                                                >
                                                                    {role.dto
                                                                        ?.userCount ??
                                                                        0}
                                                                </Typography>
                                                            </Cell>
                                                            <Cell
                                                                style={{
                                                                    maxWidth: 150,
                                                                }}
                                                                testid="roles-date"
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="textSecondary"
                                                                >
                                                                    {toDateTimeString(
                                                                        role.dto
                                                                            .modifiedAt
                                                                    )}
                                                                </Typography>
                                                            </Cell>
                                                            <AuthorizePermission
                                                                permissions={
                                                                    AllPermissions
                                                                        .role
                                                                        .delete
                                                                }
                                                            >
                                                                <Cell
                                                                    align={
                                                                        'center'
                                                                    }
                                                                >
                                                                    <MenuComponent>
                                                                        <MenuItemComponent
                                                                            text={t(
                                                                                'buttons.delete'
                                                                            )}
                                                                            icon={
                                                                                <DeleteIcon fontSize="small" />
                                                                            }
                                                                            onClick={() =>
                                                                                (store.deleteDialogState =
                                                                                    true)
                                                                            }
                                                                        />
                                                                    </MenuComponent>
                                                                </Cell>
                                                            </AuthorizePermission>
                                                        </TableRow>
                                                        {role.expand && (
                                                            <ExpandRow
                                                                key={`${role.dto.id}_`}
                                                                item={role}
                                                            >
                                                                <RoleDetails
                                                                    store={role}
                                                                    style={{
                                                                        margin: '0 40px',
                                                                    }}
                                                                />
                                                            </ExpandRow>
                                                        )}
                                                    </Fragment>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                                {!store.items.length && <EmptyBox />}
                                {store.items.length > 0 && (
                                    <Pagination store={store} />
                                )}

                                <Progress store={store} />
                            </Card>
                        </Box>
                    </Box>
                </AuthorizePermission>
            </Box>
        );
    }
);
