/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface ResDto1
 */
export interface ResDto1 {
    /**
     * 
     * @type {string}
     * @memberof ResDto1
     */
    dcrId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResDto1
     */
    erorType?: string | null;
}

export function ResDto1FromJSON(json: any): ResDto1 {
    return ResDto1FromJSONTyped(json, false);
}

export function ResDto1FromJSONTyped(json: any, ignoreDiscriminator: boolean): ResDto1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dcrId': !exists(json, 'dcrId') ? undefined : json['dcrId'],
        'erorType': !exists(json, 'erorType') ? undefined : json['erorType'],
    };
}

export function ResDto1ToJSON(value?: ResDto1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dcrId': value.dcrId,
        'erorType': value.erorType,
    };
}

