import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {AuthorizePermission, Page, PageTab, PageTabs} from "../../../../components";
import {AllPermissions} from "../../../../core/const";
import {OthersTabsStore} from "../../../../stores/management/organizations/details/others/OthersTabsStore";
import {PhoneLogo as PhoneLogoIcon} from "../../../../icons/phone-logo";
import React from "react";
import {EmailLogo as EmailLogoIcon} from "../../../../icons/email-logo";
import {WebsiteLogo as WebsiteLogoIcon} from "../../../../icons/website-logo";
import {OrganizationPhonesTable} from "./phones/OrganizationPhonesTable";
import {OrganizationEmailTable} from "./emails/OrganizationEmailTable";
import {OrganizationWebsiteTable} from "./websites/OrganizationWebsiteTable";

interface Props {
    store: OthersTabsStore;
    style?: React.CSSProperties
}

export const OthersTab = observer(({store, style}: Props) => {
    const {t} = useTranslation();

    const renderTabs = (index: number) => {
        switch (index) {
            case 0:
                return (
                    <AuthorizePermission permissions={AllPermissions.organization.phones.read}>
                        <OrganizationPhonesTable store={store.phoneStore}/>
                    </AuthorizePermission>
                )
            case 1:
                return (
                    <AuthorizePermission permissions={AllPermissions.organization.emails.read}>
                        <OrganizationEmailTable store={store.emailStore}/>
                    </AuthorizePermission>
                )
            case 2:
                return (
                    <AuthorizePermission permissions={AllPermissions.organization.websites.read}>
                        <OrganizationWebsiteTable store={store.websiteStore}/>
                    </AuthorizePermission>
                )
        }
    };

    return (
        <Page
            style={style}
        >
            <PageTabs
                index={store.tabIndex}
                onChange={t => store.tabIndex = t}>
                <PageTab icon={<PhoneLogoIcon/>} title={t('common.phone')}/>
                <PageTab icon={<EmailLogoIcon/>} title={t('common.email')}/>
                <PageTab icon={<WebsiteLogoIcon/>} title={t('common.websites')}/>
            </PageTabs>
            <div style={{marginTop: 4, marginBottom: 4, padding: 15}}>
                {renderTabs(store.tabIndex)}
            </div>
        </Page>
    );
});
