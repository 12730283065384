import {makeAutoObservable} from 'mobx';
import {getApiConfiguration, getObject, IExpanded, IHasTabs, setObject, toDateTimeString} from '../../../core';
import {
    AssignedPersonTagsApi,
    FirstNameDto,
    FirstNamesApi,
    GenderDto,
    GendersApi,
    MiddleNameDto,
    MiddleNamesApi,
    PersonDoblesApi,
    PersonDto,
    PersonsApi,
    PersonStatusDto,
    PersonStatusesApi,
    PersonTagsApi,
    SurnameDto,
    SurnamesApi,
    TagDto
} from '../../../services/management';
import {PersonTableStore} from "./PersonTableStore";
import {JobsTableStore} from "./tabs/jobs/JobsTableStore";
import {DefaultSelectStore} from '../../../components/shared/DefaultSelectStore';
import {DefaultValueStore} from '../../../components/shared/DefaultValueStore';
import i18n from '../../../i18n';
import {DoubleTableStore} from './tabs/doubles/DoubleTableStore';
import {PersonStatusesNew} from '../../../core/const';
import {DefaultMultiSelectStore} from '../../../components/shared/DefaultMultiSelectStore';
import {JobsTabsStore} from './tabs/jobs/JobsTabsStore';
import {PersonOthersTabsStore} from "./tabs/others/PersonOthersTabsStore";
import {NotesTableStore} from "./tabs/notes/NotesTableStore";
import {PersonSpecialityStore} from "./tabs/speciality/PersonSpecialityStore";

interface PersonViewModelLocalFilters {
    tabIndex: number;
    expand: boolean;
}

const isEqual = require('lodash.isequal');

export class PersonItemStore implements IHasTabs, IExpanded {
    readonly _store: PersonTableStore;
    public _dto: PersonDto;
    id: string;
    externalId: string;
    readonly pGender: DefaultSelectStore<GenderDto>;
    readonly pName: DefaultSelectStore<FirstNameDto>;
    pMiddleName: DefaultSelectStore<MiddleNameDto>;
    readonly pSurname: DefaultSelectStore<SurnameDto>;
    readonly pMiddename: DefaultSelectStore<SurnameDto>;
    personStatus: DefaultSelectStore<PersonStatusDto>;
    specialities: PersonSpecialityStore;
    jobControl: JobsTableStore;
    processing: boolean = false;
    private _tabIndex: number = 0;
    private _expand: boolean = false;
    deleteDialogState: boolean = false;
    jobTabs: JobsTabsStore;
    notes: NotesTableStore;
    personOthersStore: PersonOthersTabsStore;
    doubleStore: DoubleTableStore;
    hasNoMiddleName: boolean;
    tagsControl: DefaultMultiSelectStore<TagDto>;
    isDouble: boolean;
    isDead: boolean;
    doble: DefaultValueStore<string>;
    defaultDobleId: string = '';
    antiDoble: DefaultValueStore<string>;
    countNotes: number = 0;

    primaryJob: DefaultValueStore<string> = new DefaultValueStore<string>("");
    primarySpeciality: DefaultValueStore<string> = new DefaultValueStore<string>("");

    constructor(store: PersonTableStore, dto: PersonDto) {
        this._store = store;
        this._dto = dto;
        this.id = dto.id;
        this.externalId = dto.externalId ?? '';
        this.hasNoMiddleName = dto?.hasNoMiddleName ?? false;
        this.personOthersStore = new PersonOthersTabsStore(this, dto);
        this.notes = new NotesTableStore(this);
        this.doubleStore = new DoubleTableStore(this);
        this.isDouble = dto.personStatus?.id === PersonStatusesNew.Double;
        this.isDead = dto.personStatus?.id === PersonStatusesNew.Dead;
        this.primaryJob = new DefaultValueStore<string>(dto.primaryJob?.id);
        this.primarySpeciality = new DefaultValueStore<string>(dto.primarySpeciality?.id);

        this.pGender = new DefaultSelectStore(this._dto?.gender as GenderDto || null,
            (x) => new GendersApi(getApiConfiguration()).getGenders(x),
            (value) => !value ? i18n.t('errors.empty') : "");

        this.pName = new DefaultSelectStore(this._dto?.firstName as FirstNameDto || null,
            (x) => new FirstNamesApi(getApiConfiguration()).getFirstNames(x),
            (value) => !value ? i18n.t('errors.empty') : "");

        this.pMiddleName = new DefaultSelectStore(this._dto?.middleName as MiddleNameDto || null,
            (x) => new MiddleNamesApi(getApiConfiguration()).getMiddleNames(x),
            (value) => (!value && !this.hasNoMiddleName) ? i18n.t('errors.empty') : "");

        this.pSurname = new DefaultSelectStore(this._dto?.surname as SurnameDto || null,
            (x) => new SurnamesApi(getApiConfiguration()).getSurnames(x),
            (value) => !value ? i18n.t('errors.empty') : "");

        this.pMiddename = new DefaultSelectStore(this._dto?.maidenName as SurnameDto || null,
            (x) => new SurnamesApi(getApiConfiguration()).getSurnames(x));

        this.personStatus = new DefaultSelectStore(this._dto?.personStatus as PersonStatusDto || null,
            (filters) => new PersonStatusesApi(getApiConfiguration()).getPersonStatuses({ ...filters }),
            (value) => !value ? i18n.t('errors.empty') : (value.id === PersonStatusesNew.Double) ? i18n.t('errors.dobleStatus').replace("dobleStatus", 'Персона') : "", 'id')

        this.doble = new DefaultValueStore<string>(this.defaultDobleId,
            (value) => !value ? i18n.t('errors.empty') : (value === this._dto.id) ? i18n.t('errors.equalId') : new RegExp(/^\w{8}.\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/).test(value) ? "" : i18n.t('errors.wrongId').replace("wrongId", value));
        this.doble.validatorFuncAsync = this.validFn;

        this.antiDoble = new DefaultValueStore<string>('',
            (value) => !value ? i18n.t('errors.empty') : (value === this._dto.id) ? i18n.t('errors.equalId') : new RegExp(/^\w{8}.\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/).test(value) ? "" : i18n.t('errors.wrongId').replace("wrongId", value));
        this.antiDoble.validatorFuncAsync = this.validFn;

        this.tagsControl = new DefaultMultiSelectStore(this._dto.tags ?? [],
            (filters) => new PersonTagsApi(getApiConfiguration()).getPersonTags(filters),
        );
        this.specialities = new PersonSpecialityStore(this);
        this.jobTabs = new JobsTabsStore(this);
        this.jobControl = new JobsTableStore(this);
        const filters = getObject<PersonViewModelLocalFilters>(`person-${this.id}`);
        if (filters) {
            this._tabIndex = filters.tabIndex;
            this._expand = filters.expand;
        }

        if (this._expand) {
            this.updateState();
        }

        makeAutoObservable(this);
    }

    get expand() {
        return this._expand;
    }

    set expand(value: boolean) {
        this._expand = value;
        this.saveFilters();
        if (this.expand) {
            this.updateState();
        }
    }

    get tabIndex() {
        return this._tabIndex;
    }

    set tabIndex(value: number) {
        this._tabIndex = value;
        this.saveFilters();
        if (this._tabIndex === 0) {
            this.loadDobles();
        } else if (this._tabIndex === 1) {
            this.doubleStore.pull();
        } else if (this._tabIndex === 2) {
            this.specialities.pull();
        } else if (this._tabIndex === 3) {
            // this.jobs.pull();
            this.jobTabs.handleTabChange();
        } else if (this._tabIndex === 4) {
            this.notes.pull();
        } else if (this._tabIndex === 5) {
            this.personOthersStore.updateState();
        }
    }

    get modifiedAt() {
        if (this._dto.modifiedAt) {
            return toDateTimeString(this._dto.modifiedAt);
        }
        return '';
    }

    async saveTags() {
        this.processing = true;
        const api: AssignedPersonTagsApi = new AssignedPersonTagsApi(getApiConfiguration({successTooltip: false}));
        try {
            const res = await api.setAssignedPersonTags({
                personId: this.id,
                requestBody: this.tagsControl?.value?.map(x => x.id) ?? []
            });
            await this._store.updateState();
            const resId = await this._store.getPersonHuck(this.id, true);
            if (resId?._dto) this.update(resId?._dto);
            this.tagsControl.update(res.tags ?? []);
        } catch (e) {
        }
        this.processing = false;
    }

    get equals(): boolean {
        return this.id === this._dto.id &&
            this.pName?.equals &&
            this.pMiddleName.equals &&
            this.pSurname.equals &&
            this.pGender.equals &&
            this.personStatus.equals &&
            this.hasNoMiddleName === this._dto.hasNoMiddleName &&
            this.tagsControl.equals &&
            this.pMiddename.equals
    }

    get valid(): boolean {
        return this.pName.valid &&
            this.pMiddleName.valid &&
            this.pSurname.valid &&
            this.personStatus.valid &&
            this.pGender.valid &&
            this.pMiddename.valid
    }

    validate() {
        this.pName.validate();
        this.pMiddleName.validate();
        this.pSurname.validate();
        this.pGender.validate();
        this.personStatus.validate();
    }

    update(dto: PersonDto) {
        this._dto = dto;
        this.id = dto.id;
        this.pName.update(dto.firstName);
        this.pMiddleName.update(dto?.middleName ?? null);
        this.pSurname.update(dto.surname);
        this.pGender.update(dto.gender);
        this.personStatus.update(dto.personStatus ?? null);
        this.primarySpeciality.setValueWithoutEffects(dto.primarySpeciality?.id);
        this.primaryJob.setValueWithoutEffects(dto.primaryJob?.id);
        this.isDouble = dto.personStatus?.id === PersonStatusesNew.Double;
        this.isDead = dto.personStatus?.id === PersonStatusesNew.Dead;
        this.pMiddename.update(dto.maidenName)
        this.updateState()
    }

    get disable(): boolean {
        return this.isDead || this.isDouble;
    }

    async loadPerson(): Promise<void> {
        const result = await new PersonsApi().getPersonById({id: this.id});
        this.update(result)
    }

    reset(): void {
        this.pName.reset();
        this.pMiddleName.reset();
        this.pGender.reset();
        this.pSurname.reset();
        this.personStatus.reset();
        this.doble.reset();
        if (this.hasNoMiddleName !== this._dto.hasNoMiddleName) {
            this.changeHasNoMiddleName();
        }
        this.hasNoMiddleName = this._dto.hasNoMiddleName ?? false;
        this.tagsControl.reset()
        this.primaryJob.reset()
        this.pMiddename.reset();
        this.validate();
    }

    async validFn(value: string | null | undefined): Promise<string> {
        if (!value) return "";
        const api: PersonsApi = new PersonsApi(getApiConfiguration());
        const api_2: PersonDoblesApi = new PersonDoblesApi(getApiConfiguration());
        try {
            await api.getPersonById({ id: value });
            const result = await api_2.getPersonDobles({ searchType: 'doble', searchId: value });
            if (!!result.length) {
                return i18n.t('errors.hasDobleId').replace("hasDobleId", value);
            }
            return "";
        } catch (e) {
            return i18n.t('errors.wrongId').replace("wrongId", value);
        }
    }

    changeHasNoMiddleName() {
        this.hasNoMiddleName = !this.hasNoMiddleName;
        if (this.hasNoMiddleName) {
            this.pMiddleName = new DefaultSelectStore(null, (x) => new MiddleNamesApi(getApiConfiguration()).getMiddleNames(x));
        } else {
            this.pMiddleName = new DefaultSelectStore(this._dto?.middleName as MiddleNameDto || null,
                (x) => new MiddleNamesApi(getApiConfiguration()).getMiddleNames(x),
                (value) => (!value && !this.hasNoMiddleName) ? i18n.t('errors.empty') : "");
        }
        this.validate();
    }


    async loadDobles(): Promise<void> {
        const api: PersonDoblesApi = new PersonDoblesApi(getApiConfiguration());
        const result = await api.getPersonDobles({ searchType: 'doble', searchId: this._dto?.id });
        this.defaultDobleId = result[0]?.dobleId ?? '';
        // this.dobleId = result[0]?.id ?? '';
        this.doble = new DefaultValueStore<string>(this.defaultDobleId,
            (value) => !value ? i18n.t('errors.empty') : (value === this._dto.id) ? i18n.t('errors.equalId') : new RegExp(/^\w{8}.\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/).test(value) ? "" : i18n.t('errors.wrongId').replace("wrongId", value));
        this.doble.validatorFuncAsync = this.validFn;
    };

    async save(): Promise<void> {
        this.validate()
        this.processing = true;
        try {
            const res = await new PersonsApi(getApiConfiguration()).updatePerson({
                id: this.id,
                setPersonDto: {
                    firstNameId: this.pName.value?.id || '',
                    middleNameId: this.pMiddleName?.value?.id ?? null,
                    surnameId: this.pSurname.value?.id || '',
                    genderId: this.pGender.value?.id || '',
                    primaryJobId: this.primaryJob.value,
                    personStatusId: this.personStatus.value?.id,
                    primarySpecialityId: this.specialities.speciality.fields.primary.value?.id,
                    hasNoMiddleName: this.hasNoMiddleName,
                    maidenNameId: this.pMiddename.valueId
                }
            });
            const resId = await this._store.getPersonHuck(this.id, true);
            if (resId?._dto)
                this.update(resId?._dto);
            this.processing = false;
            if (!this.tagsControl.equals)
                await this.saveTags()
        } catch (e) {
            this.reset()
        } finally {
            this.processing = false;
        }
    };

    async updateTick(): Promise<void> {
        this.processing = true;
        const api: PersonsApi = new PersonsApi(getApiConfiguration());
        try {
            await api.tickPerson({id: this.id});
            await this._store.updateState()
        } catch {
        }
        this.processing = false;
    };


    async requestPerson() {
        this.update(await new PersonsApi().getPersonById({id: this.id}));
    }

    async delete(): Promise<void> {
        this.processing = true;
        const api: PersonsApi = new PersonsApi(getApiConfiguration());
        const result = await api.deletePerson({ id: this.id });
        this._store.items = this._store.items.filter(t => t.id !== result.id);
        for (const t of this._store.items) {
            if (t.id === result.id) {
                this._store.current = null;
            }
        }
        await this._store.pull();
        this.processing = false;
    };

    saveFilters() {
        setObject<PersonViewModelLocalFilters>(`person-${this.id}`, {
            tabIndex: this._tabIndex,
            expand: this._expand
        });
    }

    updateState() {
        if(this._store.current?.id == this.id || this.expand){
            if (this._tabIndex === 0) {
                this.loadDobles();
            } else if (this._tabIndex === 1) {
                this.doubleStore.pull();
            } else if (this._tabIndex === 2) {
                this.specialities.pull();
            } else if (this._tabIndex === 3) {
                this.jobTabs.handleTabChange();
            } else if (this._tabIndex === 4) {
                this.notes.pull();
            } else if (this._tabIndex === 5) {
                this.personOthersStore.updateState();
            }
            this.notes.countNotes(this.id).then(x => this.countNotes = x);
        }
    }
}




