/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateOrganizationDto
 */
export interface UpdateOrganizationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    statusId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    formPropertyId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    typeOrgId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    addressUniqueId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    parentId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    itn?: string | null;
}

export function UpdateOrganizationDtoFromJSON(json: any): UpdateOrganizationDto {
    return UpdateOrganizationDtoFromJSONTyped(json, false);
}

export function UpdateOrganizationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOrganizationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusId': json['statusId'],
        'formPropertyId': json['formPropertyId'],
        'typeOrgId': json['typeOrgId'],
        'addressUniqueId': json['addressUniqueId'],
        'parentId': json['parentId'],
        'itn': !exists(json, 'itn') ? undefined : json['itn'],
    };
}

export function UpdateOrganizationDtoToJSON(value?: UpdateOrganizationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'statusId': value.statusId,
        'formPropertyId': value.formPropertyId,
        'typeOrgId': value.typeOrgId,
        'addressUniqueId': value.addressUniqueId,
        'parentId': value.parentId,
        'itn': value.itn,
    };
}

