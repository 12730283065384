import { Box, Button, Card, Container, Typography } from "@mui/material";
import { observer } from 'mobx-react';
import { useTranslation } from "react-i18next";
import { Progress } from "../../components";
import { TabTitle } from "../../components/TabTitle";
import { DefaultSelect } from "../../components/shared/components/DefaultSelect";
import { useManagementStore } from "../../stores";


export const AdminPage = observer(() => {
    const { t } = useTranslation();
    const { adminStore } = useManagementStore();
    return (
        //TODO: check this
        // <AuthorizePermission permissions={AllPermissions.organization.read}>
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 3,
                    backgroundColor: 'background.default',
                }}
            >
                <Container maxWidth="xl">
                    <TabTitle title={t('pageNames.admin')} />

                    <Card sx={{ mb: 8 }} style={{ padding: 30, marginTop: 15 }}>
                        <div>
                            <Typography color="inherit"
                                style={{ paddingBottom: 15 }}
                                variant="h6">{t('admin.missionDcr')}
                            </Typography>
                            <Typography color="inherit"
                                style={{ paddingBottom: 15, color: "red" }}
                                variant="subtitle2">{t('admin.missingDcrError')}
                            </Typography>
                            <div style={{ display: "flex" }}>
                                <DefaultSelect store={adminStore.client}
                                    title={t('dcrPage.requestTable.client')}
                                    nameExp="name"
                                    valueExp="id"
                                    style={{ width: 300, marginTop: -7 }}
                                    testid="adminPage-client"
                                />
                                <Button
                                    onClick={async () => await adminStore.missingDcr(true)}
                                    size="small"
                                    variant={"text"}
                                    style={{
                                        minWidth: 'unset',
                                        backgroundColor: 'transparent',
                                        lineHeight: 'unset',
                                        marginLeft: 15
                                    }}
                                    disabled={adminStore.loading || !adminStore.client.valueId}
                                >{t('admin.downloadPerson')}
                                </Button>
                                <Button
                                    onClick={async () => await adminStore.missingDcr(false)}
                                    size="small"
                                    variant={"text"}
                                    style={{
                                        minWidth: 'unset',
                                        backgroundColor: 'transparent',
                                        lineHeight: 'unset',
                                    }}
                                    disabled={adminStore.loading || !adminStore.client.valueId}
                                >{t('admin.downloadOrganization')}
                                </Button>
                            </div>
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <Typography color="inherit"
                                style={{ paddingBottom: 15 }}
                                variant="h6">Презаписать хеш
                            </Typography>
                            <Button
                                onClick={async () => await adminStore.refreshHash()}
                                size="small"
                                type="button"
                                variant="outlined"
                                style={{
                                    minWidth: 'unset',
                                }}
                                disabled={adminStore.loading}
                            >Перезаписать
                            </Button>
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <Typography color="inherit"
                                style={{ paddingBottom: 15 }}
                                variant="h6">Проверить корректность запросов
                            </Typography>
                            <Button
                                onClick={async () => await adminStore.reloadCorrectRequests()}
                                size="small"
                                type="button"
                                variant="outlined"
                                style={{
                                    minWidth: 'unset',
                                }}
                                disabled={adminStore.loading}
                            >Проверить
                            </Button>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Typography color="inherit"
                                style={{ paddingBottom: 15 }}
                                variant="h6">{t('admin.exportRequests')}
                            </Typography>
                            <div style={{ display: "flex" }}>
                                <DefaultSelect store={adminStore.exportClient}
                                    title={t('dcrPage.requestTable.client')}
                                    nameExp="name"
                                    valueExp="id"
                                    style={{ width: 300, marginTop: -7 }}
                                    testid="adminPage-client"
                                />
                                <Button
                                    onClick={async () => await adminStore.exportAzRequests()}
                                    size="small"
                                    variant={"text"}
                                    style={{
                                        minWidth: 'unset',
                                        backgroundColor: 'transparent',
                                        lineHeight: 'unset',
                                        marginLeft: 15
                                    }}
                                    disabled={adminStore.loading || !adminStore.exportClient.valueId}
                                >{t('admin.downloadMinio')}
                                </Button>
                            </div>
                        </div>
                    </Card>
                </Container>
            </Box>
            <Progress store={adminStore} />
        </>

    );
});
