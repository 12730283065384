/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    BrickDto,
    BrickDtoFromJSON,
    BrickDtoFromJSONTyped,
    BrickDtoToJSON,
} from './BrickDto';
import {
    EntryTypeDto,
    EntryTypeDtoFromJSON,
    EntryTypeDtoFromJSONTyped,
    EntryTypeDtoToJSON,
} from './EntryTypeDto';
import {
    LocalityDto,
    LocalityDtoFromJSON,
    LocalityDtoFromJSONTyped,
    LocalityDtoToJSON,
} from './LocalityDto';
import {
    MdmRegionDto,
    MdmRegionDtoFromJSON,
    MdmRegionDtoFromJSONTyped,
    MdmRegionDtoToJSON,
} from './MdmRegionDto';
import {
    ZipDto,
    ZipDtoFromJSON,
    ZipDtoFromJSONTyped,
    ZipDtoToJSON,
} from './ZipDto';

/**
 * 
 * @export
 * @interface AddressUniqueDto
 */
export interface AddressUniqueDto {
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    externalId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AddressUniqueDto
     */
    createdAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AddressUniqueDto
     */
    modifiedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddressUniqueDto
     */
    ticks?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    localityId: string;
    /**
     * 
     * @type {LocalityDto}
     * @memberof AddressUniqueDto
     */
    locality: LocalityDto;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    zipId: string;
    /**
     * 
     * @type {ZipDto}
     * @memberof AddressUniqueDto
     */
    zip: ZipDto;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    okrugId?: string | null;
    /**
     * 
     * @type {MdmRegionDto}
     * @memberof AddressUniqueDto
     */
    okrug?: MdmRegionDto;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    districtId?: string | null;
    /**
     * 
     * @type {MdmRegionDto}
     * @memberof AddressUniqueDto
     */
    district?: MdmRegionDto;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    house: string;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    building: string;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    gpsAccuracy: string;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    longtitude: string;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    latitude: string;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    brickId?: string | null;
    /**
     * 
     * @type {BrickDto}
     * @memberof AddressUniqueDto
     */
    brick?: BrickDto;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    countryId?: string | null;
    /**
     * 
     * @type {LocalityDto}
     * @memberof AddressUniqueDto
     */
    country?: LocalityDto;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    areaId?: string | null;
    /**
     * 
     * @type {LocalityDto}
     * @memberof AddressUniqueDto
     */
    area?: LocalityDto;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    cityId?: string | null;
    /**
     * 
     * @type {LocalityDto}
     * @memberof AddressUniqueDto
     */
    city?: LocalityDto;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    streetId?: string | null;
    /**
     * 
     * @type {LocalityDto}
     * @memberof AddressUniqueDto
     */
    street?: LocalityDto;
    /**
     * 
     * @type {EntryTypeDto}
     * @memberof AddressUniqueDto
     */
    entryType?: EntryTypeDto;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    entryTypeId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddressUniqueDto
     */
    entryValue?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddressUniqueDto
     */
    fullAddress?: string | null;
}

export function AddressUniqueDtoFromJSON(json: any): AddressUniqueDto {
    return AddressUniqueDtoFromJSONTyped(json, false);
}

export function AddressUniqueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressUniqueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (json['modifiedAt'] === null ? null : new Date(json['modifiedAt'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'ticks': !exists(json, 'ticks') ? undefined : json['ticks'],
        'localityId': json['localityId'],
        'locality': LocalityDtoFromJSON(json['locality']),
        'zipId': json['zipId'],
        'zip': ZipDtoFromJSON(json['zip']),
        'okrugId': !exists(json, 'okrugId') ? undefined : json['okrugId'],
        'okrug': !exists(json, 'okrug') ? undefined : MdmRegionDtoFromJSON(json['okrug']),
        'districtId': !exists(json, 'districtId') ? undefined : json['districtId'],
        'district': !exists(json, 'district') ? undefined : MdmRegionDtoFromJSON(json['district']),
        'house': json['house'],
        'building': json['building'],
        'gpsAccuracy': json['gpsAccuracy'],
        'longtitude': json['longtitude'],
        'latitude': json['latitude'],
        'brickId': !exists(json, 'brickId') ? undefined : json['brickId'],
        'brick': !exists(json, 'brick') ? undefined : BrickDtoFromJSON(json['brick']),
        'countryId': !exists(json, 'countryId') ? undefined : json['countryId'],
        'country': !exists(json, 'country') ? undefined : LocalityDtoFromJSON(json['country']),
        'areaId': !exists(json, 'areaId') ? undefined : json['areaId'],
        'area': !exists(json, 'area') ? undefined : LocalityDtoFromJSON(json['area']),
        'cityId': !exists(json, 'cityId') ? undefined : json['cityId'],
        'city': !exists(json, 'city') ? undefined : LocalityDtoFromJSON(json['city']),
        'streetId': !exists(json, 'streetId') ? undefined : json['streetId'],
        'street': !exists(json, 'street') ? undefined : LocalityDtoFromJSON(json['street']),
        'entryType': !exists(json, 'entryType') ? undefined : EntryTypeDtoFromJSON(json['entryType']),
        'entryTypeId': !exists(json, 'entryTypeId') ? undefined : json['entryTypeId'],
        'entryValue': !exists(json, 'entryValue') ? undefined : json['entryValue'],
        'fullAddress': !exists(json, 'fullAddress') ? undefined : json['fullAddress'],
    };
}

export function AddressUniqueDtoToJSON(value?: AddressUniqueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalId': value.externalId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt === null ? null : value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt === null ? null : value.modifiedAt.toISOString()),
        'modifiedBy': value.modifiedBy,
        'ticks': value.ticks,
        'localityId': value.localityId,
        'locality': LocalityDtoToJSON(value.locality),
        'zipId': value.zipId,
        'zip': ZipDtoToJSON(value.zip),
        'okrugId': value.okrugId,
        'okrug': MdmRegionDtoToJSON(value.okrug),
        'districtId': value.districtId,
        'district': MdmRegionDtoToJSON(value.district),
        'house': value.house,
        'building': value.building,
        'gpsAccuracy': value.gpsAccuracy,
        'longtitude': value.longtitude,
        'latitude': value.latitude,
        'brickId': value.brickId,
        'brick': BrickDtoToJSON(value.brick),
        'countryId': value.countryId,
        'country': LocalityDtoToJSON(value.country),
        'areaId': value.areaId,
        'area': LocalityDtoToJSON(value.area),
        'cityId': value.cityId,
        'city': LocalityDtoToJSON(value.city),
        'streetId': value.streetId,
        'street': LocalityDtoToJSON(value.street),
        'entryType': EntryTypeDtoToJSON(value.entryType),
        'entryTypeId': value.entryTypeId,
        'entryValue': value.entryValue,
        'fullAddress': value.fullAddress,
    };
}

