/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 *
 * @export
 * @interface JurisdictionDto
 */
export interface JurisdictionDto {
    /**
     *
     * @type {string}
     * @memberof JurisdictionDto
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof JurisdictionDto
     */
    nameRus: string;
    /**
     *
     * @type {string}
     * @memberof JurisdictionDto
     */
    nameEng: string;
    /**
     *
     * @type {string}
     * @memberof JurisdictionDto
     */
    localityId?: string | null;
    /**
     *
     * @type {string}
     * @memberof JurisdictionDto
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof JurisdictionDto
     */
    phoneNumberRegex?: string | null;
    /**
     *
     * @type {string}
     * @memberof JurisdictionDto
     */
    itnRegex?: string | null;
}

export function JurisdictionDtoFromJSON(json: any): JurisdictionDto {
    return JurisdictionDtoFromJSONTyped(json, false);
}

export function JurisdictionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JurisdictionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'id': json['id'],
        'nameRus': json['nameRus'],
        'nameEng': json['nameEng'],
        'localityId': !exists(json, 'localityId') ? undefined : json['localityId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phoneNumberRegex': !exists(json, 'phoneNumberRegex') ? undefined : json['phoneNumberRegex'],
        'itnRegex': !exists(json, 'itnRegex') ? undefined : json['itnRegex'],
    };
}

export function JurisdictionDtoToJSON(value?: JurisdictionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'id': value.id,
        'nameRus': value.nameRus,
        'nameEng': value.nameEng,
        'localityId': value.localityId,
        'name': value.name,
        'phoneNumberRegex': value.phoneNumberRegex,
        'itnRegex': value.itnRegex,
    };
}

