/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Api.Export
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 * 
 * @export
 * @interface JwtBound
 */
export interface JwtBound {
    /**
     * 
     * @type {Array<string>}
     * @memberof JwtBound
     */
    boundClinicCategory?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof JwtBound
     */
    boundFormProperty?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof JwtBound
     */
    boundLocality?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof JwtBound
     */
    boundOrganization?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof JwtBound
     */
    boundPost?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof JwtBound
     */
    boundSpeciality?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof JwtBound
     */
    boundOrganizationStatus?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof JwtBound
     */
    boundPersonStatus?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof JwtBound
     */
    boundTypeOrg?: Array<string> | null;
}

export function JwtBoundFromJSON(json: any): JwtBound {
    return JwtBoundFromJSONTyped(json, false);
}

export function JwtBoundFromJSONTyped(json: any, ignoreDiscriminator: boolean): JwtBound {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'boundClinicCategory': !exists(json, 'boundClinicCategory') ? undefined : json['boundClinicCategory'],
        'boundFormProperty': !exists(json, 'boundFormProperty') ? undefined : json['boundFormProperty'],
        'boundLocality': !exists(json, 'boundLocality') ? undefined : json['boundLocality'],
        'boundOrganization': !exists(json, 'boundOrganization') ? undefined : json['boundOrganization'],
        'boundPost': !exists(json, 'boundPost') ? undefined : json['boundPost'],
        'boundSpeciality': !exists(json, 'boundSpeciality') ? undefined : json['boundSpeciality'],
        'boundOrganizationStatus': !exists(json, 'boundOrganizationStatus') ? undefined : json['boundOrganizationStatus'],
        'boundPersonStatus': !exists(json, 'boundPersonStatus') ? undefined : json['boundPersonStatus'],
        'boundTypeOrg': !exists(json, 'boundTypeOrg') ? undefined : json['boundTypeOrg'],
    };
}

export function JwtBoundToJSON(value?: JwtBound | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'boundClinicCategory': value.boundClinicCategory,
        'boundFormProperty': value.boundFormProperty,
        'boundLocality': value.boundLocality,
        'boundOrganization': value.boundOrganization,
        'boundPost': value.boundPost,
        'boundSpeciality': value.boundSpeciality,
        'boundOrganizationStatus': value.boundOrganizationStatus,
        'boundPersonStatus': value.boundPersonStatus,
        'boundTypeOrg': value.boundTypeOrg,
    };
}

