/* tslint:disable */
/* eslint-disable */
/**
 * Data360.DCR.Analytics
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DcrStatus = {
    Create: 'Create',
    Update: 'Update'
} as const;
export type DcrStatus = typeof DcrStatus[keyof typeof DcrStatus];


export function DcrStatusFromJSON(json: any): DcrStatus {
    return DcrStatusFromJSONTyped(json, false);
}

export function DcrStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): DcrStatus {
    return json as DcrStatus;
}

export function DcrStatusToJSON(value?: DcrStatus | null): any {
    return value as any;
}

