import {Box, Card, Table, TableBody, TableHead, TableRow, Typography} from "@mui/material";
import {observer} from "mobx-react";
import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import {
    AddButton,
    AuthorizePermission,
    Cell,
    ConfirmationForm,
    EmptyBlock4,
    ExpandButton,
    ExpandRow,
    ExpertDialog,
    HCell,
    Progress
} from "../../../../components";
import {DeleteIconButton} from "../../../../components/IconButtons";
import {AllPermissions} from "../../../../core/const";
import {Scrollbar} from "../../../../scrollbar";
import {PersonPhoneItemStore} from "../../../../stores/management/persons/tabs/others/phones/PersonPhoneItemStore";
import {PersonPhoneTableStore} from "../../../../stores/management/persons/tabs/others/phones/PersonPhoneTableStore";
import {PersonPhoneCreator} from "./PersonPhoneCreator";
import {toDateTimeString} from "../../../../core";
import {PersonPhoneDetails} from "./PersonPhoneDetails";
import {DefaultMultiSelect} from "../../../../components/shared/components/DefaultMultiSelect";
import {DefaultValue} from "../../../../components/shared/components/DefaultValue";

interface Props {
    store: PersonPhoneTableStore;
    style?: React.CSSProperties
}

export const PersonPhoneTable = observer(({store, style}: Props) => {
    const {t} = useTranslation();

    return (
        <>
            <ExpertDialog
                title={t('create.persPhone')}
                open={store.creator.Open}
                onClose={() => store.creator.Open = false}
            >
                <PersonPhoneCreator store={store.creator}/>
            </ExpertDialog>

            {
                store.current
                    ? (
                        <ExpertDialog
                            title={t('common.delete')}
                            open={store.current.deleteDialogState}
                            onClose={() => store.current ? store.current.deleteDialogState = false : false}
                        >
                            <ConfirmationForm
                                disabled={store.current.processing}
                                onSubmit={async () => await store.current?.delete()}
                                onClose={() => store.current ? store.current.deleteDialogState = false : false}
                            >
                                {t('common.deleteConfirm')} "{store.current.dto.phone.phoneNumber}"?
                            </ConfirmationForm>
                        </ExpertDialog>
                    )
                    : ''
            }
            <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <>
                    <Box sx={{ml: 'auto', mb: '10px'}}>
                        <AuthorizePermission permissions={AllPermissions.person.phones.create}>
                            <AddButton onClick={() => store.creator.Open = true} disabled={store.isDisable}/>
                        </AuthorizePermission>
                    </Box>
                    <Box
                        sx={{mb: 1}}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: 0,
                        }}
                    >
                        <DefaultMultiSelect
                            store={store.statusFilter}
                            title={t('common.status')}
                            nameExp={store.statusFilter.nameExp}
                            valueExp={store.statusFilter.valueExp}
                            style={{width: '33%'}}
                        />
                        <DefaultMultiSelect
                            store={store.categoryFilter}
                            title={t('common.category')}
                            nameExp={store.categoryFilter.nameExp}
                            valueExp={store.categoryFilter.valueExp}
                            style={{width: '33%'}}
                        />
                        <DefaultValue
                            store={store.description}
                            title={t('common.note')}
                            style={{width: '33%', marginTop: '16px'}}
                        />
                    </Box>
                </>
                {
                    store.items.length > 0 ?
                        <Card sx={{mb: 8, overflow: 'unset'}}>
                            <Scrollbar>
                                <Table sx={{minWidth: 800, mt: 3}}>
                                    <TableHead>
                                        <TableRow>
                                            <HCell width='5%'></HCell>
                                            <HCell store={store} orderBy="phoneNumber">{t('common.phone')}</HCell>
                                            <HCell store={store} orderBy="status">{t('common.status')}</HCell>
                                            <HCell store={store} orderBy="type">{t('common.type')}</HCell>
                                            <HCell store={store} orderBy="category">{t('common.category')}</HCell>
                                            <HCell store={store} orderBy="description">{t('common.note')}</HCell>
                                            <HCell store={store} orderBy="createdAt">{t('common.createDate')}</HCell>
                                            <HCell store={store} orderBy="modifiedAt">{t('common.modifiedDate')}</HCell>
                                            {
                                                !store.isDisable && <HCell width='5%'></HCell>
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {store.items.map((t: PersonPhoneItemStore) => {
                                            return (
                                                <Fragment key={t.dto.id}>
                                                    <TableRow
                                                        hover
                                                        key={t.dto.id}
                                                        style={{width: '100%'}}
                                                        onClick={() => store.current = t}
                                                    >
                                                        <ExpandButton item={t} isOpen={t.expand}/>
                                                        <Cell>{t.dto.phone.phoneNumber}
                                                        </Cell>
                                                        <Cell testid="personPhoneTable-phoneStatus">
                                                            {t.dto.phoneStatus.nameRus}
                                                        </Cell>
                                                        <Cell testid="personPhoneTable-phoneType">
                                                            {t.dto.phoneType.nameRus}
                                                        </Cell>
                                                        <Cell testid="personPhoneTable-phoneCategory">
                                                            {t.dto.phoneCategory.nameRus}
                                                        </Cell>
                                                        <Cell>
                                                            <Typography variant="subtitle2" color="textSecondary">
                                                                {t.dto.description}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell>
                                                            <Typography variant="subtitle2" color="textSecondary">
                                                                {toDateTimeString(t.dto.createdAt)}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell>
                                                            <Typography variant="subtitle2" color="textSecondary">
                                                                {toDateTimeString(t.dto.modifiedAt)}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell align={'right'} style={{height: 30}}>
                                                            {
                                                                !store.isDisable && <AuthorizePermission
                                                                    permissions={AllPermissions.organization.phones.delete}>
                                                                    <DeleteIconButton
                                                                        onClick={() => t.deleteDialogState = true}
                                                                        testid='personPhoneTable'/>
                                                                </AuthorizePermission>
                                                            }

                                                        </Cell>
                                                    </TableRow>
                                                    {t.expand && (
                                                        <ExpandRow key={`${t.dto.id}_`} item={t}>
                                                            <PersonPhoneDetails
                                                                store={t}
                                                            />
                                                        </ExpandRow>)}
                                                </Fragment>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </Scrollbar>
                        </Card>
                        : <EmptyBlock4/>
                }
            </Box>
            <Progress store={store}/>
        </>
    );
});

