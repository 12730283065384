import {observer} from "mobx-react";
import {Box, CircularProgress, IconButton, List, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {ResponseDetailsStore} from "../../../stores/management/dcr/responses/ResponseDetailsStore";
import {DCRStatusEnum} from "../../../stores/management/dcr/types";
import {X as XIcon} from "../../../icons/x";
import {DcrListItem} from "../DcrListItem";

interface Props {
    store: ResponseDetailsStore;
    style?: React.CSSProperties;
    onClose?: () => void;
    isOpenInDrawer: boolean;
}

export const ResponseDetails = observer(({store, style, onClose, isOpenInDrawer}: Props) => {
    const {t} = useTranslation();
    return (
        <Box>
            {isOpenInDrawer && <Box
                sx={{
                    alignItems: 'center',
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                    display: 'flex',
                    justifyContent: 'space-between',
                    px: 3,
                    py: 2
                }}
            >
                <Typography
                    color="inherit"
                    variant="h6"
                >
                    {store.dto.requestId}
                </Typography>
                <IconButton
                    color="inherit"
                    onClick={onClose}
                >
                    <XIcon fontSize="small"/>
                </IconButton>
            </Box>
            }
            <Box sx={{mx: 3, ml: 5}}>
                <List style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 15,
                    flexWrap: "wrap",
                    alignItems: 'flex-start'
                }}>
                    <DcrListItem name={t('dcrPage.status')} value={store._store.requestStatusName}/>
                    <DcrListItem name={t('dcrPage.dcrStatus')}
                                 value={t(`dcrPage.${store.dto.dcrStatus === DCRStatusEnum.Create ? 'create' : 'update'}`)}/>

                    {
                        !store.loading ?

                            store.rowValues.map(row =>
                                <> {row.map(x =>
                                    <DcrListItem name={x.name}
                                                 value={x.value}
                                                 width={'45%'}
                                    />
                                )
                                }
                                </>
                            ) : <CircularProgress size={20} style={{marginTop: 15}}/>
                    }

                    {
                        store.dto.comment &&
                        <DcrListItem name={t('common.comment')}
                                     value={store.dto.comment}/>
                    }
                </List>
            </Box>
        </Box>
    );
});
