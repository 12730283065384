/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetOrganizationNoteDto
 */
export interface SetOrganizationNoteDto {
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationNoteDto
     */
    organizationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationNoteDto
     */
    note?: string | null;
}

export function SetOrganizationNoteDtoFromJSON(json: any): SetOrganizationNoteDto {
    return SetOrganizationNoteDtoFromJSONTyped(json, false);
}

export function SetOrganizationNoteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetOrganizationNoteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function SetOrganizationNoteDtoToJSON(value?: SetOrganizationNoteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationId': value.organizationId,
        'note': value.note,
    };
}

