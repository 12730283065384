/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    PersonNoteDto,
    PersonNoteDtoFromJSON,
    PersonNoteDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetPersonNoteDto,
    SetPersonNoteDtoFromJSON,
    SetPersonNoteDtoToJSON,
} from '../models';

export interface CreatePersonNoteRequest {
    setPersonNoteDto: SetPersonNoteDto;
}

export interface DeletePersonNoteRequest {
    id: string;
}

export interface GetPersonNoteByIdRequest {
    id: string;
}

export interface GetPersonNotesRequest {
    personId?: string;
    createdByUserId?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetPersonNotesCountRequest {
    personId?: string;
    createdByUserId?: string;
}

export interface UpdatePersonNoteRequest {
    id: string;
    setPersonNoteDto: SetPersonNoteDto;
}

/**
 * 
 */
export class PersonNotesApi extends runtime.BaseAPI {

    /**
     */
    async createPersonNoteRaw(requestParameters: CreatePersonNoteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonNoteDto>> {
        if (requestParameters.setPersonNoteDto === null || requestParameters.setPersonNoteDto === undefined) {
            throw new runtime.RequiredError('setPersonNoteDto','Required parameter requestParameters.setPersonNoteDto was null or undefined when calling createPersonNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/Persons/notes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPersonNoteDtoToJSON(requestParameters.setPersonNoteDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonNoteDtoFromJSON(jsonValue));
    }

    /**
     */
    async createPersonNote(requestParameters: CreatePersonNoteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonNoteDto> {
        const response = await this.createPersonNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePersonNoteRaw(requestParameters: DeletePersonNoteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonNoteDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePersonNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/Persons/notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonNoteDtoFromJSON(jsonValue));
    }

    /**
     */
    async deletePersonNote(requestParameters: DeletePersonNoteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonNoteDto> {
        const response = await this.deletePersonNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonNoteByIdRaw(requestParameters: GetPersonNoteByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonNoteDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPersonNoteById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/Persons/notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonNoteDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonNoteById(requestParameters: GetPersonNoteByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonNoteDto> {
        const response = await this.getPersonNoteByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonNotesRaw(requestParameters: GetPersonNotesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonNoteDto>>> {
        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        if (requestParameters.createdByUserId !== undefined) {
            queryParameters['createdByUserId'] = requestParameters.createdByUserId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/Persons/notes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonNoteDtoFromJSON));
    }

    /**
     */
    async getPersonNotes(requestParameters: GetPersonNotesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonNoteDto>> {
        const response = await this.getPersonNotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonNotesCountRaw(requestParameters: GetPersonNotesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        if (requestParameters.createdByUserId !== undefined) {
            queryParameters['createdByUserId'] = requestParameters.createdByUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/Persons/notes/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonNotesCount(requestParameters: GetPersonNotesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPersonNotesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePersonNoteRaw(requestParameters: UpdatePersonNoteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonNoteDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePersonNote.');
        }

        if (requestParameters.setPersonNoteDto === null || requestParameters.setPersonNoteDto === undefined) {
            throw new runtime.RequiredError('setPersonNoteDto','Required parameter requestParameters.setPersonNoteDto was null or undefined when calling updatePersonNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/Persons/notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetPersonNoteDtoToJSON(requestParameters.setPersonNoteDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonNoteDtoFromJSON(jsonValue));
    }

    /**
     */
    async updatePersonNote(requestParameters: UpdatePersonNoteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonNoteDto> {
        const response = await this.updatePersonNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
