/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    RolePermissionDto,
    RolePermissionDtoFromJSON,
    RolePermissionDtoToJSON,
    SetRolePermissionDto,
    SetRolePermissionDtoFromJSON,
    SetRolePermissionDtoToJSON,
} from '../models';

export interface CreateRolePermissionsRequest {
    roleId: string;
    setRolePermissionDto: Array<SetRolePermissionDto>;
}

export interface GetRolePermissionsRequest {
    roleId: string;
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetRolePermissionsCountRequest {
    roleId: string;
    search?: string;
}

export interface RemoveRolePermissionsRequest {
    roleId: string;
    setRolePermissionDto: Array<SetRolePermissionDto>;
}

/**
 * 
 */
export class RolePermissionsApi extends runtime.BaseAPI {

    /**
     */
    async createRolePermissionsRaw(requestParameters: CreateRolePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<RolePermissionDto>>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling createRolePermissions.');
        }

        if (requestParameters.setRolePermissionDto === null || requestParameters.setRolePermissionDto === undefined) {
            throw new runtime.RequiredError('setRolePermissionDto','Required parameter requestParameters.setRolePermissionDto was null or undefined when calling createRolePermissions.');
        }

        const queryParameters: any = {};

        if (requestParameters.roleId !== undefined) {
            queryParameters['roleId'] = requestParameters.roleId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/v1/roles/permissions/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setRolePermissionDto.map(SetRolePermissionDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RolePermissionDtoFromJSON));
    }

    /**
     */
    async createRolePermissions(requestParameters: CreateRolePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<RolePermissionDto>> {
        const response = await this.createRolePermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRolePermissionsRaw(requestParameters: GetRolePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<RolePermissionDto>>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling getRolePermissions.');
        }

        const queryParameters: any = {};

        if (requestParameters.roleId !== undefined) {
            queryParameters['roleId'] = requestParameters.roleId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/roles/permissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RolePermissionDtoFromJSON));
    }

    /**
     */
    async getRolePermissions(requestParameters: GetRolePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<RolePermissionDto>> {
        const response = await this.getRolePermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRolePermissionsCountRaw(requestParameters: GetRolePermissionsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling getRolePermissionsCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.roleId !== undefined) {
            queryParameters['roleId'] = requestParameters.roleId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/roles/permissions/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getRolePermissionsCount(requestParameters: GetRolePermissionsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getRolePermissionsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeRolePermissionsRaw(requestParameters: RemoveRolePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<RolePermissionDto>>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling removeRolePermissions.');
        }

        if (requestParameters.setRolePermissionDto === null || requestParameters.setRolePermissionDto === undefined) {
            throw new runtime.RequiredError('setRolePermissionDto','Required parameter requestParameters.setRolePermissionDto was null or undefined when calling removeRolePermissions.');
        }

        const queryParameters: any = {};

        if (requestParameters.roleId !== undefined) {
            queryParameters['roleId'] = requestParameters.roleId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/v1/roles/permissions/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setRolePermissionDto.map(SetRolePermissionDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RolePermissionDtoFromJSON));
    }

    /**
     */
    async removeRolePermissions(requestParameters: RemoveRolePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<RolePermissionDto>> {
        const response = await this.removeRolePermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
