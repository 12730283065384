import {observer} from "mobx-react";
import React from "react";
import {DefaultValueStore} from "./DefaultValueStore";
import {Box, TextField} from "@mui/material";
// import {Dayjs} from 'dayjs';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";

interface Props {
    store: DefaultValueStore<Date>;
    style?: React.CSSProperties;
    disabled?: boolean;
    title: string;
    testid?: string;
}

export const DefaultDate = observer(({store, style, disabled, title, testid}: Props) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                data-testid={`${testid}-datePicker`}
                label={title}
                value={store.value}
                onChange={(newValue: any) => store.value = newValue}
                inputFormat="dd/MM/yyyy"
                renderInput={(inputProps) =>
                    <TextField
                        {...inputProps}
                        error={Boolean(store.error)}
                        helperText={store.error}
                        inputProps={
                            {
                                ...inputProps.inputProps,
                                placeholder: "дд/мм/гггг"
                            }
                        }
                        size="small"
                        margin='dense'
                        style={{...{marginTop: 'unset'}, ...style}}
                        sx={
                            {
                                fieldset: {
                                    height: 48,
                                }
                            }
                        }
                    />
            }
            />
        </LocalizationProvider>
    );
});
