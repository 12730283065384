import {observer} from "mobx-react";
import {Page, PageTab, PageTabs} from "../../../../components";
import {useTranslation} from "react-i18next";
import {ParametersTab} from "./tabs/ParametersTab";
import {RolesTab} from "./tabs/RolesTab";
import {PersonParams} from "../../../../icons/personParams";
import {PersonJob} from "../../../../icons/personJob";
import {UserItemStore} from "../../../../stores/management/users/tabs/Users/UserItemStore";
import {StoreProps, StyleProps} from "../../../../stores";
import {JurisdictionsTab} from "./tabs/JurisdictionsTab";
import {OfficeBuilding} from "../../../../icons/office-building";

export const UserDetails = observer(({store, style}: StoreProps<UserItemStore> & StyleProps) => {
    const {t} = useTranslation();

    const renderTabs = (index: number) => {
        switch (index) {
            case 0:
                return <ParametersTab store={store} style={{flex: 1}}/>;
            case 1:
                return <RolesTab store={store.roles} style={{flex: 1}}/>;
            case 2:
                return <JurisdictionsTab store={store.jurisdictions} style={{flex: 1}}/>;
            default:
                return null;
        }
    };

    return (
        <Page
            style={style}
        >
            <PageTabs
                index={store.tabIndex}
                onChange={t => store.tabIndex = t}>
                <PageTab icon={<PersonParams/>} title={t('usersPage.tabs.params')} />
                <PageTab icon={<PersonJob/>} title={t('usersPage.tabs.roles')} />
                <PageTab icon={<OfficeBuilding/>} title={t('usersPage.tabs.jurisdictions')}/>
            </PageTabs>
            <div style={{marginTop: 4, marginBottom: 4, padding: 20}}>
                {renderTabs(store.tabIndex)}
            </div>
        </Page>
    );
});
