import {PersonStore} from "../specifications/entity/PersonStore";
import {FieldInfoDto} from "../../../../../services/dcr";
import {DoubleEnum, RequestField} from "../../types";
import {GendersApi, PersonDto, PersonsApi, SpecialitiesApi} from "../../../../../services/management";
import {getApiConfiguration} from "../../../../../core";
import {t} from "i18next";
import {DefaultSelectStore} from "../../../../../components/shared/DefaultSelectStore";
import {getPersonFullNameHandler} from "../../../../utils";
import {PersonStatusesNew} from "../../../../../core/const";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";
import {BaseFieldBuilder} from "./BaseFieldBuilder";
import {AzSpecification} from "../specifications/clients/AzSpecification";

export class SpecializationBuilder extends BaseFieldBuilder<PersonStore> {
    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
        const api = new SpecialitiesApi(getApiConfiguration());
        let res = null;

        if(element.value){
            const valueId = this.external ? parseInt(element.value!) : element.value;
            if(!valueId)
                throw Error()
            res = await this.getById({
                id: valueId,
                external: this.external,
                getInternal: (x) => api.getSpecialityById({
                    id: x as string,
                }),
                getExternal: (x) => api.getSpecialities({
                    searchByExternalId: x as number,
                    showOnlyActive: true
                }),
            })
        }

        field.store = new DefaultSelectStore(res,
            (filters) => new SpecialitiesApi(getApiConfiguration()).getSpecialities({
                ...filters,
                showOnlyActive: true
            }));
        field.store.required = true;
    }
}

export class SexBuilder extends BaseFieldBuilder<PersonStore> {
    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
        const api = new GendersApi(getApiConfiguration());
        let res = await this.getById({
            id: element.value,
            external: false,
            getInternal: (x) => x == "male" || x == "female" ?
                api.getGenderByName({name: x}) :
                api.getGenderById({id: x!})
        })
        field.store = new DefaultSelectStore(res, (filters) => new GendersApi(getApiConfiguration()).getGenders(filters));
        field.isAdditional = false;
        field.store.required = true;
    }
}

export class NameBuilder extends BaseFieldBuilder<PersonStore> {
    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
        field.store.setValidator((e: any) => {
            return e.length > 1 ? "" : t("errors.length").replace("length", "2")
        })
        field.store.required = true;
    }
}

export class MiddenBuilder extends BaseFieldBuilder<PersonStore> {
    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
        field.store.required = false;
    }
}

export class OriginalIdBuilder extends BaseFieldBuilder<PersonStore> {
    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
        const api = new PersonsApi(getApiConfiguration());
        let res = await this.getById({
            id: element.value,
            external: false,
            getInternal: (x) => api.getPersonById({id: x}),
        });
        field.link = element.value ? `/persons/${element.value}` : ''
        field.isHide = !element.value;
        field.store = new DefaultSelectStore<PersonDto>(res, (filters) => api.getPersons({...filters}));
        field.store.nameExp = (dto) => getPersonFullNameHandler(dto);
        field.store.onChanged.push((x) => this.store._store.fields.find(x => x.field == 'originalId')!.link = !!x ? `/persons/${x.id}` : '')
        field.store.setValidator((e: PersonDto) => e?.personStatus?.id == PersonStatusesNew.Double ? t("dcrPage.errors.personIsDouble").replace("{id}", e.id) : "");
        field.store.isSearchById = true;
        field.store.required = true;
        if(this.store._store.isAz){
            field.store.onChanged.push((x) => (this.store._store.specification as AzSpecification).handleAzDoubleValComment())
        }
    }
}

export class CheckboxBuilder extends BaseFieldBuilder<PersonStore> {
    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
        field.store = new DefaultValueStore(element.value == DoubleEnum.Double);
        if(element.field == "withoutMiddlename")
            field.store.onChanged.push(async (x) => {
                const field = this.store._store.fields.find(x => x.field == 'middlename');
                if(!!x){
                    field!.readonly = true;
                    field!.store.setValidator(null);
                    field!.store.required = false;
                    field!.store.value = '';
                }
                else {
                    field!.readonly = false;
                    field!.store.required = true;
                }
                await field!.store.validate()
            })
        field.store.getReadValue = (e) => t(!!e ? "common.yes" : "common.no")
        field.store.required = false;
        field.store.type = "checkbox"
    }
}
