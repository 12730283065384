import {observer} from 'mobx-react';
import React from "react";
import {useTranslation} from 'react-i18next';
import {FormRoot} from "../../../../components";
import {DefaultSelect} from '../../../../components/shared/components/DefaultSelect';
import {SpecialityDto} from '../../../../services/management';
import {DefaultMultiSelect} from "../../../../components/shared/components/DefaultMultiSelect";
import {DefaultFormStore} from "../../../../components/shared/DefaultFormStore";
import {DefaultMultiSelectStore} from "../../../../components/shared/DefaultMultiSelectStore";
import {DefaultSelectStore} from "../../../../components/shared/DefaultSelectStore";

interface Props {
    store: DefaultFormStore<{
        specialities: DefaultMultiSelectStore<SpecialityDto>,
        primary: DefaultSelectStore<SpecialityDto>,
    }>;
}

export const SpecialityCreator = observer(({store}: Props) => {
    const {t} = useTranslation();

    return (
        <FormRoot>
            <DefaultMultiSelect
                autoFocus
                store={store.fields.specialities}
                tags
                title={t('common.specialities')}/>
            <DefaultSelect store={store.fields.primary}
                           hasLabel
                           title={t('common.primarySpeciality')}/>
        </FormRoot>
    );
});
