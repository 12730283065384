import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {EmptyData} from "../icons/plus";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import {useLocation, useNavigate} from "react-router-dom";
import {useManagementStore, useRootStore} from "../stores";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}


export const NotFound = observer(() => {
    const {t} = useTranslation();
    const theme = useTheme();
    let query = useQuery();
    const route = query.get("route")
    const navigate = useNavigate();
    const {authorizationStore} = useRootStore();
    const [first, setFirst] = useState(true)

    useEffect(() => {
        if (!first && authorizationStore.jurisdictions.value?.jurisdictionId)
            navigate("/" + route)
        else if (first && authorizationStore.jurisdictions.value?.jurisdictionId) {
            setFirst(false)
        }
    }, [authorizationStore.jurisdictions.value?.jurisdictionId])

    return <Box>
        <div style={{
            height: 250,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
        }}>
            <EmptyData sx={{stroke: theme.palette.text.secondary,fill:theme.palette.text.secondary, height: 200, width: 200}}></EmptyData>
            <Typography
                style={{marginTop: -30}}
                color="text.secondary"
                component="h4"
                variant="inherit"
            >
                {t("errors.notFound")}
            </Typography>
        </div>
    </Box>
});
