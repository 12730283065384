import {makeObservable, observable} from 'mobx';
import {OrganizationItemStore} from '../../../../index';
import {getApiConfiguration, getObject, setObject} from '../../../../../../core';
import {
    OrganizationDto,
    OrganizationPhoneCategoriesApi,
    OrganizationPhonesApi,
    OrganizationPhoneStatusesApi,
    OrganizationPhoneTypesApi,
    PhoneCategoryDto,
    PhoneStatusDto,
    PhoneTypeDto,
} from '../../../../../../services/management';
import {PagedItems, TableLocalFilters, TableStore} from '../../../../../utils';
import {OrganizationPhoneItemStore} from "./OrganizationPhoneItemStore";
import {DefaultSelectStore} from "../../../../../../components/shared/DefaultSelectStore";
import {DefaultValueStore} from "../../../../../../components/shared/DefaultValueStore";
import i18n from "i18next";
import {DefaultFormModalStore} from "../../../../../../components/shared/DefaultFormModalStore";
import {JurisdictionLocalSetting} from "../../../../../authorization/AuthorizationStore";
import {DefaultMultiSelectStore} from "../../../../../../components/shared/DefaultMultiSelectStore";

export class OrganizationPhoneTableStore extends TableStore<OrganizationPhoneItemStore> {
    _orgStore: OrganizationItemStore
    _dto: OrganizationDto
    organizationId: string

    current: OrganizationPhoneItemStore | undefined | null;
    readonly creator: DefaultFormModalStore<{
        phone: DefaultValueStore<string>,
        type: DefaultSelectStore<PhoneTypeDto>,
        status: DefaultSelectStore<PhoneStatusDto>,
        category: DefaultSelectStore<PhoneCategoryDto>,
        additionalNumber: DefaultValueStore<string>,
        description: DefaultValueStore<string>,
    }> = new DefaultFormModalStore({
        phone: new DefaultValueStore<string>("", (value) =>
            new RegExp(this.jurisdictionLocalSetting?.phoneNumberRegex!)
                .test(value ?? "") ? "" : i18n.t('dcrPage.errors.phoneFormat').replace("{length}", this.jurisdictionLocalSetting?.numberLength!)),
        type: new DefaultSelectStore<PhoneTypeDto>(
            null, (x) => new OrganizationPhoneTypesApi(getApiConfiguration()).getOrganizationPhoneTypes(x),
            (value) => !value ? i18n.t('errors.empty') : ""),
        status: new DefaultSelectStore<PhoneStatusDto>(
            null, (x) => new OrganizationPhoneStatusesApi(getApiConfiguration()).getOrganizationPhoneStatuses(x),
            (value) => !value ? i18n.t('errors.empty') : ""),
        category: new DefaultSelectStore<PhoneCategoryDto>(
            null, (x) => new OrganizationPhoneCategoriesApi(getApiConfiguration()).getOrganizationPhoneCategories(x),
            (value) => !value ? i18n.t('errors.empty') : ""),
        additionalNumber: new DefaultValueStore<string>("", (value) =>
            value?.length! > 50 ? i18n.t('errors.lengthMax').replace("lengthMax", "50") : ""),
        description: new DefaultValueStore<string>("", (value) =>
            value?.length! > 180 ? i18n.t('errors.lengthMax').replace("lengthMax", "180") : "")

    }, (model) => this.create(model));

    statusFilter: DefaultMultiSelectStore<PhoneStatusDto> = new DefaultMultiSelectStore<PhoneStatusDto>([],
        (x) => new OrganizationPhoneStatusesApi(getApiConfiguration()).getOrganizationPhoneStatuses(x), (x) => this.updateState());
    categoryFilter: DefaultMultiSelectStore<PhoneCategoryDto> = new DefaultMultiSelectStore<PhoneCategoryDto>([],
        (x) => new OrganizationPhoneCategoriesApi(getApiConfiguration()).getOrganizationPhoneCategories(x), (x) => this.updateState());
    description: DefaultValueStore<string> = new DefaultValueStore<string>("", null, (x) => this.updateState());

    constructor(orgStore: OrganizationItemStore, dto: OrganizationDto) {
        super();
        this._orgStore = orgStore
        this._dto = dto
        this.organizationId = this._dto.id

        const filters = getObject<TableLocalFilters<OrganizationPhoneItemStore>>('organizations-phone-store-id-' + this._dto.id);

        if (filters) {
            this._page = filters.page;
            this._size = filters.size;
            this.search.setValueWithoutEffects(filters.search);
            this._order = filters.order;
            this._orderBy = filters.orderBy;
        }

        makeObservable(this, {
            organizationId: observable,
            current: observable,
        });
    }

    get isDouble(): boolean {
        return this._orgStore.isDouble;
    }

    get jurisdictionLocalSetting(): JurisdictionLocalSetting | undefined {
        return this._orgStore._store.store.store.authorizationStore?.jurisdictionsSettings
    }

    async request(): Promise<PagedItems<OrganizationPhoneItemStore>> {
        const countFilters = {
            organizationId: this._dto.id,
            phoneStatusIds: this.statusFilter.value?.length ? this.statusFilter.value.map(x => x.id) : undefined,
            phoneCategoryIds: this.categoryFilter.value?.length ? this.categoryFilter.value.map(x => x.id) : undefined,
        }
        const api: OrganizationPhonesApi = new OrganizationPhonesApi(getApiConfiguration());
        const count = await api.getOrganizationPhonesCount(countFilters);

        const filters = {
            page: this.page + 1,
            size: count.count,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            organizationId: this.organizationId,
            phoneStatusIds: this.statusFilter.value?.length ? this.statusFilter.value.map(x => x.id) : undefined,
            phoneCategoryIds: this.categoryFilter.value?.length ? this.categoryFilter.value.map(x => x.id) : undefined,
            description: this.description.value ?? ""
        };
        const result = await api.getOrganizationPhones(filters);

        const newList: OrganizationPhoneItemStore[] = [];
        for (const item of result) {
            const existsItem = this.items.find(t => t.dto.id === item.id);
            if (existsItem) {
                existsItem.update(item);
                newList.push(existsItem);
                continue;
            }
            newList.push(new OrganizationPhoneItemStore(this, item))
        }

        return new PagedItems<OrganizationPhoneItemStore>(newList, count.count)
    };

    async create(model: any): Promise<void> {
        this.processing = true;
        const api: OrganizationPhonesApi = new OrganizationPhonesApi(getApiConfiguration());
        try {
            const result = await api.createOrganizationPhone({
                setOrganizationPhoneDto:
                    {
                        phoneNumber: this.creator.fields.phone.value!,
                        organizationId: this._dto.id,
                        phoneStatusId: this.creator.fields.status.value?.id!,
                        phoneCategoryId: this.creator.fields.category.value?.id!,
                        phoneTypeId: this.creator.fields.type.value?.id!,
                        additionalNumber: this.creator.fields.additionalNumber.value,
                        description: this.creator.fields.description.value,
                    }
            });
            await this.pull()
        } finally {
            this.processing = false;
        }
    };

    onFiltersSave() {
        setObject<TableLocalFilters<OrganizationPhoneItemStore>>('organizations-phone-store-id-' + this._dto.id, {
            page: this.page,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
        });
    }
}
