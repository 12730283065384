/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetClientDto
 */
export interface SetClientDto {
    /**
     * 
     * @type {string}
     * @memberof SetClientDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SetClientDto
     */
    secret?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetClientDto
     */
    clientName: string;
    /**
     * 
     * @type {string}
     * @memberof SetClientDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetClientDto
     */
    createdByUserId?: string | null;
}

export function SetClientDtoFromJSON(json: any): SetClientDto {
    return SetClientDtoFromJSONTyped(json, false);
}

export function SetClientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetClientDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'secret': !exists(json, 'secret') ? undefined : json['secret'],
        'clientName': json['clientName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'createdByUserId': !exists(json, 'createdByUserId') ? undefined : json['createdByUserId'],
    };
}

export function SetClientDtoToJSON(value?: SetClientDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'secret': value.secret,
        'clientName': value.clientName,
        'description': value.description,
        'createdByUserId': value.createdByUserId,
    };
}

