/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DcrStatus,
    ListSortDirection,
    RequestRecordType,
    RequestStatus,
    ResponseDtoPagedResponse,
    ResponseDtoPagedResponseFromJSON,
    ResponseStatus,
} from '../models';

export interface ApiDcrV1ResponsesGetRequest {
    page?: number;
    take?: number;
    dcrStatus?: Array<DcrStatus>;
    requestRecordType?: Array<RequestRecordType>;
    requestStatus?: Array<RequestStatus>;
    responseStatus?: Array<ResponseStatus>;
    client?: Array<string>;
    orderField?: string;
    order?: ListSortDirection;
}

/**
 * 
 */
export class ResponseApi extends runtime.BaseAPI {

    /**
     */
    async apiDcrV1ResponsesGetRaw(requestParameters: ApiDcrV1ResponsesGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ResponseDtoPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.take !== undefined) {
            queryParameters['Take'] = requestParameters.take;
        }

        if (requestParameters.dcrStatus) {
            queryParameters['DcrStatus'] = requestParameters.dcrStatus;
        }

        if (requestParameters.requestRecordType) {
            queryParameters['RequestRecordType'] = requestParameters.requestRecordType;
        }

        if (requestParameters.requestStatus) {
            queryParameters['RequestStatus'] = requestParameters.requestStatus;
        }

        if (requestParameters.responseStatus) {
            queryParameters['ResponseStatus'] = requestParameters.responseStatus;
        }

        if (requestParameters.client) {
            queryParameters['Client'] = requestParameters.client;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['OrderField'] = requestParameters.orderField;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['Order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/v1/responses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDcrV1ResponsesGet(requestParameters: ApiDcrV1ResponsesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ResponseDtoPagedResponse> {
        const response = await this.apiDcrV1ResponsesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
