import {PeopleTableTabStore} from "./tabs/PeopleTableTab/PeopleTableTabStore";
import {GroupTableStore} from "./GroupTableStore";
import {DcrGroupDto, GroupApi, DcrGroupListDto} from "../../../../../services/dcr";
import {SettingsTabStore} from "./tabs/SettingsTabStore";
import {ParametersTabStore} from "./tabs/ParametersTabStore";
import {DynamicalComponentStore} from "../../../../utils";
import {makeObservable, observable} from "mobx";

export class GroupItemStore extends DynamicalComponentStore {
    readonly store: GroupTableStore;
    dto: DcrGroupListDto;
    ParametersTabStore: ParametersTabStore;
    SettingsTabStore: SettingsTabStore;
    PeopleTableStore: PeopleTableTabStore;

    constructor(store: GroupTableStore, dto: DcrGroupListDto) {
        super();
        this.store = store;
        this.dto = dto;

        this.ParametersTabStore = new ParametersTabStore(this)
        this.SettingsTabStore = new SettingsTabStore(this)
        this.PeopleTableStore = new PeopleTableTabStore(this, this.dto)

        makeObservable(this, {
            dto: observable
        })
    }

    restore() {
        this.ParametersTabStore.form.reset();
        this.SettingsTabStore.form.reset();
    }

    get equals(): boolean {
        return this.ParametersTabStore.form.equals && this.SettingsTabStore.form.equals
    }

    async pull() {
        const group = await new GroupApi().getGroup({groupId: this.dto.id!})
        this.update(group)
    }

    update(dto: DcrGroupDto) {
        this.dto = dto;
        this.ParametersTabStore.update(dto)
        this.SettingsTabStore.update(dto)
    }

    get model(): DcrGroupDto {
        return {
            id: this.dto.id!,
            name: this.ParametersTabStore.form.model.name!,
            ownerId: this.ParametersTabStore.form.model.owner!.id!,
            users: this.ParametersTabStore.form.model.participants.map((x: any) => x.id!),
            responseTime: this.SettingsTabStore.form.model.responseTime!,
            clients: this.SettingsTabStore.form.model.clients.map((x: any) => x.id!),
        }
    }

    async updateState(): Promise<void> {
        if (this.expand) {
            this.restore()
            if (this._tabIndex == 0 || this._tabIndex == 1) {
                await this.pull()
            }
            if (this._tabIndex === 2) {
                await this.PeopleTableStore.pull()
            }
        }
    }
}
