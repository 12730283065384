/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface SetUserJurisdictionDto
 */
export interface SetUserJurisdictionDto {
    /**
     * 
     * @type {string}
     * @memberof SetUserJurisdictionDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof SetUserJurisdictionDto
     */
    jurisdictionId: string;
}

export function SetUserJurisdictionDtoFromJSON(json: any): SetUserJurisdictionDto {
    return SetUserJurisdictionDtoFromJSONTyped(json, false);
}

export function SetUserJurisdictionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetUserJurisdictionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'jurisdictionId': json['jurisdictionId'],
    };
}

export function SetUserJurisdictionDtoToJSON(value?: SetUserJurisdictionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'jurisdictionId': value.jurisdictionId,
    };
}

