import {makeObservable, observable} from 'mobx';
import {DefaultMultiSelectStore} from '../../../../components/shared/DefaultMultiSelectStore';
import {getApiConfiguration, IProcessing} from '../../../../core';
import {ClientJurisdictionsApi} from '../../../../services/auth/apis/ClientJurisdictionsApi';
import {JurisdictionDto, JurisdictionsApi} from '../../../../services/management';
import {LoadStore} from '../../../utils';
import {ClientJurisdictionDto} from "../../../../services/auth";
import {ClientFragment} from "../../../../gql/graphql";

export class ClientJurisdictionsStore extends LoadStore implements IProcessing {
    readonly dto: ClientFragment;
    allJurisdictionsControl: DefaultMultiSelectStore<JurisdictionDto> = new DefaultMultiSelectStore([],
        (filters: any) => new JurisdictionsApi(getApiConfiguration()).getJurisdictions(filters));
    processing: boolean = false;
    currentValue: ClientJurisdictionDto[] = []

    constructor(dto: ClientFragment) {
        super();
        this.dto = dto;
        makeObservable(this, {
            processing: observable,
            currentValue: observable
        })
        this.allJurisdictionsControl.required = true;
    }

    async remove(ids: string[]) {
        for (const id of ids) {
            await new ClientJurisdictionsApi(getApiConfiguration({successTooltip: false})).deleteClientJurisdiction({
                id: id
            });
        }
    }

    async set(ids: string[]) {
        for (const id of ids) {
            await new ClientJurisdictionsApi(getApiConfiguration({successTooltip: false})).createClientJurisdiction({
                setClientJurisdictionDto: {
                    jurisdictionId: id,
                    clientId: this.dto.id
                }
            })
        }
    }

    async submit(): Promise<void> {
        const userJurIds = this.allJurisdictionsControl.firstValue.map(x => x.id);
        const currentIds = this.allJurisdictionsControl.value.map(x => x.id);

        const deletesIds = userJurIds.filter(x => !currentIds.includes(x));
        const insetIds = currentIds.filter(x => !userJurIds.includes(x));

        await this.remove(this.currentValue.filter(x => deletesIds.includes(x.jurisdictionId!)).map(x => x.id!))
        await this.set(insetIds)
    }

    async request(): Promise<void> {
        let filters: any = {
            page: 1,
            size: 1000,
            clientId: this.dto.id,
        };
        const result = await new ClientJurisdictionsApi(getApiConfiguration()).getClientJurisdictions(filters);
        this.currentValue = result;
        this.allJurisdictionsControl.update(result.map(x => ({...x.jurisdiction!, nameRus: x.jurisdiction?.name!})))
    };
}
