export const setObject = <T>(id: string, o: T) => {
    localStorage.setItem(id, JSON.stringify(o));
};

export const getObject = <T>(id: string): T | null => {
    const result = localStorage.getItem(id);
    if (!result) {
        return null;
    }
    return JSON.parse(result) as T;
};