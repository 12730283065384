/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddressUniqueLocalityEndpointDto,
    AddressUniqueLocalityEndpointDtoFromJSON,
    AddressUniqueLocalityEndpointDtoToJSON,
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetAddressUniqueLocalityEndpointDto,
    SetAddressUniqueLocalityEndpointDtoFromJSON,
    SetAddressUniqueLocalityEndpointDtoToJSON,
} from '../models';

export interface CreateAddressUniqueLocalityEndpointRequest {
    setAddressUniqueLocalityEndpointDto?: SetAddressUniqueLocalityEndpointDto;
}

export interface DeleteAddressUniqueLocalityEndpointRequest {
    id: string;
}

export interface GetAddressUniqueLocalitiesEndpointsRequest {
    categoryLocalityId?: string;
    typeLocalityId?: string;
    localityId?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface UpdateAddressUniqueLocalityEndpointRequest {
    id: string;
    canBeEndpoint?: boolean;
}

/**
 * 
 */
export class AddressUniqueLocalityEndpointsApi extends runtime.BaseAPI {

    /**
     */
    async createAddressUniqueLocalityEndpointRaw(requestParameters: CreateAddressUniqueLocalityEndpointRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AddressUniqueLocalityEndpointDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/addressUniques/localityEndpoints`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetAddressUniqueLocalityEndpointDtoToJSON(requestParameters.setAddressUniqueLocalityEndpointDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressUniqueLocalityEndpointDtoFromJSON(jsonValue));
    }

    /**
     */
    async createAddressUniqueLocalityEndpoint(requestParameters: CreateAddressUniqueLocalityEndpointRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AddressUniqueLocalityEndpointDto> {
        const response = await this.createAddressUniqueLocalityEndpointRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAddressUniqueLocalityEndpointRaw(requestParameters: DeleteAddressUniqueLocalityEndpointRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AddressUniqueLocalityEndpointDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAddressUniqueLocalityEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addressUniques/localityEndpoints/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressUniqueLocalityEndpointDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteAddressUniqueLocalityEndpoint(requestParameters: DeleteAddressUniqueLocalityEndpointRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AddressUniqueLocalityEndpointDto> {
        const response = await this.deleteAddressUniqueLocalityEndpointRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAddressUniqueLocalitiesEndpointsRaw(requestParameters: GetAddressUniqueLocalitiesEndpointsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<AddressUniqueLocalityEndpointDto>>> {
        const queryParameters: any = {};

        if (requestParameters.categoryLocalityId !== undefined) {
            queryParameters['categoryLocalityId'] = requestParameters.categoryLocalityId;
        }

        if (requestParameters.typeLocalityId !== undefined) {
            queryParameters['typeLocalityId'] = requestParameters.typeLocalityId;
        }

        if (requestParameters.localityId !== undefined) {
            queryParameters['localityId'] = requestParameters.localityId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addressUniques/localityEndpoints`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AddressUniqueLocalityEndpointDtoFromJSON));
    }

    /**
     */
    async getAddressUniqueLocalitiesEndpoints(requestParameters: GetAddressUniqueLocalitiesEndpointsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<AddressUniqueLocalityEndpointDto>> {
        const response = await this.getAddressUniqueLocalitiesEndpointsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAddressUniqueLocalityEndpointsCountRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addressUniques/localityEndpoints/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getAddressUniqueLocalityEndpointsCount(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getAddressUniqueLocalityEndpointsCountRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updateAddressUniqueLocalityEndpointRaw(requestParameters: UpdateAddressUniqueLocalityEndpointRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AddressUniqueLocalityEndpointDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAddressUniqueLocalityEndpoint.');
        }

        const queryParameters: any = {};

        if (requestParameters.canBeEndpoint !== undefined) {
            queryParameters['canBeEndpoint'] = requestParameters.canBeEndpoint;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addressUniques/localityEndpoints/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressUniqueLocalityEndpointDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateAddressUniqueLocalityEndpoint(requestParameters: UpdateAddressUniqueLocalityEndpointRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AddressUniqueLocalityEndpointDto> {
        const response = await this.updateAddressUniqueLocalityEndpointRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
