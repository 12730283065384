/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ClientJurisdictionDto,
    ClientJurisdictionDtoFromJSON,
    CountDto,
    CountDtoFromJSON,
    ListSortDirection,
    SetClientJurisdictionDto,
    SetClientJurisdictionDtoToJSON,
} from '../models';

export interface CreateClientJurisdictionRequest {
    setClientJurisdictionDto?: SetClientJurisdictionDto;
}

export interface DeleteClientJurisdictionRequest {
    id: string;
}

export interface GetClientJurisdictionsRequest {
    clientId: string;
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetClientJurisdictionsCountRequest {
    clientId: string;
    search?: string;
}

/**
 *
 */
export class ClientJurisdictionsApi extends runtime.BaseAPI {

    /**
     */
    async createClientJurisdictionRaw(requestParameters: CreateClientJurisdictionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ClientJurisdictionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/v1/clients/jurisdictions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetClientJurisdictionDtoToJSON(requestParameters.setClientJurisdictionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientJurisdictionDtoFromJSON(jsonValue));
    }

    /**
     */
    async createClientJurisdiction(requestParameters: CreateClientJurisdictionRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ClientJurisdictionDto> {
        const response = await this.createClientJurisdictionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteClientJurisdictionRaw(requestParameters: DeleteClientJurisdictionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ClientJurisdictionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteClientJurisdiction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/clients/jurisdictions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientJurisdictionDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteClientJurisdiction(requestParameters: DeleteClientJurisdictionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ClientJurisdictionDto> {
        const response = await this.deleteClientJurisdictionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClientJurisdictionsRaw(requestParameters: GetClientJurisdictionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ClientJurisdictionDto>>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling getClientJurisdictions.');
        }

        const queryParameters: any = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/clients/jurisdictions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientJurisdictionDtoFromJSON));
    }

    /**
     */
    async getClientJurisdictions(requestParameters: GetClientJurisdictionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ClientJurisdictionDto>> {
        const response = await this.getClientJurisdictionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClientJurisdictionsCountRaw(requestParameters: GetClientJurisdictionsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling getClientJurisdictionsCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/clients/jurisdictions/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getClientJurisdictionsCount(requestParameters: GetClientJurisdictionsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getClientJurisdictionsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
