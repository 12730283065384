/* tslint:disable */
/* eslint-disable */
/**
 * Data360.DCR.Analytics
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DcrStatus,
    DcrStatusFromJSON,
    DcrStatusFromJSONTyped,
    DcrStatusToJSON,
} from './DcrStatus';
import {
    RequestRecordType,
    RequestRecordTypeFromJSON,
    RequestRecordTypeFromJSONTyped,
    RequestRecordTypeToJSON,
} from './RequestRecordType';
import {
    RequestStatus,
    RequestStatusFromJSON,
    RequestStatusFromJSONTyped,
    RequestStatusToJSON,
} from './RequestStatus';

/**
 * 
 * @export
 * @interface DcrData360ReportFilter
 */
export interface DcrData360ReportFilter {
    /**
     * 
     * @type {Date}
     * @memberof DcrData360ReportFilter
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DcrData360ReportFilter
     */
    endDate?: Date | null;
    /**
     * 
     * @type {Array<RequestStatus>}
     * @memberof DcrData360ReportFilter
     */
    status?: Array<RequestStatus> | null;
    /**
     * 
     * @type {Array<RequestRecordType>}
     * @memberof DcrData360ReportFilter
     */
    entity?: Array<RequestRecordType> | null;
    /**
     * 
     * @type {Array<DcrStatus>}
     * @memberof DcrData360ReportFilter
     */
    type?: Array<DcrStatus> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DcrData360ReportFilter
     */
    clients?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DcrData360ReportFilter
     */
    responsibleIds?: Array<string> | null;
}

export function DcrData360ReportFilterFromJSON(json: any): DcrData360ReportFilter {
    return DcrData360ReportFilterFromJSONTyped(json, false);
}

export function DcrData360ReportFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): DcrData360ReportFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'status': !exists(json, 'status') ? undefined : (json['status'] === null ? null : (json['status'] as Array<any>).map(RequestStatusFromJSON)),
        'entity': !exists(json, 'entity') ? undefined : (json['entity'] === null ? null : (json['entity'] as Array<any>).map(RequestRecordTypeFromJSON)),
        'type': !exists(json, 'type') ? undefined : (json['type'] === null ? null : (json['type'] as Array<any>).map(DcrStatusFromJSON)),
        'clients': !exists(json, 'clients') ? undefined : json['clients'],
        'responsibleIds': !exists(json, 'responsibleIds') ? undefined : json['responsibleIds'],
    };
}

export function DcrData360ReportFilterToJSON(value?: DcrData360ReportFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'status': value.status === undefined ? undefined : (value.status === null ? null : (value.status as Array<any>).map(RequestStatusToJSON)),
        'entity': value.entity === undefined ? undefined : (value.entity === null ? null : (value.entity as Array<any>).map(RequestRecordTypeToJSON)),
        'type': value.type === undefined ? undefined : (value.type === null ? null : (value.type as Array<any>).map(DcrStatusToJSON)),
        'clients': value.clients,
        'responsibleIds': value.responsibleIds,
    };
}

