import {makeAutoObservable} from 'mobx';
import i18n from "i18next";
import {OrganizationNameTableStore} from './OrganizationNameTableStore';
import {DefaultValueStore} from '../../../../../components/shared/DefaultValueStore';
import {OrgNamesApi, TypeNameDto, TypeNamesApi} from '../../../../../services/management';
import {DefaultSelectStore} from '../../../../../components/shared/DefaultSelectStore';
import {getApiConfiguration} from '../../../../../core';
import {OrganizationNameItemStore} from './OrganizationNameItemStore';


export class OrganizationNameCreatorStore {
    readonly _store: OrganizationNameTableStore;
    readonly name: DefaultValueStore<string>;
    readonly type: DefaultSelectStore<TypeNameDto>;
    private _open: boolean = false;
    processing: boolean = false;

    constructor(store: OrganizationNameTableStore) {
        this._store = store;

        this.name = new DefaultValueStore<string>("", (value) => !value ? i18n.t('errors.empty') : "");

        this.type = new DefaultSelectStore<TypeNameDto>(
            null, (x) => new TypeNamesApi(getApiConfiguration()).getTypeNames(x),
            (value) => !value ? i18n.t('errors.empty') : "");

        makeAutoObservable(this);
    }

    get Open() {
        return this._open;
    }

    set Open(value: boolean) {
        this._open = value;
        this.reset();
    }

    get valid(): boolean {
        return this.name.valid && this.type.valid
    }

    reset() {
        this.processing = false;
        this.name.reset();
        this.type.reset();
    }

    validate() {
        this.name.validate();
        this.type.validate();
    }

    async create(): Promise<void> {
        this.processing = true;
        this.validate();
        if (!this.valid) {
            this.processing = false;
            return;
        }
        const api: OrgNamesApi = new OrgNamesApi(getApiConfiguration());
        try {
            const result = await api.createOrgName({
                setOrgNameDto:{
                    name: this.name?.value!,
                    typeNameId: this.type?.value?.id!,
                    organizationId: this._store?._dto?.id
                }
            });

            const newPersonResult = await api.getOrgNameById({id: result.id});
            this._store.current = new OrganizationNameItemStore(this._store, newPersonResult);
            this._store.items = [...this._store.items, this._store.current];
            this.Open = false;
            await this._store.pull()
        } catch (ex) {

        }
        this.processing = false;
    };
}
