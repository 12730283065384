import {getObject, setObject} from '../../../core';
import {ManagementStore} from '..';
import {GroupTableStore} from './tabs/Groups/GroupTableStore';
import {RoleTableStore} from "./tabs/Roles/RoleTableStore";
import {PermissionTableStore} from "./tabs/Permissions/PermissionTableStore";
import {UserTableStore} from "./tabs/Users/UserTableStore";
import {DynamicalComponentStore} from "../../utils";
// import {ClaimTableStore} from "./tabs/Claims/ClaimTableStore";

interface UsersTabStoreLocalFilters {
    tabIndex: number;
    expand: boolean;
}

export class UsersPageStore extends DynamicalComponentStore {
    readonly store: ManagementStore;
    readonly userTableStore: UserTableStore;
    readonly permissionTableStore: PermissionTableStore;
    readonly roleTableStore: RoleTableStore;
    readonly dcrGroupTableStore: GroupTableStore;
    // readonly claimTableStore: ClaimTableStore;

    constructor(store: ManagementStore) {
        super()
        this.store = store;
        this.userTableStore = new UserTableStore(store);
        this.permissionTableStore = new PermissionTableStore();
        this.roleTableStore = new RoleTableStore();
        this.dcrGroupTableStore = new GroupTableStore(this);
        // this.claimTableStore = new ClaimTableStore();
        const filters = getObject<UsersTabStoreLocalFilters>('usersTab-store');
        if (filters) {
            this._tabIndex = filters.tabIndex;
            this.expand = filters.expand;
        }
    }

    async updateState() {
        this.saveLocalFilters();
        if (this._tabIndex === 0) {
            this.userTableStore.updateState().then()
        } else if (this._tabIndex === 1) {
            this.permissionTableStore.updateState().then()
        } else if (this._tabIndex === 2) {
            this.roleTableStore.updateState().then()
        } else if (this._tabIndex === 3) {
            this.dcrGroupTableStore.updateState().then()
        }
        // else if (this._tabIndex === 4) {
        //     this.claimTableStore.search.value = "";
        // }
    }

    saveLocalFilters = () => {
        setObject<UsersTabStoreLocalFilters>('usersTab-store', {
            tabIndex: this._tabIndex,
            expand: this.expand,
        })
    }
}
