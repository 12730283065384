import {makeAutoObservable} from 'mobx';
import {getApiConfiguration, IExpanded, IForm, IProcessing} from '../../../../../../core';
import {
    OrganizationPhoneCategoriesApi,
    OrganizationPhoneDto,
    OrganizationPhonesApi,
    OrganizationPhoneStatusesApi,
    OrganizationPhoneTypesApi,
    PhoneCategoryDto,
    PhoneStatusDto,
    PhoneTypeDto
} from '../../../../../../services/management';
import {OrganizationPhoneTableStore} from "./OrganizationPhoneTableStore";
import {DefaultSelectStore} from "../../../../../../components/shared/DefaultSelectStore";
import i18n from "i18next";
import {DefaultValueStore} from '../../../../../../components/shared/DefaultValueStore';
import {JurisdictionLocalSetting} from "../../../../../authorization/AuthorizationStore";

export class OrganizationPhoneItemStore implements IProcessing, IExpanded, IForm {
    readonly _store: OrganizationPhoneTableStore;
    dto: OrganizationPhoneDto;
    processing: boolean = false;
    deleteDialogState: boolean = false;
    expand: boolean = false;
    orgPhoneId: string

    phone: DefaultValueStore<string>
    status: DefaultSelectStore<PhoneStatusDto>
    type: DefaultSelectStore<PhoneTypeDto>
    category: DefaultSelectStore<PhoneCategoryDto>
    additionalNumber: DefaultValueStore<string>
    description: DefaultValueStore<string>

    constructor(store: OrganizationPhoneTableStore, dto: OrganizationPhoneDto) {
        this._store = store;
        this.dto = dto;
        this.orgPhoneId = dto.id;

        this.phone = new DefaultValueStore<string>(dto.phone.phoneNumber, (value) =>
            new RegExp(this.jurisdictionLocalSetting?.phoneNumberRegex!)
                .test(value ?? "") ? "" : i18n.t('dcrPage.errors.phoneFormat').replace("{length}", this.jurisdictionLocalSetting?.numberLength!));
        this.phone.required = true;

        this.status = new DefaultSelectStore<PhoneStatusDto>(
            dto.phoneStatus, (x) => new OrganizationPhoneStatusesApi(getApiConfiguration()).getOrganizationPhoneStatuses(x),
            (value) => !value ? i18n.t('errors.empty') : "")

        this.type = new DefaultSelectStore<PhoneTypeDto>(
            dto.phoneType, (x) => new OrganizationPhoneTypesApi(getApiConfiguration()).getOrganizationPhoneTypes(x),
            (value) => !value ? i18n.t('errors.empty') : "")

        this.category = new DefaultSelectStore<PhoneCategoryDto>(
            dto.phoneCategory, (x) => new OrganizationPhoneCategoriesApi(getApiConfiguration()).getOrganizationPhoneCategories(x),
            (value) => !value ? i18n.t('errors.empty') : "")

        this.additionalNumber = new DefaultValueStore<string>(dto?.additionalNumber ?? '', (value) =>
            value?.length! > 50 ? i18n.t('errors.lengthMax').replace("lengthMax", "50") : "")

        this.description = new DefaultValueStore<string>(dto?.description ?? '', (value) =>
            value?.length! > 180 ? i18n.t('errors.lengthMax').replace("lengthMax", "180") : "")


        makeAutoObservable(this);
    }

    get jurisdictionLocalSetting(): JurisdictionLocalSetting | undefined {
        return this._store._orgStore._store.store.store.authorizationStore?.jurisdictionsSettings
    }

    async delete(): Promise<void> {
        this.processing = true;
        const api: OrganizationPhonesApi = new OrganizationPhonesApi(getApiConfiguration());
        try {
            const result = await api.deleteOrganizationPhone({
                id: this.orgPhoneId
            });
            this._store.items = this._store.items.filter(t => t.dto.id !== result.id);
            await this._store.pull();
        } catch (e) {

        }
        this._store.current = null;
        this.processing = false;
    };

    get equals(): boolean {
        return this.status.equals
            && this.category.equals
            && this.type.equals
            && this.additionalNumber.equals
            && this.description.equals &&
            this.phone.equals
    }

    get valid(): boolean {
        return this.status.valid && this.category.valid && this.type.valid && this.additionalNumber.valid && this.description.valid && this.phone.valid;
    }

    validate() {
        this.status.validate();
        this.category.validate();
        this.type.validate();
        this.description.validate()
        this.phone.validate()
    }

    update(dto: OrganizationPhoneDto) {
        this.dto = dto;
        this.status.update(dto.phoneStatus);
        this.type.update(dto.phoneType);
        this.category.update(dto.phoneCategory);
        this.additionalNumber.update(dto?.additionalNumber || '');
        this.description.update(dto?.description || '');
        this.phone.update(dto?.phone.phoneNumber);
    }

    reset(): void {
        this.status.reset();
        this.category.reset();
        this.type.reset();
        this.additionalNumber.reset();
        this.description.reset();
        this.phone.reset();
        this.validate();
    }

    async submit() {
        this.validate()
        if (!this.valid)
            return
        this.processing = true;
        const api: OrganizationPhonesApi = new OrganizationPhonesApi(getApiConfiguration());

        try {
            let item = await api.updateOrganizationPhone({
                id: this.orgPhoneId,
                setOrganizationPhoneDto:
                    {
                        organizationId: this._store.organizationId,
                        phoneStatusId: this.status.value?.id!,
                        phoneTypeId: this.type.value?.id!,
                        phoneNumber: this.phone.value ?? this.dto.phone?.phoneNumber,
                        phoneCategoryId: this.category.value?.id!,
                        additionalNumber: this.additionalNumber.value,
                        description: this.description.value,
                    }
            })
            this._store.updateState();
        } finally {
            this.processing = false;
        }
    }
}
