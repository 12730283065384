import { makeObservable, observable } from "mobx";
import { getApiConfiguration } from "../../../../../core";
import { OrganizationDto, OrganizationsApi } from "../../../../../services/management";
import {PagedItems, TableStore} from "../../../../utils";
import { ParentChildTabsStore } from "./ParentChildTabsStore";

export class OrganizationChildsTableStore extends TableStore<OrganizationDto> {
    _orgStore: ParentChildTabsStore
    _dto: OrganizationDto
    organizationId: string

    constructor(orgStore: ParentChildTabsStore, dto: OrganizationDto) {
        super();
        this._orgStore = orgStore
        this._dto = dto
        this.organizationId = dto.id

        makeObservable(this, {
            _orgStore: observable,
            _dto: observable,
            organizationId: observable
        });
    }

    async request(): Promise<PagedItems<OrganizationDto>> {
        const filters = {
            page: this.page + 1,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
        };
        const api: OrganizationsApi = new OrganizationsApi(getApiConfiguration());
        const count = await api.getOrganizationsCount({ ...filters, parentId: this.organizationId});
        let newList: OrganizationDto[] = [];
        try {
            let result = await api.getOrganizations({ ...filters, parentId: this.organizationId});
            newList = result.filter(x => x.id !== this._dto.id);
        } catch(e) {

        }
        return new PagedItems<OrganizationDto>(newList, count.count)
    };
}
