import {Box, Card, Table, TableBody, TableHead, TableRow, Typography} from "@mui/material";
import {observer} from "mobx-react";
import React, {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    AddButton,
    AuthorizePermission,
    Cell,
    EmptyBox,
    ExpandButton,
    ExpandRow,
    ExpertDialog,
    HCell,
    Pagination,
    Progress,
    Search
} from '../../components';
import {NavLink} from "../../components/NavLink";
import {TabTitle} from "../../components/TabTitle";
import {FiltersComponent} from "../../components/filters/FiltersComponent";
import {AllPermissions} from "../../core/const";
import {useManagementStore} from "../../stores";
import {ClientCreator} from "./ClientCreator";
import {ClientDetails} from "./ClientDetails";
import {DefaultDate} from "../../components/shared/DefaultDate";

export const ClientTable = observer(() => {
    const { clientTableStore } = useManagementStore();
    const { t } = useTranslation();
    const [openProduct, setOpenProduct] = useState<string | null>(null);

    const handleOpenProduct = (productId: string): void => {
        setOpenProduct((prevValue) => (prevValue === productId ? null : productId));
    };

    return (

        <AuthorizePermission permissions={AllPermissions.client.read} showWarning>
            <>
                <ExpertDialog
                    title={t('create.client')}
                    open={clientTableStore.creator.Open}
                    onClose={() => clientTableStore.creator.Open = false}
                >
                    <ClientCreator store={clientTableStore.creator} />
                </ExpertDialog>
                <>

                    <TabTitle title={t('pageNames.clients')}>
                        <AuthorizePermission permissions={AllPermissions.client.create}>
                            <AddButton onClick={() => clientTableStore.creator.Open = true} />
                        </AuthorizePermission>
                    </TabTitle>
                    <FiltersComponent>
                        <Box sx={{ mt: 1 }}>
                            <Search
                                autoFocus
                                value={clientTableStore.search.value}
                                onChange={t => clientTableStore.search.value = t}
                                style={{ width: '100%' }}
                                testid="clientTable"
                            />

                        </Box>
                        <Box
                            sx={{ mt: 2, mb: 3 }}
                            style={{ display: 'flex' }}
                        >
                            <DefaultDate store={clientTableStore.startDate}
                                         title={t('common.startDate')}
                            style={{paddingRight: 10}}/>
                            <DefaultDate store={clientTableStore.endDate}
                                         title={t('common.endDate')}/>
                        </Box>
                    </FiltersComponent>


                    <Card sx={{ mb: 8, overflow: 'unset' }}>
                        <Table sx={{ minWidth: 1250 }}>
                            <TableHead style={{ position: 'sticky', top: 63, zIndex: 999 }}>
                                <TableRow>
                                    <HCell width='5%' testid="clientTable-expand"></HCell>
                                    <HCell orderBy='name' store={clientTableStore}
                                           testid="clientTable-name">{t('clientPage.name')}</HCell>
                                    <HCell orderBy='orgName' store={clientTableStore}
                                           testid="clientTable-orgName">{t('clientPage.orgName')}</HCell>
                                    <HCell orderBy='userName' testid="clientTable-creatorName"
                                           store={clientTableStore}>{t('clientPage.creatorName')}</HCell>
                                    <HCell orderBy='createdAt' width='15%' testid="clientTable-createdDate"
                                           store={clientTableStore}>{t('table.createdDate')}</HCell>
                                    <HCell orderBy='modifiedAt' width='15%' align="right" testid="clientTable-date"
                                        store={clientTableStore}>{t('table.date')}</HCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clientTableStore.items.length > 0 && clientTableStore.items.map((client) => {
                                    const open = client.dto.id === openProduct;
                                    return (
                                        <Fragment key={client.dto.id}>
                                            <TableRow
                                                hover
                                                key={client.dto.id}
                                            >
                                                <ExpandButton item={client} isOpen={open} testid="clientTable"
                                                              onClick={() => handleOpenProduct(client.dto.id)}/>
                                                <Cell style={{maxWidth: 180}} testid="clientTable-name">
                                                    <NavLink to={`${client.dto.id}`}>
                                                        <Typography variant="subtitle2" sx={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>
                                                            {client.name}
                                                        </Typography>
                                                    </NavLink>
                                                </Cell>
                                                <Cell style={{ maxWidth: 180 }} testid="clientTable-orgName">
                                                    <Typography variant="subtitle2" sx={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                    >
                                                        {client.orgName}
                                                    </Typography>
                                                </Cell>
                                                <Cell style={{ maxWidth: 180 }} testid="clientTable-creatorName">
                                                    <Typography variant="subtitle2">
                                                        {client.userName}
                                                    </Typography>
                                                </Cell>
                                                <Cell testid="clientTable-createdDate" style={{ maxWidth: 150 }}>
                                                    <Typography variant="subtitle2" color="textSecondary">
                                                        {client.createdAt}
                                                    </Typography>
                                                </Cell>
                                                <Cell testid="clientTable-date" style={{ maxWidth: 150 }}>
                                                    <Typography variant="subtitle2" color="textSecondary">
                                                        {client.modifiedAt}
                                                    </Typography>
                                                </Cell>
                                            </TableRow>
                                            {open && (
                                                <ExpandRow key={`${client.dto.id}_`} item={client}>
                                                    <ClientDetails
                                                        store={client}
                                                        style={{margin: '20px'}}
                                                    />
                                                </ExpandRow>)}
                                        </Fragment>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {!clientTableStore.items.length && <EmptyBox />}

                        {clientTableStore.items.length > 0 && <Pagination store={clientTableStore} />}

                        <Progress store={clientTableStore} />

                    </Card>
                </>
            </>
        </AuthorizePermission>

    );
});
