import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Box, IconButton, Table, TableCell, TableRow, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { AddButton, AuthorizePermission, EmptyRow, ExpertDialog, FormRow, Progress, Search } from "../../../../components";
import { DefaultSelect } from "../../../../components/shared/components/DefaultSelect";
import { ChevronDown as ChevronDownIcon } from "../../../../icons/chevron-down";
import { ChevronRight as ChevronRightIcon } from '../../../../icons/chevron-right';
import { DotsHorizontal as DotsHorizontalIcon } from '../../../../icons/dots-horizontal';
import {
    TypeOrganizationItemStore
} from "../../../../stores/management/dictionaries/organizations/tabs/TypeOrganizations/TypeOrganizationItemStore";
import {
    TypeOrganizationTableStore
} from "../../../../stores/management/dictionaries/organizations/tabs/TypeOrganizations/TypeOrganizationTableStore";
import { DictionaryCreator } from "../../DictionaryCreator";
import { DictionaryItemDetails } from "../../DictionaryItemDetails";
import {AllPermissions} from '../../../../core/const';

const StyledTreeItem = observer((props: { store: TypeOrganizationItemStore }) => {
    const { t } = useTranslation();

    return (
        <TreeItem
            key={props.store.dto.id}
            nodeId={props.store.dto.id}
            onClick={() => {
                props.store.expand = !props.store.expand
            }}

            icon={props.store.value.length > 0 || !props.store.loaded ? <IconButton
                aria-label="expand row"
                size="large"
                sx={{ ml: 2, pl: 2 }}
            >
                {props.store.expand ? <ChevronDownIcon fontSize="small" /> :
                    <ChevronRightIcon fontSize="small" />}
            </IconButton> : <></>}

            label={
                <Table onClick={(e) => e.stopPropagation()}
                    sx={{ ml: 2 }}
                >
                    <TableRow style={{ height: 56, display: 'flex', alignItems: 'center' }}>
                        <Typography variant="subtitle2">
                            {props.store.dto.nameRus}
                        </Typography>
                        <IconButton onClick={() => {
                            props.store.openDetails = !props.store.openDetails
                        }}>
                            <DotsHorizontalIcon fontSize="small" />
                        </IconButton>
                    </TableRow>

                    {props.store.openDetails &&
                        <TableRow>
                            <TableCell
                                colSpan={7}
                                sx={{
                                    p: 0,
                                    position: 'relative',
                                    '&:after': {
                                        position: 'absolute',
                                        content: '" "',
                                        top: 0,
                                        left: 0,
                                        backgroundColor: 'primary.main',
                                        width: 3,
                                        height: 'calc(100% + 1px)'
                                    }
                                }}
                            >
                                <DictionaryItemDetails
                                    store={props.store}
                                    dictionaryType={
                                        AllPermissions.dictionary.organization.typeorg
                                    }
                                    style={{ margin: '0 40px' }}
                                    placeholder={t('dictionary.tabNames.typeOrgs')}
                                />
                            </TableCell>
                        </TableRow>
                    }
                </Table>
            }
        >
            <TreeItem nodeId={props.store.dto.id + "load"} />
            {Array.isArray(props.store.value) && !props.store.loading
                ? props.store.value.map(node =>
                    <StyledTreeItem store={node} />)
                : null}
        </TreeItem>
    )
});


interface Props {
    store: TypeOrganizationTableStore;
    style?: React.CSSProperties;
}
export const TypeOrganizationTree = observer(({ store }: Props) => {
    const { t } = useTranslation();

    return (
        <Box>
            <Box sx={{
                flexGrow: 1,
                py: 0,
                backgroundColor: 'background.default',
            }}
            >
                <ExpertDialog
                    title={t('create.typeOrg')}
                    open={store.creator.Open}
                    onClose={() => store.creator.Open = false}
                >
                    <DictionaryCreator store={store.creator} placeholder={t('table.typeOrg')}>
                        <FormRow>
                            <DefaultSelect title={t('table.clinicCategory')}
                                store={store.creator.fields.clinicCategory}
                                testid="orgDictionaryTable-clinicCategory" />
                        </FormRow>
                        <FormRow>
                            <DefaultSelect title={t('table.parent')}
                                store={store.creator.fields.parent}
                                testid="orgDictionaryTable-parent" />
                        </FormRow>
                    </DictionaryCreator>
                </ExpertDialog>

                <Box sx={{ mb: 2 }}>
                    <Box
                        sx={{
                            mt: 3,
                            mb: 3
                        }}
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <Search
                            value={store.search.value}
                            onChange={(t) => store.search.value = t}
                            style={{ width: '87%', marginRight: 8 }}
                            testid="typeOrganizationTable"
                        />
                        <AuthorizePermission permissions={AllPermissions.dictionary.organization.typeorg.create}>
                            <AddButton onClick={() => store.creator.Open = true} />
                        </AuthorizePermission>
                    </Box>
                    <TreeView
                        aria-label="customized"
                        expanded={store.selectedLocalityIds}
                        sx={{ flexGrow: 1, maxWidth: '100%', width: '100%' }}
                    >
                        {
                            store.value.length > 0
                                ? store.value?.map(x =>
                                    <StyledTreeItem store={x} />
                                )
                                : <Table><EmptyRow /></Table>
                        }
                    </TreeView>

                    {(!store.value.length || store.loading) && <Box
                        sx={{
                            flexGrow: 1,
                            py: 3,
                        }}
                    >
                        <Progress store={store} />
                    </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
});
