/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    PersonSpecialityDto,
    PersonSpecialityDtoFromJSON,
    PersonSpecialityDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface GetPersonSpecialitiesRequest {
    personId: string;
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetPersonSpecialitiesCountRequest {
    personId: string;
    search?: string;
}

export interface GetPersonSpecialityPackageRequest {
    personId: string;
    specialityIds: Array<string>;
}

export interface RemovePersonSpecialitiesRequest {
    personId: string;
    requestBody: Array<string>;
}

export interface SetPersonSpecialitiesRequest {
    personId: string;
    requestBody: Array<string>;
}

/**
 * 
 */
export class PersonSpecialitiesApi extends runtime.BaseAPI {

    /**
     */
    async getPersonSpecialitiesRaw(requestParameters: GetPersonSpecialitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonSpecialityDto>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getPersonSpecialities.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/specialities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonSpecialityDtoFromJSON));
    }

    /**
     */
    async getPersonSpecialities(requestParameters: GetPersonSpecialitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonSpecialityDto>> {
        const response = await this.getPersonSpecialitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonSpecialitiesCountRaw(requestParameters: GetPersonSpecialitiesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getPersonSpecialitiesCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/specialities/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonSpecialitiesCount(requestParameters: GetPersonSpecialitiesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPersonSpecialitiesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonSpecialityPackageRaw(requestParameters: GetPersonSpecialityPackageRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonSpecialityDto>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getPersonSpecialityPackage.');
        }

        if (requestParameters.specialityIds === null || requestParameters.specialityIds === undefined) {
            throw new runtime.RequiredError('specialityIds','Required parameter requestParameters.specialityIds was null or undefined when calling getPersonSpecialityPackage.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        if (requestParameters.specialityIds) {
            queryParameters['specialityIds'] = requestParameters.specialityIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/specialities/package`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonSpecialityDtoFromJSON));
    }

    /**
     */
    async getPersonSpecialityPackage(requestParameters: GetPersonSpecialityPackageRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonSpecialityDto>> {
        const response = await this.getPersonSpecialityPackageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removePersonSpecialitiesRaw(requestParameters: RemovePersonSpecialitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonSpecialityDto>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling removePersonSpecialities.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling removePersonSpecialities.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/persons/specialities/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonSpecialityDtoFromJSON));
    }

    /**
     */
    async removePersonSpecialities(requestParameters: RemovePersonSpecialitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonSpecialityDto>> {
        const response = await this.removePersonSpecialitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setPersonSpecialitiesRaw(requestParameters: SetPersonSpecialitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonSpecialityDto>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling setPersonSpecialities.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling setPersonSpecialities.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/persons/specialities/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonSpecialityDtoFromJSON));
    }

    /**
     */
    async setPersonSpecialities(requestParameters: SetPersonSpecialitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonSpecialityDto>> {
        const response = await this.setPersonSpecialitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
