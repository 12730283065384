import type { FC } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

export type MenuItemComponentProps = {
    text: string;
    icon?: React.ReactNode;
    onClick?: () => void;
    style?: React.CSSProperties;
    disabled?: boolean;
}

export const MenuItemComponent: FC<MenuItemComponentProps> = (props) => {
    const { text, icon, onClick, style, disabled } = props;
    return (
        <MenuItem onClick={onClick} style={style} disabled={disabled}>
            <ListItemIcon sx={{ m: 0 }}>
                {icon}
            </ListItemIcon>
            <ListItemText primary={text} />
        </MenuItem>
    )
};