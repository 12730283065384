import React, {useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography} from '@mui/material';

export interface Props {
    title?: string;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    open: boolean;
    onClose?: () => void;
    children?: React.ReactNode;
    hasTitle?: boolean;
    style?: React.CSSProperties;
    withApproveClose?: boolean;
}


export const ExpertDialog = ({
                                 children,
                                 title,
                                 open,
                                 onClose,
                                 size,
                                 hasTitle = true,
                                 style,
                                 withApproveClose = false
                             }: Props) => {
    const [approveCloseValue, setApproveCloseValue] = useState(null)
    const [openApproveCloseModalValue, setOpenApproveCloseModalValue] = useState(false)


    const closeHandle = () => {
        if (withApproveClose) {
            setOpenApproveCloseModalValue(true)
        } else {
            onClose?.()
        }
    }

    return (
        <>
            {
                openApproveCloseModalValue &&
                <ExpertDialog open={openApproveCloseModalValue} onClose={() => setOpenApproveCloseModalValue(false)}
                              hasTitle={false}>
                    <Box>Вы действительно хотите прервать действие?</Box>
                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 1}}>
                        <Button
                            color="inherit"
                            sx={{mr: 1}}
                            onClick={() => {
                                setOpenApproveCloseModalValue(false)
                                onClose?.()
                            }}
                        >
                            Да
                        </Button>
                        <Box sx={{flex: '1 1 auto'}}/>
                        <Button onClick={() => setOpenApproveCloseModalValue(false)}>
                            Нет
                        </Button>
                    </Box>
                </ExpertDialog>
            }
            <Dialog
                style={{...style, border: 'unset'}}
                fullWidth
                maxWidth={size}
                onClose={closeHandle}
                open={open}
                scroll='body'
            >
                {
                    hasTitle &&
                    <DialogTitle
                        data-testid='dialog-title'
                        sx={{
                            margin: 0,
                            padding: theme => theme.spacing(2),
                        }}
                    >
                        {
                            title &&
                            <Typography variant="h6">
                                {title}
                            </Typography>
                        }
                        {onClose
                            ? (
                                <IconButton aria-label="close"
                                            sx={{
                                                position: 'absolute',
                                                marginTop: 0.5,
                                                right: theme => theme.spacing(1),
                                                top: theme => theme.spacing(1),
                                                color: theme => theme.palette.grey[500],
                                            }}
                                            onClick={closeHandle}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            )
                            : null}
                    </DialogTitle>
                }

                <DialogContent
                    data-testid="dialog-content"
                    sx={{
                        margin: 0,
                    }}
                    dividers
                >
                    {children}
                </DialogContent>
            </Dialog>
        </>

    );
};
