/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface UpdatePersonJobDto
 */
export interface UpdatePersonJobDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonJobDto
     */
    postId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdatePersonJobDto
     */
    statusId?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatePersonJobDto
     */
    tags?: Array<string> | null;
    /**
     *
     * @type {boolean}
     * @memberof UpdatePersonJobDto
     */
    telemedicine?: boolean | null;
}

export function UpdatePersonJobDtoFromJSON(json: any): UpdatePersonJobDto {
    return UpdatePersonJobDtoFromJSONTyped(json, false);
}

export function UpdatePersonJobDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePersonJobDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postId': !exists(json, 'postId') ? undefined : json['postId'],
        'statusId': !exists(json, 'statusId') ? undefined : json['statusId'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'telemedicine': !exists(json, 'telemedicine') ? undefined : json['telemedicine'],
    };
}

export function UpdatePersonJobDtoToJSON(value?: UpdatePersonJobDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postId': value.postId,
        'statusId': value.statusId,
        'tags': value.tags,
        'telemedicine': value.telemedicine,
    };
}

