/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Exchange.Analytics.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CountsHistogram,
    CountsHistogramFromJSON,
    CountsHistogramFromJSONTyped,
    CountsHistogramToJSON,
} from './CountsHistogram';

/**
 * 
 * @export
 * @interface ClientsAggregationHistogram
 */
export interface ClientsAggregationHistogram {
    /**
     * 
     * @type {string}
     * @memberof ClientsAggregationHistogram
     */
    clientId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientsAggregationHistogram
     */
    clientName?: string | null;
    /**
     * 
     * @type {Array<CountsHistogram>}
     * @memberof ClientsAggregationHistogram
     */
    histogram?: Array<CountsHistogram> | null;
}

export function ClientsAggregationHistogramFromJSON(json: any): ClientsAggregationHistogram {
    return ClientsAggregationHistogramFromJSONTyped(json, false);
}

export function ClientsAggregationHistogramFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientsAggregationHistogram {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'clientName': !exists(json, 'clientName') ? undefined : json['clientName'],
        'histogram': !exists(json, 'histogram') ? undefined : (json['histogram'] === null ? null : (json['histogram'] as Array<any>).map(CountsHistogramFromJSON)),
    };
}

export function ClientsAggregationHistogramToJSON(value?: ClientsAggregationHistogram | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientId': value.clientId,
        'clientName': value.clientName,
        'histogram': value.histogram === undefined ? undefined : (value.histogram === null ? null : (value.histogram as Array<any>).map(CountsHistogramToJSON)),
    };
}

