import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { AuthorizePermission, Page, PageTab, PageTabs } from "../../components";
import { AllPermissions } from "../../core/const";
import { FolderOpenOutline as FolderOpenOutlineIcon } from '../../icons/folder-open-outline';
import { Layers as LayersIcon } from '../../icons/layers';
import { List as OrgListIcon } from '../../icons/list';
import { OrgAddress as OrgAddressIcon } from '../../icons/orgAddress';
import { OrgEmployees as OrgEmployeesIcon } from '../../icons/orgEmployes';
import { OrgNames as OrgNamesIcon } from '../../icons/orgNames';
import { OrgParent as OrgParentIcon } from '../../icons/orgParent';
import { PersonParams as PersonParamsIcon } from '../../icons/personParams';
import { OrganizationItemStore } from "../../stores/management/organizations/OrganizationItemStore";
import { OrganizationAddress } from "./details/OrganizationAddress";
import { OrganizationProperties } from "./details/OrganizationProperties";
import { OrganizationEmployeesTable } from "./details/employees/OrganizationEmployeesTable";
import { OrganizationNameTable } from "./details/names/OrganizationNameTable";
import { NotesTable } from "./details/notes/NotesTable";
import { OthersTab } from "./details/others/OthersTab";
import { ParentChildTabs } from "./details/parentChild/ParentChildTabs";
import { DoubleTable } from "./doubles/DoubleTable";

interface Props {
    store: OrganizationItemStore;
    style?: React.CSSProperties;
}

export const OrganizationDetails = observer(({ store, style }: Props) => {
    const { t } = useTranslation();

    const renderTabs = (index: number) => {
        switch (index) {
            case 0:
                return (
                    <AuthorizePermission permissions={AllPermissions.organization.read} showWarning>
                        <OrganizationProperties store={store.propertiesStore} style={{ flex: 1 }} />
                    </AuthorizePermission>
                )
            case 1:
                return (
                    <DoubleTable store={store.doubleStore} style={{ flex: 1 }} />
                )
            case 2:
                return (
                    <AuthorizePermission permissions={AllPermissions.organization.names.read} showWarning>
                        <OrganizationNameTable store={store.orgNamesStore} style={{ flex: 1 }} />
                    </AuthorizePermission>
                )
            case 3:
                return (
                    <AuthorizePermission permissions={AllPermissions.organization.addressunique.read} showWarning>
                        <OrganizationAddress store={store.addressesStore} />
                    </AuthorizePermission>
                )
            case 4:
                return (
                    <AuthorizePermission permissions={AllPermissions.organization.parent.read} showWarning>
                        <ParentChildTabs store={store.parentChildStore} />
                    </AuthorizePermission>
                )
            case 5:
                return <OthersTab store={store.othersStore} />;
            case 6:
                return (
                    <AuthorizePermission permissions={AllPermissions.person.read} showWarning>
                        <OrganizationEmployeesTable store={store.employeesStore} />
                    </AuthorizePermission>
                )
            case 7:
                return (
                    <NotesTable store={store.notesTable} />
                )
            default:
                return null;
        }
    };

    return (
        <Page
            style={style}
        >
            <PageTabs
                index={store.tabIndex}
                onChange={t => store.tabIndex = t}>
                <PageTab icon={<PersonParamsIcon />} title={t('common.details')} testid="organizationDetails-bdetailsounds-tabs" style={{ width: '12%' }} />
                <PageTab icon={<LayersIcon />} title={t('common.doubles')} testid="organizationDetails-doubles-tabs"
                    style={(store._dto?.status?.nameRus.toLowerCase() === 'дубль') ? { display: 'none' } : { width: '12%' }} />
                <PageTab icon={<OrgNamesIcon />} title={t('common.orgNames')} testid="organizationDetails-orgNames-tabs" style={{ width: '12%' }} />
                <PageTab icon={<OrgAddressIcon />} title={t('common.orgAddress')} testid="organizationDetails-orgAddress-tabs" style={{ width: '12%' }} />
                <PageTab icon={<OrgParentIcon />} title={t('common.orgParentChild')} testid="organizationDetails-orgParent-tabs" style={{ width: '12%' }} />
                <PageTab icon={<FolderOpenOutlineIcon />} title={t('common.other')} testid="organizationDetails-orgOthers-tabs" style={{ width: '12%' }} />
                <PageTab icon={<OrgEmployeesIcon />} title={t('common.orgEmployees')} testid="organization-orgEmployees-tabs" style={{ width: '12%' }} />
                <PageTab icon={!store.countNotes ? <OrgListIcon /> : <><OrgListIcon />{store.countNotes}</>} title={t('common.notes')} testid="organization-orgNotes-tabs" style={{ width: '12%' }} />
            </PageTabs>
            <div style={{ marginTop: 4, marginBottom: 4, padding: 15 }}>
                {renderTabs(store.tabIndex)}
            </div>
        </Page>
    );
});
