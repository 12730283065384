import {getObject, setObject} from '../../../../core';
import {ManagementStore} from "../../ManagementStore";
import {ClinicCategoryTableStore} from './tabs/ClinicСategoryTableStore';
import {TypeNameTableStore} from "./tabs/TypeNameTableStore";
import {PhoneStatusTableStore} from "./tabs/PhoneStatusTableStore";
import {PhoneTypeTableStore} from "./tabs/PhoneTypeTableStore";
import {PhoneCategoryTableStore} from "./tabs/PhoneCategoryTableStore";
import {EmailStatusTableStore} from "./tabs/EmailStatusTableStore";
import {EmailTypeTableStore} from "./tabs/EmailTypeTableStore";
import {WebsiteStatusTableStore} from "./tabs/WebsiteStatusTableStore";
import {DynamicalComponentStore} from "../../../utils";
import {TypeOrganizationTableStore} from "./tabs/TypeOrganizations/TypeOrganizationTableStore";

interface DictionaryOrganizationsStoreLocalFilters {
    tabIndex: number;
    expand: boolean;
}

export class DictionaryOrganizationsStore extends DynamicalComponentStore {
    readonly store: ManagementStore;
    readonly clinicCategoryStore: ClinicCategoryTableStore;
    readonly typeNamesStore: TypeNameTableStore;
    readonly phoneStatus: PhoneStatusTableStore;
    readonly phoneType: PhoneTypeTableStore;
    readonly phoneCategory: PhoneCategoryTableStore;
    readonly emailStatus: EmailStatusTableStore;
    readonly emailType: EmailTypeTableStore;
    readonly websiteStatus: WebsiteStatusTableStore;
    readonly typeOrganizationStore: TypeOrganizationTableStore;

    constructor(store: ManagementStore) {
        super()
        this.store = store;

        this.clinicCategoryStore = new ClinicCategoryTableStore(this);
        this.typeNamesStore = new TypeNameTableStore(this);
        this.typeOrganizationStore = new TypeOrganizationTableStore(this);
        this.phoneStatus = new PhoneStatusTableStore(this);
        this.phoneType = new PhoneTypeTableStore(this);
        this.phoneCategory = new PhoneCategoryTableStore(this);
        this.emailStatus = new EmailStatusTableStore(this);
        this.emailType = new EmailTypeTableStore(this);
        this.websiteStatus = new WebsiteStatusTableStore(this);

        const filters = getObject<DictionaryOrganizationsStoreLocalFilters>('dictionaryOrgs-store');
        if (filters) {
            this._tabIndex = filters.tabIndex;
            this.expand = filters.expand;
        }
    }


    async updateState(): Promise<void> {
        this.saveLocalFilters();

        if (this._tabIndex === 0) {
            this.clinicCategoryStore.search.reset()
        } else if (this._tabIndex === 1) {
            this.typeNamesStore.search.reset()
        } else if (this._tabIndex === 2) {
            this.typeOrganizationStore.search.reset()
        } else if (this._tabIndex === 3) {
            this.phoneStatus.search.reset()
        } else if (this._tabIndex === 4) {
            this.phoneType.search.reset()
        } else if (this._tabIndex === 5) {
            this.phoneCategory.search.reset()
        } else if (this._tabIndex === 6) {
            this.emailStatus.search.reset()
        } else if (this._tabIndex === 7) {
            this.emailType.search.reset()
        } else if (this._tabIndex === 8) {
            this.websiteStatus.search.reset()
        }
    }

    saveLocalFilters = () => {
        setObject<DictionaryOrganizationsStoreLocalFilters>('dictionaryOrgs-store', {
            tabIndex: this._tabIndex,
            expand: this.expand,
        })
    }
};
