import { makeAutoObservable } from 'mobx';
import { IOrganizationFilterState, OrganizationParentFilterVM } from '../../../../../components/select/OrganizationParent/OrganizationParentFilterVM';
import { getApiConfiguration, ILoad, IProcessing } from "../../../../../core";
import { OrganizationDto, OrganizationsApi } from '../../../../../services/management';
import { ParentChildTabsStore } from './ParentChildTabsStore';

export class OrganizationParentStore implements IProcessing, ILoad {
    readonly _store: ParentChildTabsStore;
    _dto: OrganizationDto;

    organization: OrganizationParentFilterVM | null = null;
    loading: boolean = false;

    items: OrganizationParentFilterVM[] = [];
    processing: boolean = false;

    initParent?: IOrganizationFilterState;
    hasNoParent: boolean = false;

    constructor(store: ParentChildTabsStore, dto: OrganizationDto) {
        this._store = store;
        this._dto = dto;
        this.hasNoParent = (dto.parentId === dto.id);

        makeAutoObservable(this);
    }

    equals(): boolean {
        return (this.value?.id === this._dto.parentId) || (this._dto.parentId === this._dto.id && !this.value?.id);
    }

    async pull() {
        this.initParent = undefined;
        this.loading = true;
        let item = this._dto;
        while (item.parentId !== item.id) {
            const api: OrganizationsApi = new OrganizationsApi(getApiConfiguration());
            try {
                item = await api.getOrganizationById({ id: item.parentId })
                if (item.id !== this.initParent?.id)
                    this.initParent = { ...item, ...{ organization: this.initParent } };
            }
            catch (e) {
                break;
            }
        }
        this.organization = new OrganizationParentFilterVM(this._dto, null, this.initParent)
        this.loading = false;
    }

    get value() {
        let cur = this.organization;
        if (cur?.organization?.id) {
            while (true) {
                cur = cur?.organization
                if (!cur?.organization?.id)
                    break;
            }
        }
        return cur?._value ? cur?._value : null;
    }

    reset(): void {
        this.organization = new OrganizationParentFilterVM(this._dto, null, this.initParent)
        if (!this.organization?._value) {
            this.changeHasNoParent();
        }
    }

    update(dto: OrganizationDto): void {
        this._dto = dto;
    }

    changeHasNoParent() {
        if (!!this.organization?._value) return
        this.hasNoParent = !this.hasNoParent
    }

    async submit() {
        console.log(this.value?.id, 'this.value?.id')
        this.processing = true;
        const api: OrganizationsApi = new OrganizationsApi(getApiConfiguration());
        try {
            let item = await api.updateOrganization({
                id: this._dto.id,
                updateOrganizationDto: {
                    itn: this._dto.itn.itn,
                    parentId: this.value?.id ?? this._dto.id,
                    typeOrgId: this._dto.typeOrgId,
                    formPropertyId: this._dto.formPropertyId,
                    statusId: this._dto.statusId,
                    addressUniqueId: this._dto?.addressUniqueId!
                }
            })
            this.update(item)
            this._store.store.update(item)
        } catch (e) {

        }
        if (!this.organization?._value) {
            this.changeHasNoParent();
        }
        this.processing = false;
    }

}