/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Api.Export
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {DictionaryFilter, DictionaryFilterFromJSON, DictionaryFilterToJSON,} from './DictionaryFilter';

/**
 * 
 * @export
 * @interface CommonFilter
 */
export interface CommonFilter {
    /**
     * 
     * @type {object}
     * @memberof CommonFilter
     */
    organizationFilter?: object;
    /**
     * 
     * @type {object}
     * @memberof CommonFilter
     */
    personFilter?: object;
    /**
     * 
     * @type {DictionaryFilter}
     * @memberof CommonFilter
     */
    dictionaryFilter?: DictionaryFilter;
}

export function CommonFilterFromJSON(json: any): CommonFilter {
    return CommonFilterFromJSONTyped(json, false);
}

export function CommonFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommonFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationFilter': !exists(json, 'organizationFilter') ? undefined : json['organizationFilter'],
        'personFilter': !exists(json, 'personFilter') ? undefined : json['personFilter'],
        'dictionaryFilter': !exists(json, 'dictionaryFilter') ? undefined : DictionaryFilterFromJSON(json['dictionaryFilter']),
    };
}

export function CommonFilterToJSON(value?: CommonFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationFilter': value.organizationFilter,
        'personFilter': value.personFilter,
        'dictionaryFilter': DictionaryFilterToJSON(value.dictionaryFilter),
    };
}

