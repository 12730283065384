/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NameDto,
    NameDtoFromJSON,
    NameDtoFromJSONTyped,
    NameDtoToJSON,
} from './NameDto';
import {
    OrganizationDto,
    OrganizationDtoFromJSON,
    OrganizationDtoFromJSONTyped,
    OrganizationDtoToJSON,
} from './OrganizationDto';
import {
    PersonDto,
    PersonDtoFromJSON,
    PersonDtoFromJSONTyped,
    PersonDtoToJSON,
} from './PersonDto';
import {
    PersonJobDto,
    PersonJobDtoFromJSON,
    PersonJobDtoFromJSONTyped,
    PersonJobDtoToJSON,
} from './PersonJobDto';
import {
    PersonStatusDto,
    PersonStatusDtoFromJSON,
    PersonStatusDtoFromJSONTyped,
    PersonStatusDtoToJSON,
} from './PersonStatusDto';
import {
    TagDto,
    TagDtoFromJSON,
    TagDtoFromJSONTyped,
    TagDtoToJSON,
} from './TagDto';

/**
 * 
 * @export
 * @interface PersonDepJobDto
 */
export interface PersonDepJobDto {
    /**
     * 
     * @type {string}
     * @memberof PersonDepJobDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PersonDepJobDto
     */
    externalId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PersonDepJobDto
     */
    createdAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PersonDepJobDto
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PersonDepJobDto
     */
    modifiedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PersonDepJobDto
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PersonDepJobDto
     */
    ticks?: number;
    /**
     * 
     * @type {PersonDto}
     * @memberof PersonDepJobDto
     */
    person: PersonDto;
    /**
     * 
     * @type {OrganizationDto}
     * @memberof PersonDepJobDto
     */
    organizationDep: OrganizationDto;
    /**
     * 
     * @type {OrganizationDto}
     * @memberof PersonDepJobDto
     */
    organizationSurgery?: OrganizationDto;
    /**
     * 
     * @type {NameDto}
     * @memberof PersonDepJobDto
     */
    post: NameDto;
    /**
     * 
     * @type {PersonJobDto}
     * @memberof PersonDepJobDto
     */
    personJob: PersonJobDto;
    /**
     * 
     * @type {PersonStatusDto}
     * @memberof PersonDepJobDto
     */
    status?: PersonStatusDto;
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof PersonDepJobDto
     */
    tags?: Array<TagDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PersonDepJobDto
     */
    fromDoble?: boolean;
}

export function PersonDepJobDtoFromJSON(json: any): PersonDepJobDto {
    return PersonDepJobDtoFromJSONTyped(json, false);
}

export function PersonDepJobDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonDepJobDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (json['modifiedAt'] === null ? null : new Date(json['modifiedAt'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'ticks': !exists(json, 'ticks') ? undefined : json['ticks'],
        'person': PersonDtoFromJSON(json['person']),
        'organizationDep': OrganizationDtoFromJSON(json['organizationDep']),
        'organizationSurgery': !exists(json, 'organizationSurgery') ? undefined : OrganizationDtoFromJSON(json['organizationSurgery']),
        'post': NameDtoFromJSON(json['post']),
        'personJob': PersonJobDtoFromJSON(json['personJob']),
        'status': !exists(json, 'status') ? undefined : PersonStatusDtoFromJSON(json['status']),
        'tags': !exists(json, 'tags') ? undefined : (json['tags'] === null ? null : (json['tags'] as Array<any>).map(TagDtoFromJSON)),
        'fromDoble': !exists(json, 'fromDoble') ? undefined : json['fromDoble'],
    };
}

export function PersonDepJobDtoToJSON(value?: PersonDepJobDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalId': value.externalId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt === null ? null : value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt === null ? null : value.modifiedAt.toISOString()),
        'modifiedBy': value.modifiedBy,
        'ticks': value.ticks,
        'person': PersonDtoToJSON(value.person),
        'organizationDep': OrganizationDtoToJSON(value.organizationDep),
        'organizationSurgery': OrganizationDtoToJSON(value.organizationSurgery),
        'post': NameDtoToJSON(value.post),
        'personJob': PersonJobDtoToJSON(value.personJob),
        'status': PersonStatusDtoToJSON(value.status),
        'tags': value.tags === undefined ? undefined : (value.tags === null ? null : (value.tags as Array<any>).map(TagDtoToJSON)),
        'fromDoble': value.fromDoble,
    };
}

