/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    ListSortDirection,
    OCMObjectState,
    SetSpecialityDto,
    SetSpecialityDtoToJSON,
    SpecialityDto,
    SpecialityDtoFromJSON,
} from '../models';

export interface CreateSpecialityRequest {
    setSpecialityDto: SetSpecialityDto;
}

export interface DeleteSpecialityRequest {
    id: string;
}

export interface GetSpecialitiesRequest {
    search?: string;
    searchByExternalId?: number;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    showOnlyActive?: boolean;
}

export interface GetSpecialitiesCountRequest {
    searchByExternalId?: number;
    search?: string;
    showOnlyActive?: boolean;
}

export interface GetSpecialitiesCountFallbackRequest {
    searchByExternalId?: number;
    search?: string;
    states?: Array<OCMObjectState>;
    showOnlyActive?: boolean;
}

export interface GetSpecialitiesFallbackRequest {
    search?: string;
    searchByExternalId?: number;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
    showOnlyActive?: boolean;
}

export interface GetSpecialityByIdRequest {
    id: string;
}

export interface GetSpecialityByNameRequest {
    name: string;
}

export interface UpdateSpecialityRequest {
    id: string;
    setSpecialityDto: SetSpecialityDto;
}

/**
 * 
 */
export class SpecialitiesApi extends runtime.BaseAPI {

    /**
     */
    async createSpecialityRaw(requestParameters: CreateSpecialityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SpecialityDto>> {
        if (requestParameters.setSpecialityDto === null || requestParameters.setSpecialityDto === undefined) {
            throw new runtime.RequiredError('setSpecialityDto','Required parameter requestParameters.setSpecialityDto was null or undefined when calling createSpeciality.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/specialities`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetSpecialityDtoToJSON(requestParameters.setSpecialityDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SpecialityDtoFromJSON(jsonValue));
    }

    /**
     */
    async createSpeciality(requestParameters: CreateSpecialityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SpecialityDto> {
        const response = await this.createSpecialityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteSpecialityRaw(requestParameters: DeleteSpecialityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SpecialityDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSpeciality.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/specialities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SpecialityDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteSpeciality(requestParameters: DeleteSpecialityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SpecialityDto> {
        const response = await this.deleteSpecialityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSpecialitiesRaw(requestParameters: GetSpecialitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<SpecialityDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.showOnlyActive !== undefined) {
            queryParameters['showOnlyActive'] = requestParameters.showOnlyActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/specialities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpecialityDtoFromJSON));
    }

    /**
     */
    async getSpecialities(requestParameters: GetSpecialitiesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<SpecialityDto>> {
        const response = await this.getSpecialitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSpecialitiesCountRaw(requestParameters: GetSpecialitiesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.showOnlyActive !== undefined) {
            queryParameters['showOnlyActive'] = requestParameters.showOnlyActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/specialities/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getSpecialitiesCount(requestParameters: GetSpecialitiesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getSpecialitiesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSpecialitiesCountFallbackRaw(requestParameters: GetSpecialitiesCountFallbackRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        if (requestParameters.showOnlyActive !== undefined) {
            queryParameters['showOnlyActive'] = requestParameters.showOnlyActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/specialities/count/fallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getSpecialitiesCountFallback(requestParameters: GetSpecialitiesCountFallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getSpecialitiesCountFallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSpecialitiesFallbackRaw(requestParameters: GetSpecialitiesFallbackRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<SpecialityDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        if (requestParameters.showOnlyActive !== undefined) {
            queryParameters['showOnlyActive'] = requestParameters.showOnlyActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/specialities/fallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpecialityDtoFromJSON));
    }

    /**
     */
    async getSpecialitiesFallback(requestParameters: GetSpecialitiesFallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<SpecialityDto>> {
        const response = await this.getSpecialitiesFallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSpecialityByIdRaw(requestParameters: GetSpecialityByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SpecialityDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSpecialityById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/specialities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SpecialityDtoFromJSON(jsonValue));
    }

    /**
     */
    async getSpecialityById(requestParameters: GetSpecialityByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SpecialityDto> {
        const response = await this.getSpecialityByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSpecialityByNameRaw(requestParameters: GetSpecialityByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SpecialityDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getSpecialityByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/specialities/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SpecialityDtoFromJSON(jsonValue));
    }

    /**
     */
    async getSpecialityByName(requestParameters: GetSpecialityByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SpecialityDto> {
        const response = await this.getSpecialityByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateSpecialityRaw(requestParameters: UpdateSpecialityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SpecialityDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSpeciality.');
        }

        if (requestParameters.setSpecialityDto === null || requestParameters.setSpecialityDto === undefined) {
            throw new runtime.RequiredError('setSpecialityDto','Required parameter requestParameters.setSpecialityDto was null or undefined when calling updateSpeciality.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/specialities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetSpecialityDtoToJSON(requestParameters.setSpecialityDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SpecialityDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateSpeciality(requestParameters: UpdateSpecialityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SpecialityDto> {
        const response = await this.updateSpecialityRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
