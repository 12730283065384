import {action, computed, makeObservable} from 'mobx';
import {getApiConfiguration, getObject, IProcessing, setObject, toDateTimeString} from '../../../../core';
import {ImportFile, ImportFilesApi, ImportManagementApi} from '../../../../services/import';
import {ImportTableStore, ImportType} from './ImportTableStore';
import {downloadFile} from "../../../../core/blob";
import {DynamicalComponentStore} from "../../../utils";

interface ViewModelLocalFilters {
    expand: boolean;
}

export class ImportItemStore extends DynamicalComponentStore implements IProcessing {
    readonly _store: ImportTableStore;
    key: ImportType;
    dto: ImportFile
    processing: boolean = false;

    constructor(key: ImportType, store: ImportTableStore, dto: ImportFile) {
        super();

        this.dto = dto;
        this._store = store;
        this.key = key;

        const filters = getObject<ViewModelLocalFilters>(`import${key}-item-${this.id}`);
        if (filters) {
            this._expand = filters.expand;
        }

        makeObservable(this, {
            modifiedAt: computed,
            status: computed,
            success: computed,
            fileName: computed,
            id: computed,
            downloadPreImportReport: action,
            downloadAfterImportFile: action,
            importSuccessfulRecorders: action
        });
    }

    get modifiedAt() {
        if (this.dto.updateDate) {
            return toDateTimeString(this.dto.updateDate);
        }
        return '';
    }

    get status() {
        return this.dto.status ?? 0;
    }

    get success() {
        return this.dto.rowsCount ? (this.dto.rowsCount - (this.dto?.errorsRowCount ?? 0)) : null
    }

    get fileName() {
        return this.dto?.name ?? '<unspecified>';
    }

    get id() {
        return this.dto.id || ''
    }

    async updateState(): Promise<void> {
        this.saveFilters();
    }

    update(dto: ImportFile) {
        this.dto = dto;
    }

    async downloadPreImportReport(): Promise<void> {
        await new ImportManagementApi(getApiConfiguration()).downloadPreImportReport({fileId: this.id})
            .then(async x => {
                await downloadFile(await x.blob(),
                    x.headers?.get('Content-Disposition')?.split('filename=')[1]?.split(';')[0] ??
                    `Import${this.key}PreReport_${Date.now()}.csv`)
            });
    }

    async downloadAfterImportFile(): Promise<void> {
        await new ImportManagementApi(getApiConfiguration()).downloadAfterImportFile({fileId: this.id})
            .then(async x => {
                await downloadFile(await x.blob(),
                    x.headers?.get('Content-Disposition')?.split('filename=')[1]?.split(';')[0] ??
                    `Import${this.key}AfterReport_${Date.now()}.csv`)
            });
    }

    async importSuccessfulRecorders(): Promise<void> {
        const api: ImportFilesApi = new ImportFilesApi(getApiConfiguration());
        const result = this.key == "Persons" ?
            await api.importPersonsToDb({importFileId: this.id}) :
            await api.importOrganizationsToDb({importFileId: this.id});

        this._store.pullItem(new ImportItemStore(this.key, this._store, result[0]))
    };

    saveFilters() {
        setObject<ViewModelLocalFilters>(`import${this.key}-item-${this.id}`, {
            expand: this._expand
        });
    }

    async pull() {
        await this._store.pull()
    }
}
