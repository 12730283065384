/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
/**
 * 
 * @export
 * @interface ClinicCategoryDto
 */
export interface ClinicCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof ClinicCategoryDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicCategoryDto
     */
    externalId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ClinicCategoryDto
     */
    createdAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ClinicCategoryDto
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ClinicCategoryDto
     */
    modifiedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ClinicCategoryDto
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClinicCategoryDto
     */
    ticks?: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicCategoryDto
     */
    nameRus: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicCategoryDto
     */
    nameEng?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicCategoryDto
     */
    poweredByElastic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicCategoryDto
     */
    isInactive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClinicCategoryDto
     */
    jurisdictionId?: string | null;
}

export function ClinicCategoryDtoFromJSON(json: any): ClinicCategoryDto {
    return ClinicCategoryDtoFromJSONTyped(json, false);
}

export function ClinicCategoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicCategoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (json['modifiedAt'] === null ? null : new Date(json['modifiedAt'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'ticks': !exists(json, 'ticks') ? undefined : json['ticks'],
        'nameRus': json['nameRus'],
        'nameEng': !exists(json, 'nameEng') ? undefined : json['nameEng'],
        'poweredByElastic': !exists(json, 'poweredByElastic') ? undefined : json['poweredByElastic'],
        'isInactive': !exists(json, 'isInactive') ? undefined : json['isInactive'],
        'jurisdictionId': !exists(json, 'jurisdictionId') ? undefined : json['jurisdictionId'],
    };
}

export function ClinicCategoryDtoToJSON(value?: ClinicCategoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalId': value.externalId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt === null ? null : value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt === null ? null : value.modifiedAt.toISOString()),
        'modifiedBy': value.modifiedBy,
        'ticks': value.ticks,
        'nameRus': value.nameRus,
        'nameEng': value.nameEng,
        'poweredByElastic': value.poweredByElastic,
        'isInactive': value.isInactive,
        'jurisdictionId': value.jurisdictionId,
    };
}

