/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    NameDto,
    NameDtoFromJSON,
    NameDtoFromJSONTyped,
    NameDtoToJSON,
} from './NameDto';
import {
    OrganizationDto,
    OrganizationDtoFromJSON,
    OrganizationDtoFromJSONTyped,
    OrganizationDtoToJSON,
} from './OrganizationDto';
import {
    PhoneDto,
    PhoneDtoFromJSON,
    PhoneDtoFromJSONTyped,
    PhoneDtoToJSON,
} from './PhoneDto';

/**
 * 
 * @export
 * @interface OrganizationPhoneDto
 */
export interface OrganizationPhoneDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationPhoneDto
     */
    id: string;
    /**
     * 
     * @type {PhoneDto}
     * @memberof OrganizationPhoneDto
     */
    phone: PhoneDto;
    /**
     * 
     * @type {OrganizationDto}
     * @memberof OrganizationPhoneDto
     */
    organization: OrganizationDto;
    /**
     * 
     * @type {NameDto}
     * @memberof OrganizationPhoneDto
     */
    phoneStatus: NameDto;
    /**
     * 
     * @type {NameDto}
     * @memberof OrganizationPhoneDto
     */
    phoneCategory: NameDto;
    /**
     * 
     * @type {NameDto}
     * @memberof OrganizationPhoneDto
     */
    phoneType: NameDto;
    /**
     * 
     * @type {string}
     * @memberof OrganizationPhoneDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationPhoneDto
     */
    additionalNumber?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationPhoneDto
     */
    createdAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationPhoneDto
     */
    modifiedAt?: Date | null;
}

export function OrganizationPhoneDtoFromJSON(json: any): OrganizationPhoneDto {
    return OrganizationPhoneDtoFromJSONTyped(json, false);
}

export function OrganizationPhoneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationPhoneDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'phone': PhoneDtoFromJSON(json['phone']),
        'organization': OrganizationDtoFromJSON(json['organization']),
        'phoneStatus': NameDtoFromJSON(json['phoneStatus']),
        'phoneCategory': NameDtoFromJSON(json['phoneCategory']),
        'phoneType': NameDtoFromJSON(json['phoneType']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'additionalNumber': !exists(json, 'additionalNumber') ? undefined : json['additionalNumber'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (json['modifiedAt'] === null ? null : new Date(json['modifiedAt'])),
    };
}

export function OrganizationPhoneDtoToJSON(value?: OrganizationPhoneDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'phone': PhoneDtoToJSON(value.phone),
        'organization': OrganizationDtoToJSON(value.organization),
        'phoneStatus': NameDtoToJSON(value.phoneStatus),
        'phoneCategory': NameDtoToJSON(value.phoneCategory),
        'phoneType': NameDtoToJSON(value.phoneType),
        'description': value.description,
        'additionalNumber': value.additionalNumber,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt === null ? null : value.createdAt.toISOString()),
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt === null ? null : value.modifiedAt.toISOString()),
    };
}

