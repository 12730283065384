/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Api.Import
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ImportStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;
export type ImportStatus = typeof ImportStatus[keyof typeof ImportStatus];


export function ImportStatusFromJSON(json: any): ImportStatus {
    return ImportStatusFromJSONTyped(json, false);
}

export function ImportStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportStatus {
    return json as ImportStatus;
}

export function ImportStatusToJSON(value?: ImportStatus | null): any {
    return value as any;
}

