/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OCMObjectState,
    OCMObjectStateFromJSON,
    OCMObjectStateToJSON,
    PhoneStatusDto,
    PhoneStatusDtoFromJSON,
    PhoneStatusDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetPhoneStatusDto,
    SetPhoneStatusDtoFromJSON,
    SetPhoneStatusDtoToJSON,
} from '../models';

export interface CreateOrganizationPhoneStatusRequest {
    setPhoneStatusDto: SetPhoneStatusDto;
}

export interface DeleteOrganizationPhoneStatusRequest {
    id: string;
}

export interface GetOrganizationPhoneStatusByIdRequest {
    id: string;
}

export interface GetOrganizationPhoneStatusByNameRequest {
    name: string;
}

export interface GetOrganizationPhoneStatusesRequest {
    search?: string;
    orderBy?: string;
    searchByExternalId?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface GetOrganizationPhoneStatusesCountRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface UpdateOrganizationPhoneStatusRequest {
    id: string;
    setPhoneStatusDto: SetPhoneStatusDto;
}

/**
 * 
 */
export class OrganizationPhoneStatusesApi extends runtime.BaseAPI {

    /**
     */
    async createOrganizationPhoneStatusRaw(requestParameters: CreateOrganizationPhoneStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneStatusDto>> {
        if (requestParameters.setPhoneStatusDto === null || requestParameters.setPhoneStatusDto === undefined) {
            throw new runtime.RequiredError('setPhoneStatusDto','Required parameter requestParameters.setPhoneStatusDto was null or undefined when calling createOrganizationPhoneStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/phones/statuses/organization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPhoneStatusDtoToJSON(requestParameters.setPhoneStatusDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async createOrganizationPhoneStatus(requestParameters: CreateOrganizationPhoneStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneStatusDto> {
        const response = await this.createOrganizationPhoneStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteOrganizationPhoneStatusRaw(requestParameters: DeleteOrganizationPhoneStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOrganizationPhoneStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/statuses/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteOrganizationPhoneStatus(requestParameters: DeleteOrganizationPhoneStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneStatusDto> {
        const response = await this.deleteOrganizationPhoneStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationPhoneStatusByIdRaw(requestParameters: GetOrganizationPhoneStatusByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrganizationPhoneStatusById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/statuses/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationPhoneStatusById(requestParameters: GetOrganizationPhoneStatusByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneStatusDto> {
        const response = await this.getOrganizationPhoneStatusByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationPhoneStatusByNameRaw(requestParameters: GetOrganizationPhoneStatusByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneStatusDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getOrganizationPhoneStatusByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/statuses/organization/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationPhoneStatusByName(requestParameters: GetOrganizationPhoneStatusByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneStatusDto> {
        const response = await this.getOrganizationPhoneStatusByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationPhoneStatusesRaw(requestParameters: GetOrganizationPhoneStatusesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PhoneStatusDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/statuses/organization`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PhoneStatusDtoFromJSON));
    }

    /**
     */
    async getOrganizationPhoneStatuses(requestParameters: GetOrganizationPhoneStatusesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PhoneStatusDto>> {
        const response = await this.getOrganizationPhoneStatusesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationPhoneStatusesCountRaw(requestParameters: GetOrganizationPhoneStatusesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/statuses/organization/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationPhoneStatusesCount(requestParameters: GetOrganizationPhoneStatusesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getOrganizationPhoneStatusesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrganizationPhoneStatusRaw(requestParameters: UpdateOrganizationPhoneStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrganizationPhoneStatus.');
        }

        if (requestParameters.setPhoneStatusDto === null || requestParameters.setPhoneStatusDto === undefined) {
            throw new runtime.RequiredError('setPhoneStatusDto','Required parameter requestParameters.setPhoneStatusDto was null or undefined when calling updateOrganizationPhoneStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/phones/statuses/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetPhoneStatusDtoToJSON(requestParameters.setPhoneStatusDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateOrganizationPhoneStatus(requestParameters: UpdateOrganizationPhoneStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneStatusDto> {
        const response = await this.updateOrganizationPhoneStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
