/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    ListSortDirection,
    PersonWebsiteDto,
    PersonWebsiteDtoFromJSON,
    RemovePersonWebsiteDto,
    RemovePersonWebsiteDtoToJSON,
    SetPersonWebsiteDto,
    SetPersonWebsiteDtoToJSON,
    UpdatePersonWebsiteDto,
    UpdatePersonWebsiteDtoToJSON,
} from '../models';

export interface GetPersonWebsiteByIdRequest {
    id: string;
}

export interface GetPersonWebsitesRequest {
    search?: string;
    personId?: string;
    description?: string;
    websiteStatusIds?: Array<string>;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetPersonWebsitesCountRequest {
    search?: string;
    description?: string;
    websiteStatusIds?: Array<string>;
    personId?: string;
}

export interface RemovePersonWebsitesRequest {
    personId: string;
    removePersonWebsiteDto: Array<RemovePersonWebsiteDto>;
}

export interface SetPersonWebsitesRequest {
    personId: string;
    setPersonWebsiteDto: Array<SetPersonWebsiteDto>;
}

export interface UpdatePersonWebsiteRequest {
    id: string;
    updatePersonWebsiteDto: UpdatePersonWebsiteDto;
}

/**
 * 
 */
export class PersonWebsitesApi extends runtime.BaseAPI {

    /**
     */
    async getPersonWebsiteByIdRaw(requestParameters: GetPersonWebsiteByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonWebsiteDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPersonWebsiteById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/Persons/websites/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonWebsiteDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonWebsiteById(requestParameters: GetPersonWebsiteByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonWebsiteDto> {
        const response = await this.getPersonWebsiteByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonWebsitesRaw(requestParameters: GetPersonWebsitesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonWebsiteDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.websiteStatusIds) {
            queryParameters['websiteStatusIds'] = requestParameters.websiteStatusIds;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/Persons/websites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonWebsiteDtoFromJSON));
    }

    /**
     */
    async getPersonWebsites(requestParameters: GetPersonWebsitesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonWebsiteDto>> {
        const response = await this.getPersonWebsitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonWebsitesCountRaw(requestParameters: GetPersonWebsitesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.websiteStatusIds) {
            queryParameters['websiteStatusIds'] = requestParameters.websiteStatusIds;
        }

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/Persons/websites/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonWebsitesCount(requestParameters: GetPersonWebsitesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPersonWebsitesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removePersonWebsitesRaw(requestParameters: RemovePersonWebsitesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonWebsiteDto>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling removePersonWebsites.');
        }

        if (requestParameters.removePersonWebsiteDto === null || requestParameters.removePersonWebsiteDto === undefined) {
            throw new runtime.RequiredError('removePersonWebsiteDto','Required parameter requestParameters.removePersonWebsiteDto was null or undefined when calling removePersonWebsites.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['PersonId'] = requestParameters.personId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/Persons/websites/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.removePersonWebsiteDto.map(RemovePersonWebsiteDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonWebsiteDtoFromJSON));
    }

    /**
     */
    async removePersonWebsites(requestParameters: RemovePersonWebsitesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonWebsiteDto>> {
        const response = await this.removePersonWebsitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setPersonWebsitesRaw(requestParameters: SetPersonWebsitesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonWebsiteDto>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling setPersonWebsites.');
        }

        if (requestParameters.setPersonWebsiteDto === null || requestParameters.setPersonWebsiteDto === undefined) {
            throw new runtime.RequiredError('setPersonWebsiteDto','Required parameter requestParameters.setPersonWebsiteDto was null or undefined when calling setPersonWebsites.');
        }

        const queryParameters: any = {};

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/Persons/websites/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setPersonWebsiteDto.map(SetPersonWebsiteDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonWebsiteDtoFromJSON));
    }

    /**
     */
    async setPersonWebsites(requestParameters: SetPersonWebsitesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonWebsiteDto>> {
        const response = await this.setPersonWebsitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePersonWebsiteRaw(requestParameters: UpdatePersonWebsiteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PersonWebsiteDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePersonWebsite.');
        }

        if (requestParameters.updatePersonWebsiteDto === null || requestParameters.updatePersonWebsiteDto === undefined) {
            throw new runtime.RequiredError('updatePersonWebsiteDto','Required parameter requestParameters.updatePersonWebsiteDto was null or undefined when calling updatePersonWebsite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/Persons/websites/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePersonWebsiteDtoToJSON(requestParameters.updatePersonWebsiteDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonWebsiteDtoFromJSON(jsonValue));
    }

    /**
     */
    async updatePersonWebsite(requestParameters: UpdatePersonWebsiteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PersonWebsiteDto> {
        const response = await this.updatePersonWebsiteRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
