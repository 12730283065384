import {getToken} from "./http";
import {jurisdictionId} from "../stores/authorization/AuthorizationStore";

export const downloadFile = async (blob: Blob, name: string) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
}

export const getBlobRequestSettings = () => {
    return {
        headers: {
            contentType: 'multipart/form-data',
            'Authorization': `Bearer ${getToken()}`,
            Jurisdiction: jurisdictionId
        }
    };
}
