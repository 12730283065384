/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    JurisdictionDto,
    JurisdictionDtoFromJSON,
    JurisdictionDtoToJSON,
    JurisdictionLocalSettingDto,
    JurisdictionLocalSettingDtoFromJSON,
    JurisdictionLocalSettingDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OCMObjectState,
    OCMObjectStateFromJSON,
    OCMObjectStateToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetJurisdictionDto,
    SetJurisdictionDtoFromJSON,
    SetJurisdictionDtoToJSON,
} from '../models';

export interface CreateJurisdictionRequest {
    setJurisdictionDto: SetJurisdictionDto;
}

export interface DeleteJurisdictionRequest {
    id: string;
}

export interface GetJurisdictionByIdRequest {
    id: string;
}

export interface GetJurisdictionByNameRequest {
    name: string;
}

export interface GetJurisdictionsRequest {
    search?: string;
    orderBy?: string;
    searchByExternalId?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface GetJurisdictionsCountRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface UpdateJurisdictionRequest {
    id: string;
    setJurisdictionDto: SetJurisdictionDto;
}

/**
 * 
 */
export class JurisdictionsApi extends runtime.BaseAPI {

    /**
     */
    async createJurisdictionRaw(requestParameters: CreateJurisdictionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<JurisdictionDto>> {
        if (requestParameters.setJurisdictionDto === null || requestParameters.setJurisdictionDto === undefined) {
            throw new runtime.RequiredError('setJurisdictionDto','Required parameter requestParameters.setJurisdictionDto was null or undefined when calling createJurisdiction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/jurisdiction`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetJurisdictionDtoToJSON(requestParameters.setJurisdictionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JurisdictionDtoFromJSON(jsonValue));
    }

    /**
     */
    async createJurisdiction(requestParameters: CreateJurisdictionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<JurisdictionDto> {
        const response = await this.createJurisdictionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteJurisdictionRaw(requestParameters: DeleteJurisdictionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<JurisdictionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteJurisdiction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jurisdiction/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JurisdictionDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteJurisdiction(requestParameters: DeleteJurisdictionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<JurisdictionDto> {
        const response = await this.deleteJurisdictionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getJurisdictionByIdRaw(requestParameters: GetJurisdictionByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<JurisdictionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJurisdictionById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jurisdiction/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JurisdictionDtoFromJSON(jsonValue));
    }

    /**
     */
    async getJurisdictionById(requestParameters: GetJurisdictionByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<JurisdictionDto> {
        const response = await this.getJurisdictionByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getJurisdictionByNameRaw(requestParameters: GetJurisdictionByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<JurisdictionDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getJurisdictionByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jurisdiction/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JurisdictionDtoFromJSON(jsonValue));
    }

    /**
     */
    async getJurisdictionByName(requestParameters: GetJurisdictionByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<JurisdictionDto> {
        const response = await this.getJurisdictionByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getJurisdictionSettingsByJurisdictionIdRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<JurisdictionLocalSettingDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jurisdiction/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JurisdictionLocalSettingDtoFromJSON(jsonValue));
    }

    /**
     */
    async getJurisdictionSettingsByJurisdictionId(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<JurisdictionLocalSettingDto> {
        const response = await this.getJurisdictionSettingsByJurisdictionIdRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getJurisdictionsRaw(requestParameters: GetJurisdictionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<JurisdictionDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jurisdiction`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JurisdictionDtoFromJSON));
    }

    /**
     */
    async getJurisdictions(requestParameters: GetJurisdictionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<JurisdictionDto>> {
        const response = await this.getJurisdictionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getJurisdictionsCountRaw(requestParameters: GetJurisdictionsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/jurisdiction/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getJurisdictionsCount(requestParameters: GetJurisdictionsCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getJurisdictionsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateJurisdictionRaw(requestParameters: UpdateJurisdictionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<JurisdictionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateJurisdiction.');
        }

        if (requestParameters.setJurisdictionDto === null || requestParameters.setJurisdictionDto === undefined) {
            throw new runtime.RequiredError('setJurisdictionDto','Required parameter requestParameters.setJurisdictionDto was null or undefined when calling updateJurisdiction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/jurisdiction/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetJurisdictionDtoToJSON(requestParameters.setJurisdictionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JurisdictionDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateJurisdiction(requestParameters: UpdateJurisdictionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<JurisdictionDto> {
        const response = await this.updateJurisdictionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
