/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const QueryType = {
    List: 'list',
    Tree: 'tree'
} as const;
export type QueryType = typeof QueryType[keyof typeof QueryType];


export function QueryTypeFromJSON(json: any): QueryType {
    return QueryTypeFromJSONTyped(json, false);
}

export function QueryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryType {
    return json as QueryType;
}

export function QueryTypeToJSON(value?: QueryType | null): any {
    return value as any;
}

