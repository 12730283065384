import { makeAutoObservable } from 'mobx';
import { getApiConfiguration } from "../../../core";
import {
    OrganizationDto,
    OrganizationsApi,
    QueryType,
} from '../../../services/management';
import { getOrgName } from "../../../stores";

export interface IOrganizationFilterState extends OrganizationDto {
    organization?: IOrganizationFilterState;
}
export class OrganizationParentFilterVM {
    items: OrganizationDto[] = [];
    _value: OrganizationDto | null = null;
    loading: boolean = false;
    organization: OrganizationParentFilterVM | null | undefined;
    _search: string = '';
    _store: OrganizationParentFilterVM | undefined | null;
    _organizationDto: OrganizationDto | null;

    constructor(organizationDto: OrganizationDto | null, store?: OrganizationParentFilterVM | null, state?: IOrganizationFilterState) {


        this._store = store;
        this._organizationDto = organizationDto;

        if (state) {
            this._value = state
            if (state.organization) {
                this.organization = new OrganizationParentFilterVM(organizationDto, this, state.organization)
            }
        }

        makeAutoObservable(this);
    }

    add() {
        this.organization = new OrganizationParentFilterVM(this._organizationDto, this);
    }

    set value(dto: OrganizationDto | null) {
        if (!dto || (this._value && dto.id !== this._value?.id)) {
            this.organization = null;
        }
        this._value = dto;
    }


    get value() {
        return this._value;
    }

    get id(): string {
        if (this._value) {
            return this._value.id;
        }

        return ''
    }

    get name(): string | undefined {
        if (this._value) {
            return getOrgName(this._value)
        }

        return ''
    }

    get parent(): boolean {
        return this._value?.parentId === this._value?.id;
    }

    delete() {
        if (this._store) {
            this._store.organization = null
        }
    }

    async setSearch(value: string): Promise<void> {
        // if (this._value) return //если выбрали селектом - не искать
        this._search = value;
        await this.pull();
    }

    async pull(): Promise<void> {
        this.loading = true;
        let filters: {};
        if (new RegExp(/^\w{8}.\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/).test(this._search)) {
            filters = {
                page: 1,
                size: 100,
                searchById: this._search,
                queryType: QueryType.Tree,
                parentId: this._store?.id,
            };
        } else {
            filters = {
                page: 1,
                size: 25,
                search: this._search,
                queryType: QueryType.Tree,
                parentId: this._store?.id,
            }
        };
        const api: OrganizationsApi = new OrganizationsApi(getApiConfiguration());
        this.items = (await api.getOrganizations(filters)).filter(x => x.id != this._organizationDto?.id);;
        this.loading = false;
    };
}
