import {getApiConfiguration} from "../../../../../core";
import {BoundDto, TypeLocalitiesApi, TypeLocalityDto,} from "../../../../../services/management";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from "../../../../../components/shared/DefaultSelectStore";
import {MappingTableStore} from "./MappingTableStore";
import {MappingsStore} from "./MappingsStore";
import {DefaultFormModalStore} from "../../../../../components/shared/DefaultFormModalStore";
import {getAppoloClient, GRAPHQL_URLS} from "../../../../../graphql";
import {
    RemoveTypeLocalityMappingMutation,
    RemoveTypeLocalityMappingMutationVariables,
    SetTypeLocalityMappingMutation,
    SetTypeLocalityMappingMutationVariables,
    TypeLocalityMappingFragment,
    TypeLocalityMappingsQuery,
    TypeLocalityMappingsQueryVariables,
} from "../../../../../gql/graphql";
import {TYPE_LOCALITY_MAPPING_QUERY} from "../../../../../graphql/queries.graphql";
import {
    REMOVE_TYPE_LOCALITY_MAPPING_MUTATION,
    SET_TYPE_LOCALITY_MAPPING_MUTATION
} from "../../../../../graphql/mutations.graphql";
import {jurisdictionId} from "../../../../authorization/AuthorizationStore";

export class TypeLocalityTableStore extends MappingTableStore<TypeLocalityMappingFragment> {
    constructor(store: MappingsStore, dto: BoundDto) {
        super(store, dto, 'client-mappings-typeOrg-store');

        this.creator.fields.entity.defaultSort = true;
        this.creator.fields.entity.required = true;
        this.creator.fields.value.required = true;
    }

    creator: DefaultFormModalStore<{ value: DefaultValueStore<string>; entity: DefaultSelectStore<any> }> =
        new DefaultFormModalStore({
            entity: new DefaultSelectStore<TypeLocalityDto>(
                null, (x) => new TypeLocalitiesApi(getApiConfiguration()).getTypeLocalities({
                    ...x,
                    orderBy: 'name_rus',
                    order: 'ascending'
                })),
            value: new DefaultValueStore<string>("")
        }, (model) => this.create());

    async getMappings(filters: any): Promise<{
        items: { item: TypeLocalityMappingFragment; name: string }[];
        count: number
    }> {
        const {data} = await getAppoloClient(GRAPHQL_URLS.MAPPINGS).query<TypeLocalityMappingsQuery, TypeLocalityMappingsQueryVariables>({
            query: TYPE_LOCALITY_MAPPING_QUERY, variables: filters,
        })

        return {
            items: (data.typeLocalityMappings?.items ?? []).map(x => ({item: x, name: x.names?.nameRus ?? ""})),
            count: data.typeLocalityMappings?.totalCount ?? 0
        }
    }

    async removeMapping(filter: {
        boundId: string;
        setMappingDto: Array<{ clientValue: string; entityId: string }>
    }): Promise<void> {
        for (let val of filter.setMappingDto) {
            await getAppoloClient(GRAPHQL_URLS.MAPPINGS).mutate<RemoveTypeLocalityMappingMutation, RemoveTypeLocalityMappingMutationVariables>({
                mutation: REMOVE_TYPE_LOCALITY_MAPPING_MUTATION, variables: {
                    clientId: val.clientValue,
                    entityId: val.entityId,
                    jurisdictionId: jurisdictionId
                }
            })
        }
    }

    async setMapping(filter: {
        boundId: string;
        setMappingDto: Array<{ clientValue: string; entityId: string }>
    }): Promise<{ item: TypeLocalityMappingFragment; name: string }[]> {
        const list: { item: TypeLocalityMappingFragment; name: string }[] = [];
        for (let val of filter.setMappingDto) {
            const item = await getAppoloClient(GRAPHQL_URLS.MAPPINGS).mutate<SetTypeLocalityMappingMutation, SetTypeLocalityMappingMutationVariables>({
                mutation: SET_TYPE_LOCALITY_MAPPING_MUTATION, variables: {
                    entityId: val.entityId!,
                    clientValue: val.clientValue,
                    clientId: filter.boundId,
                    jurisdictionId: jurisdictionId
                }
            })
            list.push(({
                item: item.data?.setTypeLocalityMapping!,
                name: item.data?.setTypeLocalityMapping.names?.nameRus ?? ""
            }))
        }
        return list;
    }
}
