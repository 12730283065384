import {observer} from "mobx-react";
import {Box} from "@mui/material";
import React from "react";
import {AuthorizePermission, FormRoot, ParamsButtonGroup} from "../../../../../components";
import {useTranslation} from "react-i18next";
import {callTheProcessingFunc, StoreProps, StyleProps} from "../../../../../stores";
import {DefaultMultiSelect} from "../../../../../components/shared/components/DefaultMultiSelect";
import {UsersTabStore} from "../../../../../stores/management/users/tabs/Roles/tabs/UsersTabStore";
import {AllPermissions} from "../../../../../core/const";

export const UsersTab = observer(({store, style}: StoreProps<UsersTabStore> & StyleProps) => {
    const {t} = useTranslation();

    return (
        <FormRoot style={style}>

            <DefaultMultiSelect store={store.users}
                                title={t('usersPage.roles.tabs.users')}
                                valueExp="id"
                                nameExp="name"
                                tags={true}
            />

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                 <AuthorizePermission permissions={AllPermissions.role.update}>
                    <ParamsButtonGroup
                        onCancel={() => store.users.reset()}
                        onSave={async () => await callTheProcessingFunc(store, (e) => store.save(), async (e) => await store.store._store.pull())()}
                        isCancelBtnDisabled={store.processing || store.users.equals}
                        isSaveBtnDisabled={store.processing || store.users.equals || !store.users.valid}
                    />
                    </AuthorizePermission>
            </Box>
        </FormRoot>
    );
});
