/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    BoundDto,
    BoundDtoFromJSON,
    BoundDtoFromJSONTyped,
    BoundDtoToJSON,
} from './BoundDto';
import {
    TypeOrgDto,
    TypeOrgDtoFromJSON,
    TypeOrgDtoFromJSONTyped,
    TypeOrgDtoToJSON,
} from './TypeOrgDto';

/**
 * 
 * @export
 * @interface BoundTypeOrgDto
 */
export interface BoundTypeOrgDto {
    /**
     * 
     * @type {string}
     * @memberof BoundTypeOrgDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BoundTypeOrgDto
     */
    externalId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof BoundTypeOrgDto
     */
    createdAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof BoundTypeOrgDto
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof BoundTypeOrgDto
     */
    modifiedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof BoundTypeOrgDto
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BoundTypeOrgDto
     */
    ticks?: number;
    /**
     * 
     * @type {string}
     * @memberof BoundTypeOrgDto
     */
    typeOrgId: string;
    /**
     * 
     * @type {TypeOrgDto}
     * @memberof BoundTypeOrgDto
     */
    typeOrg: TypeOrgDto;
    /**
     * 
     * @type {string}
     * @memberof BoundTypeOrgDto
     */
    boundId: string;
    /**
     * 
     * @type {BoundDto}
     * @memberof BoundTypeOrgDto
     */
    bound: BoundDto;
}

export function BoundTypeOrgDtoFromJSON(json: any): BoundTypeOrgDto {
    return BoundTypeOrgDtoFromJSONTyped(json, false);
}

export function BoundTypeOrgDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoundTypeOrgDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (json['modifiedAt'] === null ? null : new Date(json['modifiedAt'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'ticks': !exists(json, 'ticks') ? undefined : json['ticks'],
        'typeOrgId': json['typeOrgId'],
        'typeOrg': TypeOrgDtoFromJSON(json['typeOrg']),
        'boundId': json['boundId'],
        'bound': BoundDtoFromJSON(json['bound']),
    };
}

export function BoundTypeOrgDtoToJSON(value?: BoundTypeOrgDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalId': value.externalId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt === null ? null : value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt === null ? null : value.modifiedAt.toISOString()),
        'modifiedBy': value.modifiedBy,
        'ticks': value.ticks,
        'typeOrgId': value.typeOrgId,
        'typeOrg': TypeOrgDtoToJSON(value.typeOrg),
        'boundId': value.boundId,
        'bound': BoundDtoToJSON(value.bound),
    };
}

