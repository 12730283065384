/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetSubjectDto,
    SetSubjectDtoFromJSON,
    SetSubjectDtoToJSON,
    SubjectDto,
    SubjectDtoFromJSON,
    SubjectDtoToJSON,
} from '../models';

export interface CreateSubjectRequest {
    setSubjectDto: SetSubjectDto;
}

export interface DeleteSubjectRequest {
    id: string;
}

export interface GetSubjectByIdRequest {
    id: string;
}

export interface GetSubjectByNameRequest {
    name: string;
}

export interface GetSubjectsRequest {
    search?: string;
    orderBy?: string;
    searchByName?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetSubjectsCountRequest {
    search?: string;
    searchByName?: string;
}

export interface UpdateSubjectRequest {
    id: string;
    setSubjectDto: SetSubjectDto;
}

/**
 * 
 */
export class SubjectsApi extends runtime.BaseAPI {

    /**
     */
    async createSubjectRaw(requestParameters: CreateSubjectRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SubjectDto>> {
        if (requestParameters.setSubjectDto === null || requestParameters.setSubjectDto === undefined) {
            throw new runtime.RequiredError('setSubjectDto','Required parameter requestParameters.setSubjectDto was null or undefined when calling createSubject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/v1/subjects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetSubjectDtoToJSON(requestParameters.setSubjectDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectDtoFromJSON(jsonValue));
    }

    /**
     */
    async createSubject(requestParameters: CreateSubjectRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SubjectDto> {
        const response = await this.createSubjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteSubjectRaw(requestParameters: DeleteSubjectRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SubjectDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSubject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/subjects/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteSubject(requestParameters: DeleteSubjectRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SubjectDto> {
        const response = await this.deleteSubjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSubjectByIdRaw(requestParameters: GetSubjectByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SubjectDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSubjectById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/subjects/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectDtoFromJSON(jsonValue));
    }

    /**
     */
    async getSubjectById(requestParameters: GetSubjectByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SubjectDto> {
        const response = await this.getSubjectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSubjectByNameRaw(requestParameters: GetSubjectByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SubjectDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getSubjectByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/subjects/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectDtoFromJSON(jsonValue));
    }

    /**
     */
    async getSubjectByName(requestParameters: GetSubjectByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SubjectDto> {
        const response = await this.getSubjectByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSubjectsRaw(requestParameters: GetSubjectsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<SubjectDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.searchByName !== undefined) {
            queryParameters['searchByName'] = requestParameters.searchByName;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/subjects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubjectDtoFromJSON));
    }

    /**
     */
    async getSubjects(requestParameters: GetSubjectsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<SubjectDto>> {
        const response = await this.getSubjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSubjectsCountRaw(requestParameters: GetSubjectsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByName !== undefined) {
            queryParameters['searchByName'] = requestParameters.searchByName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/subjects/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getSubjectsCount(requestParameters: GetSubjectsCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getSubjectsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateSubjectRaw(requestParameters: UpdateSubjectRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SubjectDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSubject.');
        }

        if (requestParameters.setSubjectDto === null || requestParameters.setSubjectDto === undefined) {
            throw new runtime.RequiredError('setSubjectDto','Required parameter requestParameters.setSubjectDto was null or undefined when calling updateSubject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/v1/subjects/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetSubjectDtoToJSON(requestParameters.setSubjectDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateSubject(requestParameters: UpdateSubjectRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SubjectDto> {
        const response = await this.updateSubjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
