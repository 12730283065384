import {Box, Card, Table, TableBody, TableHead, TableRow, Typography} from "@mui/material";
import {observer} from "mobx-react";
import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import {
    AddButton,
    AuthorizePermission,
    Cell,
    ConfirmationForm,
    EmptyBox,
    ExpandButton,
    ExpandRow,
    ExpertDialog,
    HCell,
    Pagination,
    Progress,
    Search
} from '../../components';
import {TabTitle} from "../../components/TabTitle";
import {FiltersComponent} from "../../components/filters/FiltersComponent";
import {callTheProcessingFunc, useManagementStore} from "../../stores";
import {JurisdictionCreator} from "./JurisdictionCreator";
import {JurisdictionDetails} from "./JurisdictionDetails";
import {AllPermissions} from "../../core/const";

export const JurisdictionTable = observer(() => {
    const {jurisdictionsTableStore} = useManagementStore();
    const {t} = useTranslation();

    return (

        <AuthorizePermission permissions={AllPermissions.jurisdiction.read} showWarning>
            <ExpertDialog
                title="Удаление"
                open={jurisdictionsTableStore.deleteDialogState}
                onClose={() => (jurisdictionsTableStore.deleteDialogState = false)}
            >
                <ConfirmationForm
                    disabled={jurisdictionsTableStore.processing}
                    onSubmit={async () =>
                    {
                        await callTheProcessingFunc(
                            jurisdictionsTableStore,
                            (e) => jurisdictionsTableStore.delete(),
                        )()
                        await jurisdictionsTableStore.updateState()
                    }}
                    onClose={() => (jurisdictionsTableStore.deleteDialogState = false)}
                >
                    {t('common.deleteConfirm')} "
                    {jurisdictionsTableStore.current?.dto.nameRus}"?
                </ConfirmationForm>
            </ExpertDialog>
            <ExpertDialog
                title={t('jurisdictions.create')}
                open={jurisdictionsTableStore.creator.Open}
                onClose={() => jurisdictionsTableStore.creator.Open = false}
            >
                <JurisdictionCreator store={jurisdictionsTableStore.creator}/>
            </ExpertDialog>
            <>

                <TabTitle title={t('pageNames.jurisdictions')}>
                    <AuthorizePermission permissions={AllPermissions.jurisdiction.create}>
                        <AddButton onClick={() => jurisdictionsTableStore.creator.Open = true}/>
                    </AuthorizePermission>
                </TabTitle>
                <FiltersComponent>
                    <Box sx={{m: 2}}>
                        <Search
                            autoFocus
                            value={jurisdictionsTableStore.search.value}
                            onChange={t => jurisdictionsTableStore.search.value = t}
                            style={{width: '100%'}}
                        />
                    </Box>
                </FiltersComponent>


                <Card sx={{mb: 8, overflow: 'unset'}}>
                    <Table sx={{minWidth: 1250}}>
                        <TableHead style={{position: 'sticky', top: 63, zIndex: 999}}>
                            <TableRow>
                                <HCell width='5%'></HCell>
                                <HCell store={jurisdictionsTableStore}>{t('jurisdictions.id')}</HCell>
                                <HCell store={jurisdictionsTableStore}>{t('jurisdictions.nameRus')}</HCell>
                                <HCell store={jurisdictionsTableStore}>{t('jurisdictions.nameEng')}</HCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {jurisdictionsTableStore.items.length > 0 && jurisdictionsTableStore.items.map((item) => {
                                return (
                                    <Fragment key={item.dto.id}>
                                        <TableRow
                                            hover
                                            onClick={(x) => jurisdictionsTableStore.current = item}
                                            key={item.dto.id}
                                        >
                                            <ExpandButton item={item} isOpen={item.expand}/>

                                            <Cell>
                                                <Typography variant="subtitle2">
                                                    {item.dto.id}
                                                </Typography>
                                            </Cell>
                                            <Cell>
                                                <Typography variant="subtitle2">
                                                    {item.dto.nameRus}
                                                </Typography>
                                            </Cell>
                                            <Cell>
                                                <Typography variant="subtitle2">
                                                    {item.dto.nameEng}
                                                </Typography>
                                            </Cell>
                                        </TableRow>
                                        {item.expand && (
                                            <ExpandRow key={`${item.dto.id}_`} item={item}>
                                                <JurisdictionDetails
                                                    store={item}
                                                    style={{margin: '20px'}}
                                                />
                                            </ExpandRow>)}
                                    </Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                    {!jurisdictionsTableStore.items.length && <EmptyBox/>}

                    {jurisdictionsTableStore.items.length > 0 && <Pagination store={jurisdictionsTableStore}/>}

                    <Progress store={jurisdictionsTableStore}/>

                </Card>
            </>
        </AuthorizePermission>
    );
});
