/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OCMObjectState,
    OCMObjectStateFromJSON,
    OCMObjectStateToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    QueryType,
    QueryTypeFromJSON,
    QueryTypeToJSON,
    SetTypeOrgDto,
    SetTypeOrgDtoFromJSON,
    SetTypeOrgDtoToJSON,
    TypeOrgDto,
    TypeOrgDtoFromJSON,
    TypeOrgDtoToJSON,
} from '../models';

export interface CreateTypeOrgRequest {
    setTypeOrgDto: SetTypeOrgDto;
}

export interface DeleteTypeOrgRequest {
    id: string;
}

export interface GetTypeOrgByIdRequest {
    id: string;
}

export interface GetTypeOrgByNameRequest {
    name: string;
}

export interface GetTypeOrgsRequest {
    search?: string;
    searchByExternalId?: string;
    clinicCategoryId?: string;
    orderBy?: string;
    queryType?: QueryType;
    parentId?: string;
    includeParents?: boolean;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    showOnlyActive?: boolean;
    showOnlyStructural?: boolean;
}

export interface GetTypeOrgsCountRequest {
    search?: string;
    searchByExternalId?: string;
    parentId?: string;
    clinicCategoryId?: string;
    queryType?: QueryType;
    showOnlyActive?: boolean;
    showOnlyStructural?: boolean;
}

export interface GetTypeOrgsCountFallbackRequest {
    search?: string;
    searchByExternalId?: string;
    parentId?: string;
    clinicCategoryId?: string;
    queryType?: QueryType;
    states?: Array<OCMObjectState>;
    showOnlyActive?: boolean;
    showOnlyStructural?: boolean;
}

export interface GetTypeOrgsFallbackRequest {
    search?: string;
    searchByExternalId?: string;
    clinicCategoryId?: string;
    orderBy?: string;
    queryType?: QueryType;
    parentId?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    includeParents?: boolean;
    states?: Array<OCMObjectState>;
    showOnlyActive?: boolean;
    showOnlyStructural?: boolean;
}

export interface UpdateTypeOrgRequest {
    id: string;
    setTypeOrgDto: SetTypeOrgDto;
}

/**
 * 
 */
export class TypeOrgsApi extends runtime.BaseAPI {

    /**
     */
    async createTypeOrgRaw(requestParameters: CreateTypeOrgRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TypeOrgDto>> {
        if (requestParameters.setTypeOrgDto === null || requestParameters.setTypeOrgDto === undefined) {
            throw new runtime.RequiredError('setTypeOrgDto','Required parameter requestParameters.setTypeOrgDto was null or undefined when calling createTypeOrg.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetTypeOrgDtoToJSON(requestParameters.setTypeOrgDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeOrgDtoFromJSON(jsonValue));
    }

    /**
     */
    async createTypeOrg(requestParameters: CreateTypeOrgRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TypeOrgDto> {
        const response = await this.createTypeOrgRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTypeOrgRaw(requestParameters: DeleteTypeOrgRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TypeOrgDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTypeOrg.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeOrgDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteTypeOrg(requestParameters: DeleteTypeOrgRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TypeOrgDto> {
        const response = await this.deleteTypeOrgRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTypeOrgByIdRaw(requestParameters: GetTypeOrgByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TypeOrgDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTypeOrgById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeOrgDtoFromJSON(jsonValue));
    }

    /**
     */
    async getTypeOrgById(requestParameters: GetTypeOrgByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TypeOrgDto> {
        const response = await this.getTypeOrgByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTypeOrgByNameRaw(requestParameters: GetTypeOrgByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TypeOrgDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getTypeOrgByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/types/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeOrgDtoFromJSON(jsonValue));
    }

    /**
     */
    async getTypeOrgByName(requestParameters: GetTypeOrgByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TypeOrgDto> {
        const response = await this.getTypeOrgByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTypeOrgsRaw(requestParameters: GetTypeOrgsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<TypeOrgDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.clinicCategoryId !== undefined) {
            queryParameters['clinicCategoryId'] = requestParameters.clinicCategoryId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.queryType !== undefined) {
            queryParameters['queryType'] = requestParameters.queryType;
        }

        if (requestParameters.parentId !== undefined) {
            queryParameters['parentId'] = requestParameters.parentId;
        }

        if (requestParameters.includeParents !== undefined) {
            queryParameters['includeParents'] = requestParameters.includeParents;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.showOnlyActive !== undefined) {
            queryParameters['showOnlyActive'] = requestParameters.showOnlyActive;
        }

        if (requestParameters.showOnlyStructural !== undefined) {
            queryParameters['showOnlyStructural'] = requestParameters.showOnlyStructural;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TypeOrgDtoFromJSON));
    }

    /**
     */
    async getTypeOrgs(requestParameters: GetTypeOrgsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<TypeOrgDto>> {
        const response = await this.getTypeOrgsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTypeOrgsCountRaw(requestParameters: GetTypeOrgsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.parentId !== undefined) {
            queryParameters['parentId'] = requestParameters.parentId;
        }

        if (requestParameters.clinicCategoryId !== undefined) {
            queryParameters['clinicCategoryId'] = requestParameters.clinicCategoryId;
        }

        if (requestParameters.queryType !== undefined) {
            queryParameters['queryType'] = requestParameters.queryType;
        }

        if (requestParameters.showOnlyActive !== undefined) {
            queryParameters['showOnlyActive'] = requestParameters.showOnlyActive;
        }

        if (requestParameters.showOnlyStructural !== undefined) {
            queryParameters['showOnlyStructural'] = requestParameters.showOnlyStructural;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/types/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getTypeOrgsCount(requestParameters: GetTypeOrgsCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getTypeOrgsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTypeOrgsCountFallbackRaw(requestParameters: GetTypeOrgsCountFallbackRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.parentId !== undefined) {
            queryParameters['parentId'] = requestParameters.parentId;
        }

        if (requestParameters.clinicCategoryId !== undefined) {
            queryParameters['clinicCategoryId'] = requestParameters.clinicCategoryId;
        }

        if (requestParameters.queryType !== undefined) {
            queryParameters['queryType'] = requestParameters.queryType;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        if (requestParameters.showOnlyActive !== undefined) {
            queryParameters['showOnlyActive'] = requestParameters.showOnlyActive;
        }

        if (requestParameters.showOnlyStructural !== undefined) {
            queryParameters['showOnlyStructural'] = requestParameters.showOnlyStructural;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/types/count/fallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getTypeOrgsCountFallback(requestParameters: GetTypeOrgsCountFallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getTypeOrgsCountFallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTypeOrgsFallbackRaw(requestParameters: GetTypeOrgsFallbackRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<TypeOrgDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.clinicCategoryId !== undefined) {
            queryParameters['clinicCategoryId'] = requestParameters.clinicCategoryId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.queryType !== undefined) {
            queryParameters['queryType'] = requestParameters.queryType;
        }

        if (requestParameters.parentId !== undefined) {
            queryParameters['parentId'] = requestParameters.parentId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.includeParents !== undefined) {
            queryParameters['includeParents'] = requestParameters.includeParents;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        if (requestParameters.showOnlyActive !== undefined) {
            queryParameters['showOnlyActive'] = requestParameters.showOnlyActive;
        }

        if (requestParameters.showOnlyStructural !== undefined) {
            queryParameters['showOnlyStructural'] = requestParameters.showOnlyStructural;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/organizations/types/fallback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TypeOrgDtoFromJSON));
    }

    /**
     */
    async getTypeOrgsFallback(requestParameters: GetTypeOrgsFallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<TypeOrgDto>> {
        const response = await this.getTypeOrgsFallbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTypeOrgRaw(requestParameters: UpdateTypeOrgRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TypeOrgDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTypeOrg.');
        }

        if (requestParameters.setTypeOrgDto === null || requestParameters.setTypeOrgDto === undefined) {
            throw new runtime.RequiredError('setTypeOrgDto','Required parameter requestParameters.setTypeOrgDto was null or undefined when calling updateTypeOrg.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/organizations/types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetTypeOrgDtoToJSON(requestParameters.setTypeOrgDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeOrgDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateTypeOrg(requestParameters: UpdateTypeOrgRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TypeOrgDto> {
        const response = await this.updateTypeOrgRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
