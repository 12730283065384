/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BoundDto,
    BoundDtoFromJSON,
    BoundDtoFromJSONTyped,
    BoundDtoToJSON,
} from './BoundDto';
import {
    OrganizationDto,
    OrganizationDtoFromJSON,
    OrganizationDtoFromJSONTyped,
    OrganizationDtoToJSON,
} from './OrganizationDto';

/**
 * 
 * @export
 * @interface OrganizationDtoMappingDto
 */
export interface OrganizationDtoMappingDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDtoMappingDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDtoMappingDto
     */
    externalId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationDtoMappingDto
     */
    createdAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDtoMappingDto
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationDtoMappingDto
     */
    modifiedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDtoMappingDto
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganizationDtoMappingDto
     */
    ticks?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDtoMappingDto
     */
    entityId: string;
    /**
     * 
     * @type {OrganizationDto}
     * @memberof OrganizationDtoMappingDto
     */
    entity: OrganizationDto;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDtoMappingDto
     */
    boundId: string;
    /**
     * 
     * @type {BoundDto}
     * @memberof OrganizationDtoMappingDto
     */
    bound: BoundDto;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDtoMappingDto
     */
    clientValue: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDtoMappingDto
     */
    alternativeClientValue: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationDtoMappingDto
     */
    poweredByElastic?: boolean;
}

export function OrganizationDtoMappingDtoFromJSON(json: any): OrganizationDtoMappingDto {
    return OrganizationDtoMappingDtoFromJSONTyped(json, false);
}

export function OrganizationDtoMappingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationDtoMappingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (json['modifiedAt'] === null ? null : new Date(json['modifiedAt'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'ticks': !exists(json, 'ticks') ? undefined : json['ticks'],
        'entityId': json['entityId'],
        'entity': OrganizationDtoFromJSON(json['entity']),
        'boundId': json['boundId'],
        'bound': BoundDtoFromJSON(json['bound']),
        'clientValue': json['clientValue'],
        'alternativeClientValue': json['alternativeClientValue'],
        'poweredByElastic': !exists(json, 'poweredByElastic') ? undefined : json['poweredByElastic'],
    };
}

export function OrganizationDtoMappingDtoToJSON(value?: OrganizationDtoMappingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalId': value.externalId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt === null ? null : value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt === null ? null : value.modifiedAt.toISOString()),
        'modifiedBy': value.modifiedBy,
        'ticks': value.ticks,
        'entityId': value.entityId,
        'entity': OrganizationDtoToJSON(value.entity),
        'boundId': value.boundId,
        'bound': BoundDtoToJSON(value.bound),
        'clientValue': value.clientValue,
        'alternativeClientValue': value.alternativeClientValue,
        'poweredByElastic': value.poweredByElastic,
    };
}

