/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Data360UpdateStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;
export type Data360UpdateStatus = typeof Data360UpdateStatus[keyof typeof Data360UpdateStatus];


export function Data360UpdateStatusFromJSON(json: any): Data360UpdateStatus {
    return Data360UpdateStatusFromJSONTyped(json, false);
}

export function Data360UpdateStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): Data360UpdateStatus {
    return json as Data360UpdateStatus;
}

export function Data360UpdateStatusToJSON(value?: Data360UpdateStatus | null): any {
    return value as any;
}

