import React from 'react';
import { observer } from 'mobx-react';
import { IPage } from '../../../core';
import { SxProps, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
    store: IPage;
    style?: React.CSSProperties;
    sx?: SxProps;
};

export const PaginationLeft = observer(({ store, style, sx }: Props) => {
    const { t } = useTranslation();

    const handleChangePage = async (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        await store.setPaging(newPage, store.size);
    };

    return (
        <TablePagination
            style={{ border: 'none', ...style }}
            count={store.count}
            rowsPerPage={store.size}
            page={store.page}
            onPageChange={handleChangePage}
            nextIconButtonProps={{ style: { display: "none" } }}
            backIconButtonProps={{ style: { display: "none" } }}
            labelRowsPerPage={''}
            rowsPerPageOptions={[]}
            labelDisplayedRows={
                ({ from, to, count }) => {
                    return `${t('common.paginationEntries')} ` + from + '-' + to + ` ${t('common.paginationCount')} ` + count
                }
            }
            sx={sx}
        />
    );
});