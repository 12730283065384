/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OCMObjectState,
    OCMObjectStateFromJSON,
    OCMObjectStateToJSON,
    PhoneCategoryDto,
    PhoneCategoryDtoFromJSON,
    PhoneCategoryDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetPhoneCategoryDto,
    SetPhoneCategoryDtoFromJSON,
    SetPhoneCategoryDtoToJSON,
} from '../models';

export interface CreateOrganizationPhoneCategoryRequest {
    setPhoneCategoryDto: SetPhoneCategoryDto;
}

export interface DeleteOrganizationPhoneCategoryRequest {
    id: string;
}

export interface GetOrganizationPhoneCategoriesRequest {
    search?: string;
    orderBy?: string;
    searchByExternalId?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface GetOrganizationPhoneCategoriesCountRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface GetOrganizationPhoneCategoryByIdRequest {
    id: string;
}

export interface GetOrganizationPhoneCategoryByNameRequest {
    name: string;
}

export interface UpdateOrganizationPhoneCategoryRequest {
    id: string;
    setPhoneCategoryDto: SetPhoneCategoryDto;
}

/**
 * 
 */
export class OrganizationPhoneCategoriesApi extends runtime.BaseAPI {

    /**
     */
    async createOrganizationPhoneCategoryRaw(requestParameters: CreateOrganizationPhoneCategoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneCategoryDto>> {
        if (requestParameters.setPhoneCategoryDto === null || requestParameters.setPhoneCategoryDto === undefined) {
            throw new runtime.RequiredError('setPhoneCategoryDto','Required parameter requestParameters.setPhoneCategoryDto was null or undefined when calling createOrganizationPhoneCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/phones/categories/organization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPhoneCategoryDtoToJSON(requestParameters.setPhoneCategoryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async createOrganizationPhoneCategory(requestParameters: CreateOrganizationPhoneCategoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneCategoryDto> {
        const response = await this.createOrganizationPhoneCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteOrganizationPhoneCategoryRaw(requestParameters: DeleteOrganizationPhoneCategoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneCategoryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOrganizationPhoneCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/categories/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteOrganizationPhoneCategory(requestParameters: DeleteOrganizationPhoneCategoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneCategoryDto> {
        const response = await this.deleteOrganizationPhoneCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationPhoneCategoriesRaw(requestParameters: GetOrganizationPhoneCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PhoneCategoryDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/categories/organization`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PhoneCategoryDtoFromJSON));
    }

    /**
     */
    async getOrganizationPhoneCategories(requestParameters: GetOrganizationPhoneCategoriesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PhoneCategoryDto>> {
        const response = await this.getOrganizationPhoneCategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationPhoneCategoriesCountRaw(requestParameters: GetOrganizationPhoneCategoriesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/categories/organization/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationPhoneCategoriesCount(requestParameters: GetOrganizationPhoneCategoriesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getOrganizationPhoneCategoriesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationPhoneCategoryByIdRaw(requestParameters: GetOrganizationPhoneCategoryByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneCategoryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrganizationPhoneCategoryById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/categories/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationPhoneCategoryById(requestParameters: GetOrganizationPhoneCategoryByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneCategoryDto> {
        const response = await this.getOrganizationPhoneCategoryByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationPhoneCategoryByNameRaw(requestParameters: GetOrganizationPhoneCategoryByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneCategoryDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getOrganizationPhoneCategoryByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/phones/categories/organization/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationPhoneCategoryByName(requestParameters: GetOrganizationPhoneCategoryByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneCategoryDto> {
        const response = await this.getOrganizationPhoneCategoryByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrganizationPhoneCategoryRaw(requestParameters: UpdateOrganizationPhoneCategoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PhoneCategoryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrganizationPhoneCategory.');
        }

        if (requestParameters.setPhoneCategoryDto === null || requestParameters.setPhoneCategoryDto === undefined) {
            throw new runtime.RequiredError('setPhoneCategoryDto','Required parameter requestParameters.setPhoneCategoryDto was null or undefined when calling updateOrganizationPhoneCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/phones/categories/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetPhoneCategoryDtoToJSON(requestParameters.setPhoneCategoryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateOrganizationPhoneCategory(requestParameters: UpdateOrganizationPhoneCategoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PhoneCategoryDto> {
        const response = await this.updateOrganizationPhoneCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
