/* tslint:disable */
/* eslint-disable */
/**
 * Data360.DCR.Analytics
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestsByDateDto
 */
export interface RequestsByDateDto {
    /**
     * 
     * @type {Date}
     * @memberof RequestsByDateDto
     */
    date?: Date;
    /**
     * 
     * @type {number}
     * @memberof RequestsByDateDto
     */
    count?: number;
}

export function RequestsByDateDtoFromJSON(json: any): RequestsByDateDto {
    return RequestsByDateDtoFromJSONTyped(json, false);
}

export function RequestsByDateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestsByDateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function RequestsByDateDtoToJSON(value?: RequestsByDateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'count': value.count,
    };
}

