/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetAddressUniqueDto
 */
export interface SetAddressUniqueDto {
    /**
     * 
     * @type {string}
     * @memberof SetAddressUniqueDto
     */
    localityId: string;
    /**
     *
     * @type {string}
     * @memberof SetAddressUniqueDto
     * @deprecated
     */
    zipId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetAddressUniqueDto
     */
    zip: string;
    /**
     * 
     * @type {string}
     * @memberof SetAddressUniqueDto
     */
    okrugId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetAddressUniqueDto
     */
    districtId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetAddressUniqueDto
     */
    house: string;
    /**
     * 
     * @type {string}
     * @memberof SetAddressUniqueDto
     */
    building?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetAddressUniqueDto
     */
    gpsAccuracy: string;
    /**
     * 
     * @type {string}
     * @memberof SetAddressUniqueDto
     */
    longtitude: string;
    /**
     * 
     * @type {string}
     * @memberof SetAddressUniqueDto
     */
    latitude: string;
    /**
     * 
     * @type {string}
     * @memberof SetAddressUniqueDto
     */
    brickId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetAddressUniqueDto
     */
    entryTypeId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SetAddressUniqueDto
     */
    entryValue?: number | null;
}

export function SetAddressUniqueDtoFromJSON(json: any): SetAddressUniqueDto {
    return SetAddressUniqueDtoFromJSONTyped(json, false);
}

export function SetAddressUniqueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetAddressUniqueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'localityId': json['localityId'],
        'zipId': !exists(json, 'zipId') ? undefined : json['zipId'],
        'zip': json['zip'],
        'okrugId': !exists(json, 'okrugId') ? undefined : json['okrugId'],
        'districtId': !exists(json, 'districtId') ? undefined : json['districtId'],
        'house': json['house'],
        'building': !exists(json, 'building') ? undefined : json['building'],
        'gpsAccuracy': json['gpsAccuracy'],
        'longtitude': json['longtitude'],
        'latitude': json['latitude'],
        'brickId': !exists(json, 'brickId') ? undefined : json['brickId'],
        'entryTypeId': !exists(json, 'entryTypeId') ? undefined : json['entryTypeId'],
        'entryValue': !exists(json, 'entryValue') ? undefined : json['entryValue'],
    };
}

export function SetAddressUniqueDtoToJSON(value?: SetAddressUniqueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'localityId': value.localityId,
        'zip': value.zip,
        'okrugId': value.okrugId,
        'districtId': value.districtId,
        'house': value.house,
        'building': value.building,
        'gpsAccuracy': value.gpsAccuracy,
        'longtitude': value.longtitude,
        'latitude': value.latitude,
        'brickId': value.brickId,
        'entryTypeId': value.entryTypeId,
        'entryValue': value.entryValue,
    };
}

