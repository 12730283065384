/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    PersonDto,
    PersonDtoFromJSON,
    PersonDtoFromJSONTyped,
    PersonDtoToJSON,
} from './PersonDto';
import {
    TagDto,
    TagDtoFromJSON,
    TagDtoFromJSONTyped,
    TagDtoToJSON,
} from './TagDto';

/**
 * 
 * @export
 * @interface AssignedPersonTagDto
 */
export interface AssignedPersonTagDto {
    /**
     * 
     * @type {string}
     * @memberof AssignedPersonTagDto
     */
    id?: string | null;
    /**
     * 
     * @type {PersonDto}
     * @memberof AssignedPersonTagDto
     */
    person?: PersonDto;
    /**
     * 
     * @type {TagDto}
     * @memberof AssignedPersonTagDto
     */
    personTag?: TagDto;
}

export function AssignedPersonTagDtoFromJSON(json: any): AssignedPersonTagDto {
    return AssignedPersonTagDtoFromJSONTyped(json, false);
}

export function AssignedPersonTagDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignedPersonTagDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'person': !exists(json, 'person') ? undefined : PersonDtoFromJSON(json['person']),
        'personTag': !exists(json, 'personTag') ? undefined : TagDtoFromJSON(json['personTag']),
    };
}

export function AssignedPersonTagDtoToJSON(value?: AssignedPersonTagDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'person': PersonDtoToJSON(value.person),
        'personTag': TagDtoToJSON(value.personTag),
    };
}

