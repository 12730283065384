import {getApiConfiguration} from '../../../../../core';
import {GendersApi} from "../../../../../services/management";
import {DictionaryCreateEntity, DictionaryEntity, DictionaryTableStore} from "../../DictionaryTableStore";
import {DictionaryPersonStore} from "../DictionaryPersonStore";

export class GenderTableStore extends DictionaryTableStore {
    constructor(store: DictionaryPersonStore) {
        super(store, 'gender-store');
    }

    async Create(model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new GendersApi(getApiConfiguration()).createGender({setGenderDto: model});
    }

    async Delete(id: string): Promise<DictionaryEntity> {
        return await new GendersApi(getApiConfiguration()).deleteGender({id: id});
    }

    async GetCount(search: string): Promise<{ count: number }> {
        return await new GendersApi(getApiConfiguration()).getGendersCount({search: search});
    }

    async GetList(filters: any): Promise<DictionaryEntity[]> {
        return await new GendersApi(getApiConfiguration()).getGenders(filters)
    }

    async Update(id: string, model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new GendersApi(getApiConfiguration()).updateGender({id: id, setGenderDto: model});
    }
}
