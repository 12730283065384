/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OCMObjectState,
    OCMObjectStateFromJSON,
    OCMObjectStateToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetWebsiteStatusDto,
    SetWebsiteStatusDtoFromJSON,
    SetWebsiteStatusDtoToJSON,
    WebsiteStatusDto,
    WebsiteStatusDtoFromJSON,
    WebsiteStatusDtoToJSON,
} from '../models';

export interface CreateOrganizationWebsiteStatusRequest {
    setWebsiteStatusDto: SetWebsiteStatusDto;
}

export interface DeleteOrganizationWebsiteStatusRequest {
    id: string;
}

export interface GetOrganizationWebsiteStatusByIdRequest {
    id: string;
}

export interface GetOrganizationWebsiteStatusByNameRequest {
    name: string;
}

export interface GetOrganizationWebsiteStatusesRequest {
    search?: string;
    orderBy?: string;
    searchByExternalId?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface GetOrganizationWebsiteStatusesCountRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface UpdateOrganizationWebsiteStatusRequest {
    id: string;
    setWebsiteStatusDto: SetWebsiteStatusDto;
}

/**
 * 
 */
export class OrganizationWebsiteStatusesApi extends runtime.BaseAPI {

    /**
     */
    async createOrganizationWebsiteStatusRaw(requestParameters: CreateOrganizationWebsiteStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WebsiteStatusDto>> {
        if (requestParameters.setWebsiteStatusDto === null || requestParameters.setWebsiteStatusDto === undefined) {
            throw new runtime.RequiredError('setWebsiteStatusDto','Required parameter requestParameters.setWebsiteStatusDto was null or undefined when calling createOrganizationWebsiteStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/websites/statuses/organization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetWebsiteStatusDtoToJSON(requestParameters.setWebsiteStatusDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebsiteStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async createOrganizationWebsiteStatus(requestParameters: CreateOrganizationWebsiteStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WebsiteStatusDto> {
        const response = await this.createOrganizationWebsiteStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteOrganizationWebsiteStatusRaw(requestParameters: DeleteOrganizationWebsiteStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WebsiteStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOrganizationWebsiteStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/websites/statuses/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebsiteStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteOrganizationWebsiteStatus(requestParameters: DeleteOrganizationWebsiteStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WebsiteStatusDto> {
        const response = await this.deleteOrganizationWebsiteStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationWebsiteStatusByIdRaw(requestParameters: GetOrganizationWebsiteStatusByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WebsiteStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrganizationWebsiteStatusById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/websites/statuses/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebsiteStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationWebsiteStatusById(requestParameters: GetOrganizationWebsiteStatusByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WebsiteStatusDto> {
        const response = await this.getOrganizationWebsiteStatusByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationWebsiteStatusByNameRaw(requestParameters: GetOrganizationWebsiteStatusByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WebsiteStatusDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getOrganizationWebsiteStatusByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/websites/statuses/organization/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebsiteStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationWebsiteStatusByName(requestParameters: GetOrganizationWebsiteStatusByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WebsiteStatusDto> {
        const response = await this.getOrganizationWebsiteStatusByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationWebsiteStatusesRaw(requestParameters: GetOrganizationWebsiteStatusesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<WebsiteStatusDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/websites/statuses/organization`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WebsiteStatusDtoFromJSON));
    }

    /**
     */
    async getOrganizationWebsiteStatuses(requestParameters: GetOrganizationWebsiteStatusesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<WebsiteStatusDto>> {
        const response = await this.getOrganizationWebsiteStatusesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationWebsiteStatusesCountRaw(requestParameters: GetOrganizationWebsiteStatusesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/websites/statuses/organization/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationWebsiteStatusesCount(requestParameters: GetOrganizationWebsiteStatusesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getOrganizationWebsiteStatusesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrganizationWebsiteStatusRaw(requestParameters: UpdateOrganizationWebsiteStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WebsiteStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrganizationWebsiteStatus.');
        }

        if (requestParameters.setWebsiteStatusDto === null || requestParameters.setWebsiteStatusDto === undefined) {
            throw new runtime.RequiredError('setWebsiteStatusDto','Required parameter requestParameters.setWebsiteStatusDto was null or undefined when calling updateOrganizationWebsiteStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/websites/statuses/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetWebsiteStatusDtoToJSON(requestParameters.setWebsiteStatusDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebsiteStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateOrganizationWebsiteStatus(requestParameters: UpdateOrganizationWebsiteStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WebsiteStatusDto> {
        const response = await this.updateOrganizationWebsiteStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
