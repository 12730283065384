import {getApiConfiguration,} from '../../../../../core';
import {SurnamesApi} from '../../../../../services/management';
import {DictionaryCreateEntity, DictionaryEntity, DictionaryTableStore} from "../../DictionaryTableStore";
import {DictionaryPersonStore} from "../DictionaryPersonStore";

export class SurnameTableStore extends DictionaryTableStore {
    constructor(store: DictionaryPersonStore) {
        super(store, 'surname-store');
    }

    async Create(model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new SurnamesApi(getApiConfiguration()).createSurname({setSurnameDto: model});
    }

    async Delete(id: string): Promise<DictionaryEntity> {
        return await new SurnamesApi(getApiConfiguration()).deleteSurname({id: id});
    }

    async GetById(id: string): Promise<DictionaryEntity> {
        return await new SurnamesApi(getApiConfiguration()).getSurnameById({id: id});
    }

    async GetCount(search: string): Promise<{ count: number }> {
        return await new SurnamesApi(getApiConfiguration()).getSurnamesCount({search: search});
    }

    async GetList(filters: any): Promise<DictionaryEntity[]> {
        return await new SurnamesApi(getApiConfiguration()).getSurnames(filters)
    }

    async Update(id: string, model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new SurnamesApi(getApiConfiguration()).updateSurname({id: id, setSurnameDto: model});
    }
}
