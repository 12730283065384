import axios, {AxiosInstance} from 'axios';
import {Configuration, FetchParams, RequestContext, ResponseContext} from '../services/management';
import {accessToken, exp, jurisdictionId, refreshToken} from '../stores/authorization/AuthorizationStore';
import {logout, refreshTokenFunc} from "../stores";
import SnackbarUtils from "../components/snackbar/SnackbarUtils";
import {t} from "i18next";

export const getToken = (): string => {
    return accessToken;
};

export const createApiInstance = (path: string, parameters?: any): AxiosInstance => {
    const url = new URL(`${process.env.REACT_APP_API}/${path}`);
    if (parameters) {
        for (var prop in parameters) {
            let value = parameters[prop];
            if (prop === 'page') {
                value = Number.parseInt(value + 1);
            }
            if (typeof value === 'undefined' || !value) {
                continue;
            }
            url.searchParams.append(prop, value);
        }
    }
    return axios.create({
        baseURL: url.href,
        timeout: 3000000,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
        }
    });
};

export const createPackageApiInstance = (path: string, name: string, values: string[]): AxiosInstance => {
    const url = new URL(`${process.env.REACT_APP_API}/${path}`);
    for (var val of values) {
        url.searchParams.append(name, val);
    }
    return axios.create({
        baseURL: url.href,
        timeout: 3000000,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
        }
    });
};


export const getList = async <T>(path: string, filters?: any): Promise<Array<T>> => {
    const count = await createApiInstance(`${path}/count`, filters).get<{ count: number }>('');
    if (!filters) {
        filters = {page: 0, size: 0}
    }
    filters.page = 0;
    filters.size = count.data.count;
    const result = await createApiInstance(path, filters).get<T[]>('');
    return result.data;
};

export const get = async <T>(path: string): Promise<T> =>
    await (await createApiInstance(path).get<T>('')).data;

export const create = async <T>(path: string, item: any): Promise<T> =>
    await (await createApiInstance(path).post<T>('', item)).data;

export const update = async <T>(path: string, item: any): Promise<T> =>
    await (await createApiInstance(path).put<T>('', item)).data;

export const remove = async <T>(path: string): Promise<T> =>
    await (await createApiInstance(path).delete<T>('')).data;

export class ApiConfigurationParams {
    successTooltip?: boolean = true;
    errorTooltip?: boolean = true;
    notFoundRedirect?: boolean = false;
}

const DefaultApiConfigurationParams: ApiConfigurationParams = {
    successTooltip: true,
    errorTooltip: true,
    notFoundRedirect: false
}

export const getApiConfiguration = ({
                                        successTooltip = true,
                                        errorTooltip = true,
                                        notFoundRedirect
                                    }: ApiConfigurationParams = DefaultApiConfigurationParams): Configuration =>
    new Configuration({
        basePath: process.env.REACT_APP_API,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
            'Jurisdiction': jurisdictionId
        },
        middleware: [
            {
                async pre(context: RequestContext): Promise<FetchParams | void> {
                    if (refreshToken != null && exp && (exp * 1000 < Date.now())) {
                        await refreshTokenFunc()

                        const requestHeaders: HeadersInit = new Headers();
                        requestHeaders.set('Content-Type', 'application/json');
                        requestHeaders.set('Authorization', 'Bearer ' + accessToken);
                        context.init.headers = requestHeaders;
                    }
                },
                async post(context: ResponseContext): Promise<Response | void> {
                    if ((context.response.status === 500 || context.response.status === 422 || context.response.status == 400) && errorTooltip) {
                        let resp = context.response
                        let respClone = context.response.clone();
                        resp.json().then(x => {
                            const errors = !!x?.errors ? Object.keys(x?.errors) : [];
                            const error = errors.length > 0 ? Object.values(x?.errors)?.join('\n') : x?.detail ?? x ?? t("common.baseError");
                            SnackbarUtils.error(error)
                        }).catch(async (error) => {
                            respClone.text().then(x => {
                                SnackbarUtils.error(x.indexOf("base.error") === 0 ? t(x) : x)
                            })
                        })
                    }
                    if (context.response.status === 200 && (context.init.method === 'POST' || context.init.method === 'PUT' || context.init.method === 'DELETE') && successTooltip)
                        SnackbarUtils.success(t("common.saved"))
                    if (context.response.status === 401)
                        await logout()
                    if ((context.response.status === 404 || context.response.status === 403) && context.init.method == 'GET' && notFoundRedirect) {
                        const route = window.location.pathname.slice(1)
                        window.location.replace("../not-found?route="+route);
                    }
                    if (context.response.status === 403 && (context.init.method === 'POST' || context.init.method === 'PUT' || context.init.method === 'DELETE')) {
                        SnackbarUtils.error(t("authorization.noPermission"))
                    }
                }
            }
        ]
    });


