/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    PermissionDto,
    PermissionDtoFromJSON,
    PermissionDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetPermissionDto,
    SetPermissionDtoFromJSON,
    SetPermissionDtoToJSON,
} from '../models';

export interface CreatePermissionRequest {
    setPermissionDto: SetPermissionDto;
}

export interface DeletePermissionRequest {
    id: string;
}

export interface GetPermissionByIdRequest {
    id: string;
}

export interface GetPermissionByNameRequest {
    name: string;
}

export interface GetPermissionsRequest {
    search?: string;
    orderBy?: string;
    searchByName?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetPermissionsCountRequest {
    search?: string;
    searchByName?: string;
}

export interface UpdatePermissionRequest {
    id: string;
    setPermissionDto: SetPermissionDto;
}

/**
 * 
 */
export class PermissionsApi extends runtime.BaseAPI {

    /**
     */
    async createPermissionRaw(requestParameters: CreatePermissionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PermissionDto>> {
        if (requestParameters.setPermissionDto === null || requestParameters.setPermissionDto === undefined) {
            throw new runtime.RequiredError('setPermissionDto','Required parameter requestParameters.setPermissionDto was null or undefined when calling createPermission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/v1/permissions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPermissionDtoToJSON(requestParameters.setPermissionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionDtoFromJSON(jsonValue));
    }

    /**
     */
    async createPermission(requestParameters: CreatePermissionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PermissionDto> {
        const response = await this.createPermissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePermissionRaw(requestParameters: DeletePermissionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PermissionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePermission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/permissions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionDtoFromJSON(jsonValue));
    }

    /**
     */
    async deletePermission(requestParameters: DeletePermissionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PermissionDto> {
        const response = await this.deletePermissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPermissionByIdRaw(requestParameters: GetPermissionByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PermissionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPermissionById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/permissions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPermissionById(requestParameters: GetPermissionByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PermissionDto> {
        const response = await this.getPermissionByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPermissionByNameRaw(requestParameters: GetPermissionByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PermissionDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getPermissionByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/permissions/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPermissionByName(requestParameters: GetPermissionByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PermissionDto> {
        const response = await this.getPermissionByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPermissionsRaw(requestParameters: GetPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PermissionDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.searchByName !== undefined) {
            queryParameters['searchByName'] = requestParameters.searchByName;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/permissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PermissionDtoFromJSON));
    }

    /**
     */
    async getPermissions(requestParameters: GetPermissionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PermissionDto>> {
        const response = await this.getPermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPermissionsCountRaw(requestParameters: GetPermissionsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByName !== undefined) {
            queryParameters['searchByName'] = requestParameters.searchByName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/v1/permissions/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPermissionsCount(requestParameters: GetPermissionsCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPermissionsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePermissionRaw(requestParameters: UpdatePermissionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PermissionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePermission.');
        }

        if (requestParameters.setPermissionDto === null || requestParameters.setPermissionDto === undefined) {
            throw new runtime.RequiredError('setPermissionDto','Required parameter requestParameters.setPermissionDto was null or undefined when calling updatePermission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/v1/permissions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetPermissionDtoToJSON(requestParameters.setPermissionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionDtoFromJSON(jsonValue));
    }

    /**
     */
    async updatePermission(requestParameters: UpdatePermissionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PermissionDto> {
        const response = await this.updatePermissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
