import {makeObservable, observable} from 'mobx';
import {
    getApiConfiguration,
    getObject,
    setObject,
} from "../../../../../core";
import {RoleTableStore} from "./RoleTableStore";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";
import {RoleDto, RolesApi} from '../../../../../services/auth';
import {DefaultFormStore} from "../../../../../components/shared/DefaultFormStore";
import {UsersTabStore} from "./tabs/UsersTabStore";
import {t} from "i18next";
import {DynamicalComponentStore} from "../../../../utils";
import { PermissionTableStore } from './tabs/permission/PermissionTableStore';

interface NameViewModelLocalFilters {
    expand: boolean;
}

export class RoleItemStore extends DynamicalComponentStore {
    readonly _store: RoleTableStore;
    dto: RoleDto;
    parameter: DefaultFormStore<{
        name: DefaultValueStore<string>,
        description: DefaultValueStore<string>
    }>;

    roleUsersTabStore: UsersTabStore;
    permissionsTabStore: PermissionTableStore;

    constructor(store: RoleTableStore, dto: RoleDto) {
        super();
        this._store = store;
        this.dto = dto;
        this.roleUsersTabStore = new UsersTabStore(this);
        const filters = getObject<NameViewModelLocalFilters>(`name-${this.dto.id}`);
        if (filters) {
            this._expand = filters.expand;
        }

        this.parameter = new DefaultFormStore({
            name: new DefaultValueStore<string>(dto.name),
            description: new DefaultValueStore<string>(dto.description)
        },this.save.bind(this))

        this.parameter.fields.name.required = true;
        this.parameter.fields.name.validatorFuncAsync = async (e) => {
            try {
                const exists = await new RolesApi(getApiConfiguration({errorTooltip: false})).getRoleByName({name: this.parameter.model.name});
                return exists.id != this.dto.id ? t("errors.exists") : "";
            } finally {
            }
            return "";
        }

        this.permissionsTabStore = new PermissionTableStore(this);
        makeObservable(this, {
            dto: observable
        })
    }

    async updateState() {
        setObject<NameViewModelLocalFilters>(`claim-${this.dto.id}`, {
            expand: this._expand
        });
        if (this._tabIndex === 0) {
            this.parameter.fields.name.update(this.dto.name ?? "")
            this.parameter.fields.description.update(this.dto.description ?? "")
        }
        if (this._tabIndex === 1) {
            await this.roleUsersTabStore.pull()
        }
        if (this._tabIndex === 2) {
            await this.permissionsTabStore.pull()
        }
    }

    update(dto: RoleDto) {
        this.dto = dto;
        this.updateState().then()
    }

    async save(model: any): Promise<void>{
        const result = await new RolesApi(getApiConfiguration()).updateRole({
            id: this.dto.id,
            setRoleDto: {
                name: model.name,
                description: model.description
            }
        })
        this.update(result);
    }
}
