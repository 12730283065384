/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetPersonPhoneDto
 */
export interface SetPersonPhoneDto {
    /**
     * 
     * @type {string}
     * @memberof SetPersonPhoneDto
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonPhoneDto
     */
    personId: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonPhoneDto
     */
    phoneStatusId: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonPhoneDto
     */
    phoneCategoryId: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonPhoneDto
     */
    phoneTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonPhoneDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetPersonPhoneDto
     */
    additionalNumber?: string | null;
}

export function SetPersonPhoneDtoFromJSON(json: any): SetPersonPhoneDto {
    return SetPersonPhoneDtoFromJSONTyped(json, false);
}

export function SetPersonPhoneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetPersonPhoneDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phoneNumber': json['phoneNumber'],
        'personId': json['personId'],
        'phoneStatusId': json['phoneStatusId'],
        'phoneCategoryId': json['phoneCategoryId'],
        'phoneTypeId': json['phoneTypeId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'additionalNumber': !exists(json, 'additionalNumber') ? undefined : json['additionalNumber'],
    };
}

export function SetPersonPhoneDtoToJSON(value?: SetPersonPhoneDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phoneNumber': value.phoneNumber,
        'personId': value.personId,
        'phoneStatusId': value.phoneStatusId,
        'phoneCategoryId': value.phoneCategoryId,
        'phoneTypeId': value.phoneTypeId,
        'description': value.description,
        'additionalNumber': value.additionalNumber,
    };
}

