import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {AuthorizePermission, Page, PageTab, PageTabs} from "../../../components";
import {AllPermissions} from "../../../core/const";
import {PersonOthersTabsStore} from "../../../stores/management/persons/tabs/others/PersonOthersTabsStore";
import {PhoneLogo as PhoneLogoIcon} from "../../../icons/phone-logo";
import {EmailLogo as EmailLogoIcon} from "../../../icons/email-logo";
import {WebsiteLogo as WebsiteLogoIcon} from "../../../icons/website-logo";
import React from "react";
import {PersonPhoneTable} from "./phones/PersonPhoneTable";
import {PersonEmailTable} from "./emails/PersonEmailTable";
import {PersonWebsiteTable} from "./websites/PersonWebsiteTable";

interface Props {
    store: PersonOthersTabsStore;
    style?: React.CSSProperties
}

export const OthersTab = observer(({store, style}: Props) => {
    const { t } = useTranslation();

    const renderTabs = (index: number) => {
        switch (index) {
            case 0:
                return (
                    <AuthorizePermission permissions={AllPermissions.person.phones.read}>
                        <PersonPhoneTable store={store.phoneStore}/>
                    </AuthorizePermission>
                )
            case 1:
                return (
                    <AuthorizePermission permissions={AllPermissions.person.emails.read}>
                        <PersonEmailTable store={store.emailStore}/>
                    </AuthorizePermission>
                )
            case 2:
                return (
                    <AuthorizePermission permissions={AllPermissions.person.websites.read}>
                        <PersonWebsiteTable store={store.websiteStore}/>
                    </AuthorizePermission>
                )
        }
    };

    return (
        <Page
            style={style}
        >
            <PageTabs
                index={store.tabIndex}
                onChange={t => store.tabIndex = t}>
                <PageTab icon={<PhoneLogoIcon/>} title={t('common.phone')}/>
                <PageTab icon={<EmailLogoIcon/>} title={t('common.email')}/>
                <PageTab icon={<WebsiteLogoIcon/>} title={t('common.websites')}/>
            </PageTabs>
            <div style={{marginTop: 4, marginBottom: 4, padding: 15}}>
                {renderTabs(store.tabIndex)}
            </div>
        </Page>
    );
});
