/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    ClientNameDto,
    ClientNameDtoFromJSON,
    ClientNameDtoFromJSONTyped,
    ClientNameDtoToJSON,
} from './ClientNameDto';
import {
    UserDto,
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './UserDto';

/**
 * 
 * @export
 * @interface ClientDto
 */
export interface ClientDto {
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    externalId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ClientDto
     */
    createdAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ClientDto
     */
    modifiedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClientDto
     */
    ticks?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    name: string;
    /**
     * 
     * @type {ClientNameDto}
     * @memberof ClientDto
     */
    orgName: ClientNameDto;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    secret: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    description?: string | null;
    /**
     * 
     * @type {UserDto}
     * @memberof ClientDto
     */
    createdByUser?: UserDto;
}

export function ClientDtoFromJSON(json: any): ClientDto {
    return ClientDtoFromJSONTyped(json, false);
}

export function ClientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (json['modifiedAt'] === null ? null : new Date(json['modifiedAt'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'ticks': !exists(json, 'ticks') ? undefined : json['ticks'],
        'name': json['name'],
        'orgName': ClientNameDtoFromJSON(json['orgName']),
        'secret': json['secret'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'createdByUser': !exists(json, 'createdByUser') ? undefined : UserDtoFromJSON(json['createdByUser']),
    };
}

export function ClientDtoToJSON(value?: ClientDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalId': value.externalId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt === null ? null : value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt === null ? null : value.modifiedAt.toISOString()),
        'modifiedBy': value.modifiedBy,
        'ticks': value.ticks,
        'name': value.name,
        'orgName': ClientNameDtoToJSON(value.orgName),
        'secret': value.secret,
        'description': value.description,
        'createdByUser': UserDtoToJSON(value.createdByUser),
    };
}

