import { Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useEffect, useState } from 'react';

export interface CopyProps {
    copyText: string
}

export const CopyToClipboard = ({ copyText }: CopyProps) => {
    const [copied, setCopied] = useState('');

    useEffect(() => {
        return () => {
            setTimeout(() => setCopied(''), 1000)
        }
    })

    return (
        <>
            {!!copyText &&
                <Tooltip title={copyText === copied ? 'скопировано!' : 'копировать'}>
                    <ContentCopyIcon color='disabled' sx={{ '&:hover': { color: '#6366F1' } }} style={{ marginLeft: 5, fontSize: 14, cursor: 'pointer' }}
                        onClick={() => navigator.clipboard.writeText(copyText)
                            .then(() => setCopied(copyText))}
                    />
                </Tooltip>
            }
        </>
    );
};
