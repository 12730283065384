import {getApiConfiguration} from '../../../../../core';
import {OrganizationWebsiteStatusesApi} from "../../../../../services/management";
import {DictionaryCreateEntity, DictionaryEntity, DictionaryTableStore} from "../../DictionaryTableStore";
import {DictionaryOrganizationsStore} from "../DictionaryOrganizationsStore";

export class WebsiteStatusTableStore extends DictionaryTableStore {
    constructor(store: DictionaryOrganizationsStore) {
        super(store, 'website-status-store');
    }

    async Create(model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new OrganizationWebsiteStatusesApi(getApiConfiguration()).createOrganizationWebsiteStatus({setWebsiteStatusDto: model});
    }

    async Delete(id: string): Promise<DictionaryEntity> {
        return await new OrganizationWebsiteStatusesApi(getApiConfiguration()).deleteOrganizationWebsiteStatus({id: id});
    }

    async GetCount(search: string): Promise<{ count: number }> {
        return await new OrganizationWebsiteStatusesApi(getApiConfiguration()).getOrganizationWebsiteStatusesCount({search: search});
    }

    async GetList(filters: any): Promise<DictionaryEntity[]> {
        return await new OrganizationWebsiteStatusesApi(getApiConfiguration()).getOrganizationWebsiteStatuses(filters);
    }

    async Update(id: string, model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new OrganizationWebsiteStatusesApi(getApiConfiguration()).updateOrganizationWebsiteStatus({id: id, setWebsiteStatusDto: model});
    }
}