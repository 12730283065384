/* tslint:disable */
/* eslint-disable */
/**
 * Data360.DCR.Analytics
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountInfoDto,
    CountInfoDtoFromJSON,
    CountInfoDtoToJSON,
    DcrStatus,
    DcrStatusFromJSON,
    DcrStatusToJSON,
    Interval,
    IntervalFromJSON,
    IntervalToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    RequestRecordType,
    RequestRecordTypeFromJSON,
    RequestRecordTypeToJSON,
    RequestStatus,
    RequestStatusFromJSON,
    RequestStatusToJSON,
    RequestsAggregationDto,
    RequestsAggregationDtoFromJSON,
    RequestsAggregationDtoToJSON,
    RequestsByDateDto,
    RequestsByDateDtoFromJSON,
    RequestsByDateDtoToJSON,
    RequestsByResponsibilityDto,
    RequestsByResponsibilityDtoFromJSON,
    RequestsByResponsibilityDtoToJSON,
    RequestsClientsStatusDto,
    RequestsClientsStatusDtoFromJSON,
    RequestsClientsStatusDtoToJSON,
    RequestsReportDtoPagedResponse,
    RequestsReportDtoPagedResponseFromJSON,
    RequestsReportDtoPagedResponseToJSON,
} from '../models';

export interface GetClientsCountRequest {
    startDate?: Date;
    endDate?: Date;
    interval?: Interval;
}

export interface GetRequestsCountRequest {
    startDate?: Date;
    endDate?: Date;
    interval?: Interval;
}

export interface GetRequestsCountByClientRequest {
    status?: Array<RequestStatus>;
    entityType?: Array<RequestRecordType>;
    typeRequest?: Array<DcrStatus>;
    client?: string;
    startDate?: Date;
    endDate?: Date;
    interval?: Interval;
}

export interface GetRequestsCountByDateRequest {
    status?: Array<RequestStatus>;
    entityType?: Array<RequestRecordType>;
    typeRequest?: Array<DcrStatus>;
    client?: string;
    startDate?: Date;
    endDate?: Date;
    interval?: Interval;
}

export interface GetRequestsCountByEntityRequest {
    status?: Array<RequestStatus>;
    entityType?: Array<RequestRecordType>;
    typeRequest?: Array<DcrStatus>;
    client?: string;
    startDate?: Date;
    endDate?: Date;
    interval?: Interval;
}

export interface GetRequestsCountByResponsibilityRequest {
    status?: Array<RequestStatus>;
    entityType?: Array<RequestRecordType>;
    typeRequest?: Array<DcrStatus>;
    client?: string;
    startDate?: Date;
    endDate?: Date;
    interval?: Interval;
}

export interface GetRequestsReportRequest {
    startDate?: Date;
    endDate?: Date;
    order?: ListSortDirection;
    status?: Array<RequestStatus>;
    entity?: Array<RequestRecordType>;
    type?: Array<DcrStatus>;
    clients?: Array<string>;
    responsibilities?: Array<string>;
    isError?: boolean;
    orderField?: string;
    page?: number;
    take?: number;
}

export interface GetSpeedCountRequest {
    startDate?: Date;
    endDate?: Date;
    interval?: Interval;
}

export interface GetSpeedCountByDateRequest {
    startDate?: Date;
    endDate?: Date;
    interval?: Interval;
}

export interface GetStatusesCountRequest {
    startDate?: Date;
    endDate?: Date;
    interval?: Interval;
}

/**
 * 
 */
export class AnalyticsApi extends runtime.BaseAPI {

    /**
     */
    async getClientsCountRaw(requestParameters: GetClientsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['Interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/analytics/Clients/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async getClientsCount(requestParameters: GetClientsCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountInfoDto> {
        const response = await this.getClientsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRequestsCountRaw(requestParameters: GetRequestsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['Interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/analytics/Requests/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async getRequestsCount(requestParameters: GetRequestsCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountInfoDto> {
        const response = await this.getRequestsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRequestsCountByClientRaw(requestParameters: GetRequestsCountByClientRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<RequestsClientsStatusDto>>> {
        const queryParameters: any = {};

        if (requestParameters.status) {
            queryParameters['Status'] = requestParameters.status;
        }

        if (requestParameters.entityType) {
            queryParameters['EntityType'] = requestParameters.entityType;
        }

        if (requestParameters.typeRequest) {
            queryParameters['TypeRequest'] = requestParameters.typeRequest;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['Client'] = requestParameters.client;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['Interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/analytics/Requests/count/byClient`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RequestsClientsStatusDtoFromJSON));
    }

    /**
     */
    async getRequestsCountByClient(requestParameters: GetRequestsCountByClientRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<RequestsClientsStatusDto>> {
        const response = await this.getRequestsCountByClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRequestsCountByDateRaw(requestParameters: GetRequestsCountByDateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<RequestsByDateDto>>> {
        const queryParameters: any = {};

        if (requestParameters.status) {
            queryParameters['Status'] = requestParameters.status;
        }

        if (requestParameters.entityType) {
            queryParameters['EntityType'] = requestParameters.entityType;
        }

        if (requestParameters.typeRequest) {
            queryParameters['TypeRequest'] = requestParameters.typeRequest;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['Client'] = requestParameters.client;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['Interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/analytics/Request/count/byData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RequestsByDateDtoFromJSON));
    }

    /**
     */
    async getRequestsCountByDate(requestParameters: GetRequestsCountByDateRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<RequestsByDateDto>> {
        const response = await this.getRequestsCountByDateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRequestsCountByEntityRaw(requestParameters: GetRequestsCountByEntityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<RequestsAggregationDto>>> {
        const queryParameters: any = {};

        if (requestParameters.status) {
            queryParameters['Status'] = requestParameters.status;
        }

        if (requestParameters.entityType) {
            queryParameters['EntityType'] = requestParameters.entityType;
        }

        if (requestParameters.typeRequest) {
            queryParameters['TypeRequest'] = requestParameters.typeRequest;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['Client'] = requestParameters.client;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['Interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/analytics/RequestsClient/count/byEntity`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RequestsAggregationDtoFromJSON));
    }

    /**
     */
    async getRequestsCountByEntity(requestParameters: GetRequestsCountByEntityRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<RequestsAggregationDto>> {
        const response = await this.getRequestsCountByEntityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRequestsCountByResponsibilityRaw(requestParameters: GetRequestsCountByResponsibilityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<RequestsByResponsibilityDto>>> {
        const queryParameters: any = {};

        if (requestParameters.status) {
            queryParameters['Status'] = requestParameters.status;
        }

        if (requestParameters.entityType) {
            queryParameters['EntityType'] = requestParameters.entityType;
        }

        if (requestParameters.typeRequest) {
            queryParameters['TypeRequest'] = requestParameters.typeRequest;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['Client'] = requestParameters.client;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['Interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/analytics/RequestsClient/count/byResponsibility`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RequestsByResponsibilityDtoFromJSON));
    }

    /**
     */
    async getRequestsCountByResponsibility(requestParameters: GetRequestsCountByResponsibilityRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<RequestsByResponsibilityDto>> {
        const response = await this.getRequestsCountByResponsibilityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRequestsReportRaw(requestParameters: GetRequestsReportRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RequestsReportDtoPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.order !== undefined) {
            queryParameters['Order'] = requestParameters.order;
        }

        if (requestParameters.status) {
            queryParameters['Status'] = requestParameters.status;
        }

        if (requestParameters.entity) {
            queryParameters['Entity'] = requestParameters.entity;
        }

        if (requestParameters.type) {
            queryParameters['Type'] = requestParameters.type;
        }

        if (requestParameters.clients) {
            queryParameters['Clients'] = requestParameters.clients;
        }

        if (requestParameters.responsibilities) {
            queryParameters['Responsibilities'] = requestParameters.responsibilities;
        }

        if (requestParameters.isError !== undefined) {
            queryParameters['IsError'] = requestParameters.isError;
        }

        if (requestParameters.orderField !== undefined) {
            queryParameters['OrderField'] = requestParameters.orderField;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.take !== undefined) {
            queryParameters['Take'] = requestParameters.take;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/analytics/RequestsReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestsReportDtoPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async getRequestsReport(requestParameters: GetRequestsReportRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RequestsReportDtoPagedResponse> {
        const response = await this.getRequestsReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSpeedCountRaw(requestParameters: GetSpeedCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['Interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/analytics/Speed/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async getSpeedCount(requestParameters: GetSpeedCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountInfoDto> {
        const response = await this.getSpeedCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSpeedCountByDateRaw(requestParameters: GetSpeedCountByDateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<RequestsByDateDto>>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['Interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/analytics/Speed/count/byDate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RequestsByDateDtoFromJSON));
    }

    /**
     */
    async getSpeedCountByDate(requestParameters: GetSpeedCountByDateRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<RequestsByDateDto>> {
        const response = await this.getSpeedCountByDateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getStatusesCountRaw(requestParameters: GetStatusesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<RequestsAggregationDto>>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['Interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dcr/analytics/Statuses/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RequestsAggregationDtoFromJSON));
    }

    /**
     */
    async getStatusesCount(requestParameters: GetStatusesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<RequestsAggregationDto>> {
        const response = await this.getStatusesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
