import {TextField} from "@mui/material";
import {InputProps as StandardInputProps} from "@mui/material/Input/Input";
import {observer} from "mobx-react";
import React from "react";
import {DefaultValueStore} from "../DefaultValueStore";

type Variant = 'filled' | 'outlined' | 'standard';

interface Props {
    store: DefaultValueStore<any>;
    style?: React.CSSProperties;
    variant?: Variant;
    disabled?: boolean;
    title: string;
    multiline?: boolean;
    rows?: number;
    InputProps?: Partial<StandardInputProps>;
    testid?: string;
    type?: string;
    autoFocus?: boolean;
}

export const DefaultValue = observer(({
                                          store,
                                          variant = 'outlined',
                                          type = 'text',
                                          style,
                                          disabled,
                                          title,
                                          rows,
                                          multiline = false,
                                          testid,
                                          InputProps = {
                                              sx: {
                                                  fieldset: {
                                                      height: 48,
                                                  },
                                              }
                                          },
                                          autoFocus = false
                                      }: Props) => {
    return (
        <TextField
            autoFocus={autoFocus}
            value={store.value}
            type={type}
            multiline={multiline}
            variant={variant}
            rows={rows}
            label={title}
            disabled={disabled}
            error={Boolean(store.error)}
            helperText={store.error}
            style={{...{width: '100%'}, ...style}}
            onChange={(t: any) => {
                store.value = t.target.value
            }}
            size="small"
            margin='dense'
            inputProps={{"data-testid": `${testid}-value`}}
            InputProps={InputProps}
        />
    );
});
