/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    EmailTypeDto,
    EmailTypeDtoFromJSON,
    EmailTypeDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OCMObjectState,
    OCMObjectStateFromJSON,
    OCMObjectStateToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetEmailTypeDto,
    SetEmailTypeDtoFromJSON,
    SetEmailTypeDtoToJSON,
} from '../models';

export interface CreatePersonEmailTypeRequest {
    setEmailTypeDto: SetEmailTypeDto;
}

export interface DeletePersonEmailTypeRequest {
    id: string;
}

export interface GetPersonEmailTypeByIdRequest {
    id: string;
}

export interface GetPersonEmailTypeByNameRequest {
    name: string;
}

export interface GetPersonEmailTypesRequest {
    search?: string;
    orderBy?: string;
    searchByExternalId?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface GetPersonEmailTypesCountRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface UpdatePersonEmailTypeRequest {
    id: string;
    setEmailTypeDto: SetEmailTypeDto;
}

/**
 * 
 */
export class PersonEmailTypesApi extends runtime.BaseAPI {

    /**
     */
    async createPersonEmailTypeRaw(requestParameters: CreatePersonEmailTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailTypeDto>> {
        if (requestParameters.setEmailTypeDto === null || requestParameters.setEmailTypeDto === undefined) {
            throw new runtime.RequiredError('setEmailTypeDto','Required parameter requestParameters.setEmailTypeDto was null or undefined when calling createPersonEmailType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/emails/types/person`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetEmailTypeDtoToJSON(requestParameters.setEmailTypeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async createPersonEmailType(requestParameters: CreatePersonEmailTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailTypeDto> {
        const response = await this.createPersonEmailTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePersonEmailTypeRaw(requestParameters: DeletePersonEmailTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePersonEmailType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/types/person/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async deletePersonEmailType(requestParameters: DeletePersonEmailTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailTypeDto> {
        const response = await this.deletePersonEmailTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonEmailTypeByIdRaw(requestParameters: GetPersonEmailTypeByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPersonEmailTypeById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/types/person/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonEmailTypeById(requestParameters: GetPersonEmailTypeByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailTypeDto> {
        const response = await this.getPersonEmailTypeByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonEmailTypeByNameRaw(requestParameters: GetPersonEmailTypeByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailTypeDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getPersonEmailTypeByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/types/person/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonEmailTypeByName(requestParameters: GetPersonEmailTypeByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailTypeDto> {
        const response = await this.getPersonEmailTypeByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonEmailTypesRaw(requestParameters: GetPersonEmailTypesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<EmailTypeDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/types/person`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmailTypeDtoFromJSON));
    }

    /**
     */
    async getPersonEmailTypes(requestParameters: GetPersonEmailTypesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<EmailTypeDto>> {
        const response = await this.getPersonEmailTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonEmailTypesCountRaw(requestParameters: GetPersonEmailTypesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/types/person/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonEmailTypesCount(requestParameters: GetPersonEmailTypesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPersonEmailTypesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePersonEmailTypeRaw(requestParameters: UpdatePersonEmailTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePersonEmailType.');
        }

        if (requestParameters.setEmailTypeDto === null || requestParameters.setEmailTypeDto === undefined) {
            throw new runtime.RequiredError('setEmailTypeDto','Required parameter requestParameters.setEmailTypeDto was null or undefined when calling updatePersonEmailType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/emails/types/person/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetEmailTypeDtoToJSON(requestParameters.setEmailTypeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async updatePersonEmailType(requestParameters: UpdatePersonEmailTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailTypeDto> {
        const response = await this.updatePersonEmailTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
