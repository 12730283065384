import {action, makeObservable, observable} from 'mobx';
import {ManagementStore} from '..';
import {getApiConfiguration, getObject, setObject} from '../../../core';
import {callTheProcessingFunc, PagedItems, TableLocalFilters, TableStore} from '../../utils';
import {getAppoloClient, GRAPHQL_URLS} from "../../../graphql";
import {CLIENTS_QUERY} from "../../../graphql/queries.graphql";
import {DefaultValueStore} from "../../../components/shared/DefaultValueStore";
import {ClientsQuery, ClientsQueryVariables, SetClientMutation, SetClientMutationVariables} from "../../../gql/graphql";
import {DefaultFormModalStore} from "../../../components/shared/DefaultFormModalStore";
import {DefaultSelectStore} from "../../../components/shared/DefaultSelectStore";
import {UserDto, UsersApi} from "../../../services/management";
import {SET_CLIENT_MUTATION} from "../../../graphql/mutations.graphql";
import {AuthorizationState} from "../../authorization/AuthorizationStore";
import {ClientItemStore} from "./ClientItemStore";

export class ClientTableStore extends TableStore<ClientItemStore> {
    readonly store: ManagementStore;
    current: ClientItemStore | undefined | null;
    startDate: DefaultValueStore<Date> = new DefaultValueStore<Date>(null, null, (e) => this.updateState());
    endDate: DefaultValueStore<Date> = new DefaultValueStore<Date>(null, null, (e) => this.updateState());

    creator: DefaultFormModalStore<{
        name: DefaultValueStore<string>,
        clientName: DefaultValueStore<string>,
        description: DefaultValueStore<string>
        secretKey: DefaultValueStore<string>,
        owner: DefaultSelectStore<UserDto>
    }> = new DefaultFormModalStore({
        name: new DefaultValueStore<string>(""),
        clientName: new DefaultValueStore<string>("",),
        description: new DefaultValueStore<string>(""),
        secretKey: new DefaultValueStore<string>(""),
        owner: new DefaultSelectStore(null,
            (filters) => new UsersApi(getApiConfiguration()).getUsers(filters))
    }, async (e) => await callTheProcessingFunc(this, () => this.create(), () => this.updateState())())

    constructor(store: ManagementStore) {
        super();
        this.store = store;
        const filters = getObject<TableLocalFilters<ClientItemStore>>('clients-store');
        this._order = "descending";
        this._orderBy = "modifiedAt";
        if (filters) {
            this._page = filters.page;
            this._size = filters.size;
            this.search.setValueWithoutEffects(filters.search);
            this._order = filters.order;
            this._orderBy = filters.orderBy;
        }

        this.creator.fields.name.required = true;
        this.creator.fields.clientName.required = true;
        this.creator.fields.owner.required = true;
        const st = getObject<AuthorizationState>('authorization-store');
        if (st) {
            this.creator.fields.owner.setValueWithoutEffects({
                name: st?.token?.name,
                id: st?.token?.sub!
            })
        }

        makeObservable(this, {
            current: observable,
            getClient: action,
        });
    }

    async getClient(id: string): Promise<void> {
        const {data} = await getAppoloClient(GRAPHQL_URLS.CLIENTS).query<ClientsQuery, ClientsQueryVariables>({
            query: CLIENTS_QUERY, variables: {
                id: id,
                take: 1
            }
        })

        const allReady = this.items.find(x => x.dto.id === id);

        if (data.clients?.items?.length) {
            if (allReady) {
                allReady.update(data.clients?.items[0])
                this.current = allReady;
            } else {
                this.current = data.clients?.items?.length ? new ClientItemStore(this, data.clients?.items[0]) : undefined
            }
            await this.current?.updateState()
        }
    };

    async request(): Promise<PagedItems<ClientItemStore>> {
        let order: any = {};
        if (this.orderBy == "userName") {
            order = [{createdByUser: {name: this.orderByAppolo}}]
        } else {
            order[this.orderBy!] = this.orderByAppolo;
        }
        const {data} = await getAppoloClient(GRAPHQL_URLS.CLIENTS).query<ClientsQuery, ClientsQueryVariables>({
            query: CLIENTS_QUERY, variables: {
                search: this.search.value,
                endDate: this.endDate.value ?? '01.01.3000',
                startDate: this.startDate.value ?? '01.01.2000',
                skip: this.page * this.size,
                take: this.size,
                sort: order
            },
        })

        const count = data.clients?.totalCount ?? 0;
        const newList: ClientItemStore[] = [];

        for (const item of data.clients?.items ?? []) {
            const existsItem = this.items.find(t => t.dto.id === item.id);
            if (existsItem) {
                existsItem.update(item);
                newList.push(existsItem);
                continue;
            }
            newList.push(new ClientItemStore(this, item))
        }
        return new PagedItems(newList, count)
    };

    onFiltersSave() {
        setObject<TableLocalFilters<ClientItemStore>>('clients-store', {
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            page: this.page,
            size: this.size,
        });
    }

    async create(): Promise<void> {
        await getAppoloClient(GRAPHQL_URLS.CLIENTS).mutate<SetClientMutation, SetClientMutationVariables>({
            mutation: SET_CLIENT_MUTATION, variables: {
                name: this.creator.fields.name.value!,
                secret: this.creator.fields.secretKey.value,
                description: this.creator.fields.description.value,
                userId: this.creator.fields.owner.valueId!,
                orgName: this.creator.fields.clientName.value!
            }
        })
    }
}
