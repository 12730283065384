import {observer} from "mobx-react";
import {useAuthorizationStore} from "../stores";
import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";

export const Login = observer(() => {
    React.useEffect(() => {
        const redirectUri = window.location.origin + '/cb';
        const url = new URL(process.env.REACT_APP_ISSUER! + process.env.REACT_APP_CONNECT_URL!);
        url.searchParams.append("redirect_uri", redirectUri );
        window.location.replace(url);
    }, []);

    return <></>
});

export const PostLoginCallback = observer( () => {
    const store = useAuthorizationStore();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(document.location.search)
    store.code = searchParams.get('code')!;

    const getToken = async () => {
        await store.Token();
        if(store.isAuthenticated)
            navigate('/');
    }
    useEffect( () =>{
        getToken().catch(console.error);
    }, [])
    return <></>
})
