import {Box} from "@mui/material";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {AuthorizePermission, DeleteButton, FormRoot, Page, ParamsButtonGroup, ToolDivider} from "../../components";
import {DefaultValue} from "../../components/shared/components/DefaultValue";
import {IDictionaryOperations, TCurrentSectionDictionary} from "../../core";
import {DictionaryItemStore} from "../../stores/management/dictionaries/DictionaryItemStore";
import {
    TypeOrganizationItemStore
} from "../../stores/management/dictionaries/organizations/tabs/TypeOrganizations/TypeOrganizationItemStore";
import {DefaultCheckbox} from "../../components/shared/components/DefaultCheckbox";
import {useAuthorizationStore} from "../../stores";
import {useMemo} from "react";

interface DetailsProps {
    store: DictionaryItemStore | TypeOrganizationItemStore,
    style?: React.CSSProperties,
    placeholder?: string | undefined,
    sectionType?: TCurrentSectionDictionary,
    dictionaryType: IDictionaryOperations;
}

export const DictionaryItemDetails = observer(({ store, style, placeholder, sectionType, dictionaryType }: DetailsProps) => {
    const { t } = useTranslation();
    const authorizationStore = useAuthorizationStore();

    const hasInactiveUpdatePermission = useMemo<boolean>(() =>
            dictionaryType.inactiveUpdate ? authorizationStore.hasPermission(dictionaryType.inactiveUpdate) : false,
        [dictionaryType.inactiveUpdate])

    return (
        <Page style={style}
        >
            <ToolDivider />
            <div style={{ marginTop: 4, marginBottom: 4, padding: 20 }} onClick={(e) => store.store.current = store}>
                <FormRoot
                    style={{
                        margin: '0 20px',
                        flex: 1
                    }}
                >
                    <DefaultValue
                        style={{ width: '100%' }}
                        testid="personDictionaryItemsParameters-nameRus-input"
                        store={store.form.fields.nameRus}
                        disabled={store.form.processing}
                        title={placeholder ? `${placeholder} (Rus)` : `${t('common.name')} (Rus)`}/>
                    <DefaultValue
                        style={{width: '100%'}}
                        testid="personDictionaryItemsParameters-nameEng-input"
                        store={store.form.fields.nameEng}
                        disabled={store.form.processing}
                        title={placeholder ? `${placeholder} (Eng)` : `${t('common.name')} (Eng)`}/>
                    {
                        store.store.isActiveStateUsed &&
                        <AuthorizePermission permissions={dictionaryType.inactiveRead}>
                            <DefaultCheckbox
                                style={{width: '100%'}}
                                store={store.form.fields.isActive}
                                disabled={store.form.processing || !hasInactiveUpdatePermission}
                                type="switcher"
                                title={t("common.active")}/>
                        </AuthorizePermission>

                    }
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <AuthorizePermission permissions={dictionaryType.update}>
                            <ParamsButtonGroup
                                testid="personDictionaryItemsParameters"
                                onCancel={() => store.form.reset()}
                                onSave={async () => await store.form.submit()}
                                isCancelBtnDisabled={store.form.processing || store.form.equals}
                                isSaveBtnDisabled={store.form.processing || store.form.equals || !store.form.valid}
                            />
                        </AuthorizePermission>
                        <AuthorizePermission permissions={dictionaryType.delete}>
                            <DeleteButton onClick={() => {
                                store.store.current = store
                                store.store.deleteDialogState = true
                            }}
                                disabled={store.form.processing} />
                              </AuthorizePermission>
                    </Box>
                </FormRoot>
            </div>
        </Page>
    );
});
