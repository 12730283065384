/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface SetPersonDobleDto
 */
export interface SetPersonDobleDto {
    /**
     * 
     * @type {string}
     * @memberof SetPersonDobleDto
     */
    dobleId: string;
    /**
     * 
     * @type {string}
     * @memberof SetPersonDobleDto
     */
    antidobleId: string;
}

export function SetPersonDobleDtoFromJSON(json: any): SetPersonDobleDto {
    return SetPersonDobleDtoFromJSONTyped(json, false);
}

export function SetPersonDobleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetPersonDobleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dobleId': json['dobleId'],
        'antidobleId': json['antidobleId'],
    };
}

export function SetPersonDobleDtoToJSON(value?: SetPersonDobleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dobleId': value.dobleId,
        'antidobleId': value.antidobleId,
    };
}

