import {Box} from "@mui/material";
import {observer} from "mobx-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {AuthorizePermission, ParamsButtonGroup} from "../../../../components";
import {DefaultSelect} from "../../../../components/shared/components/DefaultSelect";
import {AllPermissions} from "../../../../core/const";
import {PersonEmailItemStore} from "../../../../stores/management/persons/tabs/others/emails/PersonEmailItemStore";
import {DefaultValue} from "../../../../components/shared/components/DefaultValue";

interface Props {
    store: PersonEmailItemStore;
    style?: React.CSSProperties;
}

export const PersonEmailDetails = observer(({store, style}: Props) => {
    const {t} = useTranslation();

    return (
        <Box style={{...style, padding: '16px 0 8px 16px'}}>
            <Box sx={{display: "flex", mb: 1}}>
                <DefaultValue store={store.email}
                              title={t('common.email')}
                              disabled
                              style={{width: '24%', marginRight: 16}}
                />
                <DefaultSelect store={store.status}
                               title={t('common.status')}
                               style={{width: '24%', marginRight: 16}}
                               disabled={store._store.isDisable}
                               testid="personEmailDetails-status"
                               hasLabel
                />
                <DefaultSelect store={store.type}
                               title={t('common.type')}
                               style={{width: '24%', marginRight: 16}}
                               disabled={store._store.isDisable}
                               testid="personEmailDetails-type"
                               hasLabel
                />
                <DefaultValue store={store.description}
                              title={t('common.note')}
                              disabled={store._store.isDisable}
                              style={{width: '24%'}}
                />
            </Box>
            <Box sx={{ml: 'auto', pr: 4}}>
                <AuthorizePermission permissions={AllPermissions.person.emails.update}>
                    <ParamsButtonGroup
                        testid="personEmailDetails"
                        onCancel={() => store.reset()}
                        onSave={async () => await store.submit()}
                        isCancelBtnDisabled={store.processing || store.equals}
                        isSaveBtnDisabled={store.processing || store.equals || !store.valid || store._store.isDisable}
                    />
                </AuthorizePermission>
            </Box>
        </Box>
    );
});
