import {Box, Button, Card, CircularProgress, Container, Stack} from "@mui/material";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {ExportAddressesStore} from "../../../../stores/management/data/export/tabs/ExportAddressesStore";
import React from "react";
import {CloudDownload} from "@mui/icons-material";
import {AuthorizePermission} from "../../../../components";
import {AllPermissions} from "../../../../core/const";

interface Props {
    store: ExportAddressesStore;
}

export const ExportAddresses = observer(({store}: Props) => {
    const {t} = useTranslation();

    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 3,
                    backgroundColor: 'background.default',
                }}
            >
                <Container maxWidth="xl">
                    <Card sx={{mb: 8}} style={{padding: 30, marginTop: 15}}>
                        <AuthorizePermission permissions={AllPermissions.export.address.citylocalities.read}>
                            <Stack direction="row" spacing={2} sx={{m: 2}}>
                                <Button
                                    onClick={async () => await store.uploadFile("CityLocalities")}
                                    size="small"
                                    startIcon={(
                                        <CloudDownload fontSize="medium"/>
                                    )}
                                    variant={"text"}
                                    style={{
                                        minWidth: 'unset',
                                        backgroundColor: 'transparent',
                                        lineHeight: 'unset',
                                        marginLeft: 15,
                                        fontSize: 16
                                    }}
                                    disabled={store.typeLoad["CityLocalities"].loading}
                                >{t('dataPage.export.city')}
                                </Button>
                                {store.typeLoad["CityLocalities"].loading &&
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <CircularProgress size={20}/>
                                    </Box>
                                }
                            </Stack>
                        </AuthorizePermission>

                        <AuthorizePermission permissions={AllPermissions.export.address.typelocality.read}>
                            <Stack direction="row" spacing={2} sx={{m: 2}}>
                                <Button
                                    startIcon={(
                                        <CloudDownload fontSize="medium" color="primary"/>
                                    )}
                                    onClick={async () => await store.uploadFile("TypeLocalities")}
                                    size="small"
                                    variant={"text"}
                                    style={{
                                        minWidth: 'unset',
                                        backgroundColor: 'transparent',
                                        lineHeight: 'unset',
                                        marginLeft: 15,
                                        fontSize: 16
                                    }}
                                    disabled={store.typeLoad["TypeLocalities"].loading}
                                >{t('dataPage.export.type')}
                                </Button>
                                {store.typeLoad["TypeLocalities"].loading &&
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <CircularProgress/>
                                    </Box>
                                }
                            </Stack>
                        </AuthorizePermission>

                    </Card>
                </Container>
            </Box>
        </>
    )
});
