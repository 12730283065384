import {getObject, setObject} from '../../core';
import {makeAutoObservable} from 'mobx';
import {RootStore} from "../RootStore";

interface ExpandSidebarState {
    expandSidebar: 'open' | 'close'
}

export class ExpandSidebarStore {
    readonly store: RootStore;
    private _expandSidebar: string = 'open'

    constructor(store: RootStore) {
        this.store = store;
        const st = getObject<ExpandSidebarState>('expandSidebar-store');
        if (st) {
            this._expandSidebar = st.expandSidebar;
        }
        makeAutoObservable(this);
    }

    get expandSidebar() {
        //@ts-ignore
        return this._expandSidebar;
    }

    set expandSidebar(value: 'open' | 'close') {
        this._expandSidebar = value
        this.saveState();
    }

    saveState() {
        setObject<ExpandSidebarState>('expandSidebar-store', {
            expandSidebar: this.expandSidebar,
        });
    }
}
