import type {ApexOptions} from 'apexcharts';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {Chart} from "../chart";
import {useTheme} from '@mui/material/styles';
import React, {useMemo} from "react";
import Scrollbars from 'react-custom-scrollbars';
import {EmptyData} from "../../../icons/plus";

export const useChartOptions = (labels: string[], series: number[]): ApexOptions => {
    const theme = useTheme();
    const {t} = useTranslation();


    const colors = useMemo(() => {
        return labels.map(x => '#' + Math.random().toString(16).substr(-6))
    }, [labels.length])

    return {
        chart: {
            background: 'transparent',
            stacked: false,
            toolbar: {
                show: false
            },
        },
        dataLabels: {
            enabled: false
        },
        fill: {
            opacity: 1,
            type: 'solid'
        },
        colors,
        labels,
        series,
        legend: {
            horizontalAlign: 'right',
            labels: {
                colors: theme.palette.text.secondary
            },
            position: 'right',
            show: true,
            width: 300,
            formatter(legendName: string, opts?: any): string {
                return `<div style="vertical-align: top;display: inline-block;margin-top: 5px;margin-left: 2px;width: 240px">
                            <div style="display: flex; width: 250px">
                                <div>${opts.w.config.labels[opts.seriesIndex]}</div>
                                <div style="margin-left: auto">${opts.w.config.series[opts.seriesIndex]}</div>
                            </div>
                        </div>`
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            showAlways: true,
                            fontWeight: 400,
                            color: theme.palette.text.secondary,
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce((a: number, b: number) => {
                                    return a + b
                                })
                            }
                        },
                        value: {
                            fontSize: '18px',
                            fontWeight: 800,
                        },
                        name: {
                            color: theme.palette.text.secondary,
                            formatter: function (w) {
                                return w == 'Total' ? t("common.allCount") : w
                            }
                        },
                    }
                }
            }
        },
        states: {
            active: {
                filter: {
                    type: 'none'
                }
            },
            hover: {
                filter: {
                    type: 'none'
                }
            }
        },
        stroke: {
            width: 0
        },
        theme: {
            mode: theme.palette.mode
        },
        tooltip: {
            fillSeriesColor: false
        }
    };
};

export interface PipeChartProps {
    apexOptions: ApexOptions;
}

export const PipeChart = observer(({apexOptions}: PipeChartProps) => {
    const {t} = useTranslation();
    const theme = useTheme();

    return (
        <Grid container
              disableEqualOverflow
              spacing={{xs: 3, lg: 4}}>

            {
                apexOptions.series && apexOptions.series?.length > 0 ?
                    <>
                        <Grid xs={12} md={12}>
                            <Chart
                                height={250}
                                options={apexOptions}
                                series={apexOptions.series}
                                type="donut"
                            />
                        </Grid>
                    </> :
                    <div style={{
                        height: 250,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                    }}>
                        <EmptyData sx={{stroke: theme.palette.text.secondary,fill:theme.palette.text.secondary, height: 200, width: 200}}></EmptyData>
                        <Typography
                            style={{marginTop: -30}}
                            color="text.secondary"
                            component="h4"
                            variant="inherit"
                        >
                            {t("common.empty")}
                        </Typography>
                    </div>
            }
        </Grid>
    );
});