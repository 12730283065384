import {makeAutoObservable} from "mobx";
import {IFormValueWithError, ILoad} from "../../core";
import {getValueInObject} from "../../core/getValueInObject";
import type {DebouncedFunc} from 'lodash';
import throttle from 'lodash.throttle';
import {t} from "i18next";

export class DefaultSelectStore<T> implements IFormValueWithError<T>, ILoad {
    readonly _apiGetMethod: ((filters: {}) => Promise<Array<T>>) | null | T[]
    public onChanged: ((value: T | null) => void)[] = [];
    public debounceRequest: DebouncedFunc<() => Promise<void>>;
    public validatorFuncAsync: ((value: T | null | undefined) => Promise<string>) | null = null;
    private _dto: T | null = null;
    private _value: T | null = null;
    private delay = 1000;

    isSearchById: boolean = false;
    items: T[] = [];
    _search: string = '';
    loading: boolean = false;
    error: string = '';
    processing: boolean = false;
    valueExp?: string = 'id';
    public validatorFunc: ((value: T | null) => string) | null;
    public filterOptions?: (value: T[]) => T[];
    public filterOption?: (value: T) => boolean;
    public nameExp: string | ((dto: T) => string);
    public defaultFirstValue: boolean = false;

    required: boolean = false;
    type: string = 'select';

    defaultSort: boolean = false;
    defaultSearch: boolean = false;

    getReadValue: (value: T | null) => string = (value) => !!value ? this.name! : t("common.empty");

    constructor(dto: T | null,
                apiGetMethod: ((filters: {}) => Promise<Array<T>>) | null | T[],
                validator: ((value: T | null) => string) | null = null,
                valueExp: string = 'id',
                nameExp: string | ((dto: T) => string) = 'nameRus') {
        this._dto = dto;
        this._value = dto;
        this.valueExp = valueExp;
        this.validatorFunc = validator;
        this._apiGetMethod = apiGetMethod;
        this.nameExp = nameExp;
        this.debounceRequest = throttle(this.request, this.delay, {leading: true, trailing: true});

        makeAutoObservable(this);
    }

    async setSearch(value: string): Promise<void> {
        this._search = value;
        await this.pull();
    }

    get readValue(): string {
        return this.getReadValue(this.value)
    }

    get search() {
        return this._search;
    }

    set search(x: string) {
        this._search = x;
    }

    get equals(): boolean {
        const init = this.valueExp ? getValueInObject(this._dto, this.valueExp) : this._dto;
        const now = this.valueExp ? getValueInObject(this._value, this.valueExp) : this._value;
        return init === now;
    }

    get valid(): boolean {
        return !Boolean(this.error);
    }

    get value(): T | null {
        return this._value;
    }

    get valueId(): any | null {
        return this.value ? (this.valueExp ? getValueInObject(this._value, this.valueExp) : this.value) : null;
    }

    set value(value: T | null) {
        this._value = value;
        this.validate();
        if (this.onChanged.length > 0) {
            this.onChanged.forEach(x => x(this._value))
        }
    }

    get name(): string | null {
        return this.value ? getValueInObject(this.value, this.nameExp) : "";
    }

    async validate() {
        this.error = ''
        if (this.required) {
            this.error = !this._value ? t("common.required") : ""
            if (!!this.error)
                return
        }
        if (this.validatorFunc) {
            this.error = this.validatorFunc(this._value)
            if (!!this.error)
                return
        }
        if (this.validatorFuncAsync) {
            this.loading = true
            this.error = await this.validatorFuncAsync(this._value)
            this.loading = false
        }
    }

    update(dto: T | null | undefined) {
        this._dto = dto ?? null;
        this.reset();
    }

    reset() {
        this._value = this._dto;
        this.error = "";
        this.search = "";
    }

    setValueWithoutEffects(value: T | null) {
        this._value = value;
        this.validate()
    }

    setValidator(validator: ((value: T | null | undefined) => string) | null = null) {
        this.validatorFunc = validator;
        this.validate()
    }

    get isItemFunctions(): boolean {
        return typeof (this._apiGetMethod) == 'function';
    }

    async pull(): Promise<void> {
        if (!this.isItemFunctions)
            await this.request()

        else {
            this.loading = true;
            await this.debounceRequest();
        }
    };

    private defaultSorting(arr: T[]): T[] {
        const context = this;
        return arr.sort((i, ii) => {
            const name = getValueInObject(i, context.nameExp)
            const name2 = getValueInObject(ii, context.nameExp)

            const indexName = name.indexOf(context.search)
            const indexName2 = name2.indexOf(context.search)

            let sortName = indexName == 0 && indexName2 == 0 ? name.replace(context.search, '').toLowerCase() : name.toLowerCase();
            let sortName2 = indexName == 0 && indexName2 == 0 ? name2.replace(context.search, '').toLowerCase() : name2.toLowerCase();

            if (sortName.toLowerCase() < sortName2.toLowerCase()) {
                return -1;
            }
            if (sortName.toLowerCase() > sortName2.toLowerCase()) {
                return 1;
            }
            return 0;
        })
    }

    async request(): Promise<void> {
        let filters: {};
        if (new RegExp(/^\w{8}.\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/).test(this._search) && this.isSearchById) {
            filters = {
                page: 1,
                size: 500,
                searchById: this._search
            };
        } else {
            filters = {
                page: 1,
                size: 500,
                search: this._search
            };
        }

        let items = typeof (this._apiGetMethod) == 'function' ? await this._apiGetMethod(filters) : this._apiGetMethod as Array<T>;

        if (this.filterOption) {
            items = items.filter(this.filterOption);
        }

        this.items = this.defaultSort ? this.defaultSorting(items) : items;

        if (this.defaultFirstValue && !this.value && this.items[0]) {
            this.value = this.items[0]
        }

        if (this.defaultSearch) {
            this.items = this.items.filter(x => getValueInObject(x, this.nameExp).toLowerCase().indexOf(this._search) > -1)
        }

        this.loading = false;
    }
}
