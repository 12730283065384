/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Exchange.Analytics.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EntityType,
    EntityTypeFromJSON,
    EntityTypeFromJSONTyped,
    EntityTypeToJSON,
} from './EntityType';
import {
    FeedStatus,
    FeedStatusFromJSON,
    FeedStatusFromJSONTyped,
    FeedStatusToJSON,
} from './FeedStatus';

/**
 * 
 * @export
 * @interface ElasticFeed
 */
export interface ElasticFeed {
    /**
     * 
     * @type {string}
     * @memberof ElasticFeed
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ElasticFeed
     */
    feedId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ElasticFeed
     */
    clientId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ElasticFeed
     */
    clientName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ElasticFeed
     */
    entityId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ElasticFeed
     */
    rootEntityId?: string | null;
    /**
     * 
     * @type {EntityType}
     * @memberof ElasticFeed
     */
    entityType?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof ElasticFeed
     */
    feedTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ElasticFeed
     */
    specialityId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ElasticFeed
     */
    specialityName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ElasticFeed
     */
    organizationTypeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ElasticFeed
     */
    organizationTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ElasticFeed
     */
    data?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ElasticFeed
     */
    track?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ElasticFeed
     */
    jobType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ElasticFeed
     */
    actionType?: string | null;
    /**
     * 
     * @type {FeedStatus}
     * @memberof ElasticFeed
     */
    status?: FeedStatus;
    /**
     * 
     * @type {string}
     * @memberof ElasticFeed
     */
    reason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ElasticFeed
     */
    parentFeedId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ElasticFeed
     */
    batchId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ElasticFeed
     */
    revision?: number;
    /**
     * 
     * @type {Date}
     * @memberof ElasticFeed
     */
    completedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ElasticFeed
     */
    created?: Date;
}

export function ElasticFeedFromJSON(json: any): ElasticFeed {
    return ElasticFeedFromJSONTyped(json, false);
}

export function ElasticFeedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElasticFeed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'feedId': !exists(json, 'feedId') ? undefined : json['feedId'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'clientName': !exists(json, 'clientName') ? undefined : json['clientName'],
        'entityId': !exists(json, 'entityId') ? undefined : json['entityId'],
        'rootEntityId': !exists(json, 'rootEntityId') ? undefined : json['rootEntityId'],
        'entityType': !exists(json, 'entityType') ? undefined : EntityTypeFromJSON(json['entityType']),
        'feedTypeName': !exists(json, 'feedTypeName') ? undefined : json['feedTypeName'],
        'specialityId': !exists(json, 'specialityId') ? undefined : json['specialityId'],
        'specialityName': !exists(json, 'specialityName') ? undefined : json['specialityName'],
        'organizationTypeId': !exists(json, 'organizationTypeId') ? undefined : json['organizationTypeId'],
        'organizationTypeName': !exists(json, 'organizationTypeName') ? undefined : json['organizationTypeName'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'track': !exists(json, 'track') ? undefined : json['track'],
        'jobType': !exists(json, 'jobType') ? undefined : json['jobType'],
        'actionType': !exists(json, 'actionType') ? undefined : json['actionType'],
        'status': !exists(json, 'status') ? undefined : FeedStatusFromJSON(json['status']),
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'parentFeedId': !exists(json, 'parentFeedId') ? undefined : json['parentFeedId'],
        'batchId': !exists(json, 'batchId') ? undefined : json['batchId'],
        'revision': !exists(json, 'revision') ? undefined : json['revision'],
        'completedAt': !exists(json, 'completedAt') ? undefined : (new Date(json['completedAt'])),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
    };
}

export function ElasticFeedToJSON(value?: ElasticFeed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'feedId': value.feedId,
        'clientId': value.clientId,
        'clientName': value.clientName,
        'entityId': value.entityId,
        'rootEntityId': value.rootEntityId,
        'entityType': EntityTypeToJSON(value.entityType),
        'feedTypeName': value.feedTypeName,
        'specialityId': value.specialityId,
        'specialityName': value.specialityName,
        'organizationTypeId': value.organizationTypeId,
        'organizationTypeName': value.organizationTypeName,
        'data': value.data,
        'track': value.track,
        'jobType': value.jobType,
        'actionType': value.actionType,
        'status': FeedStatusToJSON(value.status),
        'reason': value.reason,
        'parentFeedId': value.parentFeedId,
        'batchId': value.batchId,
        'revision': value.revision,
        'completedAt': value.completedAt === undefined ? undefined : (value.completedAt.toISOString()),
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
    };
}

