/* tslint:disable */
/* eslint-disable */
/**
 * Data360.DCR.Analytics
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DcrStatus,
    DcrStatusFromJSON,
    DcrStatusFromJSONTyped,
    DcrStatusToJSON,
} from './DcrStatus';
import {
    RequestRecordType,
    RequestRecordTypeFromJSON,
    RequestRecordTypeFromJSONTyped,
    RequestRecordTypeToJSON,
} from './RequestRecordType';
import {
    RequestStatus,
    RequestStatusFromJSON,
    RequestStatusFromJSONTyped,
    RequestStatusToJSON,
} from './RequestStatus';

/**
 * 
 * @export
 * @interface RequestsReportDto
 */
export interface RequestsReportDto {
    /**
     * 
     * @type {string}
     * @memberof RequestsReportDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestsReportDto
     */
    clientName?: string | null;
    /**
     * 
     * @type {RequestStatus}
     * @memberof RequestsReportDto
     */
    status?: RequestStatus;
    /**
     * 
     * @type {number}
     * @memberof RequestsReportDto
     */
    statusSort?: number;
    /**
     * 
     * @type {DcrStatus}
     * @memberof RequestsReportDto
     */
    type?: DcrStatus;
    /**
     * 
     * @type {number}
     * @memberof RequestsReportDto
     */
    typeSort?: number;
    /**
     * 
     * @type {RequestRecordType}
     * @memberof RequestsReportDto
     */
    entity?: RequestRecordType;
    /**
     * 
     * @type {number}
     * @memberof RequestsReportDto
     */
    entitySort?: number;
    /**
     * 
     * @type {Date}
     * @memberof RequestsReportDto
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RequestsReportDto
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof RequestsReportDto
     */
    responsibleId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestsReportDto
     */
    responsibleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestsReportDto
     */
    data360Id?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RequestsReportDto
     */
    isErrorRequest?: boolean | null;
}

export function RequestsReportDtoFromJSON(json: any): RequestsReportDto {
    return RequestsReportDtoFromJSONTyped(json, false);
}

export function RequestsReportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestsReportDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clientName': !exists(json, 'clientName') ? undefined : json['clientName'],
        'status': !exists(json, 'status') ? undefined : RequestStatusFromJSON(json['status']),
        'statusSort': !exists(json, 'statusSort') ? undefined : json['statusSort'],
        'type': !exists(json, 'type') ? undefined : DcrStatusFromJSON(json['type']),
        'typeSort': !exists(json, 'typeSort') ? undefined : json['typeSort'],
        'entity': !exists(json, 'entity') ? undefined : RequestRecordTypeFromJSON(json['entity']),
        'entitySort': !exists(json, 'entitySort') ? undefined : json['entitySort'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (new Date(json['modifiedDate'])),
        'responsibleId': !exists(json, 'responsibleId') ? undefined : json['responsibleId'],
        'responsibleName': !exists(json, 'responsibleName') ? undefined : json['responsibleName'],
        'data360Id': !exists(json, 'data360Id') ? undefined : json['data360Id'],
        'isErrorRequest': !exists(json, 'isErrorRequest') ? undefined : json['isErrorRequest'],
    };
}

export function RequestsReportDtoToJSON(value?: RequestsReportDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'clientName': value.clientName,
        'status': RequestStatusToJSON(value.status),
        'statusSort': value.statusSort,
        'type': DcrStatusToJSON(value.type),
        'typeSort': value.typeSort,
        'entity': RequestRecordTypeToJSON(value.entity),
        'entitySort': value.entitySort,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate.toISOString()),
        'responsibleId': value.responsibleId,
        'responsibleName': value.responsibleName,
        'data360Id': value.data360Id,
        'isErrorRequest': value.isErrorRequest,
    };
}

