import { TableHead, TableRow } from "@mui/material";

type Props = {
    children: React.ReactNode;
};

export const Head = ({ children, ...other }: Props) => {

    return (
        <TableHead {...other}>
            <TableRow>
                { children }
            </TableRow>
        </TableHead>
    );
};