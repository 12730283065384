/* tslint:disable */
/* eslint-disable */
/**
 * Data360.DCR.Analytics
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ReportExecutionState = {
    InProcess: 'InProcess',
    Finished: 'Finished',
    Error: 'Error'
} as const;
export type ReportExecutionState = typeof ReportExecutionState[keyof typeof ReportExecutionState];


export function ReportExecutionStateFromJSON(json: any): ReportExecutionState {
    return ReportExecutionStateFromJSONTyped(json, false);
}

export function ReportExecutionStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportExecutionState {
    return json as ReportExecutionState;
}

export function ReportExecutionStateToJSON(value?: ReportExecutionState | null): any {
    return value as any;
}

