import {Box, FormControlLabel, List, Switch, Typography,} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {
    AuthorizePermission,
    ConfirmationForm,
    DeleteButton,
    ExpertDialog,
    FormRoot,
    FormRow,
    ParamsButtonGroup,
} from '../../components';
import {CopyToClipboard} from '../../components/CopyToClipboard';
import {UpdateTickIconButton} from '../../components/IconButtons';
import {DefaultMultiSelect} from '../../components/shared/components/DefaultMultiSelect';
import {DefaultSelect} from '../../components/shared/components/DefaultSelect';
import {DefaultValue} from '../../components/shared/components/DefaultValue';
import {AllPermissions, PersonStatusesNew} from '../../core/const';
import {useSettings} from '../../core/hooks/use-settings';
import {DcrListItem} from '../dcr/DcrListItem';
import {NavLink} from 'react-router-dom';
import {Link as LinkIcon} from "../../icons/link";
import {PersonItemStore} from '../../stores/management/persons/PersonItemStore';

interface Props {
    person: PersonItemStore;
    style?: React.CSSProperties;
}

export const Parameters = observer(({ person, style }: Props) => {
    const { t } = useTranslation();
    const settings = useSettings();

    return (
        <>
            <ExpertDialog
                title="Delete person"
                open={person.deleteDialogState}
                onClose={() => (person.deleteDialogState = false)}
            >
                <ConfirmationForm
                    disabled={person.processing}
                    onSubmit={async () => await person.delete()}
                    onClose={() => (person.deleteDialogState = false)}
                >
                    {t('common.deleteConfirm')} "{person.pName.value?.nameRus}{' '}
                    {person.pMiddleName.value?.nameRus}{' '}
                    {person.pSurname.value?.nameRus}"?
                </ConfirmationForm>
            </ExpertDialog>
            <FormRoot style={style}>
                <List style={{ marginBottom: 6 }}>
                    <DcrListItem
                        name={`Id ${t('common.persons').toLowerCase()}`}
                    >
                        {`${person._dto.id} `}
                        <CopyToClipboard copyText={person._dto.id} />
                    </DcrListItem>
                </List>
                <FormRow
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <DefaultSelect
                        store={person.pSurname}
                        disabled={person.disable}
                        title={t('table.surname')}
                        style={{width: '33%'}}
                        testid="parameters-surname"
                        hasLabel
                    />
                    <DefaultSelect
                        store={person.pName}
                        disabled={person.disable}
                        title={t('table.name')}
                        style={{width: '33%'}}
                        testid="parameters-name"
                        hasLabel
                    />
                    <DefaultSelect
                        store={person.pMiddleName}
                        disabled={
                            person.hasNoMiddleName ||
                            person.disable
                        }
                        title={t('table.middleName')}
                        style={{width: '33%'}}
                        testid="parameters-middleName"
                        hasLabel
                    />
                </FormRow>
                <FormRow
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <DefaultSelect
                        store={person.personStatus}
                        disabled={
                            person.personStatus.value?.id !==
                            PersonStatusesNew.NoActiveJobs || person.disable
                        }
                        title={t('common.status')}
                        style={{ width: '32%' }}
                        filterOptions={(dto) =>
                            dto.filter(
                                (x) =>
                                    x.id === PersonStatusesNew.NoActiveJobs ||
                                    x.id === PersonStatusesNew.Dead
                            )
                        }
                        testid="parameters-status"
                        hasLabel
                    />
                    <DefaultSelect
                        store={person.pGender}
                        disabled={person.disable}
                        title={t('table.gender')}
                        style={{ width: '32%' }}
                        testid="parameters-gender"
                        hasLabel
                    />
                    <Box
                        style={{
                            width: '32%',
                            padding: '0 0 10px 12px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={person.hasNoMiddleName}
                                    onChange={() =>
                                        person.changeHasNoMiddleName()
                                    }
                                    disabled={person.disable}
                                />
                            }
                            label={
                                <Typography variant="subtitle2">
                                    {t('common.emptyMiddleName')}
                                </Typography>
                            }
                            key={person.id}
                        />
                    </Box>
                </FormRow>
                {person._dto.personStatus?.id === PersonStatusesNew.Double && (
                    <FormRow sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <DefaultValue
                            store={person.doble}
                            style={{
                                marginRight: 5,
                                marginTop: 24,
                                width: '50%',
                                paddingRight: 4,
                            }}
                            title={`${t('common.antiDoble')} ID`}
                            disabled={true}
                            testid="parameters-antiDoble"
                        />
                        <Box sx={{ mt: 2.5}}>
                            <CopyToClipboard copyText={person.doble.value!} />
                        </Box>
                        <Box sx={{ mt: 2.5, ml: 2, mr: 1 , display: 'flex', alignItems: 'center'}}>
                            <NavLink
                                to={`/persons/${person.doble.value!}`}
                                target="_blank">
                                    <LinkIcon fontSize="small"/>
                            </NavLink>
                        </Box>
                    </FormRow>
                )}
                <FormRow
                    style={{ display: 'flex', justifyContent: 'space-between',  height: 'max-content' }}
                >
                    <div style={{width: '49%',  height: 'max-content'}}>
                        <AuthorizePermission
                            permissions={AllPermissions.person.tags.read}
                        >
                            <DefaultMultiSelect
                                disabled={person.disable}
                                store={person.tagsControl}
                                testid="tags-parameters"
                                tags
                                title={t('common.tags')}
                                valueExp="id"
                            />
                        </AuthorizePermission>
                    </div>

                    <DefaultSelect
                        store={person.pMiddename}
                        disabled={person.disable}
                        title={t('table.maidenname')}
                        style={{width: '49%', marginTop: 8}}
                        hasLabel
                    />
                </FormRow>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <AuthorizePermission
                        permissions={AllPermissions.person.update}
                    >
                        <ParamsButtonGroup
                            testid="parameters"
                            onCancel={() => person.reset()}
                            onSave={async () => await person.save()}
                            isCancelBtnDisabled={
                                person.processing ||
                                person.equals ||
                                person.disable
                            }
                            isSaveBtnDisabled={
                                person.processing ||
                                person.equals ||
                                !person.valid ||
                                person.disable
                            }
                        />
                        <UpdateTickIconButton
                            onClick={async () => await person.updateTick()}
                            testid="parameters"
                        />
                    </AuthorizePermission>
                    <AuthorizePermission
                        permissions={AllPermissions.person.delete}
                    >
                        <DeleteButton
                            onClick={() => (person.deleteDialogState = true)}
                            disabled={
                                person.processing ||
                                person.disable
                            }
                            testid="parameters"
                        />
                    </AuthorizePermission>
                </Box>
            </FormRoot>
        </>
    );
});

