import {observer} from "mobx-react";
import {AuthorizePermission, PageBody, ParamsButtonGroup, Progress} from "../../../../components";
import React from "react";
import {TreeView} from '@mui/x-tree-view/TreeView';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {Box, Checkbox, CircularProgress, FormControlLabel} from "@mui/material";
import {MbEmptyComponent} from "../../../../components/MbEmptyComponent";
import {AllPermissions} from "../../../../core/const";
import {DefaultValue} from "../../../../components/shared/components/DefaultValue";
import {LocalityItemStore} from "../../../../stores/management/clients/tabs/bounds/items/Locality/LocalityItemStore";
import {LocalityTableStore} from "../../../../stores/management/clients/tabs/bounds/items/Locality/LocalityTableStore";

const StyledTreeItem = observer((props: { store: LocalityItemStore }) => (
    <TreeItem
        key={props.store.dto.id}
        nodeId={props.store.dto.id}
        onClick={async (e: any) => {
            props.store.expand = !props.store.expand
        }}
        collapseIcon={props.store.loaded && props.store.value.length == 0 ? <></> : <ExpandMoreIcon/>}

        label={
            <FormControlLabel
                control={
                    <Checkbox
                        checked={props.store.selected}
                        indeterminate={props.store.indeterminate}
                        style={{marginLeft: 5}}
                        onChange={async event => {
                            props.store.setSelected(event.target.checked)
                        }}
                        onClick={e => e.stopPropagation()}
                    />
                }
                label={<Box sx={{display: 'flex'}}>
                    <div onClick={e => e.stopPropagation()}>{props.store.dto.nameRus}</div>
                    {
                        props.store.loading && <CircularProgress size={15} style={{marginTop: 5, marginLeft: 10}}/>
                    }
                </Box>}
                key={props.store.dto.id}
            />
        }
    >
        <TreeItem nodeId={props.store.dto.id + "load"}/>
        {props.store.value.filter(x => !!x?.visibility).map(node =>
            <StyledTreeItem store={node}/>)}
    </TreeItem>
));


interface Props {
    store: LocalityTableStore;
    style?: React.CSSProperties
}

export const LocalityTable = observer(({store, style}: Props) => {
    return (
        <Box sx={{minHeight: '250px', display: "grid"}}>
            <Box
                sx={{
                    mt: 3,
                    mb: 3
                }}
                style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}
            >
                <DefaultValue
                    store={store.search}
                    title="Поиск"
                    style={{width: '100%', marginRight: 8}}
                />
            </Box>
            <PageBody style={{display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
                <MbEmptyComponent
                    isEmpty={!store.loading && !store.value.length}
                    position={'relative'}
                    size={150}>
                    <TreeView
                        aria-label="customized"
                        expanded={store.selectedExpandedIds}
                        defaultExpandIcon={<ChevronRightIcon/>}
                        sx={{maxHeight: '500px', flexGrow: 1, width: '100%', minWidth: '200px'}}
                    >
                        {
                            store.value.length > 0 &&
                            store.value?.map(x =>
                                <StyledTreeItem store={x}/>
                            )
                        }
                    </TreeView>
                    {(store.loading) && <Box
                        sx={{
                            flexGrow: 1,
                            py: 3,
                        }}
                    >
                        <Progress store={store}/>
                    </Box>
                    }
                </MbEmptyComponent>
            </PageBody>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mt: 'auto'
                }}
            >
                <AuthorizePermission permissions={AllPermissions.client.update}>
                    <ParamsButtonGroup
                        testid="localityTableBound"
                        onCancel={() => store.reset()}
                        onSave={async () => await store.save()}
                        isCancelBtnDisabled={store.processing || store.equals}
                        isSaveBtnDisabled={store.processing || store.equals}
                    />
                </AuthorizePermission>
            </Box>
        </Box>
    );
});
