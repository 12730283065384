import {Grid, Typography} from "@mui/material";

export interface TitleProps {
    children?: React.ReactNode
    title: string
}

export const TabTitle = ({children, title}: TitleProps) => {
    return (
        <Grid
            container
            justifyContent="space-between"
            spacing={2}
        >
            <Grid item>
                <Typography variant="h5" data-testid={`TabTitle-${title}`}>
                    {title}
                </Typography>
            </Grid>
            {!!children && <Grid item>
                {children}
            </Grid>}
        </Grid>
    );
};
