import {makeAutoObservable} from "mobx";
import {IExpanded, IProcessing, setObject} from "../../../../core";
import {RequestTableStore} from "./RequestTableStore";
import {RequestDetailsStore} from "./RequestDetailsStore";
import {RequestApi, RequestDto} from "../../../../services/dcr";
import {mappingDcrStatusToString, mappingRequestStatusToString, mappingRequestTypeToString,} from "../services";
import {DCRStatusEnum, RequestStatusEnum} from "../types";
import moment from "moment";

export class RequestItemStore implements IExpanded, IProcessing {
    readonly _store: RequestTableStore;
    dto: RequestDto;
    details: RequestDetailsStore;
    processing: boolean = false;
    id: string;
    externalId: string;
    private _expand: boolean = false;

    private endCompletedStatuses: RequestStatusEnum[] = [
        RequestStatusEnum.Verified,
        RequestStatusEnum.Partialy,
    ]

    private endDeclineStatuses: RequestStatusEnum[] = [
        RequestStatusEnum.Decline,
        RequestStatusEnum.Double,
    ]

    private endStatuses: RequestStatusEnum[] = [...this.endCompletedStatuses, ...this.endDeclineStatuses]

    constructor(store: RequestTableStore, dto: RequestDto) {
        this._store = store;
        this.dto = dto;
        this.id = dto.id;
        this.externalId = dto.externalId ?? '';
        this.details = new RequestDetailsStore(this, dto);
        makeAutoObservable(this);
    }


    get expand() {
        return this._expand;
    }

    get rowColor(): string {
        if (this.dto.isErrorRequest)
            switch (this._store.store.store.themeColorStore.themeSettings) {
                case "dark": {
                    return "#8f353a";
                }
                case "light": {
                    return "#F47171";
                }
                default: {
                    return "unset"
                }
            }
        else return "unset"
    }

    get fontColor(): string {
        return this._store.store.store.themeColorStore.themeSettings === "dark" ? "#65748B" : "black";
    }

    get isEndStatus(): boolean {
        return this.endStatuses.indexOf(this.dto.status) > -1
    }

    get isCompletedStatus(): boolean {
        return this.endCompletedStatuses.indexOf(this.dto.status) > -1
    }

    set expand(value: boolean) {
        this._expand = value;
        this.saveFilters();
        if (this.expand) {
            this.details.pull().then();
        }
    }

    get takeUnTake(): boolean {
        return (this.dto.status == RequestStatusEnum.WaitingVerification || this.dto.status == RequestStatusEnum.OnVerification) &&
            (this.isTake || !this.dto.responsibleId)
    }

    saveFilters() {
        setObject<IExpanded>(`requestItem-tore-${this.dto.id}`, {
            expand: this._expand
        });
    }

    get isTake(): boolean {
        return  (this._store.store.store.authorizationStore.isAdmin || this.dto.responsibleId == this._store.store.store.authorizationStore.id) &&
            this.dto.status == RequestStatusEnum.OnVerification;
    }

    async take() {
        this.processing = true;
        try {
            let result = await new RequestApi().apiDcrV1RequestsTakePost({
                takeRequestResponsibleCommand: {
                    id: this.dto.id,
                    groupId: this._store.dcrGroup.value?.id
                }
            });
            this._expand = true;
            await this.update(result);
        } finally {
            this.processing = false;
        }
    }

    async update(dto: RequestDto) {
        this.dto = dto;
        if(this.expand){
            await this.details.update(this.dto)
        }
    }

    async reloadCorrect() {
        this.processing = true;
        try {
            await this.update({...this.dto, ...await new RequestApi().apiDcrV1RequestsCheckRequestPut({requestId: this.dto.id})})
        } 
        finally {
            this.processing = false;
        }
    }


    async downloadMissedFieldsData() {
        this.processing = true;
        try {
            await this.update({...this.dto, ...await new RequestApi().apiDcrV1RequestsUpdateEmptyFieldsDataPut({requestId: this.dto.id, emptyFields: this.emptyFirstValues})})
        } 
        catch{
        }
        finally {
            await this.details.update(this.dto)
            this.processing = false;
        }
    }

    async deleteRequest() {
        this.processing = true;
        try {
            await new RequestApi().apiDcrV1RequestsIdDelete({id: this.dto.id})
        } catch {
        } finally {
            this.processing = false;
            await this._store.updateState()
            this.details.deleteDialogState = false;
        }
    }

    async unpin() {
        this.processing = true;
        let result = await new RequestApi().apiDcrV1RequestsUntakePost({
            unTakeRequestResponsibleCommand: {
                id: this.dto.id
            }
        });
        await this.update(result);
        this.processing = false;
    }

    async onPinOrUnpin() {
        if (this.isTake) {
            await this.unpin();
        } else {
            await this.take();
        }
    }

    get statusName(): string {
        return mappingRequestStatusToString(this.dto.status)
    }

    get modifiedDate(): string {
        return moment(this.dto.modifiedDate!).format('DD/MM/YYYY HH:mm')
    }

    get createdDate(): string {
        return moment(this.dto.creationDate!).format('DD/MM/YYYY HH:mm')
    }

    get typesName(): string {
        return mappingRequestTypeToString(this.dto.type)
    }

    get isAz(): boolean {
        return this.dto.client == "AZ";
    }

    get hasEmptyFirstValues() : boolean{
        return this.details.firstRequestFields.filter(x => !x.value).length === 0
    }

    get emptyFirstValues() : string[] {
        let x = this.details.firstRequestFields.filter(x => !x.value).map(z =>z.field) as string[] ;
        return x;
    }

    get dcrStatusName(): string {
        return mappingDcrStatusToString(this.dto.dcrStatus as DCRStatusEnum) +
            (this.isAz && this.dto.dcrStatus === DCRStatusEnum.Update ? ` (${this.dto!.additionalFields?.azType})` : "") +
            (!!this.dto.additionalFields?.escalationComment ? ` - Эскалация` : "")
    }
}

