import {makeAutoObservable} from 'mobx';
import type {DebouncedFunc} from 'lodash';
import {debounce} from "lodash";
import {IFormValueWithError, ILoad} from "../../core";
import {t} from "i18next";
import {getValueInObject} from "../../core/getValueInObject";

export class DefaultMultiSelectStore<T> implements IFormValueWithError<T[]>, ILoad {

    readonly _Inititems: ((filters: {}) => Promise<Array<T>>) | null | T[];
    readonly _setValue: ((value: T[]) => void) | null;
    private delay = 600;
    private readonly debounceRequest: DebouncedFunc<any> = debounce(this.request.bind(this), this.delay)
    items: T[] = [];
    private _value: T[];
    firstValue: T[];

    _search: string = '';
    loading: boolean = false;

    valueExp?: string = 'id';
    public validatorFunc: ((value: T[] | null) => string) | null;
    nameExp: string | ((dto: T) => string);
    error: string = '';
    required: boolean = false;
    public validatorFuncAsync: ((value: T[] | null | undefined) => Promise<string>) | null = null;
    public onChanged: ((value: T[] | null) => void)[] = [];

    constructor(value: T[] = [],
                apiGetMethod: ((filters: {}) => Promise<Array<T>>) | null | T[],
                setValue: ((value: T[]) => void) | null = null,
                validator: ((value: T[] | null) => string) | null = null,
                valueExp: string = 'id',
                nameExp: string | ((dto: T) => string) = 'nameRus') {
        this._Inititems = apiGetMethod || null;
        this._setValue = setValue;
        this._value = value;
        this.firstValue = value;
        this.valueExp = valueExp;
        this.validatorFunc = validator;
        this.nameExp = nameExp;

        makeAutoObservable(this);
    }

    get search() {
        return this._search;
    }

    async setSearch(value: string): Promise<void> {
        this._search = value;
        this.items = []
        await this.pull()
    }

    get value() {
        return this._value;
    }


    update(dto: T[]) {
        this.firstValue = dto;
        this.reset();
    }

    setValueWithoutEffects(value: T[]){
        this._value = value;
        this.validate()
    }

    set value(value: T[]) {
        this._value = value;
        this.validate();
        if (this._setValue)
            this._setValue(value)

        if (this.onChanged.length > 0) {
            this.onChanged.forEach(x => x(this._value))
        }
    }

    get isItemFunctions(): boolean{
        return typeof (this._Inititems) == 'function';
    }

    async pull(): Promise<void> {
        if(!this.isItemFunctions)
            await this.request()

        else {
            this.loading = true;
            await this.debounceRequest();
        }
    };

    async request(): Promise<void> {

        const filters = {
            page: 1,
            size: 200,
            search: this._search
        };
        const items = typeof (this._Inititems) == 'function' ? await this._Inititems(filters) : this._Inititems as Array<T>;

        const a = items.filter(x => !!this._value.find(f => this.valueExp ? getValueInObject(f, this.valueExp) == getValueInObject(x, this.valueExp) : x == f));
        const b = items.filter(x => !this._value.find(f => this.valueExp ? getValueInObject(f, this.valueExp) == getValueInObject(x, this.valueExp) : x == f));

        this.items = a.concat(b)

        this.loading = false;
    };

    reset() {
        this._value = this.firstValue;
    }


    get equals(): boolean {
        const firstValues = this.firstValue.map(x => this.valueExp ? getValueInObject(x, this.valueExp) : x)
        const nowValues = this._value.map(x => this.valueExp ? getValueInObject(x, this.valueExp) : x)

        return firstValues.filter(x => nowValues.indexOf(x) == -1).length == 0 && nowValues.filter(x => firstValues.indexOf(x) == -1).length == 0
    }

    get valid(): boolean {
        return !Boolean(this.error);
    }

    validate() {
        this.error = ''
        if (this.required) {
            this.error = !this._value || this._value.length == 0 ? t("common.required") : ""
            if (!!this.error)
                return
        }
        if (this.validatorFunc) {
            this.error = this.validatorFunc(this._value)
            if (!!this.error)
                return
        }
        if (this.validatorFuncAsync) {
            this.validatorFuncAsync(this._value).then(x => this.error = x)
        }
    }

    setValidator(validator: ((value: T[] | null | undefined) => string) | null = null) {
        this.validatorFunc = validator;
        this.validate()
    }
}
