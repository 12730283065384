/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetZipDto,
    SetZipDtoFromJSON,
    SetZipDtoToJSON,
    ZipDto,
    ZipDtoFromJSON,
    ZipDtoToJSON,
} from '../models';

export interface CreateZipRequest {
    setZipDto: SetZipDto;
}

export interface DeleteZipRequest {
    id: string;
}

export interface GetZipByIdRequest {
    id: string;
}

export interface GetZipByNameRequest {
    name: string;
}

export interface GetZipsRequest {
    search?: string;
    searchByExternalId?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
}

export interface GetZipsCountRequest {
    searchByExternalId?: string;
    search?: string;
}

export interface UpdateZipRequest {
    id: string;
    setZipDto: SetZipDto;
}

/**
 * 
 */
export class ZipsApi extends runtime.BaseAPI {

    /**
     */
    async createZipRaw(requestParameters: CreateZipRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ZipDto>> {
        if (requestParameters.setZipDto === null || requestParameters.setZipDto === undefined) {
            throw new runtime.RequiredError('setZipDto','Required parameter requestParameters.setZipDto was null or undefined when calling createZip.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/addresses/zips`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetZipDtoToJSON(requestParameters.setZipDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ZipDtoFromJSON(jsonValue));
    }

    /**
     */
    async createZip(requestParameters: CreateZipRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ZipDto> {
        const response = await this.createZipRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteZipRaw(requestParameters: DeleteZipRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ZipDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteZip.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/zips/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ZipDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteZip(requestParameters: DeleteZipRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ZipDto> {
        const response = await this.deleteZipRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getZipByIdRaw(requestParameters: GetZipByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ZipDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getZipById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/zips/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ZipDtoFromJSON(jsonValue));
    }

    /**
     */
    async getZipById(requestParameters: GetZipByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ZipDto> {
        const response = await this.getZipByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getZipByNameRaw(requestParameters: GetZipByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ZipDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getZipByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/zips/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ZipDtoFromJSON(jsonValue));
    }

    /**
     */
    async getZipByName(requestParameters: GetZipByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ZipDto> {
        const response = await this.getZipByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getZipsRaw(requestParameters: GetZipsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ZipDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/zips`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ZipDtoFromJSON));
    }

    /**
     */
    async getZips(requestParameters: GetZipsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ZipDto>> {
        const response = await this.getZipsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getZipsCountRaw(requestParameters: GetZipsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/addresses/zips/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getZipsCount(requestParameters: GetZipsCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getZipsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateZipRaw(requestParameters: UpdateZipRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ZipDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateZip.');
        }

        if (requestParameters.setZipDto === null || requestParameters.setZipDto === undefined) {
            throw new runtime.RequiredError('setZipDto','Required parameter requestParameters.setZipDto was null or undefined when calling updateZip.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/addresses/zips/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetZipDtoToJSON(requestParameters.setZipDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ZipDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateZip(requestParameters: UpdateZipRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ZipDto> {
        const response = await this.updateZipRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
