/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface SetPersonNoteDto
 */
export interface SetPersonNoteDto {
    /**
     * 
     * @type {string}
     * @memberof SetPersonNoteDto
     */
    personId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetPersonNoteDto
     */
    note?: string | null;
}

export function SetPersonNoteDtoFromJSON(json: any): SetPersonNoteDto {
    return SetPersonNoteDtoFromJSONTyped(json, false);
}

export function SetPersonNoteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetPersonNoteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personId': !exists(json, 'personId') ? undefined : json['personId'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function SetPersonNoteDtoToJSON(value?: SetPersonNoteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personId': value.personId,
        'note': value.note,
    };
}

