import {DefaultSelectStore} from "./DefaultSelectStore";
import {IModal} from "../../core";
import {DefaultValueStore} from "./DefaultValueStore";
import {DefaultFormStore} from "./DefaultFormStore";
import {computed, makeObservable, observable} from "mobx";
import {DefaultMultiSelectStore} from "./DefaultMultiSelectStore";

export class DefaultFormModalStore<T extends { [key: string]: DefaultValueStore<any> | DefaultSelectStore<any> | DefaultMultiSelectStore<any> }>
    extends DefaultFormStore<T> implements IModal {
    public _open: boolean = false;

    constructor(fields: T, submitFunc?: (model: any) => Promise<void>) {
        super(fields, submitFunc);
        makeObservable(this, {
            _open: observable,
            Open: computed,
        });
    }

    set Open(value: boolean) {
        this._open = value;
        this.reset();
    }

    get Open(): boolean {
        return this._open;
    }

    async submit(): Promise<void> {
        await this.validate();
        if (this.valid && !!this._submitFunc) {
            this.processing = true;
            try {
                this._submitFunc(this.model).then(x => {
                    if (this.afterSubmit) {
                        this.afterSubmit();
                    }
                }).finally(() => this.processing = false);
                this.Open = false;
            } finally {

            }
        }
    }
}
