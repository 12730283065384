/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface ClientDto
 */
export interface ClientDto {
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    boundId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    name?: string | null;
}

export function ClientDtoFromJSON(json: any): ClientDto {
    return ClientDtoFromJSONTyped(json, false);
}

export function ClientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'boundId': !exists(json, 'boundId') ? undefined : json['boundId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function ClientDtoToJSON(value?: ClientDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'boundId': value.boundId,
        'name': value.name,
    };
}

