import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Box, Tooltip} from '@mui/material';
import {observer} from 'mobx-react';
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {AuthorizePermission, FormRow, ParamsButtonGroup, Progress, SimpleButton} from "../../../components";
import {LocalitySelect} from "../../../components/select/Locality/LocalitySelect";
import {DefaultCheckbox} from "../../../components/shared/components/DefaultCheckbox";
import {DefaultSelect} from "../../../components/shared/components/DefaultSelect";
import {DefaultValue} from "../../../components/shared/components/DefaultValue";
import {AllPermissions} from '../../../core/const';
import {OrganizationAddressesStore} from "../../../stores/management/organizations/details/OrganizationAddressStore";

interface Props {
    store: OrganizationAddressesStore;
}

export const OrganizationAddress = observer(({store}: Props) => {
    const {t} = useTranslation();
    const [step, setStep] = useState(0);
    const stepRef = React.useRef<HTMLDivElement | null>(null)
    const stepLocality = () => {
        if (!stepRef.current) return;
        const localityElement = stepRef.current?.getElementsByClassName('autocompleteLocality').length;
        setStep(localityElement)
    };

    return (
        <>
            <div style={{padding: 10}} ref={stepRef}>

                {store.addressStore.locality &&
                    <LocalitySelect locality={store.addressStore.locality.locality} style={{marginRight: 20}} zIndex={0}
                                    readonly={store.isDouble}
                                    stepLocality={stepLocality} step={step}/>
                }
                {!!store?.addressStore.locality?.currentLocality?.isEndpoint &&
                    <>
                        <FormRow style={{height: 60}}>
                            <DefaultValue
                                disabled={store.isDouble}
                                data-testid="addressCreator-house-input"
                                style={{flex: 1}}
                                title={t('localities.house')}
                                store={store.addressStore.house}
                            />
                            <DefaultValue
                                disabled={store.isDouble}
                                data-testid="addressCreator-building-input"
                                style={{flex: 1, marginLeft: 20}}
                                title={t('localities.building')}
                                store={store.addressStore.building}

                            />
                            <DefaultSelect
                                disabled={store.isDouble}
                                store={store.addressStore.entryType}
                                style={{flex: 1, marginLeft: 20}}
                                title={t('localities.entryType')}
                                testid="addressCreator-zip"
                                hasLabel
                            />

                            {!!store.addressStore.entryType.value && <DefaultValue
                                data-testid="addressCreator-entryValue-input"
                                disabled={store.isDouble}
                                style={{flex: 1, marginLeft: 20}}
                                title={t('localities.entryValue')}
                                store={store.addressStore.entryValue}
                            />}

                            <DefaultValue store={store.addressStore.zip}
                                          disabled={store.isDouble}
                                          title={t('localities.zip')}
                                          style={{flex: 1, marginLeft: 20}}
                                          testid="organizationAddress-zip"/>

                        </FormRow>
                        {store.addressStore.hasDistrictRegion &&
                            <FormRow style={{paddingTop: 10}}>
                                <>
                                    <DefaultSelect
                                        disabled={store.isDouble}
                                        store={store.addressStore.district}
                                        nameExp={(x) => x.mdmRegionType ? `${x?.name} (${x.mdmRegionType?.nameRus})` : `${x?.name}`}
                                        title={t('localities.district')}
                                        style={{width: '33%'}}
                                        testid="organizationAddress-district"
                                        hasLabel/>
                                    <DefaultSelect
                                        disabled={store.isDouble}
                                        store={store.addressStore.okrug}
                                        nameExp={(x) => x.mdmRegionType ? `${x?.name} (${x.mdmRegionType?.nameRus})` : `${x?.name}`}
                                        title={t('localities.okrug')}
                                        style={{marginLeft: 20, width: '33%'}}
                                        testid="organizationAddress-okrug"
                                        hasLabel/>
                                </>
                            </FormRow>
                        }

                        <FormRow style={{height: 60, marginTop: 20}}>
                            <DefaultValue
                                data-testid="organizationAddress-latitude-input"
                                title="Широта"
                                style={{flex: 1}}
                                store={store.addressStore.latitude}
                                disabled={!store.addressStore.isCustomCoordinates.value || store.isDouble}
                            />
                            <DefaultValue
                                data-testid="organizationAddress-longtitude-input"
                                title="Долгота"
                                style={{flex: 1, marginLeft: 20}}
                                store={store.addressStore.longtitude}
                                disabled={!store.addressStore.isCustomCoordinates.value || store.isDouble}
                            />
                            <DefaultCheckbox store={store.addressStore.isCustomCoordinates}
                                             disabled={store.isDouble}
                                             style={{flex: 1, marginLeft: 20}}
                                             title={t('common.isCustomCoordinates')}/>
                            <AuthorizePermission permissions={AllPermissions.organization.addressunique.update}>
                                <SimpleButton
                                    title={t('common.updatingCoordinates')}
                                    style={{width: '200px', marginLeft: 20, margin: '8px 12px 0 0'}}
                                    disabled={store.addressStore.isCustomCoordinates.value! || store.isDouble}
                                    onClick={async () => await store.updateCoordinates()}
                                    color='secondary'
                                    variant='outlined'/>
                                <Tooltip title={
                                    <Box sx={{textAlign: 'center'}}>
                                        {t('common.tooltipUpdatingCoordinates')}
                                    </Box>
                                }>
                                    <InfoOutlinedIcon fontSize='small' sx={{my: 'auto'}}/>
                                </Tooltip>
                            </AuthorizePermission>
                        </FormRow>
                    </>
                }
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mt: 2
                    }}
                >
                    <AuthorizePermission permissions={AllPermissions.organization.addressunique.update}>
                        <ParamsButtonGroup
                            testid="organizationAddress"
                            onCancel={() => store.addressStore.reset()}
                            onSave={() => store.submit()}
                            isCancelBtnDisabled={store.addressStore.equals || store._store.processing}
                            isSaveBtnDisabled={store._store.processing || store.addressStore.equals || !store.addressStore.valid || store.isDouble}
                            processing={store._store}
                        />
                    </AuthorizePermission>
                </Box>

            </div>
            {(store.loading || store.processing) && <Box
                sx={{
                    flexGrow: 1,
                    py: 3,
                }}
            >
                <Progress store={store}/>
            </Box>}

        </>
    );
});


