/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    PersonDtoMappingDto,
    PersonDtoMappingDtoFromJSON,
    PersonDtoMappingDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetMappingDto,
    SetMappingDtoFromJSON,
    SetMappingDtoToJSON,
} from '../models';

export interface CreatePersonsMappingsRequest {
    boundId: string;
    setMappingDto: Array<SetMappingDto>;
}

export interface GetPersonMappingByIdRequest {
    id?: string;
    boundId?: string;
}

export interface GetPersonValidCrmIdsRequest {
    clientIds?: Array<string>;
    boundId?: string;
}

export interface GetPersonsMappingsRequest {
    search?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    boundId?: string;
    crmId?: string;
    personId?: string;
}

export interface GetPersonsMappingsCountRequest {
    search?: string;
    boundId?: string;
    crmId?: string;
    personId?: string;
}

export interface RemovePersonsMappingsRequest {
    boundId: string;
    setMappingDto: Array<SetMappingDto>;
}

/**
 * 
 */
export class PersonsMappingsApi extends runtime.BaseAPI {

    /**
     */
    async createPersonsMappingsRaw(requestParameters: CreatePersonsMappingsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonDtoMappingDto>>> {
        if (requestParameters.boundId === null || requestParameters.boundId === undefined) {
            throw new runtime.RequiredError('boundId','Required parameter requestParameters.boundId was null or undefined when calling createPersonsMappings.');
        }

        if (requestParameters.setMappingDto === null || requestParameters.setMappingDto === undefined) {
            throw new runtime.RequiredError('setMappingDto','Required parameter requestParameters.setMappingDto was null or undefined when calling createPersonsMappings.');
        }

        const queryParameters: any = {};

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/mappings/persons/set`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setMappingDto.map(SetMappingDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonDtoMappingDtoFromJSON));
    }

    /**
     */
    async createPersonsMappings(requestParameters: CreatePersonsMappingsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonDtoMappingDto>> {
        const response = await this.createPersonsMappingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonMappingByIdRaw(requestParameters: GetPersonMappingByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonDtoMappingDto>>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/mappings/persons/byId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonDtoMappingDtoFromJSON));
    }

    /**
     */
    async getPersonMappingById(requestParameters: GetPersonMappingByIdRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonDtoMappingDto>> {
        const response = await this.getPersonMappingByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonValidCrmIdsRaw(requestParameters: GetPersonValidCrmIdsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters.clientIds) {
            queryParameters['clientIds'] = requestParameters.clientIds;
        }

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/mappings/persons/checkByCrmIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getPersonValidCrmIds(requestParameters: GetPersonValidCrmIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<string>> {
        const response = await this.getPersonValidCrmIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonsMappingsRaw(requestParameters: GetPersonsMappingsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonDtoMappingDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        if (requestParameters.crmId !== undefined) {
            queryParameters['crmId'] = requestParameters.crmId;
        }

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/mappings/persons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonDtoMappingDtoFromJSON));
    }

    /**
     */
    async getPersonsMappings(requestParameters: GetPersonsMappingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonDtoMappingDto>> {
        const response = await this.getPersonsMappingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPersonsMappingsCountRaw(requestParameters: GetPersonsMappingsCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        if (requestParameters.crmId !== undefined) {
            queryParameters['crmId'] = requestParameters.crmId;
        }

        if (requestParameters.personId !== undefined) {
            queryParameters['personId'] = requestParameters.personId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/mappings/persons/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPersonsMappingsCount(requestParameters: GetPersonsMappingsCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getPersonsMappingsCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removePersonsMappingsRaw(requestParameters: RemovePersonsMappingsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonDtoMappingDto>>> {
        if (requestParameters.boundId === null || requestParameters.boundId === undefined) {
            throw new runtime.RequiredError('boundId','Required parameter requestParameters.boundId was null or undefined when calling removePersonsMappings.');
        }

        if (requestParameters.setMappingDto === null || requestParameters.setMappingDto === undefined) {
            throw new runtime.RequiredError('setMappingDto','Required parameter requestParameters.setMappingDto was null or undefined when calling removePersonsMappings.');
        }

        const queryParameters: any = {};

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/mappings/persons/remove`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setMappingDto.map(SetMappingDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonDtoMappingDtoFromJSON));
    }

    /**
     */
    async removePersonsMappings(requestParameters: RemovePersonsMappingsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonDtoMappingDto>> {
        const response = await this.removePersonsMappingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
