import {makeAutoObservable} from 'mobx';
import {ApplicationClientsApi} from "../../../../services/management";
import {DefaultValueStore} from "../../../../components/shared/DefaultValueStore";
import {getApiConfiguration, IProcessing} from "../../../../core";
import {UserDto} from '../../../../services/management/models/UserDto';
import {DefaultSelectStore} from '../../../../components/shared/DefaultSelectStore';
import {ClientFragment, SetClientMutation, SetClientMutationVariables} from "../../../../gql/graphql";
import {getAppoloClient, GRAPHQL_URLS} from "../../../../graphql";
import {SET_CLIENT_MUTATION} from "../../../../graphql/mutations.graphql";
import {DefaultFormModalStore} from "../../../../components/shared/DefaultFormModalStore";
import {ClientStore} from "../client/ClientStore";

export class ClientDetailsStore implements IProcessing {
    readonly _store: ClientStore;
    private _dto: ClientFragment;
    processing: boolean = false;

    details: DefaultFormModalStore<{
        name: DefaultValueStore<string>,
        clientName: DefaultValueStore<string>,
        description: DefaultValueStore<string>
        secretKey: DefaultValueStore<string>,
        createdByUser: DefaultSelectStore<UserDto>
    }>

    constructor(store: ClientStore, dto: ClientFragment) {
        this._store = store;
        this._dto = dto;

        this.details = new DefaultFormModalStore({
            name: new DefaultValueStore<string>(dto.name),
            clientName: new DefaultValueStore<string>(dto.orgName),
            description: new DefaultValueStore<string>(dto.description ?? ""),
            secretKey: new DefaultValueStore<string>(dto.secret),
            createdByUser: new DefaultSelectStore<UserDto>({
                id: dto.createdByUser?.id!,
                name: dto.createdByUser?.name
            }, () => new ApplicationClientsApi(getApiConfiguration()).getClients())
        }, async (e) => {
            await this.submit()
        })

        this.details.fields.name.required = true;
        this.details.fields.clientName.required = true;
        this.details.fields.secretKey.required = true;

        makeAutoObservable(this);
    }


    update(dto: ClientFragment) {
        this._dto = dto;
        this.details.fields.name.update(dto.name)
        this.details.fields.clientName.update(dto.orgName)
        this.details.fields.description.update(dto.description ?? "")
        this.details.fields.secretKey.update(dto.secret ?? "")
        this.details.fields.createdByUser.update(dto.createdByUser!)
        this.details.reset();
    }

    async submit(): Promise<void> {
        this.details.validate()

        if (this.details.valid) {
            await getAppoloClient(GRAPHQL_URLS.CLIENTS).mutate<SetClientMutation, SetClientMutationVariables>({
                mutation: SET_CLIENT_MUTATION, variables: {
                    clientId: this._dto.id,
                    name: this.details.fields.name.value!,
                    secret: this.details.fields.secretKey.value,
                    description: this.details.fields.description.value,
                    userId: this._store._store._store.store.store.authorizationStore.id!,
                    orgName: this.details.fields.clientName.value!
                }
            })
            await this._store._store._store.getClient(this._dto.id)
        }
    };
}
