import {PeopleTableTabStore} from "./PeopleTableTabStore";
import {
    ClientApi,
    ClientDto,
    DcrGroupPermissionDto,
    DcrStatus,
    GroupApi,
    RequestRecordType
} from "../../../../../../../services/dcr";
import {getApiConfiguration} from "../../../../../../../core";
import {DefaultMultiSelectStore} from "../../../../../../../components/shared/DefaultMultiSelectStore";
import {
    mappingDcrStatusToString,
    mappingRequestTypeToString,
} from "../../../../../dcr/services";
import {DCRStatusEnum, RequestTypeEnum} from "../../../../../dcr/types";
import {DynamicalComponentStore} from "../../../../../../utils";
import {DefaultFormStore} from "../../../../../../../components/shared/DefaultFormStore";

export class PeopleItemStore extends DynamicalComponentStore {
    readonly _store: PeopleTableTabStore;
    public _dto: DcrGroupPermissionDto;

    public form: DefaultFormStore<{
        clients: DefaultMultiSelectStore<ClientDto>,
        dcrStatuses: DefaultMultiSelectStore<DCRStatusEnum>,
        entityTypes: DefaultMultiSelectStore<RequestTypeEnum>
    }>;

    constructor(store: PeopleTableTabStore, dto: DcrGroupPermissionDto) {
        super();
        this._store = store;
        this._dto = dto;

        this.form = new DefaultFormStore({
            clients: new DefaultMultiSelectStore(this._dto.clients?.map(x => ({id: x}) as ClientDto),
                (x) => new ClientApi().apiDcrV1ClientsGet({...x, groupId: this._store._dto.id!})),
            dcrStatuses: new DefaultMultiSelectStore(this._dto.dcrStatuses?.map(x => x as DCRStatusEnum), Object.values(DcrStatus),
                null,
                null, '', (e) => mappingDcrStatusToString(e)),
            entityTypes: new DefaultMultiSelectStore(this._dto.entityTypes?.map(x => x as RequestTypeEnum), Object.values(RequestRecordType),
                null,
                null, '', (e) => mappingRequestTypeToString(e))
        }, this.submit.bind(this))
    }

    update(dto: DcrGroupPermissionDto) {
        this._dto = dto;
        this.form.fields.clients.update(this._dto.clients?.map(x => ({id: x}) as ClientDto) ?? [])
        this.form.fields.dcrStatuses.update(this._dto.dcrStatuses?.map(x => x as DCRStatusEnum) ?? [])
        this.form.fields.entityTypes.update(this._dto.entityTypes?.map(x => x as RequestTypeEnum) ?? [])
    }

    async submit(): Promise<void> {
        const result = await new GroupApi(getApiConfiguration() as any).updatePermission({
            id: this._store._dto.id ?? '',
            dcrGroupPermissionDto: {
                userId: this._dto.userId,
                clients: this.form.model.clients?.map((x: any) => x.id),
                entityTypes: this.form.model.entityTypes ?? [],
                dcrStatuses: this.form.model.dcrStatuses ?? [],
            }
        })
        this.update(result)
        this.form.reset()
    }

    async updateState(): Promise<void> {
    }
}
