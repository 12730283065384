import {makeAutoObservable} from 'mobx';
import {TypeOrganizationTableStore} from "./TypeOrganizationTableStore";
import {TypeOrgDto} from "../../../../../../../services/import";
import {getApiConfiguration, IExpanded, ILoad} from "../../../../../../../core";
import {GetTypeOrgsRequest, QueryType, TypeOrgsApi} from "../../../../../../../services/management";
import {sort} from "../../../../../../../core/sort";

export class TypeOrganizationItemStore implements IExpanded, ILoad {
    private readonly store: TypeOrganizationTableStore;
    public dto: TypeOrgDto;
    private readonly parent?: TypeOrganizationItemStore;
    _expand: boolean = false;
    _selected: boolean = false;
    value: TypeOrganizationItemStore[] = []
    visibility: boolean = true;
    loaded: boolean = false;
    loading: boolean = false;

    constructor(store: TypeOrganizationTableStore,
                dto: TypeOrgDto,
                items: TypeOrganizationItemStore[] = [],
                parent?: TypeOrganizationItemStore,
                selected: boolean = false,
                expanded: boolean = false) {
        this.dto = dto;
        this.store = store;
        this.value = items;
        this.parent = parent;
        this._selected = selected;
        this._expand = expanded;

        makeAutoObservable(this)
    }

    get selected(): boolean {
        console.log("get selected")

        return this._selected || !!this.parent?.selected;
    }

    initAll(value: boolean, down: boolean = true, up: boolean = true) {
        this._selected = value;

        if (this._selected) {
            if (up) {
                if (!this.parent?._selected && !this.parent?.value?.filter(x => !x._selected)[0]) {
                    this.parent?.setSelected(true, false, true)
                    this.parent?.value.forEach(x => x._selected = false)
                }
            }
            if (down) {
                this.value?.forEach(x => x.setSelected(false, true, false))
            }
        } else {
            if (down) {
                this.value?.forEach(x => x.setSelected(false, true, false))
            }
            if (up) {
                if (this.parent?.selected) {
                    this.parent?.setSelected(false, false, true)
                    this.parent.value?.filter(x => x.dto.id != this.dto.id)
                        .forEach(x => x.setSelected(true, false, false))
                }
            }
        }
    }

    getAllRequests(): Promise<any>[] {
        console.log("getAllRequests")

        const array: Promise<any>[] = [];
        array.push(this.pull())
        if (this.parent) {
            array.push(...this.parent?.getAllRequests())
        }
        return array;
    }

    setSelected(value: boolean, down: boolean = true, up: boolean = true) {
        console.log("setSelected")

        if (this.store.search.value && up) {
            Promise.all(this.getAllRequests()).then((x) => {
                this.initAll(value, down, up)
            })
        } else {
            this.initAll(value, down, up)
        }
    }

    get allChildren(): TypeOrganizationItemStore[] {
        console.log("allChildren")

        let items: TypeOrganizationItemStore[] = [];
        this.value?.forEach(locality => {
            items.push(locality);
            items = items.concat(locality.allChildren)
        })
        return items;
    }

    get selectedChildren(): TypeOrganizationItemStore[] {
        console.log("selectedChildren")

        return this._selected ? [] : this.allChildren.filter(x => x._selected)
    }

    get expandedChildren(): TypeOrganizationItemStore[] {
        console.log("expandedChildren")

        return this.allChildren.filter(x => x.expand)
    }

    get expand(): boolean {
        console.log("get expand")

        return this._expand
    }

    set expand(value: boolean) {
        console.log("set expand")

        this._expand = value;
        if (this._expand && !this.store.search.value)
            this.pull().then()
    }

    get indeterminate(): boolean {
        console.log("indeterminate")
        if (!this.value?.length || this.selected) {
            return false;
        } else {
            return (!this.selected && !!this.value.find(x => x.selected)) || !!this.value.find(x => x.indeterminate)
        }
    }

    async pull(): Promise<void> {
        if (this.loaded)
            return;

        this.loading = true;

        const filters = {
            queryType: QueryType.Tree,
            parentId: this.dto.id,
            page: 1,
            size: 100,
            orderBy: 'nameRus'
        } as GetTypeOrgsRequest;

        const api: TypeOrgsApi = new TypeOrgsApi(getApiConfiguration());
        let result = await api.getTypeOrgs(filters);

        result = result.filter(x => this.value.map(x => x.dto.id).indexOf(x.id) == -1)
        const newElements = result
            .map(x => new TypeOrganizationItemStore(this.store, x, [], this, this.store.selectedLocalityId.indexOf(x.id) > -1));

        if (this.store.search.value)
            newElements.forEach(x => x.visibility = false)

        const oldElement = [...this.value]

        this.value.splice(0, this.value.length);

        this.value.push(
            ...[...oldElement, ...newElements]
                .filter(x => x.dto.parentId != x.dto.id)
                .sort((a, b) => sort(a.dto, b.dto, 'nameRus')))

        this.loaded = true;
        this.loading = false;
    }
}
