import {DictionaryOrganizationsStore} from "../DictionaryOrganizationsStore";
import {getApiConfiguration} from '../../../../../core';
import {TypeNamesApi} from "../../../../../services/management";
import {DictionaryCreateEntity, DictionaryEntity, DictionaryTableStore} from "../../DictionaryTableStore";

export class TypeNameTableStore extends DictionaryTableStore {
    constructor(store: DictionaryOrganizationsStore) {
        super(store, 'type-name-store');
    }

    async Create(model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new TypeNamesApi(getApiConfiguration()).createTypeName({setTypeNameDto: model});
    }

    async Delete(id: string): Promise<DictionaryEntity> {
        return await new TypeNamesApi(getApiConfiguration()).deleteTypeName({id: id});
    }

    async GetCount(search: string): Promise<{ count: number }> {
        return await new TypeNamesApi(getApiConfiguration()).getTypeNamesCount({search: search});
    }

    async GetList(filters: any): Promise<DictionaryEntity[]> {
        return await new TypeNamesApi(getApiConfiguration()).getTypeNames(filters);
    }

    async Update(id: string, model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new TypeNamesApi(getApiConfiguration()).updateTypeName({id: id, setTypeNameDto: model});
    }
}
