import {getApiConfiguration} from '../../../../../core';
import {FirstNamesApi} from '../../../../../services/management';
import {DictionaryCreateEntity, DictionaryEntity, DictionaryTableStore} from "../../DictionaryTableStore";
import {DictionaryPersonStore} from "../DictionaryPersonStore";

export class NameTableStore extends DictionaryTableStore {
    constructor(store: DictionaryPersonStore) {
        super(store, 'name-store');
    }

    async Create(model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new FirstNamesApi(getApiConfiguration()).createFirstName({setFirstNameDto: model});
    }

    async Delete(id: string): Promise<DictionaryEntity> {
        return await new FirstNamesApi(getApiConfiguration()).deleteFirstName({id: id});
    }

    async GetCount(search: string): Promise<{ count: number }> {
        return await new FirstNamesApi(getApiConfiguration()).getFirstNamesCount({search: search});
    }

    async GetList(filters: any): Promise<DictionaryEntity[]> {
        return await new FirstNamesApi(getApiConfiguration()).getFirstNames(filters)
    }

    async Update(id: string, model: DictionaryCreateEntity): Promise<DictionaryEntity> {
        return await new FirstNamesApi(getApiConfiguration()).updateFirstName({id: id, setFirstNameDto: model});
    }
}
