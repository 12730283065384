/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    GenderDto,
    GenderDtoFromJSON,
    GenderDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OCMObjectState,
    OCMObjectStateFromJSON,
    OCMObjectStateToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetGenderDto,
    SetGenderDtoFromJSON,
    SetGenderDtoToJSON,
} from '../models';

export interface CreateGenderRequest {
    setGenderDto: SetGenderDto;
}

export interface DeleteGenderRequest {
    id: string;
}

export interface GetGenderByIdRequest {
    id: string;
}

export interface GetGenderByNameRequest {
    name: string;
}

export interface GetGendersRequest {
    search?: string;
    searchByExternalId?: string;
    orderBy?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface GetGendersCountRequest {
    searchByExternalId?: string;
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface UpdateGenderRequest {
    id: string;
    setGenderDto: SetGenderDto;
}

/**
 * 
 */
export class GendersApi extends runtime.BaseAPI {

    /**
     */
    async createGenderRaw(requestParameters: CreateGenderRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GenderDto>> {
        if (requestParameters.setGenderDto === null || requestParameters.setGenderDto === undefined) {
            throw new runtime.RequiredError('setGenderDto','Required parameter requestParameters.setGenderDto was null or undefined when calling createGender.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/persons/genders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetGenderDtoToJSON(requestParameters.setGenderDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenderDtoFromJSON(jsonValue));
    }

    /**
     */
    async createGender(requestParameters: CreateGenderRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GenderDto> {
        const response = await this.createGenderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteGenderRaw(requestParameters: DeleteGenderRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GenderDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteGender.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/genders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenderDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteGender(requestParameters: DeleteGenderRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GenderDto> {
        const response = await this.deleteGenderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGenderByIdRaw(requestParameters: GetGenderByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GenderDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getGenderById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/genders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenderDtoFromJSON(jsonValue));
    }

    /**
     */
    async getGenderById(requestParameters: GetGenderByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GenderDto> {
        const response = await this.getGenderByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGenderByNameRaw(requestParameters: GetGenderByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GenderDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getGenderByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/genders/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenderDtoFromJSON(jsonValue));
    }

    /**
     */
    async getGenderByName(requestParameters: GetGenderByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GenderDto> {
        const response = await this.getGenderByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGendersRaw(requestParameters: GetGendersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<GenderDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/genders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GenderDtoFromJSON));
    }

    /**
     */
    async getGenders(requestParameters: GetGendersRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<GenderDto>> {
        const response = await this.getGendersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGendersCountRaw(requestParameters: GetGendersCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/persons/genders/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getGendersCount(requestParameters: GetGendersCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getGendersCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateGenderRaw(requestParameters: UpdateGenderRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GenderDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateGender.');
        }

        if (requestParameters.setGenderDto === null || requestParameters.setGenderDto === undefined) {
            throw new runtime.RequiredError('setGenderDto','Required parameter requestParameters.setGenderDto was null or undefined when calling updateGender.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/persons/genders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetGenderDtoToJSON(requestParameters.setGenderDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenderDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateGender(requestParameters: UpdateGenderRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GenderDto> {
        const response = await this.updateGenderRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
