import { observer } from "mobx-react";
import { Box, Card, Table, TableBody, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Cell, EmptyBox, HCell, PaginationLocal, Progress } from "../../../../components";
import React, { Fragment, useState } from "react";
import { Scrollbar } from "../../../../scrollbar";
import { SeverityPill, SeverityPillColor } from "../../../../components/severity-pill";
import { NavLink } from "../../../../components/NavLink";
import { OrganizationChildsTableStore } from "../../../../stores/management/organizations/details/parentChild/OrganizationChildsTableStore";
import { organizationStatusHandler } from "../../OrganizationTable";
import { getOrganizationAddressHandler, getOrgName } from "../../../../stores";

interface Props {
    store: OrganizationChildsTableStore;
    style?: React.CSSProperties
}

export const OrganizationChildsTable = observer(({ store, style }: Props) => {
    const { t } = useTranslation();
    const [openDetails, setOpenDetails] = useState<string | null>(null);

    const handleOpenDetails = (openId: string): void => {
        setOpenDetails((prevValue) => (prevValue === openId ? null : openId));
    };
    return (
        <Card sx={{ mt: 3 }}>
            <Scrollbar>
                <Table sx={{ minWidth: 800 }}>
                    <TableHead>
                        <TableRow>
                            <HCell width='1%' testid="OrganizationChildsTable-expand"></HCell>
                            <HCell orderBy='orgName' store={store} testid="OrganizationChildsTable-name" width='15%'>{t('common.name')}</HCell>
                            <HCell orderBy='id' store={store} testid="OrganizationChildsTable-id" width='20%'>{t('common.id')}</HCell>
                            <HCell orderBy='address' store={store} testid="OrganizationChildsTable-address" width='20%'>{t('table.address')}</HCell>
                            <HCell store={store} testid="OrganizationChildsTable-itn" width='10%' styleText={{ marginLeft: 'auto', paddingRight: 16 }}>{t('table.itn')}</HCell>
                            <HCell orderBy='status' store={store} testid="OrganizationChildsTable-status" width='10%'>{t('table.status')}</HCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            store.items.length > 0
                                ? store.items.map(t => {
                                    const open = t.id === openDetails;
                                    return (
                                        <Fragment key={t.id}>
                                            <TableRow
                                                hover
                                                key={t.id}
                                                style={{ width: '100%', height: 48 }}
                                            >
                                                <Cell />
                                                <Cell testid="OrganizationChildsTable-name">
                                                    <NavLink to={`/organizations/${t.id}`} target="_blank">
                                                        {getOrgName(t)}
                                                    </NavLink>
                                                </Cell>
                                                <Cell testid="OrganizationChildsTable-id">
                                                    <NavLink to={`/organizations/${t.id}`} target="_blank">
                                                        {t.id}
                                                    </NavLink>
                                                </Cell>
                                                <Cell testid="OrganizationChildsTable-address">
                                                    {getOrganizationAddressHandler(t.addressUnique)}
                                                </Cell>
                                                <Cell align='right' testid="OrganizationChildsTable-itn">
                                                    {t?.itn?.itn ?? ''}
                                                </Cell>
                                                <Cell testid="OrganizationChildsTable-status">
                                                    <SeverityPill
                                                        color={organizationStatusHandler(t.status?.nameRus || '') as SeverityPillColor}>
                                                        <Tooltip
                                                            title={t.status?.nameRus ? t.status?.nameRus : 'Работает'}>
                                                            <Typography variant="caption" align={'left'} sx={{
                                                                maxWidth: 130,
                                                                textOverflow: 'ellipsis',
                                                                overflow: 'hidden',
                                                            }}>
                                                                {t.status?.nameRus ? t.status?.nameRus : 'Работает'}
                                                            </Typography>
                                                        </Tooltip>
                                                    </SeverityPill>
                                                </Cell>
                                            </TableRow>
                                        </Fragment>
                                    )
                                })
                                : <Cell colSpan={6}>
                                    <EmptyBox />
                                </Cell>
                        }
                    </TableBody>
                </Table>
            </Scrollbar>
            {!store.items.length && <Box
                sx={{
                    flexGrow: 1,
                    py: 3,
                }}
            >
                <Progress store={store} />
            </Box>
            }
            {store.items.length > 0 && <PaginationLocal store={store} />}
        </Card>
    );
});
