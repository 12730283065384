/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClientType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;
export type ClientType = typeof ClientType[keyof typeof ClientType];


export function ClientTypeFromJSON(json: any): ClientType {
    return ClientTypeFromJSONTyped(json, false);
}

export function ClientTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientType {
    return json as ClientType;
}

export function ClientTypeToJSON(value?: ClientType | null): any {
    return value as any;
}

