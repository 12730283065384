/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Api.Export
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 * 
 * @export
 * @interface DictionaryFilter
 */
export interface DictionaryFilter {
    /**
     * 
     * @type {Date}
     * @memberof DictionaryFilter
     */
    createDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DictionaryFilter
     */
    updateDate?: Date | null;
}

export function DictionaryFilterFromJSON(json: any): DictionaryFilter {
    return DictionaryFilterFromJSONTyped(json, false);
}

export function DictionaryFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): DictionaryFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createDate': !exists(json, 'createDate') ? undefined : (json['createDate'] === null ? null : new Date(json['createDate'])),
        'updateDate': !exists(json, 'updateDate') ? undefined : (json['updateDate'] === null ? null : new Date(json['updateDate'])),
    };
}

export function DictionaryFilterToJSON(value?: DictionaryFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createDate': value.createDate === undefined ? undefined : (value.createDate === null ? null : value.createDate.toISOString()),
        'updateDate': value.updateDate === undefined ? undefined : (value.updateDate === null ? null : value.updateDate.toISOString()),
    };
}

