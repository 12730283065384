/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface SetOrgNameDto
 */
export interface SetOrgNameDto {
    /**
     * 
     * @type {string}
     * @memberof SetOrgNameDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SetOrgNameDto
     */
    typeNameId: string;
    /**
     * 
     * @type {string}
     * @memberof SetOrgNameDto
     */
    organizationId: string;
}

export function SetOrgNameDtoFromJSON(json: any): SetOrgNameDto {
    return SetOrgNameDtoFromJSONTyped(json, false);
}

export function SetOrgNameDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetOrgNameDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'typeNameId': json['typeNameId'],
        'organizationId': json['organizationId'],
    };
}

export function SetOrgNameDtoToJSON(value?: SetOrgNameDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'typeNameId': value.typeNameId,
        'organizationId': value.organizationId,
    };
}

