import {
    Box,
    Button,
    Card,
    Container,
    Grid,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import {observer} from "mobx-react";
import {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    AuthorizePermission,
    Cell,
    EmptyBox,
    ExpandButton,
    ExpandRow,
    ExpertDialog,
    HCell,
    Pagination,
    Progress
} from "../../../components";
import {CopyToClipboard} from "../../../components/CopyToClipboard";
import {SeverityPill, SeverityPillColor} from "../../../components/severity-pill";
import {Scrollbar} from "../../../scrollbar";
import {ImportStatus} from "../../../services/import";
import {ImportTableStore} from "../../../stores/management/data/import/ImportTableStore";
import {ImportCreate} from "./ImportCreate";
import {ImportDetails} from "./ImportDetails";
import {callTheProcessingFunc} from "../../../stores";

interface Props {
    store: ImportTableStore;
    tabsName: string;
    permission: string;
}

export const ImportTable = observer(({store, tabsName, permission}: Props) => {
    const { t } = useTranslation();
    const [openDetails, setOpenDetails] = useState<string | null>(null);

    const colorStatusHandler = (status: ImportStatus) => {
        if (status === 0) {
            return 'error'
        } else if (status === 5) {
            return 'success'
        } else {
            return 'warning'
        }
    }

    const importStatus = (status: ImportStatus) => {
        switch (status) {
            case 0:
                return t('dataPage.importStatus.error')
            case 1:
                return t('dataPage.importStatus.checkPending')
            case 2:
                return t('dataPage.importStatus.checking')
            case 3:
                return t('dataPage.importStatus.readyForImport')
            case 4:
                return t('dataPage.importStatus.importing')
        }
        return t('dataPage.importStatus.completed')
    }

    const handleOpenDetails = (productId: string): void => {
        setOpenDetails((prevValue) => (prevValue === productId ? null : productId));
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                py: 3,
                backgroundColor: 'background.default',
            }}
        >
            <ExpertDialog
                title={`${t('dataPage.dataTabs.import')} ${t(`dataPage.dataTabs.${tabsName}`).toLowerCase()}`}
                open={store.creator.Open}
                onClose={() => store.creator.Open = false}
            >
                <ImportCreate store={store.creator} />
            </ExpertDialog>
            <Container maxWidth="xl">
                <Grid
                    container
                    justifyContent="end"
                    spacing={2}
                >
                    <Grid item>
                        <Button variant='outlined'
                                onClick={() => callTheProcessingFunc(store, store.pullFormat.bind(store))()}>{t('dataPage.dataFormat')}</Button>
                    </Grid>
                    <AuthorizePermission permissions={permission}>
                        <Grid item>
                            <Button variant='contained' color="success" onClick={() => store.creator.Open = true}>
                                {t('dataPage.dataTabs.import')} {t(`dataPage.dataTabs.${tabsName}`).toLowerCase()}
                            </Button>
                        </Grid>
                    </AuthorizePermission>
                </Grid>

                <Card sx={{ mb: 8, mt: 3, overflow: 'unset' }}>
                    <Scrollbar>
                        <Table sx={{ minWidth: 850 }}>
                            <TableHead>
                                <TableRow>
                                    <HCell width='5%'></HCell>
                                    <HCell width='3%'>Id</HCell>
                                    <HCell store={store} width='20%'>{t('dataPage.table.name')}</HCell>
                                    <HCell store={store} width='10%'>{t('dataPage.table.status')}</HCell>
                                    <HCell store={store} width='10%' styleText={{ marginLeft: 'auto', paddingRight: 16 }}>{t('dataPage.table.totalRows')}</HCell>
                                    <HCell store={store} width='10%' styleText={{ marginLeft: 'auto', paddingRight: 16 }}>{t('dataPage.table.errorRows')}</HCell>
                                    <HCell store={store} width='10%' styleText={{ marginLeft: 'auto', paddingRight: 16 }}>{t('dataPage.table.successRows')}</HCell>
                                    <HCell store={store} width='12%'>{t('dataPage.table.modifiedDate')}</HCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {store.items.length > 0 && store.items.map((item) => {
                                    const open = item.id === openDetails;
                                    return (
                                        <Fragment key={item.id}>
                                            <TableRow
                                                hover
                                                key={item.id}
                                            >
                                                <ExpandButton item={item} isOpen={open}
                                                    onClick={() => handleOpenDetails(item?.id ?? '')}
                                                />
                                                <Cell>
                                                    <Box style={{ marginLeft: -5 }}>
                                                        <CopyToClipboard copyText={item?.id ?? ''} />
                                                    </Box>
                                                </Cell>
                                                <Cell>
                                                    <Tooltip title={item.fileName}>
                                                        <Typography variant="subtitle2" color="textSecondary">
                                                            {item.fileName.length > 35 ? `${item.fileName.slice(0, 35)}...` : item.fileName}
                                                        </Typography>
                                                    </Tooltip>
                                                </Cell>
                                                <Cell>
                                                    <SeverityPill
                                                        color={colorStatusHandler(item.dto.status ?? 0) as SeverityPillColor}>
                                                        <Typography variant="caption" align={'left'} sx={{
                                                            maxWidth: 160,
                                                            textOverflow: 'ellipsis',
                                                            overflow: 'hidden',
                                                        }}>
                                                            {importStatus(item.dto.status ?? 0)}
                                                        </Typography>
                                                    </SeverityPill>
                                                </Cell>
                                                <Cell>
                                                    <Typography variant="subtitle2" color="textSecondary" align='right'>
                                                        {item.dto.rowsCount ?? 0}
                                                    </Typography>
                                                </Cell>
                                                <Cell>
                                                    <Typography variant="subtitle2" color="textSecondary" align='right'>
                                                        {item.dto.errorsRowCount ?? 0}
                                                    </Typography>
                                                </Cell>
                                                <Cell>
                                                    <Typography variant="subtitle2" color="textSecondary" align='right'>
                                                        {item.success}
                                                    </Typography>
                                                </Cell>
                                                <Cell>
                                                    <Typography variant="subtitle2" color="textSecondary">
                                                        {item.modifiedAt}
                                                    </Typography>
                                                </Cell>
                                            </TableRow>
                                            {open && (
                                                <ExpandRow key={`${item.id}_`} item={item}>
                                                    <ImportDetails
                                                        store={item}
                                                        tabsName={tabsName}
                                                        style={{
                                                            margin: '0 40px'
                                                        }}
                                                    />
                                                </ExpandRow>)}
                                        </Fragment>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {!store.items.length && <EmptyBox />}
                    </Scrollbar>
                    {(store.items.length > 0 || !!store.count) && <Pagination store={store} />}
                    {store.loading && <Progress store={store} />}
                    {store.creator.processing && <Progress store={store.creator}/>}
                </Card>
            </Container>
        </Box>
    );
});
