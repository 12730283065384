import {useManagementStore} from "../../../stores";
import {observer} from 'mobx-react';
import {useTranslation} from "react-i18next";
import {Box, Breadcrumbs, Container, Typography} from "@mui/material";
import {ResponseTable} from "./ResponseTable";


export const DcrResponse = observer(() => {
    const {t} = useTranslation();
    const {dcrResponseStore} = useManagementStore();
    return (
        <>
                <Box
                    sx={{
                        mb: 3
                    }}
                >
                    <Breadcrumbs
                        separator="/"
                        // sx={{mt: 1, mb: 3}}
                    >
                        <Typography color="primary"
                                    variant="h6">{t('pageNames.dcr')}</Typography>

                        <Typography color="textSecondary"
                                    variant="h6">
                            {t('dcrPage.dcrTabs.responses')}
                        </Typography>
                    </Breadcrumbs>

                    <ResponseTable responseTableStore={dcrResponseStore}/>
                </Box>
        </>
    );
});
