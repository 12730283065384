import {getObject, setObject} from '../../../../core';
import {ManagementStore} from "../../ManagementStore";
import {MiddleNameTableStore} from './tabs/MiddleNameTableStore';
import {NameTableStore} from "./tabs/NameTableStore";
import {SurnameTableStore} from "./tabs/SurnameTableStore";
import {GenderTableStore} from "./tabs/GenderTableStore";
import {SpecialityTableStore} from "./tabs/SpecialityTableStore";
import {PositionTableStore} from "./tabs/PositionTableStore";
import {PersonWebsiteStatusTableStore} from "./tabs/WebsiteStatusTableStore";
import {PersonEmailTypeTableStore} from "./tabs/EmailTypeTableStore";
import {PersonPhoneCategoryTableStore} from "./tabs/PhoneCategoryTableStore";
import {PersonPhoneTypeTableStore} from "./tabs/PhoneTypeTableStore";
import {PersonEmailStatusTableStore} from "./tabs/EmailStatusTableStore";
import {PersonPhoneStatusTableStore} from "./tabs/PhoneStatusTableStore";
import {DynamicalComponentStore} from "../../../utils";

interface DictionaryPersonStoreLocalFilters {
    tabIndex: number;
    expand: boolean;
}

export class DictionaryPersonStore extends DynamicalComponentStore{
    readonly store: ManagementStore;
    readonly nameStore: NameTableStore;
    readonly MiddleNameTableStore: MiddleNameTableStore;
    readonly surnameStore: SurnameTableStore;
    readonly genderStore: GenderTableStore;
    readonly specialityStore: SpecialityTableStore;
    readonly positionTableStore: PositionTableStore;
    readonly phoneStatus: PersonPhoneStatusTableStore;
    readonly phoneType: PersonPhoneTypeTableStore;
    readonly phoneCategory: PersonPhoneCategoryTableStore;
    readonly emailStatus: PersonEmailStatusTableStore;
    readonly emailType: PersonEmailTypeTableStore;
    readonly websiteStatus: PersonWebsiteStatusTableStore;

    constructor(store: ManagementStore) {
        super()
        this.store = store;

        this.nameStore = new NameTableStore(this);
        this.MiddleNameTableStore = new MiddleNameTableStore(this);
        this.surnameStore = new SurnameTableStore(this);
        this.genderStore = new GenderTableStore(this);
        this.specialityStore = new SpecialityTableStore(this);
        this.positionTableStore = new PositionTableStore(this);
        this.phoneStatus = new PersonPhoneStatusTableStore(this);
        this.phoneType = new PersonPhoneTypeTableStore(this);
        this.phoneCategory = new PersonPhoneCategoryTableStore(this);
        this.emailStatus = new PersonEmailStatusTableStore(this);
        this.emailType = new PersonEmailTypeTableStore(this);
        this.websiteStatus = new PersonWebsiteStatusTableStore(this);

        const filters = getObject<DictionaryPersonStoreLocalFilters>('dictionaryPerson-store');
        if (filters) {
            this._tabIndex = filters.tabIndex;
            this.expand = filters.expand;
        }
    }

    async updateState(): Promise<void> {
        this.saveLocalFilters();

        if (this._tabIndex === 0) {
            this.nameStore.search.reset()
        } else if (this._tabIndex === 1) {
            this.MiddleNameTableStore.search.reset()
        } else if (this._tabIndex === 2) {
            this.surnameStore.search.reset()
        } else if (this._tabIndex === 3) {
            this.genderStore.search.reset()
        } else if (this._tabIndex === 4) {
            this.specialityStore.search.reset()
        } else if (this._tabIndex === 5) {
            this.positionTableStore.search.reset()
        } else if (this._tabIndex === 6) {
            this.phoneStatus.search.reset()
        } else if (this._tabIndex === 7) {
            this.phoneType.search.reset()
        } else if (this._tabIndex === 8) {
            this.phoneCategory.search.reset()
        } else if (this._tabIndex === 9) {
            this.emailStatus.search.reset()
        } else if (this._tabIndex === 10) {
            this.emailType.search.reset()
        } else if (this._tabIndex === 11) {
            this.websiteStatus.search.reset()
        }
    }

    saveLocalFilters = () => {
        setObject<DictionaryPersonStoreLocalFilters>('dictionaryPerson-store', {
            tabIndex: this._tabIndex,
            expand: this.expand,
        })
    }
}
