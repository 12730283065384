import {RequestDetailsStore} from "../../RequestDetailsStore";
import {RequestStatus} from "../../../../../../services/dcr";
import {FunctionComponentElement} from "react";
import {DcrInfoField} from "./CrmSpecification";
import {RequestField} from "../../../types";

export abstract class BaseSpecification {

    DetailsAfterComponent?: FunctionComponentElement<any>;
    DetailsButtonsComponent?: FunctionComponentElement<any>;
    DetailsBeforeComponent?: FunctionComponentElement<any>;

    readonly store: RequestDetailsStore;

    protected constructor(store: RequestDetailsStore) {
        this.store = store;
    }

    abstract get DcrStatusName(): string;

    abstract get DcrInfoFields(): DcrInfoField[];

    abstract get SpecValid(): boolean;

    get valid(): boolean {
        const originalId = this.store.fields.find(z => z.field == 'originalId');

        if(originalId && originalId.store.valid)
            return true;

        return this.store.fields.filter((x) => !x.isHide && !x.store.valid).length == 0 && this.SpecValid &&
            Object.keys(this.store.data.customFieldStore).filter(x => !this.store.data.customFieldStore[x].valid).length == 0
    }

    async changeStatus(status: RequestStatus) {
        await this.store.submit(status)
    }
    async changeAfterInitFields(fields: RequestField[]): Promise<void>{
        await this.changeAfterInitFieldsSpec(fields)
    }

    abstract changeAfterInitFieldsSpec(fields: RequestField[]): void;
}
