/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Api.Import
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {PersonDto, PersonDtoFromJSON,} from '../models';

export interface ImportOrganizationsToCheckRequest {
    boundId?: string;
    page?: number;
    size?: number;
    formFile?: any;
}

export interface ImportOrganizationsToDbRequest {
    importFileId?: string;
    page?: number;
    size?: number;
}

export interface ImportPersonsToCheckRequest {
    boundId?: string;
    formFile?: any;
}

export interface ImportPersonsToDbRequest {
    importFileId?: string;
    page?: number;
    size?: number;
}

/**
 *
 */
export class ImportFilesApi extends runtime.BaseAPI {

    /**
     */
    async importOrganizationsToCheckRaw(requestParameters: ImportOrganizationsToCheckRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonDto>>> {
        const queryParameters: any = {};

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.formFile !== undefined) {
            //@ts-ignore
            formParams.append('formFile', requestParameters.formFile);
        }

        const response = await this.request({
            path: `/api/ImportFiles/v1/ImportOrganizationsToCheck`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonDtoFromJSON));
    }

    /**
     */
    async importOrganizationsToCheck(requestParameters: ImportOrganizationsToCheckRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonDto>> {
        const response = await this.importOrganizationsToCheckRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async importOrganizationsToDbRaw(requestParameters: ImportOrganizationsToDbRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonDto>>> {
        const queryParameters: any = {};

        if (requestParameters.importFileId !== undefined) {
            queryParameters['importFileId'] = requestParameters.importFileId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ImportFiles/v1/ImportOrganizationsToDb`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonDtoFromJSON));
    }

    /**
     */
    async importOrganizationsToDb(requestParameters: ImportOrganizationsToDbRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonDto>> {
        const response = await this.importOrganizationsToDbRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async importPersonsToCheckRaw(requestParameters: ImportPersonsToCheckRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonDto>>> {
        const queryParameters: any = {};

        if (requestParameters.boundId !== undefined) {
            queryParameters['boundId'] = requestParameters.boundId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.formFile !== undefined) {
            //@ts-ignore
            formParams.append('formFile', requestParameters.formFile);
        }

        const response = await this.request({
            path: `/api/ImportFiles/v1/ImportPersonsToCheck`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonDtoFromJSON));
    }

    /**
     */
    async importPersonsToCheck(requestParameters: ImportPersonsToCheckRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonDto>> {
        const response = await this.importPersonsToCheckRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async importPersonsToDbRaw(requestParameters: ImportPersonsToDbRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PersonDto>>> {
        const queryParameters: any = {};

        if (requestParameters.importFileId !== undefined) {
            queryParameters['importFileId'] = requestParameters.importFileId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ImportFiles/v1/ImportPersonsToDb`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonDtoFromJSON));
    }

    /**
     */
    async importPersonsToDb(requestParameters: ImportPersonsToDbRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PersonDto>> {
        const response = await this.importPersonsToDbRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
