import {Box, Breadcrumbs, Card, Typography} from "@mui/material";
import {observer} from 'mobx-react';
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {AuthorizePermission, PageTab, PageTabs} from '../../components';
import {AllPermissions} from '../../core/const';
import {useManagementStore} from "../../stores";
import {Parameters} from './Parameters';
import {DoubleTable} from './doubles/DoubleTable';
import {JobsTabs} from './jobs/JobsTabs';
import {NotesTable} from './notes/NotesTable';
import {OthersTab} from './others/OthersTab';
import {PersonSpecialities} from './specialities/PersonSpecialities';

export const Person = observer(() => {
    const { id } = useParams<{ id: string }>();
    const { personStore } = useManagementStore();
    const person = personStore.current;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handlePersonsNavigate = () => {
        navigate('/persons');
    }

    useEffect(() => {
        get();
    }, [id, personStore.store.store.authorizationStore.jurisdictions.value?.jurisdictionId]);

    const get = async () => {
        try {
            if (id) {
                await personStore.getPersonPage(id);
            }
        } catch (e) {
        }
    };

    const renderTabs = (index: number) => {
        if (person) {
            switch (index) {
                case 0:
                    return (
                        <AuthorizePermission permissions={AllPermissions.person.read} showWarning>
                            <Parameters person={person} />
                        </AuthorizePermission>
                    )
                case 1:
                    return (
                        <DoubleTable store={person.doubleStore} style={{ flex: 1 }} />

                    )
                case 2:
                    return (
                        <AuthorizePermission permissions={AllPermissions.person.personspecialities.read} showWarning>
                            <PersonSpecialities store={person.specialities}/>
                        </AuthorizePermission>
                    )
                case 3:
                    return (
                        <AuthorizePermission permissions={AllPermissions.person.personjobs.read} showWarning>
                            <JobsTabs store={person.jobTabs} style={{ flex: 1 }} />
                        </AuthorizePermission>
                    )
                case 4:
                    return (
                        <AuthorizePermission permissions={AllPermissions.person.notes.read} showWarning>
                            <NotesTable store={person.notes} style={{flex: 1}}/>
                        </AuthorizePermission>
                    )
                case 5:
                    return <OthersTab store={person.personOthersStore} style={{flex: 1}}/>
            }
        }
    };

    return (
        <>

            <Box>
                {
                    person
                        ? (<Breadcrumbs
                                separator="/"
                                sx={{ mt: 1 }}
                            >
                                <Box onClick={handlePersonsNavigate} style={{ cursor: 'pointer' }}>
                                    <Typography color="primary"
                                                variant="h6">{t('pageNames.persons')}
                                    </Typography>
                                </Box>
                                <Typography color="textSecondary"
                                            variant="h6">
                                    {person.pSurname.value?.nameRus} {person.pName.value?.nameRus} {person.pMiddleName.value?.nameRus}
                                </Typography>
                            </Breadcrumbs>
                        )
                        : '...'
                }
            </Box>
            <AuthorizePermission permissions={AllPermissions.person.read} showWarning>
                <Card
                    style={{ display: 'flex', flexDirection: 'column' }}
                    sx={{
                        px: 2,
                        mt: 2
                    }}
                >
                    {
                        person
                            ? (
                                <>
                                    <PageTabs
                                        index={person.tabIndex}
                                        onChange={t => person.tabIndex = t}
                                    >
                                        <PageTab label={t('personPage.personTabs.properties')} style={{ width: '15%' }} testid="person-properties-tabs" />
                                        <PageTab label={t('personPage.personTabs.doubles')} testid="person-doubles-tabs"
                                                 style={(person.isDouble) ? { display: 'none' } : { width: '15%' }} />
                                        <PageTab label={t('personPage.personTabs.specialities')} style={{ width: '15%' }} testid="person-specialities-tabs" />
                                        <PageTab label={t('personPage.personTabs.jobs')} style={{ width: '15%' }} testid="person-jobs-tabs" />
                                        <PageTab
                                            label={!person.countNotes ? t('personPage.personTabs.notes') : `${t('personPage.personTabs.notes')} ${person.countNotes}`}
                                            style={{width: '15%'}} testid="person-notes-tabs"/>
                                        <PageTab label={t('personPage.personTabs.others')} style={{ width: '15%' }} testid="person-others-tabs" />
                                    </PageTabs>

                                    <Box sx={{ mt: 2, mb: 2 }}>
                                        {renderTabs(person.tabIndex)}
                                    </Box>
                                </>
                            )
                            : '...'
                    }
                </Card>
            </AuthorizePermission>
        </>
    );
});
