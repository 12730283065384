import {useSnackbar} from 'notistack';
import {observer} from 'mobx-react';
import {Box, Button} from '@mui/material';
import {FormRoot} from '.';
import {useTranslation} from "react-i18next";
import React from "react";

type Props = {
    submitButton?: string;
    children?: React.ReactNode[] | React.ReactElement;
    disabled: boolean;
    onSubmit: () => Promise<void>;
    onClose?: () => void;
    closeAfterSubmit?: boolean;
    processing?: boolean
};

export const ConfirmationForm = observer(({
                                              children,
                                              disabled,
                                              onSubmit,
                                              onClose,
                                              submitButton,
                                              closeAfterSubmit = true,
                                              processing = false
                                          }: Props) => {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    if (!submitButton)
        submitButton = t('buttons.delete')

    const handleSubmit = async () => {
        try {
            await onSubmit();
            if (onClose && closeAfterSubmit) {
                onClose();
            }
        } catch (e: any) {
            enqueueSnackbar(e.message);
        }
    };

    return (
        <FormRoot>
            <Box sx={{p: 2}}>
                {children}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', padding: '1px 8px' }}
            >
                <Button
                    onClick={onClose}
                    sx={{m: 1, height: 40, width: 120}}
                    variant="outlined"
                    disabled={processing}
                >
                    {t('buttons.cancel')}
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                    onClick={handleSubmit}
                    sx={{m: 1, height: 40, width: 120}}
                    type="submit"
                    variant="contained"
                    disabled={disabled}
                >
                    {submitButton}
                </Button>
            </Box>
        </FormRoot>
    );
});
