/* tslint:disable */
/* eslint-disable */
/**
 * Data360 DCR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {RequestFieldType, RequestFieldTypeFromJSON, RequestFieldTypeToJSON,} from './RequestFieldType';

/**
 * 
 * @export
 * @interface FirstValueDto
 */
export interface FirstValueDto {
    /**
     * 
     * @type {string}
     * @memberof FirstValueDto
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FirstValueDto
     */
    field?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FirstValueDto
     */
    newName?: string | null;
    /**
     * 
     * @type {RequestFieldType}
     * @memberof FirstValueDto
     */
    type?: RequestFieldType;
}

export function FirstValueDtoFromJSON(json: any): FirstValueDto {
    return FirstValueDtoFromJSONTyped(json, false);
}

export function FirstValueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FirstValueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'field': !exists(json, 'field') ? undefined : json['field'],
        'newName': !exists(json, 'newName') ? undefined : json['newName'],
        'type': !exists(json, 'type') ? undefined : RequestFieldTypeFromJSON(json['type']),
    };
}

export function FirstValueDtoToJSON(value?: FirstValueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'field': value.field,
        'newName': value.newName,
        'type': RequestFieldTypeToJSON(value.type),
    };
}

