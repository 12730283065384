import { TableBody } from "@mui/material";

type Props = {
    children?: React.ReactNode;
};

export const Body = ({ children }: Props) => {

    return (
        <TableBody
            style={{
                maxWidth: '100%'
            }}
        >
            {children}
        </TableBody>
    );
};