import {observer} from 'mobx-react';
import React from 'react';
import {LocalitySelectStore} from "./LocalitySelectStore";
import {useTranslation} from "react-i18next";
import {DefaultSelect} from "../../shared/components/DefaultSelect";

interface Props {
    store: LocalitySelectStore;
    stepLocality?: () => void;
    style?: React.CSSProperties;
}

export const LocalityTypeSelect = observer(({store, stepLocality, style}: Props) => {
    const {t} = useTranslation();

    return (
        <>
            <div style={{marginBottom: 20}}>
                <DefaultSelect store={store.localityType}
                               testid="localityTypeSelect-localityType"
                               nameExp={'nameRus'}
                               title={t('localities.localityType')}
                               onValue={() => {
                                   store.resetLocalityType();
                                   store.onTypeAddress(true)
                                }}
                               onClear={() => {
                                   store.resetLocalityType();
                                   store.onTypeAddress(false);
                                   stepLocality && stepLocality();

                               }}
                               zIndex={1300}
                               hasLabel/>
            </div>
        </>
    );
});
