import {IFormValueWithError, ILoad} from "../../../../../../core";
import {FunctionComponentElement} from "react";
import {PersonStore} from "../../specifications/entity/PersonStore";
import {OrganizationStore} from "../../specifications/entity/OrganizationStore";
import {action, computed, makeObservable, observable} from "mobx";
import {ChangeRequestField} from "../../../../../../services/dcr";

export abstract class CustomFieldStore<T extends (PersonStore | OrganizationStore)> implements ILoad, IFormValueWithError<
    {
        additionalFields:  {[p: string]: string | null | undefined} | null | undefined,
        fields: ChangeRequestField[] | null | undefined
    }> {
    store: T;
    protected constructor(store: T) {
        this.store = store;
        makeObservable(this,{
            error: observable,
            loading: observable,
            valid: computed,
            pull: action,
            reset: action,
            validate: action,
            update: action,
            value: computed
        })
    }
    abstract Component?: FunctionComponentElement<any>;
    error: string = "";
    loading: boolean = false;
    get valid(): boolean{
        return !this.error
    }
    abstract pull(): Promise<void> | void;
    abstract reset(): void;
    abstract validate(): void;
    abstract get value(): { additionalFields: { [p: string]: string | null | undefined } | null | undefined; fields: ChangeRequestField[] | null | undefined } | null;
    update(dto: { additionalFields: { [p: string]: string | null | undefined } | null | undefined; fields: ChangeRequestField[] | null | undefined } | null): void {
    }
}