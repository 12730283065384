import {makeAutoObservable} from 'mobx';
import {getApiConfiguration, ILoad} from "../../../../core";
import {
    GendersApi,
    LocalitiesApi,
    OrganizationPhoneCategoriesApi,
    OrganizationPhoneStatusesApi,
    OrganizationPhoneTypesApi,
    OrganizationsApi,
    PostsApi,
    SpecialitiesApi,
    TypeOrgsApi
} from "../../../../services/management";
import {ResponseItemStore} from './ResponseItemStore';
import {RequestApi, ResponseDto} from "../../../../services/dcr";
import {t} from "i18next";
import {getOrgName} from "../../../utils";
import {RequestFieldTypeEnum, RequestTypeEnum, ResponseField} from "../types";

export class ResponseDetailsStore implements ILoad {

    readonly _store: ResponseItemStore;
    dto: ResponseDto;
   // request: RequestDto = {} as RequestDto;

    processing: boolean = false;

    fields: ResponseField[] = [];
    loading: boolean = false;

    constructor(store: ResponseItemStore, dto: ResponseDto) {
        this._store = store;
        this.dto = dto;

        makeAutoObservable(this);
    }


    get rowValues(): ResponseField[][] {
        let size = 5;
        let subarray: ResponseField[][] = [];
        let array = this.fields ? this.fields : []
        for (let i = 0; i < Math.ceil(array?.length / size); i++) {
            subarray[i] = array.slice((i * size), (i * size) + size);
        }
        return subarray;
    }

    async pull(): Promise<void> {
        this.loading = true;

        let fields: ResponseField[] = []

        const model = await new RequestApi().apiDcrV1RequestsDetailsRequestIdGet({requestId: this.dto.requestId})

        // for (const element of model.fields!)
        //     fields.push(await this.generateField(element));

        this.fields = [...fields]

        this.loading = false;
    }

    async generateField(element: {
        field: string,
        value: string,
        type: RequestTypeEnum,
        requestFieldType: RequestFieldTypeEnum
    }): Promise<ResponseField> {
        const field: ResponseField = {
            name: t(`dcrPage.fields.${element.field}`),
            value: element.value ? element.value : "",
        }
        let external = element.requestFieldType === RequestFieldTypeEnum.External

        switch (element.field) {
            case "specialization" : {
                const api = new SpecialitiesApi(getApiConfiguration());
                let res = element.value ?
                    external ?
                        (await api.getSpecialities({
                            searchByExternalId: parseInt(element.value!)
                        }))[0] :
                        await api.getSpecialityById({id: element.value!})
                    : null;
                field.value = res ? res.nameRus : "";
                break;
            }
            case "company":
            case "companyId" : {
                const api = new OrganizationsApi(getApiConfiguration());
                let res = element.value ?
                    external ?
                        (await api.getOrganizations({
                            searchByExternalId: element.value!
                        }))[0] :
                        await api.getOrganizationById({id: element.value})
                    : null;
                field.value = res ? getOrgName(res) : "";
                break;
            }
            case "region":
            case "city": {
                const api = new LocalitiesApi(getApiConfiguration());
                let res = element.value ?
                    external ?
                        (await api.getLocalities({
                            searchByExternalId: element.value!
                        }))[0] :
                        await api.getLocalityById({id: element.value})
                    : null;
                field.value = res ? res.nameRus : "";
                break;
            }
            case "position": {
                const api = new PostsApi(getApiConfiguration());
                let res = element.value ?
                    external ?
                        (await api.getPosts({
                            searchByExternalId: element.value!
                        }))[0] :
                        await api.getPostById({id: element.value})
                    : null;
                field.value = res ? res.nameRus : "";
                break;
            }
            case "companytype": {
                const api = new TypeOrgsApi(getApiConfiguration());
                let res = element.value ?
                    external ?
                        (await api.getTypeOrgs({
                            searchByExternalId: element.value!
                        }))[0] :
                        await api.getTypeOrgById({id: element.value})
                    : null;
                field.value = res ? res.nameRus : "";
                break;
            }
            case "phonestatus": {
                let res = element.value ? (await new OrganizationPhoneStatusesApi(getApiConfiguration()).getOrganizationPhoneStatusById({
                    id: element.value!
                })) : null;
                field.value = res ? res.nameRus : "";
                break;
            }
            case "phonetype": {
                let res = element.value ? (await new OrganizationPhoneTypesApi(getApiConfiguration()).getOrganizationPhoneTypeById({
                    id: element.value!
                })) : null;
                field.value = res ? res.nameRus : "";
                break;
            }
            case "phonecategory": {
                let res = element.value ? (await new OrganizationPhoneCategoriesApi(getApiConfiguration()).getOrganizationPhoneCategoryById({
                    id: element.value!
                })) : null;
                field.value = res ? res.nameRus : "";
                break;
            }
            case "gender": {
                let res = element.value ? (await new GendersApi(getApiConfiguration()).getGenderById({
                    id: element.value!
                })) : null;
                field.value = res ? res.nameRus : "";
                break;
            }
            default:
                break
        }

        return field;
    }
}
