import {ItemsLoadStore} from "../../../../utils";
import {ClientJurisdictionsApi} from "../../../../../services/auth";
import {BoundsStore} from "./items/BoundsStore";
import {ClientStore} from "../../client/ClientStore";

export class ClientBoundStore extends ItemsLoadStore<BoundsStore> {
    store: ClientStore;

    constructor(store: ClientStore) {
        super();
        this.store = store;
    }

    async request(): Promise<BoundsStore[]> {
        const jurisdictions = await new ClientJurisdictionsApi().getClientJurisdictions({clientId: this.store.id})
        return jurisdictions.map(x => new BoundsStore(this, this.store.dto, x.jurisdiction!));
    }
}
