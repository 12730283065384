import i18n from "i18next";

export enum ExportFormat {
    none = 0,
    Xlsx = 1 << 0,
    Csv = 1 << 1
}

export enum PersonGuidesEnum {
    PersFirstNames = 1 << 0,
    PersMiddleNames = 1 << 1,
    PersSurnames = 1 << 2,
    PersGenders = 1 << 3,
    PersSpecialities = 1 << 4,
    PersPositions = 1 << 5,
}

export enum OrganizationGuidesEnum {
    OrgsTypeNames = 1 << 6,
    OrgsTypeOrgs = 1 << 7,
    OrgsPhoneStatus = 1 << 8,
    OrgsPhoneType = 1 << 9,
    OrgsPhoneCategories = 1 << 10,
    OrgsClinicCategories = 1 << 11
}


export function mappingExportFormatToString(format: string | ExportFormat): string {
    switch (format) {
        case ExportFormat.Csv: return 'csv';
        case ExportFormat.Xlsx: return 'xlsx';
    }
    return '';
}

export function mappingGuidesPersonToString(item: string | PersonGuidesEnum): string {
    switch (item) {
        case PersonGuidesEnum.PersFirstNames: return i18n.t('dataPage.guidesNames.names');
        case PersonGuidesEnum.PersMiddleNames: return i18n.t('dataPage.guidesNames.middleNames');
        case PersonGuidesEnum.PersSurnames: return i18n.t('dataPage.guidesNames.surnames');
        case PersonGuidesEnum.PersGenders: return i18n.t('dataPage.guidesNames.genders');
        case PersonGuidesEnum.PersSpecialities: return i18n.t('dataPage.guidesNames.specialities');
        case PersonGuidesEnum.PersPositions: return i18n.t('dataPage.guidesNames.positions');
    }
    return '';
}

export function mappingGuidesOrganizationToString(item: string | OrganizationGuidesEnum): string {
    switch (item) {
        case OrganizationGuidesEnum.OrgsTypeNames: return i18n.t('dataPage.guidesNames.typeNames');
        case OrganizationGuidesEnum.OrgsTypeOrgs: return i18n.t('dataPage.guidesNames.typeOrgs');
        case OrganizationGuidesEnum.OrgsPhoneStatus: return i18n.t('dataPage.guidesNames.phoneStatus');
        case OrganizationGuidesEnum.OrgsPhoneType: return i18n.t('dataPage.guidesNames.phoneType');
        case OrganizationGuidesEnum.OrgsPhoneCategories: return i18n.t('dataPage.guidesNames.phoneCategories');
        case OrganizationGuidesEnum.OrgsClinicCategories: return i18n.t('dataPage.guidesNames.clinicCategories');
    }
    return '';
}
