import {makeObservable, observable} from 'mobx';
import {getApiConfiguration, IProcessing} from '../../../../../../core';
import {LoadStore} from '../../../../../utils';
import {UserItemStore} from "../UserItemStore";
import {JurisdictionDto, JurisdictionsApi} from "../../../../../../services/management";
import {DefaultMultiSelectStore} from "../../../../../../components/shared/DefaultMultiSelectStore";
import {UserJurisdictionsApi} from "../../../../../../services/auth";

export class UserJurisdictionsTabStore extends LoadStore implements IProcessing {
    readonly _store: UserItemStore;
    allJurisdictionsControl: DefaultMultiSelectStore<JurisdictionDto> = new DefaultMultiSelectStore([],
        (filters) => new JurisdictionsApi(getApiConfiguration()).getJurisdictions(filters));
    processing: boolean = false;

    constructor(store: UserItemStore) {
        super();
        this._store = store;
        makeObservable(this, {
            processing: observable
        })
        this.allJurisdictionsControl.required = true;
    }

    async remove(ids: string[]) {
        await new UserJurisdictionsApi(getApiConfiguration({successTooltip: false})).removeUserJurisdictions({
            userId: this._store.dto.id,
            setUserJurisdictionDto: ids.map(x => ({
                jurisdictionId: x,
                userId: this._store.dto.id
            }))
        });
    }

    async set(ids: string[]) {
        await new UserJurisdictionsApi(getApiConfiguration()).createUserJurisdictions({
            userId: this._store.dto?.id!,
            setUserJurisdictionDto: ids.map(x => ({
                jurisdictionId: x,
                userId: this._store.dto.id
            }))
        });
    }

    async submit(): Promise<void> {
        const userJurIds = this.allJurisdictionsControl.firstValue.map(x => x.id);
        const currentIds = this.allJurisdictionsControl.value.map(x => x.id);

        const deletesIds = userJurIds.filter(x => !currentIds.includes(x));
        const insetIds = currentIds.filter(x => !userJurIds.includes(x));

        await this.remove(deletesIds)
        await this.set(insetIds)
        await this.allJurisdictionsControl.update(this.allJurisdictionsControl.value)
        if(this._store.dto.id == this._store._store.store.store.authorizationStore.id){
            this._store._store.store.store.authorizationStore.jurisdictions.setValueWithoutEffects(null)
            await this._store._store.store.store.authorizationStore.jurisdictions.pull()
        }
    }

    async request(): Promise<void> {
        let filters: any = {
            page: 1,
            size: 1000,
            userId: this._store.dto.id,
        };
        const result = await new UserJurisdictionsApi(getApiConfiguration()).getUserJurisdictions(filters);
        this.allJurisdictionsControl.update(result.map(x => ({id: x.jurisdictionId, nameRus: x.jurisdiction.name!, nameEng: ""})))
    };
}
