import {computed, makeObservable, observable} from 'mobx';
import {getApiConfiguration, IExpanded} from "../../../../../../core";
import {GetTypeOrgsRequest, QueryType, TypeOrgDto, TypeOrgsApi} from "../../../../../../services/management";
import {ItemsLoadStore} from '../../../../../utils';
import {TypeOrganizationTableStore} from "./TypeOrganizationTableStore";
import {DefaultFormModalStore} from "../../../../../../components/shared/DefaultFormModalStore";
import {DefaultValueStore} from "../../../../../../components/shared/DefaultValueStore";
import {DefaultFormStore} from '../../../../../../components/shared/DefaultFormStore';

export class TypeOrganizationItemStore extends ItemsLoadStore<TypeOrganizationItemStore> implements IExpanded{
    dto: TypeOrgDto;
    store: TypeOrganizationTableStore
    _expand: boolean = false;
    loaded: boolean = false;
    form: DefaultFormStore<{
        nameRus: DefaultValueStore<string>,
        nameEng: DefaultValueStore<string>,
        clinicCategoryId: DefaultValueStore<string>,
        isActive: DefaultValueStore<boolean>
    }>

    openDetails: boolean = false;

    constructor(store: TypeOrganizationTableStore,
                dto: TypeOrgDto, items: TypeOrganizationItemStore[] = [],
                expanded = false) {
        super()
        this.store = store;
        this.dto = dto;
        this.value = items
        this._expand = expanded && items.length != 0;
        this.form = new DefaultFormModalStore({
            nameRus: new DefaultValueStore<string>(dto.nameRus),
            nameEng: new DefaultValueStore<string>(dto.nameEng),
            clinicCategoryId: new DefaultValueStore<string>(dto.clinicCategoryId),
            isActive: new DefaultValueStore<boolean>(!dto.isInactive)
        }, (e) => store.save(this.dto.id, {...e, ...{isInactive: !e.isActive}}))
        this.form.fields.nameRus.required = true;
        makeObservable(this, {
            _expand: observable,
            expand: computed,
            openDetails: observable
        })
    }

    update(dto: TypeOrgDto) {
        this.dto = dto;
        this.form.fields.nameRus.update(dto.nameRus);
        this.form.fields.nameEng.update(dto.nameEng ?? "");
        this.form.fields.isActive.update(!dto.isInactive)
    }


    get allChildren(): TypeOrganizationItemStore[] {
        let items: TypeOrganizationItemStore[] = [];
        this.value?.forEach(item => {
            items.push(item);
            items = items.concat(item.allChildren)
        })
        return items;
    }

    get selectedChildren() : TypeOrganizationItemStore[] {
        return this.allChildren.filter(x => x.expand)
    }

    get expand(): boolean{
        return this._expand
    }

    set expand(value:boolean){
        this._expand = value;
        if(this.value.length == 0)
            this.updateState().then()
    }

    async request(): Promise<TypeOrganizationItemStore[]> {
        const filters = {
            queryType: QueryType.Tree,
            parentId: this.dto.id,
            page: 1,
            size: 100,
        } as GetTypeOrgsRequest;

        const api: TypeOrgsApi = new TypeOrgsApi(getApiConfiguration());
        let result = await api.getTypeOrgs(filters);
        this.loaded = true;
        return result.map(x => new TypeOrganizationItemStore(this.store ,x));
    }
}
