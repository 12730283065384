/* tslint:disable */
/* eslint-disable */
/**
 * Data360.Api.Export
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DictionaryType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_4: 4,
    NUMBER_8: 8,
    NUMBER_16: 16,
    NUMBER_32: 32,
    NUMBER_64: 64,
    NUMBER_128: 128,
    NUMBER_256: 256,
    NUMBER_512: 512,
    NUMBER_1024: 1024,
    NUMBER_2048: 2048
} as const;
export type DictionaryType = typeof DictionaryType[keyof typeof DictionaryType];


export function DictionaryTypeFromJSON(json: any): DictionaryType {
    return DictionaryTypeFromJSONTyped(json, false);
}

export function DictionaryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DictionaryType {
    return json as DictionaryType;
}

export function DictionaryTypeToJSON(value?: DictionaryType | null): any {
    return value as any;
}

