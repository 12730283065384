import {getObject, setObject} from '../../core';
import {makeAutoObservable} from 'mobx';
import {RootStore} from "../RootStore";

interface ThemeColorState {
    themeSettings: 'light' | 'dark'
}

export class ThemeColorStore {
    readonly store: RootStore;
    private _themeSettings: string = 'dark'

    constructor(store: RootStore) {
        this.store = store;
        const st = getObject<ThemeColorState>('themeColor-store');
        if (st) {
            this._themeSettings = st.themeSettings;
        }
        makeAutoObservable(this);
    }

    get themeSettings() {
        //@ts-ignore
        return this._themeSettings;
    }

    set themeSettings(value: 'light' | 'dark') {
        this._themeSettings = value
        this.saveState();
    }

    saveState() {
        setObject<ThemeColorState>('themeColor-store', {
            themeSettings: this.themeSettings,
        });
    }
}
