/* tslint:disable */
/* eslint-disable */
/**
 * Data360 Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: support@data-360.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CountDto,
    CountDtoFromJSON,
    CountDtoToJSON,
    EmailTypeDto,
    EmailTypeDtoFromJSON,
    EmailTypeDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    OCMObjectState,
    OCMObjectStateFromJSON,
    OCMObjectStateToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetEmailTypeDto,
    SetEmailTypeDtoFromJSON,
    SetEmailTypeDtoToJSON,
} from '../models';

export interface CreateOrganizationEmailTypeRequest {
    setEmailTypeDto: SetEmailTypeDto;
}

export interface DeleteOrganizationEmailTypeRequest {
    id: string;
}

export interface GetOrganizationEmailTypeByIdRequest {
    id: string;
}

export interface GetOrganizationEmailTypeByNameRequest {
    name: string;
}

export interface GetOrganizationEmailTypesRequest {
    search?: string;
    orderBy?: string;
    searchByExternalId?: string;
    order?: ListSortDirection;
    page?: number;
    size?: number;
    states?: Array<OCMObjectState>;
}

export interface GetOrganizationEmailTypesCountRequest {
    search?: string;
    states?: Array<OCMObjectState>;
}

export interface UpdateOrganizationEmailTypeRequest {
    id: string;
    setEmailTypeDto: SetEmailTypeDto;
}

/**
 * 
 */
export class OrganizationEmailTypesApi extends runtime.BaseAPI {

    /**
     */
    async createOrganizationEmailTypeRaw(requestParameters: CreateOrganizationEmailTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailTypeDto>> {
        if (requestParameters.setEmailTypeDto === null || requestParameters.setEmailTypeDto === undefined) {
            throw new runtime.RequiredError('setEmailTypeDto','Required parameter requestParameters.setEmailTypeDto was null or undefined when calling createOrganizationEmailType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/emails/types/organization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetEmailTypeDtoToJSON(requestParameters.setEmailTypeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async createOrganizationEmailType(requestParameters: CreateOrganizationEmailTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailTypeDto> {
        const response = await this.createOrganizationEmailTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteOrganizationEmailTypeRaw(requestParameters: DeleteOrganizationEmailTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOrganizationEmailType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/types/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteOrganizationEmailType(requestParameters: DeleteOrganizationEmailTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailTypeDto> {
        const response = await this.deleteOrganizationEmailTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationEmailTypeByIdRaw(requestParameters: GetOrganizationEmailTypeByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrganizationEmailTypeById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/types/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationEmailTypeById(requestParameters: GetOrganizationEmailTypeByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailTypeDto> {
        const response = await this.getOrganizationEmailTypeByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationEmailTypeByNameRaw(requestParameters: GetOrganizationEmailTypeByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailTypeDto>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getOrganizationEmailTypeByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/types/organization/name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationEmailTypeByName(requestParameters: GetOrganizationEmailTypeByNameRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailTypeDto> {
        const response = await this.getOrganizationEmailTypeByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationEmailTypesRaw(requestParameters: GetOrganizationEmailTypesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<EmailTypeDto>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.searchByExternalId !== undefined) {
            queryParameters['searchByExternalId'] = requestParameters.searchByExternalId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/types/organization`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmailTypeDtoFromJSON));
    }

    /**
     */
    async getOrganizationEmailTypes(requestParameters: GetOrganizationEmailTypesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<EmailTypeDto>> {
        const response = await this.getOrganizationEmailTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationEmailTypesCountRaw(requestParameters: GetOrganizationEmailTypesCountRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CountDto>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/management/v1/emails/types/organization/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountDtoFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationEmailTypesCount(requestParameters: GetOrganizationEmailTypesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CountDto> {
        const response = await this.getOrganizationEmailTypesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrganizationEmailTypeRaw(requestParameters: UpdateOrganizationEmailTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrganizationEmailType.');
        }

        if (requestParameters.setEmailTypeDto === null || requestParameters.setEmailTypeDto === undefined) {
            throw new runtime.RequiredError('setEmailTypeDto','Required parameter requestParameters.setEmailTypeDto was null or undefined when calling updateOrganizationEmailType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/management/v1/emails/types/organization/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetEmailTypeDtoToJSON(requestParameters.setEmailTypeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateOrganizationEmailType(requestParameters: UpdateOrganizationEmailTypeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailTypeDto> {
        const response = await this.updateOrganizationEmailTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
