import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
    AuthorizePermission,
    FormRoot,
    ParamsButtonGroup,
} from '../../../../../components';
import { DefaultFormStore } from '../../../../../components/shared/DefaultFormStore';
import { DefaultValueStore } from '../../../../../components/shared/DefaultValueStore';
import { DefaultValue } from '../../../../../components/shared/components/DefaultValue';
import { AllPermissions } from '../../../../../core/const';
import { StoreProps, StyleProps } from '../../../../../stores';

export const ParametersTab = observer(
    ({
        store,
        style,
    }: StoreProps<
        DefaultFormStore<{
            name: DefaultValueStore<string>;
            description: DefaultValueStore<string>;
        }>
    > &
        StyleProps) => {
        const { t } = useTranslation();

        return (
            <>
                <FormRoot style={style}>
                    <DefaultValue
                        store={store.fields.name}
                        title={t('common.name')}
                    />
                    <DefaultValue
                        store={store.fields.description}
                        multiline
                        rows={4}
                        InputProps={{
                            sx: {
                                height: '100%',
                            },
                        }}
                        style={{ width: '100%' }}
                        title={t('usersPage.roles.create.description')}
                    />
                    <AuthorizePermission
                        permissions={AllPermissions.role.update}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <ParamsButtonGroup
                                onCancel={() => store.reset()}
                                onSave={async () => await store.submit()}
                                isCancelBtnDisabled={
                                    store.processing || store.equals
                                }
                                isSaveBtnDisabled={
                                    store.processing ||
                                    store.equals ||
                                    !store.valid
                                }
                            />
                        </Box>
                    </AuthorizePermission>
                </FormRoot>
            </>
        );
    }
);
