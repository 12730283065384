import {makeAutoObservable} from 'mobx';
import {ILoad} from "../../../../core";
import {RequestItemStore} from "./RequestItemStore";
import {
    ChangeRequestField,
    ClientType,
    FieldInfoDto,
    FirstValueDto,
    RequestApi,
    RequestDto,
    RequestStatus,
    SubRequest
} from "../../../../services/dcr";
import {OrganizationStore} from "./specifications/entity/OrganizationStore";
import {PersonStore} from "./specifications/entity/PersonStore";
import {OrganizationBlock, OrganizationFieldPriority, PersonBlock, PersonFieldPriority,} from "../services";
import {DefaultSelectStore} from "../../../../components/shared/DefaultSelectStore";
import {DefaultValueStore} from "../../../../components/shared/DefaultValueStore";
import {BaseSpecification} from "./specifications/clients/BaseSpecification";
import {AzSpecification} from "./specifications/clients/AzSpecification";
import {CrmSpecification} from "./specifications/clients/CrmSpecification";
import {
    DcrOrganizationBlockInfo,
    DcrPersonBlockInfo,
    DCRStatusEnum,
    DoubleEnum,
    OrganizationField,
    PersonField,
    RequestField,
    RequestFieldTypeEnum,
    RequestStatusEnum,
    RequestTypeEnum
} from '../types';
import {ExternalApiSpecification} from './specifications/clients/ExternalApiSpecification';
import {ChangeRequestFieldsCommand} from "../../../../services/dcr/models/ChangeRequestFieldsCommand";

export class RequestDetailsStore implements ILoad {
    readonly _store: RequestItemStore;
    dto: RequestDto;
    loading: boolean = false;
    declineOpen: boolean = false;
    notVerifiedeOpen: boolean = false;
    specification: BaseSpecification;
    commentStore: DefaultValueStore<string> = new DefaultValueStore<string>("");
    data: OrganizationStore | PersonStore;
    fields: RequestField[] = [];
    subRequests: SubRequest[] = [];
    notVerifiedCreateRequestId?: string = "";
    errors: Map<OrganizationField | PersonField, string> = new Map<OrganizationField | PersonField, string>();
    firstRequestFields: FirstValueDto[] = [];
    requestFields: Array<FieldInfoDto> = [];
    deleteDialogState: boolean = false;

    constructor(store: RequestItemStore, dto: RequestDto) {
        this._store = store;
        this.dto = dto;
        this.data = this.dto.type === RequestTypeEnum.Organization ? new OrganizationStore(this) : new PersonStore(this)
        this.specification = this.isAz ? new AzSpecification(this) : this.isExternalApiClient ? new ExternalApiSpecification(this) : new CrmSpecification(this)
        this.commentStore.validate();
        makeAutoObservable(this);

        this.errors.keys()
    }

    get visibilityDbData(): boolean {
        return (this.dto.dcrStatus == DCRStatusEnum.Update || !!this.dto?.data360Id) && !!this.data
    }

    get errorApi(): boolean {
        return (!this.data?.data?.id && this.dto.dcrStatus == DCRStatusEnum.Update) && !!this.data?.loaded
    }

    get edit(): boolean {
        return this.isTake &&
            this.dto.status !== RequestStatusEnum.Verified &&
            this.dto.status !== RequestStatusEnum.Double &&
            this.dto.status !== RequestStatusEnum.Decline &&
            this.dto.status == RequestStatusEnum.OnVerification &&
            this.dto.isErrorRequest !== true;
    }

    get isAz(): boolean {
        return this.dto.client === "AZ";
    }

    get isExternalApiClient(): boolean {
        return this.dto.clientType == ClientType.NUMBER_2; //ExternalApi
    }

    get visibilityFieldsFirsValues(): boolean {
        return this.isExternalApiClient
    }

    get visibilityFieldsNameValues(): boolean {
        return !this.isAz
    }

    getFirstValue(fieldName: string): string {
        let res = this.firstRequestFields.find(x => x.field == fieldName)
        return res ? res.value! : "Пусто";
    }

    getFirstValueName(fieldName: string): string {
        let res = this.firstRequestFields.find(x => x.field == fieldName)
        let name = "";
        if (res?.type == RequestFieldTypeEnum.String) {
            name = res?.value ?? "Пусто"
        } else {
            name = res?.newName ?? "Название отсутвует"
        }

        return name;
    }

    get changeRequestFieldsCommand(): ChangeRequestFieldsCommand {
        let additionalFields = {} as any
        let fields: ChangeRequestField[] = []

        for (const element of this.fields.filter(x => !x.ignore)) {
            let value = element.store.type === "select"
                ? (element.store as DefaultSelectStore<any>).valueId
                : element.store.value;

            let valueType = element.store.type === "select" ?
                RequestFieldTypeEnum.Data360 :
                RequestFieldTypeEnum.String;

            if (element.field == 'isDouble') {
                value = element.store.value ? DoubleEnum.Double : DoubleEnum.NotDouble;
            }

            if (element.isAdditional) {
                if (element.store.type === "checkbox") {
                    additionalFields[element.field] = !!value ? "1" : "0"
                } else {
                    additionalFields[element.field] = value ? value.trim() : null
                }
            } else {
                if (element.store.type === "checkbox")
                    fields.push({field: element.field, value: value.trim()})
                else
                    fields.push({field: element.field, value: value ? value.toString().trim() : null, type: valueType})
            }
        }
        for (const element of Object.keys(this.data.customFieldStore)) {
            const store = this.data.customFieldStore[element];
            if (store.value?.fields)
                fields.push(...store.value?.fields)
            if (store.value?.additionalFields)
                additionalFields = {...additionalFields, ...store.value?.additionalFields}
        }
        return {
            id: this.dto.id,
            fields: fields,
            additionalFields: additionalFields
        }
    }

    async submit(status: RequestStatus): Promise<void> {
        this.loading = true;

        try {
            let result = await new RequestApi().apiDcrV1RequestsSummaryChangePost({
                summaryChangeRequestStatusCommand: {
                    ...this.changeRequestFieldsCommand, ...{
                        comment: this.commentStore.value,
                        status: status,
                        clientType: this.dto.clientType
                    }
                }
            })
            await this._store.update(result);
        } finally {
            this.loading = false;
        }
    }

    async update(dto: RequestDto) {
        this.dto = dto;
        await this.pull();
    }

    get isTake(): boolean {
        return this.dto.responsibleId == this._store._store.store.store.authorizationStore.id;
    }

    get blocksInfo(): { name: string, fields: string[] }[] {
        return this.dto.type == RequestTypeEnum.Person ?
            Object.keys(PersonBlock).map(x => {
                const fields = PersonBlock[<DcrPersonBlockInfo>x].map(x => String(x));
                return ({
                    name: x,
                    fields: fields.filter(q => this.fields.find(w => w.field == q && !w.isHide) || this.data.customFieldStore[q])
                        .filter(w => !!w)
                })
            }).filter(x => x.fields.length > 0) :
            Object.keys(OrganizationBlock).map(x => {
                const fields = OrganizationBlock[<DcrOrganizationBlockInfo>x].map(x => String(x));
                return ({
                    name: x,
                    fields: fields.filter(q => this.fields.find(w => w.field == q && !w.isHide) || this.data.customFieldStore[q])
                        .filter(w => !!w)
                })
            }).filter(x => x.fields.length > 0);
    }

    async loadDbData() {
        if (this.dto.dcrStatus === DCRStatusEnum.Update ||
            this.dto.status === RequestStatusEnum.Verified ||
            this.dto.status === RequestStatusEnum.Partialy ||
            this.dto.status === RequestStatusEnum.Double) {
            await this.data.pull()
        }
    }

    async pull(): Promise<void> {
        this.loading = true;

        try {
            const model = await new RequestApi().apiDcrV1RequestsDetailsRequestIdGet({requestId: this.dto.id})
            this.subRequests = model.subRequests!;
            this.firstRequestFields = model.firstValues!
                .map(x => ({
                    item: x,
                    sortBy: this.dto.type == 0 ? OrganizationFieldPriority[<OrganizationField>x.field] : PersonFieldPriority[<PersonField>x.field]
                }))
                .sort((x, y) => !x.sortBy ? 1 : x.sortBy > y.sortBy ? 1 : -1)
                .map(x => x.item);
            this.requestFields = model.fields!;

            try {
                await this.loadDbData()
            } catch (e) {

            }

            if (this.dto.dcrStatus === 1 && this.dto.data360Id == null && this.dto.externalId == null) {
                this.notVerifiedCreateRequestId = await new RequestApi()
                    .apiDcrV1RequestsGetNotVerifiedRequestIdGet({
                        crmId: this.dto.crmId,
                        boundId: this.dto.boundId!,
                        entityType: this.dto.type
                    });
            }

            const fields = []
            for (const element of model.fields!.filter(x => Object.keys(this.data.builders).find(y => y == x.field)))
                fields.push(await this.data.generateField(element));
            Object.keys(this.data.customFieldStore).forEach(x => this.data.customFieldStore[x].pull())

            this.fields = fields;
            await this.specification.changeAfterInitFields(this.fields);
            await this.data.changeAfterInitFields(this.fields)
        } finally {
            this.loading = false;
        }
    }
}
