import { observer } from "mobx-react";
import { Box, Typography } from "@mui/material";
import React from "react";
import { OrganizationEmployeesTable } from "../details/employees/OrganizationEmployeesTable";
import { DoubleItemStore } from "../../../stores/management/organizations/doubles/DoubleItemStore";
import { OrgEmployees as OrgEmployeesIcon } from '../../../icons/orgEmployes'
import { useTranslation } from "react-i18next";
import { ToolDivider } from "../../../components";
import { useRootStore } from "../../../stores";

interface Props {
    prop: DoubleItemStore;
    style?: React.CSSProperties
}

export const DoubleDetails = observer(({ prop, style }: Props) => {
    const { t } = useTranslation();
    const {themeColorStore} = useRootStore();
    const currentColor = themeColorStore.themeSettings === 'dark' ? '#7582EB' : '#5048E5'
    return (
        <Box>
            <Box sx={{ ml: 2 }}>
                <Box sx={{ display: 'flex', mt: 1 }}>
                    <Typography sx={{ fontWeight: 'bold', my: 'auto' }}>{t('common.jobsDouble')}</Typography>
                    <Box sx={{ alignItems: 'center', display: 'flex', pt: 0.5 }}>
                        <Box><OrgEmployeesIcon sx={{ height: 20, width: 20, mt: 1, ml: 1 }} /></Box>
                        <Typography color="textSecondary" variant="overline">{prop.jobDouble?.count}</Typography>
                    </Box>
                </Box>
                <OrganizationEmployeesTable store={prop.jobDouble} double />
            </Box>
            <Box sx={{ ml: 2 }}>
                <Box sx={{ display: 'flex', mt: 1 }}>
                    <Typography sx={{ fontWeight: 'bold', my: 'auto' }}>{t('common.jobsAntiDouble')}</Typography>
                    <Box sx={{ alignItems: 'center', display: 'flex', pt: 0.5 }} >
                        <Box><OrgEmployeesIcon sx={{ height: 20, width: 20, mt: 1, ml: 1 }} /></Box>
                        <Typography color="textSecondary" variant="overline">{prop.jobAntiDouble?.count}</Typography>
                    </Box>
                </Box>
                <OrganizationEmployeesTable store={prop.jobAntiDouble} double />
            </Box>
            <ToolDivider style={{ height: 3, backgroundColor: currentColor, margin: '4px 0' }}/>
        </Box>
    );
});
