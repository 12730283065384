import {observer} from 'mobx-react';
import {CreateButtonsGroup, FormRow} from '../../components';
import {useTranslation} from "react-i18next";
import {DefaultSelect} from "../../components/shared/components/DefaultSelect";
import React from "react";
import {Box} from "@mui/material";
import {DefaultValue} from "../../components/shared/components/DefaultValue";
import {DefaultValueStore} from "../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from "../../components/shared/DefaultSelectStore";
import {UserDto} from "../../services/management";
import {DefaultFormModalStore} from "../../components/shared/DefaultFormModalStore";

interface Props {
    store: DefaultFormModalStore<{
        name: DefaultValueStore<string>,
        clientName: DefaultValueStore<string>,
        description: DefaultValueStore<string>
        secretKey: DefaultValueStore<string>,
        owner: DefaultSelectStore<UserDto>
    }>
}

export const ClientCreator = observer(({store}: Props) => {
    const {t} = useTranslation();

    return (
        <Box sx={{p: 2}}>
            <FormRow>
                <DefaultSelect
                    autoFocus
                    title={t('usersPage.creator')} nameExp='name' store={store.fields.owner}
                    testid="clientCreator-creator" hasLabel/>
            </FormRow>
            <FormRow>
                <DefaultValue store={store.fields.name} title={t('clientPage.name')}/>
            </FormRow>
            <FormRow>
                <DefaultValue store={store.fields.clientName} title={t('clientPage.orgName')}/>
            </FormRow>
            <FormRow>
                <DefaultValue store={store.fields.secretKey} title={t('clientPage.secretKey')}/>
            </FormRow>
            <FormRow style={{height: "max-content", marginBottom: 10}}>
                <DefaultValue store={store.fields.description}
                              title={t('common.description')}
                              multiline
                              style={{width: '100%'}}/>
            </FormRow>

            <CreateButtonsGroup onCancel={() => store.Open = false}
                                isCancelBtnDisabled={store.processing}
                                onSave={async () => await store.submit()}
                                isSaveBtnDisabled={store.processing || !store.valid}
                                testid="clientCreator"/>

        </Box>
    );
});
